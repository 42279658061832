<template>
  <div>
    <LandingPageLayout :layout="pageLayout" :components="{}" />
  </div>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { LandingPageLayout } from '@ramp106/omrjs-hygraph-ui'
import { Locale, useLandingPageBySlugLazyQuery, type LandingPageBySlugQuery } from '@/gql/hygraph/hygraph.generated'
import { useContentPages } from '@/composables/useContentPages'
import { useUiStore } from '@/stores'

import { useMeStore } from '@/stores'
import { storeToRefs } from 'pinia'
import { getLocale } from '@/helpers/locale'

const { me } = storeToRefs(useMeStore())

const router = useRouter()
const route = useRoute()
const { getContentSlug } = useContentPages()

const page = ref<LandingPageBySlugQuery>()

const uiStore = useUiStore()
const { locale } = storeToRefs(uiStore)
const requestLocale = ref<'en' | 'de'>(locale.value ?? getLocale())

const contentSlug = computed(() => {
  return getContentSlug.value(route.params.slug as string, !!me)
})

const pageLayout = computed(() => {
  return page.value?.landingPage?.pageLayout ?? []
})

const { load, refetch, onResult } = useLandingPageBySlugLazyQuery(
  { slug: '', locales: [requestLocale.value == 'en' ? Locale.En : Locale.De] },
  { clientId: 'hygraph' },
)

onResult((pageData) => {
  page.value = pageData?.data
})

watch(
  () => contentSlug.value,
  (newSlug) => {
    switch (newSlug) {
      case '404': {
        router.push({ name: '404' })
        break
      }
      case 'NA': {
        break
      }
      default: {
        load(null, { slug: newSlug, locales: [requestLocale.value == 'en' ? Locale.En : Locale.De] })
      }
    }
  },
  { immediate: true },
)

watch(
  () => locale.value,
  (newLocale) => {
    refetch({ slug: contentSlug.value, locales: [newLocale == 'en' ? Locale.En : Locale.De] })
  },
)
</script>
