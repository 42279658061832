<template>
  <div>
    <TableLight>
      <thead>
        <tr>
          <TableH
            :label="t('Reviews.product.view.table.date')"
            :sort-params="getSortState(SurveyResponsesSortByEnum.UpdatedAt)"
            @sort="setSort"
          />
          <TableH
            :label="t('Reviews.product.view.table.reviewerName')"
            :sort-params="getSortState(SurveyResponsesSortByEnum.ReviewerName)"
            @sort="setSort"
          />
          <TableH
            :label="t('Reviews.product.view.table.reviewerCompany')"
            :sort-params="getSortState(SurveyResponsesSortByEnum.ReviewerCompany)"
            @sort="setSort"
          />
          <TableH :label="t('Reviews.product.view.table.reviewerPosition')" />
          <TableH
            :label="t('Reviews.product.view.table.recommendationScore')"
            :sort-params="getSortState(SurveyResponsesSortByEnum.RecommendationScore)"
            @sort="setSort"
          />
          <TableH :label="t('Reviews.product.view.table.status')" />
          <TableH label="" />
          <TableH label="" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="surveyResponse in surveyResponses"
          :key="surveyResponse.id"
          :class="{
            'cursor-pointer': isOpenable(surveyResponse.aasmState),
          }"
          @click="openDetails(surveyResponse)"
        >
          <td class="text-left">
            <span>{{ formatDate(surveyResponse.updatedAt) }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerFirstName || '---' }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerCompany || '---' }}</span>
          </td>
          <td class="text-left">
            <span>{{ surveyResponse.reviewerPosition || '---' }}</span>
          </td>
          <td class="text-left">
            <div class="flex items-center">
              <RatingStars :value="ratingValue(surveyResponse)" />
              <span class="ml-1">{{ ratingValueText(surveyResponse) }}</span>
            </div>
          </td>
          <td class="text-left">
            <SurveyResponseStatusBadge :survey-response="surveyResponse" />
          </td>
          <td class="text-left">
            <div class="flex flex-nowrap gap-x-4 justify-center">
              <ReviewInteractionStatus
                :type="
                  surveyResponse.reminders.length > 0 && surveyResponse.aasmState !== SurveyResponseStateEnum.Published
                    ? ReviewInteractionType.REMINDER_CHECK
                    : ReviewInteractionType.REMINDER
                "
                :tooltip-text="t('Reviews.product.review.reminderStatusHint', { count: surveyResponse.reminders.length })"
              />
              <ReviewInteractionStatus
                :type="hasReviewReply(surveyResponse) ? ReviewInteractionType.REPLY_CHECK : ReviewInteractionType.REPLY"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </TableLight>
    <div v-if="loading" class="flex justify-center mt-6">
      <CircleSpinner />
    </div>
  </div>
</template>

<script setup lang="ts">
import { CircleSpinner, RatingStars } from '@ramp106/omrjs-core-ui'
import { TableH, TableLight, useSortQuery } from '@/ui/TableLight'

import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { SortDirectionEnum, SurveyResponsesSortByEnum, SurveyResponseStateEnum } from '@/gql/reviews'
import SurveyResponseStatusBadge from './SurveyResponseStatusBadge.vue'
import { ReviewsRouteName } from '@/router/types'
import ReviewInteractionStatus from '@/components/ReviewsManagement/Reviews/ReviewInteractionStatus.vue'
import { ReviewInteractionType, type SurveyResponse } from '@/components/ReviewsManagement/Reviews/types'

defineProps<{
  productId: string
  surveyResponses: SurveyResponse[]
  loading?: boolean
}>()

const { d, t } = useI18n()
const router = useRouter()
const { setSort, getSortState } = useSortQuery<SurveyResponsesSortByEnum, SortDirectionEnum>()

function hasReviewReply(surveyResponse: SurveyResponse) {
  return !!surveyResponse.review?.reviewReply
}

function isOpenable(status: string) {
  return [
    SurveyResponseStateEnum.Published,
    SurveyResponseStateEnum.Rejected,
    SurveyResponseStateEnum.Drafted,
    SurveyResponseStateEnum.Proposed,
    SurveyResponseStateEnum.Stale,
  ].includes(status as SurveyResponseStateEnum)
}

function formatDate(date?: string | null) {
  if (!date) {
    return '-'
  }

  return d(date, 'ddmmyy')
}

function ratingValue(surveyResponse: SurveyResponse) {
  const score = surveyResponse.review?.recommendationScore
  if (typeof score !== 'number') {
    return undefined
  }
  return score / 2
}

function ratingValueText(surveyResponse: SurveyResponse) {
  return ratingValue(surveyResponse)?.toFixed(1) || '-'
}

function openDetails(surveyResponse: SurveyResponse) {
  if (!isOpenable(surveyResponse.aasmState)) {
    return
  }
  router.push({ name: ReviewsRouteName.ReviewsProductViewReviewDetails, params: { surveyResponseId: surveyResponse.id } })
}
</script>
