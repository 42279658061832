<template>
  <ModalDialog
    ref="modal"
    data-testid="send-reminder-dialog"
    persistent
    max-height="95vh"
    max-width="648px"
    :title="t('Reviews.sendReminderDialog.title')"
    @closed="$emit('closed')"
  >
    <div class="text-body-s mb-6">{{ sendReminderDialogText }}</div>
    <MessageBanner v-if="message" class="my-6" :headline="message.headline" :message="message.text" :type="message.type" />
    <div class="text-label-m mb-1.5">
      {{ t('Reviews.sendReminderDialog.recipientLabel') }}
    </div>
    <ChipBadge class="mb-4" rounded> {{ reviewerFirstName }} </ChipBadge>
    <div class="text-label-m mb-1.5 flex justify-between items-end">
      <span>{{ t('Reviews.sendReminderDialog.messageLabel') }}</span>
      <button v-if="focused" class="btn-secondary-black-s" @click="insertLink">
        {{ t('Reviews.sendReminderDialog.insertReviewLink') }}
      </button>
      <TooltipWrapper v-else :z-index="60">
        <template #trigger>
          <button class="btn-secondary-black-s" disabled @click="insertLink">
            {{ t('Reviews.sendReminderDialog.insertReviewLink') }}
          </button>
        </template>
        <template #content>
          <div class="max-w-xs">
            {{ t('Reviews.sendReminderDialog.insertReviewLinkHint') }}
          </div>
        </template>
      </TooltipWrapper>
    </div>
    <RichInput
      ref="richInput"
      :key="inputKey"
      v-model="v$.message.$model"
      :error="getValidationErrors(v$.message)"
      hide-controls
      plain-text-only
      @focusin="onFocusIn"
      @focusout="onFocusOut"
    />

    <div class="flex justify-between">
      <CheckBox v-model="v$.copyToSender.$model">{{ t('Reviews.sendReminderDialog.copyToSender') }}</CheckBox>
      <button class="btn-primary-purple-m" :disabled="loading || v$.$invalid" @click="onSubmit">
        {{ t('Reviews.sendReminderDialog.send') }}
      </button>
    </div>
  </ModalDialog>
</template>
<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import TurnDown from 'turndown'
import { ChipBadge, CheckBox, ModalDialog, RichInput, TooltipWrapper, nl2br, MessageBanner } from '@ramp106/omrjs-core-ui'
import useVuelidate, { type ValidationArgs } from '@vuelidate/core'
import { required, maxLength, minLength, containsValidator, withI18nMessage } from '@/services/validators'
import { type ProductById, useCreateSurveyResponseReminderMutation } from '@/gql/reviews'
import { getValidationErrors } from '@/helpers/validationHelper'
import { useMeStore } from '@/stores'
import { showNotification } from '@/helpers/notificationHelper'
import { SurveyResponseState, useSurveyResponseState } from '@/composables/useSurveyResponseState.ts'
import type { SurveyResponse } from '@/components/ReviewsManagement/Reviews/types.ts'

type FormData = {
  message: string
  copyToSender: boolean
}

const MSG_MIN_LENGTH = 100
const MSG_MAX_LENGTH = 10000
const REVIEW_LINK_BE_PLACEHOLDER = '%{review_link}'
const REVIEW_LINK_PLACEHOLDER = `(${REVIEW_LINK_BE_PLACEHOLDER})`

const props = defineProps<{
  surveyResponse: Pick<SurveyResponse, 'id' | 'reviewerFirstName' | 'aasmState'>
  product: Pick<ProductById, 'title' | 'slug'>
}>()

defineEmits(['closed'])

const meStore = useMeStore()
const { me } = storeToRefs(meStore)
const modal = ref<InstanceType<typeof ModalDialog> | null>(null)
const richInput = ref<InstanceType<typeof RichInput> | null>(null)
const inputKey = ref(1)
const focused = ref(false)
const { t } = useI18n()
const state = useSurveyResponseState(props.surveyResponse)
const reviewerFirstName = computed(() => props.surveyResponse.reviewerFirstName || undefined)

const { loading, mutate: sendReminder } = useCreateSurveyResponseReminderMutation({ clientId: 'reviews' })

const reviewLink = computed(() => `<a href="${REVIEW_LINK_BE_PLACEHOLDER}">${t('Reviews.sendReminderDialog.reviewLinkText')}</a> `)

const sendReminderDialogText = computed(() => {
  return state.value === SurveyResponseState.Rejected ? t('Reviews.sendReminderDialog.rejected.text') : t('Reviews.sendReminderDialog.text')
})

const message = computed(() => {
  switch (state.value) {
    case SurveyResponseState.Rejected:
      return {
        headline: t('Reviews.product.review.rejected.headline'),
        text: t('Reviews.product.review.rejected.text'),
        type: 'error' as const,
      }
    case SurveyResponseState.Drafted:
    case SurveyResponseState.Stale:
      return {
        headline: t('Reviews.product.review.draftedOrStale.headline'),
        text: t('Reviews.product.review.draftedOrStale.message', { authorName: reviewerFirstName.value }),
        type: 'info' as const,
      }
    case SurveyResponseState.ReDrafted:
      return {
        headline: t('Reviews.product.review.reDrafted.headline'),
        text: t('Reviews.product.review.reDrafted.message', { authorName: reviewerFirstName.value }),
        type: 'info' as const,
      }
    default:
      return null
  }
})

const formData = reactive<FormData>({
  message:
    state.value === SurveyResponseState.Rejected
      ? t('Reviews.sendReminderDialog.rejected.messageBody', {
          authorName: reviewerFirstName.value,
          productTitle: props.product.title,
          vendorName: me.value?.partnerCompany?.displayName || '',
          reviewLink: reviewLink.value,
        }).toString()
      : t('Reviews.sendReminderDialog.messageBody', {
          authorName: reviewerFirstName.value,
          productTitle: props.product.title,
          vendorName: me.value?.partnerCompany?.displayName || '',
          reviewLink: reviewLink.value,
        }).toString(),

  copyToSender: false,
})

const containsLink = withI18nMessage(containsValidator, {
  withArguments: true,
  messagePath: () => 'Reviews.sendReminderDialog.missingReviewLink',
})

const rules = computed<ValidationArgs<FormData>>(() => ({
  message: {
    required,
    minLength: minLength(MSG_MIN_LENGTH),
    maxLength: maxLength(MSG_MAX_LENGTH),
    contains: containsLink(REVIEW_LINK_BE_PLACEHOLDER),
  },
  copyToSender: {},
}))

const v$ = useVuelidate(rules, formData)

function insertLink() {
  richInput.value?.insertHTML(REVIEW_LINK_PLACEHOLDER)
  v$.value.message.$model = v$.value.message.$model.replace(REVIEW_LINK_PLACEHOLDER, reviewLink.value)
  // RichInput does not allow outside model change so we have to rerender component
  inputKey.value = inputKey.value + 1
}

function open() {
  modal.value?.open()
}

function close() {
  modal.value?.close()
}

function onFocusIn() {
  focused.value = true
}

function onFocusOut() {
  setTimeout(() => (focused.value = false), 100)
}

async function onSubmit() {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) {
    showNotification(t('Reviews.sendReminderDialog.invalidForm'), 'error')
    return
  }

  const turnDown = new TurnDown({
    emDelimiter: '_',
    linkStyle: 'inlined',
    headingStyle: 'atx',
  })

  try {
    await sendReminder({
      input: {
        surveyResponseId: props.surveyResponse.id,
        message: turnDown.turndown(nl2br(formData.message)),
        sendCopyToVendor: formData.copyToSender,
      },
    })
    showNotification(t('Reviews.sendReminderDialog.success', { authorName: reviewerFirstName.value }), 'success', 10000)
    close()
  } catch (e) {
    showNotification(t('Reviews.sendReminderDialog.serverError'), 'error', 10000)
  }
}

defineExpose({ open, close })
</script>
