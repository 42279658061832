<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProductViewReviews }">
    <span class="pr-2">
      {{
        t('Reviews.product.view.reviewDetailTitle', {
          authorName: surveyResponse.reviewerFirstName || t('Reviews.product.review.history.unknownAuthor'),
        })
      }}
    </span>
    <SurveyResponseStatusBadge class="flex" :survey-response="surveyResponse" />
    <ReviewInteractionStatus
      v-if="remindersCount && remindersCount > 0"
      :type="ReviewInteractionType.REMINDER_CHECK"
      :tooltip-text="t('Reviews.product.review.reminderStatusHint', { count: remindersCount })"
      class="ml-2"
    />
    <ReviewInteractionStatus v-if="hasReply" :type="ReviewInteractionType.REPLY_CHECK" class="ml-2" />
    <template #actions>
      <TooltipWrapper>
        <template #trigger>
          <button v-if="canAddReply" class="btn-primary-purple-m" :disabled="canAddReplyDisabled" @click="$emit('send-reply')">
            {{ t('Reviews.product.view.actions.sendReply') }}
          </button>
        </template>
        <template v-if="canAddReplyDisabled" #content>
          <div class="max-w-xs">{{ t('Reviews.product.review.sendReplyDisabledHint') }}</div>
        </template>
      </TooltipWrapper>
    </template>
  </ContentCardSubheader>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { TooltipWrapper } from '@ramp106/omrjs-core-ui'
import SurveyResponseStatusBadge from './SurveyResponseStatusBadge.vue'
import { computed } from 'vue'
import ReviewInteractionStatus from '@/components/ReviewsManagement/Reviews/ReviewInteractionStatus.vue'
import { ReviewInteractionType, type SurveyResponse } from './types'
import { ReviewsRouteName } from '@/router/types.ts'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'
import { SurveyResponseState } from '@/composables/useSurveyResponseState.ts'
import type { ProductData } from '@/gql/reviews'

const props = defineProps<{
  product: Pick<ProductData, 'configFlags'>
  surveyResponse: SurveyResponse
}>()

defineEmits(['send-reply'])

const { t } = useI18n()
const hasReply = computed(() => !!props.surveyResponse.review?.reviewReply)
const remindersCount = computed(() => props.surveyResponse.reminders.length)
const canAddReply = computed(() => props.surveyResponse.aasmState === SurveyResponseState.Published && !hasReply.value)
const canAddReplyDisabled = computed(() => !props.product.configFlags.reviewRepliesAllowed)
</script>
