<template>
  <div class="py-2" :class="{ disabled }">
    <div
      class="flex items-center justify-between border-solid border-b border-b-grey-600 pb-2"
      :class="{
        'cursor-pointer': !disabled,
      }"
      @click.prevent="toggleActive"
    >
      <div class="flex items-center min-w-0">
        <SvgIcon class="mr-1" :path="isActive ? mdiChevronUp : mdiChevronDown" :size="18" />
        <div class="text-body-s-bold min-w-0 overflow-hidden text-ellipsis whitespace-nowrap mr-2"><slot name="name" /></div>
      </div>
      <div class="text-body-s-bold flex min-w-0 shrink-0"><slot name="actions" /></div>
    </div>
    <SlideTransition class="expandable-content">
      <div class="pt-4" :class="{ hidden: !isActive }">
        <slot />
      </div>
    </SlideTransition>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { SlideTransition } from '@ramp106/omrjs-core-ui'
import SvgIcon from '@/components/SvgIcon.vue'

const props = defineProps<{ expanded?: boolean; disabled?: boolean }>()

const isActive = ref(props.expanded ?? false)

function toggleActive() {
  if (props.disabled) {
    return
  }
  isActive.value = !isActive.value
}

function open() {
  if (props.disabled) {
    return
  }
  isActive.value = true
}

function close() {
  if (props.disabled) {
    return
  }
  isActive.value = false
}

defineExpose({ open, close })

watch(
  () => props.disabled,
  () => {
    isActive.value = false
  },
)
</script>
<style scoped>
.disabled {
  opacity: 0.7;
}
</style>
