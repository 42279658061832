export const availableLocales = ['en', 'de'] as const
type AvailableLocale = (typeof availableLocales)[number]
export const defaultLocale = availableLocales[0]

// Taken from: https://fettblog.eu/typescript-array-includes/
function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T)
}

export const getValidLocale = (locale: string | null | undefined, withDefault: boolean): AvailableLocale | undefined => {
  return includes(availableLocales, locale) ? locale : withDefault ? defaultLocale : undefined
}

export const getBrowserLocale = () => {
  if (navigator) {
    const locale = navigator.language.split('-')[0]
    return getValidLocale(locale, true)
  } else {
    return defaultLocale
  }
}

export const getLocalStorageLocale = () => {
  if (window && window.localStorage) {
    return getValidLocale(localStorage.getItem('locale'), false)
  }
}

export const getLocale = (() => getLocalStorageLocale() || getBrowserLocale()) as () => AvailableLocale
