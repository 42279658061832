import { gql } from '@/helpers/gql'
import * as VueApolloComposable from '@vue/apollo-composable'
import type * as VueCompositionApi from 'vue'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type ReactiveFunction<TParam> = () => TParam
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  Hex: { input: any; output: any }
  Json: { input: any; output: any }
  Long: { input: any; output: any }
  RGBAHue: { input: any; output: any }
  RGBATransparency: { input: any; output: any }
  RichTextAST: { input: any; output: any }
}

export type Aggregate = {
  __typename?: 'Aggregate'
  count: Scalars['Int']['output']
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounter = Entity &
  Node & {
    __typename?: 'AnimatedCounter'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<AnimatedCounter>
    /** List of AnimatedCounter versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<AnimatedCounter>
    /** If specified, this will be prefixed before the counter value. */
    prefix?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    start?: Maybe<Scalars['Int']['output']>
    /** Will be shown under the counter value. */
    subhead?: Maybe<Scalars['String']['output']>
    /** If specified, this will be appended after the counter value. */
    suffix?: Maybe<Scalars['String']['output']>
    target: Scalars['Int']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** In the animated counter section this model represents one counter settings */
export type AnimatedCounterUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type AnimatedCounterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: AnimatedCounterWhereUniqueInput
}

/** A connection to a list of items. */
export type AnimatedCounterConnection = {
  __typename?: 'AnimatedCounterConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<AnimatedCounterEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type AnimatedCounterCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AnimatedCounterCreateLocalizationsInput>
  /** prefix input for default locale (de) */
  prefix?: InputMaybe<Scalars['String']['input']>
  sectionCounter?: InputMaybe<SectionByTheNumbersCreateOneInlineInput>
  start?: InputMaybe<Scalars['Int']['input']>
  /** subhead input for default locale (de) */
  subhead?: InputMaybe<Scalars['String']['input']>
  /** suffix input for default locale (de) */
  suffix?: InputMaybe<Scalars['String']['input']>
  target: Scalars['Int']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type AnimatedCounterCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  prefix?: InputMaybe<Scalars['String']['input']>
  subhead?: InputMaybe<Scalars['String']['input']>
  suffix?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type AnimatedCounterCreateLocalizationInput = {
  /** Localization input */
  data: AnimatedCounterCreateLocalizationDataInput
  locale: Locale
}

export type AnimatedCounterCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AnimatedCounterCreateLocalizationInput>>
}

export type AnimatedCounterCreateManyInlineInput = {
  /** Connect multiple existing AnimatedCounter documents */
  connect?: InputMaybe<Array<AnimatedCounterWhereUniqueInput>>
  /** Create and connect multiple existing AnimatedCounter documents */
  create?: InputMaybe<Array<AnimatedCounterCreateInput>>
}

export type AnimatedCounterCreateOneInlineInput = {
  /** Connect one existing AnimatedCounter document */
  connect?: InputMaybe<AnimatedCounterWhereUniqueInput>
  /** Create and connect one AnimatedCounter document */
  create?: InputMaybe<AnimatedCounterCreateInput>
}

/** An edge in a connection. */
export type AnimatedCounterEdge = {
  __typename?: 'AnimatedCounterEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: AnimatedCounter
}

/** Identifies documents */
export type AnimatedCounterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<AnimatedCounterWhereStageInput>
  documentInStages_none?: InputMaybe<AnimatedCounterWhereStageInput>
  documentInStages_some?: InputMaybe<AnimatedCounterWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  start?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  start_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  start_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  start_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  start_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  start_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  start_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  start_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  target?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  target_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  target_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  target_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  target_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  target_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  target_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum AnimatedCounterOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PrefixAsc = 'prefix_ASC',
  PrefixDesc = 'prefix_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SuffixAsc = 'suffix_ASC',
  SuffixDesc = 'suffix_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AnimatedCounterUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<AnimatedCounterUpdateLocalizationsInput>
  /** prefix input for default locale (de) */
  prefix?: InputMaybe<Scalars['String']['input']>
  sectionCounter?: InputMaybe<SectionByTheNumbersUpdateOneInlineInput>
  start?: InputMaybe<Scalars['Int']['input']>
  /** subhead input for default locale (de) */
  subhead?: InputMaybe<Scalars['String']['input']>
  /** suffix input for default locale (de) */
  suffix?: InputMaybe<Scalars['String']['input']>
  target?: InputMaybe<Scalars['Int']['input']>
}

export type AnimatedCounterUpdateLocalizationDataInput = {
  prefix?: InputMaybe<Scalars['String']['input']>
  subhead?: InputMaybe<Scalars['String']['input']>
  suffix?: InputMaybe<Scalars['String']['input']>
}

export type AnimatedCounterUpdateLocalizationInput = {
  data: AnimatedCounterUpdateLocalizationDataInput
  locale: Locale
}

export type AnimatedCounterUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AnimatedCounterCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<AnimatedCounterUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<AnimatedCounterUpsertLocalizationInput>>
}

export type AnimatedCounterUpdateManyInlineInput = {
  /** Connect multiple existing AnimatedCounter documents */
  connect?: InputMaybe<Array<AnimatedCounterConnectInput>>
  /** Create and connect multiple AnimatedCounter documents */
  create?: InputMaybe<Array<AnimatedCounterCreateInput>>
  /** Delete multiple AnimatedCounter documents */
  delete?: InputMaybe<Array<AnimatedCounterWhereUniqueInput>>
  /** Disconnect multiple AnimatedCounter documents */
  disconnect?: InputMaybe<Array<AnimatedCounterWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing AnimatedCounter documents */
  set?: InputMaybe<Array<AnimatedCounterWhereUniqueInput>>
  /** Update multiple AnimatedCounter documents */
  update?: InputMaybe<Array<AnimatedCounterUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple AnimatedCounter documents */
  upsert?: InputMaybe<Array<AnimatedCounterUpsertWithNestedWhereUniqueInput>>
}

export type AnimatedCounterUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AnimatedCounterUpdateManyLocalizationsInput>
  /** prefix input for default locale (de) */
  prefix?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['Int']['input']>
  /** subhead input for default locale (de) */
  subhead?: InputMaybe<Scalars['String']['input']>
  /** suffix input for default locale (de) */
  suffix?: InputMaybe<Scalars['String']['input']>
  target?: InputMaybe<Scalars['Int']['input']>
}

export type AnimatedCounterUpdateManyLocalizationDataInput = {
  prefix?: InputMaybe<Scalars['String']['input']>
  subhead?: InputMaybe<Scalars['String']['input']>
  suffix?: InputMaybe<Scalars['String']['input']>
}

export type AnimatedCounterUpdateManyLocalizationInput = {
  data: AnimatedCounterUpdateManyLocalizationDataInput
  locale: Locale
}

export type AnimatedCounterUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AnimatedCounterUpdateManyLocalizationInput>>
}

export type AnimatedCounterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AnimatedCounterUpdateManyInput
  /** Document search */
  where: AnimatedCounterWhereInput
}

export type AnimatedCounterUpdateOneInlineInput = {
  /** Connect existing AnimatedCounter document */
  connect?: InputMaybe<AnimatedCounterWhereUniqueInput>
  /** Create and connect one AnimatedCounter document */
  create?: InputMaybe<AnimatedCounterCreateInput>
  /** Delete currently connected AnimatedCounter document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected AnimatedCounter document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single AnimatedCounter document */
  update?: InputMaybe<AnimatedCounterUpdateWithNestedWhereUniqueInput>
  /** Upsert single AnimatedCounter document */
  upsert?: InputMaybe<AnimatedCounterUpsertWithNestedWhereUniqueInput>
}

export type AnimatedCounterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AnimatedCounterUpdateInput
  /** Unique document search */
  where: AnimatedCounterWhereUniqueInput
}

export type AnimatedCounterUpsertInput = {
  /** Create document if it didn't exist */
  create: AnimatedCounterCreateInput
  /** Update document if it exists */
  update: AnimatedCounterUpdateInput
}

export type AnimatedCounterUpsertLocalizationInput = {
  create: AnimatedCounterCreateLocalizationDataInput
  locale: Locale
  update: AnimatedCounterUpdateLocalizationDataInput
}

export type AnimatedCounterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AnimatedCounterUpsertInput
  /** Unique document search */
  where: AnimatedCounterWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type AnimatedCounterWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type AnimatedCounterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AnimatedCounterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<AnimatedCounterWhereStageInput>
  documentInStages_none?: InputMaybe<AnimatedCounterWhereStageInput>
  documentInStages_some?: InputMaybe<AnimatedCounterWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  prefix?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  prefix_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  prefix_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  prefix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  prefix_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  prefix_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  prefix_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  prefix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  prefix_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  prefix_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  start?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  start_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  start_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  start_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  start_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  start_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  start_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  start_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  subhead?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subhead_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subhead_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subhead_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subhead_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subhead_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subhead_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subhead_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subhead_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subhead_starts_with?: InputMaybe<Scalars['String']['input']>
  suffix?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  suffix_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  suffix_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  suffix_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  suffix_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  suffix_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  suffix_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  suffix_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  suffix_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  suffix_starts_with?: InputMaybe<Scalars['String']['input']>
  target?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  target_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  target_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  target_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  target_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  target_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  target_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AnimatedCounterWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AnimatedCounterWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AnimatedCounterWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AnimatedCounterWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AnimatedCounterWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References AnimatedCounter record uniquely */
export type AnimatedCounterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Article = Entity &
  Node & {
    __typename?: 'Article'
    articleCategories: Array<ArticleCategory>
    articleType: ArticleType
    /** Authors of German article content */
    authors: Array<Author>
    authorsEn: Array<Author>
    body: ArticleBodyRichText
    /** Optional button displayed on article page. Can be used as eg download button for briefing information. */
    callToAction: Array<ArticleCta>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Article>
    /** Featured image or short video */
    featuredMedia: Asset
    hideNewsletterBox?: Maybe<Scalars['Boolean']['output']>
    /** List of Article versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Article>
    onlyEnglish: Scalars['Boolean']['output']
    /** Page views based on Google Analytics */
    pageViews: Scalars['Int']['output']
    publishDateTime: Scalars['DateTime']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    reviewsAgency: Array<ArticleReviewsAgency>
    reviewsProductCategories: Array<ReviewsProductCategory>
    reviewsProducts: Array<ReviewsProduct>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Attributes. By default seo.title = article.title, seo.description = article.snippet, seo.ogImage = article.featuredImage, seo.ogTitle = seo.title, seo ogDescription = seo.description */
    seoAttributes?: Maybe<Seo>
    showTableOfContents?: Maybe<Scalars['Boolean']['output']>
    slug: Scalars['String']['output']
    /** Short snippet from the article which will be used on category pages and preview cards to tease the article. */
    snippet: Scalars['String']['output']
    /** Highlight sponsored articles */
    sponsored?: Maybe<Scalars['Boolean']['output']>
    /** System stage field */
    stage: Stage
    subtitle?: Maybe<Scalars['String']['output']>
    tags: Array<Tag>
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** VG Wort integration data assigned to German article content (autogenerated on save) */
    vgWort?: Maybe<VgWort>
  }

export type ArticleArticleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleCategoryWhereInput>
}

export type ArticleAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorWhereInput>
}

export type ArticleAuthorsEnArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorWhereInput>
}

export type ArticleCallToActionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleCtaOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleCtaWhereInput>
}

export type ArticleCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticleCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type ArticleFeaturedMediaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type ArticleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ArticlePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticlePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleReviewsAgencyArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type ArticleReviewsProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductCategoryWhereInput>
}

export type ArticleReviewsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWhereInput>
}

export type ArticleScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type ArticleSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagWhereInput>
}

export type ArticleUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticleUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleVgWortArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleBodyRichText = {
  __typename?: 'ArticleBodyRichText'
  /** Returns HTMl representation */
  html: Scalars['String']['output']
  json: Scalars['RichTextAST']['output']
  /** Returns Markdown representation */
  markdown: Scalars['String']['output']
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output']
  references: Array<ArticleBodyRichTextEmbeddedTypes>
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output']
}

export type ArticleBodyRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type ArticleBodyRichTextEmbeddedTypes =
  | Asset
  | Author
  | HtmlEmbed
  | ImageGalleryEmbed
  | ReviewsAgency
  | ReviewsProduct
  | ReviewsProductCategory
  | ReviewsProductWidget
  | SocialEmbed

export type ArticleCategory = Entity &
  Node & {
    __typename?: 'ArticleCategory'
    articles: Array<Article>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ArticleCategory>
    /** List of ArticleCategory versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<ArticleCategory>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Attributes. */
    seoAttributes?: Maybe<Seo>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Optional wordpress reference used by importer */
    wpId?: Maybe<Scalars['String']['output']>
  }

export type ArticleCategoryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

export type ArticleCategoryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticleCategoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleCategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type ArticleCategoryHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type ArticleCategoryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ArticleCategoryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticleCategoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleCategoryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type ArticleCategorySeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleCategoryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type ArticleCategoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticleCategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ArticleCategoryWhereUniqueInput
}

/** A connection to a list of items. */
export type ArticleCategoryConnection = {
  __typename?: 'ArticleCategoryConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ArticleCategoryEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ArticleCategoryCreateInput = {
  articleByCategory?: InputMaybe<ArticlesByTagCreateOneInlineInput>
  articles?: InputMaybe<ArticleCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCategoryCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCategoryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ArticleCategoryCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCategoryCreateLocalizationDataInput
  locale: Locale
}

export type ArticleCategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCategoryCreateLocalizationInput>>
}

export type ArticleCategoryCreateManyInlineInput = {
  /** Connect multiple existing ArticleCategory documents */
  connect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>
  /** Create and connect multiple existing ArticleCategory documents */
  create?: InputMaybe<Array<ArticleCategoryCreateInput>>
}

export type ArticleCategoryCreateOneInlineInput = {
  /** Connect one existing ArticleCategory document */
  connect?: InputMaybe<ArticleCategoryWhereUniqueInput>
  /** Create and connect one ArticleCategory document */
  create?: InputMaybe<ArticleCategoryCreateInput>
}

/** An edge in a connection. */
export type ArticleCategoryEdge = {
  __typename?: 'ArticleCategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ArticleCategory
}

/** Identifies documents */
export type ArticleCategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ArticleCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<ArticleCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<ArticleCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum ArticleCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export type ArticleCategoryUpdateInput = {
  articleByCategory?: InputMaybe<ArticlesByTagUpdateOneInlineInput>
  articles?: InputMaybe<ArticleUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleCategoryUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCategoryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCategoryUpdateLocalizationInput = {
  data: ArticleCategoryUpdateLocalizationDataInput
  locale: Locale
}

export type ArticleCategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCategoryCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleCategoryUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ArticleCategoryUpsertLocalizationInput>>
}

export type ArticleCategoryUpdateManyInlineInput = {
  /** Connect multiple existing ArticleCategory documents */
  connect?: InputMaybe<Array<ArticleCategoryConnectInput>>
  /** Create and connect multiple ArticleCategory documents */
  create?: InputMaybe<Array<ArticleCategoryCreateInput>>
  /** Delete multiple ArticleCategory documents */
  delete?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>
  /** Disconnect multiple ArticleCategory documents */
  disconnect?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ArticleCategory documents */
  set?: InputMaybe<Array<ArticleCategoryWhereUniqueInput>>
  /** Update multiple ArticleCategory documents */
  update?: InputMaybe<Array<ArticleCategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ArticleCategory documents */
  upsert?: InputMaybe<Array<ArticleCategoryUpsertWithNestedWhereUniqueInput>>
}

export type ArticleCategoryUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleCategoryUpdateManyLocalizationsInput>
}

export type ArticleCategoryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCategoryUpdateManyLocalizationInput = {
  data: ArticleCategoryUpdateManyLocalizationDataInput
  locale: Locale
}

export type ArticleCategoryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleCategoryUpdateManyLocalizationInput>>
}

export type ArticleCategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleCategoryUpdateManyInput
  /** Document search */
  where: ArticleCategoryWhereInput
}

export type ArticleCategoryUpdateOneInlineInput = {
  /** Connect existing ArticleCategory document */
  connect?: InputMaybe<ArticleCategoryWhereUniqueInput>
  /** Create and connect one ArticleCategory document */
  create?: InputMaybe<ArticleCategoryCreateInput>
  /** Delete currently connected ArticleCategory document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ArticleCategory document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticleCategory document */
  update?: InputMaybe<ArticleCategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticleCategory document */
  upsert?: InputMaybe<ArticleCategoryUpsertWithNestedWhereUniqueInput>
}

export type ArticleCategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleCategoryUpdateInput
  /** Unique document search */
  where: ArticleCategoryWhereUniqueInput
}

export type ArticleCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCategoryCreateInput
  /** Update document if it exists */
  update: ArticleCategoryUpdateInput
}

export type ArticleCategoryUpsertLocalizationInput = {
  create: ArticleCategoryCreateLocalizationDataInput
  locale: Locale
  update: ArticleCategoryUpdateLocalizationDataInput
}

export type ArticleCategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleCategoryUpsertInput
  /** Unique document search */
  where: ArticleCategoryWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ArticleCategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ArticleCategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<ArticleCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<ArticleCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<ArticleCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleCategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCategoryWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCategoryWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCategoryWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleCategoryWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ArticleCategory record uniquely */
export type ArticleCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type ArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ArticleWhereUniqueInput
}

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ArticleEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ArticleCreateInput = {
  articleCategories?: InputMaybe<ArticleCategoryCreateManyInlineInput>
  articlePageViewCount?: InputMaybe<ArticlePageViewCreateOneInlineInput>
  articleType: ArticleType
  authors?: InputMaybe<AuthorCreateManyInlineInput>
  authorsEn?: InputMaybe<AuthorCreateManyInlineInput>
  /** body input for default locale (de) */
  body: Scalars['RichTextAST']['input']
  callToAction?: InputMaybe<ArticleCtaCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  featuredMedia: AssetCreateOneInlineInput
  hideNewsletterBox?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCreateLocalizationsInput>
  onlyEnglish: Scalars['Boolean']['input']
  pageViews: Scalars['Int']['input']
  publishDateTime: Scalars['DateTime']['input']
  reviewsAgency?: InputMaybe<ArticleReviewsAgencyCreateManyInlineInput>
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryCreateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
  /** slug input for default locale (de) */
  slug: Scalars['String']['input']
  /** snippet input for default locale (de) */
  snippet: Scalars['String']['input']
  sponsored?: InputMaybe<Scalars['Boolean']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagCreateManyInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  vgWort?: InputMaybe<VgWortCreateOneInlineInput>
}

export type ArticleCreateLocalizationDataInput = {
  body: Scalars['RichTextAST']['input']
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  slug: Scalars['String']['input']
  snippet: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ArticleCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCreateLocalizationDataInput
  locale: Locale
}

export type ArticleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>
}

export type ArticleCreateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>
  /** Create and connect multiple existing Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>
}

export type ArticleCreateOneInlineInput = {
  /** Connect one existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>
}

/** Simple button call to action placed inside article content */
export type ArticleCta = Entity & {
  __typename?: 'ArticleCta'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<ArticleCta>
  /** Text displayed on the button */
  name: Scalars['String']['output']
  openInNewTab?: Maybe<Scalars['Boolean']['output']>
  position: ElementPosition
  /** System stage field */
  stage: Stage
  url: Scalars['String']['output']
}

/** Simple button call to action placed inside article content */
export type ArticleCtaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ArticleCtaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ArticleCtaWhereUniqueInput
}

/** A connection to a list of items. */
export type ArticleCtaConnection = {
  __typename?: 'ArticleCtaConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ArticleCtaEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ArticleCtaCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCtaCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
  position: ElementPosition
  url: Scalars['String']['input']
}

export type ArticleCtaCreateLocalizationDataInput = {
  name: Scalars['String']['input']
}

export type ArticleCtaCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCtaCreateLocalizationDataInput
  locale: Locale
}

export type ArticleCtaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCtaCreateLocalizationInput>>
}

export type ArticleCtaCreateManyInlineInput = {
  /** Create and connect multiple existing ArticleCta documents */
  create?: InputMaybe<Array<ArticleCtaCreateInput>>
}

export type ArticleCtaCreateOneInlineInput = {
  /** Create and connect one ArticleCta document */
  create?: InputMaybe<ArticleCtaCreateInput>
}

export type ArticleCtaCreateWithPositionInput = {
  /** Document to create */
  data: ArticleCtaCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ArticleCtaEdge = {
  __typename?: 'ArticleCtaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ArticleCta
}

/** Identifies documents */
export type ArticleCtaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum ArticleCtaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OpenInNewTabAsc = 'openInNewTab_ASC',
  OpenInNewTabDesc = 'openInNewTab_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type ArticleCtaParent = Article

export type ArticleCtaParentConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>
}

export type ArticleCtaParentCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>
}

export type ArticleCtaParentCreateManyInlineInput = {
  /** Connect multiple existing ArticleCtaParent documents */
  connect?: InputMaybe<Array<ArticleCtaParentWhereUniqueInput>>
  /** Create and connect multiple existing ArticleCtaParent documents */
  create?: InputMaybe<Array<ArticleCtaParentCreateInput>>
}

export type ArticleCtaParentCreateOneInlineInput = {
  /** Connect one existing ArticleCtaParent document */
  connect?: InputMaybe<ArticleCtaParentWhereUniqueInput>
  /** Create and connect one ArticleCtaParent document */
  create?: InputMaybe<ArticleCtaParentCreateInput>
}

export type ArticleCtaParentUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>
}

export type ArticleCtaParentUpdateManyInlineInput = {
  /** Connect multiple existing ArticleCtaParent documents */
  connect?: InputMaybe<Array<ArticleCtaParentConnectInput>>
  /** Create and connect multiple ArticleCtaParent documents */
  create?: InputMaybe<Array<ArticleCtaParentCreateInput>>
  /** Delete multiple ArticleCtaParent documents */
  delete?: InputMaybe<Array<ArticleCtaParentWhereUniqueInput>>
  /** Disconnect multiple ArticleCtaParent documents */
  disconnect?: InputMaybe<Array<ArticleCtaParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ArticleCtaParent documents */
  set?: InputMaybe<Array<ArticleCtaParentWhereUniqueInput>>
  /** Update multiple ArticleCtaParent documents */
  update?: InputMaybe<Array<ArticleCtaParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ArticleCtaParent documents */
  upsert?: InputMaybe<Array<ArticleCtaParentUpsertWithNestedWhereUniqueInput>>
}

export type ArticleCtaParentUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>
}

export type ArticleCtaParentUpdateOneInlineInput = {
  /** Connect existing ArticleCtaParent document */
  connect?: InputMaybe<ArticleCtaParentWhereUniqueInput>
  /** Create and connect one ArticleCtaParent document */
  create?: InputMaybe<ArticleCtaParentCreateInput>
  /** Delete currently connected ArticleCtaParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ArticleCtaParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticleCtaParent document */
  update?: InputMaybe<ArticleCtaParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticleCtaParent document */
  upsert?: InputMaybe<ArticleCtaParentUpsertWithNestedWhereUniqueInput>
}

export type ArticleCtaParentUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>
}

export type ArticleCtaParentUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>
}

export type ArticleCtaParentWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>
}

export type ArticleCtaParentWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>
}

export type ArticleCtaUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleCtaUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<ElementPosition>
  url?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCtaUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCtaUpdateLocalizationInput = {
  data: ArticleCtaUpdateLocalizationDataInput
  locale: Locale
}

export type ArticleCtaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCtaCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleCtaUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ArticleCtaUpsertLocalizationInput>>
}

export type ArticleCtaUpdateManyInlineInput = {
  /** Create and connect multiple ArticleCta component instances */
  create?: InputMaybe<Array<ArticleCtaCreateWithPositionInput>>
  /** Delete multiple ArticleCta documents */
  delete?: InputMaybe<Array<ArticleCtaWhereUniqueInput>>
  /** Update multiple ArticleCta component instances */
  update?: InputMaybe<Array<ArticleCtaUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ArticleCta component instances */
  upsert?: InputMaybe<Array<ArticleCtaUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ArticleCtaUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleCtaUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<ElementPosition>
  url?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCtaUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type ArticleCtaUpdateManyLocalizationInput = {
  data: ArticleCtaUpdateManyLocalizationDataInput
  locale: Locale
}

export type ArticleCtaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleCtaUpdateManyLocalizationInput>>
}

export type ArticleCtaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleCtaUpdateManyInput
  /** Document search */
  where: ArticleCtaWhereInput
}

export type ArticleCtaUpdateOneInlineInput = {
  /** Create and connect one ArticleCta document */
  create?: InputMaybe<ArticleCtaCreateInput>
  /** Delete currently connected ArticleCta document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticleCta document */
  update?: InputMaybe<ArticleCtaUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticleCta document */
  upsert?: InputMaybe<ArticleCtaUpsertWithNestedWhereUniqueInput>
}

export type ArticleCtaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ArticleCtaUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ArticleCtaWhereUniqueInput
}

export type ArticleCtaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleCtaUpdateInput
  /** Unique document search */
  where: ArticleCtaWhereUniqueInput
}

export type ArticleCtaUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCtaCreateInput
  /** Update document if it exists */
  update: ArticleCtaUpdateInput
}

export type ArticleCtaUpsertLocalizationInput = {
  create: ArticleCtaCreateLocalizationDataInput
  locale: Locale
  update: ArticleCtaUpdateLocalizationDataInput
}

export type ArticleCtaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ArticleCtaUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ArticleCtaWhereUniqueInput
}

export type ArticleCtaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleCtaUpsertInput
  /** Unique document search */
  where: ArticleCtaWhereUniqueInput
}

/** Identifies documents */
export type ArticleCtaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  openInNewTab_not?: InputMaybe<Scalars['Boolean']['input']>
  position?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References ArticleCta record uniquely */
export type ArticleCtaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Article
}

/** Identifies documents */
export type ArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articleCategories_every?: InputMaybe<ArticleCategoryWhereInput>
  articleCategories_none?: InputMaybe<ArticleCategoryWhereInput>
  articleCategories_some?: InputMaybe<ArticleCategoryWhereInput>
  articleType?: InputMaybe<ArticleType>
  /** All values that are contained in given list. */
  articleType_in?: InputMaybe<Array<InputMaybe<ArticleType>>>
  /** Any other value that exists and is not equal to the given value. */
  articleType_not?: InputMaybe<ArticleType>
  /** All values that are not contained in given list. */
  articleType_not_in?: InputMaybe<Array<InputMaybe<ArticleType>>>
  authorsEn_every?: InputMaybe<AuthorWhereInput>
  authorsEn_none?: InputMaybe<AuthorWhereInput>
  authorsEn_some?: InputMaybe<AuthorWhereInput>
  authors_every?: InputMaybe<AuthorWhereInput>
  authors_none?: InputMaybe<AuthorWhereInput>
  authors_some?: InputMaybe<AuthorWhereInput>
  callToAction_every?: InputMaybe<ArticleCtaWhereInput>
  callToAction_none?: InputMaybe<ArticleCtaWhereInput>
  callToAction_some?: InputMaybe<ArticleCtaWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>
  featuredMedia?: InputMaybe<AssetWhereInput>
  hideNewsletterBox?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideNewsletterBox_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  onlyEnglish?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  onlyEnglish_not?: InputMaybe<Scalars['Boolean']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageViews_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageViews_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageViews_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageViews_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageViews_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageViews_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageViews_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** All values in which the union is empty */
  reviewsAgency_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  reviewsAgency_some?: InputMaybe<ArticleReviewsAgencyWhereInput>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showTableOfContents_not?: InputMaybe<Scalars['Boolean']['input']>
  sponsored?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  sponsored_not?: InputMaybe<Scalars['Boolean']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  vgWort?: InputMaybe<VgWortWhereInput>
}

export enum ArticleOrderByInput {
  ArticleTypeAsc = 'articleType_ASC',
  ArticleTypeDesc = 'articleType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HideNewsletterBoxAsc = 'hideNewsletterBox_ASC',
  HideNewsletterBoxDesc = 'hideNewsletterBox_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OnlyEnglishAsc = 'onlyEnglish_ASC',
  OnlyEnglishDesc = 'onlyEnglish_DESC',
  PageViewsAsc = 'pageViews_ASC',
  PageViewsDesc = 'pageViews_DESC',
  PublishDateTimeAsc = 'publishDateTime_ASC',
  PublishDateTimeDesc = 'publishDateTime_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowTableOfContentsAsc = 'showTableOfContents_ASC',
  ShowTableOfContentsDesc = 'showTableOfContents_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SnippetAsc = 'snippet_ASC',
  SnippetDesc = 'snippet_DESC',
  SponsoredAsc = 'sponsored_ASC',
  SponsoredDesc = 'sponsored_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Article pageViews in the last 30 days */
export type ArticlePageView = Entity &
  Node & {
    __typename?: 'ArticlePageView'
    article: Article
    /** Slug of the article. Required for upserting data. */
    articleSlug: Scalars['String']['output']
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<ArticlePageView>
    /** List of ArticlePageView versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Locale of the page view. */
    pageViewLocale: Scalars['String']['output']
    /** Number of page views in the last 30 days based on Google Analytics */
    pageViews: Scalars['Int']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Article pageViews in the last 30 days */
export type ArticlePageViewArticleArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Article pageViews in the last 30 days */
export type ArticlePageViewUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticlePageViewConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ArticlePageViewWhereUniqueInput
}

/** A connection to a list of items. */
export type ArticlePageViewConnection = {
  __typename?: 'ArticlePageViewConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ArticlePageViewEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ArticlePageViewCreateInput = {
  article: ArticleCreateOneInlineInput
  articleSlug: Scalars['String']['input']
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  pageViewLocale: Scalars['String']['input']
  pageViews: Scalars['Int']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ArticlePageViewCreateManyInlineInput = {
  /** Connect multiple existing ArticlePageView documents */
  connect?: InputMaybe<Array<ArticlePageViewWhereUniqueInput>>
  /** Create and connect multiple existing ArticlePageView documents */
  create?: InputMaybe<Array<ArticlePageViewCreateInput>>
}

export type ArticlePageViewCreateOneInlineInput = {
  /** Connect one existing ArticlePageView document */
  connect?: InputMaybe<ArticlePageViewWhereUniqueInput>
  /** Create and connect one ArticlePageView document */
  create?: InputMaybe<ArticlePageViewCreateInput>
}

/** An edge in a connection. */
export type ArticlePageViewEdge = {
  __typename?: 'ArticlePageViewEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ArticlePageView
}

/** Identifies documents */
export type ArticlePageViewManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article?: InputMaybe<ArticleWhereInput>
  articleSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  articleSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  articleSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  articleSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  articleSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  articleSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  articleSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  articleSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  articleSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  articleSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ArticlePageViewWhereStageInput>
  documentInStages_none?: InputMaybe<ArticlePageViewWhereStageInput>
  documentInStages_some?: InputMaybe<ArticlePageViewWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  pageViewLocale?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageViewLocale_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageViewLocale_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageViewLocale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageViewLocale_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageViewLocale_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageViewLocale_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageViewLocale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageViewLocale_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageViewLocale_starts_with?: InputMaybe<Scalars['String']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageViews_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageViews_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageViews_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageViews_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageViews_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageViews_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageViews_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ArticlePageViewOrderByInput {
  ArticleSlugAsc = 'articleSlug_ASC',
  ArticleSlugDesc = 'articleSlug_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PageViewLocaleAsc = 'pageViewLocale_ASC',
  PageViewLocaleDesc = 'pageViewLocale_DESC',
  PageViewsAsc = 'pageViews_ASC',
  PageViewsDesc = 'pageViews_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ArticlePageViewUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneInlineInput>
  articleSlug?: InputMaybe<Scalars['String']['input']>
  pageViewLocale?: InputMaybe<Scalars['String']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
}

export type ArticlePageViewUpdateManyInlineInput = {
  /** Connect multiple existing ArticlePageView documents */
  connect?: InputMaybe<Array<ArticlePageViewConnectInput>>
  /** Create and connect multiple ArticlePageView documents */
  create?: InputMaybe<Array<ArticlePageViewCreateInput>>
  /** Delete multiple ArticlePageView documents */
  delete?: InputMaybe<Array<ArticlePageViewWhereUniqueInput>>
  /** Disconnect multiple ArticlePageView documents */
  disconnect?: InputMaybe<Array<ArticlePageViewWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ArticlePageView documents */
  set?: InputMaybe<Array<ArticlePageViewWhereUniqueInput>>
  /** Update multiple ArticlePageView documents */
  update?: InputMaybe<Array<ArticlePageViewUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ArticlePageView documents */
  upsert?: InputMaybe<Array<ArticlePageViewUpsertWithNestedWhereUniqueInput>>
}

export type ArticlePageViewUpdateManyInput = {
  pageViewLocale?: InputMaybe<Scalars['String']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
}

export type ArticlePageViewUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticlePageViewUpdateManyInput
  /** Document search */
  where: ArticlePageViewWhereInput
}

export type ArticlePageViewUpdateOneInlineInput = {
  /** Connect existing ArticlePageView document */
  connect?: InputMaybe<ArticlePageViewWhereUniqueInput>
  /** Create and connect one ArticlePageView document */
  create?: InputMaybe<ArticlePageViewCreateInput>
  /** Delete currently connected ArticlePageView document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ArticlePageView document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticlePageView document */
  update?: InputMaybe<ArticlePageViewUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticlePageView document */
  upsert?: InputMaybe<ArticlePageViewUpsertWithNestedWhereUniqueInput>
}

export type ArticlePageViewUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticlePageViewUpdateInput
  /** Unique document search */
  where: ArticlePageViewWhereUniqueInput
}

export type ArticlePageViewUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticlePageViewCreateInput
  /** Update document if it exists */
  update: ArticlePageViewUpdateInput
}

export type ArticlePageViewUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticlePageViewUpsertInput
  /** Unique document search */
  where: ArticlePageViewWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ArticlePageViewWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ArticlePageViewWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticlePageViewWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article?: InputMaybe<ArticleWhereInput>
  articleSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  articleSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  articleSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  articleSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  articleSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  articleSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  articleSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  articleSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  articleSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  articleSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ArticlePageViewWhereStageInput>
  documentInStages_none?: InputMaybe<ArticlePageViewWhereStageInput>
  documentInStages_some?: InputMaybe<ArticlePageViewWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  pageViewLocale?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageViewLocale_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageViewLocale_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageViewLocale_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageViewLocale_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageViewLocale_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageViewLocale_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageViewLocale_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageViewLocale_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageViewLocale_starts_with?: InputMaybe<Scalars['String']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageViews_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageViews_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageViews_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageViews_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageViews_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageViews_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageViews_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticlePageViewWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticlePageViewWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticlePageViewWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticlePageViewWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticlePageViewWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ArticlePageView record uniquely */
export type ArticlePageViewWhereUniqueInput = {
  articleSlug?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ArticleReviewsAgency = ReviewsAgency

export type ArticleReviewsAgencyConnectInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyConnectInput>
}

export type ArticleReviewsAgencyCreateInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyCreateInput>
}

export type ArticleReviewsAgencyCreateManyInlineInput = {
  /** Connect multiple existing ArticleReviewsAgency documents */
  connect?: InputMaybe<Array<ArticleReviewsAgencyWhereUniqueInput>>
  /** Create and connect multiple existing ArticleReviewsAgency documents */
  create?: InputMaybe<Array<ArticleReviewsAgencyCreateInput>>
}

export type ArticleReviewsAgencyCreateOneInlineInput = {
  /** Connect one existing ArticleReviewsAgency document */
  connect?: InputMaybe<ArticleReviewsAgencyWhereUniqueInput>
  /** Create and connect one ArticleReviewsAgency document */
  create?: InputMaybe<ArticleReviewsAgencyCreateInput>
}

export type ArticleReviewsAgencyUpdateInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyUpdateInput>
}

export type ArticleReviewsAgencyUpdateManyInlineInput = {
  /** Connect multiple existing ArticleReviewsAgency documents */
  connect?: InputMaybe<Array<ArticleReviewsAgencyConnectInput>>
  /** Create and connect multiple ArticleReviewsAgency documents */
  create?: InputMaybe<Array<ArticleReviewsAgencyCreateInput>>
  /** Delete multiple ArticleReviewsAgency documents */
  delete?: InputMaybe<Array<ArticleReviewsAgencyWhereUniqueInput>>
  /** Disconnect multiple ArticleReviewsAgency documents */
  disconnect?: InputMaybe<Array<ArticleReviewsAgencyWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ArticleReviewsAgency documents */
  set?: InputMaybe<Array<ArticleReviewsAgencyWhereUniqueInput>>
  /** Update multiple ArticleReviewsAgency documents */
  update?: InputMaybe<Array<ArticleReviewsAgencyUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ArticleReviewsAgency documents */
  upsert?: InputMaybe<Array<ArticleReviewsAgencyUpsertWithNestedWhereUniqueInput>>
}

export type ArticleReviewsAgencyUpdateManyWithNestedWhereInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyUpdateManyWithNestedWhereInput>
}

export type ArticleReviewsAgencyUpdateOneInlineInput = {
  /** Connect existing ArticleReviewsAgency document */
  connect?: InputMaybe<ArticleReviewsAgencyWhereUniqueInput>
  /** Create and connect one ArticleReviewsAgency document */
  create?: InputMaybe<ArticleReviewsAgencyCreateInput>
  /** Delete currently connected ArticleReviewsAgency document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ArticleReviewsAgency document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticleReviewsAgency document */
  update?: InputMaybe<ArticleReviewsAgencyUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticleReviewsAgency document */
  upsert?: InputMaybe<ArticleReviewsAgencyUpsertWithNestedWhereUniqueInput>
}

export type ArticleReviewsAgencyUpdateWithNestedWhereUniqueInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyUpdateWithNestedWhereUniqueInput>
}

export type ArticleReviewsAgencyUpsertWithNestedWhereUniqueInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyUpsertWithNestedWhereUniqueInput>
}

export type ArticleReviewsAgencyWhereInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyWhereInput>
}

export type ArticleReviewsAgencyWhereUniqueInput = {
  ReviewsAgency?: InputMaybe<ReviewsAgencyWhereUniqueInput>
}

export enum ArticleType {
  ContentHubArticle = 'ContentHubArticle',
  DailyArticle = 'DailyArticle',
  EducationArticle = 'EducationArticle',
  JobsHrArticle = 'JobsHrArticle',
  OmrxArticle = 'OmrxArticle',
}

export type ArticleUpdateInput = {
  articleCategories?: InputMaybe<ArticleCategoryUpdateManyInlineInput>
  articlePageViewCount?: InputMaybe<ArticlePageViewUpdateOneInlineInput>
  articleType?: InputMaybe<ArticleType>
  authors?: InputMaybe<AuthorUpdateManyInlineInput>
  authorsEn?: InputMaybe<AuthorUpdateManyInlineInput>
  /** body input for default locale (de) */
  body?: InputMaybe<Scalars['RichTextAST']['input']>
  callToAction?: InputMaybe<ArticleCtaUpdateManyInlineInput>
  featuredMedia?: InputMaybe<AssetUpdateOneInlineInput>
  hideNewsletterBox?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleUpdateLocalizationsInput>
  onlyEnglish?: InputMaybe<Scalars['Boolean']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  reviewsAgency?: InputMaybe<ArticleReviewsAgencyUpdateManyInlineInput>
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryUpdateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>
  /** snippet input for default locale (de) */
  snippet?: InputMaybe<Scalars['String']['input']>
  sponsored?: InputMaybe<Scalars['Boolean']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  vgWort?: InputMaybe<VgWortUpdateOneInlineInput>
}

export type ArticleUpdateLocalizationDataInput = {
  body?: InputMaybe<Scalars['RichTextAST']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  snippet?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticleUpdateLocalizationInput = {
  data: ArticleUpdateLocalizationDataInput
  locale: Locale
}

export type ArticleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ArticleUpsertLocalizationInput>>
}

export type ArticleUpdateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleConnectInput>>
  /** Create and connect multiple Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>
  /** Delete multiple Article documents */
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>
  /** Disconnect multiple Article documents */
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Article documents */
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>
  /** Update multiple Article documents */
  update?: InputMaybe<Array<ArticleUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Article documents */
  upsert?: InputMaybe<Array<ArticleUpsertWithNestedWhereUniqueInput>>
}

export type ArticleUpdateManyInput = {
  articleType?: InputMaybe<ArticleType>
  /** body input for default locale (de) */
  body?: InputMaybe<Scalars['RichTextAST']['input']>
  hideNewsletterBox?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleUpdateManyLocalizationsInput>
  onlyEnglish?: InputMaybe<Scalars['Boolean']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
  /** snippet input for default locale (de) */
  snippet?: InputMaybe<Scalars['String']['input']>
  sponsored?: InputMaybe<Scalars['Boolean']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticleUpdateManyLocalizationDataInput = {
  body?: InputMaybe<Scalars['RichTextAST']['input']>
  snippet?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticleUpdateManyLocalizationInput = {
  data: ArticleUpdateManyLocalizationDataInput
  locale: Locale
}

export type ArticleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateManyLocalizationInput>>
}

export type ArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleUpdateManyInput
  /** Document search */
  where: ArticleWhereInput
}

export type ArticleUpdateOneInlineInput = {
  /** Connect existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>
  /** Delete currently connected Article document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Article document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Article document */
  update?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>
  /** Upsert single Article document */
  upsert?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>
}

export type ArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleUpdateInput
  /** Unique document search */
  where: ArticleWhereUniqueInput
}

export type ArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCreateInput
  /** Update document if it exists */
  update: ArticleUpdateInput
}

export type ArticleUpsertLocalizationInput = {
  create: ArticleCreateLocalizationDataInput
  locale: Locale
  update: ArticleUpdateLocalizationDataInput
}

export type ArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleUpsertInput
  /** Unique document search */
  where: ArticleWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ArticleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articleCategories_every?: InputMaybe<ArticleCategoryWhereInput>
  articleCategories_none?: InputMaybe<ArticleCategoryWhereInput>
  articleCategories_some?: InputMaybe<ArticleCategoryWhereInput>
  articleType?: InputMaybe<ArticleType>
  /** All values that are contained in given list. */
  articleType_in?: InputMaybe<Array<InputMaybe<ArticleType>>>
  /** Any other value that exists and is not equal to the given value. */
  articleType_not?: InputMaybe<ArticleType>
  /** All values that are not contained in given list. */
  articleType_not_in?: InputMaybe<Array<InputMaybe<ArticleType>>>
  authorsEn_every?: InputMaybe<AuthorWhereInput>
  authorsEn_none?: InputMaybe<AuthorWhereInput>
  authorsEn_some?: InputMaybe<AuthorWhereInput>
  authors_every?: InputMaybe<AuthorWhereInput>
  authors_none?: InputMaybe<AuthorWhereInput>
  authors_some?: InputMaybe<AuthorWhereInput>
  callToAction_every?: InputMaybe<ArticleCtaWhereInput>
  callToAction_none?: InputMaybe<ArticleCtaWhereInput>
  callToAction_some?: InputMaybe<ArticleCtaWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>
  featuredMedia?: InputMaybe<AssetWhereInput>
  hideNewsletterBox?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideNewsletterBox_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  onlyEnglish?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  onlyEnglish_not?: InputMaybe<Scalars['Boolean']['input']>
  pageViews?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageViews_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageViews_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageViews_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageViews_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageViews_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageViews_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageViews_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** All values in which the union is empty */
  reviewsAgency_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  reviewsAgency_some?: InputMaybe<ArticleReviewsAgencyWhereInput>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  showTableOfContents?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showTableOfContents_not?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  snippet?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  snippet_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  snippet_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  snippet_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  snippet_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  snippet_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  snippet_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  snippet_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  snippet_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  snippet_starts_with?: InputMaybe<Scalars['String']['input']>
  sponsored?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  sponsored_not?: InputMaybe<Scalars['Boolean']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  vgWort?: InputMaybe<VgWortWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Article record uniquely */
export type ArticleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ArticlesByTag = Entity & {
  __typename?: 'ArticlesByTag'
  category?: Maybe<ArticleCategory>
  ctaText?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Leave empty for unlimited */
  limit?: Maybe<Scalars['Int']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<ArticlesByTag>
  /** System stage field */
  stage: Stage
  tag?: Maybe<Tag>
  title: Scalars['String']['output']
}

export type ArticlesByTagCategoryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticlesByTagLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ArticlesByTagTagArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ArticlesByTagConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ArticlesByTagWhereUniqueInput
}

/** A connection to a list of items. */
export type ArticlesByTagConnection = {
  __typename?: 'ArticlesByTagConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ArticlesByTagEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ArticlesByTagCreateInput = {
  category?: InputMaybe<ArticleCategoryCreateOneInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticlesByTagCreateLocalizationsInput>
  tag?: InputMaybe<TagCreateOneInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type ArticlesByTagCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type ArticlesByTagCreateLocalizationInput = {
  /** Localization input */
  data: ArticlesByTagCreateLocalizationDataInput
  locale: Locale
}

export type ArticlesByTagCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticlesByTagCreateLocalizationInput>>
}

export type ArticlesByTagCreateManyInlineInput = {
  /** Create and connect multiple existing ArticlesByTag documents */
  create?: InputMaybe<Array<ArticlesByTagCreateInput>>
}

export type ArticlesByTagCreateOneInlineInput = {
  /** Create and connect one ArticlesByTag document */
  create?: InputMaybe<ArticlesByTagCreateInput>
}

export type ArticlesByTagCreateWithPositionInput = {
  /** Document to create */
  data: ArticlesByTagCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ArticlesByTagEdge = {
  __typename?: 'ArticlesByTagEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ArticlesByTag
}

/** Identifies documents */
export type ArticlesByTagManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<ArticleCategoryWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  limit_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  limit_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  limit_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  limit_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  limit_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  limit_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  limit_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  tag?: InputMaybe<TagWhereInput>
}

export enum ArticlesByTagOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LimitAsc = 'limit_ASC',
  LimitDesc = 'limit_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ArticlesByTagParent = EventPage

export type ArticlesByTagParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type ArticlesByTagParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type ArticlesByTagParentCreateManyInlineInput = {
  /** Connect multiple existing ArticlesByTagParent documents */
  connect?: InputMaybe<Array<ArticlesByTagParentWhereUniqueInput>>
  /** Create and connect multiple existing ArticlesByTagParent documents */
  create?: InputMaybe<Array<ArticlesByTagParentCreateInput>>
}

export type ArticlesByTagParentCreateOneInlineInput = {
  /** Connect one existing ArticlesByTagParent document */
  connect?: InputMaybe<ArticlesByTagParentWhereUniqueInput>
  /** Create and connect one ArticlesByTagParent document */
  create?: InputMaybe<ArticlesByTagParentCreateInput>
}

export type ArticlesByTagParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type ArticlesByTagParentUpdateManyInlineInput = {
  /** Connect multiple existing ArticlesByTagParent documents */
  connect?: InputMaybe<Array<ArticlesByTagParentConnectInput>>
  /** Create and connect multiple ArticlesByTagParent documents */
  create?: InputMaybe<Array<ArticlesByTagParentCreateInput>>
  /** Delete multiple ArticlesByTagParent documents */
  delete?: InputMaybe<Array<ArticlesByTagParentWhereUniqueInput>>
  /** Disconnect multiple ArticlesByTagParent documents */
  disconnect?: InputMaybe<Array<ArticlesByTagParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ArticlesByTagParent documents */
  set?: InputMaybe<Array<ArticlesByTagParentWhereUniqueInput>>
  /** Update multiple ArticlesByTagParent documents */
  update?: InputMaybe<Array<ArticlesByTagParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ArticlesByTagParent documents */
  upsert?: InputMaybe<Array<ArticlesByTagParentUpsertWithNestedWhereUniqueInput>>
}

export type ArticlesByTagParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type ArticlesByTagParentUpdateOneInlineInput = {
  /** Connect existing ArticlesByTagParent document */
  connect?: InputMaybe<ArticlesByTagParentWhereUniqueInput>
  /** Create and connect one ArticlesByTagParent document */
  create?: InputMaybe<ArticlesByTagParentCreateInput>
  /** Delete currently connected ArticlesByTagParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ArticlesByTagParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticlesByTagParent document */
  update?: InputMaybe<ArticlesByTagParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticlesByTagParent document */
  upsert?: InputMaybe<ArticlesByTagParentUpsertWithNestedWhereUniqueInput>
}

export type ArticlesByTagParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type ArticlesByTagParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type ArticlesByTagParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type ArticlesByTagParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type ArticlesByTagUpdateInput = {
  category?: InputMaybe<ArticleCategoryUpdateOneInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ArticlesByTagUpdateLocalizationsInput>
  tag?: InputMaybe<TagUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticlesByTagUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticlesByTagUpdateLocalizationInput = {
  data: ArticlesByTagUpdateLocalizationDataInput
  locale: Locale
}

export type ArticlesByTagUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticlesByTagCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ArticlesByTagUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ArticlesByTagUpsertLocalizationInput>>
}

export type ArticlesByTagUpdateManyInlineInput = {
  /** Create and connect multiple ArticlesByTag component instances */
  create?: InputMaybe<Array<ArticlesByTagCreateWithPositionInput>>
  /** Delete multiple ArticlesByTag documents */
  delete?: InputMaybe<Array<ArticlesByTagWhereUniqueInput>>
  /** Update multiple ArticlesByTag component instances */
  update?: InputMaybe<Array<ArticlesByTagUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ArticlesByTag component instances */
  upsert?: InputMaybe<Array<ArticlesByTagUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ArticlesByTagUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticlesByTagUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticlesByTagUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ArticlesByTagUpdateManyLocalizationInput = {
  data: ArticlesByTagUpdateManyLocalizationDataInput
  locale: Locale
}

export type ArticlesByTagUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticlesByTagUpdateManyLocalizationInput>>
}

export type ArticlesByTagUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticlesByTagUpdateManyInput
  /** Document search */
  where: ArticlesByTagWhereInput
}

export type ArticlesByTagUpdateOneInlineInput = {
  /** Create and connect one ArticlesByTag document */
  create?: InputMaybe<ArticlesByTagCreateInput>
  /** Delete currently connected ArticlesByTag document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ArticlesByTag document */
  update?: InputMaybe<ArticlesByTagUpdateWithNestedWhereUniqueInput>
  /** Upsert single ArticlesByTag document */
  upsert?: InputMaybe<ArticlesByTagUpsertWithNestedWhereUniqueInput>
}

export type ArticlesByTagUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ArticlesByTagUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ArticlesByTagWhereUniqueInput
}

export type ArticlesByTagUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticlesByTagUpdateInput
  /** Unique document search */
  where: ArticlesByTagWhereUniqueInput
}

export type ArticlesByTagUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticlesByTagCreateInput
  /** Update document if it exists */
  update: ArticlesByTagUpdateInput
}

export type ArticlesByTagUpsertLocalizationInput = {
  create: ArticlesByTagCreateLocalizationDataInput
  locale: Locale
  update: ArticlesByTagUpdateLocalizationDataInput
}

export type ArticlesByTagUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ArticlesByTagUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ArticlesByTagWhereUniqueInput
}

export type ArticlesByTagUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticlesByTagUpsertInput
  /** Unique document search */
  where: ArticlesByTagWhereUniqueInput
}

/** Identifies documents */
export type ArticlesByTagWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticlesByTagWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<ArticleCategoryWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  limit_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  limit_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  limit_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  limit_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  limit_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  limit_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  limit_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  tag?: InputMaybe<TagWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References ArticlesByTag record uniquely */
export type ArticlesByTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Asset system model */
export type Asset = Entity &
  Node & {
    __typename?: 'Asset'
    backgroundImagePartnerCompany: Array<PartnerCompany>
    contentReferenceCoverImage: Array<ContentReference>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Asset description for end users */
    description?: Maybe<Scalars['String']['output']>
    /** URL to open on image click */
    destinationUrl?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<Asset>
    eventFeaturedImage: Array<Event>
    featuredImageArticle: Array<Article>
    /** The file name */
    fileName: Scalars['String']['output']
    /** The file handle */
    handle: Scalars['String']['output']
    headerLogo?: Maybe<Header>
    headerMobileLogo?: Maybe<Header>
    /** The height of the file */
    height?: Maybe<Scalars['Float']['output']>
    heroEventAppPageImage: Array<EventAppPage>
    /** List of Asset versions */
    history: Array<Version>
    hotelLogo?: Maybe<Hotel>
    /** The unique identifier */
    id: Scalars['ID']['output']
    jobImage: Array<JobListing>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Asset>
    logoPartnerCompany: Array<PartnerCompany>
    /** The mime type of the file */
    mimeType?: Maybe<Scalars['String']['output']>
    omrPartnerLogo?: Maybe<OmrPartner>
    podcastGroupBackgroundImage: Array<PodcastGroup>
    podcastGroupMobileBackgroundImage: Array<PodcastGroup>
    profilePhotoAuthor: Array<Author>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** The file size */
    size?: Maybe<Scalars['Float']['output']>
    /** System stage field */
    stage: Stage
    tabContentImage?: Maybe<TabContent>
    /** The required title attribute specifies an alternate text for an asset, if the asset cannot be displayed. Usually it describes shortly asset itself. */
    title?: Maybe<Scalars['String']['output']>
    topicPageThumbnailImage: Array<TopicPage>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Get the url for the asset with provided transformations applied. */
    url: Scalars['String']['output']
    /** The file width */
    width?: Maybe<Scalars['Float']['output']>
    /** wpSourceUrl */
    wpSourceUrl?: Maybe<Scalars['String']['output']>
  }

/** Asset system model */
export type AssetBackgroundImagePartnerCompanyArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

/** Asset system model */
export type AssetContentReferenceCoverImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ContentReferenceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ContentReferenceWhereInput>
}

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Asset system model */
export type AssetEventFeaturedImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

/** Asset system model */
export type AssetFeaturedImageArticleArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Asset system model */
export type AssetHeaderLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetHeaderMobileLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetHeroEventAppPageImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventAppPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppPageWhereInput>
}

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Asset system model */
export type AssetHotelLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetJobImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<JobListingOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<JobListingWhereInput>
}

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Asset system model */
export type AssetLogoPartnerCompanyArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

/** Asset system model */
export type AssetOmrPartnerLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetPodcastGroupBackgroundImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PodcastGroupOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastGroupWhereInput>
}

/** Asset system model */
export type AssetPodcastGroupMobileBackgroundImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PodcastGroupOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastGroupWhereInput>
}

/** Asset system model */
export type AssetProfilePhotoAuthorArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorWhereInput>
}

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Asset system model */
export type AssetTabContentImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetTopicPageThumbnailImageArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TopicPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageWhereInput>
}

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>
}

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: AssetWhereUniqueInput
}

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<AssetEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type AssetCreateInput = {
  appStoreButton?: InputMaybe<SectionAppCtaCreateManyInlineInput>
  background?: InputMaybe<SectionHeroCreateOneInlineInput>
  backgroundImagePartnerCompany?: InputMaybe<PartnerCompanyCreateManyInlineInput>
  banner?: InputMaybe<SectionBannerCreateOneInlineInput>
  bulletPointListImage?: InputMaybe<BulletPointListCreateOneInlineInput>
  contactPhoto?: InputMaybe<ContactCreateOneInlineInput>
  contentBackgroundLeft?: InputMaybe<SectionFramesHeroCreateManyInlineInput>
  contentBackgroundRight?: InputMaybe<SectionFramesHeroCreateManyInlineInput>
  contentReferenceCoverImage?: InputMaybe<ContentReferenceCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  ctaImageSectionCta?: InputMaybe<SectionCtaCreateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  destinationUrl?: InputMaybe<Scalars['String']['input']>
  downloadDe?: InputMaybe<DownloadCreateOneInlineInput>
  downloadEn?: InputMaybe<DownloadCreateOneInlineInput>
  eventAppCardImage?: InputMaybe<EventAppCardCreateManyInlineInput>
  eventFeaturedImage?: InputMaybe<EventCreateManyInlineInput>
  explanationStepPicture?: InputMaybe<ExplanationStepCreateOneInlineInput>
  featuredImageArticle?: InputMaybe<ArticleCreateManyInlineInput>
  festivalHeroBackground?: InputMaybe<SectionFestivalHeroCreateOneInlineInput>
  festivalHeroLogo?: InputMaybe<SectionFestivalHeroCreateOneInlineInput>
  festivalHeroMobileBackground?: InputMaybe<SectionFestivalHeroCreateOneInlineInput>
  festivalTitleDecoration?: InputMaybe<SectionFestivalHeroCreateOneInlineInput>
  fileName: Scalars['String']['input']
  genericHeroLogo?: InputMaybe<SectionFramesHeroCreateManyInlineInput>
  handle: Scalars['String']['input']
  headerLogo?: InputMaybe<HeaderCreateOneInlineInput>
  headerMobileLogo?: InputMaybe<HeaderCreateOneInlineInput>
  height?: InputMaybe<Scalars['Float']['input']>
  heroEventAppPageImage?: InputMaybe<EventAppPageCreateManyInlineInput>
  heroLogo?: InputMaybe<SectionHeroCreateOneInlineInput>
  heroMobileBackground?: InputMaybe<SectionHeroCreateOneInlineInput>
  hotelLogo?: InputMaybe<HotelCreateOneInlineInput>
  image?: InputMaybe<SlideCreateOneInlineInput>
  imageGalleryEmbed?: InputMaybe<ImageGalleryEmbedCreateManyInlineInput>
  jobImage?: InputMaybe<JobListingCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>
  logo?: InputMaybe<SectionBannerCreateOneInlineInput>
  logoPartnerCompany?: InputMaybe<PartnerCompanyCreateManyInlineInput>
  media?: InputMaybe<RichTextMediaCreateManyInlineInput>
  mimeType?: InputMaybe<Scalars['String']['input']>
  ogImageSseo?: InputMaybe<SeoCreateManyInlineInput>
  omrPartnerLogo?: InputMaybe<OmrPartnerCreateOneInlineInput>
  omrProductCardIcon?: InputMaybe<OmrProductCardCreateOneInlineInput>
  partnerLogo?: InputMaybe<SectionCtaCreateManyInlineInput>
  playStoreButton?: InputMaybe<SectionAppCtaCreateManyInlineInput>
  podcastGroupBackgroundImage?: InputMaybe<PodcastGroupCreateManyInlineInput>
  podcastGroupMobileBackgroundImage?: InputMaybe<PodcastGroupCreateManyInlineInput>
  poweredByLogo?: InputMaybe<SectionPoweredByCreateOneInlineInput>
  previewimage?: InputMaybe<DownloadCreateOneInlineInput>
  profilePhotoAuthor?: InputMaybe<AuthorCreateManyInlineInput>
  sectionAdBanner?: InputMaybe<SectionAdCreateOneInlineInput>
  sectionAdBannerMobile?: InputMaybe<SectionAdCreateOneInlineInput>
  sectionQuoteSignatureImage?: InputMaybe<SectionQuoteCreateManyInlineInput>
  sectionTestimonialImage?: InputMaybe<SectionTestimonialCreateOneInlineInput>
  sectionTestimonialSignatureImage?: InputMaybe<SectionTestimonialCreateOneInlineInput>
  singlelogo?: InputMaybe<SectionLogoFieldCreateManyInlineInput>
  size?: InputMaybe<Scalars['Float']['input']>
  socialLinkLogo?: InputMaybe<SocialLinkCreateOneInlineInput>
  stageBG?: InputMaybe<SectionStagePreviewCreateOneInlineInput>
  stagelogo?: InputMaybe<SectionStagePreviewCreateOneInlineInput>
  stickyImage?: InputMaybe<SectionStickyImageCreateOneInlineInput>
  tabContentImage?: InputMaybe<TabContentCreateOneInlineInput>
  teaserBackground?: InputMaybe<SectionTeaserCreateOneInlineInput>
  textMediaBannerDesktopAsset?: InputMaybe<TextMediaBannerCreateOneInlineInput>
  textMediaBannerMobileAsset?: InputMaybe<TextMediaBannerCreateOneInlineInput>
  textMediaImage?: InputMaybe<TextMediaCreateOneInlineInput>
  textMediaTextBanner?: InputMaybe<TextMediaCreateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<SectionHeroCreateOneInlineInput>
  topicPageThumbnailImage?: InputMaybe<TopicPageCreateManyInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  video?: InputMaybe<SectionHeroCreateOneInlineInput>
  width?: InputMaybe<Scalars['Float']['input']>
  wpSourceUrl?: InputMaybe<Scalars['String']['input']>
}

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  fileName: Scalars['String']['input']
  handle: Scalars['String']['input']
  height?: InputMaybe<Scalars['Float']['input']>
  mimeType?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  width?: InputMaybe<Scalars['Float']['input']>
}

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput
  locale: Locale
}

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>
}

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>
}

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>
}

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Asset
}

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImagePartnerCompany_every?: InputMaybe<PartnerCompanyWhereInput>
  backgroundImagePartnerCompany_none?: InputMaybe<PartnerCompanyWhereInput>
  backgroundImagePartnerCompany_some?: InputMaybe<PartnerCompanyWhereInput>
  contentReferenceCoverImage_every?: InputMaybe<ContentReferenceWhereInput>
  contentReferenceCoverImage_none?: InputMaybe<ContentReferenceWhereInput>
  contentReferenceCoverImage_some?: InputMaybe<ContentReferenceWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  destinationUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  destinationUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  destinationUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  destinationUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  destinationUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  destinationUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  destinationUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  destinationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  destinationUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  destinationUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<AssetWhereStageInput>
  documentInStages_none?: InputMaybe<AssetWhereStageInput>
  documentInStages_some?: InputMaybe<AssetWhereStageInput>
  eventFeaturedImage_every?: InputMaybe<EventWhereInput>
  eventFeaturedImage_none?: InputMaybe<EventWhereInput>
  eventFeaturedImage_some?: InputMaybe<EventWhereInput>
  featuredImageArticle_every?: InputMaybe<ArticleWhereInput>
  featuredImageArticle_none?: InputMaybe<ArticleWhereInput>
  featuredImageArticle_some?: InputMaybe<ArticleWhereInput>
  headerLogo?: InputMaybe<HeaderWhereInput>
  headerMobileLogo?: InputMaybe<HeaderWhereInput>
  heroEventAppPageImage_every?: InputMaybe<EventAppPageWhereInput>
  heroEventAppPageImage_none?: InputMaybe<EventAppPageWhereInput>
  heroEventAppPageImage_some?: InputMaybe<EventAppPageWhereInput>
  hotelLogo?: InputMaybe<HotelWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobImage_every?: InputMaybe<JobListingWhereInput>
  jobImage_none?: InputMaybe<JobListingWhereInput>
  jobImage_some?: InputMaybe<JobListingWhereInput>
  logoPartnerCompany_every?: InputMaybe<PartnerCompanyWhereInput>
  logoPartnerCompany_none?: InputMaybe<PartnerCompanyWhereInput>
  logoPartnerCompany_some?: InputMaybe<PartnerCompanyWhereInput>
  omrPartnerLogo?: InputMaybe<OmrPartnerWhereInput>
  podcastGroupBackgroundImage_every?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupBackgroundImage_none?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupBackgroundImage_some?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_every?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_none?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_some?: InputMaybe<PodcastGroupWhereInput>
  profilePhotoAuthor_every?: InputMaybe<AuthorWhereInput>
  profilePhotoAuthor_none?: InputMaybe<AuthorWhereInput>
  profilePhotoAuthor_some?: InputMaybe<AuthorWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  tabContentImage?: InputMaybe<TabContentWhereInput>
  topicPageThumbnailImage_every?: InputMaybe<TopicPageWhereInput>
  topicPageThumbnailImage_none?: InputMaybe<TopicPageWhereInput>
  topicPageThumbnailImage_some?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpSourceUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpSourceUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpSourceUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpSourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpSourceUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpSourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpSourceUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpSourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpSourceUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpSourceUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum AssetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DestinationUrlAsc = 'destinationUrl_ASC',
  DestinationUrlDesc = 'destinationUrl_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  WpSourceUrlAsc = 'wpSourceUrl_ASC',
  WpSourceUrlDesc = 'wpSourceUrl_DESC',
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>
  image?: InputMaybe<ImageTransformationInput>
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars['Boolean']['input']>
}

export type AssetUpdateInput = {
  appStoreButton?: InputMaybe<SectionAppCtaUpdateManyInlineInput>
  background?: InputMaybe<SectionHeroUpdateOneInlineInput>
  backgroundImagePartnerCompany?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  banner?: InputMaybe<SectionBannerUpdateOneInlineInput>
  bulletPointListImage?: InputMaybe<BulletPointListUpdateOneInlineInput>
  contactPhoto?: InputMaybe<ContactUpdateOneInlineInput>
  contentBackgroundLeft?: InputMaybe<SectionFramesHeroUpdateManyInlineInput>
  contentBackgroundRight?: InputMaybe<SectionFramesHeroUpdateManyInlineInput>
  contentReferenceCoverImage?: InputMaybe<ContentReferenceUpdateManyInlineInput>
  ctaImageSectionCta?: InputMaybe<SectionCtaUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  destinationUrl?: InputMaybe<Scalars['String']['input']>
  downloadDe?: InputMaybe<DownloadUpdateOneInlineInput>
  downloadEn?: InputMaybe<DownloadUpdateOneInlineInput>
  eventAppCardImage?: InputMaybe<EventAppCardUpdateManyInlineInput>
  eventFeaturedImage?: InputMaybe<EventUpdateManyInlineInput>
  explanationStepPicture?: InputMaybe<ExplanationStepUpdateOneInlineInput>
  featuredImageArticle?: InputMaybe<ArticleUpdateManyInlineInput>
  festivalHeroBackground?: InputMaybe<SectionFestivalHeroUpdateOneInlineInput>
  festivalHeroLogo?: InputMaybe<SectionFestivalHeroUpdateOneInlineInput>
  festivalHeroMobileBackground?: InputMaybe<SectionFestivalHeroUpdateOneInlineInput>
  festivalTitleDecoration?: InputMaybe<SectionFestivalHeroUpdateOneInlineInput>
  fileName?: InputMaybe<Scalars['String']['input']>
  genericHeroLogo?: InputMaybe<SectionFramesHeroUpdateManyInlineInput>
  handle?: InputMaybe<Scalars['String']['input']>
  headerLogo?: InputMaybe<HeaderUpdateOneInlineInput>
  headerMobileLogo?: InputMaybe<HeaderUpdateOneInlineInput>
  height?: InputMaybe<Scalars['Float']['input']>
  heroEventAppPageImage?: InputMaybe<EventAppPageUpdateManyInlineInput>
  heroLogo?: InputMaybe<SectionHeroUpdateOneInlineInput>
  heroMobileBackground?: InputMaybe<SectionHeroUpdateOneInlineInput>
  hotelLogo?: InputMaybe<HotelUpdateOneInlineInput>
  image?: InputMaybe<SlideUpdateOneInlineInput>
  imageGalleryEmbed?: InputMaybe<ImageGalleryEmbedUpdateManyInlineInput>
  jobImage?: InputMaybe<JobListingUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>
  logo?: InputMaybe<SectionBannerUpdateOneInlineInput>
  logoPartnerCompany?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  media?: InputMaybe<RichTextMediaUpdateManyInlineInput>
  mimeType?: InputMaybe<Scalars['String']['input']>
  ogImageSseo?: InputMaybe<SeoUpdateManyInlineInput>
  omrPartnerLogo?: InputMaybe<OmrPartnerUpdateOneInlineInput>
  omrProductCardIcon?: InputMaybe<OmrProductCardUpdateOneInlineInput>
  partnerLogo?: InputMaybe<SectionCtaUpdateManyInlineInput>
  playStoreButton?: InputMaybe<SectionAppCtaUpdateManyInlineInput>
  podcastGroupBackgroundImage?: InputMaybe<PodcastGroupUpdateManyInlineInput>
  podcastGroupMobileBackgroundImage?: InputMaybe<PodcastGroupUpdateManyInlineInput>
  poweredByLogo?: InputMaybe<SectionPoweredByUpdateOneInlineInput>
  previewimage?: InputMaybe<DownloadUpdateOneInlineInput>
  profilePhotoAuthor?: InputMaybe<AuthorUpdateManyInlineInput>
  sectionAdBanner?: InputMaybe<SectionAdUpdateOneInlineInput>
  sectionAdBannerMobile?: InputMaybe<SectionAdUpdateOneInlineInput>
  sectionQuoteSignatureImage?: InputMaybe<SectionQuoteUpdateManyInlineInput>
  sectionTestimonialImage?: InputMaybe<SectionTestimonialUpdateOneInlineInput>
  sectionTestimonialSignatureImage?: InputMaybe<SectionTestimonialUpdateOneInlineInput>
  singlelogo?: InputMaybe<SectionLogoFieldUpdateManyInlineInput>
  size?: InputMaybe<Scalars['Float']['input']>
  socialLinkLogo?: InputMaybe<SocialLinkUpdateOneInlineInput>
  stageBG?: InputMaybe<SectionStagePreviewUpdateOneInlineInput>
  stagelogo?: InputMaybe<SectionStagePreviewUpdateOneInlineInput>
  stickyImage?: InputMaybe<SectionStickyImageUpdateOneInlineInput>
  tabContentImage?: InputMaybe<TabContentUpdateOneInlineInput>
  teaserBackground?: InputMaybe<SectionTeaserUpdateOneInlineInput>
  textMediaBannerDesktopAsset?: InputMaybe<TextMediaBannerUpdateOneInlineInput>
  textMediaBannerMobileAsset?: InputMaybe<TextMediaBannerUpdateOneInlineInput>
  textMediaImage?: InputMaybe<TextMediaUpdateOneInlineInput>
  textMediaTextBanner?: InputMaybe<TextMediaUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<SectionHeroUpdateOneInlineInput>
  topicPageThumbnailImage?: InputMaybe<TopicPageUpdateManyInlineInput>
  video?: InputMaybe<SectionHeroUpdateOneInlineInput>
  width?: InputMaybe<Scalars['Float']['input']>
  wpSourceUrl?: InputMaybe<Scalars['String']['input']>
}

export type AssetUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  handle?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  mimeType?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Float']['input']>
}

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput
  locale: Locale
}

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>
}

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>
}

export type AssetUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  destinationUrl?: InputMaybe<Scalars['String']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>
  mimeType?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Float']['input']>
}

export type AssetUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  fileName?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  mimeType?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<Scalars['Float']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  width?: InputMaybe<Scalars['Float']['input']>
}

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput
  locale: Locale
}

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>
}

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput
  /** Document search */
  where: AssetWhereInput
}

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>
}

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput
  /** Unique document search */
  where: AssetWhereUniqueInput
}

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput
  /** Update document if it exists */
  update: AssetUpdateInput
}

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput
  locale: Locale
  update: AssetUpdateLocalizationDataInput
}

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput
  /** Unique document search */
  where: AssetWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImagePartnerCompany_every?: InputMaybe<PartnerCompanyWhereInput>
  backgroundImagePartnerCompany_none?: InputMaybe<PartnerCompanyWhereInput>
  backgroundImagePartnerCompany_some?: InputMaybe<PartnerCompanyWhereInput>
  contentReferenceCoverImage_every?: InputMaybe<ContentReferenceWhereInput>
  contentReferenceCoverImage_none?: InputMaybe<ContentReferenceWhereInput>
  contentReferenceCoverImage_some?: InputMaybe<ContentReferenceWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  destinationUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  destinationUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  destinationUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  destinationUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  destinationUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  destinationUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  destinationUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  destinationUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  destinationUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  destinationUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<AssetWhereStageInput>
  documentInStages_none?: InputMaybe<AssetWhereStageInput>
  documentInStages_some?: InputMaybe<AssetWhereStageInput>
  eventFeaturedImage_every?: InputMaybe<EventWhereInput>
  eventFeaturedImage_none?: InputMaybe<EventWhereInput>
  eventFeaturedImage_some?: InputMaybe<EventWhereInput>
  featuredImageArticle_every?: InputMaybe<ArticleWhereInput>
  featuredImageArticle_none?: InputMaybe<ArticleWhereInput>
  featuredImageArticle_some?: InputMaybe<ArticleWhereInput>
  fileName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars['String']['input']>
  handle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars['String']['input']>
  headerLogo?: InputMaybe<HeaderWhereInput>
  headerMobileLogo?: InputMaybe<HeaderWhereInput>
  height?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  heroEventAppPageImage_every?: InputMaybe<EventAppPageWhereInput>
  heroEventAppPageImage_none?: InputMaybe<EventAppPageWhereInput>
  heroEventAppPageImage_some?: InputMaybe<EventAppPageWhereInput>
  hotelLogo?: InputMaybe<HotelWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobImage_every?: InputMaybe<JobListingWhereInput>
  jobImage_none?: InputMaybe<JobListingWhereInput>
  jobImage_some?: InputMaybe<JobListingWhereInput>
  logoPartnerCompany_every?: InputMaybe<PartnerCompanyWhereInput>
  logoPartnerCompany_none?: InputMaybe<PartnerCompanyWhereInput>
  logoPartnerCompany_some?: InputMaybe<PartnerCompanyWhereInput>
  mimeType?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars['String']['input']>
  omrPartnerLogo?: InputMaybe<OmrPartnerWhereInput>
  podcastGroupBackgroundImage_every?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupBackgroundImage_none?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupBackgroundImage_some?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_every?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_none?: InputMaybe<PodcastGroupWhereInput>
  podcastGroupMobileBackgroundImage_some?: InputMaybe<PodcastGroupWhereInput>
  profilePhotoAuthor_every?: InputMaybe<AuthorWhereInput>
  profilePhotoAuthor_none?: InputMaybe<AuthorWhereInput>
  profilePhotoAuthor_some?: InputMaybe<AuthorWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  size?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  tabContentImage?: InputMaybe<TabContentWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPageThumbnailImage_every?: InputMaybe<TopicPageWhereInput>
  topicPageThumbnailImage_none?: InputMaybe<TopicPageWhereInput>
  topicPageThumbnailImage_some?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  width?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  wpSourceUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpSourceUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpSourceUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpSourceUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpSourceUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpSourceUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpSourceUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpSourceUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpSourceUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpSourceUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  wpSourceUrl?: InputMaybe<Scalars['String']['input']>
}

/** Content author */
export type Author = Entity &
  Node & {
    __typename?: 'Author'
    articles: Array<Article>
    /** Authors of English article content */
    articlesEn: Array<Article>
    authorType?: Maybe<AuthorType>
    avatar?: Maybe<Asset>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<Author>
    email?: Maybe<Scalars['String']['output']>
    facebookProfile?: Maybe<Scalars['String']['output']>
    /** List of Author versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    instagramProfile?: Maybe<Scalars['String']['output']>
    linkedInProfile?: Maybe<Scalars['String']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Author>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    seoAttributes?: Maybe<Seo>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    topicPages: Array<TopicPage>
    twitterProfile?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Optional wordpress reference used by importer */
    wpId?: Maybe<Scalars['String']['output']>
  }

/** Content author */
export type AuthorArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Content author */
export type AuthorArticlesEnArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Content author */
export type AuthorAvatarArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content author */
export type AuthorCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content author */
export type AuthorCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content author */
export type AuthorDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Content author */
export type AuthorHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Content author */
export type AuthorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Content author */
export type AuthorPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content author */
export type AuthorPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content author */
export type AuthorScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Content author */
export type AuthorSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content author */
export type AuthorTopicPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TopicPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageWhereInput>
}

/** Content author */
export type AuthorUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content author */
export type AuthorUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type AuthorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: AuthorWhereUniqueInput
}

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<AuthorEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type AuthorCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>
  articlesEn?: InputMaybe<ArticleCreateManyInlineInput>
  authorType?: InputMaybe<AuthorType>
  avatar?: InputMaybe<AssetCreateOneInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  facebookProfile?: InputMaybe<Scalars['String']['input']>
  instagramProfile?: InputMaybe<Scalars['String']['input']>
  linkedInProfile?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AuthorCreateLocalizationsInput>
  name: Scalars['String']['input']
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  topicPages?: InputMaybe<TopicPageCreateManyInlineInput>
  twitterProfile?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type AuthorCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type AuthorCreateLocalizationInput = {
  /** Localization input */
  data: AuthorCreateLocalizationDataInput
  locale: Locale
}

export type AuthorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AuthorCreateLocalizationInput>>
}

export type AuthorCreateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorWhereUniqueInput>>
  /** Create and connect multiple existing Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>
}

export type AuthorCreateOneInlineInput = {
  /** Connect one existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>
}

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Author
}

/** Identifies documents */
export type AuthorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articlesEn_every?: InputMaybe<ArticleWhereInput>
  articlesEn_none?: InputMaybe<ArticleWhereInput>
  articlesEn_some?: InputMaybe<ArticleWhereInput>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  authorType?: InputMaybe<AuthorType>
  /** All values that are contained in given list. */
  authorType_in?: InputMaybe<Array<InputMaybe<AuthorType>>>
  /** Any other value that exists and is not equal to the given value. */
  authorType_not?: InputMaybe<AuthorType>
  /** All values that are not contained in given list. */
  authorType_not_in?: InputMaybe<Array<InputMaybe<AuthorType>>>
  avatar?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>
  email?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>
  facebookProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebookProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebookProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebookProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebookProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebookProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebookProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebookProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebookProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebookProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagramProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagramProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagramProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagramProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagramProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagramProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagramProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagramProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagramProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagramProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedInProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedInProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedInProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedInProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedInProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedInProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedInProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedInProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedInProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedInProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPages_every?: InputMaybe<TopicPageWhereInput>
  topicPages_none?: InputMaybe<TopicPageWhereInput>
  topicPages_some?: InputMaybe<TopicPageWhereInput>
  twitterProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitterProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitterProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitterProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitterProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitterProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitterProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitterProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitterProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitterProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum AuthorOrderByInput {
  AuthorTypeAsc = 'authorType_ASC',
  AuthorTypeDesc = 'authorType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FacebookProfileAsc = 'facebookProfile_ASC',
  FacebookProfileDesc = 'facebookProfile_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InstagramProfileAsc = 'instagramProfile_ASC',
  InstagramProfileDesc = 'instagramProfile_DESC',
  LinkedInProfileAsc = 'linkedInProfile_ASC',
  LinkedInProfileDesc = 'linkedInProfile_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TwitterProfileAsc = 'twitterProfile_ASC',
  TwitterProfileDesc = 'twitterProfile_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export enum AuthorType {
  ContentTeam = 'ContentTeam',
  DailyTeam = 'DailyTeam',
  Freelancer = 'Freelancer',
  Guest = 'Guest',
  JobsTeam = 'JobsTeam',
  OmrTeam = 'OMRTeam',
}

export type AuthorUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>
  articlesEn?: InputMaybe<ArticleUpdateManyInlineInput>
  authorType?: InputMaybe<AuthorType>
  avatar?: InputMaybe<AssetUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  facebookProfile?: InputMaybe<Scalars['String']['input']>
  instagramProfile?: InputMaybe<Scalars['String']['input']>
  linkedInProfile?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<AuthorUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  topicPages?: InputMaybe<TopicPageUpdateManyInlineInput>
  twitterProfile?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type AuthorUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type AuthorUpdateLocalizationInput = {
  data: AuthorUpdateLocalizationDataInput
  locale: Locale
}

export type AuthorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AuthorCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<AuthorUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<AuthorUpsertLocalizationInput>>
}

export type AuthorUpdateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorConnectInput>>
  /** Create and connect multiple Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>
  /** Delete multiple Author documents */
  delete?: InputMaybe<Array<AuthorWhereUniqueInput>>
  /** Disconnect multiple Author documents */
  disconnect?: InputMaybe<Array<AuthorWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Author documents */
  set?: InputMaybe<Array<AuthorWhereUniqueInput>>
  /** Update multiple Author documents */
  update?: InputMaybe<Array<AuthorUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Author documents */
  upsert?: InputMaybe<Array<AuthorUpsertWithNestedWhereUniqueInput>>
}

export type AuthorUpdateManyInput = {
  authorType?: InputMaybe<AuthorType>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  facebookProfile?: InputMaybe<Scalars['String']['input']>
  instagramProfile?: InputMaybe<Scalars['String']['input']>
  linkedInProfile?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<AuthorUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  twitterProfile?: InputMaybe<Scalars['String']['input']>
}

export type AuthorUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type AuthorUpdateManyLocalizationInput = {
  data: AuthorUpdateManyLocalizationDataInput
  locale: Locale
}

export type AuthorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AuthorUpdateManyLocalizationInput>>
}

export type AuthorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AuthorUpdateManyInput
  /** Document search */
  where: AuthorWhereInput
}

export type AuthorUpdateOneInlineInput = {
  /** Connect existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>
  /** Delete currently connected Author document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Author document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Author document */
  update?: InputMaybe<AuthorUpdateWithNestedWhereUniqueInput>
  /** Upsert single Author document */
  upsert?: InputMaybe<AuthorUpsertWithNestedWhereUniqueInput>
}

export type AuthorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AuthorUpdateInput
  /** Unique document search */
  where: AuthorWhereUniqueInput
}

export type AuthorUpsertInput = {
  /** Create document if it didn't exist */
  create: AuthorCreateInput
  /** Update document if it exists */
  update: AuthorUpdateInput
}

export type AuthorUpsertLocalizationInput = {
  create: AuthorCreateLocalizationDataInput
  locale: Locale
  update: AuthorUpdateLocalizationDataInput
}

export type AuthorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AuthorUpsertInput
  /** Unique document search */
  where: AuthorWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type AuthorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type AuthorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articlesEn_every?: InputMaybe<ArticleWhereInput>
  articlesEn_none?: InputMaybe<ArticleWhereInput>
  articlesEn_some?: InputMaybe<ArticleWhereInput>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  authorType?: InputMaybe<AuthorType>
  /** All values that are contained in given list. */
  authorType_in?: InputMaybe<Array<InputMaybe<AuthorType>>>
  /** Any other value that exists and is not equal to the given value. */
  authorType_not?: InputMaybe<AuthorType>
  /** All values that are not contained in given list. */
  authorType_not_in?: InputMaybe<Array<InputMaybe<AuthorType>>>
  avatar?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>
  email?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>
  facebookProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebookProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebookProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebookProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebookProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebookProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebookProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebookProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebookProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebookProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagramProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagramProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagramProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagramProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagramProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagramProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagramProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagramProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagramProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagramProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedInProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedInProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedInProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedInProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedInProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedInProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedInProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedInProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedInProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedInProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPages_every?: InputMaybe<TopicPageWhereInput>
  topicPages_none?: InputMaybe<TopicPageWhereInput>
  topicPages_some?: InputMaybe<TopicPageWhereInput>
  twitterProfile?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitterProfile_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitterProfile_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitterProfile_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitterProfile_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitterProfile_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitterProfile_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitterProfile_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitterProfile_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitterProfile_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AuthorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AuthorWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Author record uniquely */
export type AuthorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

/** Simple component for Header / Description / CTA. */
export type BasicHeaderText = Entity & {
  __typename?: 'BasicHeaderText'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<BasicHeaderText>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Simple component for Header / Description / CTA. */
export type BasicHeaderTextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BasicHeaderTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BasicHeaderTextWhereUniqueInput
}

/** A connection to a list of items. */
export type BasicHeaderTextConnection = {
  __typename?: 'BasicHeaderTextConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BasicHeaderTextEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BasicHeaderTextCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BasicHeaderTextCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type BasicHeaderTextCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type BasicHeaderTextCreateLocalizationInput = {
  /** Localization input */
  data: BasicHeaderTextCreateLocalizationDataInput
  locale: Locale
}

export type BasicHeaderTextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BasicHeaderTextCreateLocalizationInput>>
}

export type BasicHeaderTextCreateManyInlineInput = {
  /** Create and connect multiple existing BasicHeaderText documents */
  create?: InputMaybe<Array<BasicHeaderTextCreateInput>>
}

export type BasicHeaderTextCreateOneInlineInput = {
  /** Create and connect one BasicHeaderText document */
  create?: InputMaybe<BasicHeaderTextCreateInput>
}

export type BasicHeaderTextCreateWithPositionInput = {
  /** Document to create */
  data: BasicHeaderTextCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type BasicHeaderTextEdge = {
  __typename?: 'BasicHeaderTextEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: BasicHeaderText
}

/** Identifies documents */
export type BasicHeaderTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum BasicHeaderTextOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BasicHeaderTextParent = SectionStickyImage

export type BasicHeaderTextParentConnectInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageConnectInput>
}

export type BasicHeaderTextParentCreateInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageCreateInput>
}

export type BasicHeaderTextParentCreateManyInlineInput = {
  /** Create and connect multiple existing BasicHeaderTextParent documents */
  create?: InputMaybe<Array<BasicHeaderTextParentCreateInput>>
}

export type BasicHeaderTextParentCreateOneInlineInput = {
  /** Create and connect one BasicHeaderTextParent document */
  create?: InputMaybe<BasicHeaderTextParentCreateInput>
}

export type BasicHeaderTextParentCreateWithPositionInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageCreateWithPositionInput>
}

export type BasicHeaderTextParentUpdateInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateInput>
}

export type BasicHeaderTextParentUpdateManyInlineInput = {
  /** Create and connect multiple BasicHeaderTextParent component instances */
  create?: InputMaybe<Array<BasicHeaderTextParentCreateWithPositionInput>>
  /** Delete multiple BasicHeaderTextParent documents */
  delete?: InputMaybe<Array<BasicHeaderTextParentWhereUniqueInput>>
  /** Update multiple BasicHeaderTextParent component instances */
  update?: InputMaybe<Array<BasicHeaderTextParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BasicHeaderTextParent component instances */
  upsert?: InputMaybe<Array<BasicHeaderTextParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BasicHeaderTextParentUpdateManyWithNestedWhereInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateManyWithNestedWhereInput>
}

export type BasicHeaderTextParentUpdateOneInlineInput = {
  /** Create and connect one BasicHeaderTextParent document */
  create?: InputMaybe<BasicHeaderTextParentCreateInput>
  /** Delete currently connected BasicHeaderTextParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BasicHeaderTextParent document */
  update?: InputMaybe<BasicHeaderTextParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single BasicHeaderTextParent document */
  upsert?: InputMaybe<BasicHeaderTextParentUpsertWithNestedWhereUniqueInput>
}

export type BasicHeaderTextParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateWithNestedWhereUniqueAndPositionInput>
}

export type BasicHeaderTextParentUpdateWithNestedWhereUniqueInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateWithNestedWhereUniqueInput>
}

export type BasicHeaderTextParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpsertWithNestedWhereUniqueAndPositionInput>
}

export type BasicHeaderTextParentUpsertWithNestedWhereUniqueInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageUpsertWithNestedWhereUniqueInput>
}

export type BasicHeaderTextParentWhereInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageWhereInput>
}

export type BasicHeaderTextParentWhereUniqueInput = {
  SectionStickyImage?: InputMaybe<SectionStickyImageWhereUniqueInput>
}

export type BasicHeaderTextUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<BasicHeaderTextUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BasicHeaderTextUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BasicHeaderTextUpdateLocalizationInput = {
  data: BasicHeaderTextUpdateLocalizationDataInput
  locale: Locale
}

export type BasicHeaderTextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BasicHeaderTextCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BasicHeaderTextUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BasicHeaderTextUpsertLocalizationInput>>
}

export type BasicHeaderTextUpdateManyInlineInput = {
  /** Create and connect multiple BasicHeaderText component instances */
  create?: InputMaybe<Array<BasicHeaderTextCreateWithPositionInput>>
  /** Delete multiple BasicHeaderText documents */
  delete?: InputMaybe<Array<BasicHeaderTextWhereUniqueInput>>
  /** Update multiple BasicHeaderText component instances */
  update?: InputMaybe<Array<BasicHeaderTextUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BasicHeaderText component instances */
  upsert?: InputMaybe<Array<BasicHeaderTextUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BasicHeaderTextUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<BasicHeaderTextUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BasicHeaderTextUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BasicHeaderTextUpdateManyLocalizationInput = {
  data: BasicHeaderTextUpdateManyLocalizationDataInput
  locale: Locale
}

export type BasicHeaderTextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BasicHeaderTextUpdateManyLocalizationInput>>
}

export type BasicHeaderTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BasicHeaderTextUpdateManyInput
  /** Document search */
  where: BasicHeaderTextWhereInput
}

export type BasicHeaderTextUpdateOneInlineInput = {
  /** Create and connect one BasicHeaderText document */
  create?: InputMaybe<BasicHeaderTextCreateInput>
  /** Delete currently connected BasicHeaderText document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BasicHeaderText document */
  update?: InputMaybe<BasicHeaderTextUpdateWithNestedWhereUniqueInput>
  /** Upsert single BasicHeaderText document */
  upsert?: InputMaybe<BasicHeaderTextUpsertWithNestedWhereUniqueInput>
}

export type BasicHeaderTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BasicHeaderTextUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BasicHeaderTextWhereUniqueInput
}

export type BasicHeaderTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BasicHeaderTextUpdateInput
  /** Unique document search */
  where: BasicHeaderTextWhereUniqueInput
}

export type BasicHeaderTextUpsertInput = {
  /** Create document if it didn't exist */
  create: BasicHeaderTextCreateInput
  /** Update document if it exists */
  update: BasicHeaderTextUpdateInput
}

export type BasicHeaderTextUpsertLocalizationInput = {
  create: BasicHeaderTextCreateLocalizationDataInput
  locale: Locale
  update: BasicHeaderTextUpdateLocalizationDataInput
}

export type BasicHeaderTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BasicHeaderTextUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BasicHeaderTextWhereUniqueInput
}

export type BasicHeaderTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BasicHeaderTextUpsertInput
  /** Unique document search */
  where: BasicHeaderTextWhereUniqueInput
}

/** Identifies documents */
export type BasicHeaderTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BasicHeaderTextWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References BasicHeaderText record uniquely */
export type BasicHeaderTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type BatchPayload = {
  __typename?: 'BatchPayload'
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long']['output']
}

export type Benefit = Entity & {
  __typename?: 'Benefit'
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Benefit>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type BenefitLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BenefitConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BenefitWhereUniqueInput
}

/** A connection to a list of items. */
export type BenefitConnection = {
  __typename?: 'BenefitConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BenefitEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BenefitCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BenefitCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type BenefitCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type BenefitCreateLocalizationInput = {
  /** Localization input */
  data: BenefitCreateLocalizationDataInput
  locale: Locale
}

export type BenefitCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BenefitCreateLocalizationInput>>
}

export type BenefitCreateManyInlineInput = {
  /** Create and connect multiple existing Benefit documents */
  create?: InputMaybe<Array<BenefitCreateInput>>
}

export type BenefitCreateOneInlineInput = {
  /** Create and connect one Benefit document */
  create?: InputMaybe<BenefitCreateInput>
}

export type BenefitCreateWithPositionInput = {
  /** Document to create */
  data: BenefitCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type BenefitEdge = {
  __typename?: 'BenefitEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Benefit
}

/** Identifies documents */
export type BenefitManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum BenefitOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BenefitParent = BenefitsSection

export type BenefitParentConnectInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionConnectInput>
}

export type BenefitParentCreateInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionCreateInput>
}

export type BenefitParentCreateManyInlineInput = {
  /** Create and connect multiple existing BenefitParent documents */
  create?: InputMaybe<Array<BenefitParentCreateInput>>
}

export type BenefitParentCreateOneInlineInput = {
  /** Create and connect one BenefitParent document */
  create?: InputMaybe<BenefitParentCreateInput>
}

export type BenefitParentCreateWithPositionInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionCreateWithPositionInput>
}

export type BenefitParentUpdateInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpdateInput>
}

export type BenefitParentUpdateManyInlineInput = {
  /** Create and connect multiple BenefitParent component instances */
  create?: InputMaybe<Array<BenefitParentCreateWithPositionInput>>
  /** Delete multiple BenefitParent documents */
  delete?: InputMaybe<Array<BenefitParentWhereUniqueInput>>
  /** Update multiple BenefitParent component instances */
  update?: InputMaybe<Array<BenefitParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BenefitParent component instances */
  upsert?: InputMaybe<Array<BenefitParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitParentUpdateManyWithNestedWhereInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpdateManyWithNestedWhereInput>
}

export type BenefitParentUpdateOneInlineInput = {
  /** Create and connect one BenefitParent document */
  create?: InputMaybe<BenefitParentCreateInput>
  /** Delete currently connected BenefitParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BenefitParent document */
  update?: InputMaybe<BenefitParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single BenefitParent document */
  upsert?: InputMaybe<BenefitParentUpsertWithNestedWhereUniqueInput>
}

export type BenefitParentUpdateWithNestedWhereUniqueAndPositionInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type BenefitParentUpdateWithNestedWhereUniqueInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpdateWithNestedWhereUniqueInput>
}

export type BenefitParentUpsertWithNestedWhereUniqueAndPositionInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type BenefitParentUpsertWithNestedWhereUniqueInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionUpsertWithNestedWhereUniqueInput>
}

export type BenefitParentWhereInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionWhereInput>
}

export type BenefitParentWhereUniqueInput = {
  BenefitsSection?: InputMaybe<BenefitsSectionWhereUniqueInput>
}

export type BenefitUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<BenefitUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitUpdateLocalizationInput = {
  data: BenefitUpdateLocalizationDataInput
  locale: Locale
}

export type BenefitUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BenefitCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BenefitUpsertLocalizationInput>>
}

export type BenefitUpdateManyInlineInput = {
  /** Create and connect multiple Benefit component instances */
  create?: InputMaybe<Array<BenefitCreateWithPositionInput>>
  /** Delete multiple Benefit documents */
  delete?: InputMaybe<Array<BenefitWhereUniqueInput>>
  /** Update multiple Benefit component instances */
  update?: InputMaybe<Array<BenefitUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Benefit component instances */
  upsert?: InputMaybe<Array<BenefitUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<BenefitUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitUpdateManyLocalizationInput = {
  data: BenefitUpdateManyLocalizationDataInput
  locale: Locale
}

export type BenefitUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitUpdateManyLocalizationInput>>
}

export type BenefitUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BenefitUpdateManyInput
  /** Document search */
  where: BenefitWhereInput
}

export type BenefitUpdateOneInlineInput = {
  /** Create and connect one Benefit document */
  create?: InputMaybe<BenefitCreateInput>
  /** Delete currently connected Benefit document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Benefit document */
  update?: InputMaybe<BenefitUpdateWithNestedWhereUniqueInput>
  /** Upsert single Benefit document */
  upsert?: InputMaybe<BenefitUpsertWithNestedWhereUniqueInput>
}

export type BenefitUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BenefitUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitWhereUniqueInput
}

export type BenefitUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BenefitUpdateInput
  /** Unique document search */
  where: BenefitWhereUniqueInput
}

export type BenefitUpsertInput = {
  /** Create document if it didn't exist */
  create: BenefitCreateInput
  /** Update document if it exists */
  update: BenefitUpdateInput
}

export type BenefitUpsertLocalizationInput = {
  create: BenefitCreateLocalizationDataInput
  locale: Locale
  update: BenefitUpdateLocalizationDataInput
}

export type BenefitUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BenefitUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitWhereUniqueInput
}

export type BenefitUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BenefitUpsertInput
  /** Unique document search */
  where: BenefitWhereUniqueInput
}

/** Identifies documents */
export type BenefitWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References Benefit record uniquely */
export type BenefitWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type BenefitsCard = Entity & {
  __typename?: 'BenefitsCard'
  benefitsSection: Array<BenefitsSection>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<BenefitsCard>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

export type BenefitsCardBenefitsSectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BenefitsSectionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BenefitsSectionWhereInput>
}

export type BenefitsCardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BenefitsCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BenefitsCardWhereUniqueInput
}

/** A connection to a list of items. */
export type BenefitsCardConnection = {
  __typename?: 'BenefitsCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BenefitsCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BenefitsCardCreateInput = {
  benefitsSection?: InputMaybe<BenefitsSectionCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BenefitsCardCreateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardCreateLocalizationInput = {
  /** Localization input */
  data: BenefitsCardCreateLocalizationDataInput
  locale: Locale
}

export type BenefitsCardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BenefitsCardCreateLocalizationInput>>
}

export type BenefitsCardCreateManyInlineInput = {
  /** Create and connect multiple existing BenefitsCard documents */
  create?: InputMaybe<Array<BenefitsCardCreateInput>>
}

export type BenefitsCardCreateOneInlineInput = {
  /** Create and connect one BenefitsCard document */
  create?: InputMaybe<BenefitsCardCreateInput>
}

export type BenefitsCardCreateWithPositionInput = {
  /** Document to create */
  data: BenefitsCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type BenefitsCardEdge = {
  __typename?: 'BenefitsCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: BenefitsCard
}

/** Identifies documents */
export type BenefitsCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefitsSection_every?: InputMaybe<BenefitsSectionWhereInput>
  benefitsSection_none?: InputMaybe<BenefitsSectionWhereInput>
  benefitsSection_some?: InputMaybe<BenefitsSectionWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum BenefitsCardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BenefitsCardParent = SectionStorytelling

export type BenefitsCardParentConnectInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingConnectInput>
}

export type BenefitsCardParentCreateInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingCreateInput>
}

export type BenefitsCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing BenefitsCardParent documents */
  create?: InputMaybe<Array<BenefitsCardParentCreateInput>>
}

export type BenefitsCardParentCreateOneInlineInput = {
  /** Create and connect one BenefitsCardParent document */
  create?: InputMaybe<BenefitsCardParentCreateInput>
}

export type BenefitsCardParentCreateWithPositionInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingCreateWithPositionInput>
}

export type BenefitsCardParentUpdateInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateInput>
}

export type BenefitsCardParentUpdateManyInlineInput = {
  /** Create and connect multiple BenefitsCardParent component instances */
  create?: InputMaybe<Array<BenefitsCardParentCreateWithPositionInput>>
  /** Delete multiple BenefitsCardParent documents */
  delete?: InputMaybe<Array<BenefitsCardParentWhereUniqueInput>>
  /** Update multiple BenefitsCardParent component instances */
  update?: InputMaybe<Array<BenefitsCardParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BenefitsCardParent component instances */
  upsert?: InputMaybe<Array<BenefitsCardParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitsCardParentUpdateManyWithNestedWhereInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateManyWithNestedWhereInput>
}

export type BenefitsCardParentUpdateOneInlineInput = {
  /** Create and connect one BenefitsCardParent document */
  create?: InputMaybe<BenefitsCardParentCreateInput>
  /** Delete currently connected BenefitsCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BenefitsCardParent document */
  update?: InputMaybe<BenefitsCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single BenefitsCardParent document */
  upsert?: InputMaybe<BenefitsCardParentUpsertWithNestedWhereUniqueInput>
}

export type BenefitsCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateWithNestedWhereUniqueAndPositionInput>
}

export type BenefitsCardParentUpdateWithNestedWhereUniqueInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateWithNestedWhereUniqueInput>
}

export type BenefitsCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpsertWithNestedWhereUniqueAndPositionInput>
}

export type BenefitsCardParentUpsertWithNestedWhereUniqueInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingUpsertWithNestedWhereUniqueInput>
}

export type BenefitsCardParentWhereInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingWhereInput>
}

export type BenefitsCardParentWhereUniqueInput = {
  SectionStorytelling?: InputMaybe<SectionStorytellingWhereUniqueInput>
}

export type BenefitsCardUpdateInput = {
  benefitsSection?: InputMaybe<BenefitsSectionUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<BenefitsCardUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardUpdateLocalizationInput = {
  data: BenefitsCardUpdateLocalizationDataInput
  locale: Locale
}

export type BenefitsCardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BenefitsCardCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitsCardUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BenefitsCardUpsertLocalizationInput>>
}

export type BenefitsCardUpdateManyInlineInput = {
  /** Create and connect multiple BenefitsCard component instances */
  create?: InputMaybe<Array<BenefitsCardCreateWithPositionInput>>
  /** Delete multiple BenefitsCard documents */
  delete?: InputMaybe<Array<BenefitsCardWhereUniqueInput>>
  /** Update multiple BenefitsCard component instances */
  update?: InputMaybe<Array<BenefitsCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BenefitsCard component instances */
  upsert?: InputMaybe<Array<BenefitsCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitsCardUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BenefitsCardUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsCardUpdateManyLocalizationInput = {
  data: BenefitsCardUpdateManyLocalizationDataInput
  locale: Locale
}

export type BenefitsCardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitsCardUpdateManyLocalizationInput>>
}

export type BenefitsCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BenefitsCardUpdateManyInput
  /** Document search */
  where: BenefitsCardWhereInput
}

export type BenefitsCardUpdateOneInlineInput = {
  /** Create and connect one BenefitsCard document */
  create?: InputMaybe<BenefitsCardCreateInput>
  /** Delete currently connected BenefitsCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BenefitsCard document */
  update?: InputMaybe<BenefitsCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single BenefitsCard document */
  upsert?: InputMaybe<BenefitsCardUpsertWithNestedWhereUniqueInput>
}

export type BenefitsCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BenefitsCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitsCardWhereUniqueInput
}

export type BenefitsCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BenefitsCardUpdateInput
  /** Unique document search */
  where: BenefitsCardWhereUniqueInput
}

export type BenefitsCardUpsertInput = {
  /** Create document if it didn't exist */
  create: BenefitsCardCreateInput
  /** Update document if it exists */
  update: BenefitsCardUpdateInput
}

export type BenefitsCardUpsertLocalizationInput = {
  create: BenefitsCardCreateLocalizationDataInput
  locale: Locale
  update: BenefitsCardUpdateLocalizationDataInput
}

export type BenefitsCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BenefitsCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitsCardWhereUniqueInput
}

export type BenefitsCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BenefitsCardUpsertInput
  /** Unique document search */
  where: BenefitsCardWhereUniqueInput
}

/** Identifies documents */
export type BenefitsCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitsCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefitsSection_every?: InputMaybe<BenefitsSectionWhereInput>
  benefitsSection_none?: InputMaybe<BenefitsSectionWhereInput>
  benefitsSection_some?: InputMaybe<BenefitsSectionWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References BenefitsCard record uniquely */
export type BenefitsCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type BenefitsSection = Entity & {
  __typename?: 'BenefitsSection'
  benefit: Array<Benefit>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<BenefitsSection>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

export type BenefitsSectionBenefitArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BenefitOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BenefitWhereInput>
}

export type BenefitsSectionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BenefitsSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BenefitsSectionWhereUniqueInput
}

/** A connection to a list of items. */
export type BenefitsSectionConnection = {
  __typename?: 'BenefitsSectionConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BenefitsSectionEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BenefitsSectionCreateInput = {
  benefit?: InputMaybe<BenefitCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BenefitsSectionCreateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionCreateLocalizationInput = {
  /** Localization input */
  data: BenefitsSectionCreateLocalizationDataInput
  locale: Locale
}

export type BenefitsSectionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BenefitsSectionCreateLocalizationInput>>
}

export type BenefitsSectionCreateManyInlineInput = {
  /** Create and connect multiple existing BenefitsSection documents */
  create?: InputMaybe<Array<BenefitsSectionCreateInput>>
}

export type BenefitsSectionCreateOneInlineInput = {
  /** Create and connect one BenefitsSection document */
  create?: InputMaybe<BenefitsSectionCreateInput>
}

export type BenefitsSectionCreateWithPositionInput = {
  /** Document to create */
  data: BenefitsSectionCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type BenefitsSectionEdge = {
  __typename?: 'BenefitsSectionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: BenefitsSection
}

/** Identifies documents */
export type BenefitsSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefit_every?: InputMaybe<BenefitWhereInput>
  benefit_none?: InputMaybe<BenefitWhereInput>
  benefit_some?: InputMaybe<BenefitWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum BenefitsSectionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BenefitsSectionParent = BenefitsCard

export type BenefitsSectionParentConnectInput = {
  BenefitsCard?: InputMaybe<BenefitsCardConnectInput>
}

export type BenefitsSectionParentCreateInput = {
  BenefitsCard?: InputMaybe<BenefitsCardCreateInput>
}

export type BenefitsSectionParentCreateManyInlineInput = {
  /** Create and connect multiple existing BenefitsSectionParent documents */
  create?: InputMaybe<Array<BenefitsSectionParentCreateInput>>
}

export type BenefitsSectionParentCreateOneInlineInput = {
  /** Create and connect one BenefitsSectionParent document */
  create?: InputMaybe<BenefitsSectionParentCreateInput>
}

export type BenefitsSectionParentCreateWithPositionInput = {
  BenefitsCard?: InputMaybe<BenefitsCardCreateWithPositionInput>
}

export type BenefitsSectionParentUpdateInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpdateInput>
}

export type BenefitsSectionParentUpdateManyInlineInput = {
  /** Create and connect multiple BenefitsSectionParent component instances */
  create?: InputMaybe<Array<BenefitsSectionParentCreateWithPositionInput>>
  /** Delete multiple BenefitsSectionParent documents */
  delete?: InputMaybe<Array<BenefitsSectionParentWhereUniqueInput>>
  /** Update multiple BenefitsSectionParent component instances */
  update?: InputMaybe<Array<BenefitsSectionParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BenefitsSectionParent component instances */
  upsert?: InputMaybe<Array<BenefitsSectionParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitsSectionParentUpdateManyWithNestedWhereInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpdateManyWithNestedWhereInput>
}

export type BenefitsSectionParentUpdateOneInlineInput = {
  /** Create and connect one BenefitsSectionParent document */
  create?: InputMaybe<BenefitsSectionParentCreateInput>
  /** Delete currently connected BenefitsSectionParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BenefitsSectionParent document */
  update?: InputMaybe<BenefitsSectionParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single BenefitsSectionParent document */
  upsert?: InputMaybe<BenefitsSectionParentUpsertWithNestedWhereUniqueInput>
}

export type BenefitsSectionParentUpdateWithNestedWhereUniqueAndPositionInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpdateWithNestedWhereUniqueAndPositionInput>
}

export type BenefitsSectionParentUpdateWithNestedWhereUniqueInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpdateWithNestedWhereUniqueInput>
}

export type BenefitsSectionParentUpsertWithNestedWhereUniqueAndPositionInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpsertWithNestedWhereUniqueAndPositionInput>
}

export type BenefitsSectionParentUpsertWithNestedWhereUniqueInput = {
  BenefitsCard?: InputMaybe<BenefitsCardUpsertWithNestedWhereUniqueInput>
}

export type BenefitsSectionParentWhereInput = {
  BenefitsCard?: InputMaybe<BenefitsCardWhereInput>
}

export type BenefitsSectionParentWhereUniqueInput = {
  BenefitsCard?: InputMaybe<BenefitsCardWhereUniqueInput>
}

export type BenefitsSectionUpdateInput = {
  benefit?: InputMaybe<BenefitUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<BenefitsSectionUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionUpdateLocalizationInput = {
  data: BenefitsSectionUpdateLocalizationDataInput
  locale: Locale
}

export type BenefitsSectionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BenefitsSectionCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitsSectionUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BenefitsSectionUpsertLocalizationInput>>
}

export type BenefitsSectionUpdateManyInlineInput = {
  /** Create and connect multiple BenefitsSection component instances */
  create?: InputMaybe<Array<BenefitsSectionCreateWithPositionInput>>
  /** Delete multiple BenefitsSection documents */
  delete?: InputMaybe<Array<BenefitsSectionWhereUniqueInput>>
  /** Update multiple BenefitsSection component instances */
  update?: InputMaybe<Array<BenefitsSectionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BenefitsSection component instances */
  upsert?: InputMaybe<Array<BenefitsSectionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BenefitsSectionUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BenefitsSectionUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BenefitsSectionUpdateManyLocalizationInput = {
  data: BenefitsSectionUpdateManyLocalizationDataInput
  locale: Locale
}

export type BenefitsSectionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BenefitsSectionUpdateManyLocalizationInput>>
}

export type BenefitsSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BenefitsSectionUpdateManyInput
  /** Document search */
  where: BenefitsSectionWhereInput
}

export type BenefitsSectionUpdateOneInlineInput = {
  /** Create and connect one BenefitsSection document */
  create?: InputMaybe<BenefitsSectionCreateInput>
  /** Delete currently connected BenefitsSection document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BenefitsSection document */
  update?: InputMaybe<BenefitsSectionUpdateWithNestedWhereUniqueInput>
  /** Upsert single BenefitsSection document */
  upsert?: InputMaybe<BenefitsSectionUpsertWithNestedWhereUniqueInput>
}

export type BenefitsSectionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BenefitsSectionUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitsSectionWhereUniqueInput
}

export type BenefitsSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BenefitsSectionUpdateInput
  /** Unique document search */
  where: BenefitsSectionWhereUniqueInput
}

export type BenefitsSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: BenefitsSectionCreateInput
  /** Update document if it exists */
  update: BenefitsSectionUpdateInput
}

export type BenefitsSectionUpsertLocalizationInput = {
  create: BenefitsSectionCreateLocalizationDataInput
  locale: Locale
  update: BenefitsSectionUpdateLocalizationDataInput
}

export type BenefitsSectionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BenefitsSectionUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BenefitsSectionWhereUniqueInput
}

export type BenefitsSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BenefitsSectionUpsertInput
  /** Unique document search */
  where: BenefitsSectionWhereUniqueInput
}

/** Identifies documents */
export type BenefitsSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BenefitsSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefit_every?: InputMaybe<BenefitWhereInput>
  benefit_none?: InputMaybe<BenefitWhereInput>
  benefit_some?: InputMaybe<BenefitWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References BenefitsSection record uniquely */
export type BenefitsSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Booth = Entity &
  Node & {
    __typename?: 'Booth'
    boothNumber?: Maybe<Scalars['String']['output']>
    boothType?: Maybe<BoothType>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Booth>
    event?: Maybe<Event>
    /** List of Booth versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Booth>
    partnerCompany?: Maybe<PartnerCompany>
    physicalLocation?: Maybe<PhysicalLocation>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    title?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type BoothCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type BoothCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type BoothEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type BoothLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BoothPartnerCompanyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothPhysicalLocationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type BoothPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type BoothUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type BoothUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BoothConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BoothWhereUniqueInput
}

/** A connection to a list of items. */
export type BoothConnection = {
  __typename?: 'BoothConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BoothEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BoothCreateInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  boothType?: InputMaybe<BoothType>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventCreateOneInlineInput>
  eventBooth?: InputMaybe<EventCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BoothCreateLocalizationsInput>
  partnerCompany?: InputMaybe<PartnerCompanyCreateOneInlineInput>
  physicalLocation?: InputMaybe<PhysicalLocationCreateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type BoothCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type BoothCreateLocalizationInput = {
  /** Localization input */
  data: BoothCreateLocalizationDataInput
  locale: Locale
}

export type BoothCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BoothCreateLocalizationInput>>
}

export type BoothCreateManyInlineInput = {
  /** Connect multiple existing Booth documents */
  connect?: InputMaybe<Array<BoothWhereUniqueInput>>
  /** Create and connect multiple existing Booth documents */
  create?: InputMaybe<Array<BoothCreateInput>>
}

export type BoothCreateOneInlineInput = {
  /** Connect one existing Booth document */
  connect?: InputMaybe<BoothWhereUniqueInput>
  /** Create and connect one Booth document */
  create?: InputMaybe<BoothCreateInput>
}

/** An edge in a connection. */
export type BoothEdge = {
  __typename?: 'BoothEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Booth
}

/** Identifies documents */
export type BoothManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BoothWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BoothWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BoothWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  boothNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  boothNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  boothNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  boothNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  boothNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  boothNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  boothNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  boothNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  boothNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  boothType?: InputMaybe<BoothType>
  /** All values that are contained in given list. */
  boothType_in?: InputMaybe<Array<InputMaybe<BoothType>>>
  /** Any other value that exists and is not equal to the given value. */
  boothType_not?: InputMaybe<BoothType>
  /** All values that are not contained in given list. */
  boothType_not_in?: InputMaybe<Array<InputMaybe<BoothType>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<BoothWhereStageInput>
  documentInStages_none?: InputMaybe<BoothWhereStageInput>
  documentInStages_some?: InputMaybe<BoothWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  physicalLocation?: InputMaybe<PhysicalLocationWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum BoothOrderByInput {
  BoothNumberAsc = 'boothNumber_ASC',
  BoothNumberDesc = 'boothNumber_DESC',
  BoothTypeAsc = 'boothType_ASC',
  BoothTypeDesc = 'boothType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export enum BoothType {
  CoExhibitorBooth = 'CoExhibitorBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

export type BoothUpdateInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  boothType?: InputMaybe<BoothType>
  event?: InputMaybe<EventUpdateOneInlineInput>
  eventBooth?: InputMaybe<EventUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<BoothUpdateLocalizationsInput>
  partnerCompany?: InputMaybe<PartnerCompanyUpdateOneInlineInput>
  physicalLocation?: InputMaybe<PhysicalLocationUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BoothUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BoothUpdateLocalizationInput = {
  data: BoothUpdateLocalizationDataInput
  locale: Locale
}

export type BoothUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BoothCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BoothUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BoothUpsertLocalizationInput>>
}

export type BoothUpdateManyInlineInput = {
  /** Connect multiple existing Booth documents */
  connect?: InputMaybe<Array<BoothConnectInput>>
  /** Create and connect multiple Booth documents */
  create?: InputMaybe<Array<BoothCreateInput>>
  /** Delete multiple Booth documents */
  delete?: InputMaybe<Array<BoothWhereUniqueInput>>
  /** Disconnect multiple Booth documents */
  disconnect?: InputMaybe<Array<BoothWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Booth documents */
  set?: InputMaybe<Array<BoothWhereUniqueInput>>
  /** Update multiple Booth documents */
  update?: InputMaybe<Array<BoothUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Booth documents */
  upsert?: InputMaybe<Array<BoothUpsertWithNestedWhereUniqueInput>>
}

export type BoothUpdateManyInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  boothType?: InputMaybe<BoothType>
  /** Optional updates to localizations */
  localizations?: InputMaybe<BoothUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BoothUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type BoothUpdateManyLocalizationInput = {
  data: BoothUpdateManyLocalizationDataInput
  locale: Locale
}

export type BoothUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BoothUpdateManyLocalizationInput>>
}

export type BoothUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BoothUpdateManyInput
  /** Document search */
  where: BoothWhereInput
}

export type BoothUpdateOneInlineInput = {
  /** Connect existing Booth document */
  connect?: InputMaybe<BoothWhereUniqueInput>
  /** Create and connect one Booth document */
  create?: InputMaybe<BoothCreateInput>
  /** Delete currently connected Booth document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Booth document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Booth document */
  update?: InputMaybe<BoothUpdateWithNestedWhereUniqueInput>
  /** Upsert single Booth document */
  upsert?: InputMaybe<BoothUpsertWithNestedWhereUniqueInput>
}

export type BoothUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BoothUpdateInput
  /** Unique document search */
  where: BoothWhereUniqueInput
}

export type BoothUpsertInput = {
  /** Create document if it didn't exist */
  create: BoothCreateInput
  /** Update document if it exists */
  update: BoothUpdateInput
}

export type BoothUpsertLocalizationInput = {
  create: BoothCreateLocalizationDataInput
  locale: Locale
  update: BoothUpdateLocalizationDataInput
}

export type BoothUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BoothUpsertInput
  /** Unique document search */
  where: BoothWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type BoothWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type BoothWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BoothWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BoothWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BoothWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  boothNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  boothNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  boothNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  boothNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  boothNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  boothNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  boothNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  boothNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  boothNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  boothType?: InputMaybe<BoothType>
  /** All values that are contained in given list. */
  boothType_in?: InputMaybe<Array<InputMaybe<BoothType>>>
  /** Any other value that exists and is not equal to the given value. */
  boothType_not?: InputMaybe<BoothType>
  /** All values that are not contained in given list. */
  boothType_not_in?: InputMaybe<Array<InputMaybe<BoothType>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<BoothWhereStageInput>
  documentInStages_none?: InputMaybe<BoothWhereStageInput>
  documentInStages_some?: InputMaybe<BoothWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  physicalLocation?: InputMaybe<PhysicalLocationWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BoothWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BoothWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BoothWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BoothWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BoothWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Booth record uniquely */
export type BoothWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type BulletPointList = Entity & {
  __typename?: 'BulletPointList'
  /** The unique identifier */
  id: Scalars['ID']['output']
  image: Asset
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<BulletPointList>
  points: Array<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title: Scalars['String']['output']
}

export type BulletPointListImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type BulletPointListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type BulletPointListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: BulletPointListWhereUniqueInput
}

/** A connection to a list of items. */
export type BulletPointListConnection = {
  __typename?: 'BulletPointListConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<BulletPointListEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type BulletPointListCreateInput = {
  image: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BulletPointListCreateLocalizationsInput>
  /** points input for default locale (de) */
  points: Array<Scalars['String']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type BulletPointListCreateLocalizationDataInput = {
  points: Array<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type BulletPointListCreateLocalizationInput = {
  /** Localization input */
  data: BulletPointListCreateLocalizationDataInput
  locale: Locale
}

export type BulletPointListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BulletPointListCreateLocalizationInput>>
}

export type BulletPointListCreateManyInlineInput = {
  /** Create and connect multiple existing BulletPointList documents */
  create?: InputMaybe<Array<BulletPointListCreateInput>>
}

export type BulletPointListCreateOneInlineInput = {
  /** Create and connect one BulletPointList document */
  create?: InputMaybe<BulletPointListCreateInput>
}

export type BulletPointListCreateWithPositionInput = {
  /** Document to create */
  data: BulletPointListCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type BulletPointListEdge = {
  __typename?: 'BulletPointListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: BulletPointList
}

/** Identifies documents */
export type BulletPointListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum BulletPointListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PointsAsc = 'points_ASC',
  PointsDesc = 'points_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type BulletPointListParent = EventPage

export type BulletPointListParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type BulletPointListParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type BulletPointListParentCreateManyInlineInput = {
  /** Connect multiple existing BulletPointListParent documents */
  connect?: InputMaybe<Array<BulletPointListParentWhereUniqueInput>>
  /** Create and connect multiple existing BulletPointListParent documents */
  create?: InputMaybe<Array<BulletPointListParentCreateInput>>
}

export type BulletPointListParentCreateOneInlineInput = {
  /** Connect one existing BulletPointListParent document */
  connect?: InputMaybe<BulletPointListParentWhereUniqueInput>
  /** Create and connect one BulletPointListParent document */
  create?: InputMaybe<BulletPointListParentCreateInput>
}

export type BulletPointListParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type BulletPointListParentUpdateManyInlineInput = {
  /** Connect multiple existing BulletPointListParent documents */
  connect?: InputMaybe<Array<BulletPointListParentConnectInput>>
  /** Create and connect multiple BulletPointListParent documents */
  create?: InputMaybe<Array<BulletPointListParentCreateInput>>
  /** Delete multiple BulletPointListParent documents */
  delete?: InputMaybe<Array<BulletPointListParentWhereUniqueInput>>
  /** Disconnect multiple BulletPointListParent documents */
  disconnect?: InputMaybe<Array<BulletPointListParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing BulletPointListParent documents */
  set?: InputMaybe<Array<BulletPointListParentWhereUniqueInput>>
  /** Update multiple BulletPointListParent documents */
  update?: InputMaybe<Array<BulletPointListParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple BulletPointListParent documents */
  upsert?: InputMaybe<Array<BulletPointListParentUpsertWithNestedWhereUniqueInput>>
}

export type BulletPointListParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type BulletPointListParentUpdateOneInlineInput = {
  /** Connect existing BulletPointListParent document */
  connect?: InputMaybe<BulletPointListParentWhereUniqueInput>
  /** Create and connect one BulletPointListParent document */
  create?: InputMaybe<BulletPointListParentCreateInput>
  /** Delete currently connected BulletPointListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected BulletPointListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BulletPointListParent document */
  update?: InputMaybe<BulletPointListParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single BulletPointListParent document */
  upsert?: InputMaybe<BulletPointListParentUpsertWithNestedWhereUniqueInput>
}

export type BulletPointListParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type BulletPointListParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type BulletPointListParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type BulletPointListParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type BulletPointListUpdateInput = {
  image?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<BulletPointListUpdateLocalizationsInput>
  /** points input for default locale (de) */
  points?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BulletPointListUpdateLocalizationDataInput = {
  points?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BulletPointListUpdateLocalizationInput = {
  data: BulletPointListUpdateLocalizationDataInput
  locale: Locale
}

export type BulletPointListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BulletPointListCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<BulletPointListUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<BulletPointListUpsertLocalizationInput>>
}

export type BulletPointListUpdateManyInlineInput = {
  /** Create and connect multiple BulletPointList component instances */
  create?: InputMaybe<Array<BulletPointListCreateWithPositionInput>>
  /** Delete multiple BulletPointList documents */
  delete?: InputMaybe<Array<BulletPointListWhereUniqueInput>>
  /** Update multiple BulletPointList component instances */
  update?: InputMaybe<Array<BulletPointListUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple BulletPointList component instances */
  upsert?: InputMaybe<Array<BulletPointListUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type BulletPointListUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BulletPointListUpdateManyLocalizationsInput>
  /** points input for default locale (de) */
  points?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type BulletPointListUpdateManyLocalizationDataInput = {
  points?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BulletPointListUpdateManyLocalizationInput = {
  data: BulletPointListUpdateManyLocalizationDataInput
  locale: Locale
}

export type BulletPointListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BulletPointListUpdateManyLocalizationInput>>
}

export type BulletPointListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BulletPointListUpdateManyInput
  /** Document search */
  where: BulletPointListWhereInput
}

export type BulletPointListUpdateOneInlineInput = {
  /** Create and connect one BulletPointList document */
  create?: InputMaybe<BulletPointListCreateInput>
  /** Delete currently connected BulletPointList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single BulletPointList document */
  update?: InputMaybe<BulletPointListUpdateWithNestedWhereUniqueInput>
  /** Upsert single BulletPointList document */
  upsert?: InputMaybe<BulletPointListUpsertWithNestedWhereUniqueInput>
}

export type BulletPointListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BulletPointListUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BulletPointListWhereUniqueInput
}

export type BulletPointListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BulletPointListUpdateInput
  /** Unique document search */
  where: BulletPointListWhereUniqueInput
}

export type BulletPointListUpsertInput = {
  /** Create document if it didn't exist */
  create: BulletPointListCreateInput
  /** Update document if it exists */
  update: BulletPointListUpdateInput
}

export type BulletPointListUpsertLocalizationInput = {
  create: BulletPointListCreateLocalizationDataInput
  locale: Locale
  update: BulletPointListUpdateLocalizationDataInput
}

export type BulletPointListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BulletPointListUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: BulletPointListWhereUniqueInput
}

export type BulletPointListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BulletPointListUpsertInput
  /** Unique document search */
  where: BulletPointListWhereUniqueInput
}

/** Identifies documents */
export type BulletPointListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BulletPointListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  points?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  points_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  points_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  points_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  points_not?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References BulletPointList record uniquely */
export type BulletPointListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Link or button call to action configuration */
export type CallToAction = Entity & {
  __typename?: 'CallToAction'
  /** Call to action color */
  color?: Maybe<CallToActionColor>
  /** Indicator if url is internal or external */
  external?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Link or button label */
  label: Scalars['String']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<CallToAction>
  /** Call to action size */
  size?: Maybe<CallToActionSize>
  /** System stage field */
  stage: Stage
  /** Link target (defaults to _self) */
  target?: Maybe<Scalars['String']['output']>
  /** Call to action type */
  type?: Maybe<CallToActionType>
  url: Scalars['String']['output']
}

/** Link or button call to action configuration */
export type CallToActionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export enum CallToActionColor {
  Black = 'black',
  Mint = 'mint',
  Purple = 'purple',
  White = 'white',
  Yellow = 'yellow',
}

export type CallToActionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: CallToActionWhereUniqueInput
}

/** A connection to a list of items. */
export type CallToActionConnection = {
  __typename?: 'CallToActionConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<CallToActionEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type CallToActionCreateInput = {
  color?: InputMaybe<CallToActionColor>
  external?: InputMaybe<Scalars['Boolean']['input']>
  /** label input for default locale (de) */
  label: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CallToActionCreateLocalizationsInput>
  size?: InputMaybe<CallToActionSize>
  target?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CallToActionType>
  /** url input for default locale (de) */
  url: Scalars['String']['input']
}

export type CallToActionCreateLocalizationDataInput = {
  label: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type CallToActionCreateLocalizationInput = {
  /** Localization input */
  data: CallToActionCreateLocalizationDataInput
  locale: Locale
}

export type CallToActionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CallToActionCreateLocalizationInput>>
}

export type CallToActionCreateManyInlineInput = {
  /** Create and connect multiple existing CallToAction documents */
  create?: InputMaybe<Array<CallToActionCreateInput>>
}

export type CallToActionCreateOneInlineInput = {
  /** Create and connect one CallToAction document */
  create?: InputMaybe<CallToActionCreateInput>
}

export type CallToActionCreateWithPositionInput = {
  /** Document to create */
  data: CallToActionCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type CallToActionEdge = {
  __typename?: 'CallToActionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: CallToAction
}

/** Identifies documents */
export type CallToActionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CallToActionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CallToActionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CallToActionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  color?: InputMaybe<CallToActionColor>
  /** All values that are contained in given list. */
  color_in?: InputMaybe<Array<InputMaybe<CallToActionColor>>>
  /** Any other value that exists and is not equal to the given value. */
  color_not?: InputMaybe<CallToActionColor>
  /** All values that are not contained in given list. */
  color_not_in?: InputMaybe<Array<InputMaybe<CallToActionColor>>>
  external?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  external_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  size?: InputMaybe<CallToActionSize>
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CallToActionSize>>>
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CallToActionSize>
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CallToActionSize>>>
  target?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  target_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  target_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  target_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  target_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  target_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  target_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  target_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  target_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CallToActionType>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<CallToActionType>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<CallToActionType>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<CallToActionType>>>
}

export enum CallToActionOrderByInput {
  ColorAsc = 'color_ASC',
  ColorDesc = 'color_DESC',
  ExternalAsc = 'external_ASC',
  ExternalDesc = 'external_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  TargetAsc = 'target_ASC',
  TargetDesc = 'target_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type CallToActionParent =
  | ExplanationStep
  | HeaderMenuItem
  | JobListing
  | NewsletterAudience
  | SectionByTheNumbers
  | SectionExplanationBlocks
  | SectionFestivalTimetable
  | SectionHero
  | SectionIntro
  | SectionMyomrExhibitors
  | SectionNewsletter
  | SectionSpeakers
  | SectionTeaser
  | TextMedia
  | TextMediaBanner

export type CallToActionParentConnectInput = {
  ExplanationStep?: InputMaybe<ExplanationStepConnectInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemConnectInput>
  JobListing?: InputMaybe<JobListingConnectInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceConnectInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersConnectInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksConnectInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableConnectInput>
  SectionHero?: InputMaybe<SectionHeroConnectInput>
  SectionIntro?: InputMaybe<SectionIntroConnectInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsConnectInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterConnectInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersConnectInput>
  SectionTeaser?: InputMaybe<SectionTeaserConnectInput>
  TextMedia?: InputMaybe<TextMediaConnectInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerConnectInput>
}

export type CallToActionParentCreateInput = {
  ExplanationStep?: InputMaybe<ExplanationStepCreateInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemCreateInput>
  JobListing?: InputMaybe<JobListingCreateInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceCreateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableCreateInput>
  SectionHero?: InputMaybe<SectionHeroCreateInput>
  SectionIntro?: InputMaybe<SectionIntroCreateInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsCreateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterCreateInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersCreateInput>
  SectionTeaser?: InputMaybe<SectionTeaserCreateInput>
  TextMedia?: InputMaybe<TextMediaCreateInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerCreateInput>
}

export type CallToActionParentCreateManyInlineInput = {
  /** Connect multiple existing CallToActionParent documents */
  connect?: InputMaybe<Array<CallToActionParentWhereUniqueInput>>
  /** Create and connect multiple existing CallToActionParent documents */
  create?: InputMaybe<Array<CallToActionParentCreateInput>>
}

export type CallToActionParentCreateOneInlineInput = {
  /** Connect one existing CallToActionParent document */
  connect?: InputMaybe<CallToActionParentWhereUniqueInput>
  /** Create and connect one CallToActionParent document */
  create?: InputMaybe<CallToActionParentCreateInput>
}

export type CallToActionParentUpdateInput = {
  ExplanationStep?: InputMaybe<ExplanationStepUpdateInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateInput>
  JobListing?: InputMaybe<JobListingUpdateInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceUpdateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateInput>
  SectionHero?: InputMaybe<SectionHeroUpdateInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateInput>
  TextMedia?: InputMaybe<TextMediaUpdateInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateInput>
}

export type CallToActionParentUpdateManyInlineInput = {
  /** Connect multiple existing CallToActionParent documents */
  connect?: InputMaybe<Array<CallToActionParentConnectInput>>
  /** Create and connect multiple CallToActionParent documents */
  create?: InputMaybe<Array<CallToActionParentCreateInput>>
  /** Delete multiple CallToActionParent documents */
  delete?: InputMaybe<Array<CallToActionParentWhereUniqueInput>>
  /** Disconnect multiple CallToActionParent documents */
  disconnect?: InputMaybe<Array<CallToActionParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing CallToActionParent documents */
  set?: InputMaybe<Array<CallToActionParentWhereUniqueInput>>
  /** Update multiple CallToActionParent documents */
  update?: InputMaybe<Array<CallToActionParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple CallToActionParent documents */
  upsert?: InputMaybe<Array<CallToActionParentUpsertWithNestedWhereUniqueInput>>
}

export type CallToActionParentUpdateManyWithNestedWhereInput = {
  ExplanationStep?: InputMaybe<ExplanationStepUpdateManyWithNestedWhereInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateManyWithNestedWhereInput>
  JobListing?: InputMaybe<JobListingUpdateManyWithNestedWhereInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceUpdateManyWithNestedWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateManyWithNestedWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateManyWithNestedWhereInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateManyWithNestedWhereInput>
  SectionHero?: InputMaybe<SectionHeroUpdateManyWithNestedWhereInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateManyWithNestedWhereInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateManyWithNestedWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateManyWithNestedWhereInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateManyWithNestedWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateManyWithNestedWhereInput>
  TextMedia?: InputMaybe<TextMediaUpdateManyWithNestedWhereInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateManyWithNestedWhereInput>
}

export type CallToActionParentUpdateOneInlineInput = {
  /** Connect existing CallToActionParent document */
  connect?: InputMaybe<CallToActionParentWhereUniqueInput>
  /** Create and connect one CallToActionParent document */
  create?: InputMaybe<CallToActionParentCreateInput>
  /** Delete currently connected CallToActionParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected CallToActionParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single CallToActionParent document */
  update?: InputMaybe<CallToActionParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single CallToActionParent document */
  upsert?: InputMaybe<CallToActionParentUpsertWithNestedWhereUniqueInput>
}

export type CallToActionParentUpdateWithNestedWhereUniqueInput = {
  ExplanationStep?: InputMaybe<ExplanationStepUpdateWithNestedWhereUniqueInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateWithNestedWhereUniqueInput>
  JobListing?: InputMaybe<JobListingUpdateWithNestedWhereUniqueInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceUpdateWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateWithNestedWhereUniqueInput>
}

export type CallToActionParentUpsertWithNestedWhereUniqueInput = {
  ExplanationStep?: InputMaybe<ExplanationStepUpsertWithNestedWhereUniqueInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpsertWithNestedWhereUniqueInput>
  JobListing?: InputMaybe<JobListingUpsertWithNestedWhereUniqueInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceUpsertWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpsertWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpsertWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpsertWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpsertWithNestedWhereUniqueInput>
}

export type CallToActionParentWhereInput = {
  ExplanationStep?: InputMaybe<ExplanationStepWhereInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemWhereInput>
  JobListing?: InputMaybe<JobListingWhereInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableWhereInput>
  SectionHero?: InputMaybe<SectionHeroWhereInput>
  SectionIntro?: InputMaybe<SectionIntroWhereInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereInput>
  TextMedia?: InputMaybe<TextMediaWhereInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerWhereInput>
}

export type CallToActionParentWhereUniqueInput = {
  ExplanationStep?: InputMaybe<ExplanationStepWhereUniqueInput>
  HeaderMenuItem?: InputMaybe<HeaderMenuItemWhereUniqueInput>
  JobListing?: InputMaybe<JobListingWhereUniqueInput>
  NewsletterAudience?: InputMaybe<NewsletterAudienceWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerWhereUniqueInput>
}

export enum CallToActionSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

export enum CallToActionType {
  Ghost = 'ghost',
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
}

export type CallToActionUpdateInput = {
  color?: InputMaybe<CallToActionColor>
  external?: InputMaybe<Scalars['Boolean']['input']>
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<CallToActionUpdateLocalizationsInput>
  size?: InputMaybe<CallToActionSize>
  target?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CallToActionType>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type CallToActionUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type CallToActionUpdateLocalizationInput = {
  data: CallToActionUpdateLocalizationDataInput
  locale: Locale
}

export type CallToActionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CallToActionCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<CallToActionUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<CallToActionUpsertLocalizationInput>>
}

export type CallToActionUpdateManyInlineInput = {
  /** Create and connect multiple CallToAction component instances */
  create?: InputMaybe<Array<CallToActionCreateWithPositionInput>>
  /** Delete multiple CallToAction documents */
  delete?: InputMaybe<Array<CallToActionWhereUniqueInput>>
  /** Update multiple CallToAction component instances */
  update?: InputMaybe<Array<CallToActionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple CallToAction component instances */
  upsert?: InputMaybe<Array<CallToActionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type CallToActionUpdateManyInput = {
  color?: InputMaybe<CallToActionColor>
  external?: InputMaybe<Scalars['Boolean']['input']>
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<CallToActionUpdateManyLocalizationsInput>
  size?: InputMaybe<CallToActionSize>
  target?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CallToActionType>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type CallToActionUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type CallToActionUpdateManyLocalizationInput = {
  data: CallToActionUpdateManyLocalizationDataInput
  locale: Locale
}

export type CallToActionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CallToActionUpdateManyLocalizationInput>>
}

export type CallToActionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CallToActionUpdateManyInput
  /** Document search */
  where: CallToActionWhereInput
}

export type CallToActionUpdateOneInlineInput = {
  /** Create and connect one CallToAction document */
  create?: InputMaybe<CallToActionCreateInput>
  /** Delete currently connected CallToAction document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single CallToAction document */
  update?: InputMaybe<CallToActionUpdateWithNestedWhereUniqueInput>
  /** Upsert single CallToAction document */
  upsert?: InputMaybe<CallToActionUpsertWithNestedWhereUniqueInput>
}

export type CallToActionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CallToActionUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: CallToActionWhereUniqueInput
}

export type CallToActionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CallToActionUpdateInput
  /** Unique document search */
  where: CallToActionWhereUniqueInput
}

export type CallToActionUpsertInput = {
  /** Create document if it didn't exist */
  create: CallToActionCreateInput
  /** Update document if it exists */
  update: CallToActionUpdateInput
}

export type CallToActionUpsertLocalizationInput = {
  create: CallToActionCreateLocalizationDataInput
  locale: Locale
  update: CallToActionUpdateLocalizationDataInput
}

export type CallToActionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CallToActionUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: CallToActionWhereUniqueInput
}

export type CallToActionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CallToActionUpsertInput
  /** Unique document search */
  where: CallToActionWhereUniqueInput
}

/** Identifies documents */
export type CallToActionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CallToActionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CallToActionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CallToActionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  color?: InputMaybe<CallToActionColor>
  /** All values that are contained in given list. */
  color_in?: InputMaybe<Array<InputMaybe<CallToActionColor>>>
  /** Any other value that exists and is not equal to the given value. */
  color_not?: InputMaybe<CallToActionColor>
  /** All values that are not contained in given list. */
  color_not_in?: InputMaybe<Array<InputMaybe<CallToActionColor>>>
  external?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  external_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']['input']>
  size?: InputMaybe<CallToActionSize>
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CallToActionSize>>>
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CallToActionSize>
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CallToActionSize>>>
  target?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  target_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  target_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  target_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  target_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  target_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  target_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  target_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  target_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  target_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<CallToActionType>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<CallToActionType>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<CallToActionType>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<CallToActionType>>>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References CallToAction record uniquely */
export type CallToActionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color'
  css: Scalars['String']['output']
  hex: Scalars['Hex']['output']
  rgba: Rgba
}

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars['Hex']['input']>
  rgba?: InputMaybe<RgbaInput>
}

export enum ComponentStyle {
  Black = 'black',
  Blank = 'blank',
  Grey = 'grey',
  Purple = 'purple',
  White = 'white',
  Yellow = 'yellow',
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars['ID']['input']>
  /** Connect document before specified document */
  before?: InputMaybe<Scalars['ID']['input']>
  /** Connect document at last position */
  end?: InputMaybe<Scalars['Boolean']['input']>
  /** Connect document at first position */
  start?: InputMaybe<Scalars['Boolean']['input']>
}

export type Contact = Entity & {
  __typename?: 'Contact'
  /** Enable icons for contact details (phone and email). */
  contactIcons?: Maybe<Scalars['Boolean']['output']>
  contactPhoto: Asset
  /** Defines a decoration pattern to use with the component it is used for. */
  decorationPattern?: Maybe<DecorationPattern>
  department?: Maybe<Scalars['String']['output']>
  departmentUrl?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Contact>
  phone?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type ContactContactPhotoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ContactLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ContactConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ContactWhereUniqueInput
}

/** A connection to a list of items. */
export type ContactConnection = {
  __typename?: 'ContactConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ContactEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ContactCreateInput = {
  contactIcons?: InputMaybe<Scalars['Boolean']['input']>
  contactPhoto: AssetCreateOneInlineInput
  decorationPattern?: InputMaybe<DecorationPattern>
  /** department input for default locale (de) */
  department?: InputMaybe<Scalars['String']['input']>
  /** departmentUrl input for default locale (de) */
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  email?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ContactCreateLocalizationsInput>
  phone?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type ContactCreateLocalizationDataInput = {
  department?: InputMaybe<Scalars['String']['input']>
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type ContactCreateLocalizationInput = {
  /** Localization input */
  data: ContactCreateLocalizationDataInput
  locale: Locale
}

export type ContactCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ContactCreateLocalizationInput>>
}

export type ContactCreateManyInlineInput = {
  /** Create and connect multiple existing Contact documents */
  create?: InputMaybe<Array<ContactCreateInput>>
}

export type ContactCreateOneInlineInput = {
  /** Create and connect one Contact document */
  create?: InputMaybe<ContactCreateInput>
}

export type ContactCreateWithPositionInput = {
  /** Document to create */
  data: ContactCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ContactEdge = {
  __typename?: 'ContactEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Contact
}

/** Identifies documents */
export type ContactManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContactWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContactWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContactWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  contactIcons?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  contactIcons_not?: InputMaybe<Scalars['Boolean']['input']>
  contactPhoto?: InputMaybe<AssetWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  email?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum ContactOrderByInput {
  ContactIconsAsc = 'contactIcons_ASC',
  ContactIconsDesc = 'contactIcons_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DepartmentUrlAsc = 'departmentUrl_ASC',
  DepartmentUrlDesc = 'departmentUrl_DESC',
  DepartmentAsc = 'department_ASC',
  DepartmentDesc = 'department_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContactParent = SectionContact

export type ContactParentConnectInput = {
  SectionContact?: InputMaybe<SectionContactConnectInput>
}

export type ContactParentCreateInput = {
  SectionContact?: InputMaybe<SectionContactCreateInput>
}

export type ContactParentCreateManyInlineInput = {
  /** Create and connect multiple existing ContactParent documents */
  create?: InputMaybe<Array<ContactParentCreateInput>>
}

export type ContactParentCreateOneInlineInput = {
  /** Create and connect one ContactParent document */
  create?: InputMaybe<ContactParentCreateInput>
}

export type ContactParentCreateWithPositionInput = {
  SectionContact?: InputMaybe<SectionContactCreateWithPositionInput>
}

export type ContactParentUpdateInput = {
  SectionContact?: InputMaybe<SectionContactUpdateInput>
}

export type ContactParentUpdateManyInlineInput = {
  /** Create and connect multiple ContactParent component instances */
  create?: InputMaybe<Array<ContactParentCreateWithPositionInput>>
  /** Delete multiple ContactParent documents */
  delete?: InputMaybe<Array<ContactParentWhereUniqueInput>>
  /** Update multiple ContactParent component instances */
  update?: InputMaybe<Array<ContactParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ContactParent component instances */
  upsert?: InputMaybe<Array<ContactParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ContactParentUpdateManyWithNestedWhereInput = {
  SectionContact?: InputMaybe<SectionContactUpdateManyWithNestedWhereInput>
}

export type ContactParentUpdateOneInlineInput = {
  /** Create and connect one ContactParent document */
  create?: InputMaybe<ContactParentCreateInput>
  /** Delete currently connected ContactParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ContactParent document */
  update?: InputMaybe<ContactParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ContactParent document */
  upsert?: InputMaybe<ContactParentUpsertWithNestedWhereUniqueInput>
}

export type ContactParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueAndPositionInput>
}

export type ContactParentUpdateWithNestedWhereUniqueInput = {
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueInput>
}

export type ContactParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueAndPositionInput>
}

export type ContactParentUpsertWithNestedWhereUniqueInput = {
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueInput>
}

export type ContactParentWhereInput = {
  SectionContact?: InputMaybe<SectionContactWhereInput>
}

export type ContactParentWhereUniqueInput = {
  SectionContact?: InputMaybe<SectionContactWhereUniqueInput>
}

export type ContactUpdateInput = {
  contactIcons?: InputMaybe<Scalars['Boolean']['input']>
  contactPhoto?: InputMaybe<AssetUpdateOneInlineInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** department input for default locale (de) */
  department?: InputMaybe<Scalars['String']['input']>
  /** departmentUrl input for default locale (de) */
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ContactUpdateLocalizationsInput>
  phone?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContactUpdateLocalizationDataInput = {
  department?: InputMaybe<Scalars['String']['input']>
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContactUpdateLocalizationInput = {
  data: ContactUpdateLocalizationDataInput
  locale: Locale
}

export type ContactUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ContactCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ContactUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ContactUpsertLocalizationInput>>
}

export type ContactUpdateManyInlineInput = {
  /** Create and connect multiple Contact component instances */
  create?: InputMaybe<Array<ContactCreateWithPositionInput>>
  /** Delete multiple Contact documents */
  delete?: InputMaybe<Array<ContactWhereUniqueInput>>
  /** Update multiple Contact component instances */
  update?: InputMaybe<Array<ContactUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Contact component instances */
  upsert?: InputMaybe<Array<ContactUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ContactUpdateManyInput = {
  contactIcons?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** department input for default locale (de) */
  department?: InputMaybe<Scalars['String']['input']>
  /** departmentUrl input for default locale (de) */
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ContactUpdateManyLocalizationsInput>
  phone?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContactUpdateManyLocalizationDataInput = {
  department?: InputMaybe<Scalars['String']['input']>
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ContactUpdateManyLocalizationInput = {
  data: ContactUpdateManyLocalizationDataInput
  locale: Locale
}

export type ContactUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ContactUpdateManyLocalizationInput>>
}

export type ContactUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ContactUpdateManyInput
  /** Document search */
  where: ContactWhereInput
}

export type ContactUpdateOneInlineInput = {
  /** Create and connect one Contact document */
  create?: InputMaybe<ContactCreateInput>
  /** Delete currently connected Contact document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Contact document */
  update?: InputMaybe<ContactUpdateWithNestedWhereUniqueInput>
  /** Upsert single Contact document */
  upsert?: InputMaybe<ContactUpsertWithNestedWhereUniqueInput>
}

export type ContactUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ContactUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ContactWhereUniqueInput
}

export type ContactUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ContactUpdateInput
  /** Unique document search */
  where: ContactWhereUniqueInput
}

export type ContactUpsertInput = {
  /** Create document if it didn't exist */
  create: ContactCreateInput
  /** Update document if it exists */
  update: ContactUpdateInput
}

export type ContactUpsertLocalizationInput = {
  create: ContactCreateLocalizationDataInput
  locale: Locale
  update: ContactUpdateLocalizationDataInput
}

export type ContactUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ContactUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ContactWhereUniqueInput
}

export type ContactUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ContactUpsertInput
  /** Unique document search */
  where: ContactWhereUniqueInput
}

/** Identifies documents */
export type ContactWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContactWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContactWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContactWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  contactIcons?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  contactIcons_not?: InputMaybe<Scalars['Boolean']['input']>
  contactPhoto?: InputMaybe<AssetWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  department?: InputMaybe<Scalars['String']['input']>
  departmentUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  departmentUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  departmentUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  departmentUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  departmentUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  departmentUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  departmentUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  departmentUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  departmentUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  departmentUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  department_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  department_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  department_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  department_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  department_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  department_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  department_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  department_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  department_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  email_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  email_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  email_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  email_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  email_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  email_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  email_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  phone_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  phone_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  phone_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  phone_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  phone_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  phone_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  phone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  phone_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  phone_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References Contact record uniquely */
export type ContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Generic content reference */
export type ContentReference = Entity &
  Node & {
    __typename?: 'ContentReference'
    coverImage?: Maybe<Asset>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Description of the content reference */
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ContentReference>
    externalUrl: Scalars['String']['output']
    /** List of ContentReference versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<ContentReference>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    /** Please provide external reference if possible. Will make it easier for us to migrate content in the future. */
    reference?: Maybe<Scalars['String']['output']>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** Subtitle of the reference */
    subtitle?: Maybe<Scalars['String']['output']>
    /** Title of the reference */
    title: Scalars['String']['output']
    /** Type of the content reference. Will be used as tab name. */
    type: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Generic content reference */
export type ContentReferenceCoverImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Generic content reference */
export type ContentReferenceCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Generic content reference */
export type ContentReferenceCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Generic content reference */
export type ContentReferenceDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Generic content reference */
export type ContentReferenceHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Generic content reference */
export type ContentReferenceLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Generic content reference */
export type ContentReferencePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Generic content reference */
export type ContentReferencePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Generic content reference */
export type ContentReferenceScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Generic content reference */
export type ContentReferenceUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Generic content reference */
export type ContentReferenceUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ContentReferenceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ContentReferenceWhereUniqueInput
}

/** A connection to a list of items. */
export type ContentReferenceConnection = {
  __typename?: 'ContentReferenceConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ContentReferenceEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ContentReferenceCreateInput = {
  coverImage?: InputMaybe<AssetCreateOneInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** externalUrl input for default locale (de) */
  externalUrl: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ContentReferenceCreateLocalizationsInput>
  reference?: InputMaybe<Scalars['String']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  /** type input for default locale (de) */
  type: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ContentReferenceCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  externalUrl: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  type: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ContentReferenceCreateLocalizationInput = {
  /** Localization input */
  data: ContentReferenceCreateLocalizationDataInput
  locale: Locale
}

export type ContentReferenceCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ContentReferenceCreateLocalizationInput>>
}

export type ContentReferenceCreateManyInlineInput = {
  /** Connect multiple existing ContentReference documents */
  connect?: InputMaybe<Array<ContentReferenceWhereUniqueInput>>
  /** Create and connect multiple existing ContentReference documents */
  create?: InputMaybe<Array<ContentReferenceCreateInput>>
}

export type ContentReferenceCreateOneInlineInput = {
  /** Connect one existing ContentReference document */
  connect?: InputMaybe<ContentReferenceWhereUniqueInput>
  /** Create and connect one ContentReference document */
  create?: InputMaybe<ContentReferenceCreateInput>
}

/** An edge in a connection. */
export type ContentReferenceEdge = {
  __typename?: 'ContentReferenceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ContentReference
}

/** Identifies documents */
export type ContentReferenceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ContentReferenceWhereStageInput>
  documentInStages_none?: InputMaybe<ContentReferenceWhereStageInput>
  documentInStages_some?: InputMaybe<ContentReferenceWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reference?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  reference_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  reference_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  reference_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  reference_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  reference_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  reference_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  reference_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  reference_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ContentReferenceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReferenceAsc = 'reference_ASC',
  ReferenceDesc = 'reference_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ContentReferenceUpdateInput = {
  coverImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** externalUrl input for default locale (de) */
  externalUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ContentReferenceUpdateLocalizationsInput>
  reference?: InputMaybe<Scalars['String']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** type input for default locale (de) */
  type?: InputMaybe<Scalars['String']['input']>
}

export type ContentReferenceUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  externalUrl?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ContentReferenceUpdateLocalizationInput = {
  data: ContentReferenceUpdateLocalizationDataInput
  locale: Locale
}

export type ContentReferenceUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ContentReferenceCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ContentReferenceUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ContentReferenceUpsertLocalizationInput>>
}

export type ContentReferenceUpdateManyInlineInput = {
  /** Connect multiple existing ContentReference documents */
  connect?: InputMaybe<Array<ContentReferenceConnectInput>>
  /** Create and connect multiple ContentReference documents */
  create?: InputMaybe<Array<ContentReferenceCreateInput>>
  /** Delete multiple ContentReference documents */
  delete?: InputMaybe<Array<ContentReferenceWhereUniqueInput>>
  /** Disconnect multiple ContentReference documents */
  disconnect?: InputMaybe<Array<ContentReferenceWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ContentReference documents */
  set?: InputMaybe<Array<ContentReferenceWhereUniqueInput>>
  /** Update multiple ContentReference documents */
  update?: InputMaybe<Array<ContentReferenceUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ContentReference documents */
  upsert?: InputMaybe<Array<ContentReferenceUpsertWithNestedWhereUniqueInput>>
}

export type ContentReferenceUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** externalUrl input for default locale (de) */
  externalUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ContentReferenceUpdateManyLocalizationsInput>
  reference?: InputMaybe<Scalars['String']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** type input for default locale (de) */
  type?: InputMaybe<Scalars['String']['input']>
}

export type ContentReferenceUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  externalUrl?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}

export type ContentReferenceUpdateManyLocalizationInput = {
  data: ContentReferenceUpdateManyLocalizationDataInput
  locale: Locale
}

export type ContentReferenceUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ContentReferenceUpdateManyLocalizationInput>>
}

export type ContentReferenceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ContentReferenceUpdateManyInput
  /** Document search */
  where: ContentReferenceWhereInput
}

export type ContentReferenceUpdateOneInlineInput = {
  /** Connect existing ContentReference document */
  connect?: InputMaybe<ContentReferenceWhereUniqueInput>
  /** Create and connect one ContentReference document */
  create?: InputMaybe<ContentReferenceCreateInput>
  /** Delete currently connected ContentReference document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ContentReference document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ContentReference document */
  update?: InputMaybe<ContentReferenceUpdateWithNestedWhereUniqueInput>
  /** Upsert single ContentReference document */
  upsert?: InputMaybe<ContentReferenceUpsertWithNestedWhereUniqueInput>
}

export type ContentReferenceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ContentReferenceUpdateInput
  /** Unique document search */
  where: ContentReferenceWhereUniqueInput
}

export type ContentReferenceUpsertInput = {
  /** Create document if it didn't exist */
  create: ContentReferenceCreateInput
  /** Update document if it exists */
  update: ContentReferenceUpdateInput
}

export type ContentReferenceUpsertLocalizationInput = {
  create: ContentReferenceCreateLocalizationDataInput
  locale: Locale
  update: ContentReferenceUpdateLocalizationDataInput
}

export type ContentReferenceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ContentReferenceUpsertInput
  /** Unique document search */
  where: ContentReferenceWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ContentReferenceWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ContentReferenceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentReferenceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<ContentReferenceWhereStageInput>
  documentInStages_none?: InputMaybe<ContentReferenceWhereStageInput>
  documentInStages_some?: InputMaybe<ContentReferenceWhereStageInput>
  externalUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  externalUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  externalUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  externalUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  externalUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  externalUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  externalUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  externalUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  externalUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  externalUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reference?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  reference_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  reference_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  reference_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  reference_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  reference_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  reference_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  reference_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  reference_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  reference_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  type_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  type_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  type_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  type_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ContentReferenceWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ContentReferenceWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ContentReferenceWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ContentReferenceWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ContentReferenceWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ContentReference record uniquely */
export type ContentReferenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum CustomerType {
  BrandedRoomBooth = 'BrandedRoomBooth',
  BreakEvent = 'BreakEvent',
  CareerMeetup = 'CareerMeetup',
  CoexhibitorBooth = 'CoexhibitorBooth',
  DeepDive = 'DeepDive',
  DigitalMasterclass = 'DigitalMasterclass',
  Event = 'Event',
  GastroBooth = 'GastroBooth',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  PremiumBooth = 'PremiumBooth',
  PremiumLoungeBooth = 'PremiumLoungeBooth',
  PremiumOutdoorBooth = 'PremiumOutdoorBooth',
  SamplingBooth = 'SamplingBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  SideEvent = 'SideEvent',
  StageSlot = 'StageSlot',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

/** Defines a decoration pattern to use with the component is is used for. */
export enum DecorationPattern {
  AboutFestivalSlider = 'aboutFestivalSlider',
  ArtistsSlider = 'artistsSlider',
  Bahn = 'bahn',
  ContactPurple = 'contactPurple',
  ContactWhite = 'contactWhite',
  Exhibitor = 'exhibitor',
  GetApp = 'getApp',
  Getaticket = 'getaticket',
  HomeFullwidthSlider = 'homeFullwidthSlider',
  NoDecorationFaq = 'noDecorationFaq',
  Omr24CtaRocket = 'omr24CTARocket',
  Omr24CtaSign = 'omr24CTASign',
  Omr24CtaSubmarine = 'omr24CTASubmarine',
  Omr24Contact = 'omr24Contact',
  Omr24CtaApp = 'omr24CtaApp',
  Omr24CtaHotels = 'omr24CtaHotels',
  Omr24Faq = 'omr24Faq',
  Omr24Newsletter = 'omr24Newsletter',
  Omr24SubscibeTeaser = 'omr24SubscibeTeaser',
  PremiumPartner = 'premiumPartner',
  SustainabilityFaq = 'sustainabilityFAQ',
  SustainabilityFaqIntro = 'sustainabilityFAQIntro',
  SustainabilityIntro = 'sustainabilityIntro',
  SustainabilitySticky = 'sustainabilitySticky',
  TextMediaBanner = 'textMediaBanner',
  TextMediaBannerFullWidth = 'textMediaBannerFullWidth',
}

export enum DecorationStyle {
  Circle = 'circle',
  Cross = 'cross',
  MatchBubbles = 'matchBubbles',
  None = 'none',
  Square = 'square',
  Triangle = 'triangle',
}

export enum DocumentFileTypes {
  Doc = 'doc',
  Docx = 'docx',
  Html = 'html',
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Xls = 'xls',
  Xlsx = 'xlsx',
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>
}

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>
}

export type DocumentVersion = {
  __typename?: 'DocumentVersion'
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<Scalars['Json']['output']>
  id: Scalars['ID']['output']
  revision: Scalars['Int']['output']
  stage: Stage
}

export type Download = Entity & {
  __typename?: 'Download'
  date: Scalars['DateTime']['output']
  description: Scalars['String']['output']
  downloadDe?: Maybe<Asset>
  downloadEn?: Maybe<Asset>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Download>
  previewimage?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type DownloadDownloadDeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type DownloadDownloadEnArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type DownloadLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type DownloadPreviewimageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type DownloadConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: DownloadWhereUniqueInput
}

/** A connection to a list of items. */
export type DownloadConnection = {
  __typename?: 'DownloadConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<DownloadEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type DownloadCreateInput = {
  date: Scalars['DateTime']['input']
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  downloadDe?: InputMaybe<AssetCreateOneInlineInput>
  downloadEn?: InputMaybe<AssetCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DownloadCreateLocalizationsInput>
  previewimage?: InputMaybe<AssetCreateOneInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type DownloadCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type DownloadCreateLocalizationInput = {
  /** Localization input */
  data: DownloadCreateLocalizationDataInput
  locale: Locale
}

export type DownloadCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DownloadCreateLocalizationInput>>
}

export type DownloadCreateManyInlineInput = {
  /** Create and connect multiple existing Download documents */
  create?: InputMaybe<Array<DownloadCreateInput>>
}

export type DownloadCreateOneInlineInput = {
  /** Create and connect one Download document */
  create?: InputMaybe<DownloadCreateInput>
}

export type DownloadCreateWithPositionInput = {
  /** Document to create */
  data: DownloadCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type DownloadEdge = {
  __typename?: 'DownloadEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Download
}

/** Identifies documents */
export type DownloadManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DownloadWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DownloadWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DownloadWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  downloadDe?: InputMaybe<AssetWhereInput>
  downloadEn?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  previewimage?: InputMaybe<AssetWhereInput>
}

export enum DownloadOrderByInput {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DownloadParent = SectionDownloads | TabContent

export type DownloadParentConnectInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsConnectInput>
  TabContent?: InputMaybe<TabContentConnectInput>
}

export type DownloadParentCreateInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsCreateInput>
  TabContent?: InputMaybe<TabContentCreateInput>
}

export type DownloadParentCreateManyInlineInput = {
  /** Connect multiple existing DownloadParent documents */
  connect?: InputMaybe<Array<DownloadParentWhereUniqueInput>>
  /** Create and connect multiple existing DownloadParent documents */
  create?: InputMaybe<Array<DownloadParentCreateInput>>
}

export type DownloadParentCreateOneInlineInput = {
  /** Connect one existing DownloadParent document */
  connect?: InputMaybe<DownloadParentWhereUniqueInput>
  /** Create and connect one DownloadParent document */
  create?: InputMaybe<DownloadParentCreateInput>
}

export type DownloadParentUpdateInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateInput>
  TabContent?: InputMaybe<TabContentUpdateInput>
}

export type DownloadParentUpdateManyInlineInput = {
  /** Connect multiple existing DownloadParent documents */
  connect?: InputMaybe<Array<DownloadParentConnectInput>>
  /** Create and connect multiple DownloadParent documents */
  create?: InputMaybe<Array<DownloadParentCreateInput>>
  /** Delete multiple DownloadParent documents */
  delete?: InputMaybe<Array<DownloadParentWhereUniqueInput>>
  /** Disconnect multiple DownloadParent documents */
  disconnect?: InputMaybe<Array<DownloadParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing DownloadParent documents */
  set?: InputMaybe<Array<DownloadParentWhereUniqueInput>>
  /** Update multiple DownloadParent documents */
  update?: InputMaybe<Array<DownloadParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple DownloadParent documents */
  upsert?: InputMaybe<Array<DownloadParentUpsertWithNestedWhereUniqueInput>>
}

export type DownloadParentUpdateManyWithNestedWhereInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateManyWithNestedWhereInput>
  TabContent?: InputMaybe<TabContentUpdateManyWithNestedWhereInput>
}

export type DownloadParentUpdateOneInlineInput = {
  /** Connect existing DownloadParent document */
  connect?: InputMaybe<DownloadParentWhereUniqueInput>
  /** Create and connect one DownloadParent document */
  create?: InputMaybe<DownloadParentCreateInput>
  /** Delete currently connected DownloadParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected DownloadParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single DownloadParent document */
  update?: InputMaybe<DownloadParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single DownloadParent document */
  upsert?: InputMaybe<DownloadParentUpsertWithNestedWhereUniqueInput>
}

export type DownloadParentUpdateWithNestedWhereUniqueInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateWithNestedWhereUniqueInput>
  TabContent?: InputMaybe<TabContentUpdateWithNestedWhereUniqueInput>
}

export type DownloadParentUpsertWithNestedWhereUniqueInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsUpsertWithNestedWhereUniqueInput>
  TabContent?: InputMaybe<TabContentUpsertWithNestedWhereUniqueInput>
}

export type DownloadParentWhereInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsWhereInput>
  TabContent?: InputMaybe<TabContentWhereInput>
}

export type DownloadParentWhereUniqueInput = {
  SectionDownloads?: InputMaybe<SectionDownloadsWhereUniqueInput>
  TabContent?: InputMaybe<TabContentWhereUniqueInput>
}

export type DownloadUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  downloadDe?: InputMaybe<AssetUpdateOneInlineInput>
  downloadEn?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<DownloadUpdateLocalizationsInput>
  previewimage?: InputMaybe<AssetUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DownloadUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type DownloadUpdateLocalizationInput = {
  data: DownloadUpdateLocalizationDataInput
  locale: Locale
}

export type DownloadUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DownloadCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<DownloadUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<DownloadUpsertLocalizationInput>>
}

export type DownloadUpdateManyInlineInput = {
  /** Create and connect multiple Download component instances */
  create?: InputMaybe<Array<DownloadCreateWithPositionInput>>
  /** Delete multiple Download documents */
  delete?: InputMaybe<Array<DownloadWhereUniqueInput>>
  /** Update multiple Download component instances */
  update?: InputMaybe<Array<DownloadUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Download component instances */
  upsert?: InputMaybe<Array<DownloadUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type DownloadUpdateManyInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<DownloadUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type DownloadUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type DownloadUpdateManyLocalizationInput = {
  data: DownloadUpdateManyLocalizationDataInput
  locale: Locale
}

export type DownloadUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DownloadUpdateManyLocalizationInput>>
}

export type DownloadUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DownloadUpdateManyInput
  /** Document search */
  where: DownloadWhereInput
}

export type DownloadUpdateOneInlineInput = {
  /** Create and connect one Download document */
  create?: InputMaybe<DownloadCreateInput>
  /** Delete currently connected Download document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Download document */
  update?: InputMaybe<DownloadUpdateWithNestedWhereUniqueInput>
  /** Upsert single Download document */
  upsert?: InputMaybe<DownloadUpsertWithNestedWhereUniqueInput>
}

export type DownloadUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DownloadUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: DownloadWhereUniqueInput
}

export type DownloadUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DownloadUpdateInput
  /** Unique document search */
  where: DownloadWhereUniqueInput
}

export type DownloadUpsertInput = {
  /** Create document if it didn't exist */
  create: DownloadCreateInput
  /** Update document if it exists */
  update: DownloadUpdateInput
}

export type DownloadUpsertLocalizationInput = {
  create: DownloadCreateLocalizationDataInput
  locale: Locale
  update: DownloadUpdateLocalizationDataInput
}

export type DownloadUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DownloadUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: DownloadWhereUniqueInput
}

export type DownloadUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DownloadUpsertInput
  /** Unique document search */
  where: DownloadWhereUniqueInput
}

/** Identifies documents */
export type DownloadWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DownloadWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DownloadWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DownloadWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  downloadDe?: InputMaybe<AssetWhereInput>
  downloadEn?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  previewimage?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References Download record uniquely */
export type DownloadWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EducationCard = Entity & {
  __typename?: 'EducationCard'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

export type EducationCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EducationCardWhereUniqueInput
}

/** A connection to a list of items. */
export type EducationCardConnection = {
  __typename?: 'EducationCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EducationCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EducationCardCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type EducationCardCreateManyInlineInput = {
  /** Create and connect multiple existing EducationCard documents */
  create?: InputMaybe<Array<EducationCardCreateInput>>
}

export type EducationCardCreateOneInlineInput = {
  /** Create and connect one EducationCard document */
  create?: InputMaybe<EducationCardCreateInput>
}

export type EducationCardCreateWithPositionInput = {
  /** Document to create */
  data: EducationCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type EducationCardEdge = {
  __typename?: 'EducationCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EducationCard
}

/** Identifies documents */
export type EducationCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum EducationCardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type EducationCardParent = SectionCollab

export type EducationCardParentConnectInput = {
  SectionCollab?: InputMaybe<SectionCollabConnectInput>
}

export type EducationCardParentCreateInput = {
  SectionCollab?: InputMaybe<SectionCollabCreateInput>
}

export type EducationCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing EducationCardParent documents */
  create?: InputMaybe<Array<EducationCardParentCreateInput>>
}

export type EducationCardParentCreateOneInlineInput = {
  /** Create and connect one EducationCardParent document */
  create?: InputMaybe<EducationCardParentCreateInput>
}

export type EducationCardParentCreateWithPositionInput = {
  SectionCollab?: InputMaybe<SectionCollabCreateWithPositionInput>
}

export type EducationCardParentUpdateInput = {
  SectionCollab?: InputMaybe<SectionCollabUpdateInput>
}

export type EducationCardParentUpdateManyInlineInput = {
  /** Create and connect multiple EducationCardParent component instances */
  create?: InputMaybe<Array<EducationCardParentCreateWithPositionInput>>
  /** Delete multiple EducationCardParent documents */
  delete?: InputMaybe<Array<EducationCardParentWhereUniqueInput>>
  /** Update multiple EducationCardParent component instances */
  update?: InputMaybe<Array<EducationCardParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EducationCardParent component instances */
  upsert?: InputMaybe<Array<EducationCardParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EducationCardParentUpdateManyWithNestedWhereInput = {
  SectionCollab?: InputMaybe<SectionCollabUpdateManyWithNestedWhereInput>
}

export type EducationCardParentUpdateOneInlineInput = {
  /** Create and connect one EducationCardParent document */
  create?: InputMaybe<EducationCardParentCreateInput>
  /** Delete currently connected EducationCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EducationCardParent document */
  update?: InputMaybe<EducationCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single EducationCardParent document */
  upsert?: InputMaybe<EducationCardParentUpsertWithNestedWhereUniqueInput>
}

export type EducationCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionCollab?: InputMaybe<SectionCollabUpdateWithNestedWhereUniqueAndPositionInput>
}

export type EducationCardParentUpdateWithNestedWhereUniqueInput = {
  SectionCollab?: InputMaybe<SectionCollabUpdateWithNestedWhereUniqueInput>
}

export type EducationCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionCollab?: InputMaybe<SectionCollabUpsertWithNestedWhereUniqueAndPositionInput>
}

export type EducationCardParentUpsertWithNestedWhereUniqueInput = {
  SectionCollab?: InputMaybe<SectionCollabUpsertWithNestedWhereUniqueInput>
}

export type EducationCardParentWhereInput = {
  SectionCollab?: InputMaybe<SectionCollabWhereInput>
}

export type EducationCardParentWhereUniqueInput = {
  SectionCollab?: InputMaybe<SectionCollabWhereUniqueInput>
}

export type EducationCardUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type EducationCardUpdateManyInlineInput = {
  /** Create and connect multiple EducationCard component instances */
  create?: InputMaybe<Array<EducationCardCreateWithPositionInput>>
  /** Delete multiple EducationCard documents */
  delete?: InputMaybe<Array<EducationCardWhereUniqueInput>>
  /** Update multiple EducationCard component instances */
  update?: InputMaybe<Array<EducationCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EducationCard component instances */
  upsert?: InputMaybe<Array<EducationCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EducationCardUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type EducationCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EducationCardUpdateManyInput
  /** Document search */
  where: EducationCardWhereInput
}

export type EducationCardUpdateOneInlineInput = {
  /** Create and connect one EducationCard document */
  create?: InputMaybe<EducationCardCreateInput>
  /** Delete currently connected EducationCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EducationCard document */
  update?: InputMaybe<EducationCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single EducationCard document */
  upsert?: InputMaybe<EducationCardUpsertWithNestedWhereUniqueInput>
}

export type EducationCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<EducationCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EducationCardWhereUniqueInput
}

export type EducationCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EducationCardUpdateInput
  /** Unique document search */
  where: EducationCardWhereUniqueInput
}

export type EducationCardUpsertInput = {
  /** Create document if it didn't exist */
  create: EducationCardCreateInput
  /** Update document if it exists */
  update: EducationCardUpdateInput
}

export type EducationCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<EducationCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EducationCardWhereUniqueInput
}

export type EducationCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EducationCardUpsertInput
  /** Unique document search */
  where: EducationCardWhereUniqueInput
}

/** Identifies documents */
export type EducationCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References EducationCard record uniquely */
export type EducationCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Educational report */
export type EducationReport = Entity &
  Node & {
    __typename?: 'EducationReport'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    currency: Scalars['String']['output']
    /** Description of the report */
    description: Scalars['String']['output']
    /** Get the document in other stages */
    documentInStages: Array<EducationReport>
    /** List of EducationReport versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    imageUrl: Scalars['String']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<EducationReport>
    onlineStoreUrl: Scalars['String']['output']
    price: Scalars['Float']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** Title of the report */
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Educational report */
export type EducationReportCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational report */
export type EducationReportCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Educational report */
export type EducationReportDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Educational report */
export type EducationReportHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Educational report */
export type EducationReportLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Educational report */
export type EducationReportPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational report */
export type EducationReportPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Educational report */
export type EducationReportScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Educational report */
export type EducationReportUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational report */
export type EducationReportUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EducationReportConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EducationReportWhereUniqueInput
}

/** A connection to a list of items. */
export type EducationReportConnection = {
  __typename?: 'EducationReportConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EducationReportEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EducationReportCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  currency: Scalars['String']['input']
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  imageUrl: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EducationReportCreateLocalizationsInput>
  /** onlineStoreUrl input for default locale (de) */
  onlineStoreUrl: Scalars['String']['input']
  price: Scalars['Float']['input']
  slug: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EducationReportCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description: Scalars['String']['input']
  onlineStoreUrl: Scalars['String']['input']
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EducationReportCreateLocalizationInput = {
  /** Localization input */
  data: EducationReportCreateLocalizationDataInput
  locale: Locale
}

export type EducationReportCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EducationReportCreateLocalizationInput>>
}

export type EducationReportCreateManyInlineInput = {
  /** Connect multiple existing EducationReport documents */
  connect?: InputMaybe<Array<EducationReportWhereUniqueInput>>
  /** Create and connect multiple existing EducationReport documents */
  create?: InputMaybe<Array<EducationReportCreateInput>>
}

export type EducationReportCreateOneInlineInput = {
  /** Connect one existing EducationReport document */
  connect?: InputMaybe<EducationReportWhereUniqueInput>
  /** Create and connect one EducationReport document */
  create?: InputMaybe<EducationReportCreateInput>
}

/** An edge in a connection. */
export type EducationReportEdge = {
  __typename?: 'EducationReportEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EducationReport
}

/** Identifies documents */
export type EducationReportManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationReportWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationReportWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationReportWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  currency?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  currency_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  currency_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  currency_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  currency_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<EducationReportWhereStageInput>
  documentInStages_none?: InputMaybe<EducationReportWhereStageInput>
  documentInStages_some?: InputMaybe<EducationReportWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  price_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  price_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  price_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  price_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  price_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum EducationReportOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageUrlAsc = 'imageUrl_ASC',
  ImageUrlDesc = 'imageUrl_DESC',
  OnlineStoreUrlAsc = 'onlineStoreUrl_ASC',
  OnlineStoreUrlDesc = 'onlineStoreUrl_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EducationReportUpdateInput = {
  currency?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<EducationReportUpdateLocalizationsInput>
  /** onlineStoreUrl input for default locale (de) */
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationReportUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationReportUpdateLocalizationInput = {
  data: EducationReportUpdateLocalizationDataInput
  locale: Locale
}

export type EducationReportUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EducationReportCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EducationReportUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EducationReportUpsertLocalizationInput>>
}

export type EducationReportUpdateManyInlineInput = {
  /** Connect multiple existing EducationReport documents */
  connect?: InputMaybe<Array<EducationReportConnectInput>>
  /** Create and connect multiple EducationReport documents */
  create?: InputMaybe<Array<EducationReportCreateInput>>
  /** Delete multiple EducationReport documents */
  delete?: InputMaybe<Array<EducationReportWhereUniqueInput>>
  /** Disconnect multiple EducationReport documents */
  disconnect?: InputMaybe<Array<EducationReportWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EducationReport documents */
  set?: InputMaybe<Array<EducationReportWhereUniqueInput>>
  /** Update multiple EducationReport documents */
  update?: InputMaybe<Array<EducationReportUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EducationReport documents */
  upsert?: InputMaybe<Array<EducationReportUpsertWithNestedWhereUniqueInput>>
}

export type EducationReportUpdateManyInput = {
  currency?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<EducationReportUpdateManyLocalizationsInput>
  price?: InputMaybe<Scalars['Float']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationReportUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationReportUpdateManyLocalizationInput = {
  data: EducationReportUpdateManyLocalizationDataInput
  locale: Locale
}

export type EducationReportUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EducationReportUpdateManyLocalizationInput>>
}

export type EducationReportUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EducationReportUpdateManyInput
  /** Document search */
  where: EducationReportWhereInput
}

export type EducationReportUpdateOneInlineInput = {
  /** Connect existing EducationReport document */
  connect?: InputMaybe<EducationReportWhereUniqueInput>
  /** Create and connect one EducationReport document */
  create?: InputMaybe<EducationReportCreateInput>
  /** Delete currently connected EducationReport document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EducationReport document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EducationReport document */
  update?: InputMaybe<EducationReportUpdateWithNestedWhereUniqueInput>
  /** Upsert single EducationReport document */
  upsert?: InputMaybe<EducationReportUpsertWithNestedWhereUniqueInput>
}

export type EducationReportUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EducationReportUpdateInput
  /** Unique document search */
  where: EducationReportWhereUniqueInput
}

export type EducationReportUpsertInput = {
  /** Create document if it didn't exist */
  create: EducationReportCreateInput
  /** Update document if it exists */
  update: EducationReportUpdateInput
}

export type EducationReportUpsertLocalizationInput = {
  create: EducationReportCreateLocalizationDataInput
  locale: Locale
  update: EducationReportUpdateLocalizationDataInput
}

export type EducationReportUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EducationReportUpsertInput
  /** Unique document search */
  where: EducationReportWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EducationReportWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EducationReportWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationReportWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationReportWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationReportWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  currency?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  currency_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  currency_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  currency_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  currency_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<EducationReportWhereStageInput>
  documentInStages_none?: InputMaybe<EducationReportWhereStageInput>
  documentInStages_some?: InputMaybe<EducationReportWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  onlineStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  onlineStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  onlineStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  onlineStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  onlineStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  onlineStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  onlineStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  onlineStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  onlineStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  price_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  price_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  price_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  price_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  price_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EducationReportWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationReportWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationReportWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationReportWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EducationReportWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References EducationReport record uniquely */
export type EducationReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Educational seminar */
export type EducationSeminar = Entity &
  Node & {
    __typename?: 'EducationSeminar'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    currency: Scalars['String']['output']
    /** Description of the seminar */
    description: Scalars['String']['output']
    /** Get the document in other stages */
    documentInStages: Array<EducationSeminar>
    /** List of EducationSeminar versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    imageUrl: Scalars['String']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<EducationSeminar>
    onlineStoreUrl: Scalars['String']['output']
    price: Scalars['Float']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** Title of the seminar */
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Educational seminar */
export type EducationSeminarCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational seminar */
export type EducationSeminarCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Educational seminar */
export type EducationSeminarDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Educational seminar */
export type EducationSeminarHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Educational seminar */
export type EducationSeminarLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Educational seminar */
export type EducationSeminarPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational seminar */
export type EducationSeminarPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Educational seminar */
export type EducationSeminarScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Educational seminar */
export type EducationSeminarUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Educational seminar */
export type EducationSeminarUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EducationSeminarConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EducationSeminarWhereUniqueInput
}

/** A connection to a list of items. */
export type EducationSeminarConnection = {
  __typename?: 'EducationSeminarConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EducationSeminarEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EducationSeminarCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  currency: Scalars['String']['input']
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  imageUrl: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EducationSeminarCreateLocalizationsInput>
  /** onlineStoreUrl input for default locale (de) */
  onlineStoreUrl: Scalars['String']['input']
  price: Scalars['Float']['input']
  slug: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EducationSeminarCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description: Scalars['String']['input']
  onlineStoreUrl: Scalars['String']['input']
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EducationSeminarCreateLocalizationInput = {
  /** Localization input */
  data: EducationSeminarCreateLocalizationDataInput
  locale: Locale
}

export type EducationSeminarCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EducationSeminarCreateLocalizationInput>>
}

export type EducationSeminarCreateManyInlineInput = {
  /** Connect multiple existing EducationSeminar documents */
  connect?: InputMaybe<Array<EducationSeminarWhereUniqueInput>>
  /** Create and connect multiple existing EducationSeminar documents */
  create?: InputMaybe<Array<EducationSeminarCreateInput>>
}

export type EducationSeminarCreateOneInlineInput = {
  /** Connect one existing EducationSeminar document */
  connect?: InputMaybe<EducationSeminarWhereUniqueInput>
  /** Create and connect one EducationSeminar document */
  create?: InputMaybe<EducationSeminarCreateInput>
}

/** An edge in a connection. */
export type EducationSeminarEdge = {
  __typename?: 'EducationSeminarEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EducationSeminar
}

/** Identifies documents */
export type EducationSeminarManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  currency?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  currency_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  currency_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  currency_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  currency_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<EducationSeminarWhereStageInput>
  documentInStages_none?: InputMaybe<EducationSeminarWhereStageInput>
  documentInStages_some?: InputMaybe<EducationSeminarWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  price_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  price_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  price_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  price_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  price_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum EducationSeminarOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageUrlAsc = 'imageUrl_ASC',
  ImageUrlDesc = 'imageUrl_DESC',
  OnlineStoreUrlAsc = 'onlineStoreUrl_ASC',
  OnlineStoreUrlDesc = 'onlineStoreUrl_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EducationSeminarUpdateInput = {
  currency?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<EducationSeminarUpdateLocalizationsInput>
  /** onlineStoreUrl input for default locale (de) */
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationSeminarUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationSeminarUpdateLocalizationInput = {
  data: EducationSeminarUpdateLocalizationDataInput
  locale: Locale
}

export type EducationSeminarUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EducationSeminarCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EducationSeminarUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EducationSeminarUpsertLocalizationInput>>
}

export type EducationSeminarUpdateManyInlineInput = {
  /** Connect multiple existing EducationSeminar documents */
  connect?: InputMaybe<Array<EducationSeminarConnectInput>>
  /** Create and connect multiple EducationSeminar documents */
  create?: InputMaybe<Array<EducationSeminarCreateInput>>
  /** Delete multiple EducationSeminar documents */
  delete?: InputMaybe<Array<EducationSeminarWhereUniqueInput>>
  /** Disconnect multiple EducationSeminar documents */
  disconnect?: InputMaybe<Array<EducationSeminarWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EducationSeminar documents */
  set?: InputMaybe<Array<EducationSeminarWhereUniqueInput>>
  /** Update multiple EducationSeminar documents */
  update?: InputMaybe<Array<EducationSeminarUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EducationSeminar documents */
  upsert?: InputMaybe<Array<EducationSeminarUpsertWithNestedWhereUniqueInput>>
}

export type EducationSeminarUpdateManyInput = {
  currency?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<EducationSeminarUpdateManyLocalizationsInput>
  price?: InputMaybe<Scalars['Float']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationSeminarUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EducationSeminarUpdateManyLocalizationInput = {
  data: EducationSeminarUpdateManyLocalizationDataInput
  locale: Locale
}

export type EducationSeminarUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EducationSeminarUpdateManyLocalizationInput>>
}

export type EducationSeminarUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EducationSeminarUpdateManyInput
  /** Document search */
  where: EducationSeminarWhereInput
}

export type EducationSeminarUpdateOneInlineInput = {
  /** Connect existing EducationSeminar document */
  connect?: InputMaybe<EducationSeminarWhereUniqueInput>
  /** Create and connect one EducationSeminar document */
  create?: InputMaybe<EducationSeminarCreateInput>
  /** Delete currently connected EducationSeminar document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EducationSeminar document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EducationSeminar document */
  update?: InputMaybe<EducationSeminarUpdateWithNestedWhereUniqueInput>
  /** Upsert single EducationSeminar document */
  upsert?: InputMaybe<EducationSeminarUpsertWithNestedWhereUniqueInput>
}

export type EducationSeminarUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EducationSeminarUpdateInput
  /** Unique document search */
  where: EducationSeminarWhereUniqueInput
}

export type EducationSeminarUpsertInput = {
  /** Create document if it didn't exist */
  create: EducationSeminarCreateInput
  /** Update document if it exists */
  update: EducationSeminarUpdateInput
}

export type EducationSeminarUpsertLocalizationInput = {
  create: EducationSeminarCreateLocalizationDataInput
  locale: Locale
  update: EducationSeminarUpdateLocalizationDataInput
}

export type EducationSeminarUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EducationSeminarUpsertInput
  /** Unique document search */
  where: EducationSeminarWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EducationSeminarWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EducationSeminarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationSeminarWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  currency?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  currency_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  currency_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  currency_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  currency_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  currency_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  currency_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<EducationSeminarWhereStageInput>
  documentInStages_none?: InputMaybe<EducationSeminarWhereStageInput>
  documentInStages_some?: InputMaybe<EducationSeminarWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  imageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  onlineStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  onlineStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  onlineStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  onlineStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  onlineStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  onlineStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  onlineStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  onlineStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  onlineStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  onlineStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  price_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  price_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  price_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  price_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  price_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  price_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  price_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EducationSeminarWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EducationSeminarWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EducationSeminarWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EducationSeminarWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EducationSeminarWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References EducationSeminar record uniquely */
export type EducationSeminarWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export enum ElementPosition {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

/** An object with an ID */
export type Entity = {
  /** The id of the object. */
  id: Scalars['ID']['output']
  /** The Stage of an object */
  stage: Stage
}

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
  /** In the animated counter section this model represents one counter settings */
  AnimatedCounter = 'AnimatedCounter',
  Article = 'Article',
  ArticleCategory = 'ArticleCategory',
  /** Simple button call to action placed inside article content */
  ArticleCta = 'ArticleCta',
  /** Article pageViews in the last 30 days */
  ArticlePageView = 'ArticlePageView',
  ArticlesByTag = 'ArticlesByTag',
  /** Asset system model */
  Asset = 'Asset',
  /** Content author */
  Author = 'Author',
  /** Simple component for Header / Description / CTA. */
  BasicHeaderText = 'BasicHeaderText',
  Benefit = 'Benefit',
  BenefitsCard = 'BenefitsCard',
  BenefitsSection = 'BenefitsSection',
  Booth = 'Booth',
  BulletPointList = 'BulletPointList',
  /** Link or button call to action configuration */
  CallToAction = 'CallToAction',
  Contact = 'Contact',
  /** Generic content reference */
  ContentReference = 'ContentReference',
  Download = 'Download',
  EducationCard = 'EducationCard',
  /** Educational report */
  EducationReport = 'EducationReport',
  /** Educational seminar */
  EducationSeminar = 'EducationSeminar',
  Event = 'Event',
  /** [EventApp] Card component */
  EventAppCard = 'EventAppCard',
  /** [EventApp] Text Content: Simple article title with markdown body used on festival mobile app to show content */
  EventAppCardContent = 'EventAppCardContent',
  /** Event App content */
  EventAppPage = 'EventAppPage',
  /** [EventApp] Section component */
  EventAppSection = 'EventAppSection',
  EventPage = 'EventPage',
  /** Event Stage synchronised from myomr service */
  EventStage = 'EventStage',
  /** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
  ExhibitorListOrder = 'ExhibitorListOrder',
  ExplanationStep = 'ExplanationStep',
  /** Section showing some frequently asked questions. */
  Faq = 'Faq',
  /** A question and answer in our FAQs */
  FaqQuestion = 'FaqQuestion',
  /** OMR footer */
  Footer = 'Footer',
  /** OMR footer link */
  FooterLink = 'FooterLink',
  /** Glossary model with term explanation */
  Glossary = 'Glossary',
  /** Header */
  Header = 'Header',
  /** Header menu item */
  HeaderMenuItem = 'HeaderMenuItem',
  /** Header submenu item */
  HeaderSubMenuItem = 'HeaderSubMenuItem',
  /** Header sub-submenu item */
  HeaderSubSubMenuItem = 'HeaderSubSubMenuItem',
  Hotel = 'Hotel',
  /** Raw HTML code to be rendered on the page */
  HtmlEmbed = 'HtmlEmbed',
  /** Hub page to unfiy content accros all departments */
  HubPage = 'HubPage',
  /** Common fields for all sections */
  HubPageSectionCommonFields = 'HubPageSectionCommonFields',
  /** Content Library Section for Hub Pages */
  HubPageSectionContentLibrary = 'HubPageSectionContentLibrary',
  /** Festival Events Section for Hub Pages */
  HubPageSectionFestivalEvents = 'HubPageSectionFestivalEvents',
  /** News Section for Hub Pages */
  HubPageSectionNews = 'HubPageSectionNews',
  /** Image gallery to be embedded in the rich text editor */
  ImageGalleryEmbed = 'ImageGalleryEmbed',
  ImageSlider = 'ImageSlider',
  Interest = 'Interest',
  JobListing = 'JobListing',
  JobsCard = 'JobsCard',
  /** Configuration for static landing page */
  LandingPage = 'LandingPage',
  /** Model to hold configuration options for pages to show in OMR Manage. */
  ManageContentConfig = 'ManageContentConfig',
  /** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
  MapMarker = 'MapMarker',
  /** Icon used for Map marker in the CustomMap component. */
  MapMarkerIcon = 'MapMarkerIcon',
  /** Single newsletter audience as a entry point to newsletter subscriptions. */
  NewsletterAudience = 'NewsletterAudience',
  Notification = 'Notification',
  OmrPartner = 'OMRPartner',
  OmrProductCard = 'OmrProductCard',
  /** Section showing a list of logos of our partner companies. */
  OurPartner = 'OurPartner',
  /** Partner companies */
  PartnerCompany = 'PartnerCompany',
  PhysicalLocation = 'PhysicalLocation',
  /** Podigee podcast episodes */
  PodcastEpisode = 'PodcastEpisode',
  /** Podcast Groups */
  PodcastGroup = 'PodcastGroup',
  PpcReviewsProduct = 'PpcReviewsProduct',
  /** Section showing some quick actions. */
  PreviewCardList = 'PreviewCardList',
  ProcessStep = 'ProcessStep',
  /** a stand with food at the festival */
  RecommendationFood = 'RecommendationFood',
  /** a place to go at night during the festival */
  RecommendationNightOut = 'RecommendationNightOut',
  /** Reviews Agencies synchronised from reviews-api service */
  ReviewsAgency = 'ReviewsAgency',
  /** Reviews Products synchronised from reviews-api service */
  ReviewsProduct = 'ReviewsProduct',
  /** Reviews Product Categories synchronised from reviews-api service */
  ReviewsProductCategory = 'ReviewsProductCategory',
  /** Widgets to display pre-configurate productgroups in Contenthub */
  ReviewsProductWidget = 'ReviewsProductWidget',
  RichContent = 'RichContent',
  /** Section component for richt text with media. */
  RichTextMedia = 'RichTextMedia',
  /** Scheduled Operation system model */
  ScheduledOperation = 'ScheduledOperation',
  /** Scheduled Release system model */
  ScheduledRelease = 'ScheduledRelease',
  SectionAd = 'SectionAd',
  /** Area on the page that could be linked to with an anchor */
  SectionAnchorTarget = 'SectionAnchorTarget',
  /** Call to action for App Download */
  SectionAppCta = 'SectionAppCta',
  SectionBanner = 'SectionBanner',
  /** Section displaying items with bold numbers with the description below and optional call to action */
  SectionByTheNumbers = 'SectionByTheNumbers',
  SectionCardGroup = 'SectionCardGroup',
  SectionCollab = 'SectionCollab',
  /** Text will be collapsed and only title will be shown */
  SectionCollapsedRichContent = 'SectionCollapsedRichContent',
  SectionContact = 'SectionContact',
  /** Section to reference different content items. */
  SectionContentReference = 'SectionContentReference',
  SectionCta = 'SectionCta',
  /** Showing a curated list of speakers */
  SectionCuratedSpeakerList = 'SectionCuratedSpeakerList',
  /** Map component using OpenStreetMap to showcase markers on it. */
  SectionCustomMap = 'SectionCustomMap',
  SectionDownloads = 'SectionDownloads',
  /** Shows timetable for Event offers in list view modes. */
  SectionEventOffersTimetable = 'SectionEventOffersTimetable',
  /** Contact form shown on 'become-an-exhibitor' page. */
  SectionExhibitorContactForm = 'SectionExhibitorContactForm',
  /** Section displaying items in columns with picture title description and optional call to action */
  SectionExplanationBlocks = 'SectionExplanationBlocks',
  SectionExplanationStep = 'SectionExplanationStep',
  SectionFestivalHero = 'SectionFestivalHero',
  /** Shows timetable for Festival stages in 2D and list view modes. */
  SectionFestivalTimetable = 'SectionFestivalTimetable',
  SectionFramesHero = 'SectionFramesHero',
  SectionHero = 'SectionHero',
  /** Section showing the list of hotels. */
  SectionHotel = 'SectionHotel',
  /** Embed a Hubspot form section on the page */
  SectionHubspotForm = 'SectionHubspotForm',
  SectionIntro = 'SectionIntro',
  SectionJobList = 'SectionJobList',
  SectionLivestreams = 'SectionLivestreams',
  /** Multiple Logos with Header. Used for 'in partnership with' logo fields etc. */
  SectionLogoField = 'SectionLogoField',
  /** Manage the logged in user's interests */
  SectionManageInterests = 'SectionManageInterests',
  /** Section showing a filterable grid of Exhibitors from MyOMR datasource. */
  SectionMyomrExhibitors = 'SectionMyomrExhibitors',
  /** Section showing a sorted list of Exhibitors from MyOMR datasource. */
  SectionMyomrExhibitorsList = 'SectionMyomrExhibitorsList',
  /** Section showing newsletter subscription form. */
  SectionNewsletter = 'SectionNewsletter',
  /** Section showing newsletter audiences where user can subscribe. */
  SectionNewsletterAudience = 'SectionNewsletterAudience',
  /** Logo field of OMR partners with external linking. Partners/Media Partners etc. */
  SectionOmrPartner = 'SectionOMRPartner',
  SectionOmrProductCard = 'SectionOmrProductCard',
  /** Display a partner / partners who are the main sponsors of an event. */
  SectionPoweredBy = 'SectionPoweredBy',
  /** Section representing quote */
  SectionQuote = 'SectionQuote',
  /** show a grid of places to eat or go out at night during the festival */
  SectionRecommendations = 'SectionRecommendations',
  /** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
  SectionSpeakers = 'SectionSpeakers',
  /** Shows a preview for a stage with Speakers */
  SectionStagePreview = 'SectionStagePreview',
  /** Simple component for Header / Description / CTA. */
  SectionStickyImage = 'SectionStickyImage',
  SectionStorytelling = 'SectionStorytelling',
  /** Section showing a tab view on desktop and accordions on mobile. */
  SectionTab = 'SectionTab',
  SectionTeaser = 'SectionTeaser',
  /** Section representing single testimonial */
  SectionTestimonial = 'SectionTestimonial',
  SectionTicketsTable = 'SectionTicketsTable',
  /** Section showing a video with a title. */
  SectionVideo = 'SectionVideo',
  Seo = 'Seo',
  SeoContentAttribute = 'SeoContentAttribute',
  Slide = 'Slide',
  /** Social media related links */
  Social = 'Social',
  /** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
  SocialEmbed = 'SocialEmbed',
  SocialLink = 'SocialLink',
  Speaker = 'Speaker',
  /** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
  SpeakerListOrder = 'SpeakerListOrder',
  SubscriptionTeaser = 'SubscriptionTeaser',
  TabContent = 'TabContent',
  /** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
  Tag = 'Tag',
  /** A section with multiple testimonials rendered as a slider. */
  TestimonialSlider = 'TestimonialSlider',
  /** A section representing media asset next to text content with optional CTA */
  TextMedia = 'TextMedia',
  TextMediaBanner = 'TextMediaBanner',
  TicketsTableRow = 'TicketsTableRow',
  /** Base model for all topic pages. */
  TopicPage = 'TopicPage',
  /** Topc page category */
  TopicPageCategory = 'TopicPageCategory',
  /** Section component for topic pages. */
  TopicPageSection = 'TopicPageSection',
  /** User system model */
  User = 'User',
  /** VG Wort integration data */
  VgWort = 'VGWort',
  VerticalDivider = 'VerticalDivider',
  YtEmbed = 'YTEmbed',
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
  /** The ID of an object */
  id: Scalars['ID']['input']
  locale?: InputMaybe<Locale>
  stage: Stage
  /** The Type name of an object */
  typename: EntityTypeName
}

export type Event = Entity &
  Node & {
    __typename?: 'Event'
    agbUrl?: Maybe<Scalars['String']['output']>
    artists: Array<Speaker>
    booths: Array<Booth>
    companyLogoUrl?: Maybe<Scalars['String']['output']>
    companyName?: Maybe<Scalars['String']['output']>
    companyWebsite?: Maybe<Scalars['String']['output']>
    connectedEvent: Array<Event>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    digitalOnly?: Maybe<Scalars['Boolean']['output']>
    /** Get the document in other stages */
    documentInStages: Array<Event>
    duration?: Maybe<Scalars['String']['output']>
    enableCheckin?: Maybe<Scalars['Boolean']['output']>
    endDateTime?: Maybe<Scalars['DateTime']['output']>
    eventAppPageContentEvent: Array<EventAppPage>
    eventFormat?: Maybe<Scalars['String']['output']>
    eventStage?: Maybe<EventStage>
    exhibitors: Array<PartnerCompany>
    externalEvent?: Maybe<Scalars['Boolean']['output']>
    externalEventUrl?: Maybe<Scalars['String']['output']>
    featuredImage?: Maybe<Asset>
    footerAddress?: Maybe<Scalars['String']['output']>
    footerCompany?: Maybe<Scalars['String']['output']>
    /** List of Event versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    language?: Maybe<Scalars['String']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Event>
    locationAddress?: Maybe<Scalars['String']['output']>
    locationName?: Maybe<Scalars['String']['output']>
    /** also knows as title */
    name: Scalars['String']['output']
    parentEvent?: Maybe<Event>
    privacyUrl?: Maybe<Scalars['String']['output']>
    published?: Maybe<Scalars['Boolean']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    speakers: Array<Speaker>
    /** System stage field */
    stage: Stage
    startDateTime?: Maybe<Scalars['DateTime']['output']>
    subtitle?: Maybe<Scalars['String']['output']>
    type?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    website?: Maybe<Scalars['String']['output']>
  }

export type EventArtistsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

export type EventBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BoothOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BoothWhereInput>
}

export type EventConnectedEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

export type EventCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type EventEventAppPageContentEventArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventAppPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppPageWhereInput>
}

export type EventEventStageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventExhibitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

export type EventFeaturedImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type EventLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type EventParentEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type EventSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

export type EventUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** [EventApp] Card component */
export type EventAppCard = Entity & {
  __typename?: 'EventAppCard'
  content?: Maybe<EventAppCardContent>
  /** The unique identifier */
  id: Scalars['ID']['output']
  image?: Maybe<Asset>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<EventAppCard>
  /** Add the slug at the end of this url com.omr.app://discover/content-details/ and use it to generate a QR code here https://www.qr-code-generator.com/ */
  slug?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
  /** please select custom if you want to add content */
  type: EventAppCardType
}

/** [EventApp] Card component */
export type EventAppCardContentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** [EventApp] Card component */
export type EventAppCardImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** [EventApp] Card component */
export type EventAppCardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type EventAppCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventAppCardWhereUniqueInput
}

/** A connection to a list of items. */
export type EventAppCardConnection = {
  __typename?: 'EventAppCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventAppCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** [EventApp] Text Content: Simple article title with markdown body used on festival mobile app to show content */
export type EventAppCardContent = Entity & {
  __typename?: 'EventAppCardContent'
  body: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<EventAppCardContent>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** [EventApp] Text Content: Simple article title with markdown body used on festival mobile app to show content */
export type EventAppCardContentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type EventAppCardContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventAppCardContentWhereUniqueInput
}

/** A connection to a list of items. */
export type EventAppCardContentConnection = {
  __typename?: 'EventAppCardContentConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventAppCardContentEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventAppCardContentCreateInput = {
  /** body input for default locale (de) */
  body: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventAppCardContentCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type EventAppCardContentCreateLocalizationDataInput = {
  body: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type EventAppCardContentCreateLocalizationInput = {
  /** Localization input */
  data: EventAppCardContentCreateLocalizationDataInput
  locale: Locale
}

export type EventAppCardContentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventAppCardContentCreateLocalizationInput>>
}

export type EventAppCardContentCreateManyInlineInput = {
  /** Create and connect multiple existing EventAppCardContent documents */
  create?: InputMaybe<Array<EventAppCardContentCreateInput>>
}

export type EventAppCardContentCreateOneInlineInput = {
  /** Create and connect one EventAppCardContent document */
  create?: InputMaybe<EventAppCardContentCreateInput>
}

export type EventAppCardContentCreateWithPositionInput = {
  /** Document to create */
  data: EventAppCardContentCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type EventAppCardContentEdge = {
  __typename?: 'EventAppCardContentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventAppCardContent
}

/** Identifies documents */
export type EventAppCardContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum EventAppCardContentOrderByInput {
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EventAppCardContentParent = EventAppCard

export type EventAppCardContentParentConnectInput = {
  EventAppCard?: InputMaybe<EventAppCardConnectInput>
}

export type EventAppCardContentParentCreateInput = {
  EventAppCard?: InputMaybe<EventAppCardCreateInput>
}

export type EventAppCardContentParentCreateManyInlineInput = {
  /** Create and connect multiple existing EventAppCardContentParent documents */
  create?: InputMaybe<Array<EventAppCardContentParentCreateInput>>
}

export type EventAppCardContentParentCreateOneInlineInput = {
  /** Create and connect one EventAppCardContentParent document */
  create?: InputMaybe<EventAppCardContentParentCreateInput>
}

export type EventAppCardContentParentCreateWithPositionInput = {
  EventAppCard?: InputMaybe<EventAppCardCreateWithPositionInput>
}

export type EventAppCardContentParentUpdateInput = {
  EventAppCard?: InputMaybe<EventAppCardUpdateInput>
}

export type EventAppCardContentParentUpdateManyInlineInput = {
  /** Create and connect multiple EventAppCardContentParent component instances */
  create?: InputMaybe<Array<EventAppCardContentParentCreateWithPositionInput>>
  /** Delete multiple EventAppCardContentParent documents */
  delete?: InputMaybe<Array<EventAppCardContentParentWhereUniqueInput>>
  /** Update multiple EventAppCardContentParent component instances */
  update?: InputMaybe<Array<EventAppCardContentParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventAppCardContentParent component instances */
  upsert?: InputMaybe<Array<EventAppCardContentParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventAppCardContentParentUpdateManyWithNestedWhereInput = {
  EventAppCard?: InputMaybe<EventAppCardUpdateManyWithNestedWhereInput>
}

export type EventAppCardContentParentUpdateOneInlineInput = {
  /** Create and connect one EventAppCardContentParent document */
  create?: InputMaybe<EventAppCardContentParentCreateInput>
  /** Delete currently connected EventAppCardContentParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppCardContentParent document */
  update?: InputMaybe<EventAppCardContentParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppCardContentParent document */
  upsert?: InputMaybe<EventAppCardContentParentUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardContentParentUpdateWithNestedWhereUniqueAndPositionInput = {
  EventAppCard?: InputMaybe<EventAppCardUpdateWithNestedWhereUniqueAndPositionInput>
}

export type EventAppCardContentParentUpdateWithNestedWhereUniqueInput = {
  EventAppCard?: InputMaybe<EventAppCardUpdateWithNestedWhereUniqueInput>
}

export type EventAppCardContentParentUpsertWithNestedWhereUniqueAndPositionInput = {
  EventAppCard?: InputMaybe<EventAppCardUpsertWithNestedWhereUniqueAndPositionInput>
}

export type EventAppCardContentParentUpsertWithNestedWhereUniqueInput = {
  EventAppCard?: InputMaybe<EventAppCardUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardContentParentWhereInput = {
  EventAppCard?: InputMaybe<EventAppCardWhereInput>
}

export type EventAppCardContentParentWhereUniqueInput = {
  EventAppCard?: InputMaybe<EventAppCardWhereUniqueInput>
}

export type EventAppCardContentUpdateInput = {
  /** body input for default locale (de) */
  body?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<EventAppCardContentUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardContentUpdateLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardContentUpdateLocalizationInput = {
  data: EventAppCardContentUpdateLocalizationDataInput
  locale: Locale
}

export type EventAppCardContentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventAppCardContentCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppCardContentUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventAppCardContentUpsertLocalizationInput>>
}

export type EventAppCardContentUpdateManyInlineInput = {
  /** Create and connect multiple EventAppCardContent component instances */
  create?: InputMaybe<Array<EventAppCardContentCreateWithPositionInput>>
  /** Delete multiple EventAppCardContent documents */
  delete?: InputMaybe<Array<EventAppCardContentWhereUniqueInput>>
  /** Update multiple EventAppCardContent component instances */
  update?: InputMaybe<Array<EventAppCardContentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventAppCardContent component instances */
  upsert?: InputMaybe<Array<EventAppCardContentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventAppCardContentUpdateManyInput = {
  /** body input for default locale (de) */
  body?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventAppCardContentUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardContentUpdateManyLocalizationDataInput = {
  body?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardContentUpdateManyLocalizationInput = {
  data: EventAppCardContentUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventAppCardContentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppCardContentUpdateManyLocalizationInput>>
}

export type EventAppCardContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventAppCardContentUpdateManyInput
  /** Document search */
  where: EventAppCardContentWhereInput
}

export type EventAppCardContentUpdateOneInlineInput = {
  /** Create and connect one EventAppCardContent document */
  create?: InputMaybe<EventAppCardContentCreateInput>
  /** Delete currently connected EventAppCardContent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppCardContent document */
  update?: InputMaybe<EventAppCardContentUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppCardContent document */
  upsert?: InputMaybe<EventAppCardContentUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardContentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<EventAppCardContentUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppCardContentWhereUniqueInput
}

export type EventAppCardContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventAppCardContentUpdateInput
  /** Unique document search */
  where: EventAppCardContentWhereUniqueInput
}

export type EventAppCardContentUpsertInput = {
  /** Create document if it didn't exist */
  create: EventAppCardContentCreateInput
  /** Update document if it exists */
  update: EventAppCardContentUpdateInput
}

export type EventAppCardContentUpsertLocalizationInput = {
  create: EventAppCardContentCreateLocalizationDataInput
  locale: Locale
  update: EventAppCardContentUpdateLocalizationDataInput
}

export type EventAppCardContentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<EventAppCardContentUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppCardContentWhereUniqueInput
}

export type EventAppCardContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventAppCardContentUpsertInput
  /** Unique document search */
  where: EventAppCardContentWhereUniqueInput
}

/** Identifies documents */
export type EventAppCardContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppCardContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  body?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  body_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  body_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  body_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  body_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  body_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  body_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  body_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  body_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  body_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References EventAppCardContent record uniquely */
export type EventAppCardContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventAppCardCreateInput = {
  content?: InputMaybe<EventAppCardContentCreateOneInlineInput>
  image?: InputMaybe<AssetCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventAppCardCreateLocalizationsInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  type: EventAppCardType
}

export type EventAppCardCreateLocalizationDataInput = {
  title: Scalars['String']['input']
}

export type EventAppCardCreateLocalizationInput = {
  /** Localization input */
  data: EventAppCardCreateLocalizationDataInput
  locale: Locale
}

export type EventAppCardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventAppCardCreateLocalizationInput>>
}

export type EventAppCardCreateManyInlineInput = {
  /** Create and connect multiple existing EventAppCard documents */
  create?: InputMaybe<Array<EventAppCardCreateInput>>
}

export type EventAppCardCreateOneInlineInput = {
  /** Create and connect one EventAppCard document */
  create?: InputMaybe<EventAppCardCreateInput>
}

export type EventAppCardCreateWithPositionInput = {
  /** Document to create */
  data: EventAppCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type EventAppCardEdge = {
  __typename?: 'EventAppCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventAppCard
}

/** Identifies documents */
export type EventAppCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<EventAppCardContentWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EventAppCardType>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<EventAppCardType>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<EventAppCardType>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<EventAppCardType>>>
}

export enum EventAppCardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type EventAppCardParent = EventAppSection

export type EventAppCardParentConnectInput = {
  EventAppSection?: InputMaybe<EventAppSectionConnectInput>
}

export type EventAppCardParentCreateInput = {
  EventAppSection?: InputMaybe<EventAppSectionCreateInput>
}

export type EventAppCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing EventAppCardParent documents */
  create?: InputMaybe<Array<EventAppCardParentCreateInput>>
}

export type EventAppCardParentCreateOneInlineInput = {
  /** Create and connect one EventAppCardParent document */
  create?: InputMaybe<EventAppCardParentCreateInput>
}

export type EventAppCardParentCreateWithPositionInput = {
  EventAppSection?: InputMaybe<EventAppSectionCreateWithPositionInput>
}

export type EventAppCardParentUpdateInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpdateInput>
}

export type EventAppCardParentUpdateManyInlineInput = {
  /** Create and connect multiple EventAppCardParent component instances */
  create?: InputMaybe<Array<EventAppCardParentCreateWithPositionInput>>
  /** Delete multiple EventAppCardParent documents */
  delete?: InputMaybe<Array<EventAppCardParentWhereUniqueInput>>
  /** Update multiple EventAppCardParent component instances */
  update?: InputMaybe<Array<EventAppCardParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventAppCardParent component instances */
  upsert?: InputMaybe<Array<EventAppCardParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventAppCardParentUpdateManyWithNestedWhereInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpdateManyWithNestedWhereInput>
}

export type EventAppCardParentUpdateOneInlineInput = {
  /** Create and connect one EventAppCardParent document */
  create?: InputMaybe<EventAppCardParentCreateInput>
  /** Delete currently connected EventAppCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppCardParent document */
  update?: InputMaybe<EventAppCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppCardParent document */
  upsert?: InputMaybe<EventAppCardParentUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type EventAppCardParentUpdateWithNestedWhereUniqueInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpdateWithNestedWhereUniqueInput>
}

export type EventAppCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type EventAppCardParentUpsertWithNestedWhereUniqueInput = {
  EventAppSection?: InputMaybe<EventAppSectionUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardParentWhereInput = {
  EventAppSection?: InputMaybe<EventAppSectionWhereInput>
}

export type EventAppCardParentWhereUniqueInput = {
  EventAppSection?: InputMaybe<EventAppSectionWhereUniqueInput>
}

export enum EventAppCardType {
  Artists = 'artists',
  Custom = 'custom',
  Exhibitors = 'exhibitors',
  Gastro = 'gastro',
  Speakers = 'speakers',
}

export type EventAppCardUpdateInput = {
  content?: InputMaybe<EventAppCardContentUpdateOneInlineInput>
  image?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<EventAppCardUpdateLocalizationsInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EventAppCardType>
}

export type EventAppCardUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardUpdateLocalizationInput = {
  data: EventAppCardUpdateLocalizationDataInput
  locale: Locale
}

export type EventAppCardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventAppCardCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppCardUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventAppCardUpsertLocalizationInput>>
}

export type EventAppCardUpdateManyInlineInput = {
  /** Create and connect multiple EventAppCard component instances */
  create?: InputMaybe<Array<EventAppCardCreateWithPositionInput>>
  /** Delete multiple EventAppCard documents */
  delete?: InputMaybe<Array<EventAppCardWhereUniqueInput>>
  /** Update multiple EventAppCard component instances */
  update?: InputMaybe<Array<EventAppCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventAppCard component instances */
  upsert?: InputMaybe<Array<EventAppCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventAppCardUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventAppCardUpdateManyLocalizationsInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EventAppCardType>
}

export type EventAppCardUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppCardUpdateManyLocalizationInput = {
  data: EventAppCardUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventAppCardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppCardUpdateManyLocalizationInput>>
}

export type EventAppCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventAppCardUpdateManyInput
  /** Document search */
  where: EventAppCardWhereInput
}

export type EventAppCardUpdateOneInlineInput = {
  /** Create and connect one EventAppCard document */
  create?: InputMaybe<EventAppCardCreateInput>
  /** Delete currently connected EventAppCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppCard document */
  update?: InputMaybe<EventAppCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppCard document */
  upsert?: InputMaybe<EventAppCardUpsertWithNestedWhereUniqueInput>
}

export type EventAppCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<EventAppCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppCardWhereUniqueInput
}

export type EventAppCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventAppCardUpdateInput
  /** Unique document search */
  where: EventAppCardWhereUniqueInput
}

export type EventAppCardUpsertInput = {
  /** Create document if it didn't exist */
  create: EventAppCardCreateInput
  /** Update document if it exists */
  update: EventAppCardUpdateInput
}

export type EventAppCardUpsertLocalizationInput = {
  create: EventAppCardCreateLocalizationDataInput
  locale: Locale
  update: EventAppCardUpdateLocalizationDataInput
}

export type EventAppCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<EventAppCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppCardWhereUniqueInput
}

export type EventAppCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventAppCardUpsertInput
  /** Unique document search */
  where: EventAppCardWhereUniqueInput
}

/** Identifies documents */
export type EventAppCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  content?: InputMaybe<EventAppCardContentWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<EventAppCardType>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<EventAppCardType>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<EventAppCardType>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<EventAppCardType>>>
}

/** References EventAppCard record uniquely */
export type EventAppCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Event App content */
export type EventAppPage = Entity &
  Node & {
    __typename?: 'EventAppPage'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<EventAppPage>
    event: Event
    heroImage: Asset
    /** List of EventAppPage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<EventAppPage>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    sections: Array<EventAppSection>
    /** System stage field */
    stage: Stage
    subtitle: Scalars['String']['output']
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Event App content */
export type EventAppPageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event App content */
export type EventAppPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event App content */
export type EventAppPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Event App content */
export type EventAppPageEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event App content */
export type EventAppPageHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event App content */
export type EventAppPageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Event App content */
export type EventAppPageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Event App content */
export type EventAppPagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event App content */
export type EventAppPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event App content */
export type EventAppPageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Event App content */
export type EventAppPageSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventAppSectionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppSectionWhereInput>
}

/** Event App content */
export type EventAppPageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event App content */
export type EventAppPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventAppPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventAppPageWhereUniqueInput
}

/** A connection to a list of items. */
export type EventAppPageConnection = {
  __typename?: 'EventAppPageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventAppPageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventAppPageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event: EventCreateOneInlineInput
  heroImage: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventAppPageCreateLocalizationsInput>
  sections?: InputMaybe<EventAppSectionCreateManyInlineInput>
  /** subtitle input for default locale (de) */
  subtitle: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventAppPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  subtitle: Scalars['String']['input']
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventAppPageCreateLocalizationInput = {
  /** Localization input */
  data: EventAppPageCreateLocalizationDataInput
  locale: Locale
}

export type EventAppPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventAppPageCreateLocalizationInput>>
}

export type EventAppPageCreateManyInlineInput = {
  /** Connect multiple existing EventAppPage documents */
  connect?: InputMaybe<Array<EventAppPageWhereUniqueInput>>
  /** Create and connect multiple existing EventAppPage documents */
  create?: InputMaybe<Array<EventAppPageCreateInput>>
}

export type EventAppPageCreateOneInlineInput = {
  /** Connect one existing EventAppPage document */
  connect?: InputMaybe<EventAppPageWhereUniqueInput>
  /** Create and connect one EventAppPage document */
  create?: InputMaybe<EventAppPageCreateInput>
}

/** An edge in a connection. */
export type EventAppPageEdge = {
  __typename?: 'EventAppPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventAppPage
}

/** Identifies documents */
export type EventAppPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<EventAppPageWhereStageInput>
  documentInStages_none?: InputMaybe<EventAppPageWhereStageInput>
  documentInStages_some?: InputMaybe<EventAppPageWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  heroImage?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  sections_every?: InputMaybe<EventAppSectionWhereInput>
  sections_none?: InputMaybe<EventAppSectionWhereInput>
  sections_some?: InputMaybe<EventAppSectionWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum EventAppPageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EventAppPageUpdateInput = {
  event?: InputMaybe<EventUpdateOneInlineInput>
  heroImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<EventAppPageUpdateLocalizationsInput>
  sections?: InputMaybe<EventAppSectionUpdateManyInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppPageUpdateLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppPageUpdateLocalizationInput = {
  data: EventAppPageUpdateLocalizationDataInput
  locale: Locale
}

export type EventAppPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventAppPageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppPageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventAppPageUpsertLocalizationInput>>
}

export type EventAppPageUpdateManyInlineInput = {
  /** Connect multiple existing EventAppPage documents */
  connect?: InputMaybe<Array<EventAppPageConnectInput>>
  /** Create and connect multiple EventAppPage documents */
  create?: InputMaybe<Array<EventAppPageCreateInput>>
  /** Delete multiple EventAppPage documents */
  delete?: InputMaybe<Array<EventAppPageWhereUniqueInput>>
  /** Disconnect multiple EventAppPage documents */
  disconnect?: InputMaybe<Array<EventAppPageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EventAppPage documents */
  set?: InputMaybe<Array<EventAppPageWhereUniqueInput>>
  /** Update multiple EventAppPage documents */
  update?: InputMaybe<Array<EventAppPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EventAppPage documents */
  upsert?: InputMaybe<Array<EventAppPageUpsertWithNestedWhereUniqueInput>>
}

export type EventAppPageUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventAppPageUpdateManyLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppPageUpdateManyLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppPageUpdateManyLocalizationInput = {
  data: EventAppPageUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventAppPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppPageUpdateManyLocalizationInput>>
}

export type EventAppPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventAppPageUpdateManyInput
  /** Document search */
  where: EventAppPageWhereInput
}

export type EventAppPageUpdateOneInlineInput = {
  /** Connect existing EventAppPage document */
  connect?: InputMaybe<EventAppPageWhereUniqueInput>
  /** Create and connect one EventAppPage document */
  create?: InputMaybe<EventAppPageCreateInput>
  /** Delete currently connected EventAppPage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EventAppPage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppPage document */
  update?: InputMaybe<EventAppPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppPage document */
  upsert?: InputMaybe<EventAppPageUpsertWithNestedWhereUniqueInput>
}

export type EventAppPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventAppPageUpdateInput
  /** Unique document search */
  where: EventAppPageWhereUniqueInput
}

export type EventAppPageUpsertInput = {
  /** Create document if it didn't exist */
  create: EventAppPageCreateInput
  /** Update document if it exists */
  update: EventAppPageUpdateInput
}

export type EventAppPageUpsertLocalizationInput = {
  create: EventAppPageCreateLocalizationDataInput
  locale: Locale
  update: EventAppPageUpdateLocalizationDataInput
}

export type EventAppPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventAppPageUpsertInput
  /** Unique document search */
  where: EventAppPageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EventAppPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EventAppPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<EventAppPageWhereStageInput>
  documentInStages_none?: InputMaybe<EventAppPageWhereStageInput>
  documentInStages_some?: InputMaybe<EventAppPageWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  heroImage?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  sections_every?: InputMaybe<EventAppSectionWhereInput>
  sections_none?: InputMaybe<EventAppSectionWhereInput>
  sections_some?: InputMaybe<EventAppSectionWhereInput>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EventAppPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppPageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppPageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppPageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EventAppPageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References EventAppPage record uniquely */
export type EventAppPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** [EventApp] Section component */
export type EventAppSection = Entity & {
  __typename?: 'EventAppSection'
  cards: Array<EventAppCard>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<EventAppSection>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** [EventApp] Section component */
export type EventAppSectionCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventAppCardOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppCardWhereInput>
}

/** [EventApp] Section component */
export type EventAppSectionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type EventAppSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventAppSectionWhereUniqueInput
}

/** A connection to a list of items. */
export type EventAppSectionConnection = {
  __typename?: 'EventAppSectionConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventAppSectionEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventAppSectionCreateInput = {
  cards?: InputMaybe<EventAppCardCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventAppSectionCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type EventAppSectionCreateLocalizationDataInput = {
  title: Scalars['String']['input']
}

export type EventAppSectionCreateLocalizationInput = {
  /** Localization input */
  data: EventAppSectionCreateLocalizationDataInput
  locale: Locale
}

export type EventAppSectionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventAppSectionCreateLocalizationInput>>
}

export type EventAppSectionCreateManyInlineInput = {
  /** Create and connect multiple existing EventAppSection documents */
  create?: InputMaybe<Array<EventAppSectionCreateInput>>
}

export type EventAppSectionCreateOneInlineInput = {
  /** Create and connect one EventAppSection document */
  create?: InputMaybe<EventAppSectionCreateInput>
}

export type EventAppSectionCreateWithPositionInput = {
  /** Document to create */
  data: EventAppSectionCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type EventAppSectionEdge = {
  __typename?: 'EventAppSectionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventAppSection
}

/** Identifies documents */
export type EventAppSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cards_every?: InputMaybe<EventAppCardWhereInput>
  cards_none?: InputMaybe<EventAppCardWhereInput>
  cards_some?: InputMaybe<EventAppCardWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum EventAppSectionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EventAppSectionParent = EventAppPage

export type EventAppSectionParentConnectInput = {
  EventAppPage?: InputMaybe<EventAppPageConnectInput>
}

export type EventAppSectionParentCreateInput = {
  EventAppPage?: InputMaybe<EventAppPageCreateInput>
}

export type EventAppSectionParentCreateManyInlineInput = {
  /** Connect multiple existing EventAppSectionParent documents */
  connect?: InputMaybe<Array<EventAppSectionParentWhereUniqueInput>>
  /** Create and connect multiple existing EventAppSectionParent documents */
  create?: InputMaybe<Array<EventAppSectionParentCreateInput>>
}

export type EventAppSectionParentCreateOneInlineInput = {
  /** Connect one existing EventAppSectionParent document */
  connect?: InputMaybe<EventAppSectionParentWhereUniqueInput>
  /** Create and connect one EventAppSectionParent document */
  create?: InputMaybe<EventAppSectionParentCreateInput>
}

export type EventAppSectionParentUpdateInput = {
  EventAppPage?: InputMaybe<EventAppPageUpdateInput>
}

export type EventAppSectionParentUpdateManyInlineInput = {
  /** Connect multiple existing EventAppSectionParent documents */
  connect?: InputMaybe<Array<EventAppSectionParentConnectInput>>
  /** Create and connect multiple EventAppSectionParent documents */
  create?: InputMaybe<Array<EventAppSectionParentCreateInput>>
  /** Delete multiple EventAppSectionParent documents */
  delete?: InputMaybe<Array<EventAppSectionParentWhereUniqueInput>>
  /** Disconnect multiple EventAppSectionParent documents */
  disconnect?: InputMaybe<Array<EventAppSectionParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EventAppSectionParent documents */
  set?: InputMaybe<Array<EventAppSectionParentWhereUniqueInput>>
  /** Update multiple EventAppSectionParent documents */
  update?: InputMaybe<Array<EventAppSectionParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EventAppSectionParent documents */
  upsert?: InputMaybe<Array<EventAppSectionParentUpsertWithNestedWhereUniqueInput>>
}

export type EventAppSectionParentUpdateManyWithNestedWhereInput = {
  EventAppPage?: InputMaybe<EventAppPageUpdateManyWithNestedWhereInput>
}

export type EventAppSectionParentUpdateOneInlineInput = {
  /** Connect existing EventAppSectionParent document */
  connect?: InputMaybe<EventAppSectionParentWhereUniqueInput>
  /** Create and connect one EventAppSectionParent document */
  create?: InputMaybe<EventAppSectionParentCreateInput>
  /** Delete currently connected EventAppSectionParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EventAppSectionParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppSectionParent document */
  update?: InputMaybe<EventAppSectionParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppSectionParent document */
  upsert?: InputMaybe<EventAppSectionParentUpsertWithNestedWhereUniqueInput>
}

export type EventAppSectionParentUpdateWithNestedWhereUniqueInput = {
  EventAppPage?: InputMaybe<EventAppPageUpdateWithNestedWhereUniqueInput>
}

export type EventAppSectionParentUpsertWithNestedWhereUniqueInput = {
  EventAppPage?: InputMaybe<EventAppPageUpsertWithNestedWhereUniqueInput>
}

export type EventAppSectionParentWhereInput = {
  EventAppPage?: InputMaybe<EventAppPageWhereInput>
}

export type EventAppSectionParentWhereUniqueInput = {
  EventAppPage?: InputMaybe<EventAppPageWhereUniqueInput>
}

export type EventAppSectionUpdateInput = {
  cards?: InputMaybe<EventAppCardUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<EventAppSectionUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppSectionUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppSectionUpdateLocalizationInput = {
  data: EventAppSectionUpdateLocalizationDataInput
  locale: Locale
}

export type EventAppSectionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventAppSectionCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppSectionUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventAppSectionUpsertLocalizationInput>>
}

export type EventAppSectionUpdateManyInlineInput = {
  /** Create and connect multiple EventAppSection component instances */
  create?: InputMaybe<Array<EventAppSectionCreateWithPositionInput>>
  /** Delete multiple EventAppSection documents */
  delete?: InputMaybe<Array<EventAppSectionWhereUniqueInput>>
  /** Update multiple EventAppSection component instances */
  update?: InputMaybe<Array<EventAppSectionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventAppSection component instances */
  upsert?: InputMaybe<Array<EventAppSectionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventAppSectionUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventAppSectionUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppSectionUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventAppSectionUpdateManyLocalizationInput = {
  data: EventAppSectionUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventAppSectionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventAppSectionUpdateManyLocalizationInput>>
}

export type EventAppSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventAppSectionUpdateManyInput
  /** Document search */
  where: EventAppSectionWhereInput
}

export type EventAppSectionUpdateOneInlineInput = {
  /** Create and connect one EventAppSection document */
  create?: InputMaybe<EventAppSectionCreateInput>
  /** Delete currently connected EventAppSection document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventAppSection document */
  update?: InputMaybe<EventAppSectionUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventAppSection document */
  upsert?: InputMaybe<EventAppSectionUpsertWithNestedWhereUniqueInput>
}

export type EventAppSectionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<EventAppSectionUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppSectionWhereUniqueInput
}

export type EventAppSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventAppSectionUpdateInput
  /** Unique document search */
  where: EventAppSectionWhereUniqueInput
}

export type EventAppSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: EventAppSectionCreateInput
  /** Update document if it exists */
  update: EventAppSectionUpdateInput
}

export type EventAppSectionUpsertLocalizationInput = {
  create: EventAppSectionCreateLocalizationDataInput
  locale: Locale
  update: EventAppSectionUpdateLocalizationDataInput
}

export type EventAppSectionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<EventAppSectionUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: EventAppSectionWhereUniqueInput
}

export type EventAppSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventAppSectionUpsertInput
  /** Unique document search */
  where: EventAppSectionWhereUniqueInput
}

/** Identifies documents */
export type EventAppSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventAppSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cards_every?: InputMaybe<EventAppCardWhereInput>
  cards_none?: InputMaybe<EventAppCardWhereInput>
  cards_some?: InputMaybe<EventAppCardWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References EventAppSection record uniquely */
export type EventAppSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventWhereUniqueInput
}

/** A connection to a list of items. */
export type EventConnection = {
  __typename?: 'EventConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventCreateInput = {
  agbUrl?: InputMaybe<Scalars['String']['input']>
  artists?: InputMaybe<SpeakerCreateManyInlineInput>
  booths?: InputMaybe<BoothCreateManyInlineInput>
  clai9mwl31it101tccfahfkdl?: InputMaybe<EventPageCreateManyInlineInput>
  cle5lekfc1l7l01ul0hgghv9f?: InputMaybe<BoothCreateManyInlineInput>
  companyLogoUrl?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  connectedEvent?: InputMaybe<EventCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  digitalOnly?: InputMaybe<Scalars['Boolean']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
  enableCheckin?: InputMaybe<Scalars['Boolean']['input']>
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<NotificationCreateOneInlineInput>
  eventAppPageContentEvent?: InputMaybe<EventAppPageCreateManyInlineInput>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageCreateOneInlineInput>
  exhibitorOrderEvent?: InputMaybe<ExhibitorListOrderCreateManyInlineInput>
  exhibitors?: InputMaybe<PartnerCompanyCreateManyInlineInput>
  externalEvent?: InputMaybe<Scalars['Boolean']['input']>
  externalEventUrl?: InputMaybe<Scalars['String']['input']>
  featuredImage?: InputMaybe<AssetCreateOneInlineInput>
  footerAddress?: InputMaybe<Scalars['String']['input']>
  footerCompany?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventCreateLocalizationsInput>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  parentEvent?: InputMaybe<EventCreateOneInlineInput>
  privacyUrl?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  slug: Scalars['String']['input']
  speakerOrderEvent?: InputMaybe<SpeakerListOrderCreateManyInlineInput>
  speakers?: InputMaybe<SpeakerCreateManyInlineInput>
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type EventCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventCreateLocalizationInput = {
  /** Localization input */
  data: EventCreateLocalizationDataInput
  locale: Locale
}

export type EventCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventCreateLocalizationInput>>
}

export type EventCreateManyInlineInput = {
  /** Connect multiple existing Event documents */
  connect?: InputMaybe<Array<EventWhereUniqueInput>>
  /** Create and connect multiple existing Event documents */
  create?: InputMaybe<Array<EventCreateInput>>
}

export type EventCreateOneInlineInput = {
  /** Connect one existing Event document */
  connect?: InputMaybe<EventWhereUniqueInput>
  /** Create and connect one Event document */
  create?: InputMaybe<EventCreateInput>
}

/** An edge in a connection. */
export type EventEdge = {
  __typename?: 'EventEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Event
}

/** Identifies documents */
export type EventManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  agbUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  agbUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  agbUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  agbUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  agbUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  agbUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  agbUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  agbUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  agbUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  agbUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  artists_every?: InputMaybe<SpeakerWhereInput>
  artists_none?: InputMaybe<SpeakerWhereInput>
  artists_some?: InputMaybe<SpeakerWhereInput>
  booths_every?: InputMaybe<BoothWhereInput>
  booths_none?: InputMaybe<BoothWhereInput>
  booths_some?: InputMaybe<BoothWhereInput>
  companyLogoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyLogoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyLogoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyLogoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyLogoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyLogoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyLogoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyLogoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyLogoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyLogoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyName_starts_with?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyWebsite_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyWebsite_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyWebsite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyWebsite_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyWebsite_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyWebsite_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyWebsite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyWebsite_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyWebsite_starts_with?: InputMaybe<Scalars['String']['input']>
  connectedEvent_every?: InputMaybe<EventWhereInput>
  connectedEvent_none?: InputMaybe<EventWhereInput>
  connectedEvent_some?: InputMaybe<EventWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  digitalOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  digitalOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  documentInStages_every?: InputMaybe<EventWhereStageInput>
  documentInStages_none?: InputMaybe<EventWhereStageInput>
  documentInStages_some?: InputMaybe<EventWhereStageInput>
  duration?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  duration_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  duration_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  duration_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  duration_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  duration_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  duration_starts_with?: InputMaybe<Scalars['String']['input']>
  enableCheckin?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableCheckin_not?: InputMaybe<Scalars['Boolean']['input']>
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  endDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  endDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  endDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  endDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  endDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  endDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  endDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  eventAppPageContentEvent_every?: InputMaybe<EventAppPageWhereInput>
  eventAppPageContentEvent_none?: InputMaybe<EventAppPageWhereInput>
  eventAppPageContentEvent_some?: InputMaybe<EventAppPageWhereInput>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  eventFormat_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  eventFormat_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  eventFormat_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  eventFormat_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  eventFormat_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  eventFormat_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  eventFormat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  eventFormat_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  eventFormat_starts_with?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageWhereInput>
  exhibitors_every?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_none?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_some?: InputMaybe<PartnerCompanyWhereInput>
  externalEvent?: InputMaybe<Scalars['Boolean']['input']>
  externalEventUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  externalEventUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  externalEventUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  externalEventUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  externalEventUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  externalEventUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  externalEventUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  externalEventUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  externalEventUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  externalEventUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  /** Any other value that exists and is not equal to the given value. */
  externalEvent_not?: InputMaybe<Scalars['Boolean']['input']>
  featuredImage?: InputMaybe<AssetWhereInput>
  footerAddress?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  footerAddress_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  footerAddress_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  footerAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  footerAddress_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  footerAddress_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  footerAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  footerAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  footerAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  footerAddress_starts_with?: InputMaybe<Scalars['String']['input']>
  footerCompany?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  footerCompany_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  footerCompany_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  footerCompany_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  footerCompany_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  footerCompany_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  footerCompany_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  footerCompany_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  footerCompany_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  footerCompany_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  language_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  language_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  language_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  language_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  language_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  language_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  language_starts_with?: InputMaybe<Scalars['String']['input']>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  locationAddress_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  locationAddress_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  locationAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  locationAddress_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  locationAddress_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  locationAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  locationAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  locationAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  locationAddress_starts_with?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  locationName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  locationName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  locationName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  locationName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  locationName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  locationName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  locationName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  locationName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  locationName_starts_with?: InputMaybe<Scalars['String']['input']>
  parentEvent?: InputMaybe<EventWhereInput>
  privacyUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  privacyUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  privacyUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  privacyUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  privacyUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  privacyUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  privacyUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  privacyUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  privacyUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  privacyUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  startDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  startDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  startDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  startDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  startDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  startDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  startDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  type_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  type_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  type_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  type_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum EventOrderByInput {
  AgbUrlAsc = 'agbUrl_ASC',
  AgbUrlDesc = 'agbUrl_DESC',
  CompanyLogoUrlAsc = 'companyLogoUrl_ASC',
  CompanyLogoUrlDesc = 'companyLogoUrl_DESC',
  CompanyNameAsc = 'companyName_ASC',
  CompanyNameDesc = 'companyName_DESC',
  CompanyWebsiteAsc = 'companyWebsite_ASC',
  CompanyWebsiteDesc = 'companyWebsite_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DigitalOnlyAsc = 'digitalOnly_ASC',
  DigitalOnlyDesc = 'digitalOnly_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EnableCheckinAsc = 'enableCheckin_ASC',
  EnableCheckinDesc = 'enableCheckin_DESC',
  EndDateTimeAsc = 'endDateTime_ASC',
  EndDateTimeDesc = 'endDateTime_DESC',
  EventFormatAsc = 'eventFormat_ASC',
  EventFormatDesc = 'eventFormat_DESC',
  ExternalEventUrlAsc = 'externalEventUrl_ASC',
  ExternalEventUrlDesc = 'externalEventUrl_DESC',
  ExternalEventAsc = 'externalEvent_ASC',
  ExternalEventDesc = 'externalEvent_DESC',
  FooterAddressAsc = 'footerAddress_ASC',
  FooterAddressDesc = 'footerAddress_DESC',
  FooterCompanyAsc = 'footerCompany_ASC',
  FooterCompanyDesc = 'footerCompany_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LanguageAsc = 'language_ASC',
  LanguageDesc = 'language_DESC',
  LocationAddressAsc = 'locationAddress_ASC',
  LocationAddressDesc = 'locationAddress_DESC',
  LocationNameAsc = 'locationName_ASC',
  LocationNameDesc = 'locationName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrivacyUrlAsc = 'privacyUrl_ASC',
  PrivacyUrlDesc = 'privacyUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  StartDateTimeAsc = 'startDateTime_ASC',
  StartDateTimeDesc = 'startDateTime_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
}

export type EventPage = Entity &
  Node & {
    __typename?: 'EventPage'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<EventPage>
    event?: Maybe<Event>
    /** List of EventPage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<EventPage>
    pageLayout: Array<EventPagepageLayoutUnion>
    pageName: Scalars['String']['output']
    /** Parent event page to setup hierarchy between pages. */
    parentPage?: Maybe<EventPage>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    seoSettings?: Maybe<Seo>
    /** Should be unique within the scope of an event */
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type EventPageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type EventPageEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type EventPageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type EventPagePageLayoutArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type EventPageParentPageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type EventPageSeoSettingsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type EventPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventPageWhereUniqueInput
}

/** A connection to a list of items. */
export type EventPageConnection = {
  __typename?: 'EventPageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventPageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventPageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  event?: InputMaybe<EventCreateOneInlineInput>
  eventPage?: InputMaybe<SectionCtaCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventPageCreateLocalizationsInput>
  pageLayout?: InputMaybe<EventPagepageLayoutUnionCreateManyInlineInput>
  /** pageName input for default locale (de) */
  pageName: Scalars['String']['input']
  pageParents?: InputMaybe<EventPageCreateManyInlineInput>
  parentPage?: InputMaybe<EventPageCreateOneInlineInput>
  seoSettings?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  pageName: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventPageCreateLocalizationInput = {
  /** Localization input */
  data: EventPageCreateLocalizationDataInput
  locale: Locale
}

export type EventPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventPageCreateLocalizationInput>>
}

export type EventPageCreateManyInlineInput = {
  /** Connect multiple existing EventPage documents */
  connect?: InputMaybe<Array<EventPageWhereUniqueInput>>
  /** Create and connect multiple existing EventPage documents */
  create?: InputMaybe<Array<EventPageCreateInput>>
}

export type EventPageCreateOneInlineInput = {
  /** Connect one existing EventPage document */
  connect?: InputMaybe<EventPageWhereUniqueInput>
  /** Create and connect one EventPage document */
  create?: InputMaybe<EventPageCreateInput>
}

/** An edge in a connection. */
export type EventPageEdge = {
  __typename?: 'EventPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventPage
}

/** Identifies documents */
export type EventPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<EventPageWhereStageInput>
  documentInStages_none?: InputMaybe<EventPageWhereStageInput>
  documentInStages_some?: InputMaybe<EventPageWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  pageLayout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageLayout_some?: InputMaybe<EventPagepageLayoutUnionWhereInput>
  parentPage?: InputMaybe<EventPageWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoSettings?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum EventPageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PageNameAsc = 'pageName_ASC',
  PageNameDesc = 'pageName_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EventPageUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  event?: InputMaybe<EventUpdateOneInlineInput>
  eventPage?: InputMaybe<SectionCtaUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<EventPageUpdateLocalizationsInput>
  pageLayout?: InputMaybe<EventPagepageLayoutUnionUpdateManyInlineInput>
  /** pageName input for default locale (de) */
  pageName?: InputMaybe<Scalars['String']['input']>
  pageParents?: InputMaybe<EventPageUpdateManyInlineInput>
  parentPage?: InputMaybe<EventPageUpdateOneInlineInput>
  seoSettings?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type EventPageUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  pageName?: InputMaybe<Scalars['String']['input']>
}

export type EventPageUpdateLocalizationInput = {
  data: EventPageUpdateLocalizationDataInput
  locale: Locale
}

export type EventPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventPageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventPageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventPageUpsertLocalizationInput>>
}

export type EventPageUpdateManyInlineInput = {
  /** Connect multiple existing EventPage documents */
  connect?: InputMaybe<Array<EventPageConnectInput>>
  /** Create and connect multiple EventPage documents */
  create?: InputMaybe<Array<EventPageCreateInput>>
  /** Delete multiple EventPage documents */
  delete?: InputMaybe<Array<EventPageWhereUniqueInput>>
  /** Disconnect multiple EventPage documents */
  disconnect?: InputMaybe<Array<EventPageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EventPage documents */
  set?: InputMaybe<Array<EventPageWhereUniqueInput>>
  /** Update multiple EventPage documents */
  update?: InputMaybe<Array<EventPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EventPage documents */
  upsert?: InputMaybe<Array<EventPageUpsertWithNestedWhereUniqueInput>>
}

export type EventPageUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventPageUpdateManyLocalizationsInput>
  /** pageName input for default locale (de) */
  pageName?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type EventPageUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  pageName?: InputMaybe<Scalars['String']['input']>
}

export type EventPageUpdateManyLocalizationInput = {
  data: EventPageUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventPageUpdateManyLocalizationInput>>
}

export type EventPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventPageUpdateManyInput
  /** Document search */
  where: EventPageWhereInput
}

export type EventPageUpdateOneInlineInput = {
  /** Connect existing EventPage document */
  connect?: InputMaybe<EventPageWhereUniqueInput>
  /** Create and connect one EventPage document */
  create?: InputMaybe<EventPageCreateInput>
  /** Delete currently connected EventPage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EventPage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventPage document */
  update?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventPage document */
  upsert?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type EventPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventPageUpdateInput
  /** Unique document search */
  where: EventPageWhereUniqueInput
}

export type EventPageUpsertInput = {
  /** Create document if it didn't exist */
  create: EventPageCreateInput
  /** Update document if it exists */
  update: EventPageUpdateInput
}

export type EventPageUpsertLocalizationInput = {
  create: EventPageCreateLocalizationDataInput
  locale: Locale
  update: EventPageUpdateLocalizationDataInput
}

export type EventPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventPageUpsertInput
  /** Unique document search */
  where: EventPageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EventPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EventPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<EventPageWhereStageInput>
  documentInStages_none?: InputMaybe<EventPageWhereStageInput>
  documentInStages_some?: InputMaybe<EventPageWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  pageLayout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageLayout_some?: InputMaybe<EventPagepageLayoutUnionWhereInput>
  pageName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageName_starts_with?: InputMaybe<Scalars['String']['input']>
  parentPage?: InputMaybe<EventPageWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoSettings?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EventPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventPageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventPageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventPageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EventPageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References EventPage record uniquely */
export type EventPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventPagepageLayoutUnion =
  | ArticlesByTag
  | BulletPointList
  | Faq
  | ImageSlider
  | PreviewCardList
  | ProcessStep
  | SectionAppCta
  | SectionBanner
  | SectionByTheNumbers
  | SectionContact
  | SectionCta
  | SectionCustomMap
  | SectionDownloads
  | SectionEventOffersTimetable
  | SectionExhibitorContactForm
  | SectionExplanationBlocks
  | SectionFestivalHero
  | SectionFestivalTimetable
  | SectionHero
  | SectionHotel
  | SectionIntro
  | SectionJobList
  | SectionLivestreams
  | SectionLogoField
  | SectionManageInterests
  | SectionMyomrExhibitors
  | SectionMyomrExhibitorsList
  | SectionNewsletter
  | SectionOmrPartner
  | SectionPoweredBy
  | SectionRecommendations
  | SectionSpeakers
  | SectionStagePreview
  | SectionStickyImage
  | SectionStorytelling
  | SectionTab
  | SectionTeaser
  | SectionTicketsTable
  | SectionVideo
  | SubscriptionTeaser
  | TestimonialSlider
  | TextMedia
  | VerticalDivider

export type EventPagepageLayoutUnionConnectInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagConnectInput>
  BulletPointList?: InputMaybe<BulletPointListConnectInput>
  Faq?: InputMaybe<FaqConnectInput>
  ImageSlider?: InputMaybe<ImageSliderConnectInput>
  PreviewCardList?: InputMaybe<PreviewCardListConnectInput>
  ProcessStep?: InputMaybe<ProcessStepConnectInput>
  SectionAppCta?: InputMaybe<SectionAppCtaConnectInput>
  SectionBanner?: InputMaybe<SectionBannerConnectInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersConnectInput>
  SectionContact?: InputMaybe<SectionContactConnectInput>
  SectionCta?: InputMaybe<SectionCtaConnectInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapConnectInput>
  SectionDownloads?: InputMaybe<SectionDownloadsConnectInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableConnectInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormConnectInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksConnectInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroConnectInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableConnectInput>
  SectionHero?: InputMaybe<SectionHeroConnectInput>
  SectionHotel?: InputMaybe<SectionHotelConnectInput>
  SectionIntro?: InputMaybe<SectionIntroConnectInput>
  SectionJobList?: InputMaybe<SectionJobListConnectInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsConnectInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldConnectInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsConnectInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsConnectInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListConnectInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterConnectInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerConnectInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByConnectInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsConnectInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersConnectInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewConnectInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageConnectInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingConnectInput>
  SectionTab?: InputMaybe<SectionTabConnectInput>
  SectionTeaser?: InputMaybe<SectionTeaserConnectInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableConnectInput>
  SectionVideo?: InputMaybe<SectionVideoConnectInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserConnectInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderConnectInput>
  TextMedia?: InputMaybe<TextMediaConnectInput>
  VerticalDivider?: InputMaybe<VerticalDividerConnectInput>
}

export type EventPagepageLayoutUnionCreateInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagCreateInput>
  BulletPointList?: InputMaybe<BulletPointListCreateInput>
  Faq?: InputMaybe<FaqCreateInput>
  ImageSlider?: InputMaybe<ImageSliderCreateInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateInput>
  ProcessStep?: InputMaybe<ProcessStepCreateInput>
  SectionAppCta?: InputMaybe<SectionAppCtaCreateInput>
  SectionBanner?: InputMaybe<SectionBannerCreateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateInput>
  SectionContact?: InputMaybe<SectionContactCreateInput>
  SectionCta?: InputMaybe<SectionCtaCreateInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapCreateInput>
  SectionDownloads?: InputMaybe<SectionDownloadsCreateInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableCreateInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormCreateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroCreateInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableCreateInput>
  SectionHero?: InputMaybe<SectionHeroCreateInput>
  SectionHotel?: InputMaybe<SectionHotelCreateInput>
  SectionIntro?: InputMaybe<SectionIntroCreateInput>
  SectionJobList?: InputMaybe<SectionJobListCreateInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsCreateInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldCreateInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsCreateInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsCreateInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListCreateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterCreateInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerCreateInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByCreateInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsCreateInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersCreateInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewCreateInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageCreateInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingCreateInput>
  SectionTab?: InputMaybe<SectionTabCreateInput>
  SectionTeaser?: InputMaybe<SectionTeaserCreateInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableCreateInput>
  SectionVideo?: InputMaybe<SectionVideoCreateInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserCreateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderCreateInput>
  TextMedia?: InputMaybe<TextMediaCreateInput>
  VerticalDivider?: InputMaybe<VerticalDividerCreateInput>
}

export type EventPagepageLayoutUnionCreateManyInlineInput = {
  /** Create and connect multiple existing EventPagepageLayoutUnion documents */
  create?: InputMaybe<Array<EventPagepageLayoutUnionCreateInput>>
}

export type EventPagepageLayoutUnionCreateOneInlineInput = {
  /** Create and connect one EventPagepageLayoutUnion document */
  create?: InputMaybe<EventPagepageLayoutUnionCreateInput>
}

export type EventPagepageLayoutUnionCreateWithPositionInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagCreateWithPositionInput>
  BulletPointList?: InputMaybe<BulletPointListCreateWithPositionInput>
  Faq?: InputMaybe<FaqCreateWithPositionInput>
  ImageSlider?: InputMaybe<ImageSliderCreateWithPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateWithPositionInput>
  ProcessStep?: InputMaybe<ProcessStepCreateWithPositionInput>
  SectionAppCta?: InputMaybe<SectionAppCtaCreateWithPositionInput>
  SectionBanner?: InputMaybe<SectionBannerCreateWithPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateWithPositionInput>
  SectionContact?: InputMaybe<SectionContactCreateWithPositionInput>
  SectionCta?: InputMaybe<SectionCtaCreateWithPositionInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapCreateWithPositionInput>
  SectionDownloads?: InputMaybe<SectionDownloadsCreateWithPositionInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableCreateWithPositionInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormCreateWithPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateWithPositionInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroCreateWithPositionInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableCreateWithPositionInput>
  SectionHero?: InputMaybe<SectionHeroCreateWithPositionInput>
  SectionHotel?: InputMaybe<SectionHotelCreateWithPositionInput>
  SectionIntro?: InputMaybe<SectionIntroCreateWithPositionInput>
  SectionJobList?: InputMaybe<SectionJobListCreateWithPositionInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsCreateWithPositionInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldCreateWithPositionInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsCreateWithPositionInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsCreateWithPositionInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListCreateWithPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterCreateWithPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerCreateWithPositionInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByCreateWithPositionInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsCreateWithPositionInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersCreateWithPositionInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewCreateWithPositionInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageCreateWithPositionInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingCreateWithPositionInput>
  SectionTab?: InputMaybe<SectionTabCreateWithPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserCreateWithPositionInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableCreateWithPositionInput>
  SectionVideo?: InputMaybe<SectionVideoCreateWithPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserCreateWithPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderCreateWithPositionInput>
  TextMedia?: InputMaybe<TextMediaCreateWithPositionInput>
  VerticalDivider?: InputMaybe<VerticalDividerCreateWithPositionInput>
}

export type EventPagepageLayoutUnionUpdateInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpdateInput>
  BulletPointList?: InputMaybe<BulletPointListUpdateInput>
  Faq?: InputMaybe<FaqUpdateInput>
  ImageSlider?: InputMaybe<ImageSliderUpdateInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateInput>
  ProcessStep?: InputMaybe<ProcessStepUpdateInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpdateInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateInput>
  SectionContact?: InputMaybe<SectionContactUpdateInput>
  SectionCta?: InputMaybe<SectionCtaUpdateInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpdateInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpdateInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpdateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpdateInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateInput>
  SectionHero?: InputMaybe<SectionHeroUpdateInput>
  SectionHotel?: InputMaybe<SectionHotelUpdateInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateInput>
  SectionJobList?: InputMaybe<SectionJobListUpdateInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpdateInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpdateInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpdateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpdateInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpdateInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpdateInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateInput>
  SectionTab?: InputMaybe<SectionTabUpdateInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateInput>
  SectionVideo?: InputMaybe<SectionVideoUpdateInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateInput>
  TextMedia?: InputMaybe<TextMediaUpdateInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpdateInput>
}

export type EventPagepageLayoutUnionUpdateManyInlineInput = {
  /** Create and connect multiple EventPagepageLayoutUnion component instances */
  create?: InputMaybe<Array<EventPagepageLayoutUnionCreateWithPositionInput>>
  /** Delete multiple EventPagepageLayoutUnion documents */
  delete?: InputMaybe<Array<EventPagepageLayoutUnionWhereUniqueInput>>
  /** Update multiple EventPagepageLayoutUnion component instances */
  update?: InputMaybe<Array<EventPagepageLayoutUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple EventPagepageLayoutUnion component instances */
  upsert?: InputMaybe<Array<EventPagepageLayoutUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type EventPagepageLayoutUnionUpdateManyWithNestedWhereInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpdateManyWithNestedWhereInput>
  BulletPointList?: InputMaybe<BulletPointListUpdateManyWithNestedWhereInput>
  Faq?: InputMaybe<FaqUpdateManyWithNestedWhereInput>
  ImageSlider?: InputMaybe<ImageSliderUpdateManyWithNestedWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateManyWithNestedWhereInput>
  ProcessStep?: InputMaybe<ProcessStepUpdateManyWithNestedWhereInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpdateManyWithNestedWhereInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateManyWithNestedWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateManyWithNestedWhereInput>
  SectionContact?: InputMaybe<SectionContactUpdateManyWithNestedWhereInput>
  SectionCta?: InputMaybe<SectionCtaUpdateManyWithNestedWhereInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpdateManyWithNestedWhereInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateManyWithNestedWhereInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpdateManyWithNestedWhereInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpdateManyWithNestedWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateManyWithNestedWhereInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpdateManyWithNestedWhereInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateManyWithNestedWhereInput>
  SectionHero?: InputMaybe<SectionHeroUpdateManyWithNestedWhereInput>
  SectionHotel?: InputMaybe<SectionHotelUpdateManyWithNestedWhereInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateManyWithNestedWhereInput>
  SectionJobList?: InputMaybe<SectionJobListUpdateManyWithNestedWhereInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateManyWithNestedWhereInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpdateManyWithNestedWhereInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpdateManyWithNestedWhereInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateManyWithNestedWhereInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpdateManyWithNestedWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateManyWithNestedWhereInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateManyWithNestedWhereInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpdateManyWithNestedWhereInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpdateManyWithNestedWhereInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateManyWithNestedWhereInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpdateManyWithNestedWhereInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateManyWithNestedWhereInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateManyWithNestedWhereInput>
  SectionTab?: InputMaybe<SectionTabUpdateManyWithNestedWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateManyWithNestedWhereInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateManyWithNestedWhereInput>
  SectionVideo?: InputMaybe<SectionVideoUpdateManyWithNestedWhereInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateManyWithNestedWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateManyWithNestedWhereInput>
  TextMedia?: InputMaybe<TextMediaUpdateManyWithNestedWhereInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpdateManyWithNestedWhereInput>
}

export type EventPagepageLayoutUnionUpdateOneInlineInput = {
  /** Create and connect one EventPagepageLayoutUnion document */
  create?: InputMaybe<EventPagepageLayoutUnionCreateInput>
  /** Delete currently connected EventPagepageLayoutUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventPagepageLayoutUnion document */
  update?: InputMaybe<EventPagepageLayoutUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventPagepageLayoutUnion document */
  upsert?: InputMaybe<EventPagepageLayoutUnionUpsertWithNestedWhereUniqueInput>
}

export type EventPagepageLayoutUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpdateWithNestedWhereUniqueAndPositionInput>
  BulletPointList?: InputMaybe<BulletPointListUpdateWithNestedWhereUniqueAndPositionInput>
  Faq?: InputMaybe<FaqUpdateWithNestedWhereUniqueAndPositionInput>
  ImageSlider?: InputMaybe<ImageSliderUpdateWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueAndPositionInput>
  ProcessStep?: InputMaybe<ProcessStepUpdateWithNestedWhereUniqueAndPositionInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpdateWithNestedWhereUniqueAndPositionInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateWithNestedWhereUniqueAndPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueAndPositionInput>
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCta?: InputMaybe<SectionCtaUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpdateWithNestedWhereUniqueAndPositionInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateWithNestedWhereUniqueAndPositionInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueAndPositionInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateWithNestedWhereUniqueAndPositionInput>
  SectionHero?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionHotel?: InputMaybe<SectionHotelUpdateWithNestedWhereUniqueAndPositionInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionJobList?: InputMaybe<SectionJobListUpdateWithNestedWhereUniqueAndPositionInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateWithNestedWhereUniqueAndPositionInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpdateWithNestedWhereUniqueAndPositionInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpdateWithNestedWhereUniqueAndPositionInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateWithNestedWhereUniqueAndPositionInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueAndPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueAndPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateWithNestedWhereUniqueAndPositionInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpdateWithNestedWhereUniqueAndPositionInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpdateWithNestedWhereUniqueAndPositionInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateWithNestedWhereUniqueAndPositionInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpdateWithNestedWhereUniqueAndPositionInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateWithNestedWhereUniqueAndPositionInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTab?: InputMaybe<SectionTabUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateWithNestedWhereUniqueAndPositionInput>
  SectionVideo?: InputMaybe<SectionVideoUpdateWithNestedWhereUniqueAndPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateWithNestedWhereUniqueAndPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueAndPositionInput>
  TextMedia?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueAndPositionInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpdateWithNestedWhereUniqueAndPositionInput>
}

export type EventPagepageLayoutUnionUpdateWithNestedWhereUniqueInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpdateWithNestedWhereUniqueInput>
  BulletPointList?: InputMaybe<BulletPointListUpdateWithNestedWhereUniqueInput>
  Faq?: InputMaybe<FaqUpdateWithNestedWhereUniqueInput>
  ImageSlider?: InputMaybe<ImageSliderUpdateWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueInput>
  ProcessStep?: InputMaybe<ProcessStepUpdateWithNestedWhereUniqueInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpdateWithNestedWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaUpdateWithNestedWhereUniqueInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpdateWithNestedWhereUniqueInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpdateWithNestedWhereUniqueInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpdateWithNestedWhereUniqueInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpdateWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpdateWithNestedWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpdateWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueInput>
  SectionHotel?: InputMaybe<SectionHotelUpdateWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueInput>
  SectionJobList?: InputMaybe<SectionJobListUpdateWithNestedWhereUniqueInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateWithNestedWhereUniqueInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpdateWithNestedWhereUniqueInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpdateWithNestedWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateWithNestedWhereUniqueInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateWithNestedWhereUniqueInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpdateWithNestedWhereUniqueInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpdateWithNestedWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpdateWithNestedWhereUniqueInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpdateWithNestedWhereUniqueInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpdateWithNestedWhereUniqueInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpdateWithNestedWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabUpdateWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateWithNestedWhereUniqueInput>
  SectionVideo?: InputMaybe<SectionVideoUpdateWithNestedWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpdateWithNestedWhereUniqueInput>
}

export type EventPagepageLayoutUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpsertWithNestedWhereUniqueAndPositionInput>
  BulletPointList?: InputMaybe<BulletPointListUpsertWithNestedWhereUniqueAndPositionInput>
  Faq?: InputMaybe<FaqUpsertWithNestedWhereUniqueAndPositionInput>
  ImageSlider?: InputMaybe<ImageSliderUpsertWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueAndPositionInput>
  ProcessStep?: InputMaybe<ProcessStepUpsertWithNestedWhereUniqueAndPositionInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpsertWithNestedWhereUniqueAndPositionInput>
  SectionBanner?: InputMaybe<SectionBannerUpsertWithNestedWhereUniqueAndPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueAndPositionInput>
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCta?: InputMaybe<SectionCtaUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpsertWithNestedWhereUniqueAndPositionInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpsertWithNestedWhereUniqueAndPositionInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueAndPositionInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpsertWithNestedWhereUniqueAndPositionInput>
  SectionHero?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionHotel?: InputMaybe<SectionHotelUpsertWithNestedWhereUniqueAndPositionInput>
  SectionIntro?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionJobList?: InputMaybe<SectionJobListUpsertWithNestedWhereUniqueAndPositionInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpsertWithNestedWhereUniqueAndPositionInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpsertWithNestedWhereUniqueAndPositionInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpsertWithNestedWhereUniqueAndPositionInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpsertWithNestedWhereUniqueAndPositionInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueAndPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueAndPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpsertWithNestedWhereUniqueAndPositionInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpsertWithNestedWhereUniqueAndPositionInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpsertWithNestedWhereUniqueAndPositionInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpsertWithNestedWhereUniqueAndPositionInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpsertWithNestedWhereUniqueAndPositionInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpsertWithNestedWhereUniqueAndPositionInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTab?: InputMaybe<SectionTabUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpsertWithNestedWhereUniqueAndPositionInput>
  SectionVideo?: InputMaybe<SectionVideoUpsertWithNestedWhereUniqueAndPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpsertWithNestedWhereUniqueAndPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueAndPositionInput>
  TextMedia?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueAndPositionInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpsertWithNestedWhereUniqueAndPositionInput>
}

export type EventPagepageLayoutUnionUpsertWithNestedWhereUniqueInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagUpsertWithNestedWhereUniqueInput>
  BulletPointList?: InputMaybe<BulletPointListUpsertWithNestedWhereUniqueInput>
  Faq?: InputMaybe<FaqUpsertWithNestedWhereUniqueInput>
  ImageSlider?: InputMaybe<ImageSliderUpsertWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueInput>
  ProcessStep?: InputMaybe<ProcessStepUpsertWithNestedWhereUniqueInput>
  SectionAppCta?: InputMaybe<SectionAppCtaUpsertWithNestedWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerUpsertWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaUpsertWithNestedWhereUniqueInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapUpsertWithNestedWhereUniqueInput>
  SectionDownloads?: InputMaybe<SectionDownloadsUpsertWithNestedWhereUniqueInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableUpsertWithNestedWhereUniqueInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormUpsertWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroUpsertWithNestedWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableUpsertWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueInput>
  SectionHotel?: InputMaybe<SectionHotelUpsertWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueInput>
  SectionJobList?: InputMaybe<SectionJobListUpsertWithNestedWhereUniqueInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpsertWithNestedWhereUniqueInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldUpsertWithNestedWhereUniqueInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsUpsertWithNestedWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpsertWithNestedWhereUniqueInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpsertWithNestedWhereUniqueInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByUpsertWithNestedWhereUniqueInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsUpsertWithNestedWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersUpsertWithNestedWhereUniqueInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewUpsertWithNestedWhereUniqueInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageUpsertWithNestedWhereUniqueInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingUpsertWithNestedWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabUpsertWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpsertWithNestedWhereUniqueInput>
  SectionVideo?: InputMaybe<SectionVideoUpsertWithNestedWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpsertWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueInput>
  VerticalDivider?: InputMaybe<VerticalDividerUpsertWithNestedWhereUniqueInput>
}

export type EventPagepageLayoutUnionWhereInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagWhereInput>
  BulletPointList?: InputMaybe<BulletPointListWhereInput>
  Faq?: InputMaybe<FaqWhereInput>
  ImageSlider?: InputMaybe<ImageSliderWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereInput>
  ProcessStep?: InputMaybe<ProcessStepWhereInput>
  SectionAppCta?: InputMaybe<SectionAppCtaWhereInput>
  SectionBanner?: InputMaybe<SectionBannerWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereInput>
  SectionContact?: InputMaybe<SectionContactWhereInput>
  SectionCta?: InputMaybe<SectionCtaWhereInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapWhereInput>
  SectionDownloads?: InputMaybe<SectionDownloadsWhereInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableWhereInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroWhereInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableWhereInput>
  SectionHero?: InputMaybe<SectionHeroWhereInput>
  SectionHotel?: InputMaybe<SectionHotelWhereInput>
  SectionIntro?: InputMaybe<SectionIntroWhereInput>
  SectionJobList?: InputMaybe<SectionJobListWhereInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsWhereInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldWhereInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsWhereInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsWhereInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerWhereInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByWhereInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsWhereInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersWhereInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewWhereInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageWhereInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingWhereInput>
  SectionTab?: InputMaybe<SectionTabWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableWhereInput>
  SectionVideo?: InputMaybe<SectionVideoWhereInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereInput>
  TextMedia?: InputMaybe<TextMediaWhereInput>
  VerticalDivider?: InputMaybe<VerticalDividerWhereInput>
}

export type EventPagepageLayoutUnionWhereUniqueInput = {
  ArticlesByTag?: InputMaybe<ArticlesByTagWhereUniqueInput>
  BulletPointList?: InputMaybe<BulletPointListWhereUniqueInput>
  Faq?: InputMaybe<FaqWhereUniqueInput>
  ImageSlider?: InputMaybe<ImageSliderWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereUniqueInput>
  ProcessStep?: InputMaybe<ProcessStepWhereUniqueInput>
  SectionAppCta?: InputMaybe<SectionAppCtaWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaWhereUniqueInput>
  SectionCustomMap?: InputMaybe<SectionCustomMapWhereUniqueInput>
  SectionDownloads?: InputMaybe<SectionDownloadsWhereUniqueInput>
  SectionEventOffersTimetable?: InputMaybe<SectionEventOffersTimetableWhereUniqueInput>
  SectionExhibitorContactForm?: InputMaybe<SectionExhibitorContactFormWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereUniqueInput>
  SectionFestivalHero?: InputMaybe<SectionFestivalHeroWhereUniqueInput>
  SectionFestivalTimetable?: InputMaybe<SectionFestivalTimetableWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroWhereUniqueInput>
  SectionHotel?: InputMaybe<SectionHotelWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroWhereUniqueInput>
  SectionJobList?: InputMaybe<SectionJobListWhereUniqueInput>
  SectionLivestreams?: InputMaybe<SectionLivestreamsWhereUniqueInput>
  SectionLogoField?: InputMaybe<SectionLogoFieldWhereUniqueInput>
  SectionManageInterests?: InputMaybe<SectionManageInterestsWhereUniqueInput>
  SectionMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsWhereUniqueInput>
  SectionMyomrExhibitorsList?: InputMaybe<SectionMyomrExhibitorsListWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerWhereUniqueInput>
  SectionPoweredBy?: InputMaybe<SectionPoweredByWhereUniqueInput>
  SectionRecommendations?: InputMaybe<SectionRecommendationsWhereUniqueInput>
  SectionSpeakers?: InputMaybe<SectionSpeakersWhereUniqueInput>
  SectionStagePreview?: InputMaybe<SectionStagePreviewWhereUniqueInput>
  SectionStickyImage?: InputMaybe<SectionStickyImageWhereUniqueInput>
  SectionStorytelling?: InputMaybe<SectionStorytellingWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereUniqueInput>
  SectionTicketsTable?: InputMaybe<SectionTicketsTableWhereUniqueInput>
  SectionVideo?: InputMaybe<SectionVideoWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaWhereUniqueInput>
  VerticalDivider?: InputMaybe<VerticalDividerWhereUniqueInput>
}

/** Event Stage synchronised from myomr service */
export type EventStage = Entity &
  Node & {
    __typename?: 'EventStage'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<EventStage>
    events: Array<Event>
    /** List of EventStage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<EventStage>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    subtitle?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Event Stage synchronised from myomr service */
export type EventStageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event Stage synchronised from myomr service */
export type EventStageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event Stage synchronised from myomr service */
export type EventStageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Event Stage synchronised from myomr service */
export type EventStageEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

/** Event Stage synchronised from myomr service */
export type EventStageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Event Stage synchronised from myomr service */
export type EventStageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Event Stage synchronised from myomr service */
export type EventStagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event Stage synchronised from myomr service */
export type EventStagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Event Stage synchronised from myomr service */
export type EventStageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Event Stage synchronised from myomr service */
export type EventStageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Event Stage synchronised from myomr service */
export type EventStageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type EventStageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: EventStageWhereUniqueInput
}

/** A connection to a list of items. */
export type EventStageConnection = {
  __typename?: 'EventStageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<EventStageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type EventStageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  events?: InputMaybe<EventCreateManyInlineInput>
  festivalTimetableStages?: InputMaybe<SectionFestivalTimetableCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EventStageCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  slug: Scalars['String']['input']
  speakerSections?: InputMaybe<SectionSpeakersCreateManyInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventStageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventStageCreateLocalizationInput = {
  /** Localization input */
  data: EventStageCreateLocalizationDataInput
  locale: Locale
}

export type EventStageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EventStageCreateLocalizationInput>>
}

export type EventStageCreateManyInlineInput = {
  /** Connect multiple existing EventStage documents */
  connect?: InputMaybe<Array<EventStageWhereUniqueInput>>
  /** Create and connect multiple existing EventStage documents */
  create?: InputMaybe<Array<EventStageCreateInput>>
}

export type EventStageCreateOneInlineInput = {
  /** Connect one existing EventStage document */
  connect?: InputMaybe<EventStageWhereUniqueInput>
  /** Create and connect one EventStage document */
  create?: InputMaybe<EventStageCreateInput>
}

/** An edge in a connection. */
export type EventStageEdge = {
  __typename?: 'EventStageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: EventStage
}

/** Identifies documents */
export type EventStageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventStageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventStageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventStageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<EventStageWhereStageInput>
  documentInStages_none?: InputMaybe<EventStageWhereStageInput>
  documentInStages_some?: InputMaybe<EventStageWhereStageInput>
  events_every?: InputMaybe<EventWhereInput>
  events_none?: InputMaybe<EventWhereInput>
  events_some?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum EventStageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EventStageUpdateInput = {
  events?: InputMaybe<EventUpdateManyInlineInput>
  festivalTimetableStages?: InputMaybe<SectionFestivalTimetableUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<EventStageUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  speakerSections?: InputMaybe<SectionSpeakersUpdateManyInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
}

export type EventStageUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
}

export type EventStageUpdateLocalizationInput = {
  data: EventStageUpdateLocalizationDataInput
  locale: Locale
}

export type EventStageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventStageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventStageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventStageUpsertLocalizationInput>>
}

export type EventStageUpdateManyInlineInput = {
  /** Connect multiple existing EventStage documents */
  connect?: InputMaybe<Array<EventStageConnectInput>>
  /** Create and connect multiple EventStage documents */
  create?: InputMaybe<Array<EventStageCreateInput>>
  /** Delete multiple EventStage documents */
  delete?: InputMaybe<Array<EventStageWhereUniqueInput>>
  /** Disconnect multiple EventStage documents */
  disconnect?: InputMaybe<Array<EventStageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing EventStage documents */
  set?: InputMaybe<Array<EventStageWhereUniqueInput>>
  /** Update multiple EventStage documents */
  update?: InputMaybe<Array<EventStageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple EventStage documents */
  upsert?: InputMaybe<Array<EventStageUpsertWithNestedWhereUniqueInput>>
}

export type EventStageUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventStageUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
}

export type EventStageUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
}

export type EventStageUpdateManyLocalizationInput = {
  data: EventStageUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventStageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventStageUpdateManyLocalizationInput>>
}

export type EventStageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventStageUpdateManyInput
  /** Document search */
  where: EventStageWhereInput
}

export type EventStageUpdateOneInlineInput = {
  /** Connect existing EventStage document */
  connect?: InputMaybe<EventStageWhereUniqueInput>
  /** Create and connect one EventStage document */
  create?: InputMaybe<EventStageCreateInput>
  /** Delete currently connected EventStage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected EventStage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single EventStage document */
  update?: InputMaybe<EventStageUpdateWithNestedWhereUniqueInput>
  /** Upsert single EventStage document */
  upsert?: InputMaybe<EventStageUpsertWithNestedWhereUniqueInput>
}

export type EventStageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventStageUpdateInput
  /** Unique document search */
  where: EventStageWhereUniqueInput
}

export type EventStageUpsertInput = {
  /** Create document if it didn't exist */
  create: EventStageCreateInput
  /** Update document if it exists */
  update: EventStageUpdateInput
}

export type EventStageUpsertLocalizationInput = {
  create: EventStageCreateLocalizationDataInput
  locale: Locale
  update: EventStageUpdateLocalizationDataInput
}

export type EventStageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventStageUpsertInput
  /** Unique document search */
  where: EventStageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EventStageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EventStageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventStageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventStageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventStageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<EventStageWhereStageInput>
  documentInStages_none?: InputMaybe<EventStageWhereStageInput>
  documentInStages_some?: InputMaybe<EventStageWhereStageInput>
  events_every?: InputMaybe<EventWhereInput>
  events_none?: InputMaybe<EventWhereInput>
  events_some?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EventStageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventStageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventStageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventStageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EventStageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References EventStage record uniquely */
export type EventStageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type EventUpdateInput = {
  agbUrl?: InputMaybe<Scalars['String']['input']>
  artists?: InputMaybe<SpeakerUpdateManyInlineInput>
  booths?: InputMaybe<BoothUpdateManyInlineInput>
  clai9mwl31it101tccfahfkdl?: InputMaybe<EventPageUpdateManyInlineInput>
  cle5lekfc1l7l01ul0hgghv9f?: InputMaybe<BoothUpdateManyInlineInput>
  companyLogoUrl?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  connectedEvent?: InputMaybe<EventUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  digitalOnly?: InputMaybe<Scalars['Boolean']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
  enableCheckin?: InputMaybe<Scalars['Boolean']['input']>
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<NotificationUpdateOneInlineInput>
  eventAppPageContentEvent?: InputMaybe<EventAppPageUpdateManyInlineInput>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageUpdateOneInlineInput>
  exhibitorOrderEvent?: InputMaybe<ExhibitorListOrderUpdateManyInlineInput>
  exhibitors?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  externalEvent?: InputMaybe<Scalars['Boolean']['input']>
  externalEventUrl?: InputMaybe<Scalars['String']['input']>
  featuredImage?: InputMaybe<AssetUpdateOneInlineInput>
  footerAddress?: InputMaybe<Scalars['String']['input']>
  footerCompany?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<EventUpdateLocalizationsInput>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  parentEvent?: InputMaybe<EventUpdateOneInlineInput>
  privacyUrl?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  speakerOrderEvent?: InputMaybe<SpeakerListOrderUpdateManyInlineInput>
  speakers?: InputMaybe<SpeakerUpdateManyInlineInput>
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type EventUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EventUpdateLocalizationInput = {
  data: EventUpdateLocalizationDataInput
  locale: Locale
}

export type EventUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EventCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<EventUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<EventUpsertLocalizationInput>>
}

export type EventUpdateManyInlineInput = {
  /** Connect multiple existing Event documents */
  connect?: InputMaybe<Array<EventConnectInput>>
  /** Create and connect multiple Event documents */
  create?: InputMaybe<Array<EventCreateInput>>
  /** Delete multiple Event documents */
  delete?: InputMaybe<Array<EventWhereUniqueInput>>
  /** Disconnect multiple Event documents */
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Event documents */
  set?: InputMaybe<Array<EventWhereUniqueInput>>
  /** Update multiple Event documents */
  update?: InputMaybe<Array<EventUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Event documents */
  upsert?: InputMaybe<Array<EventUpsertWithNestedWhereUniqueInput>>
}

export type EventUpdateManyInput = {
  agbUrl?: InputMaybe<Scalars['String']['input']>
  companyLogoUrl?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  digitalOnly?: InputMaybe<Scalars['Boolean']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
  enableCheckin?: InputMaybe<Scalars['Boolean']['input']>
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  externalEvent?: InputMaybe<Scalars['Boolean']['input']>
  externalEventUrl?: InputMaybe<Scalars['String']['input']>
  footerAddress?: InputMaybe<Scalars['String']['input']>
  footerCompany?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<EventUpdateManyLocalizationsInput>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  privacyUrl?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type EventUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EventUpdateManyLocalizationInput = {
  data: EventUpdateManyLocalizationDataInput
  locale: Locale
}

export type EventUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EventUpdateManyLocalizationInput>>
}

export type EventUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EventUpdateManyInput
  /** Document search */
  where: EventWhereInput
}

export type EventUpdateOneInlineInput = {
  /** Connect existing Event document */
  connect?: InputMaybe<EventWhereUniqueInput>
  /** Create and connect one Event document */
  create?: InputMaybe<EventCreateInput>
  /** Delete currently connected Event document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Event document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Event document */
  update?: InputMaybe<EventUpdateWithNestedWhereUniqueInput>
  /** Upsert single Event document */
  upsert?: InputMaybe<EventUpsertWithNestedWhereUniqueInput>
}

export type EventUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EventUpdateInput
  /** Unique document search */
  where: EventWhereUniqueInput
}

export type EventUpsertInput = {
  /** Create document if it didn't exist */
  create: EventCreateInput
  /** Update document if it exists */
  update: EventUpdateInput
}

export type EventUpsertLocalizationInput = {
  create: EventCreateLocalizationDataInput
  locale: Locale
  update: EventUpdateLocalizationDataInput
}

export type EventUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EventUpsertInput
  /** Unique document search */
  where: EventWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type EventWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type EventWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  agbUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  agbUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  agbUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  agbUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  agbUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  agbUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  agbUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  agbUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  agbUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  agbUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  artists_every?: InputMaybe<SpeakerWhereInput>
  artists_none?: InputMaybe<SpeakerWhereInput>
  artists_some?: InputMaybe<SpeakerWhereInput>
  booths_every?: InputMaybe<BoothWhereInput>
  booths_none?: InputMaybe<BoothWhereInput>
  booths_some?: InputMaybe<BoothWhereInput>
  companyLogoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyLogoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyLogoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyLogoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyLogoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyLogoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyLogoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyLogoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyLogoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyLogoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyName_starts_with?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  companyWebsite_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  companyWebsite_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  companyWebsite_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  companyWebsite_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  companyWebsite_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  companyWebsite_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  companyWebsite_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  companyWebsite_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  companyWebsite_starts_with?: InputMaybe<Scalars['String']['input']>
  connectedEvent_every?: InputMaybe<EventWhereInput>
  connectedEvent_none?: InputMaybe<EventWhereInput>
  connectedEvent_some?: InputMaybe<EventWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  digitalOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  digitalOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  documentInStages_every?: InputMaybe<EventWhereStageInput>
  documentInStages_none?: InputMaybe<EventWhereStageInput>
  documentInStages_some?: InputMaybe<EventWhereStageInput>
  duration?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  duration_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  duration_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  duration_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  duration_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  duration_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  duration_starts_with?: InputMaybe<Scalars['String']['input']>
  enableCheckin?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableCheckin_not?: InputMaybe<Scalars['Boolean']['input']>
  endDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  endDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  endDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  endDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  endDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  endDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  endDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  endDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  eventAppPageContentEvent_every?: InputMaybe<EventAppPageWhereInput>
  eventAppPageContentEvent_none?: InputMaybe<EventAppPageWhereInput>
  eventAppPageContentEvent_some?: InputMaybe<EventAppPageWhereInput>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  eventFormat_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  eventFormat_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  eventFormat_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  eventFormat_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  eventFormat_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  eventFormat_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  eventFormat_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  eventFormat_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  eventFormat_starts_with?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageWhereInput>
  exhibitors_every?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_none?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_some?: InputMaybe<PartnerCompanyWhereInput>
  externalEvent?: InputMaybe<Scalars['Boolean']['input']>
  externalEventUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  externalEventUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  externalEventUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  externalEventUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  externalEventUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  externalEventUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  externalEventUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  externalEventUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  externalEventUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  externalEventUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  /** Any other value that exists and is not equal to the given value. */
  externalEvent_not?: InputMaybe<Scalars['Boolean']['input']>
  featuredImage?: InputMaybe<AssetWhereInput>
  footerAddress?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  footerAddress_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  footerAddress_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  footerAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  footerAddress_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  footerAddress_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  footerAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  footerAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  footerAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  footerAddress_starts_with?: InputMaybe<Scalars['String']['input']>
  footerCompany?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  footerCompany_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  footerCompany_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  footerCompany_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  footerCompany_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  footerCompany_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  footerCompany_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  footerCompany_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  footerCompany_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  footerCompany_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  language_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  language_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  language_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  language_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  language_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  language_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  language_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  language_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  language_starts_with?: InputMaybe<Scalars['String']['input']>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  locationAddress_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  locationAddress_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  locationAddress_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  locationAddress_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  locationAddress_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  locationAddress_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  locationAddress_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  locationAddress_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  locationAddress_starts_with?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  locationName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  locationName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  locationName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  locationName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  locationName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  locationName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  locationName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  locationName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  locationName_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  parentEvent?: InputMaybe<EventWhereInput>
  privacyUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  privacyUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  privacyUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  privacyUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  privacyUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  privacyUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  privacyUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  privacyUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  privacyUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  privacyUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  startDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  startDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  startDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  startDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  startDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  startDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  startDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  startDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  type_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  type_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  type_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  type_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EventWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EventWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EventWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EventWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EventWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Event record uniquely */
export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrder = Entity &
  Node & {
    __typename?: 'ExhibitorListOrder'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<ExhibitorListOrder>
    event: Event
    /** The order of exhibitors. */
    exhibitors: Array<PartnerCompany>
    /** List of ExhibitorListOrder versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderExhibitorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on exhibitors pages or in the mobile app. */
export type ExhibitorListOrderUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ExhibitorListOrderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ExhibitorListOrderWhereUniqueInput
}

/** A connection to a list of items. */
export type ExhibitorListOrderConnection = {
  __typename?: 'ExhibitorListOrderConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ExhibitorListOrderEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ExhibitorListOrderCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event: EventCreateOneInlineInput
  exhibitors: PartnerCompanyCreateManyInlineInput
  sectionsMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsCreateManyInlineInput>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ExhibitorListOrderCreateManyInlineInput = {
  /** Connect multiple existing ExhibitorListOrder documents */
  connect?: InputMaybe<Array<ExhibitorListOrderWhereUniqueInput>>
  /** Create and connect multiple existing ExhibitorListOrder documents */
  create?: InputMaybe<Array<ExhibitorListOrderCreateInput>>
}

export type ExhibitorListOrderCreateOneInlineInput = {
  /** Connect one existing ExhibitorListOrder document */
  connect?: InputMaybe<ExhibitorListOrderWhereUniqueInput>
  /** Create and connect one ExhibitorListOrder document */
  create?: InputMaybe<ExhibitorListOrderCreateInput>
}

/** An edge in a connection. */
export type ExhibitorListOrderEdge = {
  __typename?: 'ExhibitorListOrderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ExhibitorListOrder
}

/** Identifies documents */
export type ExhibitorListOrderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ExhibitorListOrderWhereStageInput>
  documentInStages_none?: InputMaybe<ExhibitorListOrderWhereStageInput>
  documentInStages_some?: InputMaybe<ExhibitorListOrderWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  exhibitors_every?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_none?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_some?: InputMaybe<PartnerCompanyWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ExhibitorListOrderOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ExhibitorListOrderUpdateInput = {
  event?: InputMaybe<EventUpdateOneInlineInput>
  exhibitors?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  sectionsMyomrExhibitors?: InputMaybe<SectionMyomrExhibitorsUpdateManyInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type ExhibitorListOrderUpdateManyInlineInput = {
  /** Connect multiple existing ExhibitorListOrder documents */
  connect?: InputMaybe<Array<ExhibitorListOrderConnectInput>>
  /** Create and connect multiple ExhibitorListOrder documents */
  create?: InputMaybe<Array<ExhibitorListOrderCreateInput>>
  /** Delete multiple ExhibitorListOrder documents */
  delete?: InputMaybe<Array<ExhibitorListOrderWhereUniqueInput>>
  /** Disconnect multiple ExhibitorListOrder documents */
  disconnect?: InputMaybe<Array<ExhibitorListOrderWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ExhibitorListOrder documents */
  set?: InputMaybe<Array<ExhibitorListOrderWhereUniqueInput>>
  /** Update multiple ExhibitorListOrder documents */
  update?: InputMaybe<Array<ExhibitorListOrderUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ExhibitorListOrder documents */
  upsert?: InputMaybe<Array<ExhibitorListOrderUpsertWithNestedWhereUniqueInput>>
}

export type ExhibitorListOrderUpdateManyInput = {
  slug?: InputMaybe<Scalars['String']['input']>
}

export type ExhibitorListOrderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ExhibitorListOrderUpdateManyInput
  /** Document search */
  where: ExhibitorListOrderWhereInput
}

export type ExhibitorListOrderUpdateOneInlineInput = {
  /** Connect existing ExhibitorListOrder document */
  connect?: InputMaybe<ExhibitorListOrderWhereUniqueInput>
  /** Create and connect one ExhibitorListOrder document */
  create?: InputMaybe<ExhibitorListOrderCreateInput>
  /** Delete currently connected ExhibitorListOrder document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ExhibitorListOrder document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ExhibitorListOrder document */
  update?: InputMaybe<ExhibitorListOrderUpdateWithNestedWhereUniqueInput>
  /** Upsert single ExhibitorListOrder document */
  upsert?: InputMaybe<ExhibitorListOrderUpsertWithNestedWhereUniqueInput>
}

export type ExhibitorListOrderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ExhibitorListOrderUpdateInput
  /** Unique document search */
  where: ExhibitorListOrderWhereUniqueInput
}

export type ExhibitorListOrderUpsertInput = {
  /** Create document if it didn't exist */
  create: ExhibitorListOrderCreateInput
  /** Update document if it exists */
  update: ExhibitorListOrderUpdateInput
}

export type ExhibitorListOrderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ExhibitorListOrderUpsertInput
  /** Unique document search */
  where: ExhibitorListOrderWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ExhibitorListOrderWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ExhibitorListOrderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExhibitorListOrderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ExhibitorListOrderWhereStageInput>
  documentInStages_none?: InputMaybe<ExhibitorListOrderWhereStageInput>
  documentInStages_some?: InputMaybe<ExhibitorListOrderWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  exhibitors_every?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_none?: InputMaybe<PartnerCompanyWhereInput>
  exhibitors_some?: InputMaybe<PartnerCompanyWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ExhibitorListOrderWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExhibitorListOrderWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExhibitorListOrderWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExhibitorListOrderWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ExhibitorListOrderWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ExhibitorListOrder record uniquely */
export type ExhibitorListOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ExplanationStep = Entity & {
  __typename?: 'ExplanationStep'
  cta?: Maybe<CallToAction>
  description: Scalars['String']['output']
  icon?: Maybe<IconEnum>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<ExplanationStep>
  picture?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

export type ExplanationStepCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ExplanationStepLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ExplanationStepPictureArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ExplanationStepConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ExplanationStepWhereUniqueInput
}

/** A connection to a list of items. */
export type ExplanationStepConnection = {
  __typename?: 'ExplanationStepConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ExplanationStepEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ExplanationStepCreateInput = {
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  icon?: InputMaybe<IconEnum>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ExplanationStepCreateLocalizationsInput>
  picture?: InputMaybe<AssetCreateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepCreateLocalizationInput = {
  /** Localization input */
  data: ExplanationStepCreateLocalizationDataInput
  locale: Locale
}

export type ExplanationStepCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ExplanationStepCreateLocalizationInput>>
}

export type ExplanationStepCreateManyInlineInput = {
  /** Create and connect multiple existing ExplanationStep documents */
  create?: InputMaybe<Array<ExplanationStepCreateInput>>
}

export type ExplanationStepCreateOneInlineInput = {
  /** Create and connect one ExplanationStep document */
  create?: InputMaybe<ExplanationStepCreateInput>
}

export type ExplanationStepCreateWithPositionInput = {
  /** Document to create */
  data: ExplanationStepCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ExplanationStepEdge = {
  __typename?: 'ExplanationStepEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ExplanationStep
}

/** Identifies documents */
export type ExplanationStepManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  icon?: InputMaybe<IconEnum>
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<IconEnum>>>
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<IconEnum>
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<IconEnum>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  picture?: InputMaybe<AssetWhereInput>
}

export enum ExplanationStepOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ExplanationStepParent = SectionByTheNumbers | SectionExplanationBlocks | SectionExplanationStep

export type ExplanationStepParentConnectInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersConnectInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksConnectInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepConnectInput>
}

export type ExplanationStepParentCreateInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepCreateInput>
}

export type ExplanationStepParentCreateManyInlineInput = {
  /** Create and connect multiple existing ExplanationStepParent documents */
  create?: InputMaybe<Array<ExplanationStepParentCreateInput>>
}

export type ExplanationStepParentCreateOneInlineInput = {
  /** Create and connect one ExplanationStepParent document */
  create?: InputMaybe<ExplanationStepParentCreateInput>
}

export type ExplanationStepParentCreateWithPositionInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateWithPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateWithPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepCreateWithPositionInput>
}

export type ExplanationStepParentUpdateInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateInput>
}

export type ExplanationStepParentUpdateManyInlineInput = {
  /** Create and connect multiple ExplanationStepParent component instances */
  create?: InputMaybe<Array<ExplanationStepParentCreateWithPositionInput>>
  /** Delete multiple ExplanationStepParent documents */
  delete?: InputMaybe<Array<ExplanationStepParentWhereUniqueInput>>
  /** Update multiple ExplanationStepParent component instances */
  update?: InputMaybe<Array<ExplanationStepParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ExplanationStepParent component instances */
  upsert?: InputMaybe<Array<ExplanationStepParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ExplanationStepParentUpdateManyWithNestedWhereInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateManyWithNestedWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateManyWithNestedWhereInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateManyWithNestedWhereInput>
}

export type ExplanationStepParentUpdateOneInlineInput = {
  /** Create and connect one ExplanationStepParent document */
  create?: InputMaybe<ExplanationStepParentCreateInput>
  /** Delete currently connected ExplanationStepParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ExplanationStepParent document */
  update?: InputMaybe<ExplanationStepParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ExplanationStepParent document */
  upsert?: InputMaybe<ExplanationStepParentUpsertWithNestedWhereUniqueInput>
}

export type ExplanationStepParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateWithNestedWhereUniqueAndPositionInput>
}

export type ExplanationStepParentUpdateWithNestedWhereUniqueInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateWithNestedWhereUniqueInput>
}

export type ExplanationStepParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpsertWithNestedWhereUniqueAndPositionInput>
}

export type ExplanationStepParentUpsertWithNestedWhereUniqueInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpsertWithNestedWhereUniqueInput>
}

export type ExplanationStepParentWhereInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepWhereInput>
}

export type ExplanationStepParentWhereUniqueInput = {
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepWhereUniqueInput>
}

export type ExplanationStepUpdateInput = {
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconEnum>
  /** Manage document localizations */
  localizations?: InputMaybe<ExplanationStepUpdateLocalizationsInput>
  picture?: InputMaybe<AssetUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepUpdateLocalizationInput = {
  data: ExplanationStepUpdateLocalizationDataInput
  locale: Locale
}

export type ExplanationStepUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ExplanationStepCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ExplanationStepUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ExplanationStepUpsertLocalizationInput>>
}

export type ExplanationStepUpdateManyInlineInput = {
  /** Create and connect multiple ExplanationStep component instances */
  create?: InputMaybe<Array<ExplanationStepCreateWithPositionInput>>
  /** Delete multiple ExplanationStep documents */
  delete?: InputMaybe<Array<ExplanationStepWhereUniqueInput>>
  /** Update multiple ExplanationStep component instances */
  update?: InputMaybe<Array<ExplanationStepUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ExplanationStep component instances */
  upsert?: InputMaybe<Array<ExplanationStepUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ExplanationStepUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconEnum>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ExplanationStepUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ExplanationStepUpdateManyLocalizationInput = {
  data: ExplanationStepUpdateManyLocalizationDataInput
  locale: Locale
}

export type ExplanationStepUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ExplanationStepUpdateManyLocalizationInput>>
}

export type ExplanationStepUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ExplanationStepUpdateManyInput
  /** Document search */
  where: ExplanationStepWhereInput
}

export type ExplanationStepUpdateOneInlineInput = {
  /** Create and connect one ExplanationStep document */
  create?: InputMaybe<ExplanationStepCreateInput>
  /** Delete currently connected ExplanationStep document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ExplanationStep document */
  update?: InputMaybe<ExplanationStepUpdateWithNestedWhereUniqueInput>
  /** Upsert single ExplanationStep document */
  upsert?: InputMaybe<ExplanationStepUpsertWithNestedWhereUniqueInput>
}

export type ExplanationStepUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ExplanationStepUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ExplanationStepWhereUniqueInput
}

export type ExplanationStepUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ExplanationStepUpdateInput
  /** Unique document search */
  where: ExplanationStepWhereUniqueInput
}

export type ExplanationStepUpsertInput = {
  /** Create document if it didn't exist */
  create: ExplanationStepCreateInput
  /** Update document if it exists */
  update: ExplanationStepUpdateInput
}

export type ExplanationStepUpsertLocalizationInput = {
  create: ExplanationStepCreateLocalizationDataInput
  locale: Locale
  update: ExplanationStepUpdateLocalizationDataInput
}

export type ExplanationStepUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ExplanationStepUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ExplanationStepWhereUniqueInput
}

export type ExplanationStepUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ExplanationStepUpsertInput
  /** Unique document search */
  where: ExplanationStepWhereUniqueInput
}

/** Identifies documents */
export type ExplanationStepWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExplanationStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<IconEnum>
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<IconEnum>>>
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<IconEnum>
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<IconEnum>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  picture?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References ExplanationStep record uniquely */
export type ExplanationStepWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing some frequently asked questions. */
export type Faq = Entity & {
  __typename?: 'Faq'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Faq>
  questions: Array<FaqQuestion>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title: Scalars['String']['output']
  /** Sets white BG and removes ? decorations. */
  whiteTheme?: Maybe<Scalars['Boolean']['output']>
}

/** Section showing some frequently asked questions. */
export type FaqLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing some frequently asked questions. */
export type FaqQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<FaqQuestionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FaqQuestionWhereInput>
}

export type FaqConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: FaqWhereUniqueInput
}

/** A connection to a list of items. */
export type FaqConnection = {
  __typename?: 'FaqConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<FaqEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type FaqCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FaqCreateLocalizationsInput>
  questions?: InputMaybe<FaqQuestionCreateManyInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  whiteTheme?: InputMaybe<Scalars['Boolean']['input']>
}

export type FaqCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type FaqCreateLocalizationInput = {
  /** Localization input */
  data: FaqCreateLocalizationDataInput
  locale: Locale
}

export type FaqCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FaqCreateLocalizationInput>>
}

export type FaqCreateManyInlineInput = {
  /** Create and connect multiple existing Faq documents */
  create?: InputMaybe<Array<FaqCreateInput>>
}

export type FaqCreateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>
}

export type FaqCreateWithPositionInput = {
  /** Document to create */
  data: FaqCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type FaqEdge = {
  __typename?: 'FaqEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Faq
}

/** Identifies documents */
export type FaqManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  questions_every?: InputMaybe<FaqQuestionWhereInput>
  questions_none?: InputMaybe<FaqQuestionWhereInput>
  questions_some?: InputMaybe<FaqQuestionWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  whiteTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  whiteTheme_not?: InputMaybe<Scalars['Boolean']['input']>
}

export enum FaqOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  WhiteThemeAsc = 'whiteTheme_ASC',
  WhiteThemeDesc = 'whiteTheme_DESC',
}

export type FaqParent = EventPage | LandingPage

export type FaqParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type FaqParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type FaqParentCreateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentWhereUniqueInput>>
  /** Create and connect multiple existing FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>
}

export type FaqParentCreateOneInlineInput = {
  /** Connect one existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>
}

export type FaqParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type FaqParentUpdateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentConnectInput>>
  /** Create and connect multiple FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>
  /** Delete multiple FaqParent documents */
  delete?: InputMaybe<Array<FaqParentWhereUniqueInput>>
  /** Disconnect multiple FaqParent documents */
  disconnect?: InputMaybe<Array<FaqParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing FaqParent documents */
  set?: InputMaybe<Array<FaqParentWhereUniqueInput>>
  /** Update multiple FaqParent documents */
  update?: InputMaybe<Array<FaqParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple FaqParent documents */
  upsert?: InputMaybe<Array<FaqParentUpsertWithNestedWhereUniqueInput>>
}

export type FaqParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type FaqParentUpdateOneInlineInput = {
  /** Connect existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>
  /** Delete currently connected FaqParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected FaqParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single FaqParent document */
  update?: InputMaybe<FaqParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single FaqParent document */
  upsert?: InputMaybe<FaqParentUpsertWithNestedWhereUniqueInput>
}

export type FaqParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type FaqParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type FaqParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type FaqParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

/** A question and answer in our FAQs */
export type FaqQuestion = Entity &
  Node & {
    __typename?: 'FaqQuestion'
    answer?: Maybe<Scalars['String']['output']>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<FaqQuestion>
    /** List of FaqQuestion versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<FaqQuestion>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    question?: Maybe<Scalars['String']['output']>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** A question and answer in our FAQs */
export type FaqQuestionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** A question and answer in our FAQs */
export type FaqQuestionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** A question and answer in our FAQs */
export type FaqQuestionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** A question and answer in our FAQs */
export type FaqQuestionHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** A question and answer in our FAQs */
export type FaqQuestionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** A question and answer in our FAQs */
export type FaqQuestionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** A question and answer in our FAQs */
export type FaqQuestionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** A question and answer in our FAQs */
export type FaqQuestionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** A question and answer in our FAQs */
export type FaqQuestionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** A question and answer in our FAQs */
export type FaqQuestionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type FaqQuestionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: FaqQuestionWhereUniqueInput
}

/** A connection to a list of items. */
export type FaqQuestionConnection = {
  __typename?: 'FaqQuestionConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<FaqQuestionEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type FaqQuestionCreateInput = {
  /** answer input for default locale (de) */
  answer?: InputMaybe<Scalars['String']['input']>
  clg59ifj401sp01ujct3nhkyj?: InputMaybe<FaqCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FaqQuestionCreateLocalizationsInput>
  /** question input for default locale (de) */
  question?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type FaqQuestionCreateLocalizationDataInput = {
  answer?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  question?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type FaqQuestionCreateLocalizationInput = {
  /** Localization input */
  data: FaqQuestionCreateLocalizationDataInput
  locale: Locale
}

export type FaqQuestionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FaqQuestionCreateLocalizationInput>>
}

export type FaqQuestionCreateManyInlineInput = {
  /** Connect multiple existing FaqQuestion documents */
  connect?: InputMaybe<Array<FaqQuestionWhereUniqueInput>>
  /** Create and connect multiple existing FaqQuestion documents */
  create?: InputMaybe<Array<FaqQuestionCreateInput>>
}

export type FaqQuestionCreateOneInlineInput = {
  /** Connect one existing FaqQuestion document */
  connect?: InputMaybe<FaqQuestionWhereUniqueInput>
  /** Create and connect one FaqQuestion document */
  create?: InputMaybe<FaqQuestionCreateInput>
}

/** An edge in a connection. */
export type FaqQuestionEdge = {
  __typename?: 'FaqQuestionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: FaqQuestion
}

/** Identifies documents */
export type FaqQuestionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<FaqQuestionWhereStageInput>
  documentInStages_none?: InputMaybe<FaqQuestionWhereStageInput>
  documentInStages_some?: InputMaybe<FaqQuestionWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum FaqQuestionOrderByInput {
  AnswerAsc = 'answer_ASC',
  AnswerDesc = 'answer_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FaqQuestionUpdateInput = {
  /** answer input for default locale (de) */
  answer?: InputMaybe<Scalars['String']['input']>
  clg59ifj401sp01ujct3nhkyj?: InputMaybe<FaqUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<FaqQuestionUpdateLocalizationsInput>
  /** question input for default locale (de) */
  question?: InputMaybe<Scalars['String']['input']>
}

export type FaqQuestionUpdateLocalizationDataInput = {
  answer?: InputMaybe<Scalars['String']['input']>
  question?: InputMaybe<Scalars['String']['input']>
}

export type FaqQuestionUpdateLocalizationInput = {
  data: FaqQuestionUpdateLocalizationDataInput
  locale: Locale
}

export type FaqQuestionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FaqQuestionCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<FaqQuestionUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<FaqQuestionUpsertLocalizationInput>>
}

export type FaqQuestionUpdateManyInlineInput = {
  /** Connect multiple existing FaqQuestion documents */
  connect?: InputMaybe<Array<FaqQuestionConnectInput>>
  /** Create and connect multiple FaqQuestion documents */
  create?: InputMaybe<Array<FaqQuestionCreateInput>>
  /** Delete multiple FaqQuestion documents */
  delete?: InputMaybe<Array<FaqQuestionWhereUniqueInput>>
  /** Disconnect multiple FaqQuestion documents */
  disconnect?: InputMaybe<Array<FaqQuestionWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing FaqQuestion documents */
  set?: InputMaybe<Array<FaqQuestionWhereUniqueInput>>
  /** Update multiple FaqQuestion documents */
  update?: InputMaybe<Array<FaqQuestionUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple FaqQuestion documents */
  upsert?: InputMaybe<Array<FaqQuestionUpsertWithNestedWhereUniqueInput>>
}

export type FaqQuestionUpdateManyInput = {
  /** answer input for default locale (de) */
  answer?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<FaqQuestionUpdateManyLocalizationsInput>
  /** question input for default locale (de) */
  question?: InputMaybe<Scalars['String']['input']>
}

export type FaqQuestionUpdateManyLocalizationDataInput = {
  answer?: InputMaybe<Scalars['String']['input']>
  question?: InputMaybe<Scalars['String']['input']>
}

export type FaqQuestionUpdateManyLocalizationInput = {
  data: FaqQuestionUpdateManyLocalizationDataInput
  locale: Locale
}

export type FaqQuestionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FaqQuestionUpdateManyLocalizationInput>>
}

export type FaqQuestionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqQuestionUpdateManyInput
  /** Document search */
  where: FaqQuestionWhereInput
}

export type FaqQuestionUpdateOneInlineInput = {
  /** Connect existing FaqQuestion document */
  connect?: InputMaybe<FaqQuestionWhereUniqueInput>
  /** Create and connect one FaqQuestion document */
  create?: InputMaybe<FaqQuestionCreateInput>
  /** Delete currently connected FaqQuestion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected FaqQuestion document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single FaqQuestion document */
  update?: InputMaybe<FaqQuestionUpdateWithNestedWhereUniqueInput>
  /** Upsert single FaqQuestion document */
  upsert?: InputMaybe<FaqQuestionUpsertWithNestedWhereUniqueInput>
}

export type FaqQuestionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqQuestionUpdateInput
  /** Unique document search */
  where: FaqQuestionWhereUniqueInput
}

export type FaqQuestionUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqQuestionCreateInput
  /** Update document if it exists */
  update: FaqQuestionUpdateInput
}

export type FaqQuestionUpsertLocalizationInput = {
  create: FaqQuestionCreateLocalizationDataInput
  locale: Locale
  update: FaqQuestionUpdateLocalizationDataInput
}

export type FaqQuestionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqQuestionUpsertInput
  /** Unique document search */
  where: FaqQuestionWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type FaqQuestionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type FaqQuestionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqQuestionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  answer?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  answer_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  answer_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  answer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  answer_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  answer_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  answer_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  answer_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  answer_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<FaqQuestionWhereStageInput>
  documentInStages_none?: InputMaybe<FaqQuestionWhereStageInput>
  documentInStages_some?: InputMaybe<FaqQuestionWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  question?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  question_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  question_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  question_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  question_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  question_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  question_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  question_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  question_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FaqQuestionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqQuestionWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqQuestionWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqQuestionWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FaqQuestionWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References FaqQuestion record uniquely */
export type FaqQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type FaqUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<FaqUpdateLocalizationsInput>
  questions?: InputMaybe<FaqQuestionUpdateManyInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  whiteTheme?: InputMaybe<Scalars['Boolean']['input']>
}

export type FaqUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type FaqUpdateLocalizationInput = {
  data: FaqUpdateLocalizationDataInput
  locale: Locale
}

export type FaqUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FaqCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<FaqUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<FaqUpsertLocalizationInput>>
}

export type FaqUpdateManyInlineInput = {
  /** Create and connect multiple Faq component instances */
  create?: InputMaybe<Array<FaqCreateWithPositionInput>>
  /** Delete multiple Faq documents */
  delete?: InputMaybe<Array<FaqWhereUniqueInput>>
  /** Update multiple Faq component instances */
  update?: InputMaybe<Array<FaqUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Faq component instances */
  upsert?: InputMaybe<Array<FaqUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type FaqUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<FaqUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  whiteTheme?: InputMaybe<Scalars['Boolean']['input']>
}

export type FaqUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type FaqUpdateManyLocalizationInput = {
  data: FaqUpdateManyLocalizationDataInput
  locale: Locale
}

export type FaqUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FaqUpdateManyLocalizationInput>>
}

export type FaqUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqUpdateManyInput
  /** Document search */
  where: FaqWhereInput
}

export type FaqUpdateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>
  /** Delete currently connected Faq document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Faq document */
  update?: InputMaybe<FaqUpdateWithNestedWhereUniqueInput>
  /** Upsert single Faq document */
  upsert?: InputMaybe<FaqUpsertWithNestedWhereUniqueInput>
}

export type FaqUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FaqUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: FaqWhereUniqueInput
}

export type FaqUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqUpdateInput
  /** Unique document search */
  where: FaqWhereUniqueInput
}

export type FaqUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqCreateInput
  /** Update document if it exists */
  update: FaqUpdateInput
}

export type FaqUpsertLocalizationInput = {
  create: FaqCreateLocalizationDataInput
  locale: Locale
  update: FaqUpdateLocalizationDataInput
}

export type FaqUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FaqUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: FaqWhereUniqueInput
}

export type FaqUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqUpsertInput
  /** Unique document search */
  where: FaqWhereUniqueInput
}

/** Identifies documents */
export type FaqWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  questions_every?: InputMaybe<FaqQuestionWhereInput>
  questions_none?: InputMaybe<FaqQuestionWhereInput>
  questions_some?: InputMaybe<FaqQuestionWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  whiteTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  whiteTheme_not?: InputMaybe<Scalars['Boolean']['input']>
}

/** References Faq record uniquely */
export type FaqWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** the type of food on offer at this particular food stand */
export enum FestivalFoodType {
  Fish = 'fish',
  Meat = 'meat',
  Vegan = 'vegan',
  Veggie = 'veggie',
}

/** the type of place recommended for a night on the town during the festival */
export enum FestivalNightOutType {
  Bar = 'bar',
  Club = 'club',
}

/** OMR footer */
export type Footer = Entity &
  Node & {
    __typename?: 'Footer'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Footer>
    /** List of Footer versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    omrFooterLinks: Array<FooterLink>
    omrFooterSocials: Array<SocialLink>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** OMR footer */
export type FooterCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** OMR footer */
export type FooterDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** OMR footer */
export type FooterHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** OMR footer */
export type FooterOmrFooterLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<FooterLinkOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FooterLinkWhereInput>
}

/** OMR footer */
export type FooterOmrFooterSocialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SocialLinkOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SocialLinkWhereInput>
}

/** OMR footer */
export type FooterPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** OMR footer */
export type FooterScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** OMR footer */
export type FooterUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type FooterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: FooterWhereUniqueInput
}

/** A connection to a list of items. */
export type FooterConnection = {
  __typename?: 'FooterConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<FooterEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type FooterCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FooterCreateLocalizationsInput>
  omrFooterLinks?: InputMaybe<FooterLinkCreateManyInlineInput>
  omrFooterSocials?: InputMaybe<SocialLinkCreateManyInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type FooterCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type FooterCreateLocalizationInput = {
  /** Localization input */
  data: FooterCreateLocalizationDataInput
  locale: Locale
}

export type FooterCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FooterCreateLocalizationInput>>
}

export type FooterCreateManyInlineInput = {
  /** Connect multiple existing Footer documents */
  connect?: InputMaybe<Array<FooterWhereUniqueInput>>
  /** Create and connect multiple existing Footer documents */
  create?: InputMaybe<Array<FooterCreateInput>>
}

export type FooterCreateOneInlineInput = {
  /** Connect one existing Footer document */
  connect?: InputMaybe<FooterWhereUniqueInput>
  /** Create and connect one Footer document */
  create?: InputMaybe<FooterCreateInput>
}

/** An edge in a connection. */
export type FooterEdge = {
  __typename?: 'FooterEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Footer
}

/** OMR footer link */
export type FooterLink = Entity & {
  __typename?: 'FooterLink'
  /** The unique identifier */
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<FooterLink>
  name?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
}

/** OMR footer link */
export type FooterLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type FooterLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: FooterLinkWhereUniqueInput
}

/** A connection to a list of items. */
export type FooterLinkConnection = {
  __typename?: 'FooterLinkConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<FooterLinkEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type FooterLinkCreateInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FooterLinkCreateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkCreateLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkCreateLocalizationInput = {
  /** Localization input */
  data: FooterLinkCreateLocalizationDataInput
  locale: Locale
}

export type FooterLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FooterLinkCreateLocalizationInput>>
}

export type FooterLinkCreateManyInlineInput = {
  /** Create and connect multiple existing FooterLink documents */
  create?: InputMaybe<Array<FooterLinkCreateInput>>
}

export type FooterLinkCreateOneInlineInput = {
  /** Create and connect one FooterLink document */
  create?: InputMaybe<FooterLinkCreateInput>
}

export type FooterLinkCreateWithPositionInput = {
  /** Document to create */
  data: FooterLinkCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type FooterLinkEdge = {
  __typename?: 'FooterLinkEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: FooterLink
}

/** Identifies documents */
export type FooterLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum FooterLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type FooterLinkParent = Footer

export type FooterLinkParentConnectInput = {
  Footer?: InputMaybe<FooterConnectInput>
}

export type FooterLinkParentCreateInput = {
  Footer?: InputMaybe<FooterCreateInput>
}

export type FooterLinkParentCreateManyInlineInput = {
  /** Connect multiple existing FooterLinkParent documents */
  connect?: InputMaybe<Array<FooterLinkParentWhereUniqueInput>>
  /** Create and connect multiple existing FooterLinkParent documents */
  create?: InputMaybe<Array<FooterLinkParentCreateInput>>
}

export type FooterLinkParentCreateOneInlineInput = {
  /** Connect one existing FooterLinkParent document */
  connect?: InputMaybe<FooterLinkParentWhereUniqueInput>
  /** Create and connect one FooterLinkParent document */
  create?: InputMaybe<FooterLinkParentCreateInput>
}

export type FooterLinkParentUpdateInput = {
  Footer?: InputMaybe<FooterUpdateInput>
}

export type FooterLinkParentUpdateManyInlineInput = {
  /** Connect multiple existing FooterLinkParent documents */
  connect?: InputMaybe<Array<FooterLinkParentConnectInput>>
  /** Create and connect multiple FooterLinkParent documents */
  create?: InputMaybe<Array<FooterLinkParentCreateInput>>
  /** Delete multiple FooterLinkParent documents */
  delete?: InputMaybe<Array<FooterLinkParentWhereUniqueInput>>
  /** Disconnect multiple FooterLinkParent documents */
  disconnect?: InputMaybe<Array<FooterLinkParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing FooterLinkParent documents */
  set?: InputMaybe<Array<FooterLinkParentWhereUniqueInput>>
  /** Update multiple FooterLinkParent documents */
  update?: InputMaybe<Array<FooterLinkParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple FooterLinkParent documents */
  upsert?: InputMaybe<Array<FooterLinkParentUpsertWithNestedWhereUniqueInput>>
}

export type FooterLinkParentUpdateManyWithNestedWhereInput = {
  Footer?: InputMaybe<FooterUpdateManyWithNestedWhereInput>
}

export type FooterLinkParentUpdateOneInlineInput = {
  /** Connect existing FooterLinkParent document */
  connect?: InputMaybe<FooterLinkParentWhereUniqueInput>
  /** Create and connect one FooterLinkParent document */
  create?: InputMaybe<FooterLinkParentCreateInput>
  /** Delete currently connected FooterLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected FooterLinkParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single FooterLinkParent document */
  update?: InputMaybe<FooterLinkParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single FooterLinkParent document */
  upsert?: InputMaybe<FooterLinkParentUpsertWithNestedWhereUniqueInput>
}

export type FooterLinkParentUpdateWithNestedWhereUniqueInput = {
  Footer?: InputMaybe<FooterUpdateWithNestedWhereUniqueInput>
}

export type FooterLinkParentUpsertWithNestedWhereUniqueInput = {
  Footer?: InputMaybe<FooterUpsertWithNestedWhereUniqueInput>
}

export type FooterLinkParentWhereInput = {
  Footer?: InputMaybe<FooterWhereInput>
}

export type FooterLinkParentWhereUniqueInput = {
  Footer?: InputMaybe<FooterWhereUniqueInput>
}

export type FooterLinkUpdateInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<FooterLinkUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkUpdateLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkUpdateLocalizationInput = {
  data: FooterLinkUpdateLocalizationDataInput
  locale: Locale
}

export type FooterLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FooterLinkCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<FooterLinkUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<FooterLinkUpsertLocalizationInput>>
}

export type FooterLinkUpdateManyInlineInput = {
  /** Create and connect multiple FooterLink component instances */
  create?: InputMaybe<Array<FooterLinkCreateWithPositionInput>>
  /** Delete multiple FooterLink documents */
  delete?: InputMaybe<Array<FooterLinkWhereUniqueInput>>
  /** Update multiple FooterLink component instances */
  update?: InputMaybe<Array<FooterLinkUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple FooterLink component instances */
  upsert?: InputMaybe<Array<FooterLinkUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type FooterLinkUpdateManyInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<FooterLinkUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkUpdateManyLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type FooterLinkUpdateManyLocalizationInput = {
  data: FooterLinkUpdateManyLocalizationDataInput
  locale: Locale
}

export type FooterLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FooterLinkUpdateManyLocalizationInput>>
}

export type FooterLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FooterLinkUpdateManyInput
  /** Document search */
  where: FooterLinkWhereInput
}

export type FooterLinkUpdateOneInlineInput = {
  /** Create and connect one FooterLink document */
  create?: InputMaybe<FooterLinkCreateInput>
  /** Delete currently connected FooterLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single FooterLink document */
  update?: InputMaybe<FooterLinkUpdateWithNestedWhereUniqueInput>
  /** Upsert single FooterLink document */
  upsert?: InputMaybe<FooterLinkUpsertWithNestedWhereUniqueInput>
}

export type FooterLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FooterLinkUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: FooterLinkWhereUniqueInput
}

export type FooterLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FooterLinkUpdateInput
  /** Unique document search */
  where: FooterLinkWhereUniqueInput
}

export type FooterLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: FooterLinkCreateInput
  /** Update document if it exists */
  update: FooterLinkUpdateInput
}

export type FooterLinkUpsertLocalizationInput = {
  create: FooterLinkCreateLocalizationDataInput
  locale: Locale
  update: FooterLinkUpdateLocalizationDataInput
}

export type FooterLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FooterLinkUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: FooterLinkWhereUniqueInput
}

export type FooterLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FooterLinkUpsertInput
  /** Unique document search */
  where: FooterLinkWhereUniqueInput
}

/** Identifies documents */
export type FooterLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FooterLinkWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References FooterLink record uniquely */
export type FooterLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Identifies documents */
export type FooterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FooterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FooterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FooterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<FooterWhereStageInput>
  documentInStages_none?: InputMaybe<FooterWhereStageInput>
  documentInStages_some?: InputMaybe<FooterWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrFooterLinks_every?: InputMaybe<FooterLinkWhereInput>
  omrFooterLinks_none?: InputMaybe<FooterLinkWhereInput>
  omrFooterLinks_some?: InputMaybe<FooterLinkWhereInput>
  omrFooterSocials_every?: InputMaybe<SocialLinkWhereInput>
  omrFooterSocials_none?: InputMaybe<SocialLinkWhereInput>
  omrFooterSocials_some?: InputMaybe<SocialLinkWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum FooterOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type FooterUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<FooterUpdateLocalizationsInput>
  omrFooterLinks?: InputMaybe<FooterLinkUpdateManyInlineInput>
  omrFooterSocials?: InputMaybe<SocialLinkUpdateManyInlineInput>
}

export type FooterUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FooterCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
}

export type FooterUpdateManyInlineInput = {
  /** Connect multiple existing Footer documents */
  connect?: InputMaybe<Array<FooterConnectInput>>
  /** Create and connect multiple Footer documents */
  create?: InputMaybe<Array<FooterCreateInput>>
  /** Delete multiple Footer documents */
  delete?: InputMaybe<Array<FooterWhereUniqueInput>>
  /** Disconnect multiple Footer documents */
  disconnect?: InputMaybe<Array<FooterWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Footer documents */
  set?: InputMaybe<Array<FooterWhereUniqueInput>>
  /** Update multiple Footer documents */
  update?: InputMaybe<Array<FooterUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Footer documents */
  upsert?: InputMaybe<Array<FooterUpsertWithNestedWhereUniqueInput>>
}

export type FooterUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type FooterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FooterUpdateManyInput
  /** Document search */
  where: FooterWhereInput
}

export type FooterUpdateOneInlineInput = {
  /** Connect existing Footer document */
  connect?: InputMaybe<FooterWhereUniqueInput>
  /** Create and connect one Footer document */
  create?: InputMaybe<FooterCreateInput>
  /** Delete currently connected Footer document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Footer document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Footer document */
  update?: InputMaybe<FooterUpdateWithNestedWhereUniqueInput>
  /** Upsert single Footer document */
  upsert?: InputMaybe<FooterUpsertWithNestedWhereUniqueInput>
}

export type FooterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FooterUpdateInput
  /** Unique document search */
  where: FooterWhereUniqueInput
}

export type FooterUpsertInput = {
  /** Create document if it didn't exist */
  create: FooterCreateInput
  /** Update document if it exists */
  update: FooterUpdateInput
}

export type FooterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FooterUpsertInput
  /** Unique document search */
  where: FooterWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type FooterWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type FooterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FooterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FooterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FooterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<FooterWhereStageInput>
  documentInStages_none?: InputMaybe<FooterWhereStageInput>
  documentInStages_some?: InputMaybe<FooterWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrFooterLinks_every?: InputMaybe<FooterLinkWhereInput>
  omrFooterLinks_none?: InputMaybe<FooterLinkWhereInput>
  omrFooterLinks_some?: InputMaybe<FooterLinkWhereInput>
  omrFooterSocials_every?: InputMaybe<SocialLinkWhereInput>
  omrFooterSocials_none?: InputMaybe<SocialLinkWhereInput>
  omrFooterSocials_some?: InputMaybe<SocialLinkWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FooterWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FooterWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FooterWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FooterWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FooterWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Footer record uniquely */
export type FooterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Glossary model with term explanation */
export type Glossary = Entity &
  Node & {
    __typename?: 'Glossary'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description: GlossaryDescriptionRichText
    /** Get the document in other stages */
    documentInStages: Array<Glossary>
    /** List of Glossary versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Glossary>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Attributes. */
    seoAttributes?: Maybe<Seo>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    topicPageRedirect?: Maybe<TopicPage>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Optional wordpress reference used by importer */
    wpId?: Maybe<Scalars['String']['output']>
  }

/** Glossary model with term explanation */
export type GlossaryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Glossary model with term explanation */
export type GlossaryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Glossary model with term explanation */
export type GlossaryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Glossary model with term explanation */
export type GlossaryHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Glossary model with term explanation */
export type GlossaryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Glossary model with term explanation */
export type GlossaryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Glossary model with term explanation */
export type GlossaryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Glossary model with term explanation */
export type GlossaryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Glossary model with term explanation */
export type GlossarySeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Glossary model with term explanation */
export type GlossaryTopicPageRedirectArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Glossary model with term explanation */
export type GlossaryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Glossary model with term explanation */
export type GlossaryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type GlossaryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: GlossaryWhereUniqueInput
}

/** A connection to a list of items. */
export type GlossaryConnection = {
  __typename?: 'GlossaryConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<GlossaryEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type GlossaryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description: Scalars['RichTextAST']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<GlossaryCreateLocalizationsInput>
  name: Scalars['String']['input']
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  topicPageRedirect?: InputMaybe<TopicPageCreateOneInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type GlossaryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description: Scalars['RichTextAST']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type GlossaryCreateLocalizationInput = {
  /** Localization input */
  data: GlossaryCreateLocalizationDataInput
  locale: Locale
}

export type GlossaryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<GlossaryCreateLocalizationInput>>
}

export type GlossaryCreateManyInlineInput = {
  /** Connect multiple existing Glossary documents */
  connect?: InputMaybe<Array<GlossaryWhereUniqueInput>>
  /** Create and connect multiple existing Glossary documents */
  create?: InputMaybe<Array<GlossaryCreateInput>>
}

export type GlossaryCreateOneInlineInput = {
  /** Connect one existing Glossary document */
  connect?: InputMaybe<GlossaryWhereUniqueInput>
  /** Create and connect one Glossary document */
  create?: InputMaybe<GlossaryCreateInput>
}

export type GlossaryDescriptionRichText = {
  __typename?: 'GlossaryDescriptionRichText'
  /** Returns HTMl representation */
  html: Scalars['String']['output']
  json: Scalars['RichTextAST']['output']
  /** Returns Markdown representation */
  markdown: Scalars['String']['output']
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST']['output']
  references: Array<GlossaryDescriptionRichTextEmbeddedTypes>
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output']
}

export type GlossaryDescriptionRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type GlossaryDescriptionRichTextEmbeddedTypes = Asset | HtmlEmbed | ReviewsProduct | ReviewsProductCategory | SocialEmbed

/** An edge in a connection. */
export type GlossaryEdge = {
  __typename?: 'GlossaryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Glossary
}

/** Identifies documents */
export type GlossaryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GlossaryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GlossaryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GlossaryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<GlossaryWhereStageInput>
  documentInStages_none?: InputMaybe<GlossaryWhereStageInput>
  documentInStages_some?: InputMaybe<GlossaryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPageRedirect?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum GlossaryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export type GlossaryUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<GlossaryUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  topicPageRedirect?: InputMaybe<TopicPageUpdateOneInlineInput>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type GlossaryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type GlossaryUpdateLocalizationInput = {
  data: GlossaryUpdateLocalizationDataInput
  locale: Locale
}

export type GlossaryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<GlossaryCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<GlossaryUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<GlossaryUpsertLocalizationInput>>
}

export type GlossaryUpdateManyInlineInput = {
  /** Connect multiple existing Glossary documents */
  connect?: InputMaybe<Array<GlossaryConnectInput>>
  /** Create and connect multiple Glossary documents */
  create?: InputMaybe<Array<GlossaryCreateInput>>
  /** Delete multiple Glossary documents */
  delete?: InputMaybe<Array<GlossaryWhereUniqueInput>>
  /** Disconnect multiple Glossary documents */
  disconnect?: InputMaybe<Array<GlossaryWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Glossary documents */
  set?: InputMaybe<Array<GlossaryWhereUniqueInput>>
  /** Update multiple Glossary documents */
  update?: InputMaybe<Array<GlossaryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Glossary documents */
  upsert?: InputMaybe<Array<GlossaryUpsertWithNestedWhereUniqueInput>>
}

export type GlossaryUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<GlossaryUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type GlossaryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type GlossaryUpdateManyLocalizationInput = {
  data: GlossaryUpdateManyLocalizationDataInput
  locale: Locale
}

export type GlossaryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<GlossaryUpdateManyLocalizationInput>>
}

export type GlossaryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GlossaryUpdateManyInput
  /** Document search */
  where: GlossaryWhereInput
}

export type GlossaryUpdateOneInlineInput = {
  /** Connect existing Glossary document */
  connect?: InputMaybe<GlossaryWhereUniqueInput>
  /** Create and connect one Glossary document */
  create?: InputMaybe<GlossaryCreateInput>
  /** Delete currently connected Glossary document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Glossary document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Glossary document */
  update?: InputMaybe<GlossaryUpdateWithNestedWhereUniqueInput>
  /** Upsert single Glossary document */
  upsert?: InputMaybe<GlossaryUpsertWithNestedWhereUniqueInput>
}

export type GlossaryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GlossaryUpdateInput
  /** Unique document search */
  where: GlossaryWhereUniqueInput
}

export type GlossaryUpsertInput = {
  /** Create document if it didn't exist */
  create: GlossaryCreateInput
  /** Update document if it exists */
  update: GlossaryUpdateInput
}

export type GlossaryUpsertLocalizationInput = {
  create: GlossaryCreateLocalizationDataInput
  locale: Locale
  update: GlossaryUpdateLocalizationDataInput
}

export type GlossaryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GlossaryUpsertInput
  /** Unique document search */
  where: GlossaryWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type GlossaryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type GlossaryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GlossaryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GlossaryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GlossaryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<GlossaryWhereStageInput>
  documentInStages_none?: InputMaybe<GlossaryWhereStageInput>
  documentInStages_some?: InputMaybe<GlossaryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPageRedirect?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type GlossaryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GlossaryWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GlossaryWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GlossaryWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<GlossaryWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Glossary record uniquely */
export type GlossaryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

/** Header */
export type Header = Entity &
  Node & {
    __typename?: 'Header'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Header>
    /** List of Header versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    logo?: Maybe<Asset>
    mobileLogo?: Maybe<Asset>
    omrHeaderMenuItems: Array<HeaderMenuItem>
    omrHeaderPartners: Array<OmrPartner>
    omrHeaderSocials: Array<SocialLink>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Header */
export type HeaderCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Header */
export type HeaderDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Header */
export type HeaderHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Header */
export type HeaderLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Header */
export type HeaderMobileLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Header */
export type HeaderOmrHeaderMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<HeaderMenuItemOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HeaderMenuItemWhereInput>
}

/** Header */
export type HeaderOmrHeaderPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<OmrPartnerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<OmrPartnerWhereInput>
}

/** Header */
export type HeaderOmrHeaderSocialsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SocialLinkOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SocialLinkWhereInput>
}

/** Header */
export type HeaderPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Header */
export type HeaderScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Header */
export type HeaderUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HeaderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HeaderWhereUniqueInput
}

/** A connection to a list of items. */
export type HeaderConnection = {
  __typename?: 'HeaderConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HeaderEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HeaderCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeaderCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  mobileLogo?: InputMaybe<AssetCreateOneInlineInput>
  omrHeaderMenuItems?: InputMaybe<HeaderMenuItemCreateManyInlineInput>
  omrHeaderPartners?: InputMaybe<OmrPartnerCreateManyInlineInput>
  omrHeaderSocials?: InputMaybe<SocialLinkCreateManyInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type HeaderCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type HeaderCreateLocalizationInput = {
  /** Localization input */
  data: HeaderCreateLocalizationDataInput
  locale: Locale
}

export type HeaderCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeaderCreateLocalizationInput>>
}

export type HeaderCreateManyInlineInput = {
  /** Connect multiple existing Header documents */
  connect?: InputMaybe<Array<HeaderWhereUniqueInput>>
  /** Create and connect multiple existing Header documents */
  create?: InputMaybe<Array<HeaderCreateInput>>
}

export type HeaderCreateOneInlineInput = {
  /** Connect one existing Header document */
  connect?: InputMaybe<HeaderWhereUniqueInput>
  /** Create and connect one Header document */
  create?: InputMaybe<HeaderCreateInput>
}

/** An edge in a connection. */
export type HeaderEdge = {
  __typename?: 'HeaderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Header
}

/** Identifies documents */
export type HeaderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HeaderWhereStageInput>
  documentInStages_none?: InputMaybe<HeaderWhereStageInput>
  documentInStages_some?: InputMaybe<HeaderWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileLogo?: InputMaybe<AssetWhereInput>
  omrHeaderMenuItems_every?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderMenuItems_none?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderMenuItems_some?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderPartners_every?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderPartners_none?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderPartners_some?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderSocials_every?: InputMaybe<SocialLinkWhereInput>
  omrHeaderSocials_none?: InputMaybe<SocialLinkWhereInput>
  omrHeaderSocials_some?: InputMaybe<SocialLinkWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** Header menu item */
export type HeaderMenuItem = Entity & {
  __typename?: 'HeaderMenuItem'
  /** The unique identifier */
  id: Scalars['ID']['output']
  link: Scalars['String']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<HeaderMenuItem>
  name: Scalars['String']['output']
  omrHeaderSubmenuCta?: Maybe<CallToAction>
  omrHeaderSubmenuItems: Array<HeaderSubMenuItem>
  slug: Scalars['String']['output']
  /** System stage field */
  stage: Stage
}

/** Header menu item */
export type HeaderMenuItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Header menu item */
export type HeaderMenuItemOmrHeaderSubmenuCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Header menu item */
export type HeaderMenuItemOmrHeaderSubmenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<HeaderSubMenuItemOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HeaderSubMenuItemWhereInput>
}

export type HeaderMenuItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HeaderMenuItemWhereUniqueInput
}

/** A connection to a list of items. */
export type HeaderMenuItemConnection = {
  __typename?: 'HeaderMenuItemConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HeaderMenuItemEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HeaderMenuItemCreateInput = {
  /** link input for default locale (de) */
  link: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeaderMenuItemCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  omrHeaderSubmenuCta?: InputMaybe<CallToActionCreateOneInlineInput>
  omrHeaderSubmenuItems?: InputMaybe<HeaderSubMenuItemCreateManyInlineInput>
  slug: Scalars['String']['input']
}

export type HeaderMenuItemCreateLocalizationDataInput = {
  link: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type HeaderMenuItemCreateLocalizationInput = {
  /** Localization input */
  data: HeaderMenuItemCreateLocalizationDataInput
  locale: Locale
}

export type HeaderMenuItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeaderMenuItemCreateLocalizationInput>>
}

export type HeaderMenuItemCreateManyInlineInput = {
  /** Create and connect multiple existing HeaderMenuItem documents */
  create?: InputMaybe<Array<HeaderMenuItemCreateInput>>
}

export type HeaderMenuItemCreateOneInlineInput = {
  /** Create and connect one HeaderMenuItem document */
  create?: InputMaybe<HeaderMenuItemCreateInput>
}

export type HeaderMenuItemCreateWithPositionInput = {
  /** Document to create */
  data: HeaderMenuItemCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HeaderMenuItemEdge = {
  __typename?: 'HeaderMenuItemEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HeaderMenuItem
}

/** Identifies documents */
export type HeaderMenuItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrHeaderSubmenuCta?: InputMaybe<CallToActionWhereInput>
  omrHeaderSubmenuItems_every?: InputMaybe<HeaderSubMenuItemWhereInput>
  omrHeaderSubmenuItems_none?: InputMaybe<HeaderSubMenuItemWhereInput>
  omrHeaderSubmenuItems_some?: InputMaybe<HeaderSubMenuItemWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum HeaderMenuItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

export type HeaderMenuItemParent = Header

export type HeaderMenuItemParentConnectInput = {
  Header?: InputMaybe<HeaderConnectInput>
}

export type HeaderMenuItemParentCreateInput = {
  Header?: InputMaybe<HeaderCreateInput>
}

export type HeaderMenuItemParentCreateManyInlineInput = {
  /** Connect multiple existing HeaderMenuItemParent documents */
  connect?: InputMaybe<Array<HeaderMenuItemParentWhereUniqueInput>>
  /** Create and connect multiple existing HeaderMenuItemParent documents */
  create?: InputMaybe<Array<HeaderMenuItemParentCreateInput>>
}

export type HeaderMenuItemParentCreateOneInlineInput = {
  /** Connect one existing HeaderMenuItemParent document */
  connect?: InputMaybe<HeaderMenuItemParentWhereUniqueInput>
  /** Create and connect one HeaderMenuItemParent document */
  create?: InputMaybe<HeaderMenuItemParentCreateInput>
}

export type HeaderMenuItemParentUpdateInput = {
  Header?: InputMaybe<HeaderUpdateInput>
}

export type HeaderMenuItemParentUpdateManyInlineInput = {
  /** Connect multiple existing HeaderMenuItemParent documents */
  connect?: InputMaybe<Array<HeaderMenuItemParentConnectInput>>
  /** Create and connect multiple HeaderMenuItemParent documents */
  create?: InputMaybe<Array<HeaderMenuItemParentCreateInput>>
  /** Delete multiple HeaderMenuItemParent documents */
  delete?: InputMaybe<Array<HeaderMenuItemParentWhereUniqueInput>>
  /** Disconnect multiple HeaderMenuItemParent documents */
  disconnect?: InputMaybe<Array<HeaderMenuItemParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HeaderMenuItemParent documents */
  set?: InputMaybe<Array<HeaderMenuItemParentWhereUniqueInput>>
  /** Update multiple HeaderMenuItemParent documents */
  update?: InputMaybe<Array<HeaderMenuItemParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HeaderMenuItemParent documents */
  upsert?: InputMaybe<Array<HeaderMenuItemParentUpsertWithNestedWhereUniqueInput>>
}

export type HeaderMenuItemParentUpdateManyWithNestedWhereInput = {
  Header?: InputMaybe<HeaderUpdateManyWithNestedWhereInput>
}

export type HeaderMenuItemParentUpdateOneInlineInput = {
  /** Connect existing HeaderMenuItemParent document */
  connect?: InputMaybe<HeaderMenuItemParentWhereUniqueInput>
  /** Create and connect one HeaderMenuItemParent document */
  create?: InputMaybe<HeaderMenuItemParentCreateInput>
  /** Delete currently connected HeaderMenuItemParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HeaderMenuItemParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderMenuItemParent document */
  update?: InputMaybe<HeaderMenuItemParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderMenuItemParent document */
  upsert?: InputMaybe<HeaderMenuItemParentUpsertWithNestedWhereUniqueInput>
}

export type HeaderMenuItemParentUpdateWithNestedWhereUniqueInput = {
  Header?: InputMaybe<HeaderUpdateWithNestedWhereUniqueInput>
}

export type HeaderMenuItemParentUpsertWithNestedWhereUniqueInput = {
  Header?: InputMaybe<HeaderUpsertWithNestedWhereUniqueInput>
}

export type HeaderMenuItemParentWhereInput = {
  Header?: InputMaybe<HeaderWhereInput>
}

export type HeaderMenuItemParentWhereUniqueInput = {
  Header?: InputMaybe<HeaderWhereUniqueInput>
}

export type HeaderMenuItemUpdateInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<HeaderMenuItemUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  omrHeaderSubmenuCta?: InputMaybe<CallToActionUpdateOneInlineInput>
  omrHeaderSubmenuItems?: InputMaybe<HeaderSubMenuItemUpdateManyInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type HeaderMenuItemUpdateLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderMenuItemUpdateLocalizationInput = {
  data: HeaderMenuItemUpdateLocalizationDataInput
  locale: Locale
}

export type HeaderMenuItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeaderMenuItemCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderMenuItemUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<HeaderMenuItemUpsertLocalizationInput>>
}

export type HeaderMenuItemUpdateManyInlineInput = {
  /** Create and connect multiple HeaderMenuItem component instances */
  create?: InputMaybe<Array<HeaderMenuItemCreateWithPositionInput>>
  /** Delete multiple HeaderMenuItem documents */
  delete?: InputMaybe<Array<HeaderMenuItemWhereUniqueInput>>
  /** Update multiple HeaderMenuItem component instances */
  update?: InputMaybe<Array<HeaderMenuItemUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HeaderMenuItem component instances */
  upsert?: InputMaybe<Array<HeaderMenuItemUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HeaderMenuItemUpdateManyInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<HeaderMenuItemUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type HeaderMenuItemUpdateManyLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderMenuItemUpdateManyLocalizationInput = {
  data: HeaderMenuItemUpdateManyLocalizationDataInput
  locale: Locale
}

export type HeaderMenuItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderMenuItemUpdateManyLocalizationInput>>
}

export type HeaderMenuItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeaderMenuItemUpdateManyInput
  /** Document search */
  where: HeaderMenuItemWhereInput
}

export type HeaderMenuItemUpdateOneInlineInput = {
  /** Create and connect one HeaderMenuItem document */
  create?: InputMaybe<HeaderMenuItemCreateInput>
  /** Delete currently connected HeaderMenuItem document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderMenuItem document */
  update?: InputMaybe<HeaderMenuItemUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderMenuItem document */
  upsert?: InputMaybe<HeaderMenuItemUpsertWithNestedWhereUniqueInput>
}

export type HeaderMenuItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HeaderMenuItemUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderMenuItemWhereUniqueInput
}

export type HeaderMenuItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeaderMenuItemUpdateInput
  /** Unique document search */
  where: HeaderMenuItemWhereUniqueInput
}

export type HeaderMenuItemUpsertInput = {
  /** Create document if it didn't exist */
  create: HeaderMenuItemCreateInput
  /** Update document if it exists */
  update: HeaderMenuItemUpdateInput
}

export type HeaderMenuItemUpsertLocalizationInput = {
  create: HeaderMenuItemCreateLocalizationDataInput
  locale: Locale
  update: HeaderMenuItemUpdateLocalizationDataInput
}

export type HeaderMenuItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HeaderMenuItemUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderMenuItemWhereUniqueInput
}

export type HeaderMenuItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeaderMenuItemUpsertInput
  /** Unique document search */
  where: HeaderMenuItemWhereUniqueInput
}

/** Identifies documents */
export type HeaderMenuItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  omrHeaderSubmenuCta?: InputMaybe<CallToActionWhereInput>
  omrHeaderSubmenuItems_every?: InputMaybe<HeaderSubMenuItemWhereInput>
  omrHeaderSubmenuItems_none?: InputMaybe<HeaderSubMenuItemWhereInput>
  omrHeaderSubmenuItems_some?: InputMaybe<HeaderSubMenuItemWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References HeaderMenuItem record uniquely */
export type HeaderMenuItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum HeaderOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Header submenu item */
export type HeaderSubMenuItem = Entity & {
  __typename?: 'HeaderSubMenuItem'
  /** The unique identifier */
  id: Scalars['ID']['output']
  link: Scalars['String']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<HeaderSubMenuItem>
  name: Scalars['String']['output']
  omrHeaderSubSubmenuItems: Array<HeaderSubSubMenuItem>
  /** System stage field */
  stage: Stage
}

/** Header submenu item */
export type HeaderSubMenuItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Header submenu item */
export type HeaderSubMenuItemOmrHeaderSubSubmenuItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<HeaderSubSubMenuItemOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HeaderSubSubMenuItemWhereInput>
}

export type HeaderSubMenuItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HeaderSubMenuItemWhereUniqueInput
}

/** A connection to a list of items. */
export type HeaderSubMenuItemConnection = {
  __typename?: 'HeaderSubMenuItemConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HeaderSubMenuItemEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HeaderSubMenuItemCreateInput = {
  /** link input for default locale (de) */
  link: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeaderSubMenuItemCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  omrHeaderSubSubmenuItems?: InputMaybe<HeaderSubSubMenuItemCreateManyInlineInput>
}

export type HeaderSubMenuItemCreateLocalizationDataInput = {
  link: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type HeaderSubMenuItemCreateLocalizationInput = {
  /** Localization input */
  data: HeaderSubMenuItemCreateLocalizationDataInput
  locale: Locale
}

export type HeaderSubMenuItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeaderSubMenuItemCreateLocalizationInput>>
}

export type HeaderSubMenuItemCreateManyInlineInput = {
  /** Create and connect multiple existing HeaderSubMenuItem documents */
  create?: InputMaybe<Array<HeaderSubMenuItemCreateInput>>
}

export type HeaderSubMenuItemCreateOneInlineInput = {
  /** Create and connect one HeaderSubMenuItem document */
  create?: InputMaybe<HeaderSubMenuItemCreateInput>
}

export type HeaderSubMenuItemCreateWithPositionInput = {
  /** Document to create */
  data: HeaderSubMenuItemCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HeaderSubMenuItemEdge = {
  __typename?: 'HeaderSubMenuItemEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HeaderSubMenuItem
}

/** Identifies documents */
export type HeaderSubMenuItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrHeaderSubSubmenuItems_every?: InputMaybe<HeaderSubSubMenuItemWhereInput>
  omrHeaderSubSubmenuItems_none?: InputMaybe<HeaderSubSubMenuItemWhereInput>
  omrHeaderSubSubmenuItems_some?: InputMaybe<HeaderSubSubMenuItemWhereInput>
}

export enum HeaderSubMenuItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type HeaderSubMenuItemParent = HeaderMenuItem

export type HeaderSubMenuItemParentConnectInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemConnectInput>
}

export type HeaderSubMenuItemParentCreateInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemCreateInput>
}

export type HeaderSubMenuItemParentCreateManyInlineInput = {
  /** Create and connect multiple existing HeaderSubMenuItemParent documents */
  create?: InputMaybe<Array<HeaderSubMenuItemParentCreateInput>>
}

export type HeaderSubMenuItemParentCreateOneInlineInput = {
  /** Create and connect one HeaderSubMenuItemParent document */
  create?: InputMaybe<HeaderSubMenuItemParentCreateInput>
}

export type HeaderSubMenuItemParentCreateWithPositionInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemCreateWithPositionInput>
}

export type HeaderSubMenuItemParentUpdateInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateInput>
}

export type HeaderSubMenuItemParentUpdateManyInlineInput = {
  /** Create and connect multiple HeaderSubMenuItemParent component instances */
  create?: InputMaybe<Array<HeaderSubMenuItemParentCreateWithPositionInput>>
  /** Delete multiple HeaderSubMenuItemParent documents */
  delete?: InputMaybe<Array<HeaderSubMenuItemParentWhereUniqueInput>>
  /** Update multiple HeaderSubMenuItemParent component instances */
  update?: InputMaybe<Array<HeaderSubMenuItemParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HeaderSubMenuItemParent component instances */
  upsert?: InputMaybe<Array<HeaderSubMenuItemParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HeaderSubMenuItemParentUpdateManyWithNestedWhereInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateManyWithNestedWhereInput>
}

export type HeaderSubMenuItemParentUpdateOneInlineInput = {
  /** Create and connect one HeaderSubMenuItemParent document */
  create?: InputMaybe<HeaderSubMenuItemParentCreateInput>
  /** Delete currently connected HeaderSubMenuItemParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderSubMenuItemParent document */
  update?: InputMaybe<HeaderSubMenuItemParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderSubMenuItemParent document */
  upsert?: InputMaybe<HeaderSubMenuItemParentUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubMenuItemParentUpdateWithNestedWhereUniqueAndPositionInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateWithNestedWhereUniqueAndPositionInput>
}

export type HeaderSubMenuItemParentUpdateWithNestedWhereUniqueInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpdateWithNestedWhereUniqueInput>
}

export type HeaderSubMenuItemParentUpsertWithNestedWhereUniqueAndPositionInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpsertWithNestedWhereUniqueAndPositionInput>
}

export type HeaderSubMenuItemParentUpsertWithNestedWhereUniqueInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubMenuItemParentWhereInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemWhereInput>
}

export type HeaderSubMenuItemParentWhereUniqueInput = {
  HeaderMenuItem?: InputMaybe<HeaderMenuItemWhereUniqueInput>
}

export type HeaderSubMenuItemUpdateInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<HeaderSubMenuItemUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  omrHeaderSubSubmenuItems?: InputMaybe<HeaderSubSubMenuItemUpdateManyInlineInput>
}

export type HeaderSubMenuItemUpdateLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubMenuItemUpdateLocalizationInput = {
  data: HeaderSubMenuItemUpdateLocalizationDataInput
  locale: Locale
}

export type HeaderSubMenuItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeaderSubMenuItemCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderSubMenuItemUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<HeaderSubMenuItemUpsertLocalizationInput>>
}

export type HeaderSubMenuItemUpdateManyInlineInput = {
  /** Create and connect multiple HeaderSubMenuItem component instances */
  create?: InputMaybe<Array<HeaderSubMenuItemCreateWithPositionInput>>
  /** Delete multiple HeaderSubMenuItem documents */
  delete?: InputMaybe<Array<HeaderSubMenuItemWhereUniqueInput>>
  /** Update multiple HeaderSubMenuItem component instances */
  update?: InputMaybe<Array<HeaderSubMenuItemUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HeaderSubMenuItem component instances */
  upsert?: InputMaybe<Array<HeaderSubMenuItemUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HeaderSubMenuItemUpdateManyInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<HeaderSubMenuItemUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubMenuItemUpdateManyLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubMenuItemUpdateManyLocalizationInput = {
  data: HeaderSubMenuItemUpdateManyLocalizationDataInput
  locale: Locale
}

export type HeaderSubMenuItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderSubMenuItemUpdateManyLocalizationInput>>
}

export type HeaderSubMenuItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeaderSubMenuItemUpdateManyInput
  /** Document search */
  where: HeaderSubMenuItemWhereInput
}

export type HeaderSubMenuItemUpdateOneInlineInput = {
  /** Create and connect one HeaderSubMenuItem document */
  create?: InputMaybe<HeaderSubMenuItemCreateInput>
  /** Delete currently connected HeaderSubMenuItem document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderSubMenuItem document */
  update?: InputMaybe<HeaderSubMenuItemUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderSubMenuItem document */
  upsert?: InputMaybe<HeaderSubMenuItemUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubMenuItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HeaderSubMenuItemUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderSubMenuItemWhereUniqueInput
}

export type HeaderSubMenuItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeaderSubMenuItemUpdateInput
  /** Unique document search */
  where: HeaderSubMenuItemWhereUniqueInput
}

export type HeaderSubMenuItemUpsertInput = {
  /** Create document if it didn't exist */
  create: HeaderSubMenuItemCreateInput
  /** Update document if it exists */
  update: HeaderSubMenuItemUpdateInput
}

export type HeaderSubMenuItemUpsertLocalizationInput = {
  create: HeaderSubMenuItemCreateLocalizationDataInput
  locale: Locale
  update: HeaderSubMenuItemUpdateLocalizationDataInput
}

export type HeaderSubMenuItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HeaderSubMenuItemUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderSubMenuItemWhereUniqueInput
}

export type HeaderSubMenuItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeaderSubMenuItemUpsertInput
  /** Unique document search */
  where: HeaderSubMenuItemWhereUniqueInput
}

/** Identifies documents */
export type HeaderSubMenuItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderSubMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  omrHeaderSubSubmenuItems_every?: InputMaybe<HeaderSubSubMenuItemWhereInput>
  omrHeaderSubSubmenuItems_none?: InputMaybe<HeaderSubSubMenuItemWhereInput>
  omrHeaderSubSubmenuItems_some?: InputMaybe<HeaderSubSubMenuItemWhereInput>
}

/** References HeaderSubMenuItem record uniquely */
export type HeaderSubMenuItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Header sub-submenu item */
export type HeaderSubSubMenuItem = Entity & {
  __typename?: 'HeaderSubSubMenuItem'
  /** The unique identifier */
  id: Scalars['ID']['output']
  link: Scalars['String']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<HeaderSubSubMenuItem>
  name: Scalars['String']['output']
  /** System stage field */
  stage: Stage
}

/** Header sub-submenu item */
export type HeaderSubSubMenuItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type HeaderSubSubMenuItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HeaderSubSubMenuItemWhereUniqueInput
}

/** A connection to a list of items. */
export type HeaderSubSubMenuItemConnection = {
  __typename?: 'HeaderSubSubMenuItemConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HeaderSubSubMenuItemEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HeaderSubSubMenuItemCreateInput = {
  /** link input for default locale (de) */
  link: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HeaderSubSubMenuItemCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
}

export type HeaderSubSubMenuItemCreateLocalizationDataInput = {
  link: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type HeaderSubSubMenuItemCreateLocalizationInput = {
  /** Localization input */
  data: HeaderSubSubMenuItemCreateLocalizationDataInput
  locale: Locale
}

export type HeaderSubSubMenuItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HeaderSubSubMenuItemCreateLocalizationInput>>
}

export type HeaderSubSubMenuItemCreateManyInlineInput = {
  /** Create and connect multiple existing HeaderSubSubMenuItem documents */
  create?: InputMaybe<Array<HeaderSubSubMenuItemCreateInput>>
}

export type HeaderSubSubMenuItemCreateOneInlineInput = {
  /** Create and connect one HeaderSubSubMenuItem document */
  create?: InputMaybe<HeaderSubSubMenuItemCreateInput>
}

export type HeaderSubSubMenuItemCreateWithPositionInput = {
  /** Document to create */
  data: HeaderSubSubMenuItemCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HeaderSubSubMenuItemEdge = {
  __typename?: 'HeaderSubSubMenuItemEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HeaderSubSubMenuItem
}

/** Identifies documents */
export type HeaderSubSubMenuItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum HeaderSubSubMenuItemOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type HeaderSubSubMenuItemParent = HeaderSubMenuItem

export type HeaderSubSubMenuItemParentConnectInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemConnectInput>
}

export type HeaderSubSubMenuItemParentCreateInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemCreateInput>
}

export type HeaderSubSubMenuItemParentCreateManyInlineInput = {
  /** Create and connect multiple existing HeaderSubSubMenuItemParent documents */
  create?: InputMaybe<Array<HeaderSubSubMenuItemParentCreateInput>>
}

export type HeaderSubSubMenuItemParentCreateOneInlineInput = {
  /** Create and connect one HeaderSubSubMenuItemParent document */
  create?: InputMaybe<HeaderSubSubMenuItemParentCreateInput>
}

export type HeaderSubSubMenuItemParentCreateWithPositionInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemCreateWithPositionInput>
}

export type HeaderSubSubMenuItemParentUpdateInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpdateInput>
}

export type HeaderSubSubMenuItemParentUpdateManyInlineInput = {
  /** Create and connect multiple HeaderSubSubMenuItemParent component instances */
  create?: InputMaybe<Array<HeaderSubSubMenuItemParentCreateWithPositionInput>>
  /** Delete multiple HeaderSubSubMenuItemParent documents */
  delete?: InputMaybe<Array<HeaderSubSubMenuItemParentWhereUniqueInput>>
  /** Update multiple HeaderSubSubMenuItemParent component instances */
  update?: InputMaybe<Array<HeaderSubSubMenuItemParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HeaderSubSubMenuItemParent component instances */
  upsert?: InputMaybe<Array<HeaderSubSubMenuItemParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HeaderSubSubMenuItemParentUpdateManyWithNestedWhereInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpdateManyWithNestedWhereInput>
}

export type HeaderSubSubMenuItemParentUpdateOneInlineInput = {
  /** Create and connect one HeaderSubSubMenuItemParent document */
  create?: InputMaybe<HeaderSubSubMenuItemParentCreateInput>
  /** Delete currently connected HeaderSubSubMenuItemParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderSubSubMenuItemParent document */
  update?: InputMaybe<HeaderSubSubMenuItemParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderSubSubMenuItemParent document */
  upsert?: InputMaybe<HeaderSubSubMenuItemParentUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubSubMenuItemParentUpdateWithNestedWhereUniqueAndPositionInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpdateWithNestedWhereUniqueAndPositionInput>
}

export type HeaderSubSubMenuItemParentUpdateWithNestedWhereUniqueInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpdateWithNestedWhereUniqueInput>
}

export type HeaderSubSubMenuItemParentUpsertWithNestedWhereUniqueAndPositionInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpsertWithNestedWhereUniqueAndPositionInput>
}

export type HeaderSubSubMenuItemParentUpsertWithNestedWhereUniqueInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubSubMenuItemParentWhereInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemWhereInput>
}

export type HeaderSubSubMenuItemParentWhereUniqueInput = {
  HeaderSubMenuItem?: InputMaybe<HeaderSubMenuItemWhereUniqueInput>
}

export type HeaderSubSubMenuItemUpdateInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<HeaderSubSubMenuItemUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubSubMenuItemUpdateLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubSubMenuItemUpdateLocalizationInput = {
  data: HeaderSubSubMenuItemUpdateLocalizationDataInput
  locale: Locale
}

export type HeaderSubSubMenuItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeaderSubSubMenuItemCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderSubSubMenuItemUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<HeaderSubSubMenuItemUpsertLocalizationInput>>
}

export type HeaderSubSubMenuItemUpdateManyInlineInput = {
  /** Create and connect multiple HeaderSubSubMenuItem component instances */
  create?: InputMaybe<Array<HeaderSubSubMenuItemCreateWithPositionInput>>
  /** Delete multiple HeaderSubSubMenuItem documents */
  delete?: InputMaybe<Array<HeaderSubSubMenuItemWhereUniqueInput>>
  /** Update multiple HeaderSubSubMenuItem component instances */
  update?: InputMaybe<Array<HeaderSubSubMenuItemUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HeaderSubSubMenuItem component instances */
  upsert?: InputMaybe<Array<HeaderSubSubMenuItemUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HeaderSubSubMenuItemUpdateManyInput = {
  /** link input for default locale (de) */
  link?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<HeaderSubSubMenuItemUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubSubMenuItemUpdateManyLocalizationDataInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type HeaderSubSubMenuItemUpdateManyLocalizationInput = {
  data: HeaderSubSubMenuItemUpdateManyLocalizationDataInput
  locale: Locale
}

export type HeaderSubSubMenuItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HeaderSubSubMenuItemUpdateManyLocalizationInput>>
}

export type HeaderSubSubMenuItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeaderSubSubMenuItemUpdateManyInput
  /** Document search */
  where: HeaderSubSubMenuItemWhereInput
}

export type HeaderSubSubMenuItemUpdateOneInlineInput = {
  /** Create and connect one HeaderSubSubMenuItem document */
  create?: InputMaybe<HeaderSubSubMenuItemCreateInput>
  /** Delete currently connected HeaderSubSubMenuItem document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HeaderSubSubMenuItem document */
  update?: InputMaybe<HeaderSubSubMenuItemUpdateWithNestedWhereUniqueInput>
  /** Upsert single HeaderSubSubMenuItem document */
  upsert?: InputMaybe<HeaderSubSubMenuItemUpsertWithNestedWhereUniqueInput>
}

export type HeaderSubSubMenuItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HeaderSubSubMenuItemUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderSubSubMenuItemWhereUniqueInput
}

export type HeaderSubSubMenuItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeaderSubSubMenuItemUpdateInput
  /** Unique document search */
  where: HeaderSubSubMenuItemWhereUniqueInput
}

export type HeaderSubSubMenuItemUpsertInput = {
  /** Create document if it didn't exist */
  create: HeaderSubSubMenuItemCreateInput
  /** Update document if it exists */
  update: HeaderSubSubMenuItemUpdateInput
}

export type HeaderSubSubMenuItemUpsertLocalizationInput = {
  create: HeaderSubSubMenuItemCreateLocalizationDataInput
  locale: Locale
  update: HeaderSubSubMenuItemUpdateLocalizationDataInput
}

export type HeaderSubSubMenuItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HeaderSubSubMenuItemUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HeaderSubSubMenuItemWhereUniqueInput
}

export type HeaderSubSubMenuItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeaderSubSubMenuItemUpsertInput
  /** Unique document search */
  where: HeaderSubSubMenuItemWhereUniqueInput
}

/** Identifies documents */
export type HeaderSubSubMenuItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderSubSubMenuItemWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References HeaderSubSubMenuItem record uniquely */
export type HeaderSubSubMenuItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type HeaderUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<HeaderUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  mobileLogo?: InputMaybe<AssetUpdateOneInlineInput>
  omrHeaderMenuItems?: InputMaybe<HeaderMenuItemUpdateManyInlineInput>
  omrHeaderPartners?: InputMaybe<OmrPartnerUpdateManyInlineInput>
  omrHeaderSocials?: InputMaybe<SocialLinkUpdateManyInlineInput>
}

export type HeaderUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HeaderCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
}

export type HeaderUpdateManyInlineInput = {
  /** Connect multiple existing Header documents */
  connect?: InputMaybe<Array<HeaderConnectInput>>
  /** Create and connect multiple Header documents */
  create?: InputMaybe<Array<HeaderCreateInput>>
  /** Delete multiple Header documents */
  delete?: InputMaybe<Array<HeaderWhereUniqueInput>>
  /** Disconnect multiple Header documents */
  disconnect?: InputMaybe<Array<HeaderWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Header documents */
  set?: InputMaybe<Array<HeaderWhereUniqueInput>>
  /** Update multiple Header documents */
  update?: InputMaybe<Array<HeaderUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Header documents */
  upsert?: InputMaybe<Array<HeaderUpsertWithNestedWhereUniqueInput>>
}

export type HeaderUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type HeaderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HeaderUpdateManyInput
  /** Document search */
  where: HeaderWhereInput
}

export type HeaderUpdateOneInlineInput = {
  /** Connect existing Header document */
  connect?: InputMaybe<HeaderWhereUniqueInput>
  /** Create and connect one Header document */
  create?: InputMaybe<HeaderCreateInput>
  /** Delete currently connected Header document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Header document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Header document */
  update?: InputMaybe<HeaderUpdateWithNestedWhereUniqueInput>
  /** Upsert single Header document */
  upsert?: InputMaybe<HeaderUpsertWithNestedWhereUniqueInput>
}

export type HeaderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HeaderUpdateInput
  /** Unique document search */
  where: HeaderWhereUniqueInput
}

export type HeaderUpsertInput = {
  /** Create document if it didn't exist */
  create: HeaderCreateInput
  /** Update document if it exists */
  update: HeaderUpdateInput
}

export type HeaderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HeaderUpsertInput
  /** Unique document search */
  where: HeaderWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type HeaderWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type HeaderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HeaderWhereStageInput>
  documentInStages_none?: InputMaybe<HeaderWhereStageInput>
  documentInStages_some?: InputMaybe<HeaderWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileLogo?: InputMaybe<AssetWhereInput>
  omrHeaderMenuItems_every?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderMenuItems_none?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderMenuItems_some?: InputMaybe<HeaderMenuItemWhereInput>
  omrHeaderPartners_every?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderPartners_none?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderPartners_some?: InputMaybe<OmrPartnerWhereInput>
  omrHeaderSocials_every?: InputMaybe<SocialLinkWhereInput>
  omrHeaderSocials_none?: InputMaybe<SocialLinkWhereInput>
  omrHeaderSocials_some?: InputMaybe<SocialLinkWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HeaderWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HeaderWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HeaderWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HeaderWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HeaderWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Header record uniquely */
export type HeaderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Hotel = Entity &
  Node & {
    __typename?: 'Hotel'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description: Scalars['String']['output']
    distance: Scalars['String']['output']
    /** Get the document in other stages */
    documentInStages: Array<Hotel>
    /** List of Hotel versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Hotel>
    logo: Asset
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    rating: Scalars['String']['output']
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    url: Scalars['String']['output']
  }

export type HotelCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type HotelCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HotelDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type HotelHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type HotelLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type HotelLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HotelPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type HotelPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HotelScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type HotelUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type HotelUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HotelConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HotelWhereUniqueInput
}

/** A connection to a list of items. */
export type HotelConnection = {
  __typename?: 'HotelConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HotelEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HotelCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** distance input for default locale (de) */
  distance: Scalars['String']['input']
  hotelInSection?: InputMaybe<SectionHotelCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HotelCreateLocalizationsInput>
  logo: AssetCreateOneInlineInput
  name: Scalars['String']['input']
  /** rating input for default locale (de) */
  rating: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** url input for default locale (de) */
  url: Scalars['String']['input']
}

export type HotelCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description: Scalars['String']['input']
  distance: Scalars['String']['input']
  rating: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  url: Scalars['String']['input']
}

export type HotelCreateLocalizationInput = {
  /** Localization input */
  data: HotelCreateLocalizationDataInput
  locale: Locale
}

export type HotelCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HotelCreateLocalizationInput>>
}

export type HotelCreateManyInlineInput = {
  /** Connect multiple existing Hotel documents */
  connect?: InputMaybe<Array<HotelWhereUniqueInput>>
  /** Create and connect multiple existing Hotel documents */
  create?: InputMaybe<Array<HotelCreateInput>>
}

export type HotelCreateOneInlineInput = {
  /** Connect one existing Hotel document */
  connect?: InputMaybe<HotelWhereUniqueInput>
  /** Create and connect one Hotel document */
  create?: InputMaybe<HotelCreateInput>
}

/** An edge in a connection. */
export type HotelEdge = {
  __typename?: 'HotelEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Hotel
}

/** Identifies documents */
export type HotelManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HotelWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HotelWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HotelWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HotelWhereStageInput>
  documentInStages_none?: InputMaybe<HotelWhereStageInput>
  documentInStages_some?: InputMaybe<HotelWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum HotelOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DistanceAsc = 'distance_ASC',
  DistanceDesc = 'distance_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type HotelUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** distance input for default locale (de) */
  distance?: InputMaybe<Scalars['String']['input']>
  hotelInSection?: InputMaybe<SectionHotelUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<HotelUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** rating input for default locale (de) */
  rating?: InputMaybe<Scalars['String']['input']>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type HotelUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  distance?: InputMaybe<Scalars['String']['input']>
  rating?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type HotelUpdateLocalizationInput = {
  data: HotelUpdateLocalizationDataInput
  locale: Locale
}

export type HotelUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HotelCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<HotelUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<HotelUpsertLocalizationInput>>
}

export type HotelUpdateManyInlineInput = {
  /** Connect multiple existing Hotel documents */
  connect?: InputMaybe<Array<HotelConnectInput>>
  /** Create and connect multiple Hotel documents */
  create?: InputMaybe<Array<HotelCreateInput>>
  /** Delete multiple Hotel documents */
  delete?: InputMaybe<Array<HotelWhereUniqueInput>>
  /** Disconnect multiple Hotel documents */
  disconnect?: InputMaybe<Array<HotelWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Hotel documents */
  set?: InputMaybe<Array<HotelWhereUniqueInput>>
  /** Update multiple Hotel documents */
  update?: InputMaybe<Array<HotelUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Hotel documents */
  upsert?: InputMaybe<Array<HotelUpsertWithNestedWhereUniqueInput>>
}

export type HotelUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** distance input for default locale (de) */
  distance?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<HotelUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** rating input for default locale (de) */
  rating?: InputMaybe<Scalars['String']['input']>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type HotelUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  distance?: InputMaybe<Scalars['String']['input']>
  rating?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type HotelUpdateManyLocalizationInput = {
  data: HotelUpdateManyLocalizationDataInput
  locale: Locale
}

export type HotelUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HotelUpdateManyLocalizationInput>>
}

export type HotelUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HotelUpdateManyInput
  /** Document search */
  where: HotelWhereInput
}

export type HotelUpdateOneInlineInput = {
  /** Connect existing Hotel document */
  connect?: InputMaybe<HotelWhereUniqueInput>
  /** Create and connect one Hotel document */
  create?: InputMaybe<HotelCreateInput>
  /** Delete currently connected Hotel document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Hotel document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Hotel document */
  update?: InputMaybe<HotelUpdateWithNestedWhereUniqueInput>
  /** Upsert single Hotel document */
  upsert?: InputMaybe<HotelUpsertWithNestedWhereUniqueInput>
}

export type HotelUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HotelUpdateInput
  /** Unique document search */
  where: HotelWhereUniqueInput
}

export type HotelUpsertInput = {
  /** Create document if it didn't exist */
  create: HotelCreateInput
  /** Update document if it exists */
  update: HotelUpdateInput
}

export type HotelUpsertLocalizationInput = {
  create: HotelCreateLocalizationDataInput
  locale: Locale
  update: HotelUpdateLocalizationDataInput
}

export type HotelUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HotelUpsertInput
  /** Unique document search */
  where: HotelWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type HotelWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type HotelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HotelWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HotelWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HotelWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  distance?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  distance_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  distance_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  distance_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  distance_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  distance_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  distance_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  distance_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  distance_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  distance_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<HotelWhereStageInput>
  documentInStages_none?: InputMaybe<HotelWhereStageInput>
  documentInStages_some?: InputMaybe<HotelWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  rating?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  rating_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  rating_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  rating_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  rating_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  rating_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  rating_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  rating_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  rating_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HotelWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HotelWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HotelWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HotelWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HotelWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Hotel record uniquely */
export type HotelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbed = Entity &
  Node & {
    __typename?: 'HtmlEmbed'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<HtmlEmbed>
    /** List of HtmlEmbed versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    rawHtml: Scalars['String']['output']
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** Descriptive title for HTML snippet */
    title?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Optional wordpress reference used by importer */
    wpId?: Maybe<Scalars['String']['output']>
  }

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Raw HTML code to be rendered on the page */
export type HtmlEmbedUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HtmlEmbedConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HtmlEmbedWhereUniqueInput
}

/** A connection to a list of items. */
export type HtmlEmbedConnection = {
  __typename?: 'HtmlEmbedConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HtmlEmbedEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HtmlEmbedCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  rawHtml: Scalars['String']['input']
  title?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type HtmlEmbedCreateManyInlineInput = {
  /** Connect multiple existing HtmlEmbed documents */
  connect?: InputMaybe<Array<HtmlEmbedWhereUniqueInput>>
  /** Create and connect multiple existing HtmlEmbed documents */
  create?: InputMaybe<Array<HtmlEmbedCreateInput>>
}

export type HtmlEmbedCreateOneInlineInput = {
  /** Connect one existing HtmlEmbed document */
  connect?: InputMaybe<HtmlEmbedWhereUniqueInput>
  /** Create and connect one HtmlEmbed document */
  create?: InputMaybe<HtmlEmbedCreateInput>
}

/** An edge in a connection. */
export type HtmlEmbedEdge = {
  __typename?: 'HtmlEmbedEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HtmlEmbed
}

/** Identifies documents */
export type HtmlEmbedManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HtmlEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<HtmlEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<HtmlEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  rawHtml?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  rawHtml_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  rawHtml_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  rawHtml_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  rawHtml_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  rawHtml_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  rawHtml_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  rawHtml_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  rawHtml_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  rawHtml_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum HtmlEmbedOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  RawHtmlAsc = 'rawHtml_ASC',
  RawHtmlDesc = 'rawHtml_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export type HtmlEmbedUpdateInput = {
  rawHtml?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type HtmlEmbedUpdateManyInlineInput = {
  /** Connect multiple existing HtmlEmbed documents */
  connect?: InputMaybe<Array<HtmlEmbedConnectInput>>
  /** Create and connect multiple HtmlEmbed documents */
  create?: InputMaybe<Array<HtmlEmbedCreateInput>>
  /** Delete multiple HtmlEmbed documents */
  delete?: InputMaybe<Array<HtmlEmbedWhereUniqueInput>>
  /** Disconnect multiple HtmlEmbed documents */
  disconnect?: InputMaybe<Array<HtmlEmbedWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HtmlEmbed documents */
  set?: InputMaybe<Array<HtmlEmbedWhereUniqueInput>>
  /** Update multiple HtmlEmbed documents */
  update?: InputMaybe<Array<HtmlEmbedUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HtmlEmbed documents */
  upsert?: InputMaybe<Array<HtmlEmbedUpsertWithNestedWhereUniqueInput>>
}

export type HtmlEmbedUpdateManyInput = {
  rawHtml?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HtmlEmbedUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HtmlEmbedUpdateManyInput
  /** Document search */
  where: HtmlEmbedWhereInput
}

export type HtmlEmbedUpdateOneInlineInput = {
  /** Connect existing HtmlEmbed document */
  connect?: InputMaybe<HtmlEmbedWhereUniqueInput>
  /** Create and connect one HtmlEmbed document */
  create?: InputMaybe<HtmlEmbedCreateInput>
  /** Delete currently connected HtmlEmbed document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HtmlEmbed document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HtmlEmbed document */
  update?: InputMaybe<HtmlEmbedUpdateWithNestedWhereUniqueInput>
  /** Upsert single HtmlEmbed document */
  upsert?: InputMaybe<HtmlEmbedUpsertWithNestedWhereUniqueInput>
}

export type HtmlEmbedUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HtmlEmbedUpdateInput
  /** Unique document search */
  where: HtmlEmbedWhereUniqueInput
}

export type HtmlEmbedUpsertInput = {
  /** Create document if it didn't exist */
  create: HtmlEmbedCreateInput
  /** Update document if it exists */
  update: HtmlEmbedUpdateInput
}

export type HtmlEmbedUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HtmlEmbedUpsertInput
  /** Unique document search */
  where: HtmlEmbedWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type HtmlEmbedWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type HtmlEmbedWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HtmlEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HtmlEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<HtmlEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<HtmlEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  rawHtml?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  rawHtml_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  rawHtml_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  rawHtml_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  rawHtml_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  rawHtml_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  rawHtml_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  rawHtml_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  rawHtml_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  rawHtml_starts_with?: InputMaybe<Scalars['String']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HtmlEmbedWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HtmlEmbedWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HtmlEmbedWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HtmlEmbedWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HtmlEmbedWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References HtmlEmbed record uniquely */
export type HtmlEmbedWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

/** Hub page to unfiy content accros all departments */
export type HubPage = Entity &
  Node & {
    __typename?: 'HubPage'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<HubPage>
    /** List of HubPage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Identifier of the hub page to distinguish it from other hub pages */
    identifier: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** List of sections. Each section includes the content of content navigation slider. */
    sections: Array<HubPagesectionsUnion>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Hub page to unfiy content accros all departments */
export type HubPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Hub page to unfiy content accros all departments */
export type HubPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Hub page to unfiy content accros all departments */
export type HubPageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Hub page to unfiy content accros all departments */
export type HubPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Hub page to unfiy content accros all departments */
export type HubPageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Hub page to unfiy content accros all departments */
export type HubPageSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

/** Hub page to unfiy content accros all departments */
export type HubPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HubPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HubPageWhereUniqueInput
}

/** A connection to a list of items. */
export type HubPageConnection = {
  __typename?: 'HubPageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HubPageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HubPageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  identifier: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HubPageCreateLocalizationsInput>
  sections?: InputMaybe<HubPagesectionsUnionCreateManyInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type HubPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type HubPageCreateLocalizationInput = {
  /** Localization input */
  data: HubPageCreateLocalizationDataInput
  locale: Locale
}

export type HubPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HubPageCreateLocalizationInput>>
}

export type HubPageCreateManyInlineInput = {
  /** Connect multiple existing HubPage documents */
  connect?: InputMaybe<Array<HubPageWhereUniqueInput>>
  /** Create and connect multiple existing HubPage documents */
  create?: InputMaybe<Array<HubPageCreateInput>>
}

export type HubPageCreateOneInlineInput = {
  /** Connect one existing HubPage document */
  connect?: InputMaybe<HubPageWhereUniqueInput>
  /** Create and connect one HubPage document */
  create?: InputMaybe<HubPageCreateInput>
}

/** An edge in a connection. */
export type HubPageEdge = {
  __typename?: 'HubPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HubPage
}

/** Identifies documents */
export type HubPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HubPageWhereStageInput>
  documentInStages_none?: InputMaybe<HubPageWhereStageInput>
  documentInStages_some?: InputMaybe<HubPageWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  identifier_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  identifier_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  identifier_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  identifier_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  identifier_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  identifier_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  /** All values in which the union is empty. */
  sections_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  sections_some?: InputMaybe<HubPagesectionsUnionWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum HubPageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Common fields for all sections */
export type HubPageSectionCommonFields = Entity & {
  __typename?: 'HubPageSectionCommonFields'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<HubPageSectionCommonFields>
  /** System stage field */
  stage: Stage
  /** Title/headline for each section. Used for content navigation slider. */
  title: Scalars['String']['output']
}

/** Common fields for all sections */
export type HubPageSectionCommonFieldsLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type HubPageSectionCommonFieldsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HubPageSectionCommonFieldsWhereUniqueInput
}

/** A connection to a list of items. */
export type HubPageSectionCommonFieldsConnection = {
  __typename?: 'HubPageSectionCommonFieldsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HubPageSectionCommonFieldsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HubPageSectionCommonFieldsCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<HubPageSectionCommonFieldsCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type HubPageSectionCommonFieldsCreateLocalizationDataInput = {
  title: Scalars['String']['input']
}

export type HubPageSectionCommonFieldsCreateLocalizationInput = {
  /** Localization input */
  data: HubPageSectionCommonFieldsCreateLocalizationDataInput
  locale: Locale
}

export type HubPageSectionCommonFieldsCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsCreateLocalizationInput>>
}

export type HubPageSectionCommonFieldsCreateManyInlineInput = {
  /** Create and connect multiple existing HubPageSectionCommonFields documents */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsCreateInput>>
}

export type HubPageSectionCommonFieldsCreateOneInlineInput = {
  /** Create and connect one HubPageSectionCommonFields document */
  create?: InputMaybe<HubPageSectionCommonFieldsCreateInput>
}

export type HubPageSectionCommonFieldsCreateWithPositionInput = {
  /** Document to create */
  data: HubPageSectionCommonFieldsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HubPageSectionCommonFieldsEdge = {
  __typename?: 'HubPageSectionCommonFieldsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HubPageSectionCommonFields
}

/** Identifies documents */
export type HubPageSectionCommonFieldsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum HubPageSectionCommonFieldsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type HubPageSectionCommonFieldsParent = HubPageSectionContentLibrary | HubPageSectionFestivalEvents | HubPageSectionNews

export type HubPageSectionCommonFieldsParentConnectInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryConnectInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsConnectInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsConnectInput>
}

export type HubPageSectionCommonFieldsParentCreateInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryCreateInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsCreateInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsCreateInput>
}

export type HubPageSectionCommonFieldsParentCreateManyInlineInput = {
  /** Create and connect multiple existing HubPageSectionCommonFieldsParent documents */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsParentCreateInput>>
}

export type HubPageSectionCommonFieldsParentCreateOneInlineInput = {
  /** Create and connect one HubPageSectionCommonFieldsParent document */
  create?: InputMaybe<HubPageSectionCommonFieldsParentCreateInput>
}

export type HubPageSectionCommonFieldsParentCreateWithPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryCreateWithPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsCreateWithPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsCreateWithPositionInput>
}

export type HubPageSectionCommonFieldsParentUpdateInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateInput>
}

export type HubPageSectionCommonFieldsParentUpdateManyInlineInput = {
  /** Create and connect multiple HubPageSectionCommonFieldsParent component instances */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsParentCreateWithPositionInput>>
  /** Delete multiple HubPageSectionCommonFieldsParent documents */
  delete?: InputMaybe<Array<HubPageSectionCommonFieldsParentWhereUniqueInput>>
  /** Update multiple HubPageSectionCommonFieldsParent component instances */
  update?: InputMaybe<Array<HubPageSectionCommonFieldsParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPageSectionCommonFieldsParent component instances */
  upsert?: InputMaybe<Array<HubPageSectionCommonFieldsParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPageSectionCommonFieldsParentUpdateManyWithNestedWhereInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateManyWithNestedWhereInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateManyWithNestedWhereInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateManyWithNestedWhereInput>
}

export type HubPageSectionCommonFieldsParentUpdateOneInlineInput = {
  /** Create and connect one HubPageSectionCommonFieldsParent document */
  create?: InputMaybe<HubPageSectionCommonFieldsParentCreateInput>
  /** Delete currently connected HubPageSectionCommonFieldsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionCommonFieldsParent document */
  update?: InputMaybe<HubPageSectionCommonFieldsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionCommonFieldsParent document */
  upsert?: InputMaybe<HubPageSectionCommonFieldsParentUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionCommonFieldsParentUpdateWithNestedWhereUniqueAndPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueAndPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueAndPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateWithNestedWhereUniqueAndPositionInput>
}

export type HubPageSectionCommonFieldsParentUpdateWithNestedWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateWithNestedWhereUniqueInput>
}

export type HubPageSectionCommonFieldsParentUpsertWithNestedWhereUniqueAndPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueAndPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueAndPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpsertWithNestedWhereUniqueAndPositionInput>
}

export type HubPageSectionCommonFieldsParentUpsertWithNestedWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionCommonFieldsParentWhereInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryWhereInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsWhereInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsWhereInput>
}

export type HubPageSectionCommonFieldsParentWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsWhereUniqueInput>
}

export type HubPageSectionCommonFieldsUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<HubPageSectionCommonFieldsUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionCommonFieldsUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionCommonFieldsUpdateLocalizationInput = {
  data: HubPageSectionCommonFieldsUpdateLocalizationDataInput
  locale: Locale
}

export type HubPageSectionCommonFieldsUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<HubPageSectionCommonFieldsUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<HubPageSectionCommonFieldsUpsertLocalizationInput>>
}

export type HubPageSectionCommonFieldsUpdateManyInlineInput = {
  /** Create and connect multiple HubPageSectionCommonFields component instances */
  create?: InputMaybe<Array<HubPageSectionCommonFieldsCreateWithPositionInput>>
  /** Delete multiple HubPageSectionCommonFields documents */
  delete?: InputMaybe<Array<HubPageSectionCommonFieldsWhereUniqueInput>>
  /** Update multiple HubPageSectionCommonFields component instances */
  update?: InputMaybe<Array<HubPageSectionCommonFieldsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPageSectionCommonFields component instances */
  upsert?: InputMaybe<Array<HubPageSectionCommonFieldsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPageSectionCommonFieldsUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<HubPageSectionCommonFieldsUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionCommonFieldsUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionCommonFieldsUpdateManyLocalizationInput = {
  data: HubPageSectionCommonFieldsUpdateManyLocalizationDataInput
  locale: Locale
}

export type HubPageSectionCommonFieldsUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<HubPageSectionCommonFieldsUpdateManyLocalizationInput>>
}

export type HubPageSectionCommonFieldsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HubPageSectionCommonFieldsUpdateManyInput
  /** Document search */
  where: HubPageSectionCommonFieldsWhereInput
}

export type HubPageSectionCommonFieldsUpdateOneInlineInput = {
  /** Create and connect one HubPageSectionCommonFields document */
  create?: InputMaybe<HubPageSectionCommonFieldsCreateInput>
  /** Delete currently connected HubPageSectionCommonFields document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionCommonFields document */
  update?: InputMaybe<HubPageSectionCommonFieldsUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionCommonFields document */
  upsert?: InputMaybe<HubPageSectionCommonFieldsUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionCommonFieldsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HubPageSectionCommonFieldsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionCommonFieldsWhereUniqueInput
}

export type HubPageSectionCommonFieldsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HubPageSectionCommonFieldsUpdateInput
  /** Unique document search */
  where: HubPageSectionCommonFieldsWhereUniqueInput
}

export type HubPageSectionCommonFieldsUpsertInput = {
  /** Create document if it didn't exist */
  create: HubPageSectionCommonFieldsCreateInput
  /** Update document if it exists */
  update: HubPageSectionCommonFieldsUpdateInput
}

export type HubPageSectionCommonFieldsUpsertLocalizationInput = {
  create: HubPageSectionCommonFieldsCreateLocalizationDataInput
  locale: Locale
  update: HubPageSectionCommonFieldsUpdateLocalizationDataInput
}

export type HubPageSectionCommonFieldsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HubPageSectionCommonFieldsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionCommonFieldsWhereUniqueInput
}

export type HubPageSectionCommonFieldsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HubPageSectionCommonFieldsUpsertInput
  /** Unique document search */
  where: HubPageSectionCommonFieldsWhereUniqueInput
}

/** Identifies documents */
export type HubPageSectionCommonFieldsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionCommonFieldsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References HubPageSectionCommonFields record uniquely */
export type HubPageSectionCommonFieldsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Content Library Section for Hub Pages */
export type HubPageSectionContentLibrary = Entity & {
  __typename?: 'HubPageSectionContentLibrary'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Common fields for all sections */
  sectionCommonFields: HubPageSectionCommonFields
  /** System stage field */
  stage: Stage
}

/** Content Library Section for Hub Pages */
export type HubPageSectionContentLibrarySectionCommonFieldsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HubPageSectionContentLibraryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HubPageSectionContentLibraryWhereUniqueInput
}

/** A connection to a list of items. */
export type HubPageSectionContentLibraryConnection = {
  __typename?: 'HubPageSectionContentLibraryConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HubPageSectionContentLibraryEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HubPageSectionContentLibraryCreateInput = {
  sectionCommonFields: HubPageSectionCommonFieldsCreateOneInlineInput
}

export type HubPageSectionContentLibraryCreateManyInlineInput = {
  /** Create and connect multiple existing HubPageSectionContentLibrary documents */
  create?: InputMaybe<Array<HubPageSectionContentLibraryCreateInput>>
}

export type HubPageSectionContentLibraryCreateOneInlineInput = {
  /** Create and connect one HubPageSectionContentLibrary document */
  create?: InputMaybe<HubPageSectionContentLibraryCreateInput>
}

export type HubPageSectionContentLibraryCreateWithPositionInput = {
  /** Document to create */
  data: HubPageSectionContentLibraryCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HubPageSectionContentLibraryEdge = {
  __typename?: 'HubPageSectionContentLibraryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HubPageSectionContentLibrary
}

/** Identifies documents */
export type HubPageSectionContentLibraryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

export enum HubPageSectionContentLibraryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type HubPageSectionContentLibraryParent = HubPage

export type HubPageSectionContentLibraryParentConnectInput = {
  HubPage?: InputMaybe<HubPageConnectInput>
}

export type HubPageSectionContentLibraryParentCreateInput = {
  HubPage?: InputMaybe<HubPageCreateInput>
}

export type HubPageSectionContentLibraryParentCreateManyInlineInput = {
  /** Connect multiple existing HubPageSectionContentLibraryParent documents */
  connect?: InputMaybe<Array<HubPageSectionContentLibraryParentWhereUniqueInput>>
  /** Create and connect multiple existing HubPageSectionContentLibraryParent documents */
  create?: InputMaybe<Array<HubPageSectionContentLibraryParentCreateInput>>
}

export type HubPageSectionContentLibraryParentCreateOneInlineInput = {
  /** Connect one existing HubPageSectionContentLibraryParent document */
  connect?: InputMaybe<HubPageSectionContentLibraryParentWhereUniqueInput>
  /** Create and connect one HubPageSectionContentLibraryParent document */
  create?: InputMaybe<HubPageSectionContentLibraryParentCreateInput>
}

export type HubPageSectionContentLibraryParentUpdateInput = {
  HubPage?: InputMaybe<HubPageUpdateInput>
}

export type HubPageSectionContentLibraryParentUpdateManyInlineInput = {
  /** Connect multiple existing HubPageSectionContentLibraryParent documents */
  connect?: InputMaybe<Array<HubPageSectionContentLibraryParentConnectInput>>
  /** Create and connect multiple HubPageSectionContentLibraryParent documents */
  create?: InputMaybe<Array<HubPageSectionContentLibraryParentCreateInput>>
  /** Delete multiple HubPageSectionContentLibraryParent documents */
  delete?: InputMaybe<Array<HubPageSectionContentLibraryParentWhereUniqueInput>>
  /** Disconnect multiple HubPageSectionContentLibraryParent documents */
  disconnect?: InputMaybe<Array<HubPageSectionContentLibraryParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HubPageSectionContentLibraryParent documents */
  set?: InputMaybe<Array<HubPageSectionContentLibraryParentWhereUniqueInput>>
  /** Update multiple HubPageSectionContentLibraryParent documents */
  update?: InputMaybe<Array<HubPageSectionContentLibraryParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HubPageSectionContentLibraryParent documents */
  upsert?: InputMaybe<Array<HubPageSectionContentLibraryParentUpsertWithNestedWhereUniqueInput>>
}

export type HubPageSectionContentLibraryParentUpdateManyWithNestedWhereInput = {
  HubPage?: InputMaybe<HubPageUpdateManyWithNestedWhereInput>
}

export type HubPageSectionContentLibraryParentUpdateOneInlineInput = {
  /** Connect existing HubPageSectionContentLibraryParent document */
  connect?: InputMaybe<HubPageSectionContentLibraryParentWhereUniqueInput>
  /** Create and connect one HubPageSectionContentLibraryParent document */
  create?: InputMaybe<HubPageSectionContentLibraryParentCreateInput>
  /** Delete currently connected HubPageSectionContentLibraryParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HubPageSectionContentLibraryParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionContentLibraryParent document */
  update?: InputMaybe<HubPageSectionContentLibraryParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionContentLibraryParent document */
  upsert?: InputMaybe<HubPageSectionContentLibraryParentUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionContentLibraryParentUpdateWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpdateWithNestedWhereUniqueInput>
}

export type HubPageSectionContentLibraryParentUpsertWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionContentLibraryParentWhereInput = {
  HubPage?: InputMaybe<HubPageWhereInput>
}

export type HubPageSectionContentLibraryParentWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageWhereUniqueInput>
}

export type HubPageSectionContentLibraryUpdateInput = {
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsUpdateOneInlineInput>
}

export type HubPageSectionContentLibraryUpdateManyInlineInput = {
  /** Create and connect multiple HubPageSectionContentLibrary component instances */
  create?: InputMaybe<Array<HubPageSectionContentLibraryCreateWithPositionInput>>
  /** Delete multiple HubPageSectionContentLibrary documents */
  delete?: InputMaybe<Array<HubPageSectionContentLibraryWhereUniqueInput>>
  /** Update multiple HubPageSectionContentLibrary component instances */
  update?: InputMaybe<Array<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPageSectionContentLibrary component instances */
  upsert?: InputMaybe<Array<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPageSectionContentLibraryUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionContentLibraryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HubPageSectionContentLibraryUpdateManyInput
  /** Document search */
  where: HubPageSectionContentLibraryWhereInput
}

export type HubPageSectionContentLibraryUpdateOneInlineInput = {
  /** Create and connect one HubPageSectionContentLibrary document */
  create?: InputMaybe<HubPageSectionContentLibraryCreateInput>
  /** Delete currently connected HubPageSectionContentLibrary document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionContentLibrary document */
  update?: InputMaybe<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionContentLibrary document */
  upsert?: InputMaybe<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionContentLibraryUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HubPageSectionContentLibraryUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionContentLibraryWhereUniqueInput
}

export type HubPageSectionContentLibraryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HubPageSectionContentLibraryUpdateInput
  /** Unique document search */
  where: HubPageSectionContentLibraryWhereUniqueInput
}

export type HubPageSectionContentLibraryUpsertInput = {
  /** Create document if it didn't exist */
  create: HubPageSectionContentLibraryCreateInput
  /** Update document if it exists */
  update: HubPageSectionContentLibraryUpdateInput
}

export type HubPageSectionContentLibraryUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HubPageSectionContentLibraryUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionContentLibraryWhereUniqueInput
}

export type HubPageSectionContentLibraryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HubPageSectionContentLibraryUpsertInput
  /** Unique document search */
  where: HubPageSectionContentLibraryWhereUniqueInput
}

/** Identifies documents */
export type HubPageSectionContentLibraryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionContentLibraryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

/** References HubPageSectionContentLibrary record uniquely */
export type HubPageSectionContentLibraryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Festival Events Section for Hub Pages */
export type HubPageSectionFestivalEvents = Entity & {
  __typename?: 'HubPageSectionFestivalEvents'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Common fields for all sections */
  sectionCommonFields: HubPageSectionCommonFields
  /** System stage field */
  stage: Stage
}

/** Festival Events Section for Hub Pages */
export type HubPageSectionFestivalEventsSectionCommonFieldsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HubPageSectionFestivalEventsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HubPageSectionFestivalEventsWhereUniqueInput
}

/** A connection to a list of items. */
export type HubPageSectionFestivalEventsConnection = {
  __typename?: 'HubPageSectionFestivalEventsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HubPageSectionFestivalEventsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HubPageSectionFestivalEventsCreateInput = {
  sectionCommonFields: HubPageSectionCommonFieldsCreateOneInlineInput
}

export type HubPageSectionFestivalEventsCreateManyInlineInput = {
  /** Create and connect multiple existing HubPageSectionFestivalEvents documents */
  create?: InputMaybe<Array<HubPageSectionFestivalEventsCreateInput>>
}

export type HubPageSectionFestivalEventsCreateOneInlineInput = {
  /** Create and connect one HubPageSectionFestivalEvents document */
  create?: InputMaybe<HubPageSectionFestivalEventsCreateInput>
}

export type HubPageSectionFestivalEventsCreateWithPositionInput = {
  /** Document to create */
  data: HubPageSectionFestivalEventsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HubPageSectionFestivalEventsEdge = {
  __typename?: 'HubPageSectionFestivalEventsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HubPageSectionFestivalEvents
}

/** Identifies documents */
export type HubPageSectionFestivalEventsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

export enum HubPageSectionFestivalEventsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type HubPageSectionFestivalEventsParent = HubPage

export type HubPageSectionFestivalEventsParentConnectInput = {
  HubPage?: InputMaybe<HubPageConnectInput>
}

export type HubPageSectionFestivalEventsParentCreateInput = {
  HubPage?: InputMaybe<HubPageCreateInput>
}

export type HubPageSectionFestivalEventsParentCreateManyInlineInput = {
  /** Connect multiple existing HubPageSectionFestivalEventsParent documents */
  connect?: InputMaybe<Array<HubPageSectionFestivalEventsParentWhereUniqueInput>>
  /** Create and connect multiple existing HubPageSectionFestivalEventsParent documents */
  create?: InputMaybe<Array<HubPageSectionFestivalEventsParentCreateInput>>
}

export type HubPageSectionFestivalEventsParentCreateOneInlineInput = {
  /** Connect one existing HubPageSectionFestivalEventsParent document */
  connect?: InputMaybe<HubPageSectionFestivalEventsParentWhereUniqueInput>
  /** Create and connect one HubPageSectionFestivalEventsParent document */
  create?: InputMaybe<HubPageSectionFestivalEventsParentCreateInput>
}

export type HubPageSectionFestivalEventsParentUpdateInput = {
  HubPage?: InputMaybe<HubPageUpdateInput>
}

export type HubPageSectionFestivalEventsParentUpdateManyInlineInput = {
  /** Connect multiple existing HubPageSectionFestivalEventsParent documents */
  connect?: InputMaybe<Array<HubPageSectionFestivalEventsParentConnectInput>>
  /** Create and connect multiple HubPageSectionFestivalEventsParent documents */
  create?: InputMaybe<Array<HubPageSectionFestivalEventsParentCreateInput>>
  /** Delete multiple HubPageSectionFestivalEventsParent documents */
  delete?: InputMaybe<Array<HubPageSectionFestivalEventsParentWhereUniqueInput>>
  /** Disconnect multiple HubPageSectionFestivalEventsParent documents */
  disconnect?: InputMaybe<Array<HubPageSectionFestivalEventsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HubPageSectionFestivalEventsParent documents */
  set?: InputMaybe<Array<HubPageSectionFestivalEventsParentWhereUniqueInput>>
  /** Update multiple HubPageSectionFestivalEventsParent documents */
  update?: InputMaybe<Array<HubPageSectionFestivalEventsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HubPageSectionFestivalEventsParent documents */
  upsert?: InputMaybe<Array<HubPageSectionFestivalEventsParentUpsertWithNestedWhereUniqueInput>>
}

export type HubPageSectionFestivalEventsParentUpdateManyWithNestedWhereInput = {
  HubPage?: InputMaybe<HubPageUpdateManyWithNestedWhereInput>
}

export type HubPageSectionFestivalEventsParentUpdateOneInlineInput = {
  /** Connect existing HubPageSectionFestivalEventsParent document */
  connect?: InputMaybe<HubPageSectionFestivalEventsParentWhereUniqueInput>
  /** Create and connect one HubPageSectionFestivalEventsParent document */
  create?: InputMaybe<HubPageSectionFestivalEventsParentCreateInput>
  /** Delete currently connected HubPageSectionFestivalEventsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HubPageSectionFestivalEventsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionFestivalEventsParent document */
  update?: InputMaybe<HubPageSectionFestivalEventsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionFestivalEventsParent document */
  upsert?: InputMaybe<HubPageSectionFestivalEventsParentUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionFestivalEventsParentUpdateWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpdateWithNestedWhereUniqueInput>
}

export type HubPageSectionFestivalEventsParentUpsertWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionFestivalEventsParentWhereInput = {
  HubPage?: InputMaybe<HubPageWhereInput>
}

export type HubPageSectionFestivalEventsParentWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageWhereUniqueInput>
}

export type HubPageSectionFestivalEventsUpdateInput = {
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsUpdateOneInlineInput>
}

export type HubPageSectionFestivalEventsUpdateManyInlineInput = {
  /** Create and connect multiple HubPageSectionFestivalEvents component instances */
  create?: InputMaybe<Array<HubPageSectionFestivalEventsCreateWithPositionInput>>
  /** Delete multiple HubPageSectionFestivalEvents documents */
  delete?: InputMaybe<Array<HubPageSectionFestivalEventsWhereUniqueInput>>
  /** Update multiple HubPageSectionFestivalEvents component instances */
  update?: InputMaybe<Array<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPageSectionFestivalEvents component instances */
  upsert?: InputMaybe<Array<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPageSectionFestivalEventsUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionFestivalEventsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HubPageSectionFestivalEventsUpdateManyInput
  /** Document search */
  where: HubPageSectionFestivalEventsWhereInput
}

export type HubPageSectionFestivalEventsUpdateOneInlineInput = {
  /** Create and connect one HubPageSectionFestivalEvents document */
  create?: InputMaybe<HubPageSectionFestivalEventsCreateInput>
  /** Delete currently connected HubPageSectionFestivalEvents document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionFestivalEvents document */
  update?: InputMaybe<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionFestivalEvents document */
  upsert?: InputMaybe<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HubPageSectionFestivalEventsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionFestivalEventsWhereUniqueInput
}

export type HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HubPageSectionFestivalEventsUpdateInput
  /** Unique document search */
  where: HubPageSectionFestivalEventsWhereUniqueInput
}

export type HubPageSectionFestivalEventsUpsertInput = {
  /** Create document if it didn't exist */
  create: HubPageSectionFestivalEventsCreateInput
  /** Update document if it exists */
  update: HubPageSectionFestivalEventsUpdateInput
}

export type HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HubPageSectionFestivalEventsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionFestivalEventsWhereUniqueInput
}

export type HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HubPageSectionFestivalEventsUpsertInput
  /** Unique document search */
  where: HubPageSectionFestivalEventsWhereUniqueInput
}

/** Identifies documents */
export type HubPageSectionFestivalEventsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionFestivalEventsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

/** References HubPageSectionFestivalEvents record uniquely */
export type HubPageSectionFestivalEventsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** News Section for Hub Pages */
export type HubPageSectionNews = Entity & {
  __typename?: 'HubPageSectionNews'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Common fields for all sections */
  sectionCommonFields: HubPageSectionCommonFields
  /** System stage field */
  stage: Stage
}

/** News Section for Hub Pages */
export type HubPageSectionNewsSectionCommonFieldsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type HubPageSectionNewsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: HubPageSectionNewsWhereUniqueInput
}

/** A connection to a list of items. */
export type HubPageSectionNewsConnection = {
  __typename?: 'HubPageSectionNewsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<HubPageSectionNewsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type HubPageSectionNewsCreateInput = {
  sectionCommonFields: HubPageSectionCommonFieldsCreateOneInlineInput
}

export type HubPageSectionNewsCreateManyInlineInput = {
  /** Create and connect multiple existing HubPageSectionNews documents */
  create?: InputMaybe<Array<HubPageSectionNewsCreateInput>>
}

export type HubPageSectionNewsCreateOneInlineInput = {
  /** Create and connect one HubPageSectionNews document */
  create?: InputMaybe<HubPageSectionNewsCreateInput>
}

export type HubPageSectionNewsCreateWithPositionInput = {
  /** Document to create */
  data: HubPageSectionNewsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type HubPageSectionNewsEdge = {
  __typename?: 'HubPageSectionNewsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: HubPageSectionNews
}

/** Identifies documents */
export type HubPageSectionNewsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

export enum HubPageSectionNewsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type HubPageSectionNewsParent = HubPage

export type HubPageSectionNewsParentConnectInput = {
  HubPage?: InputMaybe<HubPageConnectInput>
}

export type HubPageSectionNewsParentCreateInput = {
  HubPage?: InputMaybe<HubPageCreateInput>
}

export type HubPageSectionNewsParentCreateManyInlineInput = {
  /** Connect multiple existing HubPageSectionNewsParent documents */
  connect?: InputMaybe<Array<HubPageSectionNewsParentWhereUniqueInput>>
  /** Create and connect multiple existing HubPageSectionNewsParent documents */
  create?: InputMaybe<Array<HubPageSectionNewsParentCreateInput>>
}

export type HubPageSectionNewsParentCreateOneInlineInput = {
  /** Connect one existing HubPageSectionNewsParent document */
  connect?: InputMaybe<HubPageSectionNewsParentWhereUniqueInput>
  /** Create and connect one HubPageSectionNewsParent document */
  create?: InputMaybe<HubPageSectionNewsParentCreateInput>
}

export type HubPageSectionNewsParentUpdateInput = {
  HubPage?: InputMaybe<HubPageUpdateInput>
}

export type HubPageSectionNewsParentUpdateManyInlineInput = {
  /** Connect multiple existing HubPageSectionNewsParent documents */
  connect?: InputMaybe<Array<HubPageSectionNewsParentConnectInput>>
  /** Create and connect multiple HubPageSectionNewsParent documents */
  create?: InputMaybe<Array<HubPageSectionNewsParentCreateInput>>
  /** Delete multiple HubPageSectionNewsParent documents */
  delete?: InputMaybe<Array<HubPageSectionNewsParentWhereUniqueInput>>
  /** Disconnect multiple HubPageSectionNewsParent documents */
  disconnect?: InputMaybe<Array<HubPageSectionNewsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HubPageSectionNewsParent documents */
  set?: InputMaybe<Array<HubPageSectionNewsParentWhereUniqueInput>>
  /** Update multiple HubPageSectionNewsParent documents */
  update?: InputMaybe<Array<HubPageSectionNewsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HubPageSectionNewsParent documents */
  upsert?: InputMaybe<Array<HubPageSectionNewsParentUpsertWithNestedWhereUniqueInput>>
}

export type HubPageSectionNewsParentUpdateManyWithNestedWhereInput = {
  HubPage?: InputMaybe<HubPageUpdateManyWithNestedWhereInput>
}

export type HubPageSectionNewsParentUpdateOneInlineInput = {
  /** Connect existing HubPageSectionNewsParent document */
  connect?: InputMaybe<HubPageSectionNewsParentWhereUniqueInput>
  /** Create and connect one HubPageSectionNewsParent document */
  create?: InputMaybe<HubPageSectionNewsParentCreateInput>
  /** Delete currently connected HubPageSectionNewsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HubPageSectionNewsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionNewsParent document */
  update?: InputMaybe<HubPageSectionNewsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionNewsParent document */
  upsert?: InputMaybe<HubPageSectionNewsParentUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionNewsParentUpdateWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpdateWithNestedWhereUniqueInput>
}

export type HubPageSectionNewsParentUpsertWithNestedWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionNewsParentWhereInput = {
  HubPage?: InputMaybe<HubPageWhereInput>
}

export type HubPageSectionNewsParentWhereUniqueInput = {
  HubPage?: InputMaybe<HubPageWhereUniqueInput>
}

export type HubPageSectionNewsUpdateInput = {
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsUpdateOneInlineInput>
}

export type HubPageSectionNewsUpdateManyInlineInput = {
  /** Create and connect multiple HubPageSectionNews component instances */
  create?: InputMaybe<Array<HubPageSectionNewsCreateWithPositionInput>>
  /** Delete multiple HubPageSectionNews documents */
  delete?: InputMaybe<Array<HubPageSectionNewsWhereUniqueInput>>
  /** Update multiple HubPageSectionNews component instances */
  update?: InputMaybe<Array<HubPageSectionNewsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPageSectionNews component instances */
  upsert?: InputMaybe<Array<HubPageSectionNewsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPageSectionNewsUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type HubPageSectionNewsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HubPageSectionNewsUpdateManyInput
  /** Document search */
  where: HubPageSectionNewsWhereInput
}

export type HubPageSectionNewsUpdateOneInlineInput = {
  /** Create and connect one HubPageSectionNews document */
  create?: InputMaybe<HubPageSectionNewsCreateInput>
  /** Delete currently connected HubPageSectionNews document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPageSectionNews document */
  update?: InputMaybe<HubPageSectionNewsUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPageSectionNews document */
  upsert?: InputMaybe<HubPageSectionNewsUpsertWithNestedWhereUniqueInput>
}

export type HubPageSectionNewsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<HubPageSectionNewsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionNewsWhereUniqueInput
}

export type HubPageSectionNewsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HubPageSectionNewsUpdateInput
  /** Unique document search */
  where: HubPageSectionNewsWhereUniqueInput
}

export type HubPageSectionNewsUpsertInput = {
  /** Create document if it didn't exist */
  create: HubPageSectionNewsCreateInput
  /** Update document if it exists */
  update: HubPageSectionNewsUpdateInput
}

export type HubPageSectionNewsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<HubPageSectionNewsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: HubPageSectionNewsWhereUniqueInput
}

export type HubPageSectionNewsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HubPageSectionNewsUpsertInput
  /** Unique document search */
  where: HubPageSectionNewsWhereUniqueInput
}

/** Identifies documents */
export type HubPageSectionNewsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageSectionNewsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionCommonFields?: InputMaybe<HubPageSectionCommonFieldsWhereInput>
}

/** References HubPageSectionNews record uniquely */
export type HubPageSectionNewsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type HubPageUpdateInput = {
  identifier?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<HubPageUpdateLocalizationsInput>
  sections?: InputMaybe<HubPagesectionsUnionUpdateManyInlineInput>
}

export type HubPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<HubPageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
}

export type HubPageUpdateManyInlineInput = {
  /** Connect multiple existing HubPage documents */
  connect?: InputMaybe<Array<HubPageConnectInput>>
  /** Create and connect multiple HubPage documents */
  create?: InputMaybe<Array<HubPageCreateInput>>
  /** Delete multiple HubPage documents */
  delete?: InputMaybe<Array<HubPageWhereUniqueInput>>
  /** Disconnect multiple HubPage documents */
  disconnect?: InputMaybe<Array<HubPageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing HubPage documents */
  set?: InputMaybe<Array<HubPageWhereUniqueInput>>
  /** Update multiple HubPage documents */
  update?: InputMaybe<Array<HubPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple HubPage documents */
  upsert?: InputMaybe<Array<HubPageUpsertWithNestedWhereUniqueInput>>
}

export type HubPageUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type HubPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: HubPageUpdateManyInput
  /** Document search */
  where: HubPageWhereInput
}

export type HubPageUpdateOneInlineInput = {
  /** Connect existing HubPage document */
  connect?: InputMaybe<HubPageWhereUniqueInput>
  /** Create and connect one HubPage document */
  create?: InputMaybe<HubPageCreateInput>
  /** Delete currently connected HubPage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected HubPage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPage document */
  update?: InputMaybe<HubPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPage document */
  upsert?: InputMaybe<HubPageUpsertWithNestedWhereUniqueInput>
}

export type HubPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: HubPageUpdateInput
  /** Unique document search */
  where: HubPageWhereUniqueInput
}

export type HubPageUpsertInput = {
  /** Create document if it didn't exist */
  create: HubPageCreateInput
  /** Update document if it exists */
  update: HubPageUpdateInput
}

export type HubPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: HubPageUpsertInput
  /** Unique document search */
  where: HubPageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type HubPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type HubPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<HubPageWhereStageInput>
  documentInStages_none?: InputMaybe<HubPageWhereStageInput>
  documentInStages_some?: InputMaybe<HubPageWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  identifier_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  identifier_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  identifier_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  identifier_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  identifier_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  identifier_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  identifier_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  /** All values in which the union is empty. */
  sections_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  sections_some?: InputMaybe<HubPagesectionsUnionWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type HubPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<HubPageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<HubPageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<HubPageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<HubPageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References HubPage record uniquely */
export type HubPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
}

export type HubPagesectionsUnion = HubPageSectionContentLibrary | HubPageSectionFestivalEvents | HubPageSectionNews

export type HubPagesectionsUnionConnectInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryConnectInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsConnectInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsConnectInput>
}

export type HubPagesectionsUnionCreateInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryCreateInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsCreateInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsCreateInput>
}

export type HubPagesectionsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing HubPagesectionsUnion documents */
  create?: InputMaybe<Array<HubPagesectionsUnionCreateInput>>
}

export type HubPagesectionsUnionCreateOneInlineInput = {
  /** Create and connect one HubPagesectionsUnion document */
  create?: InputMaybe<HubPagesectionsUnionCreateInput>
}

export type HubPagesectionsUnionCreateWithPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryCreateWithPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsCreateWithPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsCreateWithPositionInput>
}

export type HubPagesectionsUnionUpdateInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateInput>
}

export type HubPagesectionsUnionUpdateManyInlineInput = {
  /** Create and connect multiple HubPagesectionsUnion component instances */
  create?: InputMaybe<Array<HubPagesectionsUnionCreateWithPositionInput>>
  /** Delete multiple HubPagesectionsUnion documents */
  delete?: InputMaybe<Array<HubPagesectionsUnionWhereUniqueInput>>
  /** Update multiple HubPagesectionsUnion component instances */
  update?: InputMaybe<Array<HubPagesectionsUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple HubPagesectionsUnion component instances */
  upsert?: InputMaybe<Array<HubPagesectionsUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type HubPagesectionsUnionUpdateManyWithNestedWhereInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateManyWithNestedWhereInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateManyWithNestedWhereInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateManyWithNestedWhereInput>
}

export type HubPagesectionsUnionUpdateOneInlineInput = {
  /** Create and connect one HubPagesectionsUnion document */
  create?: InputMaybe<HubPagesectionsUnionCreateInput>
  /** Delete currently connected HubPagesectionsUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single HubPagesectionsUnion document */
  update?: InputMaybe<HubPagesectionsUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single HubPagesectionsUnion document */
  upsert?: InputMaybe<HubPagesectionsUnionUpsertWithNestedWhereUniqueInput>
}

export type HubPagesectionsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueAndPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueAndPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateWithNestedWhereUniqueAndPositionInput>
}

export type HubPagesectionsUnionUpdateWithNestedWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpdateWithNestedWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpdateWithNestedWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpdateWithNestedWhereUniqueInput>
}

export type HubPagesectionsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueAndPositionInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueAndPositionInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpsertWithNestedWhereUniqueAndPositionInput>
}

export type HubPagesectionsUnionUpsertWithNestedWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryUpsertWithNestedWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsUpsertWithNestedWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsUpsertWithNestedWhereUniqueInput>
}

export type HubPagesectionsUnionWhereInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryWhereInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsWhereInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsWhereInput>
}

export type HubPagesectionsUnionWhereUniqueInput = {
  HubPageSectionContentLibrary?: InputMaybe<HubPageSectionContentLibraryWhereUniqueInput>
  HubPageSectionFestivalEvents?: InputMaybe<HubPageSectionFestivalEventsWhereUniqueInput>
  HubPageSectionNews?: InputMaybe<HubPageSectionNewsWhereUniqueInput>
}

export type HygraphSearchResult = {
  __typename?: 'HygraphSearchResult'
  id: Scalars['ID']['output']
  locale: Locale
  matchContext: HygraphSearchResultMatchContext
  model: HygraphSearchResultModel
  resultId: Scalars['String']['output']
  stage: Stage
  titleFields: Array<HygraphSearchResultTitleField>
}

export type HygraphSearchResultMatchContext = {
  __typename?: 'HygraphSearchResultMatchContext'
  highlight: Scalars['String']['output']
}

export type HygraphSearchResultModel = {
  __typename?: 'HygraphSearchResultModel'
  apiId: Scalars['String']['output']
}

export type HygraphSearchResultTitleField = {
  __typename?: 'HygraphSearchResultTitleField'
  apiId: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type HygraphSearchWhereInput = {
  term: Scalars['String']['input']
}

export enum IconEnum {
  MdiAccountMultipleOutline = 'mdiAccountMultipleOutline',
  MdiChat = 'mdiChat',
  MdiHomeCityOutline = 'mdiHomeCityOutline',
  MdiMapMarkerOutline = 'mdiMapMarkerOutline',
  MdiStarOutline = 'mdiStarOutline',
  MdiTarget = 'mdiTarget',
}

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbed = Entity &
  Node & {
    __typename?: 'ImageGalleryEmbed'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<ImageGalleryEmbed>
    /** List of ImageGalleryEmbed versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Images to be displayed in the gallery */
    images: Array<Asset>
    /** Name of the gallery to distinguish it from other galleries */
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AssetWhereInput>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Image gallery to be embedded in the rich text editor */
export type ImageGalleryEmbedUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ImageGalleryEmbedConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ImageGalleryEmbedWhereUniqueInput
}

/** A connection to a list of items. */
export type ImageGalleryEmbedConnection = {
  __typename?: 'ImageGalleryEmbedConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ImageGalleryEmbedEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ImageGalleryEmbedCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  images: AssetCreateManyInlineInput
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ImageGalleryEmbedCreateManyInlineInput = {
  /** Connect multiple existing ImageGalleryEmbed documents */
  connect?: InputMaybe<Array<ImageGalleryEmbedWhereUniqueInput>>
  /** Create and connect multiple existing ImageGalleryEmbed documents */
  create?: InputMaybe<Array<ImageGalleryEmbedCreateInput>>
}

export type ImageGalleryEmbedCreateOneInlineInput = {
  /** Connect one existing ImageGalleryEmbed document */
  connect?: InputMaybe<ImageGalleryEmbedWhereUniqueInput>
  /** Create and connect one ImageGalleryEmbed document */
  create?: InputMaybe<ImageGalleryEmbedCreateInput>
}

/** An edge in a connection. */
export type ImageGalleryEmbedEdge = {
  __typename?: 'ImageGalleryEmbedEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ImageGalleryEmbed
}

/** Identifies documents */
export type ImageGalleryEmbedManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  images_every?: InputMaybe<AssetWhereInput>
  images_none?: InputMaybe<AssetWhereInput>
  images_some?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ImageGalleryEmbedOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ImageGalleryEmbedUpdateInput = {
  images?: InputMaybe<AssetUpdateManyInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ImageGalleryEmbedUpdateManyInlineInput = {
  /** Connect multiple existing ImageGalleryEmbed documents */
  connect?: InputMaybe<Array<ImageGalleryEmbedConnectInput>>
  /** Create and connect multiple ImageGalleryEmbed documents */
  create?: InputMaybe<Array<ImageGalleryEmbedCreateInput>>
  /** Delete multiple ImageGalleryEmbed documents */
  delete?: InputMaybe<Array<ImageGalleryEmbedWhereUniqueInput>>
  /** Disconnect multiple ImageGalleryEmbed documents */
  disconnect?: InputMaybe<Array<ImageGalleryEmbedWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ImageGalleryEmbed documents */
  set?: InputMaybe<Array<ImageGalleryEmbedWhereUniqueInput>>
  /** Update multiple ImageGalleryEmbed documents */
  update?: InputMaybe<Array<ImageGalleryEmbedUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ImageGalleryEmbed documents */
  upsert?: InputMaybe<Array<ImageGalleryEmbedUpsertWithNestedWhereUniqueInput>>
}

export type ImageGalleryEmbedUpdateManyInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type ImageGalleryEmbedUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageGalleryEmbedUpdateManyInput
  /** Document search */
  where: ImageGalleryEmbedWhereInput
}

export type ImageGalleryEmbedUpdateOneInlineInput = {
  /** Connect existing ImageGalleryEmbed document */
  connect?: InputMaybe<ImageGalleryEmbedWhereUniqueInput>
  /** Create and connect one ImageGalleryEmbed document */
  create?: InputMaybe<ImageGalleryEmbedCreateInput>
  /** Delete currently connected ImageGalleryEmbed document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ImageGalleryEmbed document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ImageGalleryEmbed document */
  update?: InputMaybe<ImageGalleryEmbedUpdateWithNestedWhereUniqueInput>
  /** Upsert single ImageGalleryEmbed document */
  upsert?: InputMaybe<ImageGalleryEmbedUpsertWithNestedWhereUniqueInput>
}

export type ImageGalleryEmbedUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageGalleryEmbedUpdateInput
  /** Unique document search */
  where: ImageGalleryEmbedWhereUniqueInput
}

export type ImageGalleryEmbedUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageGalleryEmbedCreateInput
  /** Update document if it exists */
  update: ImageGalleryEmbedUpdateInput
}

export type ImageGalleryEmbedUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageGalleryEmbedUpsertInput
  /** Unique document search */
  where: ImageGalleryEmbedWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ImageGalleryEmbedWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ImageGalleryEmbedWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageGalleryEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<ImageGalleryEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  images_every?: InputMaybe<AssetWhereInput>
  images_none?: InputMaybe<AssetWhereInput>
  images_some?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ImageGalleryEmbedWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageGalleryEmbedWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageGalleryEmbedWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageGalleryEmbedWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ImageGalleryEmbedWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ImageGalleryEmbed record uniquely */
export type ImageGalleryEmbedWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars['Int']['input']>
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars['Int']['input']>
}

export type ImageSlider = Entity & {
  __typename?: 'ImageSlider'
  /** Defines a decoration pattern to use with the component is is used for. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  fullScreenWidth?: Maybe<Scalars['Boolean']['output']>
  fullWidth?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<ImageSlider>
  slide: Array<Slide>
  sliderLayout?: Maybe<SliderLayout>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

export type ImageSliderLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ImageSliderSlideArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SlideOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SlideWhereInput>
}

export type ImageSliderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ImageSliderWhereUniqueInput
}

/** A connection to a list of items. */
export type ImageSliderConnection = {
  __typename?: 'ImageSliderConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ImageSliderEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ImageSliderCreateInput = {
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  fullScreenWidth?: InputMaybe<Scalars['Boolean']['input']>
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ImageSliderCreateLocalizationsInput>
  slide: SlideCreateManyInlineInput
  sliderLayout?: InputMaybe<SliderLayout>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderCreateLocalizationInput = {
  /** Localization input */
  data: ImageSliderCreateLocalizationDataInput
  locale: Locale
}

export type ImageSliderCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ImageSliderCreateLocalizationInput>>
}

export type ImageSliderCreateManyInlineInput = {
  /** Create and connect multiple existing ImageSlider documents */
  create?: InputMaybe<Array<ImageSliderCreateInput>>
}

export type ImageSliderCreateOneInlineInput = {
  /** Create and connect one ImageSlider document */
  create?: InputMaybe<ImageSliderCreateInput>
}

export type ImageSliderCreateWithPositionInput = {
  /** Document to create */
  data: ImageSliderCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ImageSliderEdge = {
  __typename?: 'ImageSliderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ImageSlider
}

/** Identifies documents */
export type ImageSliderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  fullScreenWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  fullScreenWidth_not?: InputMaybe<Scalars['Boolean']['input']>
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  fullWidth_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  slide_every?: InputMaybe<SlideWhereInput>
  slide_none?: InputMaybe<SlideWhereInput>
  slide_some?: InputMaybe<SlideWhereInput>
  sliderLayout?: InputMaybe<SliderLayout>
  /** All values that are contained in given list. */
  sliderLayout_in?: InputMaybe<Array<InputMaybe<SliderLayout>>>
  /** Any other value that exists and is not equal to the given value. */
  sliderLayout_not?: InputMaybe<SliderLayout>
  /** All values that are not contained in given list. */
  sliderLayout_not_in?: InputMaybe<Array<InputMaybe<SliderLayout>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum ImageSliderOrderByInput {
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FullScreenWidthAsc = 'fullScreenWidth_ASC',
  FullScreenWidthDesc = 'fullScreenWidth_DESC',
  FullWidthAsc = 'fullWidth_ASC',
  FullWidthDesc = 'fullWidth_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SliderLayoutAsc = 'sliderLayout_ASC',
  SliderLayoutDesc = 'sliderLayout_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ImageSliderParent = EventPage

export type ImageSliderParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type ImageSliderParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type ImageSliderParentCreateManyInlineInput = {
  /** Connect multiple existing ImageSliderParent documents */
  connect?: InputMaybe<Array<ImageSliderParentWhereUniqueInput>>
  /** Create and connect multiple existing ImageSliderParent documents */
  create?: InputMaybe<Array<ImageSliderParentCreateInput>>
}

export type ImageSliderParentCreateOneInlineInput = {
  /** Connect one existing ImageSliderParent document */
  connect?: InputMaybe<ImageSliderParentWhereUniqueInput>
  /** Create and connect one ImageSliderParent document */
  create?: InputMaybe<ImageSliderParentCreateInput>
}

export type ImageSliderParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type ImageSliderParentUpdateManyInlineInput = {
  /** Connect multiple existing ImageSliderParent documents */
  connect?: InputMaybe<Array<ImageSliderParentConnectInput>>
  /** Create and connect multiple ImageSliderParent documents */
  create?: InputMaybe<Array<ImageSliderParentCreateInput>>
  /** Delete multiple ImageSliderParent documents */
  delete?: InputMaybe<Array<ImageSliderParentWhereUniqueInput>>
  /** Disconnect multiple ImageSliderParent documents */
  disconnect?: InputMaybe<Array<ImageSliderParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ImageSliderParent documents */
  set?: InputMaybe<Array<ImageSliderParentWhereUniqueInput>>
  /** Update multiple ImageSliderParent documents */
  update?: InputMaybe<Array<ImageSliderParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ImageSliderParent documents */
  upsert?: InputMaybe<Array<ImageSliderParentUpsertWithNestedWhereUniqueInput>>
}

export type ImageSliderParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type ImageSliderParentUpdateOneInlineInput = {
  /** Connect existing ImageSliderParent document */
  connect?: InputMaybe<ImageSliderParentWhereUniqueInput>
  /** Create and connect one ImageSliderParent document */
  create?: InputMaybe<ImageSliderParentCreateInput>
  /** Delete currently connected ImageSliderParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ImageSliderParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ImageSliderParent document */
  update?: InputMaybe<ImageSliderParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ImageSliderParent document */
  upsert?: InputMaybe<ImageSliderParentUpsertWithNestedWhereUniqueInput>
}

export type ImageSliderParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type ImageSliderParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type ImageSliderParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type ImageSliderParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type ImageSliderUpdateInput = {
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  fullScreenWidth?: InputMaybe<Scalars['Boolean']['input']>
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ImageSliderUpdateLocalizationsInput>
  slide?: InputMaybe<SlideUpdateManyInlineInput>
  sliderLayout?: InputMaybe<SliderLayout>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderUpdateLocalizationInput = {
  data: ImageSliderUpdateLocalizationDataInput
  locale: Locale
}

export type ImageSliderUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ImageSliderCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ImageSliderUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ImageSliderUpsertLocalizationInput>>
}

export type ImageSliderUpdateManyInlineInput = {
  /** Create and connect multiple ImageSlider component instances */
  create?: InputMaybe<Array<ImageSliderCreateWithPositionInput>>
  /** Delete multiple ImageSlider documents */
  delete?: InputMaybe<Array<ImageSliderWhereUniqueInput>>
  /** Update multiple ImageSlider component instances */
  update?: InputMaybe<Array<ImageSliderUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ImageSlider component instances */
  upsert?: InputMaybe<Array<ImageSliderUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ImageSliderUpdateManyInput = {
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  fullScreenWidth?: InputMaybe<Scalars['Boolean']['input']>
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ImageSliderUpdateManyLocalizationsInput>
  sliderLayout?: InputMaybe<SliderLayout>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ImageSliderUpdateManyLocalizationInput = {
  data: ImageSliderUpdateManyLocalizationDataInput
  locale: Locale
}

export type ImageSliderUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ImageSliderUpdateManyLocalizationInput>>
}

export type ImageSliderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageSliderUpdateManyInput
  /** Document search */
  where: ImageSliderWhereInput
}

export type ImageSliderUpdateOneInlineInput = {
  /** Create and connect one ImageSlider document */
  create?: InputMaybe<ImageSliderCreateInput>
  /** Delete currently connected ImageSlider document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ImageSlider document */
  update?: InputMaybe<ImageSliderUpdateWithNestedWhereUniqueInput>
  /** Upsert single ImageSlider document */
  upsert?: InputMaybe<ImageSliderUpsertWithNestedWhereUniqueInput>
}

export type ImageSliderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ImageSliderUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ImageSliderWhereUniqueInput
}

export type ImageSliderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageSliderUpdateInput
  /** Unique document search */
  where: ImageSliderWhereUniqueInput
}

export type ImageSliderUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageSliderCreateInput
  /** Update document if it exists */
  update: ImageSliderUpdateInput
}

export type ImageSliderUpsertLocalizationInput = {
  create: ImageSliderCreateLocalizationDataInput
  locale: Locale
  update: ImageSliderUpdateLocalizationDataInput
}

export type ImageSliderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ImageSliderUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ImageSliderWhereUniqueInput
}

export type ImageSliderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageSliderUpsertInput
  /** Unique document search */
  where: ImageSliderWhereUniqueInput
}

/** Identifies documents */
export type ImageSliderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageSliderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  fullScreenWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  fullScreenWidth_not?: InputMaybe<Scalars['Boolean']['input']>
  fullWidth?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  fullWidth_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  slide_every?: InputMaybe<SlideWhereInput>
  slide_none?: InputMaybe<SlideWhereInput>
  slide_some?: InputMaybe<SlideWhereInput>
  sliderLayout?: InputMaybe<SliderLayout>
  /** All values that are contained in given list. */
  sliderLayout_in?: InputMaybe<Array<InputMaybe<SliderLayout>>>
  /** Any other value that exists and is not equal to the given value. */
  sliderLayout_not?: InputMaybe<SliderLayout>
  /** All values that are not contained in given list. */
  sliderLayout_not_in?: InputMaybe<Array<InputMaybe<SliderLayout>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References ImageSlider record uniquely */
export type ImageSliderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>
}

export type Interest = Entity &
  Node & {
    __typename?: 'Interest'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Interest>
    /** List of Interest versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    partnerCompanies: Array<PartnerCompany>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    speakers: Array<Speaker>
    /** System stage field */
    stage: Stage
    /** Name */
    text: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type InterestCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type InterestDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type InterestHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type InterestPartnerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

export type InterestPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type InterestScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type InterestSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

export type InterestUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type InterestConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: InterestWhereUniqueInput
}

/** A connection to a list of items. */
export type InterestConnection = {
  __typename?: 'InterestConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<InterestEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type InterestCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  partnerCompanies?: InputMaybe<PartnerCompanyCreateManyInlineInput>
  slug: Scalars['String']['input']
  speakers?: InputMaybe<SpeakerCreateManyInlineInput>
  text: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type InterestCreateManyInlineInput = {
  /** Connect multiple existing Interest documents */
  connect?: InputMaybe<Array<InterestWhereUniqueInput>>
  /** Create and connect multiple existing Interest documents */
  create?: InputMaybe<Array<InterestCreateInput>>
}

export type InterestCreateOneInlineInput = {
  /** Connect one existing Interest document */
  connect?: InputMaybe<InterestWhereUniqueInput>
  /** Create and connect one Interest document */
  create?: InputMaybe<InterestCreateInput>
}

/** An edge in a connection. */
export type InterestEdge = {
  __typename?: 'InterestEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Interest
}

/** Identifies documents */
export type InterestManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InterestWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InterestWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InterestWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<InterestWhereStageInput>
  documentInStages_none?: InputMaybe<InterestWhereStageInput>
  documentInStages_some?: InputMaybe<InterestWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partnerCompanies_every?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanies_none?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanies_some?: InputMaybe<PartnerCompanyWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  text?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum InterestOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type InterestUpdateInput = {
  partnerCompanies?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  speakers?: InputMaybe<SpeakerUpdateManyInlineInput>
  text?: InputMaybe<Scalars['String']['input']>
}

export type InterestUpdateManyInlineInput = {
  /** Connect multiple existing Interest documents */
  connect?: InputMaybe<Array<InterestConnectInput>>
  /** Create and connect multiple Interest documents */
  create?: InputMaybe<Array<InterestCreateInput>>
  /** Delete multiple Interest documents */
  delete?: InputMaybe<Array<InterestWhereUniqueInput>>
  /** Disconnect multiple Interest documents */
  disconnect?: InputMaybe<Array<InterestWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Interest documents */
  set?: InputMaybe<Array<InterestWhereUniqueInput>>
  /** Update multiple Interest documents */
  update?: InputMaybe<Array<InterestUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Interest documents */
  upsert?: InputMaybe<Array<InterestUpsertWithNestedWhereUniqueInput>>
}

export type InterestUpdateManyInput = {
  text?: InputMaybe<Scalars['String']['input']>
}

export type InterestUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InterestUpdateManyInput
  /** Document search */
  where: InterestWhereInput
}

export type InterestUpdateOneInlineInput = {
  /** Connect existing Interest document */
  connect?: InputMaybe<InterestWhereUniqueInput>
  /** Create and connect one Interest document */
  create?: InputMaybe<InterestCreateInput>
  /** Delete currently connected Interest document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Interest document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Interest document */
  update?: InputMaybe<InterestUpdateWithNestedWhereUniqueInput>
  /** Upsert single Interest document */
  upsert?: InputMaybe<InterestUpsertWithNestedWhereUniqueInput>
}

export type InterestUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InterestUpdateInput
  /** Unique document search */
  where: InterestWhereUniqueInput
}

export type InterestUpsertInput = {
  /** Create document if it didn't exist */
  create: InterestCreateInput
  /** Update document if it exists */
  update: InterestUpdateInput
}

export type InterestUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InterestUpsertInput
  /** Unique document search */
  where: InterestWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type InterestWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type InterestWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InterestWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InterestWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InterestWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<InterestWhereStageInput>
  documentInStages_none?: InputMaybe<InterestWhereStageInput>
  documentInStages_some?: InputMaybe<InterestWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partnerCompanies_every?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanies_none?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanies_some?: InputMaybe<PartnerCompanyWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  text?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type InterestWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InterestWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InterestWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InterestWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<InterestWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Interest record uniquely */
export type InterestWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type JobListing = Entity &
  Node & {
    __typename?: 'JobListing'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<JobListing>
    /** List of JobListing versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    jobCta?: Maybe<CallToAction>
    jobImage?: Maybe<Asset>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<JobListing>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    styleVariant?: Maybe<ComponentStyle>
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type JobListingCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type JobListingCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type JobListingDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type JobListingHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type JobListingJobCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type JobListingJobImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type JobListingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type JobListingPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type JobListingPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type JobListingScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type JobListingUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type JobListingUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type JobListingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: JobListingWhereUniqueInput
}

/** A connection to a list of items. */
export type JobListingConnection = {
  __typename?: 'JobListingConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<JobListingEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type JobListingCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  jobCta?: InputMaybe<CallToActionCreateOneInlineInput>
  jobImage?: InputMaybe<AssetCreateOneInlineInput>
  jobsInList?: InputMaybe<SectionJobListCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<JobListingCreateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type JobListingCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type JobListingCreateLocalizationInput = {
  /** Localization input */
  data: JobListingCreateLocalizationDataInput
  locale: Locale
}

export type JobListingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<JobListingCreateLocalizationInput>>
}

export type JobListingCreateManyInlineInput = {
  /** Connect multiple existing JobListing documents */
  connect?: InputMaybe<Array<JobListingWhereUniqueInput>>
  /** Create and connect multiple existing JobListing documents */
  create?: InputMaybe<Array<JobListingCreateInput>>
}

export type JobListingCreateOneInlineInput = {
  /** Connect one existing JobListing document */
  connect?: InputMaybe<JobListingWhereUniqueInput>
  /** Create and connect one JobListing document */
  create?: InputMaybe<JobListingCreateInput>
}

/** An edge in a connection. */
export type JobListingEdge = {
  __typename?: 'JobListingEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: JobListing
}

/** Identifies documents */
export type JobListingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobListingWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobListingWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobListingWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<JobListingWhereStageInput>
  documentInStages_none?: InputMaybe<JobListingWhereStageInput>
  documentInStages_some?: InputMaybe<JobListingWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobCta?: InputMaybe<CallToActionWhereInput>
  jobImage?: InputMaybe<AssetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum JobListingOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type JobListingUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  jobCta?: InputMaybe<CallToActionUpdateOneInlineInput>
  jobImage?: InputMaybe<AssetUpdateOneInlineInput>
  jobsInList?: InputMaybe<SectionJobListUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<JobListingUpdateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type JobListingUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type JobListingUpdateLocalizationInput = {
  data: JobListingUpdateLocalizationDataInput
  locale: Locale
}

export type JobListingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<JobListingCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<JobListingUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<JobListingUpsertLocalizationInput>>
}

export type JobListingUpdateManyInlineInput = {
  /** Connect multiple existing JobListing documents */
  connect?: InputMaybe<Array<JobListingConnectInput>>
  /** Create and connect multiple JobListing documents */
  create?: InputMaybe<Array<JobListingCreateInput>>
  /** Delete multiple JobListing documents */
  delete?: InputMaybe<Array<JobListingWhereUniqueInput>>
  /** Disconnect multiple JobListing documents */
  disconnect?: InputMaybe<Array<JobListingWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing JobListing documents */
  set?: InputMaybe<Array<JobListingWhereUniqueInput>>
  /** Update multiple JobListing documents */
  update?: InputMaybe<Array<JobListingUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple JobListing documents */
  upsert?: InputMaybe<Array<JobListingUpsertWithNestedWhereUniqueInput>>
}

export type JobListingUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<JobListingUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type JobListingUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type JobListingUpdateManyLocalizationInput = {
  data: JobListingUpdateManyLocalizationDataInput
  locale: Locale
}

export type JobListingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<JobListingUpdateManyLocalizationInput>>
}

export type JobListingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: JobListingUpdateManyInput
  /** Document search */
  where: JobListingWhereInput
}

export type JobListingUpdateOneInlineInput = {
  /** Connect existing JobListing document */
  connect?: InputMaybe<JobListingWhereUniqueInput>
  /** Create and connect one JobListing document */
  create?: InputMaybe<JobListingCreateInput>
  /** Delete currently connected JobListing document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected JobListing document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single JobListing document */
  update?: InputMaybe<JobListingUpdateWithNestedWhereUniqueInput>
  /** Upsert single JobListing document */
  upsert?: InputMaybe<JobListingUpsertWithNestedWhereUniqueInput>
}

export type JobListingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: JobListingUpdateInput
  /** Unique document search */
  where: JobListingWhereUniqueInput
}

export type JobListingUpsertInput = {
  /** Create document if it didn't exist */
  create: JobListingCreateInput
  /** Update document if it exists */
  update: JobListingUpdateInput
}

export type JobListingUpsertLocalizationInput = {
  create: JobListingCreateLocalizationDataInput
  locale: Locale
  update: JobListingUpdateLocalizationDataInput
}

export type JobListingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: JobListingUpsertInput
  /** Unique document search */
  where: JobListingWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type JobListingWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type JobListingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobListingWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobListingWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobListingWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<JobListingWhereStageInput>
  documentInStages_none?: InputMaybe<JobListingWhereStageInput>
  documentInStages_some?: InputMaybe<JobListingWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobCta?: InputMaybe<CallToActionWhereInput>
  jobImage?: InputMaybe<AssetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type JobListingWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobListingWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobListingWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobListingWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<JobListingWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References JobListing record uniquely */
export type JobListingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type JobsCard = Entity & {
  __typename?: 'JobsCard'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

export type JobsCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: JobsCardWhereUniqueInput
}

/** A connection to a list of items. */
export type JobsCardConnection = {
  __typename?: 'JobsCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<JobsCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type JobsCardCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type JobsCardCreateManyInlineInput = {
  /** Create and connect multiple existing JobsCard documents */
  create?: InputMaybe<Array<JobsCardCreateInput>>
}

export type JobsCardCreateOneInlineInput = {
  /** Create and connect one JobsCard document */
  create?: InputMaybe<JobsCardCreateInput>
}

export type JobsCardCreateWithPositionInput = {
  /** Document to create */
  data: JobsCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type JobsCardEdge = {
  __typename?: 'JobsCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: JobsCard
}

/** Identifies documents */
export type JobsCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobsCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobsCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobsCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum JobsCardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type JobsCardParent = SectionCardGroup

export type JobsCardParentConnectInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupConnectInput>
}

export type JobsCardParentCreateInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupCreateInput>
}

export type JobsCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing JobsCardParent documents */
  create?: InputMaybe<Array<JobsCardParentCreateInput>>
}

export type JobsCardParentCreateOneInlineInput = {
  /** Create and connect one JobsCardParent document */
  create?: InputMaybe<JobsCardParentCreateInput>
}

export type JobsCardParentCreateWithPositionInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupCreateWithPositionInput>
}

export type JobsCardParentUpdateInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateInput>
}

export type JobsCardParentUpdateManyInlineInput = {
  /** Create and connect multiple JobsCardParent component instances */
  create?: InputMaybe<Array<JobsCardParentCreateWithPositionInput>>
  /** Delete multiple JobsCardParent documents */
  delete?: InputMaybe<Array<JobsCardParentWhereUniqueInput>>
  /** Update multiple JobsCardParent component instances */
  update?: InputMaybe<Array<JobsCardParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple JobsCardParent component instances */
  upsert?: InputMaybe<Array<JobsCardParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type JobsCardParentUpdateManyWithNestedWhereInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateManyWithNestedWhereInput>
}

export type JobsCardParentUpdateOneInlineInput = {
  /** Create and connect one JobsCardParent document */
  create?: InputMaybe<JobsCardParentCreateInput>
  /** Delete currently connected JobsCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single JobsCardParent document */
  update?: InputMaybe<JobsCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single JobsCardParent document */
  upsert?: InputMaybe<JobsCardParentUpsertWithNestedWhereUniqueInput>
}

export type JobsCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateWithNestedWhereUniqueAndPositionInput>
}

export type JobsCardParentUpdateWithNestedWhereUniqueInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateWithNestedWhereUniqueInput>
}

export type JobsCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpsertWithNestedWhereUniqueAndPositionInput>
}

export type JobsCardParentUpsertWithNestedWhereUniqueInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupUpsertWithNestedWhereUniqueInput>
}

export type JobsCardParentWhereInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupWhereInput>
}

export type JobsCardParentWhereUniqueInput = {
  SectionCardGroup?: InputMaybe<SectionCardGroupWhereUniqueInput>
}

export type JobsCardUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type JobsCardUpdateManyInlineInput = {
  /** Create and connect multiple JobsCard component instances */
  create?: InputMaybe<Array<JobsCardCreateWithPositionInput>>
  /** Delete multiple JobsCard documents */
  delete?: InputMaybe<Array<JobsCardWhereUniqueInput>>
  /** Update multiple JobsCard component instances */
  update?: InputMaybe<Array<JobsCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple JobsCard component instances */
  upsert?: InputMaybe<Array<JobsCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type JobsCardUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type JobsCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: JobsCardUpdateManyInput
  /** Document search */
  where: JobsCardWhereInput
}

export type JobsCardUpdateOneInlineInput = {
  /** Create and connect one JobsCard document */
  create?: InputMaybe<JobsCardCreateInput>
  /** Delete currently connected JobsCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single JobsCard document */
  update?: InputMaybe<JobsCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single JobsCard document */
  upsert?: InputMaybe<JobsCardUpsertWithNestedWhereUniqueInput>
}

export type JobsCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<JobsCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: JobsCardWhereUniqueInput
}

export type JobsCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: JobsCardUpdateInput
  /** Unique document search */
  where: JobsCardWhereUniqueInput
}

export type JobsCardUpsertInput = {
  /** Create document if it didn't exist */
  create: JobsCardCreateInput
  /** Update document if it exists */
  update: JobsCardUpdateInput
}

export type JobsCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<JobsCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: JobsCardWhereUniqueInput
}

export type JobsCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: JobsCardUpsertInput
  /** Unique document search */
  where: JobsCardWhereUniqueInput
}

/** Identifies documents */
export type JobsCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobsCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobsCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobsCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References JobsCard record uniquely */
export type JobsCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Configuration for static landing page */
export type LandingPage = Entity &
  Node & {
    __typename?: 'LandingPage'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<LandingPage>
    /** List of LandingPage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<LandingPage>
    /** Ordered list of landing page components */
    pageLayout: Array<LandingPagepageLayoutUnion>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration to specify page meta tags */
    seoAttributes?: Maybe<Seo>
    /** Optional configuration if you want to have specific SEO Content Attributes. */
    seoContentAttributes?: Maybe<SeoContentAttribute>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** Landing page title */
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Configuration for static landing page */
export type LandingPageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Configuration for static landing page */
export type LandingPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Configuration for static landing page */
export type LandingPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Configuration for static landing page */
export type LandingPageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Configuration for static landing page */
export type LandingPageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Configuration for static landing page */
export type LandingPagePageLayoutArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

/** Configuration for static landing page */
export type LandingPagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Configuration for static landing page */
export type LandingPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Configuration for static landing page */
export type LandingPageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Configuration for static landing page */
export type LandingPageSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Configuration for static landing page */
export type LandingPageSeoContentAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Configuration for static landing page */
export type LandingPageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Configuration for static landing page */
export type LandingPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type LandingPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: LandingPageWhereUniqueInput
}

/** A connection to a list of items. */
export type LandingPageConnection = {
  __typename?: 'LandingPageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<LandingPageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type LandingPageCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LandingPageCreateLocalizationsInput>
  pageLayout?: InputMaybe<LandingPagepageLayoutUnionCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeCreateOneInlineInput>
  slug: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type LandingPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type LandingPageCreateLocalizationInput = {
  /** Localization input */
  data: LandingPageCreateLocalizationDataInput
  locale: Locale
}

export type LandingPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LandingPageCreateLocalizationInput>>
}

export type LandingPageCreateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageWhereUniqueInput>>
  /** Create and connect multiple existing LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>
}

export type LandingPageCreateOneInlineInput = {
  /** Connect one existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>
}

/** An edge in a connection. */
export type LandingPageEdge = {
  __typename?: 'LandingPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: LandingPage
}

/** Identifies documents */
export type LandingPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  pageLayout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageLayout_some?: InputMaybe<LandingPagepageLayoutUnionWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum LandingPageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type LandingPageUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<LandingPageUpdateLocalizationsInput>
  pageLayout?: InputMaybe<LandingPagepageLayoutUnionUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LandingPageUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type LandingPageUpdateLocalizationInput = {
  data: LandingPageUpdateLocalizationDataInput
  locale: Locale
}

export type LandingPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LandingPageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<LandingPageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<LandingPageUpsertLocalizationInput>>
}

export type LandingPageUpdateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageConnectInput>>
  /** Create and connect multiple LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>
  /** Delete multiple LandingPage documents */
  delete?: InputMaybe<Array<LandingPageWhereUniqueInput>>
  /** Disconnect multiple LandingPage documents */
  disconnect?: InputMaybe<Array<LandingPageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing LandingPage documents */
  set?: InputMaybe<Array<LandingPageWhereUniqueInput>>
  /** Update multiple LandingPage documents */
  update?: InputMaybe<Array<LandingPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple LandingPage documents */
  upsert?: InputMaybe<Array<LandingPageUpsertWithNestedWhereUniqueInput>>
}

export type LandingPageUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<LandingPageUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type LandingPageUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type LandingPageUpdateManyLocalizationInput = {
  data: LandingPageUpdateManyLocalizationDataInput
  locale: Locale
}

export type LandingPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<LandingPageUpdateManyLocalizationInput>>
}

export type LandingPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LandingPageUpdateManyInput
  /** Document search */
  where: LandingPageWhereInput
}

export type LandingPageUpdateOneInlineInput = {
  /** Connect existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>
  /** Delete currently connected LandingPage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected LandingPage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single LandingPage document */
  update?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single LandingPage document */
  upsert?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type LandingPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LandingPageUpdateInput
  /** Unique document search */
  where: LandingPageWhereUniqueInput
}

export type LandingPageUpsertInput = {
  /** Create document if it didn't exist */
  create: LandingPageCreateInput
  /** Update document if it exists */
  update: LandingPageUpdateInput
}

export type LandingPageUpsertLocalizationInput = {
  create: LandingPageCreateLocalizationDataInput
  locale: Locale
  update: LandingPageUpdateLocalizationDataInput
}

export type LandingPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LandingPageUpsertInput
  /** Unique document search */
  where: LandingPageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type LandingPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type LandingPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  pageLayout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageLayout_some?: InputMaybe<LandingPagepageLayoutUnionWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LandingPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LandingPageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References LandingPage record uniquely */
export type LandingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type LandingPagepageLayoutUnion =
  | Faq
  | PpcReviewsProduct
  | PreviewCardList
  | RichContent
  | SectionAd
  | SectionAnchorTarget
  | SectionBanner
  | SectionByTheNumbers
  | SectionCardGroup
  | SectionCollab
  | SectionContact
  | SectionCta
  | SectionCuratedSpeakerList
  | SectionExplanationBlocks
  | SectionExplanationStep
  | SectionFramesHero
  | SectionHero
  | SectionHubspotForm
  | SectionIntro
  | SectionNewsletter
  | SectionNewsletterAudience
  | SectionOmrPartner
  | SectionOmrProductCard
  | SectionTab
  | SectionTeaser
  | SectionTestimonial
  | SubscriptionTeaser
  | TestimonialSlider
  | TextMedia
  | TextMediaBanner

export type LandingPagepageLayoutUnionConnectInput = {
  Faq?: InputMaybe<FaqConnectInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductConnectInput>
  PreviewCardList?: InputMaybe<PreviewCardListConnectInput>
  RichContent?: InputMaybe<RichContentConnectInput>
  SectionAd?: InputMaybe<SectionAdConnectInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetConnectInput>
  SectionBanner?: InputMaybe<SectionBannerConnectInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersConnectInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupConnectInput>
  SectionCollab?: InputMaybe<SectionCollabConnectInput>
  SectionContact?: InputMaybe<SectionContactConnectInput>
  SectionCta?: InputMaybe<SectionCtaConnectInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListConnectInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksConnectInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepConnectInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroConnectInput>
  SectionHero?: InputMaybe<SectionHeroConnectInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormConnectInput>
  SectionIntro?: InputMaybe<SectionIntroConnectInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterConnectInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceConnectInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerConnectInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardConnectInput>
  SectionTab?: InputMaybe<SectionTabConnectInput>
  SectionTeaser?: InputMaybe<SectionTeaserConnectInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialConnectInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserConnectInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderConnectInput>
  TextMedia?: InputMaybe<TextMediaConnectInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerConnectInput>
}

export type LandingPagepageLayoutUnionCreateInput = {
  Faq?: InputMaybe<FaqCreateInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductCreateInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateInput>
  RichContent?: InputMaybe<RichContentCreateInput>
  SectionAd?: InputMaybe<SectionAdCreateInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetCreateInput>
  SectionBanner?: InputMaybe<SectionBannerCreateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupCreateInput>
  SectionCollab?: InputMaybe<SectionCollabCreateInput>
  SectionContact?: InputMaybe<SectionContactCreateInput>
  SectionCta?: InputMaybe<SectionCtaCreateInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListCreateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepCreateInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroCreateInput>
  SectionHero?: InputMaybe<SectionHeroCreateInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormCreateInput>
  SectionIntro?: InputMaybe<SectionIntroCreateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterCreateInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceCreateInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerCreateInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardCreateInput>
  SectionTab?: InputMaybe<SectionTabCreateInput>
  SectionTeaser?: InputMaybe<SectionTeaserCreateInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialCreateInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserCreateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderCreateInput>
  TextMedia?: InputMaybe<TextMediaCreateInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerCreateInput>
}

export type LandingPagepageLayoutUnionCreateManyInlineInput = {
  /** Create and connect multiple existing LandingPagepageLayoutUnion documents */
  create?: InputMaybe<Array<LandingPagepageLayoutUnionCreateInput>>
}

export type LandingPagepageLayoutUnionCreateOneInlineInput = {
  /** Create and connect one LandingPagepageLayoutUnion document */
  create?: InputMaybe<LandingPagepageLayoutUnionCreateInput>
}

export type LandingPagepageLayoutUnionCreateWithPositionInput = {
  Faq?: InputMaybe<FaqCreateWithPositionInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductCreateWithPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateWithPositionInput>
  RichContent?: InputMaybe<RichContentCreateWithPositionInput>
  SectionAd?: InputMaybe<SectionAdCreateWithPositionInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetCreateWithPositionInput>
  SectionBanner?: InputMaybe<SectionBannerCreateWithPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersCreateWithPositionInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupCreateWithPositionInput>
  SectionCollab?: InputMaybe<SectionCollabCreateWithPositionInput>
  SectionContact?: InputMaybe<SectionContactCreateWithPositionInput>
  SectionCta?: InputMaybe<SectionCtaCreateWithPositionInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListCreateWithPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksCreateWithPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepCreateWithPositionInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroCreateWithPositionInput>
  SectionHero?: InputMaybe<SectionHeroCreateWithPositionInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormCreateWithPositionInput>
  SectionIntro?: InputMaybe<SectionIntroCreateWithPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterCreateWithPositionInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceCreateWithPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerCreateWithPositionInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardCreateWithPositionInput>
  SectionTab?: InputMaybe<SectionTabCreateWithPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserCreateWithPositionInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialCreateWithPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserCreateWithPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderCreateWithPositionInput>
  TextMedia?: InputMaybe<TextMediaCreateWithPositionInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerCreateWithPositionInput>
}

export type LandingPagepageLayoutUnionUpdateInput = {
  Faq?: InputMaybe<FaqUpdateInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpdateInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateInput>
  RichContent?: InputMaybe<RichContentUpdateInput>
  SectionAd?: InputMaybe<SectionAdUpdateInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpdateInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateInput>
  SectionCollab?: InputMaybe<SectionCollabUpdateInput>
  SectionContact?: InputMaybe<SectionContactUpdateInput>
  SectionCta?: InputMaybe<SectionCtaUpdateInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpdateInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpdateInput>
  SectionHero?: InputMaybe<SectionHeroUpdateInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpdateInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpdateInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateInput>
  SectionTab?: InputMaybe<SectionTabUpdateInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpdateInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateInput>
  TextMedia?: InputMaybe<TextMediaUpdateInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateInput>
}

export type LandingPagepageLayoutUnionUpdateManyInlineInput = {
  /** Create and connect multiple LandingPagepageLayoutUnion component instances */
  create?: InputMaybe<Array<LandingPagepageLayoutUnionCreateWithPositionInput>>
  /** Delete multiple LandingPagepageLayoutUnion documents */
  delete?: InputMaybe<Array<LandingPagepageLayoutUnionWhereUniqueInput>>
  /** Update multiple LandingPagepageLayoutUnion component instances */
  update?: InputMaybe<Array<LandingPagepageLayoutUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple LandingPagepageLayoutUnion component instances */
  upsert?: InputMaybe<Array<LandingPagepageLayoutUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type LandingPagepageLayoutUnionUpdateManyWithNestedWhereInput = {
  Faq?: InputMaybe<FaqUpdateManyWithNestedWhereInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpdateManyWithNestedWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateManyWithNestedWhereInput>
  RichContent?: InputMaybe<RichContentUpdateManyWithNestedWhereInput>
  SectionAd?: InputMaybe<SectionAdUpdateManyWithNestedWhereInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpdateManyWithNestedWhereInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateManyWithNestedWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateManyWithNestedWhereInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateManyWithNestedWhereInput>
  SectionCollab?: InputMaybe<SectionCollabUpdateManyWithNestedWhereInput>
  SectionContact?: InputMaybe<SectionContactUpdateManyWithNestedWhereInput>
  SectionCta?: InputMaybe<SectionCtaUpdateManyWithNestedWhereInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpdateManyWithNestedWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateManyWithNestedWhereInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateManyWithNestedWhereInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpdateManyWithNestedWhereInput>
  SectionHero?: InputMaybe<SectionHeroUpdateManyWithNestedWhereInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpdateManyWithNestedWhereInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateManyWithNestedWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateManyWithNestedWhereInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpdateManyWithNestedWhereInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateManyWithNestedWhereInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateManyWithNestedWhereInput>
  SectionTab?: InputMaybe<SectionTabUpdateManyWithNestedWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateManyWithNestedWhereInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpdateManyWithNestedWhereInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateManyWithNestedWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateManyWithNestedWhereInput>
  TextMedia?: InputMaybe<TextMediaUpdateManyWithNestedWhereInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateManyWithNestedWhereInput>
}

export type LandingPagepageLayoutUnionUpdateOneInlineInput = {
  /** Create and connect one LandingPagepageLayoutUnion document */
  create?: InputMaybe<LandingPagepageLayoutUnionCreateInput>
  /** Delete currently connected LandingPagepageLayoutUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single LandingPagepageLayoutUnion document */
  update?: InputMaybe<LandingPagepageLayoutUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single LandingPagepageLayoutUnion document */
  upsert?: InputMaybe<LandingPagepageLayoutUnionUpsertWithNestedWhereUniqueInput>
}

export type LandingPagepageLayoutUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  Faq?: InputMaybe<FaqUpdateWithNestedWhereUniqueAndPositionInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpdateWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueAndPositionInput>
  RichContent?: InputMaybe<RichContentUpdateWithNestedWhereUniqueAndPositionInput>
  SectionAd?: InputMaybe<SectionAdUpdateWithNestedWhereUniqueAndPositionInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpdateWithNestedWhereUniqueAndPositionInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateWithNestedWhereUniqueAndPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCollab?: InputMaybe<SectionCollabUpdateWithNestedWhereUniqueAndPositionInput>
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCta?: InputMaybe<SectionCtaUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueAndPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateWithNestedWhereUniqueAndPositionInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionHero?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpdateWithNestedWhereUniqueAndPositionInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueAndPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueAndPositionInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpdateWithNestedWhereUniqueAndPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateWithNestedWhereUniqueAndPositionInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTab?: InputMaybe<SectionTabUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueAndPositionInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpdateWithNestedWhereUniqueAndPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateWithNestedWhereUniqueAndPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueAndPositionInput>
  TextMedia?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueAndPositionInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateWithNestedWhereUniqueAndPositionInput>
}

export type LandingPagepageLayoutUnionUpdateWithNestedWhereUniqueInput = {
  Faq?: InputMaybe<FaqUpdateWithNestedWhereUniqueInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpdateWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueInput>
  RichContent?: InputMaybe<RichContentUpdateWithNestedWhereUniqueInput>
  SectionAd?: InputMaybe<SectionAdUpdateWithNestedWhereUniqueInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpdateWithNestedWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerUpdateWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpdateWithNestedWhereUniqueInput>
  SectionCollab?: InputMaybe<SectionCollabUpdateWithNestedWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaUpdateWithNestedWhereUniqueInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpdateWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpdateWithNestedWhereUniqueInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpdateWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpdateWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpdateWithNestedWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpdateWithNestedWhereUniqueInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateWithNestedWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabUpdateWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpdateWithNestedWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpdateWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpdateWithNestedWhereUniqueInput>
}

export type LandingPagepageLayoutUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  Faq?: InputMaybe<FaqUpsertWithNestedWhereUniqueAndPositionInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpsertWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueAndPositionInput>
  RichContent?: InputMaybe<RichContentUpsertWithNestedWhereUniqueAndPositionInput>
  SectionAd?: InputMaybe<SectionAdUpsertWithNestedWhereUniqueAndPositionInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpsertWithNestedWhereUniqueAndPositionInput>
  SectionBanner?: InputMaybe<SectionBannerUpsertWithNestedWhereUniqueAndPositionInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCollab?: InputMaybe<SectionCollabUpsertWithNestedWhereUniqueAndPositionInput>
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCta?: InputMaybe<SectionCtaUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueAndPositionInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpsertWithNestedWhereUniqueAndPositionInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionHero?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpsertWithNestedWhereUniqueAndPositionInput>
  SectionIntro?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueAndPositionInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueAndPositionInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpsertWithNestedWhereUniqueAndPositionInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpsertWithNestedWhereUniqueAndPositionInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTab?: InputMaybe<SectionTabUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueAndPositionInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpsertWithNestedWhereUniqueAndPositionInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpsertWithNestedWhereUniqueAndPositionInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueAndPositionInput>
  TextMedia?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueAndPositionInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpsertWithNestedWhereUniqueAndPositionInput>
}

export type LandingPagepageLayoutUnionUpsertWithNestedWhereUniqueInput = {
  Faq?: InputMaybe<FaqUpsertWithNestedWhereUniqueInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductUpsertWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueInput>
  RichContent?: InputMaybe<RichContentUpsertWithNestedWhereUniqueInput>
  SectionAd?: InputMaybe<SectionAdUpsertWithNestedWhereUniqueInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetUpsertWithNestedWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerUpsertWithNestedWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupUpsertWithNestedWhereUniqueInput>
  SectionCollab?: InputMaybe<SectionCollabUpsertWithNestedWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaUpsertWithNestedWhereUniqueInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListUpsertWithNestedWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepUpsertWithNestedWhereUniqueInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroUpsertWithNestedWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormUpsertWithNestedWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceUpsertWithNestedWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerUpsertWithNestedWhereUniqueInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpsertWithNestedWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabUpsertWithNestedWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialUpsertWithNestedWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserUpsertWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerUpsertWithNestedWhereUniqueInput>
}

export type LandingPagepageLayoutUnionWhereInput = {
  Faq?: InputMaybe<FaqWhereInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereInput>
  RichContent?: InputMaybe<RichContentWhereInput>
  SectionAd?: InputMaybe<SectionAdWhereInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetWhereInput>
  SectionBanner?: InputMaybe<SectionBannerWhereInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupWhereInput>
  SectionCollab?: InputMaybe<SectionCollabWhereInput>
  SectionContact?: InputMaybe<SectionContactWhereInput>
  SectionCta?: InputMaybe<SectionCtaWhereInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListWhereInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepWhereInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroWhereInput>
  SectionHero?: InputMaybe<SectionHeroWhereInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormWhereInput>
  SectionIntro?: InputMaybe<SectionIntroWhereInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceWhereInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerWhereInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardWhereInput>
  SectionTab?: InputMaybe<SectionTabWhereInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialWhereInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereInput>
  TextMedia?: InputMaybe<TextMediaWhereInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerWhereInput>
}

export type LandingPagepageLayoutUnionWhereUniqueInput = {
  Faq?: InputMaybe<FaqWhereUniqueInput>
  PpcReviewsProduct?: InputMaybe<PpcReviewsProductWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereUniqueInput>
  RichContent?: InputMaybe<RichContentWhereUniqueInput>
  SectionAd?: InputMaybe<SectionAdWhereUniqueInput>
  SectionAnchorTarget?: InputMaybe<SectionAnchorTargetWhereUniqueInput>
  SectionBanner?: InputMaybe<SectionBannerWhereUniqueInput>
  SectionByTheNumbers?: InputMaybe<SectionByTheNumbersWhereUniqueInput>
  SectionCardGroup?: InputMaybe<SectionCardGroupWhereUniqueInput>
  SectionCollab?: InputMaybe<SectionCollabWhereUniqueInput>
  SectionContact?: InputMaybe<SectionContactWhereUniqueInput>
  SectionCta?: InputMaybe<SectionCtaWhereUniqueInput>
  SectionCuratedSpeakerList?: InputMaybe<SectionCuratedSpeakerListWhereUniqueInput>
  SectionExplanationBlocks?: InputMaybe<SectionExplanationBlocksWhereUniqueInput>
  SectionExplanationStep?: InputMaybe<SectionExplanationStepWhereUniqueInput>
  SectionFramesHero?: InputMaybe<SectionFramesHeroWhereUniqueInput>
  SectionHero?: InputMaybe<SectionHeroWhereUniqueInput>
  SectionHubspotForm?: InputMaybe<SectionHubspotFormWhereUniqueInput>
  SectionIntro?: InputMaybe<SectionIntroWhereUniqueInput>
  SectionNewsletter?: InputMaybe<SectionNewsletterWhereUniqueInput>
  SectionNewsletterAudience?: InputMaybe<SectionNewsletterAudienceWhereUniqueInput>
  SectionOMRPartner?: InputMaybe<SectionOmrPartnerWhereUniqueInput>
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardWhereUniqueInput>
  SectionTab?: InputMaybe<SectionTabWhereUniqueInput>
  SectionTeaser?: InputMaybe<SectionTeaserWhereUniqueInput>
  SectionTestimonial?: InputMaybe<SectionTestimonialWhereUniqueInput>
  SubscriptionTeaser?: InputMaybe<SubscriptionTeaserWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereUniqueInput>
  TextMedia?: InputMaybe<TextMediaWhereUniqueInput>
  TextMediaBanner?: InputMaybe<TextMediaBannerWhereUniqueInput>
}

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  De = 'de',
  En = 'en',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location'
  distance: Scalars['Float']['output']
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput
}

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfig = Entity &
  Node & {
    __typename?: 'ManageContentConfig'
    /** Slug to use in manage when user is not logged in - if not provided, item is not accesible for anonymous users. */
    anonymousSlug?: Maybe<Scalars['String']['output']>
    /** Slug of the page to show. */
    contentSlug: Scalars['String']['output']
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** The reference content page will be shown for the user with the given customer type. */
    customerTypes: Array<CustomerType>
    /** Get the document in other stages */
    documentInStages: Array<ManageContentConfig>
    /** List of ManageContentConfig versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Slug to use in manage when user is logged in - if not provided, item is not accessible with logged in url and not showing up in the Manage sidebar. */
    loggedInSlug?: Maybe<Scalars['String']['output']>
    /** Title to use in OMR Manage sidebar. */
    menuTitle?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Model to hold configuration options for pages to show in OMR Manage. */
export type ManageContentConfigUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ManageContentConfigConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ManageContentConfigWhereUniqueInput
}

/** A connection to a list of items. */
export type ManageContentConfigConnection = {
  __typename?: 'ManageContentConfigConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ManageContentConfigEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ManageContentConfigCreateInput = {
  anonymousSlug?: InputMaybe<Scalars['String']['input']>
  contentSlug: Scalars['String']['input']
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customerTypes?: InputMaybe<Array<CustomerType>>
  loggedInSlug?: InputMaybe<Scalars['String']['input']>
  menuTitle?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ManageContentConfigCreateManyInlineInput = {
  /** Connect multiple existing ManageContentConfig documents */
  connect?: InputMaybe<Array<ManageContentConfigWhereUniqueInput>>
  /** Create and connect multiple existing ManageContentConfig documents */
  create?: InputMaybe<Array<ManageContentConfigCreateInput>>
}

export type ManageContentConfigCreateOneInlineInput = {
  /** Connect one existing ManageContentConfig document */
  connect?: InputMaybe<ManageContentConfigWhereUniqueInput>
  /** Create and connect one ManageContentConfig document */
  create?: InputMaybe<ManageContentConfigCreateInput>
}

/** An edge in a connection. */
export type ManageContentConfigEdge = {
  __typename?: 'ManageContentConfigEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ManageContentConfig
}

/** Identifies documents */
export type ManageContentConfigManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  anonymousSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  anonymousSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  anonymousSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  anonymousSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  anonymousSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  anonymousSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  anonymousSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  anonymousSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  anonymousSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  anonymousSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  contentSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  contentSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  contentSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  contentSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  contentSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  contentSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  contentSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  contentSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  contentSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  contentSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  customerTypes?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array contains *all* items provided to the filter */
  customerTypes_contains_all?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  customerTypes_contains_none?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array contains at least one item provided to the filter */
  customerTypes_contains_some?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  customerTypes_not?: InputMaybe<Array<CustomerType>>
  documentInStages_every?: InputMaybe<ManageContentConfigWhereStageInput>
  documentInStages_none?: InputMaybe<ManageContentConfigWhereStageInput>
  documentInStages_some?: InputMaybe<ManageContentConfigWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  loggedInSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  loggedInSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  loggedInSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  loggedInSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  loggedInSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  loggedInSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  loggedInSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  loggedInSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  loggedInSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  loggedInSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  menuTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  menuTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  menuTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  menuTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  menuTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  menuTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  menuTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  menuTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  menuTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  menuTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ManageContentConfigOrderByInput {
  AnonymousSlugAsc = 'anonymousSlug_ASC',
  AnonymousSlugDesc = 'anonymousSlug_DESC',
  ContentSlugAsc = 'contentSlug_ASC',
  ContentSlugDesc = 'contentSlug_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CustomerTypesAsc = 'customerTypes_ASC',
  CustomerTypesDesc = 'customerTypes_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LoggedInSlugAsc = 'loggedInSlug_ASC',
  LoggedInSlugDesc = 'loggedInSlug_DESC',
  MenuTitleAsc = 'menuTitle_ASC',
  MenuTitleDesc = 'menuTitle_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ManageContentConfigUpdateInput = {
  anonymousSlug?: InputMaybe<Scalars['String']['input']>
  contentSlug?: InputMaybe<Scalars['String']['input']>
  customerTypes?: InputMaybe<Array<CustomerType>>
  loggedInSlug?: InputMaybe<Scalars['String']['input']>
  menuTitle?: InputMaybe<Scalars['String']['input']>
}

export type ManageContentConfigUpdateManyInlineInput = {
  /** Connect multiple existing ManageContentConfig documents */
  connect?: InputMaybe<Array<ManageContentConfigConnectInput>>
  /** Create and connect multiple ManageContentConfig documents */
  create?: InputMaybe<Array<ManageContentConfigCreateInput>>
  /** Delete multiple ManageContentConfig documents */
  delete?: InputMaybe<Array<ManageContentConfigWhereUniqueInput>>
  /** Disconnect multiple ManageContentConfig documents */
  disconnect?: InputMaybe<Array<ManageContentConfigWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ManageContentConfig documents */
  set?: InputMaybe<Array<ManageContentConfigWhereUniqueInput>>
  /** Update multiple ManageContentConfig documents */
  update?: InputMaybe<Array<ManageContentConfigUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ManageContentConfig documents */
  upsert?: InputMaybe<Array<ManageContentConfigUpsertWithNestedWhereUniqueInput>>
}

export type ManageContentConfigUpdateManyInput = {
  anonymousSlug?: InputMaybe<Scalars['String']['input']>
  contentSlug?: InputMaybe<Scalars['String']['input']>
  customerTypes?: InputMaybe<Array<CustomerType>>
  loggedInSlug?: InputMaybe<Scalars['String']['input']>
  menuTitle?: InputMaybe<Scalars['String']['input']>
}

export type ManageContentConfigUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ManageContentConfigUpdateManyInput
  /** Document search */
  where: ManageContentConfigWhereInput
}

export type ManageContentConfigUpdateOneInlineInput = {
  /** Connect existing ManageContentConfig document */
  connect?: InputMaybe<ManageContentConfigWhereUniqueInput>
  /** Create and connect one ManageContentConfig document */
  create?: InputMaybe<ManageContentConfigCreateInput>
  /** Delete currently connected ManageContentConfig document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ManageContentConfig document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ManageContentConfig document */
  update?: InputMaybe<ManageContentConfigUpdateWithNestedWhereUniqueInput>
  /** Upsert single ManageContentConfig document */
  upsert?: InputMaybe<ManageContentConfigUpsertWithNestedWhereUniqueInput>
}

export type ManageContentConfigUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ManageContentConfigUpdateInput
  /** Unique document search */
  where: ManageContentConfigWhereUniqueInput
}

export type ManageContentConfigUpsertInput = {
  /** Create document if it didn't exist */
  create: ManageContentConfigCreateInput
  /** Update document if it exists */
  update: ManageContentConfigUpdateInput
}

export type ManageContentConfigUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ManageContentConfigUpsertInput
  /** Unique document search */
  where: ManageContentConfigWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ManageContentConfigWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ManageContentConfigWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManageContentConfigWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  anonymousSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  anonymousSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  anonymousSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  anonymousSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  anonymousSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  anonymousSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  anonymousSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  anonymousSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  anonymousSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  anonymousSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  contentSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  contentSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  contentSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  contentSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  contentSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  contentSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  contentSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  contentSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  contentSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  contentSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  customerTypes?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array contains *all* items provided to the filter */
  customerTypes_contains_all?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  customerTypes_contains_none?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array contains at least one item provided to the filter */
  customerTypes_contains_some?: InputMaybe<Array<CustomerType>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  customerTypes_not?: InputMaybe<Array<CustomerType>>
  documentInStages_every?: InputMaybe<ManageContentConfigWhereStageInput>
  documentInStages_none?: InputMaybe<ManageContentConfigWhereStageInput>
  documentInStages_some?: InputMaybe<ManageContentConfigWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  loggedInSlug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  loggedInSlug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  loggedInSlug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  loggedInSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  loggedInSlug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  loggedInSlug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  loggedInSlug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  loggedInSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  loggedInSlug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  loggedInSlug_starts_with?: InputMaybe<Scalars['String']['input']>
  menuTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  menuTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  menuTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  menuTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  menuTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  menuTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  menuTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  menuTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  menuTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  menuTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ManageContentConfigWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManageContentConfigWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManageContentConfigWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManageContentConfigWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ManageContentConfigWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ManageContentConfig record uniquely */
export type ManageContentConfigWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarker = Entity &
  Node & {
    __typename?: 'MapMarker'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<MapMarker>
    /** Custom extra content in the custom map popup rendered as the last section of the popup. */
    extraContent?: Maybe<RichText>
    /** List of MapMarker versions */
    history: Array<Version>
    /** Icon displayed on the map. */
    icon: MapMarkerIcon
    /** The unique identifier */
    id: Scalars['ID']['output']
    linkoutUrl?: Maybe<Scalars['String']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<MapMarker>
    location: Location
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerIconArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Map marker used in the CustomMap component to highligh important places and provide more information about them. */
export type MapMarkerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type MapMarkerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: MapMarkerWhereUniqueInput
}

/** A connection to a list of items. */
export type MapMarkerConnection = {
  __typename?: 'MapMarkerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<MapMarkerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type MapMarkerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  extraContent?: InputMaybe<Scalars['RichTextAST']['input']>
  icon: MapMarkerIconCreateOneInlineInput
  linkoutUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MapMarkerCreateLocalizationsInput>
  location: LocationInput
  sectionCustomMap?: InputMaybe<SectionCustomMapCreateManyInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type MapMarkerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type MapMarkerCreateLocalizationInput = {
  /** Localization input */
  data: MapMarkerCreateLocalizationDataInput
  locale: Locale
}

export type MapMarkerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MapMarkerCreateLocalizationInput>>
}

export type MapMarkerCreateManyInlineInput = {
  /** Connect multiple existing MapMarker documents */
  connect?: InputMaybe<Array<MapMarkerWhereUniqueInput>>
  /** Create and connect multiple existing MapMarker documents */
  create?: InputMaybe<Array<MapMarkerCreateInput>>
}

export type MapMarkerCreateOneInlineInput = {
  /** Connect one existing MapMarker document */
  connect?: InputMaybe<MapMarkerWhereUniqueInput>
  /** Create and connect one MapMarker document */
  create?: InputMaybe<MapMarkerCreateInput>
}

/** An edge in a connection. */
export type MapMarkerEdge = {
  __typename?: 'MapMarkerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: MapMarker
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIcon = Entity &
  Node & {
    __typename?: 'MapMarkerIcon'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<MapMarkerIcon>
    /** List of MapMarkerIcon versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    mapMarker: Array<MapMarker>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    url: Scalars['String']['output']
  }

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconMapMarkerArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<MapMarkerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerWhereInput>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Icon used for Map marker in the CustomMap component. */
export type MapMarkerIconUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type MapMarkerIconConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: MapMarkerIconWhereUniqueInput
}

/** A connection to a list of items. */
export type MapMarkerIconConnection = {
  __typename?: 'MapMarkerIconConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<MapMarkerIconEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type MapMarkerIconCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  mapMarker?: InputMaybe<MapMarkerCreateManyInlineInput>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  url: Scalars['String']['input']
}

export type MapMarkerIconCreateManyInlineInput = {
  /** Connect multiple existing MapMarkerIcon documents */
  connect?: InputMaybe<Array<MapMarkerIconWhereUniqueInput>>
  /** Create and connect multiple existing MapMarkerIcon documents */
  create?: InputMaybe<Array<MapMarkerIconCreateInput>>
}

export type MapMarkerIconCreateOneInlineInput = {
  /** Connect one existing MapMarkerIcon document */
  connect?: InputMaybe<MapMarkerIconWhereUniqueInput>
  /** Create and connect one MapMarkerIcon document */
  create?: InputMaybe<MapMarkerIconCreateInput>
}

/** An edge in a connection. */
export type MapMarkerIconEdge = {
  __typename?: 'MapMarkerIconEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: MapMarkerIcon
}

/** Identifies documents */
export type MapMarkerIconManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<MapMarkerIconWhereStageInput>
  documentInStages_none?: InputMaybe<MapMarkerIconWhereStageInput>
  documentInStages_some?: InputMaybe<MapMarkerIconWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mapMarker_every?: InputMaybe<MapMarkerWhereInput>
  mapMarker_none?: InputMaybe<MapMarkerWhereInput>
  mapMarker_some?: InputMaybe<MapMarkerWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum MapMarkerIconOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type MapMarkerIconUpdateInput = {
  mapMarker?: InputMaybe<MapMarkerUpdateManyInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerIconUpdateManyInlineInput = {
  /** Connect multiple existing MapMarkerIcon documents */
  connect?: InputMaybe<Array<MapMarkerIconConnectInput>>
  /** Create and connect multiple MapMarkerIcon documents */
  create?: InputMaybe<Array<MapMarkerIconCreateInput>>
  /** Delete multiple MapMarkerIcon documents */
  delete?: InputMaybe<Array<MapMarkerIconWhereUniqueInput>>
  /** Disconnect multiple MapMarkerIcon documents */
  disconnect?: InputMaybe<Array<MapMarkerIconWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing MapMarkerIcon documents */
  set?: InputMaybe<Array<MapMarkerIconWhereUniqueInput>>
  /** Update multiple MapMarkerIcon documents */
  update?: InputMaybe<Array<MapMarkerIconUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple MapMarkerIcon documents */
  upsert?: InputMaybe<Array<MapMarkerIconUpsertWithNestedWhereUniqueInput>>
}

export type MapMarkerIconUpdateManyInput = {
  name?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerIconUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MapMarkerIconUpdateManyInput
  /** Document search */
  where: MapMarkerIconWhereInput
}

export type MapMarkerIconUpdateOneInlineInput = {
  /** Connect existing MapMarkerIcon document */
  connect?: InputMaybe<MapMarkerIconWhereUniqueInput>
  /** Create and connect one MapMarkerIcon document */
  create?: InputMaybe<MapMarkerIconCreateInput>
  /** Delete currently connected MapMarkerIcon document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected MapMarkerIcon document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single MapMarkerIcon document */
  update?: InputMaybe<MapMarkerIconUpdateWithNestedWhereUniqueInput>
  /** Upsert single MapMarkerIcon document */
  upsert?: InputMaybe<MapMarkerIconUpsertWithNestedWhereUniqueInput>
}

export type MapMarkerIconUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MapMarkerIconUpdateInput
  /** Unique document search */
  where: MapMarkerIconWhereUniqueInput
}

export type MapMarkerIconUpsertInput = {
  /** Create document if it didn't exist */
  create: MapMarkerIconCreateInput
  /** Update document if it exists */
  update: MapMarkerIconUpdateInput
}

export type MapMarkerIconUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MapMarkerIconUpsertInput
  /** Unique document search */
  where: MapMarkerIconWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type MapMarkerIconWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type MapMarkerIconWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerIconWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<MapMarkerIconWhereStageInput>
  documentInStages_none?: InputMaybe<MapMarkerIconWhereStageInput>
  documentInStages_some?: InputMaybe<MapMarkerIconWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mapMarker_every?: InputMaybe<MapMarkerWhereInput>
  mapMarker_none?: InputMaybe<MapMarkerWhereInput>
  mapMarker_some?: InputMaybe<MapMarkerWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MapMarkerIconWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerIconWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerIconWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerIconWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MapMarkerIconWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References MapMarkerIcon record uniquely */
export type MapMarkerIconWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Identifies documents */
export type MapMarkerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<MapMarkerWhereStageInput>
  documentInStages_none?: InputMaybe<MapMarkerWhereStageInput>
  documentInStages_some?: InputMaybe<MapMarkerWhereStageInput>
  icon?: InputMaybe<MapMarkerIconWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  linkoutUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkoutUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkoutUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkoutUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkoutUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkoutUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkoutUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkoutUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkoutUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkoutUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum MapMarkerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkoutUrlAsc = 'linkoutUrl_ASC',
  LinkoutUrlDesc = 'linkoutUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MapMarkerUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  extraContent?: InputMaybe<Scalars['RichTextAST']['input']>
  icon?: InputMaybe<MapMarkerIconUpdateOneInlineInput>
  linkoutUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<MapMarkerUpdateLocalizationsInput>
  location?: InputMaybe<LocationInput>
  sectionCustomMap?: InputMaybe<SectionCustomMapUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerUpdateLocalizationInput = {
  data: MapMarkerUpdateLocalizationDataInput
  locale: Locale
}

export type MapMarkerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MapMarkerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<MapMarkerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<MapMarkerUpsertLocalizationInput>>
}

export type MapMarkerUpdateManyInlineInput = {
  /** Connect multiple existing MapMarker documents */
  connect?: InputMaybe<Array<MapMarkerConnectInput>>
  /** Create and connect multiple MapMarker documents */
  create?: InputMaybe<Array<MapMarkerCreateInput>>
  /** Delete multiple MapMarker documents */
  delete?: InputMaybe<Array<MapMarkerWhereUniqueInput>>
  /** Disconnect multiple MapMarker documents */
  disconnect?: InputMaybe<Array<MapMarkerWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing MapMarker documents */
  set?: InputMaybe<Array<MapMarkerWhereUniqueInput>>
  /** Update multiple MapMarker documents */
  update?: InputMaybe<Array<MapMarkerUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple MapMarker documents */
  upsert?: InputMaybe<Array<MapMarkerUpsertWithNestedWhereUniqueInput>>
}

export type MapMarkerUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  extraContent?: InputMaybe<Scalars['RichTextAST']['input']>
  linkoutUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<MapMarkerUpdateManyLocalizationsInput>
  location?: InputMaybe<LocationInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type MapMarkerUpdateManyLocalizationInput = {
  data: MapMarkerUpdateManyLocalizationDataInput
  locale: Locale
}

export type MapMarkerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<MapMarkerUpdateManyLocalizationInput>>
}

export type MapMarkerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MapMarkerUpdateManyInput
  /** Document search */
  where: MapMarkerWhereInput
}

export type MapMarkerUpdateOneInlineInput = {
  /** Connect existing MapMarker document */
  connect?: InputMaybe<MapMarkerWhereUniqueInput>
  /** Create and connect one MapMarker document */
  create?: InputMaybe<MapMarkerCreateInput>
  /** Delete currently connected MapMarker document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected MapMarker document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single MapMarker document */
  update?: InputMaybe<MapMarkerUpdateWithNestedWhereUniqueInput>
  /** Upsert single MapMarker document */
  upsert?: InputMaybe<MapMarkerUpsertWithNestedWhereUniqueInput>
}

export type MapMarkerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MapMarkerUpdateInput
  /** Unique document search */
  where: MapMarkerWhereUniqueInput
}

export type MapMarkerUpsertInput = {
  /** Create document if it didn't exist */
  create: MapMarkerCreateInput
  /** Update document if it exists */
  update: MapMarkerUpdateInput
}

export type MapMarkerUpsertLocalizationInput = {
  create: MapMarkerCreateLocalizationDataInput
  locale: Locale
  update: MapMarkerUpdateLocalizationDataInput
}

export type MapMarkerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MapMarkerUpsertInput
  /** Unique document search */
  where: MapMarkerWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type MapMarkerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type MapMarkerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<MapMarkerWhereStageInput>
  documentInStages_none?: InputMaybe<MapMarkerWhereStageInput>
  documentInStages_some?: InputMaybe<MapMarkerWhereStageInput>
  icon?: InputMaybe<MapMarkerIconWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  linkoutUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkoutUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkoutUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkoutUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkoutUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkoutUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkoutUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkoutUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkoutUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkoutUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MapMarkerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MapMarkerWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MapMarkerWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MapMarkerWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MapMarkerWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References MapMarker record uniquely */
export type MapMarkerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Create one animatedCounter */
  createAnimatedCounter?: Maybe<AnimatedCounter>
  /** Create one article */
  createArticle?: Maybe<Article>
  /** Create one articleCategory */
  createArticleCategory?: Maybe<ArticleCategory>
  /** Create one articlePageView */
  createArticlePageView?: Maybe<ArticlePageView>
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>
  /** Create one author */
  createAuthor?: Maybe<Author>
  /** Create one booth */
  createBooth?: Maybe<Booth>
  /** Create one contentReference */
  createContentReference?: Maybe<ContentReference>
  /** Create one educationReport */
  createEducationReport?: Maybe<EducationReport>
  /** Create one educationSeminar */
  createEducationSeminar?: Maybe<EducationSeminar>
  /** Create one event */
  createEvent?: Maybe<Event>
  /** Create one eventAppPage */
  createEventAppPage?: Maybe<EventAppPage>
  /** Create one eventPage */
  createEventPage?: Maybe<EventPage>
  /** Create one eventStage */
  createEventStage?: Maybe<EventStage>
  /** Create one exhibitorListOrder */
  createExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Create one faqQuestion */
  createFaqQuestion?: Maybe<FaqQuestion>
  /** Create one footer */
  createFooter?: Maybe<Footer>
  /** Create one glossary */
  createGlossary?: Maybe<Glossary>
  /** Create one header */
  createHeader?: Maybe<Header>
  /** Create one hotel */
  createHotel?: Maybe<Hotel>
  /** Create one htmlEmbed */
  createHtmlEmbed?: Maybe<HtmlEmbed>
  /** Create one hubPage */
  createHubPage?: Maybe<HubPage>
  /** Create one imageGalleryEmbed */
  createImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Create one interest */
  createInterest?: Maybe<Interest>
  /** Create one jobListing */
  createJobListing?: Maybe<JobListing>
  /** Create one landingPage */
  createLandingPage?: Maybe<LandingPage>
  /** Create one manageContentConfig */
  createManageContentConfig?: Maybe<ManageContentConfig>
  /** Create one mapMarker */
  createMapMarker?: Maybe<MapMarker>
  /** Create one mapMarkerIcon */
  createMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Create one newsletterAudience */
  createNewsletterAudience?: Maybe<NewsletterAudience>
  /** Create one notification */
  createNotification?: Maybe<Notification>
  /** Create one oMRPartner */
  createOMRPartner?: Maybe<OmrPartner>
  /** Create one partnerCompany */
  createPartnerCompany?: Maybe<PartnerCompany>
  /** Create one physicalLocation */
  createPhysicalLocation?: Maybe<PhysicalLocation>
  /** Create one podcastEpisode */
  createPodcastEpisode?: Maybe<PodcastEpisode>
  /** Create one podcastGroup */
  createPodcastGroup?: Maybe<PodcastGroup>
  /** Create one recommendationFood */
  createRecommendationFood?: Maybe<RecommendationFood>
  /** Create one recommendationNightOut */
  createRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Create one reviewsAgency */
  createReviewsAgency?: Maybe<ReviewsAgency>
  /** Create one reviewsProduct */
  createReviewsProduct?: Maybe<ReviewsProduct>
  /** Create one reviewsProductCategory */
  createReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Create one reviewsProductWidget */
  createReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>
  /** Create one socialEmbed */
  createSocialEmbed?: Maybe<SocialEmbed>
  /** Create one speaker */
  createSpeaker?: Maybe<Speaker>
  /** Create one speakerListOrder */
  createSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Create one tabContent */
  createTabContent?: Maybe<TabContent>
  /** Create one tag */
  createTag?: Maybe<Tag>
  /** Create one topicPage */
  createTopicPage?: Maybe<TopicPage>
  /** Create one topicPageCategory */
  createTopicPageCategory?: Maybe<TopicPageCategory>
  /** Create one vGWort */
  createVGWort?: Maybe<VgWort>
  /** Delete one animatedCounter from _all_ existing stages. Returns deleted document. */
  deleteAnimatedCounter?: Maybe<AnimatedCounter>
  /** Delete one article from _all_ existing stages. Returns deleted document. */
  deleteArticle?: Maybe<Article>
  /** Delete one articleCategory from _all_ existing stages. Returns deleted document. */
  deleteArticleCategory?: Maybe<ArticleCategory>
  /** Delete one articlePageView from _all_ existing stages. Returns deleted document. */
  deleteArticlePageView?: Maybe<ArticlePageView>
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>
  /** Delete one author from _all_ existing stages. Returns deleted document. */
  deleteAuthor?: Maybe<Author>
  /** Delete one booth from _all_ existing stages. Returns deleted document. */
  deleteBooth?: Maybe<Booth>
  /** Delete one contentReference from _all_ existing stages. Returns deleted document. */
  deleteContentReference?: Maybe<ContentReference>
  /** Delete one educationReport from _all_ existing stages. Returns deleted document. */
  deleteEducationReport?: Maybe<EducationReport>
  /** Delete one educationSeminar from _all_ existing stages. Returns deleted document. */
  deleteEducationSeminar?: Maybe<EducationSeminar>
  /** Delete one event from _all_ existing stages. Returns deleted document. */
  deleteEvent?: Maybe<Event>
  /** Delete one eventAppPage from _all_ existing stages. Returns deleted document. */
  deleteEventAppPage?: Maybe<EventAppPage>
  /** Delete one eventPage from _all_ existing stages. Returns deleted document. */
  deleteEventPage?: Maybe<EventPage>
  /** Delete one eventStage from _all_ existing stages. Returns deleted document. */
  deleteEventStage?: Maybe<EventStage>
  /** Delete one exhibitorListOrder from _all_ existing stages. Returns deleted document. */
  deleteExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Delete one faqQuestion from _all_ existing stages. Returns deleted document. */
  deleteFaqQuestion?: Maybe<FaqQuestion>
  /** Delete one footer from _all_ existing stages. Returns deleted document. */
  deleteFooter?: Maybe<Footer>
  /** Delete one glossary from _all_ existing stages. Returns deleted document. */
  deleteGlossary?: Maybe<Glossary>
  /** Delete one header from _all_ existing stages. Returns deleted document. */
  deleteHeader?: Maybe<Header>
  /** Delete one hotel from _all_ existing stages. Returns deleted document. */
  deleteHotel?: Maybe<Hotel>
  /** Delete one htmlEmbed from _all_ existing stages. Returns deleted document. */
  deleteHtmlEmbed?: Maybe<HtmlEmbed>
  /** Delete one hubPage from _all_ existing stages. Returns deleted document. */
  deleteHubPage?: Maybe<HubPage>
  /** Delete one imageGalleryEmbed from _all_ existing stages. Returns deleted document. */
  deleteImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Delete one interest from _all_ existing stages. Returns deleted document. */
  deleteInterest?: Maybe<Interest>
  /** Delete one jobListing from _all_ existing stages. Returns deleted document. */
  deleteJobListing?: Maybe<JobListing>
  /** Delete one landingPage from _all_ existing stages. Returns deleted document. */
  deleteLandingPage?: Maybe<LandingPage>
  /** Delete one manageContentConfig from _all_ existing stages. Returns deleted document. */
  deleteManageContentConfig?: Maybe<ManageContentConfig>
  /**
   * Delete many AnimatedCounter documents
   * @deprecated Please use the new paginated many mutation (deleteManyAnimatedCountersConnection)
   */
  deleteManyAnimatedCounters: BatchPayload
  /** Delete many AnimatedCounter documents, return deleted documents */
  deleteManyAnimatedCountersConnection: AnimatedCounterConnection
  /**
   * Delete many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticleCategoriesConnection)
   */
  deleteManyArticleCategories: BatchPayload
  /** Delete many ArticleCategory documents, return deleted documents */
  deleteManyArticleCategoriesConnection: ArticleCategoryConnection
  /**
   * Delete many ArticlePageView documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlePageViewsConnection)
   */
  deleteManyArticlePageViews: BatchPayload
  /** Delete many ArticlePageView documents, return deleted documents */
  deleteManyArticlePageViewsConnection: ArticlePageViewConnection
  /**
   * Delete many Article documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlesConnection)
   */
  deleteManyArticles: BatchPayload
  /** Delete many Article documents, return deleted documents */
  deleteManyArticlesConnection: ArticleConnection
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection
  /**
   * Delete many Author documents
   * @deprecated Please use the new paginated many mutation (deleteManyAuthorsConnection)
   */
  deleteManyAuthors: BatchPayload
  /** Delete many Author documents, return deleted documents */
  deleteManyAuthorsConnection: AuthorConnection
  /**
   * Delete many Booth documents
   * @deprecated Please use the new paginated many mutation (deleteManyBoothsConnection)
   */
  deleteManyBooths: BatchPayload
  /** Delete many Booth documents, return deleted documents */
  deleteManyBoothsConnection: BoothConnection
  /**
   * Delete many ContentReference documents
   * @deprecated Please use the new paginated many mutation (deleteManyContentReferencesConnection)
   */
  deleteManyContentReferences: BatchPayload
  /** Delete many ContentReference documents, return deleted documents */
  deleteManyContentReferencesConnection: ContentReferenceConnection
  /**
   * Delete many EducationReport documents
   * @deprecated Please use the new paginated many mutation (deleteManyEducationReportsConnection)
   */
  deleteManyEducationReports: BatchPayload
  /** Delete many EducationReport documents, return deleted documents */
  deleteManyEducationReportsConnection: EducationReportConnection
  /**
   * Delete many EducationSeminar documents
   * @deprecated Please use the new paginated many mutation (deleteManyEducationSeminarsConnection)
   */
  deleteManyEducationSeminars: BatchPayload
  /** Delete many EducationSeminar documents, return deleted documents */
  deleteManyEducationSeminarsConnection: EducationSeminarConnection
  /**
   * Delete many EventAppPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyEventAppPagesConnection)
   */
  deleteManyEventAppPages: BatchPayload
  /** Delete many EventAppPage documents, return deleted documents */
  deleteManyEventAppPagesConnection: EventAppPageConnection
  /**
   * Delete many EventPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyEventPagesConnection)
   */
  deleteManyEventPages: BatchPayload
  /** Delete many EventPage documents, return deleted documents */
  deleteManyEventPagesConnection: EventPageConnection
  /**
   * Delete many EventStage documents
   * @deprecated Please use the new paginated many mutation (deleteManyEventStagesConnection)
   */
  deleteManyEventStages: BatchPayload
  /** Delete many EventStage documents, return deleted documents */
  deleteManyEventStagesConnection: EventStageConnection
  /**
   * Delete many Event documents
   * @deprecated Please use the new paginated many mutation (deleteManyEventsConnection)
   */
  deleteManyEvents: BatchPayload
  /** Delete many Event documents, return deleted documents */
  deleteManyEventsConnection: EventConnection
  /**
   * Delete many ExhibitorListOrder documents
   * @deprecated Please use the new paginated many mutation (deleteManyExhibitorListOrdersConnection)
   */
  deleteManyExhibitorListOrders: BatchPayload
  /** Delete many ExhibitorListOrder documents, return deleted documents */
  deleteManyExhibitorListOrdersConnection: ExhibitorListOrderConnection
  /**
   * Delete many FaqQuestion documents
   * @deprecated Please use the new paginated many mutation (deleteManyFaqQuestionsConnection)
   */
  deleteManyFaqQuestions: BatchPayload
  /** Delete many FaqQuestion documents, return deleted documents */
  deleteManyFaqQuestionsConnection: FaqQuestionConnection
  /**
   * Delete many Footer documents
   * @deprecated Please use the new paginated many mutation (deleteManyFootersConnection)
   */
  deleteManyFooters: BatchPayload
  /** Delete many Footer documents, return deleted documents */
  deleteManyFootersConnection: FooterConnection
  /**
   * Delete many Glossary documents
   * @deprecated Please use the new paginated many mutation (deleteManyGlossariesConnection)
   */
  deleteManyGlossaries: BatchPayload
  /** Delete many Glossary documents, return deleted documents */
  deleteManyGlossariesConnection: GlossaryConnection
  /**
   * Delete many Header documents
   * @deprecated Please use the new paginated many mutation (deleteManyHeadersConnection)
   */
  deleteManyHeaders: BatchPayload
  /** Delete many Header documents, return deleted documents */
  deleteManyHeadersConnection: HeaderConnection
  /**
   * Delete many Hotel documents
   * @deprecated Please use the new paginated many mutation (deleteManyHotelsConnection)
   */
  deleteManyHotels: BatchPayload
  /** Delete many Hotel documents, return deleted documents */
  deleteManyHotelsConnection: HotelConnection
  /**
   * Delete many HtmlEmbed documents
   * @deprecated Please use the new paginated many mutation (deleteManyHtmlEmbedsConnection)
   */
  deleteManyHtmlEmbeds: BatchPayload
  /** Delete many HtmlEmbed documents, return deleted documents */
  deleteManyHtmlEmbedsConnection: HtmlEmbedConnection
  /**
   * Delete many HubPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyHubPagesConnection)
   */
  deleteManyHubPages: BatchPayload
  /** Delete many HubPage documents, return deleted documents */
  deleteManyHubPagesConnection: HubPageConnection
  /**
   * Delete many ImageGalleryEmbed documents
   * @deprecated Please use the new paginated many mutation (deleteManyImageGalleryEmbedsConnection)
   */
  deleteManyImageGalleryEmbeds: BatchPayload
  /** Delete many ImageGalleryEmbed documents, return deleted documents */
  deleteManyImageGalleryEmbedsConnection: ImageGalleryEmbedConnection
  /**
   * Delete many Interest documents
   * @deprecated Please use the new paginated many mutation (deleteManyInterestsConnection)
   */
  deleteManyInterests: BatchPayload
  /** Delete many Interest documents, return deleted documents */
  deleteManyInterestsConnection: InterestConnection
  /**
   * Delete many JobListing documents
   * @deprecated Please use the new paginated many mutation (deleteManyJobListingsConnection)
   */
  deleteManyJobListings: BatchPayload
  /** Delete many JobListing documents, return deleted documents */
  deleteManyJobListingsConnection: JobListingConnection
  /**
   * Delete many LandingPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyLandingPagesConnection)
   */
  deleteManyLandingPages: BatchPayload
  /** Delete many LandingPage documents, return deleted documents */
  deleteManyLandingPagesConnection: LandingPageConnection
  /**
   * Delete many ManageContentConfig documents
   * @deprecated Please use the new paginated many mutation (deleteManyManageContentConfigsConnection)
   */
  deleteManyManageContentConfigs: BatchPayload
  /** Delete many ManageContentConfig documents, return deleted documents */
  deleteManyManageContentConfigsConnection: ManageContentConfigConnection
  /**
   * Delete many MapMarkerIcon documents
   * @deprecated Please use the new paginated many mutation (deleteManyMapMarkerIconsConnection)
   */
  deleteManyMapMarkerIcons: BatchPayload
  /** Delete many MapMarkerIcon documents, return deleted documents */
  deleteManyMapMarkerIconsConnection: MapMarkerIconConnection
  /**
   * Delete many MapMarker documents
   * @deprecated Please use the new paginated many mutation (deleteManyMapMarkersConnection)
   */
  deleteManyMapMarkers: BatchPayload
  /** Delete many MapMarker documents, return deleted documents */
  deleteManyMapMarkersConnection: MapMarkerConnection
  /**
   * Delete many NewsletterAudience documents
   * @deprecated Please use the new paginated many mutation (deleteManyNewsletterAudiencesConnection)
   */
  deleteManyNewsletterAudiences: BatchPayload
  /** Delete many NewsletterAudience documents, return deleted documents */
  deleteManyNewsletterAudiencesConnection: NewsletterAudienceConnection
  /**
   * Delete many Notification documents
   * @deprecated Please use the new paginated many mutation (deleteManyNotificationsConnection)
   */
  deleteManyNotifications: BatchPayload
  /** Delete many Notification documents, return deleted documents */
  deleteManyNotificationsConnection: NotificationConnection
  /**
   * Delete many OMRPartner documents
   * @deprecated Please use the new paginated many mutation (deleteManyOMRPartnersConnection)
   */
  deleteManyOMRPartners: BatchPayload
  /** Delete many OMRPartner documents, return deleted documents */
  deleteManyOMRPartnersConnection: OmrPartnerConnection
  /**
   * Delete many PartnerCompany documents
   * @deprecated Please use the new paginated many mutation (deleteManyPartnerCompaniesConnection)
   */
  deleteManyPartnerCompanies: BatchPayload
  /** Delete many PartnerCompany documents, return deleted documents */
  deleteManyPartnerCompaniesConnection: PartnerCompanyConnection
  /**
   * Delete many PhysicalLocation documents
   * @deprecated Please use the new paginated many mutation (deleteManyPhysicalLocationsConnection)
   */
  deleteManyPhysicalLocations: BatchPayload
  /** Delete many PhysicalLocation documents, return deleted documents */
  deleteManyPhysicalLocationsConnection: PhysicalLocationConnection
  /**
   * Delete many PodcastEpisode documents
   * @deprecated Please use the new paginated many mutation (deleteManyPodcastEpisodesConnection)
   */
  deleteManyPodcastEpisodes: BatchPayload
  /** Delete many PodcastEpisode documents, return deleted documents */
  deleteManyPodcastEpisodesConnection: PodcastEpisodeConnection
  /**
   * Delete many PodcastGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyPodcastGroupsConnection)
   */
  deleteManyPodcastGroups: BatchPayload
  /** Delete many PodcastGroup documents, return deleted documents */
  deleteManyPodcastGroupsConnection: PodcastGroupConnection
  /**
   * Delete many RecommendationFood documents
   * @deprecated Please use the new paginated many mutation (deleteManyRecommendationsFoodConnection)
   */
  deleteManyRecommendationsFood: BatchPayload
  /** Delete many RecommendationFood documents, return deleted documents */
  deleteManyRecommendationsFoodConnection: RecommendationFoodConnection
  /**
   * Delete many RecommendationNightOut documents
   * @deprecated Please use the new paginated many mutation (deleteManyRecommendationsNightOutConnection)
   */
  deleteManyRecommendationsNightOut: BatchPayload
  /** Delete many RecommendationNightOut documents, return deleted documents */
  deleteManyRecommendationsNightOutConnection: RecommendationNightOutConnection
  /**
   * Delete many ReviewsAgency documents
   * @deprecated Please use the new paginated many mutation (deleteManyReviewsAgenciesConnection)
   */
  deleteManyReviewsAgencies: BatchPayload
  /** Delete many ReviewsAgency documents, return deleted documents */
  deleteManyReviewsAgenciesConnection: ReviewsAgencyConnection
  /**
   * Delete many ReviewsProductCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyReviewsProductCategoriesConnection)
   */
  deleteManyReviewsProductCategories: BatchPayload
  /** Delete many ReviewsProductCategory documents, return deleted documents */
  deleteManyReviewsProductCategoriesConnection: ReviewsProductCategoryConnection
  /**
   * Delete many ReviewsProductWidget documents
   * @deprecated Please use the new paginated many mutation (deleteManyReviewsProductWidgetsConnection)
   */
  deleteManyReviewsProductWidgets: BatchPayload
  /** Delete many ReviewsProductWidget documents, return deleted documents */
  deleteManyReviewsProductWidgetsConnection: ReviewsProductWidgetConnection
  /**
   * Delete many ReviewsProduct documents
   * @deprecated Please use the new paginated many mutation (deleteManyReviewsProductsConnection)
   */
  deleteManyReviewsProducts: BatchPayload
  /** Delete many ReviewsProduct documents, return deleted documents */
  deleteManyReviewsProductsConnection: ReviewsProductConnection
  /**
   * Delete many SocialEmbed documents
   * @deprecated Please use the new paginated many mutation (deleteManySocialEmbedsConnection)
   */
  deleteManySocialEmbeds: BatchPayload
  /** Delete many SocialEmbed documents, return deleted documents */
  deleteManySocialEmbedsConnection: SocialEmbedConnection
  /**
   * Delete many SpeakerListOrder documents
   * @deprecated Please use the new paginated many mutation (deleteManySpeakerListOrdersConnection)
   */
  deleteManySpeakerListOrders: BatchPayload
  /** Delete many SpeakerListOrder documents, return deleted documents */
  deleteManySpeakerListOrdersConnection: SpeakerListOrderConnection
  /**
   * Delete many Speaker documents
   * @deprecated Please use the new paginated many mutation (deleteManySpeakersConnection)
   */
  deleteManySpeakers: BatchPayload
  /** Delete many Speaker documents, return deleted documents */
  deleteManySpeakersConnection: SpeakerConnection
  /**
   * Delete many TabContent documents
   * @deprecated Please use the new paginated many mutation (deleteManyTabContentsConnection)
   */
  deleteManyTabContents: BatchPayload
  /** Delete many TabContent documents, return deleted documents */
  deleteManyTabContentsConnection: TabContentConnection
  /**
   * Delete many Tag documents
   * @deprecated Please use the new paginated many mutation (deleteManyTagsConnection)
   */
  deleteManyTags: BatchPayload
  /** Delete many Tag documents, return deleted documents */
  deleteManyTagsConnection: TagConnection
  /**
   * Delete many TopicPageCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyTopicPageCategoriesConnection)
   */
  deleteManyTopicPageCategories: BatchPayload
  /** Delete many TopicPageCategory documents, return deleted documents */
  deleteManyTopicPageCategoriesConnection: TopicPageCategoryConnection
  /**
   * Delete many TopicPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyTopicPagesConnection)
   */
  deleteManyTopicPages: BatchPayload
  /** Delete many TopicPage documents, return deleted documents */
  deleteManyTopicPagesConnection: TopicPageConnection
  /**
   * Delete many VGWort documents
   * @deprecated Please use the new paginated many mutation (deleteManyVGWortsConnection)
   */
  deleteManyVGWorts: BatchPayload
  /** Delete many VGWort documents, return deleted documents */
  deleteManyVGWortsConnection: VgWortConnection
  /** Delete one mapMarker from _all_ existing stages. Returns deleted document. */
  deleteMapMarker?: Maybe<MapMarker>
  /** Delete one mapMarkerIcon from _all_ existing stages. Returns deleted document. */
  deleteMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Delete one newsletterAudience from _all_ existing stages. Returns deleted document. */
  deleteNewsletterAudience?: Maybe<NewsletterAudience>
  /** Delete one notification from _all_ existing stages. Returns deleted document. */
  deleteNotification?: Maybe<Notification>
  /** Delete one oMRPartner from _all_ existing stages. Returns deleted document. */
  deleteOMRPartner?: Maybe<OmrPartner>
  /** Delete one partnerCompany from _all_ existing stages. Returns deleted document. */
  deletePartnerCompany?: Maybe<PartnerCompany>
  /** Delete one physicalLocation from _all_ existing stages. Returns deleted document. */
  deletePhysicalLocation?: Maybe<PhysicalLocation>
  /** Delete one podcastEpisode from _all_ existing stages. Returns deleted document. */
  deletePodcastEpisode?: Maybe<PodcastEpisode>
  /** Delete one podcastGroup from _all_ existing stages. Returns deleted document. */
  deletePodcastGroup?: Maybe<PodcastGroup>
  /** Delete one recommendationFood from _all_ existing stages. Returns deleted document. */
  deleteRecommendationFood?: Maybe<RecommendationFood>
  /** Delete one recommendationNightOut from _all_ existing stages. Returns deleted document. */
  deleteRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Delete one reviewsAgency from _all_ existing stages. Returns deleted document. */
  deleteReviewsAgency?: Maybe<ReviewsAgency>
  /** Delete one reviewsProduct from _all_ existing stages. Returns deleted document. */
  deleteReviewsProduct?: Maybe<ReviewsProduct>
  /** Delete one reviewsProductCategory from _all_ existing stages. Returns deleted document. */
  deleteReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Delete one reviewsProductWidget from _all_ existing stages. Returns deleted document. */
  deleteReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>
  /** Delete one socialEmbed from _all_ existing stages. Returns deleted document. */
  deleteSocialEmbed?: Maybe<SocialEmbed>
  /** Delete one speaker from _all_ existing stages. Returns deleted document. */
  deleteSpeaker?: Maybe<Speaker>
  /** Delete one speakerListOrder from _all_ existing stages. Returns deleted document. */
  deleteSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Delete one tabContent from _all_ existing stages. Returns deleted document. */
  deleteTabContent?: Maybe<TabContent>
  /** Delete one tag from _all_ existing stages. Returns deleted document. */
  deleteTag?: Maybe<Tag>
  /** Delete one topicPage from _all_ existing stages. Returns deleted document. */
  deleteTopicPage?: Maybe<TopicPage>
  /** Delete one topicPageCategory from _all_ existing stages. Returns deleted document. */
  deleteTopicPageCategory?: Maybe<TopicPageCategory>
  /** Delete one vGWort from _all_ existing stages. Returns deleted document. */
  deleteVGWort?: Maybe<VgWort>
  /** Publish one animatedCounter */
  publishAnimatedCounter?: Maybe<AnimatedCounter>
  /** Publish one article */
  publishArticle?: Maybe<Article>
  /** Publish one articleCategory */
  publishArticleCategory?: Maybe<ArticleCategory>
  /** Publish one articlePageView */
  publishArticlePageView?: Maybe<ArticlePageView>
  /** Publish one asset */
  publishAsset?: Maybe<Asset>
  /** Publish one author */
  publishAuthor?: Maybe<Author>
  /** Publish one booth */
  publishBooth?: Maybe<Booth>
  /** Publish one contentReference */
  publishContentReference?: Maybe<ContentReference>
  /** Publish one educationReport */
  publishEducationReport?: Maybe<EducationReport>
  /** Publish one educationSeminar */
  publishEducationSeminar?: Maybe<EducationSeminar>
  /** Publish one event */
  publishEvent?: Maybe<Event>
  /** Publish one eventAppPage */
  publishEventAppPage?: Maybe<EventAppPage>
  /** Publish one eventPage */
  publishEventPage?: Maybe<EventPage>
  /** Publish one eventStage */
  publishEventStage?: Maybe<EventStage>
  /** Publish one exhibitorListOrder */
  publishExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Publish one faqQuestion */
  publishFaqQuestion?: Maybe<FaqQuestion>
  /** Publish one footer */
  publishFooter?: Maybe<Footer>
  /** Publish one glossary */
  publishGlossary?: Maybe<Glossary>
  /** Publish one header */
  publishHeader?: Maybe<Header>
  /** Publish one hotel */
  publishHotel?: Maybe<Hotel>
  /** Publish one htmlEmbed */
  publishHtmlEmbed?: Maybe<HtmlEmbed>
  /** Publish one hubPage */
  publishHubPage?: Maybe<HubPage>
  /** Publish one imageGalleryEmbed */
  publishImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Publish one interest */
  publishInterest?: Maybe<Interest>
  /** Publish one jobListing */
  publishJobListing?: Maybe<JobListing>
  /** Publish one landingPage */
  publishLandingPage?: Maybe<LandingPage>
  /** Publish one manageContentConfig */
  publishManageContentConfig?: Maybe<ManageContentConfig>
  /**
   * Publish many AnimatedCounter documents
   * @deprecated Please use the new paginated many mutation (publishManyAnimatedCountersConnection)
   */
  publishManyAnimatedCounters: BatchPayload
  /** Publish many AnimatedCounter documents */
  publishManyAnimatedCountersConnection: AnimatedCounterConnection
  /**
   * Publish many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyArticleCategoriesConnection)
   */
  publishManyArticleCategories: BatchPayload
  /** Publish many ArticleCategory documents */
  publishManyArticleCategoriesConnection: ArticleCategoryConnection
  /**
   * Publish many ArticlePageView documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlePageViewsConnection)
   */
  publishManyArticlePageViews: BatchPayload
  /** Publish many ArticlePageView documents */
  publishManyArticlePageViewsConnection: ArticlePageViewConnection
  /**
   * Publish many Article documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlesConnection)
   */
  publishManyArticles: BatchPayload
  /** Publish many Article documents */
  publishManyArticlesConnection: ArticleConnection
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection
  /**
   * Publish many Author documents
   * @deprecated Please use the new paginated many mutation (publishManyAuthorsConnection)
   */
  publishManyAuthors: BatchPayload
  /** Publish many Author documents */
  publishManyAuthorsConnection: AuthorConnection
  /**
   * Publish many Booth documents
   * @deprecated Please use the new paginated many mutation (publishManyBoothsConnection)
   */
  publishManyBooths: BatchPayload
  /** Publish many Booth documents */
  publishManyBoothsConnection: BoothConnection
  /**
   * Publish many ContentReference documents
   * @deprecated Please use the new paginated many mutation (publishManyContentReferencesConnection)
   */
  publishManyContentReferences: BatchPayload
  /** Publish many ContentReference documents */
  publishManyContentReferencesConnection: ContentReferenceConnection
  /**
   * Publish many EducationReport documents
   * @deprecated Please use the new paginated many mutation (publishManyEducationReportsConnection)
   */
  publishManyEducationReports: BatchPayload
  /** Publish many EducationReport documents */
  publishManyEducationReportsConnection: EducationReportConnection
  /**
   * Publish many EducationSeminar documents
   * @deprecated Please use the new paginated many mutation (publishManyEducationSeminarsConnection)
   */
  publishManyEducationSeminars: BatchPayload
  /** Publish many EducationSeminar documents */
  publishManyEducationSeminarsConnection: EducationSeminarConnection
  /**
   * Publish many EventAppPage documents
   * @deprecated Please use the new paginated many mutation (publishManyEventAppPagesConnection)
   */
  publishManyEventAppPages: BatchPayload
  /** Publish many EventAppPage documents */
  publishManyEventAppPagesConnection: EventAppPageConnection
  /**
   * Publish many EventPage documents
   * @deprecated Please use the new paginated many mutation (publishManyEventPagesConnection)
   */
  publishManyEventPages: BatchPayload
  /** Publish many EventPage documents */
  publishManyEventPagesConnection: EventPageConnection
  /**
   * Publish many EventStage documents
   * @deprecated Please use the new paginated many mutation (publishManyEventStagesConnection)
   */
  publishManyEventStages: BatchPayload
  /** Publish many EventStage documents */
  publishManyEventStagesConnection: EventStageConnection
  /**
   * Publish many Event documents
   * @deprecated Please use the new paginated many mutation (publishManyEventsConnection)
   */
  publishManyEvents: BatchPayload
  /** Publish many Event documents */
  publishManyEventsConnection: EventConnection
  /**
   * Publish many ExhibitorListOrder documents
   * @deprecated Please use the new paginated many mutation (publishManyExhibitorListOrdersConnection)
   */
  publishManyExhibitorListOrders: BatchPayload
  /** Publish many ExhibitorListOrder documents */
  publishManyExhibitorListOrdersConnection: ExhibitorListOrderConnection
  /**
   * Publish many FaqQuestion documents
   * @deprecated Please use the new paginated many mutation (publishManyFaqQuestionsConnection)
   */
  publishManyFaqQuestions: BatchPayload
  /** Publish many FaqQuestion documents */
  publishManyFaqQuestionsConnection: FaqQuestionConnection
  /**
   * Publish many Footer documents
   * @deprecated Please use the new paginated many mutation (publishManyFootersConnection)
   */
  publishManyFooters: BatchPayload
  /** Publish many Footer documents */
  publishManyFootersConnection: FooterConnection
  /**
   * Publish many Glossary documents
   * @deprecated Please use the new paginated many mutation (publishManyGlossariesConnection)
   */
  publishManyGlossaries: BatchPayload
  /** Publish many Glossary documents */
  publishManyGlossariesConnection: GlossaryConnection
  /**
   * Publish many Header documents
   * @deprecated Please use the new paginated many mutation (publishManyHeadersConnection)
   */
  publishManyHeaders: BatchPayload
  /** Publish many Header documents */
  publishManyHeadersConnection: HeaderConnection
  /**
   * Publish many Hotel documents
   * @deprecated Please use the new paginated many mutation (publishManyHotelsConnection)
   */
  publishManyHotels: BatchPayload
  /** Publish many Hotel documents */
  publishManyHotelsConnection: HotelConnection
  /**
   * Publish many HtmlEmbed documents
   * @deprecated Please use the new paginated many mutation (publishManyHtmlEmbedsConnection)
   */
  publishManyHtmlEmbeds: BatchPayload
  /** Publish many HtmlEmbed documents */
  publishManyHtmlEmbedsConnection: HtmlEmbedConnection
  /**
   * Publish many HubPage documents
   * @deprecated Please use the new paginated many mutation (publishManyHubPagesConnection)
   */
  publishManyHubPages: BatchPayload
  /** Publish many HubPage documents */
  publishManyHubPagesConnection: HubPageConnection
  /**
   * Publish many ImageGalleryEmbed documents
   * @deprecated Please use the new paginated many mutation (publishManyImageGalleryEmbedsConnection)
   */
  publishManyImageGalleryEmbeds: BatchPayload
  /** Publish many ImageGalleryEmbed documents */
  publishManyImageGalleryEmbedsConnection: ImageGalleryEmbedConnection
  /**
   * Publish many Interest documents
   * @deprecated Please use the new paginated many mutation (publishManyInterestsConnection)
   */
  publishManyInterests: BatchPayload
  /** Publish many Interest documents */
  publishManyInterestsConnection: InterestConnection
  /**
   * Publish many JobListing documents
   * @deprecated Please use the new paginated many mutation (publishManyJobListingsConnection)
   */
  publishManyJobListings: BatchPayload
  /** Publish many JobListing documents */
  publishManyJobListingsConnection: JobListingConnection
  /**
   * Publish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (publishManyLandingPagesConnection)
   */
  publishManyLandingPages: BatchPayload
  /** Publish many LandingPage documents */
  publishManyLandingPagesConnection: LandingPageConnection
  /**
   * Publish many ManageContentConfig documents
   * @deprecated Please use the new paginated many mutation (publishManyManageContentConfigsConnection)
   */
  publishManyManageContentConfigs: BatchPayload
  /** Publish many ManageContentConfig documents */
  publishManyManageContentConfigsConnection: ManageContentConfigConnection
  /**
   * Publish many MapMarkerIcon documents
   * @deprecated Please use the new paginated many mutation (publishManyMapMarkerIconsConnection)
   */
  publishManyMapMarkerIcons: BatchPayload
  /** Publish many MapMarkerIcon documents */
  publishManyMapMarkerIconsConnection: MapMarkerIconConnection
  /**
   * Publish many MapMarker documents
   * @deprecated Please use the new paginated many mutation (publishManyMapMarkersConnection)
   */
  publishManyMapMarkers: BatchPayload
  /** Publish many MapMarker documents */
  publishManyMapMarkersConnection: MapMarkerConnection
  /**
   * Publish many NewsletterAudience documents
   * @deprecated Please use the new paginated many mutation (publishManyNewsletterAudiencesConnection)
   */
  publishManyNewsletterAudiences: BatchPayload
  /** Publish many NewsletterAudience documents */
  publishManyNewsletterAudiencesConnection: NewsletterAudienceConnection
  /**
   * Publish many Notification documents
   * @deprecated Please use the new paginated many mutation (publishManyNotificationsConnection)
   */
  publishManyNotifications: BatchPayload
  /** Publish many Notification documents */
  publishManyNotificationsConnection: NotificationConnection
  /**
   * Publish many OMRPartner documents
   * @deprecated Please use the new paginated many mutation (publishManyOMRPartnersConnection)
   */
  publishManyOMRPartners: BatchPayload
  /** Publish many OMRPartner documents */
  publishManyOMRPartnersConnection: OmrPartnerConnection
  /**
   * Publish many PartnerCompany documents
   * @deprecated Please use the new paginated many mutation (publishManyPartnerCompaniesConnection)
   */
  publishManyPartnerCompanies: BatchPayload
  /** Publish many PartnerCompany documents */
  publishManyPartnerCompaniesConnection: PartnerCompanyConnection
  /**
   * Publish many PhysicalLocation documents
   * @deprecated Please use the new paginated many mutation (publishManyPhysicalLocationsConnection)
   */
  publishManyPhysicalLocations: BatchPayload
  /** Publish many PhysicalLocation documents */
  publishManyPhysicalLocationsConnection: PhysicalLocationConnection
  /**
   * Publish many PodcastEpisode documents
   * @deprecated Please use the new paginated many mutation (publishManyPodcastEpisodesConnection)
   */
  publishManyPodcastEpisodes: BatchPayload
  /** Publish many PodcastEpisode documents */
  publishManyPodcastEpisodesConnection: PodcastEpisodeConnection
  /**
   * Publish many PodcastGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyPodcastGroupsConnection)
   */
  publishManyPodcastGroups: BatchPayload
  /** Publish many PodcastGroup documents */
  publishManyPodcastGroupsConnection: PodcastGroupConnection
  /**
   * Publish many RecommendationFood documents
   * @deprecated Please use the new paginated many mutation (publishManyRecommendationsFoodConnection)
   */
  publishManyRecommendationsFood: BatchPayload
  /** Publish many RecommendationFood documents */
  publishManyRecommendationsFoodConnection: RecommendationFoodConnection
  /**
   * Publish many RecommendationNightOut documents
   * @deprecated Please use the new paginated many mutation (publishManyRecommendationsNightOutConnection)
   */
  publishManyRecommendationsNightOut: BatchPayload
  /** Publish many RecommendationNightOut documents */
  publishManyRecommendationsNightOutConnection: RecommendationNightOutConnection
  /**
   * Publish many ReviewsAgency documents
   * @deprecated Please use the new paginated many mutation (publishManyReviewsAgenciesConnection)
   */
  publishManyReviewsAgencies: BatchPayload
  /** Publish many ReviewsAgency documents */
  publishManyReviewsAgenciesConnection: ReviewsAgencyConnection
  /**
   * Publish many ReviewsProductCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyReviewsProductCategoriesConnection)
   */
  publishManyReviewsProductCategories: BatchPayload
  /** Publish many ReviewsProductCategory documents */
  publishManyReviewsProductCategoriesConnection: ReviewsProductCategoryConnection
  /**
   * Publish many ReviewsProductWidget documents
   * @deprecated Please use the new paginated many mutation (publishManyReviewsProductWidgetsConnection)
   */
  publishManyReviewsProductWidgets: BatchPayload
  /** Publish many ReviewsProductWidget documents */
  publishManyReviewsProductWidgetsConnection: ReviewsProductWidgetConnection
  /**
   * Publish many ReviewsProduct documents
   * @deprecated Please use the new paginated many mutation (publishManyReviewsProductsConnection)
   */
  publishManyReviewsProducts: BatchPayload
  /** Publish many ReviewsProduct documents */
  publishManyReviewsProductsConnection: ReviewsProductConnection
  /**
   * Publish many SocialEmbed documents
   * @deprecated Please use the new paginated many mutation (publishManySocialEmbedsConnection)
   */
  publishManySocialEmbeds: BatchPayload
  /** Publish many SocialEmbed documents */
  publishManySocialEmbedsConnection: SocialEmbedConnection
  /**
   * Publish many SpeakerListOrder documents
   * @deprecated Please use the new paginated many mutation (publishManySpeakerListOrdersConnection)
   */
  publishManySpeakerListOrders: BatchPayload
  /** Publish many SpeakerListOrder documents */
  publishManySpeakerListOrdersConnection: SpeakerListOrderConnection
  /**
   * Publish many Speaker documents
   * @deprecated Please use the new paginated many mutation (publishManySpeakersConnection)
   */
  publishManySpeakers: BatchPayload
  /** Publish many Speaker documents */
  publishManySpeakersConnection: SpeakerConnection
  /**
   * Publish many TabContent documents
   * @deprecated Please use the new paginated many mutation (publishManyTabContentsConnection)
   */
  publishManyTabContents: BatchPayload
  /** Publish many TabContent documents */
  publishManyTabContentsConnection: TabContentConnection
  /**
   * Publish many Tag documents
   * @deprecated Please use the new paginated many mutation (publishManyTagsConnection)
   */
  publishManyTags: BatchPayload
  /** Publish many Tag documents */
  publishManyTagsConnection: TagConnection
  /**
   * Publish many TopicPageCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyTopicPageCategoriesConnection)
   */
  publishManyTopicPageCategories: BatchPayload
  /** Publish many TopicPageCategory documents */
  publishManyTopicPageCategoriesConnection: TopicPageCategoryConnection
  /**
   * Publish many TopicPage documents
   * @deprecated Please use the new paginated many mutation (publishManyTopicPagesConnection)
   */
  publishManyTopicPages: BatchPayload
  /** Publish many TopicPage documents */
  publishManyTopicPagesConnection: TopicPageConnection
  /**
   * Publish many VGWort documents
   * @deprecated Please use the new paginated many mutation (publishManyVGWortsConnection)
   */
  publishManyVGWorts: BatchPayload
  /** Publish many VGWort documents */
  publishManyVGWortsConnection: VgWortConnection
  /** Publish one mapMarker */
  publishMapMarker?: Maybe<MapMarker>
  /** Publish one mapMarkerIcon */
  publishMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Publish one newsletterAudience */
  publishNewsletterAudience?: Maybe<NewsletterAudience>
  /** Publish one notification */
  publishNotification?: Maybe<Notification>
  /** Publish one oMRPartner */
  publishOMRPartner?: Maybe<OmrPartner>
  /** Publish one partnerCompany */
  publishPartnerCompany?: Maybe<PartnerCompany>
  /** Publish one physicalLocation */
  publishPhysicalLocation?: Maybe<PhysicalLocation>
  /** Publish one podcastEpisode */
  publishPodcastEpisode?: Maybe<PodcastEpisode>
  /** Publish one podcastGroup */
  publishPodcastGroup?: Maybe<PodcastGroup>
  /** Publish one recommendationFood */
  publishRecommendationFood?: Maybe<RecommendationFood>
  /** Publish one recommendationNightOut */
  publishRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Publish one reviewsAgency */
  publishReviewsAgency?: Maybe<ReviewsAgency>
  /** Publish one reviewsProduct */
  publishReviewsProduct?: Maybe<ReviewsProduct>
  /** Publish one reviewsProductCategory */
  publishReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Publish one reviewsProductWidget */
  publishReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Publish one socialEmbed */
  publishSocialEmbed?: Maybe<SocialEmbed>
  /** Publish one speaker */
  publishSpeaker?: Maybe<Speaker>
  /** Publish one speakerListOrder */
  publishSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Publish one tabContent */
  publishTabContent?: Maybe<TabContent>
  /** Publish one tag */
  publishTag?: Maybe<Tag>
  /** Publish one topicPage */
  publishTopicPage?: Maybe<TopicPage>
  /** Publish one topicPageCategory */
  publishTopicPageCategory?: Maybe<TopicPageCategory>
  /** Publish one vGWort */
  publishVGWort?: Maybe<VgWort>
  /** Schedule to publish one animatedCounter */
  schedulePublishAnimatedCounter?: Maybe<AnimatedCounter>
  /** Schedule to publish one article */
  schedulePublishArticle?: Maybe<Article>
  /** Schedule to publish one articleCategory */
  schedulePublishArticleCategory?: Maybe<ArticleCategory>
  /** Schedule to publish one articlePageView */
  schedulePublishArticlePageView?: Maybe<ArticlePageView>
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>
  /** Schedule to publish one author */
  schedulePublishAuthor?: Maybe<Author>
  /** Schedule to publish one booth */
  schedulePublishBooth?: Maybe<Booth>
  /** Schedule to publish one contentReference */
  schedulePublishContentReference?: Maybe<ContentReference>
  /** Schedule to publish one educationReport */
  schedulePublishEducationReport?: Maybe<EducationReport>
  /** Schedule to publish one educationSeminar */
  schedulePublishEducationSeminar?: Maybe<EducationSeminar>
  /** Schedule to publish one event */
  schedulePublishEvent?: Maybe<Event>
  /** Schedule to publish one eventAppPage */
  schedulePublishEventAppPage?: Maybe<EventAppPage>
  /** Schedule to publish one eventPage */
  schedulePublishEventPage?: Maybe<EventPage>
  /** Schedule to publish one eventStage */
  schedulePublishEventStage?: Maybe<EventStage>
  /** Schedule to publish one exhibitorListOrder */
  schedulePublishExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Schedule to publish one faqQuestion */
  schedulePublishFaqQuestion?: Maybe<FaqQuestion>
  /** Schedule to publish one footer */
  schedulePublishFooter?: Maybe<Footer>
  /** Schedule to publish one glossary */
  schedulePublishGlossary?: Maybe<Glossary>
  /** Schedule to publish one header */
  schedulePublishHeader?: Maybe<Header>
  /** Schedule to publish one hotel */
  schedulePublishHotel?: Maybe<Hotel>
  /** Schedule to publish one htmlEmbed */
  schedulePublishHtmlEmbed?: Maybe<HtmlEmbed>
  /** Schedule to publish one hubPage */
  schedulePublishHubPage?: Maybe<HubPage>
  /** Schedule to publish one imageGalleryEmbed */
  schedulePublishImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Schedule to publish one interest */
  schedulePublishInterest?: Maybe<Interest>
  /** Schedule to publish one jobListing */
  schedulePublishJobListing?: Maybe<JobListing>
  /** Schedule to publish one landingPage */
  schedulePublishLandingPage?: Maybe<LandingPage>
  /** Schedule to publish one manageContentConfig */
  schedulePublishManageContentConfig?: Maybe<ManageContentConfig>
  /** Schedule to publish one mapMarker */
  schedulePublishMapMarker?: Maybe<MapMarker>
  /** Schedule to publish one mapMarkerIcon */
  schedulePublishMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Schedule to publish one newsletterAudience */
  schedulePublishNewsletterAudience?: Maybe<NewsletterAudience>
  /** Schedule to publish one notification */
  schedulePublishNotification?: Maybe<Notification>
  /** Schedule to publish one oMRPartner */
  schedulePublishOMRPartner?: Maybe<OmrPartner>
  /** Schedule to publish one partnerCompany */
  schedulePublishPartnerCompany?: Maybe<PartnerCompany>
  /** Schedule to publish one physicalLocation */
  schedulePublishPhysicalLocation?: Maybe<PhysicalLocation>
  /** Schedule to publish one podcastEpisode */
  schedulePublishPodcastEpisode?: Maybe<PodcastEpisode>
  /** Schedule to publish one podcastGroup */
  schedulePublishPodcastGroup?: Maybe<PodcastGroup>
  /** Schedule to publish one recommendationFood */
  schedulePublishRecommendationFood?: Maybe<RecommendationFood>
  /** Schedule to publish one recommendationNightOut */
  schedulePublishRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Schedule to publish one reviewsAgency */
  schedulePublishReviewsAgency?: Maybe<ReviewsAgency>
  /** Schedule to publish one reviewsProduct */
  schedulePublishReviewsProduct?: Maybe<ReviewsProduct>
  /** Schedule to publish one reviewsProductCategory */
  schedulePublishReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Schedule to publish one reviewsProductWidget */
  schedulePublishReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Schedule to publish one socialEmbed */
  schedulePublishSocialEmbed?: Maybe<SocialEmbed>
  /** Schedule to publish one speaker */
  schedulePublishSpeaker?: Maybe<Speaker>
  /** Schedule to publish one speakerListOrder */
  schedulePublishSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Schedule to publish one tabContent */
  schedulePublishTabContent?: Maybe<TabContent>
  /** Schedule to publish one tag */
  schedulePublishTag?: Maybe<Tag>
  /** Schedule to publish one topicPage */
  schedulePublishTopicPage?: Maybe<TopicPage>
  /** Schedule to publish one topicPageCategory */
  schedulePublishTopicPageCategory?: Maybe<TopicPageCategory>
  /** Schedule to publish one vGWort */
  schedulePublishVGWort?: Maybe<VgWort>
  /** Unpublish one animatedCounter from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAnimatedCounter?: Maybe<AnimatedCounter>
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticle?: Maybe<Article>
  /** Unpublish one articleCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticleCategory?: Maybe<ArticleCategory>
  /** Unpublish one articlePageView from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticlePageView?: Maybe<ArticlePageView>
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAuthor?: Maybe<Author>
  /** Unpublish one booth from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBooth?: Maybe<Booth>
  /** Unpublish one contentReference from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishContentReference?: Maybe<ContentReference>
  /** Unpublish one educationReport from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEducationReport?: Maybe<EducationReport>
  /** Unpublish one educationSeminar from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEducationSeminar?: Maybe<EducationSeminar>
  /** Unpublish one event from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEvent?: Maybe<Event>
  /** Unpublish one eventAppPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEventAppPage?: Maybe<EventAppPage>
  /** Unpublish one eventPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEventPage?: Maybe<EventPage>
  /** Unpublish one eventStage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEventStage?: Maybe<EventStage>
  /** Unpublish one exhibitorListOrder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Unpublish one faqQuestion from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFaqQuestion?: Maybe<FaqQuestion>
  /** Unpublish one footer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFooter?: Maybe<Footer>
  /** Unpublish one glossary from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGlossary?: Maybe<Glossary>
  /** Unpublish one header from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHeader?: Maybe<Header>
  /** Unpublish one hotel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHotel?: Maybe<Hotel>
  /** Unpublish one htmlEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHtmlEmbed?: Maybe<HtmlEmbed>
  /** Unpublish one hubPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishHubPage?: Maybe<HubPage>
  /** Unpublish one imageGalleryEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Unpublish one interest from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishInterest?: Maybe<Interest>
  /** Unpublish one jobListing from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishJobListing?: Maybe<JobListing>
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLandingPage?: Maybe<LandingPage>
  /** Unpublish one manageContentConfig from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishManageContentConfig?: Maybe<ManageContentConfig>
  /** Unpublish one mapMarker from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMapMarker?: Maybe<MapMarker>
  /** Unpublish one mapMarkerIcon from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Unpublish one newsletterAudience from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNewsletterAudience?: Maybe<NewsletterAudience>
  /** Unpublish one notification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNotification?: Maybe<Notification>
  /** Unpublish one oMRPartner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishOMRPartner?: Maybe<OmrPartner>
  /** Unpublish one partnerCompany from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPartnerCompany?: Maybe<PartnerCompany>
  /** Unpublish one physicalLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPhysicalLocation?: Maybe<PhysicalLocation>
  /** Unpublish one podcastEpisode from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPodcastEpisode?: Maybe<PodcastEpisode>
  /** Unpublish one podcastGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPodcastGroup?: Maybe<PodcastGroup>
  /** Unpublish one recommendationFood from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRecommendationFood?: Maybe<RecommendationFood>
  /** Unpublish one recommendationNightOut from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Unpublish one reviewsAgency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishReviewsAgency?: Maybe<ReviewsAgency>
  /** Unpublish one reviewsProduct from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishReviewsProduct?: Maybe<ReviewsProduct>
  /** Unpublish one reviewsProductCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Unpublish one reviewsProductWidget from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Unpublish one socialEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSocialEmbed?: Maybe<SocialEmbed>
  /** Unpublish one speaker from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSpeaker?: Maybe<Speaker>
  /** Unpublish one speakerListOrder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Unpublish one tabContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTabContent?: Maybe<TabContent>
  /** Unpublish one tag from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTag?: Maybe<Tag>
  /** Unpublish one topicPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTopicPage?: Maybe<TopicPage>
  /** Unpublish one topicPageCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTopicPageCategory?: Maybe<TopicPageCategory>
  /** Unpublish one vGWort from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishVGWort?: Maybe<VgWort>
  /** Unpublish one animatedCounter from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAnimatedCounter?: Maybe<AnimatedCounter>
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticle?: Maybe<Article>
  /** Unpublish one articleCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticleCategory?: Maybe<ArticleCategory>
  /** Unpublish one articlePageView from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticlePageView?: Maybe<ArticlePageView>
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAuthor?: Maybe<Author>
  /** Unpublish one booth from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBooth?: Maybe<Booth>
  /** Unpublish one contentReference from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishContentReference?: Maybe<ContentReference>
  /** Unpublish one educationReport from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEducationReport?: Maybe<EducationReport>
  /** Unpublish one educationSeminar from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEducationSeminar?: Maybe<EducationSeminar>
  /** Unpublish one event from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEvent?: Maybe<Event>
  /** Unpublish one eventAppPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEventAppPage?: Maybe<EventAppPage>
  /** Unpublish one eventPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEventPage?: Maybe<EventPage>
  /** Unpublish one eventStage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEventStage?: Maybe<EventStage>
  /** Unpublish one exhibitorListOrder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Unpublish one faqQuestion from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFaqQuestion?: Maybe<FaqQuestion>
  /** Unpublish one footer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFooter?: Maybe<Footer>
  /** Unpublish one glossary from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGlossary?: Maybe<Glossary>
  /** Unpublish one header from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHeader?: Maybe<Header>
  /** Unpublish one hotel from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHotel?: Maybe<Hotel>
  /** Unpublish one htmlEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHtmlEmbed?: Maybe<HtmlEmbed>
  /** Unpublish one hubPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishHubPage?: Maybe<HubPage>
  /** Unpublish one imageGalleryEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Unpublish one interest from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishInterest?: Maybe<Interest>
  /** Unpublish one jobListing from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishJobListing?: Maybe<JobListing>
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLandingPage?: Maybe<LandingPage>
  /** Unpublish one manageContentConfig from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishManageContentConfig?: Maybe<ManageContentConfig>
  /**
   * Unpublish many AnimatedCounter documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAnimatedCountersConnection)
   */
  unpublishManyAnimatedCounters: BatchPayload
  /** Find many AnimatedCounter documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAnimatedCountersConnection: AnimatedCounterConnection
  /**
   * Unpublish many ArticleCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticleCategoriesConnection)
   */
  unpublishManyArticleCategories: BatchPayload
  /** Find many ArticleCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticleCategoriesConnection: ArticleCategoryConnection
  /**
   * Unpublish many ArticlePageView documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlePageViewsConnection)
   */
  unpublishManyArticlePageViews: BatchPayload
  /** Find many ArticlePageView documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlePageViewsConnection: ArticlePageViewConnection
  /**
   * Unpublish many Article documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlesConnection)
   */
  unpublishManyArticles: BatchPayload
  /** Find many Article documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlesConnection: ArticleConnection
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection
  /**
   * Unpublish many Author documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAuthorsConnection)
   */
  unpublishManyAuthors: BatchPayload
  /** Find many Author documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAuthorsConnection: AuthorConnection
  /**
   * Unpublish many Booth documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBoothsConnection)
   */
  unpublishManyBooths: BatchPayload
  /** Find many Booth documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBoothsConnection: BoothConnection
  /**
   * Unpublish many ContentReference documents
   * @deprecated Please use the new paginated many mutation (unpublishManyContentReferencesConnection)
   */
  unpublishManyContentReferences: BatchPayload
  /** Find many ContentReference documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyContentReferencesConnection: ContentReferenceConnection
  /**
   * Unpublish many EducationReport documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEducationReportsConnection)
   */
  unpublishManyEducationReports: BatchPayload
  /** Find many EducationReport documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEducationReportsConnection: EducationReportConnection
  /**
   * Unpublish many EducationSeminar documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEducationSeminarsConnection)
   */
  unpublishManyEducationSeminars: BatchPayload
  /** Find many EducationSeminar documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEducationSeminarsConnection: EducationSeminarConnection
  /**
   * Unpublish many EventAppPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEventAppPagesConnection)
   */
  unpublishManyEventAppPages: BatchPayload
  /** Find many EventAppPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEventAppPagesConnection: EventAppPageConnection
  /**
   * Unpublish many EventPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEventPagesConnection)
   */
  unpublishManyEventPages: BatchPayload
  /** Find many EventPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEventPagesConnection: EventPageConnection
  /**
   * Unpublish many EventStage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEventStagesConnection)
   */
  unpublishManyEventStages: BatchPayload
  /** Find many EventStage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEventStagesConnection: EventStageConnection
  /**
   * Unpublish many Event documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEventsConnection)
   */
  unpublishManyEvents: BatchPayload
  /** Find many Event documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEventsConnection: EventConnection
  /**
   * Unpublish many ExhibitorListOrder documents
   * @deprecated Please use the new paginated many mutation (unpublishManyExhibitorListOrdersConnection)
   */
  unpublishManyExhibitorListOrders: BatchPayload
  /** Find many ExhibitorListOrder documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyExhibitorListOrdersConnection: ExhibitorListOrderConnection
  /**
   * Unpublish many FaqQuestion documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFaqQuestionsConnection)
   */
  unpublishManyFaqQuestions: BatchPayload
  /** Find many FaqQuestion documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFaqQuestionsConnection: FaqQuestionConnection
  /**
   * Unpublish many Footer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFootersConnection)
   */
  unpublishManyFooters: BatchPayload
  /** Find many Footer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFootersConnection: FooterConnection
  /**
   * Unpublish many Glossary documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGlossariesConnection)
   */
  unpublishManyGlossaries: BatchPayload
  /** Find many Glossary documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGlossariesConnection: GlossaryConnection
  /**
   * Unpublish many Header documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHeadersConnection)
   */
  unpublishManyHeaders: BatchPayload
  /** Find many Header documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHeadersConnection: HeaderConnection
  /**
   * Unpublish many Hotel documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHotelsConnection)
   */
  unpublishManyHotels: BatchPayload
  /** Find many Hotel documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHotelsConnection: HotelConnection
  /**
   * Unpublish many HtmlEmbed documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHtmlEmbedsConnection)
   */
  unpublishManyHtmlEmbeds: BatchPayload
  /** Find many HtmlEmbed documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHtmlEmbedsConnection: HtmlEmbedConnection
  /**
   * Unpublish many HubPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyHubPagesConnection)
   */
  unpublishManyHubPages: BatchPayload
  /** Find many HubPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyHubPagesConnection: HubPageConnection
  /**
   * Unpublish many ImageGalleryEmbed documents
   * @deprecated Please use the new paginated many mutation (unpublishManyImageGalleryEmbedsConnection)
   */
  unpublishManyImageGalleryEmbeds: BatchPayload
  /** Find many ImageGalleryEmbed documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyImageGalleryEmbedsConnection: ImageGalleryEmbedConnection
  /**
   * Unpublish many Interest documents
   * @deprecated Please use the new paginated many mutation (unpublishManyInterestsConnection)
   */
  unpublishManyInterests: BatchPayload
  /** Find many Interest documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyInterestsConnection: InterestConnection
  /**
   * Unpublish many JobListing documents
   * @deprecated Please use the new paginated many mutation (unpublishManyJobListingsConnection)
   */
  unpublishManyJobListings: BatchPayload
  /** Find many JobListing documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyJobListingsConnection: JobListingConnection
  /**
   * Unpublish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLandingPagesConnection)
   */
  unpublishManyLandingPages: BatchPayload
  /** Find many LandingPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLandingPagesConnection: LandingPageConnection
  /**
   * Unpublish many ManageContentConfig documents
   * @deprecated Please use the new paginated many mutation (unpublishManyManageContentConfigsConnection)
   */
  unpublishManyManageContentConfigs: BatchPayload
  /** Find many ManageContentConfig documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyManageContentConfigsConnection: ManageContentConfigConnection
  /**
   * Unpublish many MapMarkerIcon documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMapMarkerIconsConnection)
   */
  unpublishManyMapMarkerIcons: BatchPayload
  /** Find many MapMarkerIcon documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMapMarkerIconsConnection: MapMarkerIconConnection
  /**
   * Unpublish many MapMarker documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMapMarkersConnection)
   */
  unpublishManyMapMarkers: BatchPayload
  /** Find many MapMarker documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMapMarkersConnection: MapMarkerConnection
  /**
   * Unpublish many NewsletterAudience documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNewsletterAudiencesConnection)
   */
  unpublishManyNewsletterAudiences: BatchPayload
  /** Find many NewsletterAudience documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNewsletterAudiencesConnection: NewsletterAudienceConnection
  /**
   * Unpublish many Notification documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNotificationsConnection)
   */
  unpublishManyNotifications: BatchPayload
  /** Find many Notification documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNotificationsConnection: NotificationConnection
  /**
   * Unpublish many OMRPartner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyOMRPartnersConnection)
   */
  unpublishManyOMRPartners: BatchPayload
  /** Find many OMRPartner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyOMRPartnersConnection: OmrPartnerConnection
  /**
   * Unpublish many PartnerCompany documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPartnerCompaniesConnection)
   */
  unpublishManyPartnerCompanies: BatchPayload
  /** Find many PartnerCompany documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPartnerCompaniesConnection: PartnerCompanyConnection
  /**
   * Unpublish many PhysicalLocation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPhysicalLocationsConnection)
   */
  unpublishManyPhysicalLocations: BatchPayload
  /** Find many PhysicalLocation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPhysicalLocationsConnection: PhysicalLocationConnection
  /**
   * Unpublish many PodcastEpisode documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPodcastEpisodesConnection)
   */
  unpublishManyPodcastEpisodes: BatchPayload
  /** Find many PodcastEpisode documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPodcastEpisodesConnection: PodcastEpisodeConnection
  /**
   * Unpublish many PodcastGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPodcastGroupsConnection)
   */
  unpublishManyPodcastGroups: BatchPayload
  /** Find many PodcastGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPodcastGroupsConnection: PodcastGroupConnection
  /**
   * Unpublish many RecommendationFood documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRecommendationsFoodConnection)
   */
  unpublishManyRecommendationsFood: BatchPayload
  /** Find many RecommendationFood documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRecommendationsFoodConnection: RecommendationFoodConnection
  /**
   * Unpublish many RecommendationNightOut documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRecommendationsNightOutConnection)
   */
  unpublishManyRecommendationsNightOut: BatchPayload
  /** Find many RecommendationNightOut documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRecommendationsNightOutConnection: RecommendationNightOutConnection
  /**
   * Unpublish many ReviewsAgency documents
   * @deprecated Please use the new paginated many mutation (unpublishManyReviewsAgenciesConnection)
   */
  unpublishManyReviewsAgencies: BatchPayload
  /** Find many ReviewsAgency documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyReviewsAgenciesConnection: ReviewsAgencyConnection
  /**
   * Unpublish many ReviewsProductCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyReviewsProductCategoriesConnection)
   */
  unpublishManyReviewsProductCategories: BatchPayload
  /** Find many ReviewsProductCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyReviewsProductCategoriesConnection: ReviewsProductCategoryConnection
  /**
   * Unpublish many ReviewsProductWidget documents
   * @deprecated Please use the new paginated many mutation (unpublishManyReviewsProductWidgetsConnection)
   */
  unpublishManyReviewsProductWidgets: BatchPayload
  /** Find many ReviewsProductWidget documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyReviewsProductWidgetsConnection: ReviewsProductWidgetConnection
  /**
   * Unpublish many ReviewsProduct documents
   * @deprecated Please use the new paginated many mutation (unpublishManyReviewsProductsConnection)
   */
  unpublishManyReviewsProducts: BatchPayload
  /** Find many ReviewsProduct documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyReviewsProductsConnection: ReviewsProductConnection
  /**
   * Unpublish many SocialEmbed documents
   * @deprecated Please use the new paginated many mutation (unpublishManySocialEmbedsConnection)
   */
  unpublishManySocialEmbeds: BatchPayload
  /** Find many SocialEmbed documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySocialEmbedsConnection: SocialEmbedConnection
  /**
   * Unpublish many SpeakerListOrder documents
   * @deprecated Please use the new paginated many mutation (unpublishManySpeakerListOrdersConnection)
   */
  unpublishManySpeakerListOrders: BatchPayload
  /** Find many SpeakerListOrder documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySpeakerListOrdersConnection: SpeakerListOrderConnection
  /**
   * Unpublish many Speaker documents
   * @deprecated Please use the new paginated many mutation (unpublishManySpeakersConnection)
   */
  unpublishManySpeakers: BatchPayload
  /** Find many Speaker documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySpeakersConnection: SpeakerConnection
  /**
   * Unpublish many TabContent documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTabContentsConnection)
   */
  unpublishManyTabContents: BatchPayload
  /** Find many TabContent documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTabContentsConnection: TabContentConnection
  /**
   * Unpublish many Tag documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTagsConnection)
   */
  unpublishManyTags: BatchPayload
  /** Find many Tag documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTagsConnection: TagConnection
  /**
   * Unpublish many TopicPageCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTopicPageCategoriesConnection)
   */
  unpublishManyTopicPageCategories: BatchPayload
  /** Find many TopicPageCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTopicPageCategoriesConnection: TopicPageCategoryConnection
  /**
   * Unpublish many TopicPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTopicPagesConnection)
   */
  unpublishManyTopicPages: BatchPayload
  /** Find many TopicPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTopicPagesConnection: TopicPageConnection
  /**
   * Unpublish many VGWort documents
   * @deprecated Please use the new paginated many mutation (unpublishManyVGWortsConnection)
   */
  unpublishManyVGWorts: BatchPayload
  /** Find many VGWort documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyVGWortsConnection: VgWortConnection
  /** Unpublish one mapMarker from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMapMarker?: Maybe<MapMarker>
  /** Unpublish one mapMarkerIcon from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Unpublish one newsletterAudience from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNewsletterAudience?: Maybe<NewsletterAudience>
  /** Unpublish one notification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNotification?: Maybe<Notification>
  /** Unpublish one oMRPartner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishOMRPartner?: Maybe<OmrPartner>
  /** Unpublish one partnerCompany from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPartnerCompany?: Maybe<PartnerCompany>
  /** Unpublish one physicalLocation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPhysicalLocation?: Maybe<PhysicalLocation>
  /** Unpublish one podcastEpisode from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPodcastEpisode?: Maybe<PodcastEpisode>
  /** Unpublish one podcastGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPodcastGroup?: Maybe<PodcastGroup>
  /** Unpublish one recommendationFood from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRecommendationFood?: Maybe<RecommendationFood>
  /** Unpublish one recommendationNightOut from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Unpublish one reviewsAgency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishReviewsAgency?: Maybe<ReviewsAgency>
  /** Unpublish one reviewsProduct from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishReviewsProduct?: Maybe<ReviewsProduct>
  /** Unpublish one reviewsProductCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Unpublish one reviewsProductWidget from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Unpublish one socialEmbed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSocialEmbed?: Maybe<SocialEmbed>
  /** Unpublish one speaker from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSpeaker?: Maybe<Speaker>
  /** Unpublish one speakerListOrder from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Unpublish one tabContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTabContent?: Maybe<TabContent>
  /** Unpublish one tag from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTag?: Maybe<Tag>
  /** Unpublish one topicPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTopicPage?: Maybe<TopicPage>
  /** Unpublish one topicPageCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTopicPageCategory?: Maybe<TopicPageCategory>
  /** Unpublish one vGWort from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishVGWort?: Maybe<VgWort>
  /** Update one animatedCounter */
  updateAnimatedCounter?: Maybe<AnimatedCounter>
  /** Update one article */
  updateArticle?: Maybe<Article>
  /** Update one articleCategory */
  updateArticleCategory?: Maybe<ArticleCategory>
  /** Update one articlePageView */
  updateArticlePageView?: Maybe<ArticlePageView>
  /** Update one asset */
  updateAsset?: Maybe<Asset>
  /** Update one author */
  updateAuthor?: Maybe<Author>
  /** Update one booth */
  updateBooth?: Maybe<Booth>
  /** Update one contentReference */
  updateContentReference?: Maybe<ContentReference>
  /** Update one educationReport */
  updateEducationReport?: Maybe<EducationReport>
  /** Update one educationSeminar */
  updateEducationSeminar?: Maybe<EducationSeminar>
  /** Update one event */
  updateEvent?: Maybe<Event>
  /** Update one eventAppPage */
  updateEventAppPage?: Maybe<EventAppPage>
  /** Update one eventPage */
  updateEventPage?: Maybe<EventPage>
  /** Update one eventStage */
  updateEventStage?: Maybe<EventStage>
  /** Update one exhibitorListOrder */
  updateExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Update one faqQuestion */
  updateFaqQuestion?: Maybe<FaqQuestion>
  /** Update one footer */
  updateFooter?: Maybe<Footer>
  /** Update one glossary */
  updateGlossary?: Maybe<Glossary>
  /** Update one header */
  updateHeader?: Maybe<Header>
  /** Update one hotel */
  updateHotel?: Maybe<Hotel>
  /** Update one htmlEmbed */
  updateHtmlEmbed?: Maybe<HtmlEmbed>
  /** Update one hubPage */
  updateHubPage?: Maybe<HubPage>
  /** Update one imageGalleryEmbed */
  updateImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Update one interest */
  updateInterest?: Maybe<Interest>
  /** Update one jobListing */
  updateJobListing?: Maybe<JobListing>
  /** Update one landingPage */
  updateLandingPage?: Maybe<LandingPage>
  /** Update one manageContentConfig */
  updateManageContentConfig?: Maybe<ManageContentConfig>
  /**
   * Update many animatedCounters
   * @deprecated Please use the new paginated many mutation (updateManyAnimatedCountersConnection)
   */
  updateManyAnimatedCounters: BatchPayload
  /** Update many AnimatedCounter documents */
  updateManyAnimatedCountersConnection: AnimatedCounterConnection
  /**
   * Update many articleCategories
   * @deprecated Please use the new paginated many mutation (updateManyArticleCategoriesConnection)
   */
  updateManyArticleCategories: BatchPayload
  /** Update many ArticleCategory documents */
  updateManyArticleCategoriesConnection: ArticleCategoryConnection
  /**
   * Update many articlePageViews
   * @deprecated Please use the new paginated many mutation (updateManyArticlePageViewsConnection)
   */
  updateManyArticlePageViews: BatchPayload
  /** Update many ArticlePageView documents */
  updateManyArticlePageViewsConnection: ArticlePageViewConnection
  /**
   * Update many articles
   * @deprecated Please use the new paginated many mutation (updateManyArticlesConnection)
   */
  updateManyArticles: BatchPayload
  /** Update many Article documents */
  updateManyArticlesConnection: ArticleConnection
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection
  /**
   * Update many authors
   * @deprecated Please use the new paginated many mutation (updateManyAuthorsConnection)
   */
  updateManyAuthors: BatchPayload
  /** Update many Author documents */
  updateManyAuthorsConnection: AuthorConnection
  /**
   * Update many booths
   * @deprecated Please use the new paginated many mutation (updateManyBoothsConnection)
   */
  updateManyBooths: BatchPayload
  /** Update many Booth documents */
  updateManyBoothsConnection: BoothConnection
  /**
   * Update many contentReferences
   * @deprecated Please use the new paginated many mutation (updateManyContentReferencesConnection)
   */
  updateManyContentReferences: BatchPayload
  /** Update many ContentReference documents */
  updateManyContentReferencesConnection: ContentReferenceConnection
  /**
   * Update many educationReports
   * @deprecated Please use the new paginated many mutation (updateManyEducationReportsConnection)
   */
  updateManyEducationReports: BatchPayload
  /** Update many EducationReport documents */
  updateManyEducationReportsConnection: EducationReportConnection
  /**
   * Update many educationSeminars
   * @deprecated Please use the new paginated many mutation (updateManyEducationSeminarsConnection)
   */
  updateManyEducationSeminars: BatchPayload
  /** Update many EducationSeminar documents */
  updateManyEducationSeminarsConnection: EducationSeminarConnection
  /**
   * Update many eventAppPages
   * @deprecated Please use the new paginated many mutation (updateManyEventAppPagesConnection)
   */
  updateManyEventAppPages: BatchPayload
  /** Update many EventAppPage documents */
  updateManyEventAppPagesConnection: EventAppPageConnection
  /**
   * Update many eventPages
   * @deprecated Please use the new paginated many mutation (updateManyEventPagesConnection)
   */
  updateManyEventPages: BatchPayload
  /** Update many EventPage documents */
  updateManyEventPagesConnection: EventPageConnection
  /**
   * Update many eventStages
   * @deprecated Please use the new paginated many mutation (updateManyEventStagesConnection)
   */
  updateManyEventStages: BatchPayload
  /** Update many EventStage documents */
  updateManyEventStagesConnection: EventStageConnection
  /**
   * Update many events
   * @deprecated Please use the new paginated many mutation (updateManyEventsConnection)
   */
  updateManyEvents: BatchPayload
  /** Update many Event documents */
  updateManyEventsConnection: EventConnection
  /**
   * Update many exhibitorListOrders
   * @deprecated Please use the new paginated many mutation (updateManyExhibitorListOrdersConnection)
   */
  updateManyExhibitorListOrders: BatchPayload
  /** Update many ExhibitorListOrder documents */
  updateManyExhibitorListOrdersConnection: ExhibitorListOrderConnection
  /**
   * Update many faqQuestions
   * @deprecated Please use the new paginated many mutation (updateManyFaqQuestionsConnection)
   */
  updateManyFaqQuestions: BatchPayload
  /** Update many FaqQuestion documents */
  updateManyFaqQuestionsConnection: FaqQuestionConnection
  /**
   * Update many footers
   * @deprecated Please use the new paginated many mutation (updateManyFootersConnection)
   */
  updateManyFooters: BatchPayload
  /** Update many Footer documents */
  updateManyFootersConnection: FooterConnection
  /**
   * Update many glossaries
   * @deprecated Please use the new paginated many mutation (updateManyGlossariesConnection)
   */
  updateManyGlossaries: BatchPayload
  /** Update many Glossary documents */
  updateManyGlossariesConnection: GlossaryConnection
  /**
   * Update many headers
   * @deprecated Please use the new paginated many mutation (updateManyHeadersConnection)
   */
  updateManyHeaders: BatchPayload
  /** Update many Header documents */
  updateManyHeadersConnection: HeaderConnection
  /**
   * Update many hotels
   * @deprecated Please use the new paginated many mutation (updateManyHotelsConnection)
   */
  updateManyHotels: BatchPayload
  /** Update many Hotel documents */
  updateManyHotelsConnection: HotelConnection
  /**
   * Update many htmlEmbeds
   * @deprecated Please use the new paginated many mutation (updateManyHtmlEmbedsConnection)
   */
  updateManyHtmlEmbeds: BatchPayload
  /** Update many HtmlEmbed documents */
  updateManyHtmlEmbedsConnection: HtmlEmbedConnection
  /**
   * Update many hubPages
   * @deprecated Please use the new paginated many mutation (updateManyHubPagesConnection)
   */
  updateManyHubPages: BatchPayload
  /** Update many HubPage documents */
  updateManyHubPagesConnection: HubPageConnection
  /**
   * Update many imageGalleryEmbeds
   * @deprecated Please use the new paginated many mutation (updateManyImageGalleryEmbedsConnection)
   */
  updateManyImageGalleryEmbeds: BatchPayload
  /** Update many ImageGalleryEmbed documents */
  updateManyImageGalleryEmbedsConnection: ImageGalleryEmbedConnection
  /**
   * Update many interests
   * @deprecated Please use the new paginated many mutation (updateManyInterestsConnection)
   */
  updateManyInterests: BatchPayload
  /** Update many Interest documents */
  updateManyInterestsConnection: InterestConnection
  /**
   * Update many jobListings
   * @deprecated Please use the new paginated many mutation (updateManyJobListingsConnection)
   */
  updateManyJobListings: BatchPayload
  /** Update many JobListing documents */
  updateManyJobListingsConnection: JobListingConnection
  /**
   * Update many landingPages
   * @deprecated Please use the new paginated many mutation (updateManyLandingPagesConnection)
   */
  updateManyLandingPages: BatchPayload
  /** Update many LandingPage documents */
  updateManyLandingPagesConnection: LandingPageConnection
  /**
   * Update many manageContentConfigs
   * @deprecated Please use the new paginated many mutation (updateManyManageContentConfigsConnection)
   */
  updateManyManageContentConfigs: BatchPayload
  /** Update many ManageContentConfig documents */
  updateManyManageContentConfigsConnection: ManageContentConfigConnection
  /**
   * Update many mapMarkerIcons
   * @deprecated Please use the new paginated many mutation (updateManyMapMarkerIconsConnection)
   */
  updateManyMapMarkerIcons: BatchPayload
  /** Update many MapMarkerIcon documents */
  updateManyMapMarkerIconsConnection: MapMarkerIconConnection
  /**
   * Update many mapMarkers
   * @deprecated Please use the new paginated many mutation (updateManyMapMarkersConnection)
   */
  updateManyMapMarkers: BatchPayload
  /** Update many MapMarker documents */
  updateManyMapMarkersConnection: MapMarkerConnection
  /**
   * Update many newsletterAudiences
   * @deprecated Please use the new paginated many mutation (updateManyNewsletterAudiencesConnection)
   */
  updateManyNewsletterAudiences: BatchPayload
  /** Update many NewsletterAudience documents */
  updateManyNewsletterAudiencesConnection: NewsletterAudienceConnection
  /**
   * Update many notifications
   * @deprecated Please use the new paginated many mutation (updateManyNotificationsConnection)
   */
  updateManyNotifications: BatchPayload
  /** Update many Notification documents */
  updateManyNotificationsConnection: NotificationConnection
  /**
   * Update many oMRPartners
   * @deprecated Please use the new paginated many mutation (updateManyOMRPartnersConnection)
   */
  updateManyOMRPartners: BatchPayload
  /** Update many OMRPartner documents */
  updateManyOMRPartnersConnection: OmrPartnerConnection
  /**
   * Update many partnerCompanies
   * @deprecated Please use the new paginated many mutation (updateManyPartnerCompaniesConnection)
   */
  updateManyPartnerCompanies: BatchPayload
  /** Update many PartnerCompany documents */
  updateManyPartnerCompaniesConnection: PartnerCompanyConnection
  /**
   * Update many physicalLocations
   * @deprecated Please use the new paginated many mutation (updateManyPhysicalLocationsConnection)
   */
  updateManyPhysicalLocations: BatchPayload
  /** Update many PhysicalLocation documents */
  updateManyPhysicalLocationsConnection: PhysicalLocationConnection
  /**
   * Update many podcastEpisodes
   * @deprecated Please use the new paginated many mutation (updateManyPodcastEpisodesConnection)
   */
  updateManyPodcastEpisodes: BatchPayload
  /** Update many PodcastEpisode documents */
  updateManyPodcastEpisodesConnection: PodcastEpisodeConnection
  /**
   * Update many podcastGroups
   * @deprecated Please use the new paginated many mutation (updateManyPodcastGroupsConnection)
   */
  updateManyPodcastGroups: BatchPayload
  /** Update many PodcastGroup documents */
  updateManyPodcastGroupsConnection: PodcastGroupConnection
  /**
   * Update many recommendationsFood
   * @deprecated Please use the new paginated many mutation (updateManyRecommendationsFoodConnection)
   */
  updateManyRecommendationsFood: BatchPayload
  /** Update many RecommendationFood documents */
  updateManyRecommendationsFoodConnection: RecommendationFoodConnection
  /**
   * Update many recommendationsNightOut
   * @deprecated Please use the new paginated many mutation (updateManyRecommendationsNightOutConnection)
   */
  updateManyRecommendationsNightOut: BatchPayload
  /** Update many RecommendationNightOut documents */
  updateManyRecommendationsNightOutConnection: RecommendationNightOutConnection
  /**
   * Update many reviewsAgencies
   * @deprecated Please use the new paginated many mutation (updateManyReviewsAgenciesConnection)
   */
  updateManyReviewsAgencies: BatchPayload
  /** Update many ReviewsAgency documents */
  updateManyReviewsAgenciesConnection: ReviewsAgencyConnection
  /**
   * Update many reviewsProductCategories
   * @deprecated Please use the new paginated many mutation (updateManyReviewsProductCategoriesConnection)
   */
  updateManyReviewsProductCategories: BatchPayload
  /** Update many ReviewsProductCategory documents */
  updateManyReviewsProductCategoriesConnection: ReviewsProductCategoryConnection
  /**
   * Update many reviewsProductWidgets
   * @deprecated Please use the new paginated many mutation (updateManyReviewsProductWidgetsConnection)
   */
  updateManyReviewsProductWidgets: BatchPayload
  /** Update many ReviewsProductWidget documents */
  updateManyReviewsProductWidgetsConnection: ReviewsProductWidgetConnection
  /**
   * Update many reviewsProducts
   * @deprecated Please use the new paginated many mutation (updateManyReviewsProductsConnection)
   */
  updateManyReviewsProducts: BatchPayload
  /** Update many ReviewsProduct documents */
  updateManyReviewsProductsConnection: ReviewsProductConnection
  /**
   * Update many socialEmbeds
   * @deprecated Please use the new paginated many mutation (updateManySocialEmbedsConnection)
   */
  updateManySocialEmbeds: BatchPayload
  /** Update many SocialEmbed documents */
  updateManySocialEmbedsConnection: SocialEmbedConnection
  /**
   * Update many speakerListOrders
   * @deprecated Please use the new paginated many mutation (updateManySpeakerListOrdersConnection)
   */
  updateManySpeakerListOrders: BatchPayload
  /** Update many SpeakerListOrder documents */
  updateManySpeakerListOrdersConnection: SpeakerListOrderConnection
  /**
   * Update many speakers
   * @deprecated Please use the new paginated many mutation (updateManySpeakersConnection)
   */
  updateManySpeakers: BatchPayload
  /** Update many Speaker documents */
  updateManySpeakersConnection: SpeakerConnection
  /**
   * Update many tabContents
   * @deprecated Please use the new paginated many mutation (updateManyTabContentsConnection)
   */
  updateManyTabContents: BatchPayload
  /** Update many TabContent documents */
  updateManyTabContentsConnection: TabContentConnection
  /**
   * Update many tags
   * @deprecated Please use the new paginated many mutation (updateManyTagsConnection)
   */
  updateManyTags: BatchPayload
  /** Update many Tag documents */
  updateManyTagsConnection: TagConnection
  /**
   * Update many topicPageCategories
   * @deprecated Please use the new paginated many mutation (updateManyTopicPageCategoriesConnection)
   */
  updateManyTopicPageCategories: BatchPayload
  /** Update many TopicPageCategory documents */
  updateManyTopicPageCategoriesConnection: TopicPageCategoryConnection
  /**
   * Update many topicPages
   * @deprecated Please use the new paginated many mutation (updateManyTopicPagesConnection)
   */
  updateManyTopicPages: BatchPayload
  /** Update many TopicPage documents */
  updateManyTopicPagesConnection: TopicPageConnection
  /**
   * Update many vGWorts
   * @deprecated Please use the new paginated many mutation (updateManyVGWortsConnection)
   */
  updateManyVGWorts: BatchPayload
  /** Update many VGWort documents */
  updateManyVGWortsConnection: VgWortConnection
  /** Update one mapMarker */
  updateMapMarker?: Maybe<MapMarker>
  /** Update one mapMarkerIcon */
  updateMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Update one newsletterAudience */
  updateNewsletterAudience?: Maybe<NewsletterAudience>
  /** Update one notification */
  updateNotification?: Maybe<Notification>
  /** Update one oMRPartner */
  updateOMRPartner?: Maybe<OmrPartner>
  /** Update one partnerCompany */
  updatePartnerCompany?: Maybe<PartnerCompany>
  /** Update one physicalLocation */
  updatePhysicalLocation?: Maybe<PhysicalLocation>
  /** Update one podcastEpisode */
  updatePodcastEpisode?: Maybe<PodcastEpisode>
  /** Update one podcastGroup */
  updatePodcastGroup?: Maybe<PodcastGroup>
  /** Update one recommendationFood */
  updateRecommendationFood?: Maybe<RecommendationFood>
  /** Update one recommendationNightOut */
  updateRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Update one reviewsAgency */
  updateReviewsAgency?: Maybe<ReviewsAgency>
  /** Update one reviewsProduct */
  updateReviewsProduct?: Maybe<ReviewsProduct>
  /** Update one reviewsProductCategory */
  updateReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Update one reviewsProductWidget */
  updateReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>
  /** Update one socialEmbed */
  updateSocialEmbed?: Maybe<SocialEmbed>
  /** Update one speaker */
  updateSpeaker?: Maybe<Speaker>
  /** Update one speakerListOrder */
  updateSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Update one tabContent */
  updateTabContent?: Maybe<TabContent>
  /** Update one tag */
  updateTag?: Maybe<Tag>
  /** Update one topicPage */
  updateTopicPage?: Maybe<TopicPage>
  /** Update one topicPageCategory */
  updateTopicPageCategory?: Maybe<TopicPageCategory>
  /** Update one vGWort */
  updateVGWort?: Maybe<VgWort>
  /** Upsert one animatedCounter */
  upsertAnimatedCounter?: Maybe<AnimatedCounter>
  /** Upsert one article */
  upsertArticle?: Maybe<Article>
  /** Upsert one articleCategory */
  upsertArticleCategory?: Maybe<ArticleCategory>
  /** Upsert one articlePageView */
  upsertArticlePageView?: Maybe<ArticlePageView>
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>
  /** Upsert one author */
  upsertAuthor?: Maybe<Author>
  /** Upsert one booth */
  upsertBooth?: Maybe<Booth>
  /** Upsert one contentReference */
  upsertContentReference?: Maybe<ContentReference>
  /** Upsert one educationReport */
  upsertEducationReport?: Maybe<EducationReport>
  /** Upsert one educationSeminar */
  upsertEducationSeminar?: Maybe<EducationSeminar>
  /** Upsert one event */
  upsertEvent?: Maybe<Event>
  /** Upsert one eventAppPage */
  upsertEventAppPage?: Maybe<EventAppPage>
  /** Upsert one eventPage */
  upsertEventPage?: Maybe<EventPage>
  /** Upsert one eventStage */
  upsertEventStage?: Maybe<EventStage>
  /** Upsert one exhibitorListOrder */
  upsertExhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Upsert one faqQuestion */
  upsertFaqQuestion?: Maybe<FaqQuestion>
  /** Upsert one footer */
  upsertFooter?: Maybe<Footer>
  /** Upsert one glossary */
  upsertGlossary?: Maybe<Glossary>
  /** Upsert one header */
  upsertHeader?: Maybe<Header>
  /** Upsert one hotel */
  upsertHotel?: Maybe<Hotel>
  /** Upsert one htmlEmbed */
  upsertHtmlEmbed?: Maybe<HtmlEmbed>
  /** Upsert one hubPage */
  upsertHubPage?: Maybe<HubPage>
  /** Upsert one imageGalleryEmbed */
  upsertImageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Upsert one interest */
  upsertInterest?: Maybe<Interest>
  /** Upsert one jobListing */
  upsertJobListing?: Maybe<JobListing>
  /** Upsert one landingPage */
  upsertLandingPage?: Maybe<LandingPage>
  /** Upsert one manageContentConfig */
  upsertManageContentConfig?: Maybe<ManageContentConfig>
  /** Upsert one mapMarker */
  upsertMapMarker?: Maybe<MapMarker>
  /** Upsert one mapMarkerIcon */
  upsertMapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Upsert one newsletterAudience */
  upsertNewsletterAudience?: Maybe<NewsletterAudience>
  /** Upsert one notification */
  upsertNotification?: Maybe<Notification>
  /** Upsert one oMRPartner */
  upsertOMRPartner?: Maybe<OmrPartner>
  /** Upsert one partnerCompany */
  upsertPartnerCompany?: Maybe<PartnerCompany>
  /** Upsert one physicalLocation */
  upsertPhysicalLocation?: Maybe<PhysicalLocation>
  /** Upsert one podcastEpisode */
  upsertPodcastEpisode?: Maybe<PodcastEpisode>
  /** Upsert one podcastGroup */
  upsertPodcastGroup?: Maybe<PodcastGroup>
  /** Upsert one recommendationFood */
  upsertRecommendationFood?: Maybe<RecommendationFood>
  /** Upsert one recommendationNightOut */
  upsertRecommendationNightOut?: Maybe<RecommendationNightOut>
  /** Upsert one reviewsAgency */
  upsertReviewsAgency?: Maybe<ReviewsAgency>
  /** Upsert one reviewsProduct */
  upsertReviewsProduct?: Maybe<ReviewsProduct>
  /** Upsert one reviewsProductCategory */
  upsertReviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Upsert one reviewsProductWidget */
  upsertReviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Upsert one socialEmbed */
  upsertSocialEmbed?: Maybe<SocialEmbed>
  /** Upsert one speaker */
  upsertSpeaker?: Maybe<Speaker>
  /** Upsert one speakerListOrder */
  upsertSpeakerListOrder?: Maybe<SpeakerListOrder>
  /** Upsert one tabContent */
  upsertTabContent?: Maybe<TabContent>
  /** Upsert one tag */
  upsertTag?: Maybe<Tag>
  /** Upsert one topicPage */
  upsertTopicPage?: Maybe<TopicPage>
  /** Upsert one topicPageCategory */
  upsertTopicPageCategory?: Maybe<TopicPageCategory>
  /** Upsert one vGWort */
  upsertVGWort?: Maybe<VgWort>
}

export type MutationCreateAnimatedCounterArgs = {
  data: AnimatedCounterCreateInput
}

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput
}

export type MutationCreateArticleCategoryArgs = {
  data: ArticleCategoryCreateInput
}

export type MutationCreateArticlePageViewArgs = {
  data: ArticlePageViewCreateInput
}

export type MutationCreateAssetArgs = {
  data: AssetCreateInput
}

export type MutationCreateAuthorArgs = {
  data: AuthorCreateInput
}

export type MutationCreateBoothArgs = {
  data: BoothCreateInput
}

export type MutationCreateContentReferenceArgs = {
  data: ContentReferenceCreateInput
}

export type MutationCreateEducationReportArgs = {
  data: EducationReportCreateInput
}

export type MutationCreateEducationSeminarArgs = {
  data: EducationSeminarCreateInput
}

export type MutationCreateEventArgs = {
  data: EventCreateInput
}

export type MutationCreateEventAppPageArgs = {
  data: EventAppPageCreateInput
}

export type MutationCreateEventPageArgs = {
  data: EventPageCreateInput
}

export type MutationCreateEventStageArgs = {
  data: EventStageCreateInput
}

export type MutationCreateExhibitorListOrderArgs = {
  data: ExhibitorListOrderCreateInput
}

export type MutationCreateFaqQuestionArgs = {
  data: FaqQuestionCreateInput
}

export type MutationCreateFooterArgs = {
  data: FooterCreateInput
}

export type MutationCreateGlossaryArgs = {
  data: GlossaryCreateInput
}

export type MutationCreateHeaderArgs = {
  data: HeaderCreateInput
}

export type MutationCreateHotelArgs = {
  data: HotelCreateInput
}

export type MutationCreateHtmlEmbedArgs = {
  data: HtmlEmbedCreateInput
}

export type MutationCreateHubPageArgs = {
  data: HubPageCreateInput
}

export type MutationCreateImageGalleryEmbedArgs = {
  data: ImageGalleryEmbedCreateInput
}

export type MutationCreateInterestArgs = {
  data: InterestCreateInput
}

export type MutationCreateJobListingArgs = {
  data: JobListingCreateInput
}

export type MutationCreateLandingPageArgs = {
  data: LandingPageCreateInput
}

export type MutationCreateManageContentConfigArgs = {
  data: ManageContentConfigCreateInput
}

export type MutationCreateMapMarkerArgs = {
  data: MapMarkerCreateInput
}

export type MutationCreateMapMarkerIconArgs = {
  data: MapMarkerIconCreateInput
}

export type MutationCreateNewsletterAudienceArgs = {
  data: NewsletterAudienceCreateInput
}

export type MutationCreateNotificationArgs = {
  data: NotificationCreateInput
}

export type MutationCreateOmrPartnerArgs = {
  data: OmrPartnerCreateInput
}

export type MutationCreatePartnerCompanyArgs = {
  data: PartnerCompanyCreateInput
}

export type MutationCreatePhysicalLocationArgs = {
  data: PhysicalLocationCreateInput
}

export type MutationCreatePodcastEpisodeArgs = {
  data: PodcastEpisodeCreateInput
}

export type MutationCreatePodcastGroupArgs = {
  data: PodcastGroupCreateInput
}

export type MutationCreateRecommendationFoodArgs = {
  data: RecommendationFoodCreateInput
}

export type MutationCreateRecommendationNightOutArgs = {
  data: RecommendationNightOutCreateInput
}

export type MutationCreateReviewsAgencyArgs = {
  data: ReviewsAgencyCreateInput
}

export type MutationCreateReviewsProductArgs = {
  data: ReviewsProductCreateInput
}

export type MutationCreateReviewsProductCategoryArgs = {
  data: ReviewsProductCategoryCreateInput
}

export type MutationCreateReviewsProductWidgetArgs = {
  data: ReviewsProductWidgetCreateInput
}

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput
}

export type MutationCreateSocialEmbedArgs = {
  data: SocialEmbedCreateInput
}

export type MutationCreateSpeakerArgs = {
  data: SpeakerCreateInput
}

export type MutationCreateSpeakerListOrderArgs = {
  data: SpeakerListOrderCreateInput
}

export type MutationCreateTabContentArgs = {
  data: TabContentCreateInput
}

export type MutationCreateTagArgs = {
  data: TagCreateInput
}

export type MutationCreateTopicPageArgs = {
  data: TopicPageCreateInput
}

export type MutationCreateTopicPageCategoryArgs = {
  data: TopicPageCategoryCreateInput
}

export type MutationCreateVgWortArgs = {
  data: VgWortCreateInput
}

export type MutationDeleteAnimatedCounterArgs = {
  where: AnimatedCounterWhereUniqueInput
}

export type MutationDeleteArticleArgs = {
  where: ArticleWhereUniqueInput
}

export type MutationDeleteArticleCategoryArgs = {
  where: ArticleCategoryWhereUniqueInput
}

export type MutationDeleteArticlePageViewArgs = {
  where: ArticlePageViewWhereUniqueInput
}

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput
}

export type MutationDeleteAuthorArgs = {
  where: AuthorWhereUniqueInput
}

export type MutationDeleteBoothArgs = {
  where: BoothWhereUniqueInput
}

export type MutationDeleteContentReferenceArgs = {
  where: ContentReferenceWhereUniqueInput
}

export type MutationDeleteEducationReportArgs = {
  where: EducationReportWhereUniqueInput
}

export type MutationDeleteEducationSeminarArgs = {
  where: EducationSeminarWhereUniqueInput
}

export type MutationDeleteEventArgs = {
  where: EventWhereUniqueInput
}

export type MutationDeleteEventAppPageArgs = {
  where: EventAppPageWhereUniqueInput
}

export type MutationDeleteEventPageArgs = {
  where: EventPageWhereUniqueInput
}

export type MutationDeleteEventStageArgs = {
  where: EventStageWhereUniqueInput
}

export type MutationDeleteExhibitorListOrderArgs = {
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationDeleteFaqQuestionArgs = {
  where: FaqQuestionWhereUniqueInput
}

export type MutationDeleteFooterArgs = {
  where: FooterWhereUniqueInput
}

export type MutationDeleteGlossaryArgs = {
  where: GlossaryWhereUniqueInput
}

export type MutationDeleteHeaderArgs = {
  where: HeaderWhereUniqueInput
}

export type MutationDeleteHotelArgs = {
  where: HotelWhereUniqueInput
}

export type MutationDeleteHtmlEmbedArgs = {
  where: HtmlEmbedWhereUniqueInput
}

export type MutationDeleteHubPageArgs = {
  where: HubPageWhereUniqueInput
}

export type MutationDeleteImageGalleryEmbedArgs = {
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationDeleteInterestArgs = {
  where: InterestWhereUniqueInput
}

export type MutationDeleteJobListingArgs = {
  where: JobListingWhereUniqueInput
}

export type MutationDeleteLandingPageArgs = {
  where: LandingPageWhereUniqueInput
}

export type MutationDeleteManageContentConfigArgs = {
  where: ManageContentConfigWhereUniqueInput
}

export type MutationDeleteManyAnimatedCountersArgs = {
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationDeleteManyAnimatedCountersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationDeleteManyArticleCategoriesArgs = {
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationDeleteManyArticleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationDeleteManyArticlePageViewsArgs = {
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationDeleteManyArticlePageViewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationDeleteManyArticlesArgs = {
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationDeleteManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationDeleteManyAuthorsArgs = {
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationDeleteManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationDeleteManyBoothsArgs = {
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationDeleteManyBoothsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationDeleteManyContentReferencesArgs = {
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationDeleteManyContentReferencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationDeleteManyEducationReportsArgs = {
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationDeleteManyEducationReportsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationDeleteManyEducationSeminarsArgs = {
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationDeleteManyEducationSeminarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationDeleteManyEventAppPagesArgs = {
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationDeleteManyEventAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationDeleteManyEventPagesArgs = {
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationDeleteManyEventPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationDeleteManyEventStagesArgs = {
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationDeleteManyEventStagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationDeleteManyEventsArgs = {
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationDeleteManyEventsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationDeleteManyExhibitorListOrdersArgs = {
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationDeleteManyExhibitorListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationDeleteManyFaqQuestionsArgs = {
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationDeleteManyFaqQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationDeleteManyFootersArgs = {
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationDeleteManyFootersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationDeleteManyGlossariesArgs = {
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationDeleteManyGlossariesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationDeleteManyHeadersArgs = {
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationDeleteManyHeadersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationDeleteManyHotelsArgs = {
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationDeleteManyHotelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationDeleteManyHtmlEmbedsArgs = {
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationDeleteManyHtmlEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationDeleteManyHubPagesArgs = {
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationDeleteManyHubPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationDeleteManyImageGalleryEmbedsArgs = {
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationDeleteManyImageGalleryEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationDeleteManyInterestsArgs = {
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationDeleteManyInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationDeleteManyJobListingsArgs = {
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationDeleteManyJobListingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationDeleteManyLandingPagesArgs = {
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationDeleteManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationDeleteManyManageContentConfigsArgs = {
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationDeleteManyManageContentConfigsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationDeleteManyMapMarkerIconsArgs = {
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationDeleteManyMapMarkerIconsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationDeleteManyMapMarkersArgs = {
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationDeleteManyMapMarkersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationDeleteManyNewsletterAudiencesArgs = {
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationDeleteManyNewsletterAudiencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationDeleteManyNotificationsArgs = {
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationDeleteManyNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationDeleteManyOmrPartnersArgs = {
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationDeleteManyOmrPartnersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationDeleteManyPartnerCompaniesArgs = {
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationDeleteManyPartnerCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationDeleteManyPhysicalLocationsArgs = {
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationDeleteManyPhysicalLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationDeleteManyPodcastEpisodesArgs = {
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationDeleteManyPodcastEpisodesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationDeleteManyPodcastGroupsArgs = {
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationDeleteManyPodcastGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationDeleteManyRecommendationsFoodArgs = {
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationDeleteManyRecommendationsFoodConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationDeleteManyRecommendationsNightOutArgs = {
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationDeleteManyRecommendationsNightOutConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationDeleteManyReviewsAgenciesArgs = {
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationDeleteManyReviewsAgenciesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationDeleteManyReviewsProductCategoriesArgs = {
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationDeleteManyReviewsProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationDeleteManyReviewsProductWidgetsArgs = {
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationDeleteManyReviewsProductWidgetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationDeleteManyReviewsProductsArgs = {
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationDeleteManyReviewsProductsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationDeleteManySocialEmbedsArgs = {
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationDeleteManySocialEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationDeleteManySpeakerListOrdersArgs = {
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationDeleteManySpeakerListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationDeleteManySpeakersArgs = {
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationDeleteManySpeakersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationDeleteManyTabContentsArgs = {
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationDeleteManyTabContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationDeleteManyTagsArgs = {
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationDeleteManyTagsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationDeleteManyTopicPageCategoriesArgs = {
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationDeleteManyTopicPageCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationDeleteManyTopicPagesArgs = {
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationDeleteManyTopicPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationDeleteManyVgWortsArgs = {
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationDeleteManyVgWortsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationDeleteMapMarkerArgs = {
  where: MapMarkerWhereUniqueInput
}

export type MutationDeleteMapMarkerIconArgs = {
  where: MapMarkerIconWhereUniqueInput
}

export type MutationDeleteNewsletterAudienceArgs = {
  where: NewsletterAudienceWhereUniqueInput
}

export type MutationDeleteNotificationArgs = {
  where: NotificationWhereUniqueInput
}

export type MutationDeleteOmrPartnerArgs = {
  where: OmrPartnerWhereUniqueInput
}

export type MutationDeletePartnerCompanyArgs = {
  where: PartnerCompanyWhereUniqueInput
}

export type MutationDeletePhysicalLocationArgs = {
  where: PhysicalLocationWhereUniqueInput
}

export type MutationDeletePodcastEpisodeArgs = {
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationDeletePodcastGroupArgs = {
  where: PodcastGroupWhereUniqueInput
}

export type MutationDeleteRecommendationFoodArgs = {
  where: RecommendationFoodWhereUniqueInput
}

export type MutationDeleteRecommendationNightOutArgs = {
  where: RecommendationNightOutWhereUniqueInput
}

export type MutationDeleteReviewsAgencyArgs = {
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationDeleteReviewsProductArgs = {
  where: ReviewsProductWhereUniqueInput
}

export type MutationDeleteReviewsProductCategoryArgs = {
  where: ReviewsProductCategoryWhereUniqueInput
}

export type MutationDeleteReviewsProductWidgetArgs = {
  where: ReviewsProductWidgetWhereUniqueInput
}

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput
}

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput
}

export type MutationDeleteSocialEmbedArgs = {
  where: SocialEmbedWhereUniqueInput
}

export type MutationDeleteSpeakerArgs = {
  where: SpeakerWhereUniqueInput
}

export type MutationDeleteSpeakerListOrderArgs = {
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationDeleteTabContentArgs = {
  where: TabContentWhereUniqueInput
}

export type MutationDeleteTagArgs = {
  where: TagWhereUniqueInput
}

export type MutationDeleteTopicPageArgs = {
  where: TopicPageWhereUniqueInput
}

export type MutationDeleteTopicPageCategoryArgs = {
  where: TopicPageCategoryWhereUniqueInput
}

export type MutationDeleteVgWortArgs = {
  where: VgWortWhereUniqueInput
}

export type MutationPublishAnimatedCounterArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: AnimatedCounterWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ArticleWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishArticleCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ArticleCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishArticlePageViewArgs = {
  to?: Array<Stage>
  where: ArticlePageViewWhereUniqueInput
}

export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: AssetWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishAuthorArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: AuthorWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishBoothArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: BoothWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishContentReferenceArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ContentReferenceWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEducationReportArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EducationReportWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEducationSeminarArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EducationSeminarWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEventArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EventWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEventAppPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EventAppPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEventPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EventPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishEventStageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: EventStageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishExhibitorListOrderArgs = {
  to?: Array<Stage>
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationPublishFaqQuestionArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: FaqQuestionWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishFooterArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: FooterWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishGlossaryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: GlossaryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishHeaderArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: HeaderWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishHotelArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: HotelWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishHtmlEmbedArgs = {
  to?: Array<Stage>
  where: HtmlEmbedWhereUniqueInput
}

export type MutationPublishHubPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: HubPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishImageGalleryEmbedArgs = {
  to?: Array<Stage>
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationPublishInterestArgs = {
  to?: Array<Stage>
  where: InterestWhereUniqueInput
}

export type MutationPublishJobListingArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: JobListingWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishLandingPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: LandingPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManageContentConfigArgs = {
  to?: Array<Stage>
  where: ManageContentConfigWhereUniqueInput
}

export type MutationPublishManyAnimatedCountersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyAnimatedCountersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyArticleCategoriesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyArticleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyArticlePageViewsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationPublishManyArticlePageViewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationPublishManyArticlesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ArticleManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ArticleManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AssetManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AssetManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyAuthorsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AuthorManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<AuthorManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyBoothsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<BoothManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyBoothsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<BoothManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyContentReferencesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ContentReferenceManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyContentReferencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ContentReferenceManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEducationReportsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EducationReportManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEducationReportsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EducationReportManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEducationSeminarsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EducationSeminarManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEducationSeminarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EducationSeminarManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventAppPagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventAppPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventAppPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventPagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventStagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventStageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventStagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventStageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyEventsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<EventManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyExhibitorListOrdersArgs = {
  to?: Array<Stage>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationPublishManyExhibitorListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationPublishManyFaqQuestionsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<FaqQuestionManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyFaqQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<FaqQuestionManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyFootersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<FooterManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyFootersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<FooterManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyGlossariesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<GlossaryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyGlossariesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<GlossaryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHeadersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HeaderManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHeadersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HeaderManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHotelsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HotelManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHotelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HotelManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHtmlEmbedsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationPublishManyHtmlEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationPublishManyHubPagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HubPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyHubPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<HubPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyImageGalleryEmbedsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationPublishManyImageGalleryEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationPublishManyInterestsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationPublishManyInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationPublishManyJobListingsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<JobListingManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyJobListingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<JobListingManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyLandingPagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<LandingPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<LandingPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyManageContentConfigsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationPublishManyManageContentConfigsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationPublishManyMapMarkerIconsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationPublishManyMapMarkerIconsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationPublishManyMapMarkersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<MapMarkerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyMapMarkersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<MapMarkerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyNewsletterAudiencesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyNewsletterAudiencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyNotificationsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<NotificationManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<NotificationManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyOmrPartnersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<OmrPartnerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyOmrPartnersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<OmrPartnerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPartnerCompaniesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPartnerCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPhysicalLocationsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPhysicalLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPodcastEpisodesArgs = {
  to?: Array<Stage>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationPublishManyPodcastEpisodesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationPublishManyPodcastGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PodcastGroupManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyPodcastGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<PodcastGroupManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyRecommendationsFoodArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyRecommendationsFoodConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyRecommendationsNightOutArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyRecommendationsNightOutConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsAgenciesArgs = {
  to?: Array<Stage>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationPublishManyReviewsAgenciesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationPublishManyReviewsProductCategoriesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsProductWidgetsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsProductWidgetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsProductsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyReviewsProductsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<ReviewsProductManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManySocialEmbedsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationPublishManySocialEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationPublishManySpeakerListOrdersArgs = {
  to?: Array<Stage>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationPublishManySpeakerListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationPublishManySpeakersArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<SpeakerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManySpeakersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<SpeakerManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTabContentsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TabContentManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTabContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TabContentManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTagsArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TagManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTagsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TagManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTopicPageCategoriesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTopicPageCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTopicPagesArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TopicPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyTopicPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<TopicPageManyWhereInput>
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishManyVgWortsArgs = {
  to?: Array<Stage>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationPublishManyVgWortsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  to?: Array<Stage>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationPublishMapMarkerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: MapMarkerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishMapMarkerIconArgs = {
  to?: Array<Stage>
  where: MapMarkerIconWhereUniqueInput
}

export type MutationPublishNewsletterAudienceArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: NewsletterAudienceWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishNotificationArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: NotificationWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishOmrPartnerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: OmrPartnerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishPartnerCompanyArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: PartnerCompanyWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishPhysicalLocationArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: PhysicalLocationWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishPodcastEpisodeArgs = {
  to?: Array<Stage>
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationPublishPodcastGroupArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: PodcastGroupWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishRecommendationFoodArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: RecommendationFoodWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishRecommendationNightOutArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: RecommendationNightOutWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishReviewsAgencyArgs = {
  to?: Array<Stage>
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationPublishReviewsProductArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ReviewsProductWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishReviewsProductCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ReviewsProductCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishReviewsProductWidgetArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: ReviewsProductWidgetWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishSocialEmbedArgs = {
  to?: Array<Stage>
  where: SocialEmbedWhereUniqueInput
}

export type MutationPublishSpeakerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: SpeakerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishSpeakerListOrderArgs = {
  to?: Array<Stage>
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationPublishTabContentArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: TabContentWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishTagArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: TagWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishTopicPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: TopicPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishTopicPageCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  to?: Array<Stage>
  where: TopicPageCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationPublishVgWortArgs = {
  to?: Array<Stage>
  where: VgWortWhereUniqueInput
}

export type MutationSchedulePublishAnimatedCounterArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: AnimatedCounterWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ArticleWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishArticleCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ArticleCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishArticlePageViewArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ArticlePageViewWhereUniqueInput
}

export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: AssetWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishAuthorArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: AuthorWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishBoothArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: BoothWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishContentReferenceArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ContentReferenceWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEducationReportArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EducationReportWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEducationSeminarArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EducationSeminarWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEventArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EventWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEventAppPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EventAppPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEventPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EventPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishEventStageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: EventStageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishExhibitorListOrderArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationSchedulePublishFaqQuestionArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: FaqQuestionWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishFooterArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: FooterWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishGlossaryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: GlossaryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishHeaderArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: HeaderWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishHotelArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: HotelWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishHtmlEmbedArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: HtmlEmbedWhereUniqueInput
}

export type MutationSchedulePublishHubPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: HubPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishImageGalleryEmbedArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationSchedulePublishInterestArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: InterestWhereUniqueInput
}

export type MutationSchedulePublishJobListingArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: JobListingWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishLandingPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: LandingPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishManageContentConfigArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ManageContentConfigWhereUniqueInput
}

export type MutationSchedulePublishMapMarkerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: MapMarkerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishMapMarkerIconArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: MapMarkerIconWhereUniqueInput
}

export type MutationSchedulePublishNewsletterAudienceArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: NewsletterAudienceWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishNotificationArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: NotificationWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishOmrPartnerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: OmrPartnerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishPartnerCompanyArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: PartnerCompanyWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishPhysicalLocationArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: PhysicalLocationWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishPodcastEpisodeArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationSchedulePublishPodcastGroupArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: PodcastGroupWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishRecommendationFoodArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: RecommendationFoodWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishRecommendationNightOutArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: RecommendationNightOutWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishReviewsAgencyArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationSchedulePublishReviewsProductArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ReviewsProductWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishReviewsProductCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ReviewsProductCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishReviewsProductWidgetArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: ReviewsProductWidgetWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishSocialEmbedArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: SocialEmbedWhereUniqueInput
}

export type MutationSchedulePublishSpeakerArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: SpeakerWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishSpeakerListOrderArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationSchedulePublishTabContentArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: TabContentWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishTagArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: TagWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishTopicPageArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: TopicPageWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishTopicPageCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>
  publishBase?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: TopicPageCategoryWhereUniqueInput
  withDefaultLocale?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSchedulePublishVgWortArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  to?: Array<Stage>
  where: VgWortWhereUniqueInput
}

export type MutationScheduleUnpublishAnimatedCounterArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AnimatedCounterWhereUniqueInput
}

export type MutationScheduleUnpublishArticleArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ArticleWhereUniqueInput
}

export type MutationScheduleUnpublishArticleCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ArticleCategoryWhereUniqueInput
}

export type MutationScheduleUnpublishArticlePageViewArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: ArticlePageViewWhereUniqueInput
}

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AssetWhereUniqueInput
}

export type MutationScheduleUnpublishAuthorArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AuthorWhereUniqueInput
}

export type MutationScheduleUnpublishBoothArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: BoothWhereUniqueInput
}

export type MutationScheduleUnpublishContentReferenceArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ContentReferenceWhereUniqueInput
}

export type MutationScheduleUnpublishEducationReportArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EducationReportWhereUniqueInput
}

export type MutationScheduleUnpublishEducationSeminarArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EducationSeminarWhereUniqueInput
}

export type MutationScheduleUnpublishEventArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventWhereUniqueInput
}

export type MutationScheduleUnpublishEventAppPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventAppPageWhereUniqueInput
}

export type MutationScheduleUnpublishEventPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventPageWhereUniqueInput
}

export type MutationScheduleUnpublishEventStageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventStageWhereUniqueInput
}

export type MutationScheduleUnpublishExhibitorListOrderArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationScheduleUnpublishFaqQuestionArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: FaqQuestionWhereUniqueInput
}

export type MutationScheduleUnpublishFooterArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: FooterWhereUniqueInput
}

export type MutationScheduleUnpublishGlossaryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: GlossaryWhereUniqueInput
}

export type MutationScheduleUnpublishHeaderArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HeaderWhereUniqueInput
}

export type MutationScheduleUnpublishHotelArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HotelWhereUniqueInput
}

export type MutationScheduleUnpublishHtmlEmbedArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: HtmlEmbedWhereUniqueInput
}

export type MutationScheduleUnpublishHubPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HubPageWhereUniqueInput
}

export type MutationScheduleUnpublishImageGalleryEmbedArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationScheduleUnpublishInterestArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: InterestWhereUniqueInput
}

export type MutationScheduleUnpublishJobListingArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: JobListingWhereUniqueInput
}

export type MutationScheduleUnpublishLandingPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: LandingPageWhereUniqueInput
}

export type MutationScheduleUnpublishManageContentConfigArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: ManageContentConfigWhereUniqueInput
}

export type MutationScheduleUnpublishMapMarkerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: MapMarkerWhereUniqueInput
}

export type MutationScheduleUnpublishMapMarkerIconArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: MapMarkerIconWhereUniqueInput
}

export type MutationScheduleUnpublishNewsletterAudienceArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: NewsletterAudienceWhereUniqueInput
}

export type MutationScheduleUnpublishNotificationArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: NotificationWhereUniqueInput
}

export type MutationScheduleUnpublishOmrPartnerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: OmrPartnerWhereUniqueInput
}

export type MutationScheduleUnpublishPartnerCompanyArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PartnerCompanyWhereUniqueInput
}

export type MutationScheduleUnpublishPhysicalLocationArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PhysicalLocationWhereUniqueInput
}

export type MutationScheduleUnpublishPodcastEpisodeArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationScheduleUnpublishPodcastGroupArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PodcastGroupWhereUniqueInput
}

export type MutationScheduleUnpublishRecommendationFoodArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: RecommendationFoodWhereUniqueInput
}

export type MutationScheduleUnpublishRecommendationNightOutArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: RecommendationNightOutWhereUniqueInput
}

export type MutationScheduleUnpublishReviewsAgencyArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationScheduleUnpublishReviewsProductArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductWhereUniqueInput
}

export type MutationScheduleUnpublishReviewsProductCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductCategoryWhereUniqueInput
}

export type MutationScheduleUnpublishReviewsProductWidgetArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductWidgetWhereUniqueInput
}

export type MutationScheduleUnpublishSocialEmbedArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: SocialEmbedWhereUniqueInput
}

export type MutationScheduleUnpublishSpeakerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: SpeakerWhereUniqueInput
}

export type MutationScheduleUnpublishSpeakerListOrderArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationScheduleUnpublishTabContentArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TabContentWhereUniqueInput
}

export type MutationScheduleUnpublishTagArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TagWhereUniqueInput
}

export type MutationScheduleUnpublishTopicPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TopicPageWhereUniqueInput
}

export type MutationScheduleUnpublishTopicPageCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TopicPageCategoryWhereUniqueInput
}

export type MutationScheduleUnpublishVgWortArgs = {
  from?: Array<Stage>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  releaseId?: InputMaybe<Scalars['String']['input']>
  where: VgWortWhereUniqueInput
}

export type MutationUnpublishAnimatedCounterArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AnimatedCounterWhereUniqueInput
}

export type MutationUnpublishArticleArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ArticleWhereUniqueInput
}

export type MutationUnpublishArticleCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ArticleCategoryWhereUniqueInput
}

export type MutationUnpublishArticlePageViewArgs = {
  from?: Array<Stage>
  where: ArticlePageViewWhereUniqueInput
}

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AssetWhereUniqueInput
}

export type MutationUnpublishAuthorArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: AuthorWhereUniqueInput
}

export type MutationUnpublishBoothArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: BoothWhereUniqueInput
}

export type MutationUnpublishContentReferenceArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ContentReferenceWhereUniqueInput
}

export type MutationUnpublishEducationReportArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EducationReportWhereUniqueInput
}

export type MutationUnpublishEducationSeminarArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EducationSeminarWhereUniqueInput
}

export type MutationUnpublishEventArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventWhereUniqueInput
}

export type MutationUnpublishEventAppPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventAppPageWhereUniqueInput
}

export type MutationUnpublishEventPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventPageWhereUniqueInput
}

export type MutationUnpublishEventStageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: EventStageWhereUniqueInput
}

export type MutationUnpublishExhibitorListOrderArgs = {
  from?: Array<Stage>
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationUnpublishFaqQuestionArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: FaqQuestionWhereUniqueInput
}

export type MutationUnpublishFooterArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: FooterWhereUniqueInput
}

export type MutationUnpublishGlossaryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: GlossaryWhereUniqueInput
}

export type MutationUnpublishHeaderArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HeaderWhereUniqueInput
}

export type MutationUnpublishHotelArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HotelWhereUniqueInput
}

export type MutationUnpublishHtmlEmbedArgs = {
  from?: Array<Stage>
  where: HtmlEmbedWhereUniqueInput
}

export type MutationUnpublishHubPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: HubPageWhereUniqueInput
}

export type MutationUnpublishImageGalleryEmbedArgs = {
  from?: Array<Stage>
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationUnpublishInterestArgs = {
  from?: Array<Stage>
  where: InterestWhereUniqueInput
}

export type MutationUnpublishJobListingArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: JobListingWhereUniqueInput
}

export type MutationUnpublishLandingPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: LandingPageWhereUniqueInput
}

export type MutationUnpublishManageContentConfigArgs = {
  from?: Array<Stage>
  where: ManageContentConfigWhereUniqueInput
}

export type MutationUnpublishManyAnimatedCountersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationUnpublishManyAnimatedCountersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationUnpublishManyArticleCategoriesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationUnpublishManyArticleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationUnpublishManyArticlePageViewsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationUnpublishManyArticlePageViewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationUnpublishManyArticlesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationUnpublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationUnpublishManyAuthorsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationUnpublishManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationUnpublishManyBoothsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationUnpublishManyBoothsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationUnpublishManyContentReferencesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationUnpublishManyContentReferencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationUnpublishManyEducationReportsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationUnpublishManyEducationReportsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationUnpublishManyEducationSeminarsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationUnpublishManyEducationSeminarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationUnpublishManyEventAppPagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationUnpublishManyEventAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationUnpublishManyEventPagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationUnpublishManyEventPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationUnpublishManyEventStagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationUnpublishManyEventStagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationUnpublishManyEventsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationUnpublishManyEventsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationUnpublishManyExhibitorListOrdersArgs = {
  from?: Array<Stage>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationUnpublishManyExhibitorListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationUnpublishManyFaqQuestionsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationUnpublishManyFaqQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationUnpublishManyFootersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationUnpublishManyFootersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationUnpublishManyGlossariesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationUnpublishManyGlossariesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationUnpublishManyHeadersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationUnpublishManyHeadersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationUnpublishManyHotelsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationUnpublishManyHotelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationUnpublishManyHtmlEmbedsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationUnpublishManyHtmlEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationUnpublishManyHubPagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationUnpublishManyHubPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationUnpublishManyImageGalleryEmbedsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationUnpublishManyImageGalleryEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationUnpublishManyInterestsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationUnpublishManyInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationUnpublishManyJobListingsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationUnpublishManyJobListingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationUnpublishManyLandingPagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationUnpublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationUnpublishManyManageContentConfigsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationUnpublishManyManageContentConfigsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationUnpublishManyMapMarkerIconsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationUnpublishManyMapMarkerIconsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationUnpublishManyMapMarkersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationUnpublishManyMapMarkersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationUnpublishManyNewsletterAudiencesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationUnpublishManyNewsletterAudiencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationUnpublishManyNotificationsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationUnpublishManyNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationUnpublishManyOmrPartnersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationUnpublishManyOmrPartnersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationUnpublishManyPartnerCompaniesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationUnpublishManyPartnerCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationUnpublishManyPhysicalLocationsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationUnpublishManyPhysicalLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationUnpublishManyPodcastEpisodesArgs = {
  from?: Array<Stage>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationUnpublishManyPodcastEpisodesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationUnpublishManyPodcastGroupsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationUnpublishManyPodcastGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationUnpublishManyRecommendationsFoodArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationUnpublishManyRecommendationsFoodConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationUnpublishManyRecommendationsNightOutArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationUnpublishManyRecommendationsNightOutConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationUnpublishManyReviewsAgenciesArgs = {
  from?: Array<Stage>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationUnpublishManyReviewsAgenciesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationUnpublishManyReviewsProductCategoriesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationUnpublishManyReviewsProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationUnpublishManyReviewsProductWidgetsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationUnpublishManyReviewsProductWidgetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationUnpublishManyReviewsProductsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationUnpublishManyReviewsProductsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationUnpublishManySocialEmbedsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationUnpublishManySocialEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationUnpublishManySpeakerListOrdersArgs = {
  from?: Array<Stage>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationUnpublishManySpeakerListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationUnpublishManySpeakersArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationUnpublishManySpeakersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationUnpublishManyTabContentsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationUnpublishManyTabContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationUnpublishManyTagsArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationUnpublishManyTagsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationUnpublishManyTopicPageCategoriesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationUnpublishManyTopicPageCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationUnpublishManyTopicPagesArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationUnpublishManyTopicPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationUnpublishManyVgWortsArgs = {
  from?: Array<Stage>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationUnpublishManyVgWortsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: Array<Stage>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: InputMaybe<Stage>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationUnpublishMapMarkerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: MapMarkerWhereUniqueInput
}

export type MutationUnpublishMapMarkerIconArgs = {
  from?: Array<Stage>
  where: MapMarkerIconWhereUniqueInput
}

export type MutationUnpublishNewsletterAudienceArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: NewsletterAudienceWhereUniqueInput
}

export type MutationUnpublishNotificationArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: NotificationWhereUniqueInput
}

export type MutationUnpublishOmrPartnerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: OmrPartnerWhereUniqueInput
}

export type MutationUnpublishPartnerCompanyArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PartnerCompanyWhereUniqueInput
}

export type MutationUnpublishPhysicalLocationArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PhysicalLocationWhereUniqueInput
}

export type MutationUnpublishPodcastEpisodeArgs = {
  from?: Array<Stage>
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationUnpublishPodcastGroupArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: PodcastGroupWhereUniqueInput
}

export type MutationUnpublishRecommendationFoodArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: RecommendationFoodWhereUniqueInput
}

export type MutationUnpublishRecommendationNightOutArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: RecommendationNightOutWhereUniqueInput
}

export type MutationUnpublishReviewsAgencyArgs = {
  from?: Array<Stage>
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationUnpublishReviewsProductArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductWhereUniqueInput
}

export type MutationUnpublishReviewsProductCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductCategoryWhereUniqueInput
}

export type MutationUnpublishReviewsProductWidgetArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: ReviewsProductWidgetWhereUniqueInput
}

export type MutationUnpublishSocialEmbedArgs = {
  from?: Array<Stage>
  where: SocialEmbedWhereUniqueInput
}

export type MutationUnpublishSpeakerArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: SpeakerWhereUniqueInput
}

export type MutationUnpublishSpeakerListOrderArgs = {
  from?: Array<Stage>
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationUnpublishTabContentArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TabContentWhereUniqueInput
}

export type MutationUnpublishTagArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TagWhereUniqueInput
}

export type MutationUnpublishTopicPageArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TopicPageWhereUniqueInput
}

export type MutationUnpublishTopicPageCategoryArgs = {
  from?: Array<Stage>
  locales?: InputMaybe<Array<Locale>>
  unpublishBase?: InputMaybe<Scalars['Boolean']['input']>
  where: TopicPageCategoryWhereUniqueInput
}

export type MutationUnpublishVgWortArgs = {
  from?: Array<Stage>
  where: VgWortWhereUniqueInput
}

export type MutationUpdateAnimatedCounterArgs = {
  data: AnimatedCounterUpdateInput
  where: AnimatedCounterWhereUniqueInput
}

export type MutationUpdateArticleArgs = {
  data: ArticleUpdateInput
  where: ArticleWhereUniqueInput
}

export type MutationUpdateArticleCategoryArgs = {
  data: ArticleCategoryUpdateInput
  where: ArticleCategoryWhereUniqueInput
}

export type MutationUpdateArticlePageViewArgs = {
  data: ArticlePageViewUpdateInput
  where: ArticlePageViewWhereUniqueInput
}

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput
  where: AssetWhereUniqueInput
}

export type MutationUpdateAuthorArgs = {
  data: AuthorUpdateInput
  where: AuthorWhereUniqueInput
}

export type MutationUpdateBoothArgs = {
  data: BoothUpdateInput
  where: BoothWhereUniqueInput
}

export type MutationUpdateContentReferenceArgs = {
  data: ContentReferenceUpdateInput
  where: ContentReferenceWhereUniqueInput
}

export type MutationUpdateEducationReportArgs = {
  data: EducationReportUpdateInput
  where: EducationReportWhereUniqueInput
}

export type MutationUpdateEducationSeminarArgs = {
  data: EducationSeminarUpdateInput
  where: EducationSeminarWhereUniqueInput
}

export type MutationUpdateEventArgs = {
  data: EventUpdateInput
  where: EventWhereUniqueInput
}

export type MutationUpdateEventAppPageArgs = {
  data: EventAppPageUpdateInput
  where: EventAppPageWhereUniqueInput
}

export type MutationUpdateEventPageArgs = {
  data: EventPageUpdateInput
  where: EventPageWhereUniqueInput
}

export type MutationUpdateEventStageArgs = {
  data: EventStageUpdateInput
  where: EventStageWhereUniqueInput
}

export type MutationUpdateExhibitorListOrderArgs = {
  data: ExhibitorListOrderUpdateInput
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationUpdateFaqQuestionArgs = {
  data: FaqQuestionUpdateInput
  where: FaqQuestionWhereUniqueInput
}

export type MutationUpdateFooterArgs = {
  data: FooterUpdateInput
  where: FooterWhereUniqueInput
}

export type MutationUpdateGlossaryArgs = {
  data: GlossaryUpdateInput
  where: GlossaryWhereUniqueInput
}

export type MutationUpdateHeaderArgs = {
  data: HeaderUpdateInput
  where: HeaderWhereUniqueInput
}

export type MutationUpdateHotelArgs = {
  data: HotelUpdateInput
  where: HotelWhereUniqueInput
}

export type MutationUpdateHtmlEmbedArgs = {
  data: HtmlEmbedUpdateInput
  where: HtmlEmbedWhereUniqueInput
}

export type MutationUpdateHubPageArgs = {
  data: HubPageUpdateInput
  where: HubPageWhereUniqueInput
}

export type MutationUpdateImageGalleryEmbedArgs = {
  data: ImageGalleryEmbedUpdateInput
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationUpdateInterestArgs = {
  data: InterestUpdateInput
  where: InterestWhereUniqueInput
}

export type MutationUpdateJobListingArgs = {
  data: JobListingUpdateInput
  where: JobListingWhereUniqueInput
}

export type MutationUpdateLandingPageArgs = {
  data: LandingPageUpdateInput
  where: LandingPageWhereUniqueInput
}

export type MutationUpdateManageContentConfigArgs = {
  data: ManageContentConfigUpdateInput
  where: ManageContentConfigWhereUniqueInput
}

export type MutationUpdateManyAnimatedCountersArgs = {
  data: AnimatedCounterUpdateManyInput
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationUpdateManyAnimatedCountersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: AnimatedCounterUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AnimatedCounterManyWhereInput>
}

export type MutationUpdateManyArticleCategoriesArgs = {
  data: ArticleCategoryUpdateManyInput
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationUpdateManyArticleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ArticleCategoryUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleCategoryManyWhereInput>
}

export type MutationUpdateManyArticlePageViewsArgs = {
  data: ArticlePageViewUpdateManyInput
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationUpdateManyArticlePageViewsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ArticlePageViewUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticlePageViewManyWhereInput>
}

export type MutationUpdateManyArticlesArgs = {
  data: ArticleUpdateManyInput
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationUpdateManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ArticleUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleManyWhereInput>
}

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: AssetUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AssetManyWhereInput>
}

export type MutationUpdateManyAuthorsArgs = {
  data: AuthorUpdateManyInput
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationUpdateManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: AuthorUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorManyWhereInput>
}

export type MutationUpdateManyBoothsArgs = {
  data: BoothUpdateManyInput
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationUpdateManyBoothsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: BoothUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BoothManyWhereInput>
}

export type MutationUpdateManyContentReferencesArgs = {
  data: ContentReferenceUpdateManyInput
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationUpdateManyContentReferencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ContentReferenceUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ContentReferenceManyWhereInput>
}

export type MutationUpdateManyEducationReportsArgs = {
  data: EducationReportUpdateManyInput
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationUpdateManyEducationReportsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EducationReportUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EducationReportManyWhereInput>
}

export type MutationUpdateManyEducationSeminarsArgs = {
  data: EducationSeminarUpdateManyInput
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationUpdateManyEducationSeminarsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EducationSeminarUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EducationSeminarManyWhereInput>
}

export type MutationUpdateManyEventAppPagesArgs = {
  data: EventAppPageUpdateManyInput
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationUpdateManyEventAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EventAppPageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventAppPageManyWhereInput>
}

export type MutationUpdateManyEventPagesArgs = {
  data: EventPageUpdateManyInput
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationUpdateManyEventPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EventPageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventPageManyWhereInput>
}

export type MutationUpdateManyEventStagesArgs = {
  data: EventStageUpdateManyInput
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationUpdateManyEventStagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EventStageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventStageManyWhereInput>
}

export type MutationUpdateManyEventsArgs = {
  data: EventUpdateManyInput
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationUpdateManyEventsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: EventUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventManyWhereInput>
}

export type MutationUpdateManyExhibitorListOrdersArgs = {
  data: ExhibitorListOrderUpdateManyInput
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationUpdateManyExhibitorListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ExhibitorListOrderUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ExhibitorListOrderManyWhereInput>
}

export type MutationUpdateManyFaqQuestionsArgs = {
  data: FaqQuestionUpdateManyInput
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationUpdateManyFaqQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: FaqQuestionUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FaqQuestionManyWhereInput>
}

export type MutationUpdateManyFootersArgs = {
  data: FooterUpdateManyInput
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationUpdateManyFootersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: FooterUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<FooterManyWhereInput>
}

export type MutationUpdateManyGlossariesArgs = {
  data: GlossaryUpdateManyInput
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationUpdateManyGlossariesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: GlossaryUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GlossaryManyWhereInput>
}

export type MutationUpdateManyHeadersArgs = {
  data: HeaderUpdateManyInput
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationUpdateManyHeadersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: HeaderUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HeaderManyWhereInput>
}

export type MutationUpdateManyHotelsArgs = {
  data: HotelUpdateManyInput
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationUpdateManyHotelsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: HotelUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HotelManyWhereInput>
}

export type MutationUpdateManyHtmlEmbedsArgs = {
  data: HtmlEmbedUpdateManyInput
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationUpdateManyHtmlEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: HtmlEmbedUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HtmlEmbedManyWhereInput>
}

export type MutationUpdateManyHubPagesArgs = {
  data: HubPageUpdateManyInput
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationUpdateManyHubPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: HubPageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HubPageManyWhereInput>
}

export type MutationUpdateManyImageGalleryEmbedsArgs = {
  data: ImageGalleryEmbedUpdateManyInput
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationUpdateManyImageGalleryEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ImageGalleryEmbedUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ImageGalleryEmbedManyWhereInput>
}

export type MutationUpdateManyInterestsArgs = {
  data: InterestUpdateManyInput
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationUpdateManyInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: InterestUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InterestManyWhereInput>
}

export type MutationUpdateManyJobListingsArgs = {
  data: JobListingUpdateManyInput
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationUpdateManyJobListingsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: JobListingUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<JobListingManyWhereInput>
}

export type MutationUpdateManyLandingPagesArgs = {
  data: LandingPageUpdateManyInput
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationUpdateManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: LandingPageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<LandingPageManyWhereInput>
}

export type MutationUpdateManyManageContentConfigsArgs = {
  data: ManageContentConfigUpdateManyInput
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationUpdateManyManageContentConfigsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ManageContentConfigUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ManageContentConfigManyWhereInput>
}

export type MutationUpdateManyMapMarkerIconsArgs = {
  data: MapMarkerIconUpdateManyInput
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationUpdateManyMapMarkerIconsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: MapMarkerIconUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerIconManyWhereInput>
}

export type MutationUpdateManyMapMarkersArgs = {
  data: MapMarkerUpdateManyInput
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationUpdateManyMapMarkersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: MapMarkerUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerManyWhereInput>
}

export type MutationUpdateManyNewsletterAudiencesArgs = {
  data: NewsletterAudienceUpdateManyInput
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationUpdateManyNewsletterAudiencesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: NewsletterAudienceUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<NewsletterAudienceManyWhereInput>
}

export type MutationUpdateManyNotificationsArgs = {
  data: NotificationUpdateManyInput
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationUpdateManyNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: NotificationUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<NotificationManyWhereInput>
}

export type MutationUpdateManyOmrPartnersArgs = {
  data: OmrPartnerUpdateManyInput
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationUpdateManyOmrPartnersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: OmrPartnerUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<OmrPartnerManyWhereInput>
}

export type MutationUpdateManyPartnerCompaniesArgs = {
  data: PartnerCompanyUpdateManyInput
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationUpdateManyPartnerCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: PartnerCompanyUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyManyWhereInput>
}

export type MutationUpdateManyPhysicalLocationsArgs = {
  data: PhysicalLocationUpdateManyInput
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationUpdateManyPhysicalLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: PhysicalLocationUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PhysicalLocationManyWhereInput>
}

export type MutationUpdateManyPodcastEpisodesArgs = {
  data: PodcastEpisodeUpdateManyInput
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationUpdateManyPodcastEpisodesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: PodcastEpisodeUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastEpisodeManyWhereInput>
}

export type MutationUpdateManyPodcastGroupsArgs = {
  data: PodcastGroupUpdateManyInput
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationUpdateManyPodcastGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: PodcastGroupUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PodcastGroupManyWhereInput>
}

export type MutationUpdateManyRecommendationsFoodArgs = {
  data: RecommendationFoodUpdateManyInput
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationUpdateManyRecommendationsFoodConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: RecommendationFoodUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RecommendationFoodManyWhereInput>
}

export type MutationUpdateManyRecommendationsNightOutArgs = {
  data: RecommendationNightOutUpdateManyInput
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationUpdateManyRecommendationsNightOutConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: RecommendationNightOutUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<RecommendationNightOutManyWhereInput>
}

export type MutationUpdateManyReviewsAgenciesArgs = {
  data: ReviewsAgencyUpdateManyInput
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationUpdateManyReviewsAgenciesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ReviewsAgencyUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsAgencyManyWhereInput>
}

export type MutationUpdateManyReviewsProductCategoriesArgs = {
  data: ReviewsProductCategoryUpdateManyInput
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationUpdateManyReviewsProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ReviewsProductCategoryUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductCategoryManyWhereInput>
}

export type MutationUpdateManyReviewsProductWidgetsArgs = {
  data: ReviewsProductWidgetUpdateManyInput
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationUpdateManyReviewsProductWidgetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ReviewsProductWidgetUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWidgetManyWhereInput>
}

export type MutationUpdateManyReviewsProductsArgs = {
  data: ReviewsProductUpdateManyInput
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationUpdateManyReviewsProductsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: ReviewsProductUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductManyWhereInput>
}

export type MutationUpdateManySocialEmbedsArgs = {
  data: SocialEmbedUpdateManyInput
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationUpdateManySocialEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: SocialEmbedUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SocialEmbedManyWhereInput>
}

export type MutationUpdateManySpeakerListOrdersArgs = {
  data: SpeakerListOrderUpdateManyInput
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationUpdateManySpeakerListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: SpeakerListOrderUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerListOrderManyWhereInput>
}

export type MutationUpdateManySpeakersArgs = {
  data: SpeakerUpdateManyInput
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationUpdateManySpeakersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: SpeakerUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerManyWhereInput>
}

export type MutationUpdateManyTabContentsArgs = {
  data: TabContentUpdateManyInput
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationUpdateManyTabContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: TabContentUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TabContentManyWhereInput>
}

export type MutationUpdateManyTagsArgs = {
  data: TagUpdateManyInput
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationUpdateManyTagsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: TagUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagManyWhereInput>
}

export type MutationUpdateManyTopicPageCategoriesArgs = {
  data: TopicPageCategoryUpdateManyInput
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationUpdateManyTopicPageCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: TopicPageCategoryUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageCategoryManyWhereInput>
}

export type MutationUpdateManyTopicPagesArgs = {
  data: TopicPageUpdateManyInput
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationUpdateManyTopicPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: TopicPageUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageManyWhereInput>
}

export type MutationUpdateManyVgWortsArgs = {
  data: VgWortUpdateManyInput
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationUpdateManyVgWortsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  data: VgWortUpdateManyInput
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<VgWortManyWhereInput>
}

export type MutationUpdateMapMarkerArgs = {
  data: MapMarkerUpdateInput
  where: MapMarkerWhereUniqueInput
}

export type MutationUpdateMapMarkerIconArgs = {
  data: MapMarkerIconUpdateInput
  where: MapMarkerIconWhereUniqueInput
}

export type MutationUpdateNewsletterAudienceArgs = {
  data: NewsletterAudienceUpdateInput
  where: NewsletterAudienceWhereUniqueInput
}

export type MutationUpdateNotificationArgs = {
  data: NotificationUpdateInput
  where: NotificationWhereUniqueInput
}

export type MutationUpdateOmrPartnerArgs = {
  data: OmrPartnerUpdateInput
  where: OmrPartnerWhereUniqueInput
}

export type MutationUpdatePartnerCompanyArgs = {
  data: PartnerCompanyUpdateInput
  where: PartnerCompanyWhereUniqueInput
}

export type MutationUpdatePhysicalLocationArgs = {
  data: PhysicalLocationUpdateInput
  where: PhysicalLocationWhereUniqueInput
}

export type MutationUpdatePodcastEpisodeArgs = {
  data: PodcastEpisodeUpdateInput
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationUpdatePodcastGroupArgs = {
  data: PodcastGroupUpdateInput
  where: PodcastGroupWhereUniqueInput
}

export type MutationUpdateRecommendationFoodArgs = {
  data: RecommendationFoodUpdateInput
  where: RecommendationFoodWhereUniqueInput
}

export type MutationUpdateRecommendationNightOutArgs = {
  data: RecommendationNightOutUpdateInput
  where: RecommendationNightOutWhereUniqueInput
}

export type MutationUpdateReviewsAgencyArgs = {
  data: ReviewsAgencyUpdateInput
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationUpdateReviewsProductArgs = {
  data: ReviewsProductUpdateInput
  where: ReviewsProductWhereUniqueInput
}

export type MutationUpdateReviewsProductCategoryArgs = {
  data: ReviewsProductCategoryUpdateInput
  where: ReviewsProductCategoryWhereUniqueInput
}

export type MutationUpdateReviewsProductWidgetArgs = {
  data: ReviewsProductWidgetUpdateInput
  where: ReviewsProductWidgetWhereUniqueInput
}

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput
  where: ScheduledReleaseWhereUniqueInput
}

export type MutationUpdateSocialEmbedArgs = {
  data: SocialEmbedUpdateInput
  where: SocialEmbedWhereUniqueInput
}

export type MutationUpdateSpeakerArgs = {
  data: SpeakerUpdateInput
  where: SpeakerWhereUniqueInput
}

export type MutationUpdateSpeakerListOrderArgs = {
  data: SpeakerListOrderUpdateInput
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationUpdateTabContentArgs = {
  data: TabContentUpdateInput
  where: TabContentWhereUniqueInput
}

export type MutationUpdateTagArgs = {
  data: TagUpdateInput
  where: TagWhereUniqueInput
}

export type MutationUpdateTopicPageArgs = {
  data: TopicPageUpdateInput
  where: TopicPageWhereUniqueInput
}

export type MutationUpdateTopicPageCategoryArgs = {
  data: TopicPageCategoryUpdateInput
  where: TopicPageCategoryWhereUniqueInput
}

export type MutationUpdateVgWortArgs = {
  data: VgWortUpdateInput
  where: VgWortWhereUniqueInput
}

export type MutationUpsertAnimatedCounterArgs = {
  upsert: AnimatedCounterUpsertInput
  where: AnimatedCounterWhereUniqueInput
}

export type MutationUpsertArticleArgs = {
  upsert: ArticleUpsertInput
  where: ArticleWhereUniqueInput
}

export type MutationUpsertArticleCategoryArgs = {
  upsert: ArticleCategoryUpsertInput
  where: ArticleCategoryWhereUniqueInput
}

export type MutationUpsertArticlePageViewArgs = {
  upsert: ArticlePageViewUpsertInput
  where: ArticlePageViewWhereUniqueInput
}

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput
  where: AssetWhereUniqueInput
}

export type MutationUpsertAuthorArgs = {
  upsert: AuthorUpsertInput
  where: AuthorWhereUniqueInput
}

export type MutationUpsertBoothArgs = {
  upsert: BoothUpsertInput
  where: BoothWhereUniqueInput
}

export type MutationUpsertContentReferenceArgs = {
  upsert: ContentReferenceUpsertInput
  where: ContentReferenceWhereUniqueInput
}

export type MutationUpsertEducationReportArgs = {
  upsert: EducationReportUpsertInput
  where: EducationReportWhereUniqueInput
}

export type MutationUpsertEducationSeminarArgs = {
  upsert: EducationSeminarUpsertInput
  where: EducationSeminarWhereUniqueInput
}

export type MutationUpsertEventArgs = {
  upsert: EventUpsertInput
  where: EventWhereUniqueInput
}

export type MutationUpsertEventAppPageArgs = {
  upsert: EventAppPageUpsertInput
  where: EventAppPageWhereUniqueInput
}

export type MutationUpsertEventPageArgs = {
  upsert: EventPageUpsertInput
  where: EventPageWhereUniqueInput
}

export type MutationUpsertEventStageArgs = {
  upsert: EventStageUpsertInput
  where: EventStageWhereUniqueInput
}

export type MutationUpsertExhibitorListOrderArgs = {
  upsert: ExhibitorListOrderUpsertInput
  where: ExhibitorListOrderWhereUniqueInput
}

export type MutationUpsertFaqQuestionArgs = {
  upsert: FaqQuestionUpsertInput
  where: FaqQuestionWhereUniqueInput
}

export type MutationUpsertFooterArgs = {
  upsert: FooterUpsertInput
  where: FooterWhereUniqueInput
}

export type MutationUpsertGlossaryArgs = {
  upsert: GlossaryUpsertInput
  where: GlossaryWhereUniqueInput
}

export type MutationUpsertHeaderArgs = {
  upsert: HeaderUpsertInput
  where: HeaderWhereUniqueInput
}

export type MutationUpsertHotelArgs = {
  upsert: HotelUpsertInput
  where: HotelWhereUniqueInput
}

export type MutationUpsertHtmlEmbedArgs = {
  upsert: HtmlEmbedUpsertInput
  where: HtmlEmbedWhereUniqueInput
}

export type MutationUpsertHubPageArgs = {
  upsert: HubPageUpsertInput
  where: HubPageWhereUniqueInput
}

export type MutationUpsertImageGalleryEmbedArgs = {
  upsert: ImageGalleryEmbedUpsertInput
  where: ImageGalleryEmbedWhereUniqueInput
}

export type MutationUpsertInterestArgs = {
  upsert: InterestUpsertInput
  where: InterestWhereUniqueInput
}

export type MutationUpsertJobListingArgs = {
  upsert: JobListingUpsertInput
  where: JobListingWhereUniqueInput
}

export type MutationUpsertLandingPageArgs = {
  upsert: LandingPageUpsertInput
  where: LandingPageWhereUniqueInput
}

export type MutationUpsertManageContentConfigArgs = {
  upsert: ManageContentConfigUpsertInput
  where: ManageContentConfigWhereUniqueInput
}

export type MutationUpsertMapMarkerArgs = {
  upsert: MapMarkerUpsertInput
  where: MapMarkerWhereUniqueInput
}

export type MutationUpsertMapMarkerIconArgs = {
  upsert: MapMarkerIconUpsertInput
  where: MapMarkerIconWhereUniqueInput
}

export type MutationUpsertNewsletterAudienceArgs = {
  upsert: NewsletterAudienceUpsertInput
  where: NewsletterAudienceWhereUniqueInput
}

export type MutationUpsertNotificationArgs = {
  upsert: NotificationUpsertInput
  where: NotificationWhereUniqueInput
}

export type MutationUpsertOmrPartnerArgs = {
  upsert: OmrPartnerUpsertInput
  where: OmrPartnerWhereUniqueInput
}

export type MutationUpsertPartnerCompanyArgs = {
  upsert: PartnerCompanyUpsertInput
  where: PartnerCompanyWhereUniqueInput
}

export type MutationUpsertPhysicalLocationArgs = {
  upsert: PhysicalLocationUpsertInput
  where: PhysicalLocationWhereUniqueInput
}

export type MutationUpsertPodcastEpisodeArgs = {
  upsert: PodcastEpisodeUpsertInput
  where: PodcastEpisodeWhereUniqueInput
}

export type MutationUpsertPodcastGroupArgs = {
  upsert: PodcastGroupUpsertInput
  where: PodcastGroupWhereUniqueInput
}

export type MutationUpsertRecommendationFoodArgs = {
  upsert: RecommendationFoodUpsertInput
  where: RecommendationFoodWhereUniqueInput
}

export type MutationUpsertRecommendationNightOutArgs = {
  upsert: RecommendationNightOutUpsertInput
  where: RecommendationNightOutWhereUniqueInput
}

export type MutationUpsertReviewsAgencyArgs = {
  upsert: ReviewsAgencyUpsertInput
  where: ReviewsAgencyWhereUniqueInput
}

export type MutationUpsertReviewsProductArgs = {
  upsert: ReviewsProductUpsertInput
  where: ReviewsProductWhereUniqueInput
}

export type MutationUpsertReviewsProductCategoryArgs = {
  upsert: ReviewsProductCategoryUpsertInput
  where: ReviewsProductCategoryWhereUniqueInput
}

export type MutationUpsertReviewsProductWidgetArgs = {
  upsert: ReviewsProductWidgetUpsertInput
  where: ReviewsProductWidgetWhereUniqueInput
}

export type MutationUpsertSocialEmbedArgs = {
  upsert: SocialEmbedUpsertInput
  where: SocialEmbedWhereUniqueInput
}

export type MutationUpsertSpeakerArgs = {
  upsert: SpeakerUpsertInput
  where: SpeakerWhereUniqueInput
}

export type MutationUpsertSpeakerListOrderArgs = {
  upsert: SpeakerListOrderUpsertInput
  where: SpeakerListOrderWhereUniqueInput
}

export type MutationUpsertTabContentArgs = {
  upsert: TabContentUpsertInput
  where: TabContentWhereUniqueInput
}

export type MutationUpsertTagArgs = {
  upsert: TagUpsertInput
  where: TagWhereUniqueInput
}

export type MutationUpsertTopicPageArgs = {
  upsert: TopicPageUpsertInput
  where: TopicPageWhereUniqueInput
}

export type MutationUpsertTopicPageCategoryArgs = {
  upsert: TopicPageCategoryUpsertInput
  where: TopicPageCategoryWhereUniqueInput
}

export type MutationUpsertVgWortArgs = {
  upsert: VgWortUpsertInput
  where: VgWortWhereUniqueInput
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudience = Entity &
  Node & {
    __typename?: 'NewsletterAudience'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<NewsletterAudience>
    /** List of NewsletterAudience versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<NewsletterAudience>
    name: Scalars['String']['output']
    previewCta?: Maybe<CallToAction>
    /** Private only audience for logged in users */
    private?: Maybe<Scalars['Boolean']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudiencePreviewCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudiencePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudiencePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Single newsletter audience as a entry point to newsletter subscriptions. */
export type NewsletterAudienceUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type NewsletterAudienceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: NewsletterAudienceWhereUniqueInput
}

/** A connection to a list of items. */
export type NewsletterAudienceConnection = {
  __typename?: 'NewsletterAudienceConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<NewsletterAudienceEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type NewsletterAudienceCreateInput = {
  audienceInSection?: InputMaybe<SectionNewsletterAudienceCreateOneInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<NewsletterAudienceCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  previewCta?: InputMaybe<CallToActionCreateOneInlineInput>
  private?: InputMaybe<Scalars['Boolean']['input']>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type NewsletterAudienceCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type NewsletterAudienceCreateLocalizationInput = {
  /** Localization input */
  data: NewsletterAudienceCreateLocalizationDataInput
  locale: Locale
}

export type NewsletterAudienceCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<NewsletterAudienceCreateLocalizationInput>>
}

export type NewsletterAudienceCreateManyInlineInput = {
  /** Connect multiple existing NewsletterAudience documents */
  connect?: InputMaybe<Array<NewsletterAudienceWhereUniqueInput>>
  /** Create and connect multiple existing NewsletterAudience documents */
  create?: InputMaybe<Array<NewsletterAudienceCreateInput>>
}

export type NewsletterAudienceCreateOneInlineInput = {
  /** Connect one existing NewsletterAudience document */
  connect?: InputMaybe<NewsletterAudienceWhereUniqueInput>
  /** Create and connect one NewsletterAudience document */
  create?: InputMaybe<NewsletterAudienceCreateInput>
}

/** An edge in a connection. */
export type NewsletterAudienceEdge = {
  __typename?: 'NewsletterAudienceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: NewsletterAudience
}

/** Identifies documents */
export type NewsletterAudienceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<NewsletterAudienceWhereStageInput>
  documentInStages_none?: InputMaybe<NewsletterAudienceWhereStageInput>
  documentInStages_some?: InputMaybe<NewsletterAudienceWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  previewCta?: InputMaybe<CallToActionWhereInput>
  private?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  private_not?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum NewsletterAudienceOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PrivateAsc = 'private_ASC',
  PrivateDesc = 'private_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NewsletterAudienceUpdateInput = {
  audienceInSection?: InputMaybe<SectionNewsletterAudienceUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<NewsletterAudienceUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  previewCta?: InputMaybe<CallToActionUpdateOneInlineInput>
  private?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type NewsletterAudienceUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type NewsletterAudienceUpdateLocalizationInput = {
  data: NewsletterAudienceUpdateLocalizationDataInput
  locale: Locale
}

export type NewsletterAudienceUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<NewsletterAudienceCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<NewsletterAudienceUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<NewsletterAudienceUpsertLocalizationInput>>
}

export type NewsletterAudienceUpdateManyInlineInput = {
  /** Connect multiple existing NewsletterAudience documents */
  connect?: InputMaybe<Array<NewsletterAudienceConnectInput>>
  /** Create and connect multiple NewsletterAudience documents */
  create?: InputMaybe<Array<NewsletterAudienceCreateInput>>
  /** Delete multiple NewsletterAudience documents */
  delete?: InputMaybe<Array<NewsletterAudienceWhereUniqueInput>>
  /** Disconnect multiple NewsletterAudience documents */
  disconnect?: InputMaybe<Array<NewsletterAudienceWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing NewsletterAudience documents */
  set?: InputMaybe<Array<NewsletterAudienceWhereUniqueInput>>
  /** Update multiple NewsletterAudience documents */
  update?: InputMaybe<Array<NewsletterAudienceUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple NewsletterAudience documents */
  upsert?: InputMaybe<Array<NewsletterAudienceUpsertWithNestedWhereUniqueInput>>
}

export type NewsletterAudienceUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<NewsletterAudienceUpdateManyLocalizationsInput>
  private?: InputMaybe<Scalars['Boolean']['input']>
}

export type NewsletterAudienceUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type NewsletterAudienceUpdateManyLocalizationInput = {
  data: NewsletterAudienceUpdateManyLocalizationDataInput
  locale: Locale
}

export type NewsletterAudienceUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<NewsletterAudienceUpdateManyLocalizationInput>>
}

export type NewsletterAudienceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NewsletterAudienceUpdateManyInput
  /** Document search */
  where: NewsletterAudienceWhereInput
}

export type NewsletterAudienceUpdateOneInlineInput = {
  /** Connect existing NewsletterAudience document */
  connect?: InputMaybe<NewsletterAudienceWhereUniqueInput>
  /** Create and connect one NewsletterAudience document */
  create?: InputMaybe<NewsletterAudienceCreateInput>
  /** Delete currently connected NewsletterAudience document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected NewsletterAudience document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single NewsletterAudience document */
  update?: InputMaybe<NewsletterAudienceUpdateWithNestedWhereUniqueInput>
  /** Upsert single NewsletterAudience document */
  upsert?: InputMaybe<NewsletterAudienceUpsertWithNestedWhereUniqueInput>
}

export type NewsletterAudienceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NewsletterAudienceUpdateInput
  /** Unique document search */
  where: NewsletterAudienceWhereUniqueInput
}

export type NewsletterAudienceUpsertInput = {
  /** Create document if it didn't exist */
  create: NewsletterAudienceCreateInput
  /** Update document if it exists */
  update: NewsletterAudienceUpdateInput
}

export type NewsletterAudienceUpsertLocalizationInput = {
  create: NewsletterAudienceCreateLocalizationDataInput
  locale: Locale
  update: NewsletterAudienceUpdateLocalizationDataInput
}

export type NewsletterAudienceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NewsletterAudienceUpsertInput
  /** Unique document search */
  where: NewsletterAudienceWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type NewsletterAudienceWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type NewsletterAudienceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NewsletterAudienceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<NewsletterAudienceWhereStageInput>
  documentInStages_none?: InputMaybe<NewsletterAudienceWhereStageInput>
  documentInStages_some?: InputMaybe<NewsletterAudienceWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  previewCta?: InputMaybe<CallToActionWhereInput>
  private?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  private_not?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NewsletterAudienceWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NewsletterAudienceWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NewsletterAudienceWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NewsletterAudienceWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NewsletterAudienceWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References NewsletterAudience record uniquely */
export type NewsletterAudienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output']
  /** The Stage of an object */
  stage: Stage
}

export type Notification = Entity &
  Node & {
    __typename?: 'Notification'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Notification>
    event: Event
    /** List of Notification versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Notification>
    message: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type NotificationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type NotificationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type NotificationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type NotificationEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type NotificationHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type NotificationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type NotificationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type NotificationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type NotificationScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type NotificationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type NotificationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type NotificationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: NotificationWhereUniqueInput
}

/** A connection to a list of items. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<NotificationEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type NotificationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event: EventCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<NotificationCreateLocalizationsInput>
  message: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type NotificationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type NotificationCreateLocalizationInput = {
  /** Localization input */
  data: NotificationCreateLocalizationDataInput
  locale: Locale
}

export type NotificationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<NotificationCreateLocalizationInput>>
}

export type NotificationCreateManyInlineInput = {
  /** Connect multiple existing Notification documents */
  connect?: InputMaybe<Array<NotificationWhereUniqueInput>>
  /** Create and connect multiple existing Notification documents */
  create?: InputMaybe<Array<NotificationCreateInput>>
}

export type NotificationCreateOneInlineInput = {
  /** Connect one existing Notification document */
  connect?: InputMaybe<NotificationWhereUniqueInput>
  /** Create and connect one Notification document */
  create?: InputMaybe<NotificationCreateInput>
}

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Notification
}

/** Identifies documents */
export type NotificationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NotificationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NotificationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NotificationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<NotificationWhereStageInput>
  documentInStages_none?: InputMaybe<NotificationWhereStageInput>
  documentInStages_some?: InputMaybe<NotificationWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  message_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  message_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  message_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  message_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  message_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  message_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  message_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum NotificationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type NotificationUpdateInput = {
  event?: InputMaybe<EventUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<NotificationUpdateLocalizationsInput>
  message?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type NotificationUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type NotificationUpdateLocalizationInput = {
  data: NotificationUpdateLocalizationDataInput
  locale: Locale
}

export type NotificationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<NotificationCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<NotificationUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<NotificationUpsertLocalizationInput>>
}

export type NotificationUpdateManyInlineInput = {
  /** Connect multiple existing Notification documents */
  connect?: InputMaybe<Array<NotificationConnectInput>>
  /** Create and connect multiple Notification documents */
  create?: InputMaybe<Array<NotificationCreateInput>>
  /** Delete multiple Notification documents */
  delete?: InputMaybe<Array<NotificationWhereUniqueInput>>
  /** Disconnect multiple Notification documents */
  disconnect?: InputMaybe<Array<NotificationWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Notification documents */
  set?: InputMaybe<Array<NotificationWhereUniqueInput>>
  /** Update multiple Notification documents */
  update?: InputMaybe<Array<NotificationUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Notification documents */
  upsert?: InputMaybe<Array<NotificationUpsertWithNestedWhereUniqueInput>>
}

export type NotificationUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<NotificationUpdateManyLocalizationsInput>
  message?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type NotificationUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type NotificationUpdateManyLocalizationInput = {
  data: NotificationUpdateManyLocalizationDataInput
  locale: Locale
}

export type NotificationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<NotificationUpdateManyLocalizationInput>>
}

export type NotificationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NotificationUpdateManyInput
  /** Document search */
  where: NotificationWhereInput
}

export type NotificationUpdateOneInlineInput = {
  /** Connect existing Notification document */
  connect?: InputMaybe<NotificationWhereUniqueInput>
  /** Create and connect one Notification document */
  create?: InputMaybe<NotificationCreateInput>
  /** Delete currently connected Notification document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Notification document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Notification document */
  update?: InputMaybe<NotificationUpdateWithNestedWhereUniqueInput>
  /** Upsert single Notification document */
  upsert?: InputMaybe<NotificationUpsertWithNestedWhereUniqueInput>
}

export type NotificationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NotificationUpdateInput
  /** Unique document search */
  where: NotificationWhereUniqueInput
}

export type NotificationUpsertInput = {
  /** Create document if it didn't exist */
  create: NotificationCreateInput
  /** Update document if it exists */
  update: NotificationUpdateInput
}

export type NotificationUpsertLocalizationInput = {
  create: NotificationCreateLocalizationDataInput
  locale: Locale
  update: NotificationUpdateLocalizationDataInput
}

export type NotificationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NotificationUpsertInput
  /** Unique document search */
  where: NotificationWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type NotificationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type NotificationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NotificationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NotificationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NotificationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<NotificationWhereStageInput>
  documentInStages_none?: InputMaybe<NotificationWhereStageInput>
  documentInStages_some?: InputMaybe<NotificationWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  message?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  message_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  message_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  message_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  message_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  message_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  message_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  message_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NotificationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NotificationWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NotificationWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NotificationWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NotificationWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Notification record uniquely */
export type NotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type OmrPartner = Entity &
  Node & {
    __typename?: 'OMRPartner'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<OmrPartner>
    /** List of OMRPartner versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<OmrPartner>
    logo: Asset
    name: Scalars['String']['output']
    partnerUrl?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type OmrPartnerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type OmrPartnerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type OmrPartnerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type OmrPartnerHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type OmrPartnerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type OmrPartnerLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type OmrPartnerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type OmrPartnerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type OmrPartnerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type OmrPartnerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type OmrPartnerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type OmrPartnerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: OmrPartnerWhereUniqueInput
}

/** A connection to a list of items. */
export type OmrPartnerConnection = {
  __typename?: 'OMRPartnerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<OmrPartnerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type OmrPartnerCreateInput = {
  clt4mem3805vq07umhpbmhrvj?: InputMaybe<SectionOmrPartnerCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<OmrPartnerCreateLocalizationsInput>
  logo: AssetCreateOneInlineInput
  name: Scalars['String']['input']
  omrHeader?: InputMaybe<HeaderCreateOneInlineInput>
  /** partnerUrl input for default locale (de) */
  partnerUrl?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type OmrPartnerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  partnerUrl?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type OmrPartnerCreateLocalizationInput = {
  /** Localization input */
  data: OmrPartnerCreateLocalizationDataInput
  locale: Locale
}

export type OmrPartnerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<OmrPartnerCreateLocalizationInput>>
}

export type OmrPartnerCreateManyInlineInput = {
  /** Connect multiple existing OMRPartner documents */
  connect?: InputMaybe<Array<OmrPartnerWhereUniqueInput>>
  /** Create and connect multiple existing OMRPartner documents */
  create?: InputMaybe<Array<OmrPartnerCreateInput>>
}

export type OmrPartnerCreateOneInlineInput = {
  /** Connect one existing OMRPartner document */
  connect?: InputMaybe<OmrPartnerWhereUniqueInput>
  /** Create and connect one OMRPartner document */
  create?: InputMaybe<OmrPartnerCreateInput>
}

/** An edge in a connection. */
export type OmrPartnerEdge = {
  __typename?: 'OMRPartnerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: OmrPartner
}

/** Identifies documents */
export type OmrPartnerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<OmrPartnerWhereStageInput>
  documentInStages_none?: InputMaybe<OmrPartnerWhereStageInput>
  documentInStages_some?: InputMaybe<OmrPartnerWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum OmrPartnerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerUrlAsc = 'partnerUrl_ASC',
  PartnerUrlDesc = 'partnerUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type OmrPartnerUpdateInput = {
  clt4mem3805vq07umhpbmhrvj?: InputMaybe<SectionOmrPartnerUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<OmrPartnerUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
  omrHeader?: InputMaybe<HeaderUpdateOneInlineInput>
  /** partnerUrl input for default locale (de) */
  partnerUrl?: InputMaybe<Scalars['String']['input']>
}

export type OmrPartnerUpdateLocalizationDataInput = {
  partnerUrl?: InputMaybe<Scalars['String']['input']>
}

export type OmrPartnerUpdateLocalizationInput = {
  data: OmrPartnerUpdateLocalizationDataInput
  locale: Locale
}

export type OmrPartnerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<OmrPartnerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<OmrPartnerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<OmrPartnerUpsertLocalizationInput>>
}

export type OmrPartnerUpdateManyInlineInput = {
  /** Connect multiple existing OMRPartner documents */
  connect?: InputMaybe<Array<OmrPartnerConnectInput>>
  /** Create and connect multiple OMRPartner documents */
  create?: InputMaybe<Array<OmrPartnerCreateInput>>
  /** Delete multiple OMRPartner documents */
  delete?: InputMaybe<Array<OmrPartnerWhereUniqueInput>>
  /** Disconnect multiple OMRPartner documents */
  disconnect?: InputMaybe<Array<OmrPartnerWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing OMRPartner documents */
  set?: InputMaybe<Array<OmrPartnerWhereUniqueInput>>
  /** Update multiple OMRPartner documents */
  update?: InputMaybe<Array<OmrPartnerUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple OMRPartner documents */
  upsert?: InputMaybe<Array<OmrPartnerUpsertWithNestedWhereUniqueInput>>
}

export type OmrPartnerUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<OmrPartnerUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** partnerUrl input for default locale (de) */
  partnerUrl?: InputMaybe<Scalars['String']['input']>
}

export type OmrPartnerUpdateManyLocalizationDataInput = {
  partnerUrl?: InputMaybe<Scalars['String']['input']>
}

export type OmrPartnerUpdateManyLocalizationInput = {
  data: OmrPartnerUpdateManyLocalizationDataInput
  locale: Locale
}

export type OmrPartnerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<OmrPartnerUpdateManyLocalizationInput>>
}

export type OmrPartnerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: OmrPartnerUpdateManyInput
  /** Document search */
  where: OmrPartnerWhereInput
}

export type OmrPartnerUpdateOneInlineInput = {
  /** Connect existing OMRPartner document */
  connect?: InputMaybe<OmrPartnerWhereUniqueInput>
  /** Create and connect one OMRPartner document */
  create?: InputMaybe<OmrPartnerCreateInput>
  /** Delete currently connected OMRPartner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected OMRPartner document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single OMRPartner document */
  update?: InputMaybe<OmrPartnerUpdateWithNestedWhereUniqueInput>
  /** Upsert single OMRPartner document */
  upsert?: InputMaybe<OmrPartnerUpsertWithNestedWhereUniqueInput>
}

export type OmrPartnerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: OmrPartnerUpdateInput
  /** Unique document search */
  where: OmrPartnerWhereUniqueInput
}

export type OmrPartnerUpsertInput = {
  /** Create document if it didn't exist */
  create: OmrPartnerCreateInput
  /** Update document if it exists */
  update: OmrPartnerUpdateInput
}

export type OmrPartnerUpsertLocalizationInput = {
  create: OmrPartnerCreateLocalizationDataInput
  locale: Locale
  update: OmrPartnerUpdateLocalizationDataInput
}

export type OmrPartnerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: OmrPartnerUpsertInput
  /** Unique document search */
  where: OmrPartnerWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type OmrPartnerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type OmrPartnerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OmrPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<OmrPartnerWhereStageInput>
  documentInStages_none?: InputMaybe<OmrPartnerWhereStageInput>
  documentInStages_some?: InputMaybe<OmrPartnerWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  partnerUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  partnerUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  partnerUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  partnerUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  partnerUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  partnerUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  partnerUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  partnerUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  partnerUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  partnerUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type OmrPartnerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OmrPartnerWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OmrPartnerWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OmrPartnerWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<OmrPartnerWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References OMRPartner record uniquely */
export type OmrPartnerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type OmrProductCard = Entity & {
  __typename?: 'OmrProductCard'
  ctaLink?: Maybe<Scalars['String']['output']>
  ctaText?: Maybe<Scalars['String']['output']>
  icon?: Maybe<Asset>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<OmrProductCard>
  /** System stage field */
  stage: Stage
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

export type OmrProductCardIconArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type OmrProductCardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type OmrProductCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: OmrProductCardWhereUniqueInput
}

/** A connection to a list of items. */
export type OmrProductCardConnection = {
  __typename?: 'OmrProductCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<OmrProductCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type OmrProductCardCreateInput = {
  /** ctaLink input for default locale (de) */
  ctaLink?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<AssetCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<OmrProductCardCreateLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type OmrProductCardCreateLocalizationDataInput = {
  ctaLink?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type OmrProductCardCreateLocalizationInput = {
  /** Localization input */
  data: OmrProductCardCreateLocalizationDataInput
  locale: Locale
}

export type OmrProductCardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<OmrProductCardCreateLocalizationInput>>
}

export type OmrProductCardCreateManyInlineInput = {
  /** Create and connect multiple existing OmrProductCard documents */
  create?: InputMaybe<Array<OmrProductCardCreateInput>>
}

export type OmrProductCardCreateOneInlineInput = {
  /** Create and connect one OmrProductCard document */
  create?: InputMaybe<OmrProductCardCreateInput>
}

export type OmrProductCardCreateWithPositionInput = {
  /** Document to create */
  data: OmrProductCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type OmrProductCardEdge = {
  __typename?: 'OmrProductCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: OmrProductCard
}

/** Identifies documents */
export type OmrProductCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum OmrProductCardOrderByInput {
  CtaLinkAsc = 'ctaLink_ASC',
  CtaLinkDesc = 'ctaLink_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type OmrProductCardParent = SectionOmrProductCard

export type OmrProductCardParentConnectInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardConnectInput>
}

export type OmrProductCardParentCreateInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardCreateInput>
}

export type OmrProductCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing OmrProductCardParent documents */
  create?: InputMaybe<Array<OmrProductCardParentCreateInput>>
}

export type OmrProductCardParentCreateOneInlineInput = {
  /** Create and connect one OmrProductCardParent document */
  create?: InputMaybe<OmrProductCardParentCreateInput>
}

export type OmrProductCardParentCreateWithPositionInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardCreateWithPositionInput>
}

export type OmrProductCardParentUpdateInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateInput>
}

export type OmrProductCardParentUpdateManyInlineInput = {
  /** Create and connect multiple OmrProductCardParent component instances */
  create?: InputMaybe<Array<OmrProductCardParentCreateWithPositionInput>>
  /** Delete multiple OmrProductCardParent documents */
  delete?: InputMaybe<Array<OmrProductCardParentWhereUniqueInput>>
  /** Update multiple OmrProductCardParent component instances */
  update?: InputMaybe<Array<OmrProductCardParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple OmrProductCardParent component instances */
  upsert?: InputMaybe<Array<OmrProductCardParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type OmrProductCardParentUpdateManyWithNestedWhereInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateManyWithNestedWhereInput>
}

export type OmrProductCardParentUpdateOneInlineInput = {
  /** Create and connect one OmrProductCardParent document */
  create?: InputMaybe<OmrProductCardParentCreateInput>
  /** Delete currently connected OmrProductCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single OmrProductCardParent document */
  update?: InputMaybe<OmrProductCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single OmrProductCardParent document */
  upsert?: InputMaybe<OmrProductCardParentUpsertWithNestedWhereUniqueInput>
}

export type OmrProductCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateWithNestedWhereUniqueAndPositionInput>
}

export type OmrProductCardParentUpdateWithNestedWhereUniqueInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpdateWithNestedWhereUniqueInput>
}

export type OmrProductCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpsertWithNestedWhereUniqueAndPositionInput>
}

export type OmrProductCardParentUpsertWithNestedWhereUniqueInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardUpsertWithNestedWhereUniqueInput>
}

export type OmrProductCardParentWhereInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardWhereInput>
}

export type OmrProductCardParentWhereUniqueInput = {
  SectionOmrProductCard?: InputMaybe<SectionOmrProductCardWhereUniqueInput>
}

export type OmrProductCardUpdateInput = {
  /** ctaLink input for default locale (de) */
  ctaLink?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<OmrProductCardUpdateLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type OmrProductCardUpdateLocalizationDataInput = {
  ctaLink?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type OmrProductCardUpdateLocalizationInput = {
  data: OmrProductCardUpdateLocalizationDataInput
  locale: Locale
}

export type OmrProductCardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<OmrProductCardCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<OmrProductCardUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<OmrProductCardUpsertLocalizationInput>>
}

export type OmrProductCardUpdateManyInlineInput = {
  /** Create and connect multiple OmrProductCard component instances */
  create?: InputMaybe<Array<OmrProductCardCreateWithPositionInput>>
  /** Delete multiple OmrProductCard documents */
  delete?: InputMaybe<Array<OmrProductCardWhereUniqueInput>>
  /** Update multiple OmrProductCard component instances */
  update?: InputMaybe<Array<OmrProductCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple OmrProductCard component instances */
  upsert?: InputMaybe<Array<OmrProductCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type OmrProductCardUpdateManyInput = {
  /** ctaLink input for default locale (de) */
  ctaLink?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<OmrProductCardUpdateManyLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type OmrProductCardUpdateManyLocalizationDataInput = {
  ctaLink?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type OmrProductCardUpdateManyLocalizationInput = {
  data: OmrProductCardUpdateManyLocalizationDataInput
  locale: Locale
}

export type OmrProductCardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<OmrProductCardUpdateManyLocalizationInput>>
}

export type OmrProductCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: OmrProductCardUpdateManyInput
  /** Document search */
  where: OmrProductCardWhereInput
}

export type OmrProductCardUpdateOneInlineInput = {
  /** Create and connect one OmrProductCard document */
  create?: InputMaybe<OmrProductCardCreateInput>
  /** Delete currently connected OmrProductCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single OmrProductCard document */
  update?: InputMaybe<OmrProductCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single OmrProductCard document */
  upsert?: InputMaybe<OmrProductCardUpsertWithNestedWhereUniqueInput>
}

export type OmrProductCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<OmrProductCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: OmrProductCardWhereUniqueInput
}

export type OmrProductCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: OmrProductCardUpdateInput
  /** Unique document search */
  where: OmrProductCardWhereUniqueInput
}

export type OmrProductCardUpsertInput = {
  /** Create document if it didn't exist */
  create: OmrProductCardCreateInput
  /** Update document if it exists */
  update: OmrProductCardUpdateInput
}

export type OmrProductCardUpsertLocalizationInput = {
  create: OmrProductCardCreateLocalizationDataInput
  locale: Locale
  update: OmrProductCardUpdateLocalizationDataInput
}

export type OmrProductCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<OmrProductCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: OmrProductCardWhereUniqueInput
}

export type OmrProductCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: OmrProductCardUpsertInput
  /** Unique document search */
  where: OmrProductCardWhereUniqueInput
}

/** Identifies documents */
export type OmrProductCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OmrProductCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaLink?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaLink_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaLink_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaLink_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaLink_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaLink_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  icon?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References OmrProductCard record uniquely */
export type OmrProductCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing a list of logos of our partner companies. */
export type OurPartner = Entity & {
  __typename?: 'OurPartner'
  headline: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<OurPartner>
  partner: Array<PartnerCompany>
  /** System stage field */
  stage: Stage
  subHeadline: Scalars['String']['output']
}

/** Section showing a list of logos of our partner companies. */
export type OurPartnerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing a list of logos of our partner companies. */
export type OurPartnerPartnerArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<PartnerCompanyWhereInput>
}

export type OurPartnerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: OurPartnerWhereUniqueInput
}

/** A connection to a list of items. */
export type OurPartnerConnection = {
  __typename?: 'OurPartnerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<OurPartnerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type OurPartnerCreateInput = {
  /** headline input for default locale (de) */
  headline: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<OurPartnerCreateLocalizationsInput>
  partner: PartnerCompanyCreateManyInlineInput
  /** subHeadline input for default locale (de) */
  subHeadline: Scalars['String']['input']
}

export type OurPartnerCreateLocalizationDataInput = {
  headline: Scalars['String']['input']
  subHeadline: Scalars['String']['input']
}

export type OurPartnerCreateLocalizationInput = {
  /** Localization input */
  data: OurPartnerCreateLocalizationDataInput
  locale: Locale
}

export type OurPartnerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<OurPartnerCreateLocalizationInput>>
}

export type OurPartnerCreateManyInlineInput = {
  /** Create and connect multiple existing OurPartner documents */
  create?: InputMaybe<Array<OurPartnerCreateInput>>
}

export type OurPartnerCreateOneInlineInput = {
  /** Create and connect one OurPartner document */
  create?: InputMaybe<OurPartnerCreateInput>
}

export type OurPartnerCreateWithPositionInput = {
  /** Document to create */
  data: OurPartnerCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type OurPartnerEdge = {
  __typename?: 'OurPartnerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: OurPartner
}

/** Identifies documents */
export type OurPartnerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partner_every?: InputMaybe<PartnerCompanyWhereInput>
  partner_none?: InputMaybe<PartnerCompanyWhereInput>
  partner_some?: InputMaybe<PartnerCompanyWhereInput>
}

export enum OurPartnerOrderByInput {
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubHeadlineAsc = 'subHeadline_ASC',
  SubHeadlineDesc = 'subHeadline_DESC',
}

export type OurPartnerUpdateInput = {
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<OurPartnerUpdateLocalizationsInput>
  partner?: InputMaybe<PartnerCompanyUpdateManyInlineInput>
  /** subHeadline input for default locale (de) */
  subHeadline?: InputMaybe<Scalars['String']['input']>
}

export type OurPartnerUpdateLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>
  subHeadline?: InputMaybe<Scalars['String']['input']>
}

export type OurPartnerUpdateLocalizationInput = {
  data: OurPartnerUpdateLocalizationDataInput
  locale: Locale
}

export type OurPartnerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<OurPartnerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<OurPartnerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<OurPartnerUpsertLocalizationInput>>
}

export type OurPartnerUpdateManyInlineInput = {
  /** Create and connect multiple OurPartner component instances */
  create?: InputMaybe<Array<OurPartnerCreateWithPositionInput>>
  /** Delete multiple OurPartner documents */
  delete?: InputMaybe<Array<OurPartnerWhereUniqueInput>>
  /** Update multiple OurPartner component instances */
  update?: InputMaybe<Array<OurPartnerUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple OurPartner component instances */
  upsert?: InputMaybe<Array<OurPartnerUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type OurPartnerUpdateManyInput = {
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<OurPartnerUpdateManyLocalizationsInput>
  /** subHeadline input for default locale (de) */
  subHeadline?: InputMaybe<Scalars['String']['input']>
}

export type OurPartnerUpdateManyLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>
  subHeadline?: InputMaybe<Scalars['String']['input']>
}

export type OurPartnerUpdateManyLocalizationInput = {
  data: OurPartnerUpdateManyLocalizationDataInput
  locale: Locale
}

export type OurPartnerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<OurPartnerUpdateManyLocalizationInput>>
}

export type OurPartnerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: OurPartnerUpdateManyInput
  /** Document search */
  where: OurPartnerWhereInput
}

export type OurPartnerUpdateOneInlineInput = {
  /** Create and connect one OurPartner document */
  create?: InputMaybe<OurPartnerCreateInput>
  /** Delete currently connected OurPartner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single OurPartner document */
  update?: InputMaybe<OurPartnerUpdateWithNestedWhereUniqueInput>
  /** Upsert single OurPartner document */
  upsert?: InputMaybe<OurPartnerUpsertWithNestedWhereUniqueInput>
}

export type OurPartnerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<OurPartnerUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: OurPartnerWhereUniqueInput
}

export type OurPartnerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: OurPartnerUpdateInput
  /** Unique document search */
  where: OurPartnerWhereUniqueInput
}

export type OurPartnerUpsertInput = {
  /** Create document if it didn't exist */
  create: OurPartnerCreateInput
  /** Update document if it exists */
  update: OurPartnerUpdateInput
}

export type OurPartnerUpsertLocalizationInput = {
  create: OurPartnerCreateLocalizationDataInput
  locale: Locale
  update: OurPartnerUpdateLocalizationDataInput
}

export type OurPartnerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<OurPartnerUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: OurPartnerWhereUniqueInput
}

export type OurPartnerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: OurPartnerUpsertInput
  /** Unique document search */
  where: OurPartnerWhereUniqueInput
}

/** Identifies documents */
export type OurPartnerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<OurPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  headline?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  headline_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  headline_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  headline_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  headline_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  headline_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  headline_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  headline_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  partner_every?: InputMaybe<PartnerCompanyWhereInput>
  partner_none?: InputMaybe<PartnerCompanyWhereInput>
  partner_some?: InputMaybe<PartnerCompanyWhereInput>
  subHeadline?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subHeadline_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subHeadline_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subHeadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subHeadline_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subHeadline_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subHeadline_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subHeadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subHeadline_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subHeadline_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References OurPartner record uniquely */
export type OurPartnerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']['output']>
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

/** Partner companies */
export type PartnerCompany = Entity &
  Node & {
    __typename?: 'PartnerCompany'
    /** Background image used on partner list items */
    backgroundImage?: Maybe<Asset>
    booths: Array<Booth>
    contact_id?: Maybe<Scalars['Int']['output']>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    displayed_on_website?: Maybe<Scalars['Boolean']['output']>
    /** Get the document in other stages */
    documentInStages: Array<PartnerCompany>
    eventsAsExhibitor: Array<Event>
    facebook?: Maybe<Scalars['String']['output']>
    /** List of PartnerCompany versions */
    history: Array<Version>
    /** Set order of partner company at omr.com page. Partner company will be not listed if value not provided */
    homePageOrder?: Maybe<Scalars['Int']['output']>
    /** The unique identifier */
    id: Scalars['ID']['output']
    instagram?: Maybe<Scalars['String']['output']>
    interests: Array<Interest>
    linkedIn?: Maybe<Scalars['String']['output']>
    linkedin?: Maybe<Scalars['String']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<PartnerCompany>
    logo?: Maybe<Asset>
    logoUrl?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    omrJobsUrl?: Maybe<Scalars['String']['output']>
    /** Custom partner page url */
    pageUrl?: Maybe<Scalars['String']['output']>
    premium?: Maybe<Scalars['Boolean']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    reviewsProducts: Array<ReviewsProduct>
    scheduledIn: Array<ScheduledOperation>
    shortDescription?: Maybe<Scalars['String']['output']>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    twitter?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    webLogoUrl?: Maybe<Scalars['String']['output']>
    website?: Maybe<Scalars['String']['output']>
    youtube?: Maybe<Scalars['String']['output']>
  }

/** Partner companies */
export type PartnerCompanyBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Partner companies */
export type PartnerCompanyBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BoothOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BoothWhereInput>
}

/** Partner companies */
export type PartnerCompanyCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Partner companies */
export type PartnerCompanyCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Partner companies */
export type PartnerCompanyDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Partner companies */
export type PartnerCompanyEventsAsExhibitorArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

/** Partner companies */
export type PartnerCompanyHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Partner companies */
export type PartnerCompanyInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<InterestOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InterestWhereInput>
}

/** Partner companies */
export type PartnerCompanyLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Partner companies */
export type PartnerCompanyLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Partner companies */
export type PartnerCompanyPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Partner companies */
export type PartnerCompanyPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Partner companies */
export type PartnerCompanyReviewsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWhereInput>
}

/** Partner companies */
export type PartnerCompanyScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Partner companies */
export type PartnerCompanyUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Partner companies */
export type PartnerCompanyUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PartnerCompanyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PartnerCompanyWhereUniqueInput
}

/** A connection to a list of items. */
export type PartnerCompanyConnection = {
  __typename?: 'PartnerCompanyConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PartnerCompanyEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PartnerCompanyCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>
  booths?: InputMaybe<BoothCreateManyInlineInput>
  contact_id?: InputMaybe<Scalars['Int']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayed_on_website?: InputMaybe<Scalars['Boolean']['input']>
  eventsAsExhibitor?: InputMaybe<EventCreateManyInlineInput>
  exhibitorOrder?: InputMaybe<ExhibitorListOrderCreateManyInlineInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  homePageOrder?: InputMaybe<Scalars['Int']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<InterestCreateManyInlineInput>
  linkedIn?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PartnerCompanyCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  ourPartner?: InputMaybe<OurPartnerCreateOneInlineInput>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  reviewsProducts?: InputMaybe<ReviewsProductCreateManyInlineInput>
  /** shortDescription input for default locale (de) */
  shortDescription?: InputMaybe<Scalars['String']['input']>
  slug: Scalars['String']['input']
  speaker?: InputMaybe<SpeakerCreateOneInlineInput>
  twitter?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  webLogoUrl?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type PartnerCompanyCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PartnerCompanyCreateLocalizationInput = {
  /** Localization input */
  data: PartnerCompanyCreateLocalizationDataInput
  locale: Locale
}

export type PartnerCompanyCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PartnerCompanyCreateLocalizationInput>>
}

export type PartnerCompanyCreateManyInlineInput = {
  /** Connect multiple existing PartnerCompany documents */
  connect?: InputMaybe<Array<PartnerCompanyWhereUniqueInput>>
  /** Create and connect multiple existing PartnerCompany documents */
  create?: InputMaybe<Array<PartnerCompanyCreateInput>>
}

export type PartnerCompanyCreateOneInlineInput = {
  /** Connect one existing PartnerCompany document */
  connect?: InputMaybe<PartnerCompanyWhereUniqueInput>
  /** Create and connect one PartnerCompany document */
  create?: InputMaybe<PartnerCompanyCreateInput>
}

/** An edge in a connection. */
export type PartnerCompanyEdge = {
  __typename?: 'PartnerCompanyEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PartnerCompany
}

/** Identifies documents */
export type PartnerCompanyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImage?: InputMaybe<AssetWhereInput>
  booths_every?: InputMaybe<BoothWhereInput>
  booths_none?: InputMaybe<BoothWhereInput>
  booths_some?: InputMaybe<BoothWhereInput>
  contact_id?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  contact_id_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  contact_id_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  contact_id_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  contact_id_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  contact_id_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  contact_id_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  contact_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  displayed_on_website?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayed_on_website_not?: InputMaybe<Scalars['Boolean']['input']>
  documentInStages_every?: InputMaybe<PartnerCompanyWhereStageInput>
  documentInStages_none?: InputMaybe<PartnerCompanyWhereStageInput>
  documentInStages_some?: InputMaybe<PartnerCompanyWhereStageInput>
  eventsAsExhibitor_every?: InputMaybe<EventWhereInput>
  eventsAsExhibitor_none?: InputMaybe<EventWhereInput>
  eventsAsExhibitor_some?: InputMaybe<EventWhereInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebook_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebook_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebook_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebook_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebook_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebook_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebook_starts_with?: InputMaybe<Scalars['String']['input']>
  homePageOrder?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  homePageOrder_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  homePageOrder_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  homePageOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  homePageOrder_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  homePageOrder_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  homePageOrder_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  homePageOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagram_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagram_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagram_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagram_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagram_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagram_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagram_starts_with?: InputMaybe<Scalars['String']['input']>
  interests_every?: InputMaybe<InterestWhereInput>
  interests_none?: InputMaybe<InterestWhereInput>
  interests_some?: InputMaybe<InterestWhereInput>
  linkedIn?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedIn_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedIn_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedIn_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedIn_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedIn_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedIn_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedIn_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedin_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedin_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedin_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedin_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedin_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedin_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedin_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  omrJobsUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  omrJobsUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  omrJobsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  omrJobsUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  omrJobsUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  omrJobsUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  omrJobsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  omrJobsUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  omrJobsUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premium_not?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitter_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitter_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitter_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitter_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitter_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitter_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitter_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  webLogoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  webLogoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  webLogoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  webLogoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  webLogoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  webLogoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  webLogoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  webLogoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  webLogoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  webLogoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youtube_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youtube_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youtube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youtube_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youtube_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youtube_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youtube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youtube_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youtube_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum PartnerCompanyOrderByInput {
  ContactIdAsc = 'contact_id_ASC',
  ContactIdDesc = 'contact_id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayedOnWebsiteAsc = 'displayed_on_website_ASC',
  DisplayedOnWebsiteDesc = 'displayed_on_website_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  HomePageOrderAsc = 'homePageOrder_ASC',
  HomePageOrderDesc = 'homePageOrder_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InstagramAsc = 'instagram_ASC',
  InstagramDesc = 'instagram_DESC',
  LinkedInAsc = 'linkedIn_ASC',
  LinkedInDesc = 'linkedIn_DESC',
  LinkedinAsc = 'linkedin_ASC',
  LinkedinDesc = 'linkedin_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OmrJobsUrlAsc = 'omrJobsUrl_ASC',
  OmrJobsUrlDesc = 'omrJobsUrl_DESC',
  PageUrlAsc = 'pageUrl_ASC',
  PageUrlDesc = 'pageUrl_DESC',
  PremiumAsc = 'premium_ASC',
  PremiumDesc = 'premium_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WebLogoUrlAsc = 'webLogoUrl_ASC',
  WebLogoUrlDesc = 'webLogoUrl_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  YoutubeAsc = 'youtube_ASC',
  YoutubeDesc = 'youtube_DESC',
}

export type PartnerCompanyUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>
  booths?: InputMaybe<BoothUpdateManyInlineInput>
  contact_id?: InputMaybe<Scalars['Int']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayed_on_website?: InputMaybe<Scalars['Boolean']['input']>
  eventsAsExhibitor?: InputMaybe<EventUpdateManyInlineInput>
  exhibitorOrder?: InputMaybe<ExhibitorListOrderUpdateManyInlineInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  homePageOrder?: InputMaybe<Scalars['Int']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<InterestUpdateManyInlineInput>
  linkedIn?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<PartnerCompanyUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  ourPartner?: InputMaybe<OurPartnerUpdateOneInlineInput>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  reviewsProducts?: InputMaybe<ReviewsProductUpdateManyInlineInput>
  /** shortDescription input for default locale (de) */
  shortDescription?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  speaker?: InputMaybe<SpeakerUpdateOneInlineInput>
  twitter?: InputMaybe<Scalars['String']['input']>
  webLogoUrl?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type PartnerCompanyUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  shortDescription?: InputMaybe<Scalars['String']['input']>
}

export type PartnerCompanyUpdateLocalizationInput = {
  data: PartnerCompanyUpdateLocalizationDataInput
  locale: Locale
}

export type PartnerCompanyUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PartnerCompanyCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<PartnerCompanyUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<PartnerCompanyUpsertLocalizationInput>>
}

export type PartnerCompanyUpdateManyInlineInput = {
  /** Connect multiple existing PartnerCompany documents */
  connect?: InputMaybe<Array<PartnerCompanyConnectInput>>
  /** Create and connect multiple PartnerCompany documents */
  create?: InputMaybe<Array<PartnerCompanyCreateInput>>
  /** Delete multiple PartnerCompany documents */
  delete?: InputMaybe<Array<PartnerCompanyWhereUniqueInput>>
  /** Disconnect multiple PartnerCompany documents */
  disconnect?: InputMaybe<Array<PartnerCompanyWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PartnerCompany documents */
  set?: InputMaybe<Array<PartnerCompanyWhereUniqueInput>>
  /** Update multiple PartnerCompany documents */
  update?: InputMaybe<Array<PartnerCompanyUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PartnerCompany documents */
  upsert?: InputMaybe<Array<PartnerCompanyUpsertWithNestedWhereUniqueInput>>
}

export type PartnerCompanyUpdateManyInput = {
  contact_id?: InputMaybe<Scalars['Int']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayed_on_website?: InputMaybe<Scalars['Boolean']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  linkedIn?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<PartnerCompanyUpdateManyLocalizationsInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  /** shortDescription input for default locale (de) */
  shortDescription?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  webLogoUrl?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type PartnerCompanyUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  shortDescription?: InputMaybe<Scalars['String']['input']>
}

export type PartnerCompanyUpdateManyLocalizationInput = {
  data: PartnerCompanyUpdateManyLocalizationDataInput
  locale: Locale
}

export type PartnerCompanyUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PartnerCompanyUpdateManyLocalizationInput>>
}

export type PartnerCompanyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PartnerCompanyUpdateManyInput
  /** Document search */
  where: PartnerCompanyWhereInput
}

export type PartnerCompanyUpdateOneInlineInput = {
  /** Connect existing PartnerCompany document */
  connect?: InputMaybe<PartnerCompanyWhereUniqueInput>
  /** Create and connect one PartnerCompany document */
  create?: InputMaybe<PartnerCompanyCreateInput>
  /** Delete currently connected PartnerCompany document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PartnerCompany document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PartnerCompany document */
  update?: InputMaybe<PartnerCompanyUpdateWithNestedWhereUniqueInput>
  /** Upsert single PartnerCompany document */
  upsert?: InputMaybe<PartnerCompanyUpsertWithNestedWhereUniqueInput>
}

export type PartnerCompanyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PartnerCompanyUpdateInput
  /** Unique document search */
  where: PartnerCompanyWhereUniqueInput
}

export type PartnerCompanyUpsertInput = {
  /** Create document if it didn't exist */
  create: PartnerCompanyCreateInput
  /** Update document if it exists */
  update: PartnerCompanyUpdateInput
}

export type PartnerCompanyUpsertLocalizationInput = {
  create: PartnerCompanyCreateLocalizationDataInput
  locale: Locale
  update: PartnerCompanyUpdateLocalizationDataInput
}

export type PartnerCompanyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PartnerCompanyUpsertInput
  /** Unique document search */
  where: PartnerCompanyWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type PartnerCompanyWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type PartnerCompanyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerCompanyWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImage?: InputMaybe<AssetWhereInput>
  booths_every?: InputMaybe<BoothWhereInput>
  booths_none?: InputMaybe<BoothWhereInput>
  booths_some?: InputMaybe<BoothWhereInput>
  contact_id?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  contact_id_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  contact_id_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  contact_id_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  contact_id_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  contact_id_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  contact_id_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  contact_id_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  displayed_on_website?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayed_on_website_not?: InputMaybe<Scalars['Boolean']['input']>
  documentInStages_every?: InputMaybe<PartnerCompanyWhereStageInput>
  documentInStages_none?: InputMaybe<PartnerCompanyWhereStageInput>
  documentInStages_some?: InputMaybe<PartnerCompanyWhereStageInput>
  eventsAsExhibitor_every?: InputMaybe<EventWhereInput>
  eventsAsExhibitor_none?: InputMaybe<EventWhereInput>
  eventsAsExhibitor_some?: InputMaybe<EventWhereInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebook_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebook_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebook_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebook_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebook_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebook_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebook_starts_with?: InputMaybe<Scalars['String']['input']>
  homePageOrder?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  homePageOrder_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  homePageOrder_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  homePageOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  homePageOrder_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  homePageOrder_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  homePageOrder_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  homePageOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagram_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagram_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagram_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagram_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagram_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagram_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagram_starts_with?: InputMaybe<Scalars['String']['input']>
  interests_every?: InputMaybe<InterestWhereInput>
  interests_none?: InputMaybe<InterestWhereInput>
  interests_some?: InputMaybe<InterestWhereInput>
  linkedIn?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedIn_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedIn_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedIn_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedIn_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedIn_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedIn_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedIn_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedIn_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedIn_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedin_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedin_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedin_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedin_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedin_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedin_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedin_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  omrJobsUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  omrJobsUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  omrJobsUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  omrJobsUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  omrJobsUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  omrJobsUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  omrJobsUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  omrJobsUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  omrJobsUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premium_not?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  shortDescription?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  shortDescription_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  shortDescription_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  shortDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  shortDescription_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  shortDescription_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  shortDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  shortDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  shortDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  shortDescription_starts_with?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitter_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitter_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitter_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitter_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitter_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitter_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitter_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  webLogoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  webLogoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  webLogoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  webLogoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  webLogoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  webLogoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  webLogoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  webLogoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  webLogoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  webLogoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youtube_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youtube_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youtube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youtube_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youtube_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youtube_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youtube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youtube_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youtube_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PartnerCompanyWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerCompanyWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerCompanyWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerCompanyWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PartnerCompanyWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References PartnerCompany record uniquely */
export type PartnerCompanyWhereUniqueInput = {
  homePageOrder?: InputMaybe<Scalars['Int']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PhysicalLocation = Entity &
  Node & {
    __typename?: 'PhysicalLocation'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<PhysicalLocation>
    /** List of PhysicalLocation versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<PhysicalLocation>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type PhysicalLocationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type PhysicalLocationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PhysicalLocationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type PhysicalLocationHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type PhysicalLocationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type PhysicalLocationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type PhysicalLocationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PhysicalLocationScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type PhysicalLocationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type PhysicalLocationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PhysicalLocationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PhysicalLocationWhereUniqueInput
}

/** A connection to a list of items. */
export type PhysicalLocationConnection = {
  __typename?: 'PhysicalLocationConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PhysicalLocationEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PhysicalLocationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PhysicalLocationCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  physicalLocation?: InputMaybe<BoothCreateOneInlineInput>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PhysicalLocationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PhysicalLocationCreateLocalizationInput = {
  /** Localization input */
  data: PhysicalLocationCreateLocalizationDataInput
  locale: Locale
}

export type PhysicalLocationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PhysicalLocationCreateLocalizationInput>>
}

export type PhysicalLocationCreateManyInlineInput = {
  /** Connect multiple existing PhysicalLocation documents */
  connect?: InputMaybe<Array<PhysicalLocationWhereUniqueInput>>
  /** Create and connect multiple existing PhysicalLocation documents */
  create?: InputMaybe<Array<PhysicalLocationCreateInput>>
}

export type PhysicalLocationCreateOneInlineInput = {
  /** Connect one existing PhysicalLocation document */
  connect?: InputMaybe<PhysicalLocationWhereUniqueInput>
  /** Create and connect one PhysicalLocation document */
  create?: InputMaybe<PhysicalLocationCreateInput>
}

/** An edge in a connection. */
export type PhysicalLocationEdge = {
  __typename?: 'PhysicalLocationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PhysicalLocation
}

/** Identifies documents */
export type PhysicalLocationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<PhysicalLocationWhereStageInput>
  documentInStages_none?: InputMaybe<PhysicalLocationWhereStageInput>
  documentInStages_some?: InputMaybe<PhysicalLocationWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum PhysicalLocationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PhysicalLocationUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<PhysicalLocationUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  physicalLocation?: InputMaybe<BoothUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PhysicalLocationUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type PhysicalLocationUpdateLocalizationInput = {
  data: PhysicalLocationUpdateLocalizationDataInput
  locale: Locale
}

export type PhysicalLocationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PhysicalLocationCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<PhysicalLocationUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<PhysicalLocationUpsertLocalizationInput>>
}

export type PhysicalLocationUpdateManyInlineInput = {
  /** Connect multiple existing PhysicalLocation documents */
  connect?: InputMaybe<Array<PhysicalLocationConnectInput>>
  /** Create and connect multiple PhysicalLocation documents */
  create?: InputMaybe<Array<PhysicalLocationCreateInput>>
  /** Delete multiple PhysicalLocation documents */
  delete?: InputMaybe<Array<PhysicalLocationWhereUniqueInput>>
  /** Disconnect multiple PhysicalLocation documents */
  disconnect?: InputMaybe<Array<PhysicalLocationWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PhysicalLocation documents */
  set?: InputMaybe<Array<PhysicalLocationWhereUniqueInput>>
  /** Update multiple PhysicalLocation documents */
  update?: InputMaybe<Array<PhysicalLocationUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PhysicalLocation documents */
  upsert?: InputMaybe<Array<PhysicalLocationUpsertWithNestedWhereUniqueInput>>
}

export type PhysicalLocationUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<PhysicalLocationUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type PhysicalLocationUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type PhysicalLocationUpdateManyLocalizationInput = {
  data: PhysicalLocationUpdateManyLocalizationDataInput
  locale: Locale
}

export type PhysicalLocationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PhysicalLocationUpdateManyLocalizationInput>>
}

export type PhysicalLocationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PhysicalLocationUpdateManyInput
  /** Document search */
  where: PhysicalLocationWhereInput
}

export type PhysicalLocationUpdateOneInlineInput = {
  /** Connect existing PhysicalLocation document */
  connect?: InputMaybe<PhysicalLocationWhereUniqueInput>
  /** Create and connect one PhysicalLocation document */
  create?: InputMaybe<PhysicalLocationCreateInput>
  /** Delete currently connected PhysicalLocation document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PhysicalLocation document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PhysicalLocation document */
  update?: InputMaybe<PhysicalLocationUpdateWithNestedWhereUniqueInput>
  /** Upsert single PhysicalLocation document */
  upsert?: InputMaybe<PhysicalLocationUpsertWithNestedWhereUniqueInput>
}

export type PhysicalLocationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PhysicalLocationUpdateInput
  /** Unique document search */
  where: PhysicalLocationWhereUniqueInput
}

export type PhysicalLocationUpsertInput = {
  /** Create document if it didn't exist */
  create: PhysicalLocationCreateInput
  /** Update document if it exists */
  update: PhysicalLocationUpdateInput
}

export type PhysicalLocationUpsertLocalizationInput = {
  create: PhysicalLocationCreateLocalizationDataInput
  locale: Locale
  update: PhysicalLocationUpdateLocalizationDataInput
}

export type PhysicalLocationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PhysicalLocationUpsertInput
  /** Unique document search */
  where: PhysicalLocationWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type PhysicalLocationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type PhysicalLocationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PhysicalLocationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<PhysicalLocationWhereStageInput>
  documentInStages_none?: InputMaybe<PhysicalLocationWhereStageInput>
  documentInStages_some?: InputMaybe<PhysicalLocationWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PhysicalLocationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PhysicalLocationWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PhysicalLocationWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PhysicalLocationWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PhysicalLocationWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References PhysicalLocation record uniquely */
export type PhysicalLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Podigee podcast episodes */
export type PodcastEpisode = Entity &
  Node & {
    __typename?: 'PodcastEpisode'
    authors: Scalars['String']['output']
    coverImage?: Maybe<Scalars['String']['output']>
    createDateTime: Scalars['DateTime']['output']
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Description of the podcast episode */
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<PodcastEpisode>
    duration: Scalars['Int']['output']
    /** List of PodcastEpisode versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    lastSyncTimestamp?: Maybe<Scalars['DateTime']['output']>
    number: Scalars['Int']['output']
    permalink: Scalars['String']['output']
    /** Name of the podcast */
    podcastName: Scalars['String']['output']
    /** Podigee podcast episode id */
    podigeeId: Scalars['Int']['output']
    /** Podigee podcast id */
    podigeePodcastId: Scalars['Int']['output']
    publishDateTime: Scalars['DateTime']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    season: Scalars['Int']['output']
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** Subtitle of the podcast episode */
    subtitle?: Maybe<Scalars['String']['output']>
    /** Title of the podcast episode */
    title: Scalars['String']['output']
    updateDateTime: Scalars['DateTime']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Podigee podcast episodes */
export type PodcastEpisodeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podigee podcast episodes */
export type PodcastEpisodeDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Podigee podcast episodes */
export type PodcastEpisodeHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Podigee podcast episodes */
export type PodcastEpisodePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podigee podcast episodes */
export type PodcastEpisodeScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Podigee podcast episodes */
export type PodcastEpisodeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PodcastEpisodeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PodcastEpisodeWhereUniqueInput
}

/** A connection to a list of items. */
export type PodcastEpisodeConnection = {
  __typename?: 'PodcastEpisodeConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PodcastEpisodeEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PodcastEpisodeCreateInput = {
  authors: Scalars['String']['input']
  coverImage?: InputMaybe<Scalars['String']['input']>
  createDateTime: Scalars['DateTime']['input']
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  duration: Scalars['Int']['input']
  lastSyncTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  number: Scalars['Int']['input']
  permalink: Scalars['String']['input']
  podcastName: Scalars['String']['input']
  podigeeId: Scalars['Int']['input']
  podigeePodcastId: Scalars['Int']['input']
  publishDateTime: Scalars['DateTime']['input']
  season: Scalars['Int']['input']
  slug: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  updateDateTime: Scalars['DateTime']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PodcastEpisodeCreateManyInlineInput = {
  /** Connect multiple existing PodcastEpisode documents */
  connect?: InputMaybe<Array<PodcastEpisodeWhereUniqueInput>>
  /** Create and connect multiple existing PodcastEpisode documents */
  create?: InputMaybe<Array<PodcastEpisodeCreateInput>>
}

export type PodcastEpisodeCreateOneInlineInput = {
  /** Connect one existing PodcastEpisode document */
  connect?: InputMaybe<PodcastEpisodeWhereUniqueInput>
  /** Create and connect one PodcastEpisode document */
  create?: InputMaybe<PodcastEpisodeCreateInput>
}

/** An edge in a connection. */
export type PodcastEpisodeEdge = {
  __typename?: 'PodcastEpisodeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PodcastEpisode
}

/** Identifies documents */
export type PodcastEpisodeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  authors?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  authors_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  authors_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  authors_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  authors_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  authors_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  authors_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  authors_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  authors_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  authors_starts_with?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  coverImage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  coverImage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  coverImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  coverImage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  coverImage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  coverImage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  coverImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  coverImage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  coverImage_starts_with?: InputMaybe<Scalars['String']['input']>
  createDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<PodcastEpisodeWhereStageInput>
  documentInStages_none?: InputMaybe<PodcastEpisodeWhereStageInput>
  documentInStages_some?: InputMaybe<PodcastEpisodeWhereStageInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  duration_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  duration_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  duration_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  duration_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  lastSyncTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  lastSyncTimestamp_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  lastSyncTimestamp_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  lastSyncTimestamp_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  lastSyncTimestamp_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  lastSyncTimestamp_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  lastSyncTimestamp_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  lastSyncTimestamp_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  number?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  number_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  number_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  number_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  number_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  number_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  permalink?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  permalink_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  permalink_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  permalink_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  permalink_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  permalink_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  permalink_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  permalink_starts_with?: InputMaybe<Scalars['String']['input']>
  podcastName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  podcastName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  podcastName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  podcastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  podcastName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  podcastName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  podcastName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  podcastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  podcastName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  podcastName_starts_with?: InputMaybe<Scalars['String']['input']>
  podigeeId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  podigeeId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  podigeeId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  podigeeId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  podigeeId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  podigeeId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  podigeeId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  podigeeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  podigeePodcastId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  podigeePodcastId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  podigeePodcastId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  podigeePodcastId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  podigeePodcastId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  podigeePodcastId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  podigeePodcastId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  podigeePodcastId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  season?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  season_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  season_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  season_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  season_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  season_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  season_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  season_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updateDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updateDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updateDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updateDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updateDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updateDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updateDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updateDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum PodcastEpisodeOrderByInput {
  AuthorsAsc = 'authors_ASC',
  AuthorsDesc = 'authors_DESC',
  CoverImageAsc = 'coverImage_ASC',
  CoverImageDesc = 'coverImage_DESC',
  CreateDateTimeAsc = 'createDateTime_ASC',
  CreateDateTimeDesc = 'createDateTime_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LastSyncTimestampAsc = 'lastSyncTimestamp_ASC',
  LastSyncTimestampDesc = 'lastSyncTimestamp_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  PermalinkAsc = 'permalink_ASC',
  PermalinkDesc = 'permalink_DESC',
  PodcastNameAsc = 'podcastName_ASC',
  PodcastNameDesc = 'podcastName_DESC',
  PodigeeIdAsc = 'podigeeId_ASC',
  PodigeeIdDesc = 'podigeeId_DESC',
  PodigeePodcastIdAsc = 'podigeePodcastId_ASC',
  PodigeePodcastIdDesc = 'podigeePodcastId_DESC',
  PublishDateTimeAsc = 'publishDateTime_ASC',
  PublishDateTimeDesc = 'publishDateTime_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SeasonAsc = 'season_ASC',
  SeasonDesc = 'season_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdateDateTimeAsc = 'updateDateTime_ASC',
  UpdateDateTimeDesc = 'updateDateTime_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PodcastEpisodeUpdateInput = {
  authors?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<Scalars['String']['input']>
  createDateTime?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<Scalars['Int']['input']>
  lastSyncTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  number?: InputMaybe<Scalars['Int']['input']>
  permalink?: InputMaybe<Scalars['String']['input']>
  podcastName?: InputMaybe<Scalars['String']['input']>
  podigeeId?: InputMaybe<Scalars['Int']['input']>
  podigeePodcastId?: InputMaybe<Scalars['Int']['input']>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  season?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updateDateTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type PodcastEpisodeUpdateManyInlineInput = {
  /** Connect multiple existing PodcastEpisode documents */
  connect?: InputMaybe<Array<PodcastEpisodeConnectInput>>
  /** Create and connect multiple PodcastEpisode documents */
  create?: InputMaybe<Array<PodcastEpisodeCreateInput>>
  /** Delete multiple PodcastEpisode documents */
  delete?: InputMaybe<Array<PodcastEpisodeWhereUniqueInput>>
  /** Disconnect multiple PodcastEpisode documents */
  disconnect?: InputMaybe<Array<PodcastEpisodeWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PodcastEpisode documents */
  set?: InputMaybe<Array<PodcastEpisodeWhereUniqueInput>>
  /** Update multiple PodcastEpisode documents */
  update?: InputMaybe<Array<PodcastEpisodeUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PodcastEpisode documents */
  upsert?: InputMaybe<Array<PodcastEpisodeUpsertWithNestedWhereUniqueInput>>
}

export type PodcastEpisodeUpdateManyInput = {
  authors?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<Scalars['String']['input']>
  createDateTime?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  duration?: InputMaybe<Scalars['Int']['input']>
  lastSyncTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  number?: InputMaybe<Scalars['Int']['input']>
  podcastName?: InputMaybe<Scalars['String']['input']>
  podigeePodcastId?: InputMaybe<Scalars['Int']['input']>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  season?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updateDateTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type PodcastEpisodeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PodcastEpisodeUpdateManyInput
  /** Document search */
  where: PodcastEpisodeWhereInput
}

export type PodcastEpisodeUpdateOneInlineInput = {
  /** Connect existing PodcastEpisode document */
  connect?: InputMaybe<PodcastEpisodeWhereUniqueInput>
  /** Create and connect one PodcastEpisode document */
  create?: InputMaybe<PodcastEpisodeCreateInput>
  /** Delete currently connected PodcastEpisode document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PodcastEpisode document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PodcastEpisode document */
  update?: InputMaybe<PodcastEpisodeUpdateWithNestedWhereUniqueInput>
  /** Upsert single PodcastEpisode document */
  upsert?: InputMaybe<PodcastEpisodeUpsertWithNestedWhereUniqueInput>
}

export type PodcastEpisodeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PodcastEpisodeUpdateInput
  /** Unique document search */
  where: PodcastEpisodeWhereUniqueInput
}

export type PodcastEpisodeUpsertInput = {
  /** Create document if it didn't exist */
  create: PodcastEpisodeCreateInput
  /** Update document if it exists */
  update: PodcastEpisodeUpdateInput
}

export type PodcastEpisodeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PodcastEpisodeUpsertInput
  /** Unique document search */
  where: PodcastEpisodeWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type PodcastEpisodeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type PodcastEpisodeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastEpisodeWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  authors?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  authors_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  authors_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  authors_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  authors_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  authors_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  authors_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  authors_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  authors_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  authors_starts_with?: InputMaybe<Scalars['String']['input']>
  coverImage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  coverImage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  coverImage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  coverImage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  coverImage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  coverImage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  coverImage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  coverImage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  coverImage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  coverImage_starts_with?: InputMaybe<Scalars['String']['input']>
  createDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<PodcastEpisodeWhereStageInput>
  documentInStages_none?: InputMaybe<PodcastEpisodeWhereStageInput>
  documentInStages_some?: InputMaybe<PodcastEpisodeWhereStageInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  duration_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  duration_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  duration_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  duration_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  lastSyncTimestamp?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  lastSyncTimestamp_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  lastSyncTimestamp_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  lastSyncTimestamp_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  lastSyncTimestamp_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  lastSyncTimestamp_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  lastSyncTimestamp_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  lastSyncTimestamp_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  number?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  number_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  number_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  number_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  number_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  number_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  number_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  permalink?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  permalink_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  permalink_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  permalink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  permalink_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  permalink_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  permalink_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  permalink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  permalink_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  permalink_starts_with?: InputMaybe<Scalars['String']['input']>
  podcastName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  podcastName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  podcastName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  podcastName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  podcastName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  podcastName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  podcastName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  podcastName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  podcastName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  podcastName_starts_with?: InputMaybe<Scalars['String']['input']>
  podigeeId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  podigeeId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  podigeeId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  podigeeId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  podigeeId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  podigeeId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  podigeeId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  podigeeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  podigeePodcastId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  podigeePodcastId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  podigeePodcastId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  podigeePodcastId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  podigeePodcastId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  podigeePodcastId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  podigeePodcastId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  podigeePodcastId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  publishDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  season?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  season_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  season_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  season_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  season_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  season_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  season_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  season_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updateDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updateDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updateDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updateDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updateDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updateDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updateDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updateDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PodcastEpisodeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastEpisodeWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastEpisodeWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastEpisodeWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PodcastEpisodeWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References PodcastEpisode record uniquely */
export type PodcastEpisodeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  permalink?: InputMaybe<Scalars['String']['input']>
  podigeeId?: InputMaybe<Scalars['Int']['input']>
}

/** Podcast Groups */
export type PodcastGroup = Entity &
  Node & {
    __typename?: 'PodcastGroup'
    /** Desktop background URL. Suggested size 1320x350 */
    backgroundImage: Asset
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Short podcast group description */
    description: Scalars['String']['output']
    /** Get the document in other stages */
    documentInStages: Array<PodcastGroup>
    /** Podigee feed page URL */
    feedUrl?: Maybe<Scalars['String']['output']>
    /** List of PodcastGroup versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<PodcastGroup>
    /** Mobile background URL. Suggested size 320x160. Fallbacks to background image if not provided */
    mobileBackgroundImage?: Maybe<Asset>
    /** Podcast group name */
    name: Scalars['String']['output']
    /** Podcast group page URL */
    pageUrl: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** Reference all related social media sources */
    socialMedia?: Maybe<Social>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Podcast Groups */
export type PodcastGroupBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podcast Groups */
export type PodcastGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Podcast Groups */
export type PodcastGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podcast Groups */
export type PodcastGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Podcast Groups */
export type PodcastGroupHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Podcast Groups */
export type PodcastGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Podcast Groups */
export type PodcastGroupMobileBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podcast Groups */
export type PodcastGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Podcast Groups */
export type PodcastGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podcast Groups */
export type PodcastGroupScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Podcast Groups */
export type PodcastGroupSocialMediaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Podcast Groups */
export type PodcastGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Podcast Groups */
export type PodcastGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PodcastGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PodcastGroupWhereUniqueInput
}

/** A connection to a list of items. */
export type PodcastGroupConnection = {
  __typename?: 'PodcastGroupConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PodcastGroupEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PodcastGroupCreateInput = {
  backgroundImage: AssetCreateOneInlineInput
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  feedUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PodcastGroupCreateLocalizationsInput>
  mobileBackgroundImage?: InputMaybe<AssetCreateOneInlineInput>
  name: Scalars['String']['input']
  pageUrl: Scalars['String']['input']
  socialMedia?: InputMaybe<SocialCreateOneInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PodcastGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type PodcastGroupCreateLocalizationInput = {
  /** Localization input */
  data: PodcastGroupCreateLocalizationDataInput
  locale: Locale
}

export type PodcastGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PodcastGroupCreateLocalizationInput>>
}

export type PodcastGroupCreateManyInlineInput = {
  /** Connect multiple existing PodcastGroup documents */
  connect?: InputMaybe<Array<PodcastGroupWhereUniqueInput>>
  /** Create and connect multiple existing PodcastGroup documents */
  create?: InputMaybe<Array<PodcastGroupCreateInput>>
}

export type PodcastGroupCreateOneInlineInput = {
  /** Connect one existing PodcastGroup document */
  connect?: InputMaybe<PodcastGroupWhereUniqueInput>
  /** Create and connect one PodcastGroup document */
  create?: InputMaybe<PodcastGroupCreateInput>
}

/** An edge in a connection. */
export type PodcastGroupEdge = {
  __typename?: 'PodcastGroupEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PodcastGroup
}

/** Identifies documents */
export type PodcastGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImage?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<PodcastGroupWhereStageInput>
  documentInStages_none?: InputMaybe<PodcastGroupWhereStageInput>
  documentInStages_some?: InputMaybe<PodcastGroupWhereStageInput>
  feedUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  feedUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  feedUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  feedUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  feedUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  feedUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  feedUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  feedUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  feedUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  feedUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileBackgroundImage?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  socialMedia?: InputMaybe<SocialWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum PodcastGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FeedUrlAsc = 'feedUrl_ASC',
  FeedUrlDesc = 'feedUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageUrlAsc = 'pageUrl_ASC',
  PageUrlDesc = 'pageUrl_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PodcastGroupUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  feedUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<PodcastGroupUpdateLocalizationsInput>
  mobileBackgroundImage?: InputMaybe<AssetUpdateOneInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  socialMedia?: InputMaybe<SocialUpdateOneInlineInput>
}

export type PodcastGroupUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type PodcastGroupUpdateLocalizationInput = {
  data: PodcastGroupUpdateLocalizationDataInput
  locale: Locale
}

export type PodcastGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PodcastGroupCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<PodcastGroupUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<PodcastGroupUpsertLocalizationInput>>
}

export type PodcastGroupUpdateManyInlineInput = {
  /** Connect multiple existing PodcastGroup documents */
  connect?: InputMaybe<Array<PodcastGroupConnectInput>>
  /** Create and connect multiple PodcastGroup documents */
  create?: InputMaybe<Array<PodcastGroupCreateInput>>
  /** Delete multiple PodcastGroup documents */
  delete?: InputMaybe<Array<PodcastGroupWhereUniqueInput>>
  /** Disconnect multiple PodcastGroup documents */
  disconnect?: InputMaybe<Array<PodcastGroupWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PodcastGroup documents */
  set?: InputMaybe<Array<PodcastGroupWhereUniqueInput>>
  /** Update multiple PodcastGroup documents */
  update?: InputMaybe<Array<PodcastGroupUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PodcastGroup documents */
  upsert?: InputMaybe<Array<PodcastGroupUpsertWithNestedWhereUniqueInput>>
}

export type PodcastGroupUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  feedUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<PodcastGroupUpdateManyLocalizationsInput>
  pageUrl?: InputMaybe<Scalars['String']['input']>
}

export type PodcastGroupUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type PodcastGroupUpdateManyLocalizationInput = {
  data: PodcastGroupUpdateManyLocalizationDataInput
  locale: Locale
}

export type PodcastGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PodcastGroupUpdateManyLocalizationInput>>
}

export type PodcastGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PodcastGroupUpdateManyInput
  /** Document search */
  where: PodcastGroupWhereInput
}

export type PodcastGroupUpdateOneInlineInput = {
  /** Connect existing PodcastGroup document */
  connect?: InputMaybe<PodcastGroupWhereUniqueInput>
  /** Create and connect one PodcastGroup document */
  create?: InputMaybe<PodcastGroupCreateInput>
  /** Delete currently connected PodcastGroup document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PodcastGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PodcastGroup document */
  update?: InputMaybe<PodcastGroupUpdateWithNestedWhereUniqueInput>
  /** Upsert single PodcastGroup document */
  upsert?: InputMaybe<PodcastGroupUpsertWithNestedWhereUniqueInput>
}

export type PodcastGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PodcastGroupUpdateInput
  /** Unique document search */
  where: PodcastGroupWhereUniqueInput
}

export type PodcastGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: PodcastGroupCreateInput
  /** Update document if it exists */
  update: PodcastGroupUpdateInput
}

export type PodcastGroupUpsertLocalizationInput = {
  create: PodcastGroupCreateLocalizationDataInput
  locale: Locale
  update: PodcastGroupUpdateLocalizationDataInput
}

export type PodcastGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PodcastGroupUpsertInput
  /** Unique document search */
  where: PodcastGroupWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type PodcastGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type PodcastGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastGroupWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  backgroundImage?: InputMaybe<AssetWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<PodcastGroupWhereStageInput>
  documentInStages_none?: InputMaybe<PodcastGroupWhereStageInput>
  documentInStages_some?: InputMaybe<PodcastGroupWhereStageInput>
  feedUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  feedUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  feedUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  feedUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  feedUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  feedUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  feedUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  feedUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  feedUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  feedUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileBackgroundImage?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  pageUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  socialMedia?: InputMaybe<SocialWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PodcastGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastGroupWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastGroupWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastGroupWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PodcastGroupWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References PodcastGroup record uniquely */
export type PodcastGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type PpcReviewsProduct = Entity & {
  __typename?: 'PpcReviewsProduct'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<PpcReviewsProduct>
  ppcDescription?: Maybe<Scalars['String']['output']>
  reviewsProduct?: Maybe<ReviewsProduct>
  /** System stage field */
  stage: Stage
  url: Scalars['String']['output']
}

export type PpcReviewsProductLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type PpcReviewsProductReviewsProductArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type PpcReviewsProductConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PpcReviewsProductWhereUniqueInput
}

/** A connection to a list of items. */
export type PpcReviewsProductConnection = {
  __typename?: 'PpcReviewsProductConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PpcReviewsProductEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PpcReviewsProductCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PpcReviewsProductCreateLocalizationsInput>
  /** ppcDescription input for default locale (de) */
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  reviewsProduct?: InputMaybe<ReviewsProductCreateOneInlineInput>
  /** url input for default locale (de) */
  url: Scalars['String']['input']
}

export type PpcReviewsProductCreateLocalizationDataInput = {
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  url: Scalars['String']['input']
}

export type PpcReviewsProductCreateLocalizationInput = {
  /** Localization input */
  data: PpcReviewsProductCreateLocalizationDataInput
  locale: Locale
}

export type PpcReviewsProductCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PpcReviewsProductCreateLocalizationInput>>
}

export type PpcReviewsProductCreateManyInlineInput = {
  /** Create and connect multiple existing PpcReviewsProduct documents */
  create?: InputMaybe<Array<PpcReviewsProductCreateInput>>
}

export type PpcReviewsProductCreateOneInlineInput = {
  /** Create and connect one PpcReviewsProduct document */
  create?: InputMaybe<PpcReviewsProductCreateInput>
}

export type PpcReviewsProductCreateWithPositionInput = {
  /** Document to create */
  data: PpcReviewsProductCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type PpcReviewsProductEdge = {
  __typename?: 'PpcReviewsProductEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PpcReviewsProduct
}

/** Identifies documents */
export type PpcReviewsProductManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  reviewsProduct?: InputMaybe<ReviewsProductWhereInput>
}

export enum PpcReviewsProductOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PpcDescriptionAsc = 'ppcDescription_ASC',
  PpcDescriptionDesc = 'ppcDescription_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type PpcReviewsProductParent = LandingPage

export type PpcReviewsProductParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type PpcReviewsProductParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type PpcReviewsProductParentCreateManyInlineInput = {
  /** Connect multiple existing PpcReviewsProductParent documents */
  connect?: InputMaybe<Array<PpcReviewsProductParentWhereUniqueInput>>
  /** Create and connect multiple existing PpcReviewsProductParent documents */
  create?: InputMaybe<Array<PpcReviewsProductParentCreateInput>>
}

export type PpcReviewsProductParentCreateOneInlineInput = {
  /** Connect one existing PpcReviewsProductParent document */
  connect?: InputMaybe<PpcReviewsProductParentWhereUniqueInput>
  /** Create and connect one PpcReviewsProductParent document */
  create?: InputMaybe<PpcReviewsProductParentCreateInput>
}

export type PpcReviewsProductParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type PpcReviewsProductParentUpdateManyInlineInput = {
  /** Connect multiple existing PpcReviewsProductParent documents */
  connect?: InputMaybe<Array<PpcReviewsProductParentConnectInput>>
  /** Create and connect multiple PpcReviewsProductParent documents */
  create?: InputMaybe<Array<PpcReviewsProductParentCreateInput>>
  /** Delete multiple PpcReviewsProductParent documents */
  delete?: InputMaybe<Array<PpcReviewsProductParentWhereUniqueInput>>
  /** Disconnect multiple PpcReviewsProductParent documents */
  disconnect?: InputMaybe<Array<PpcReviewsProductParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PpcReviewsProductParent documents */
  set?: InputMaybe<Array<PpcReviewsProductParentWhereUniqueInput>>
  /** Update multiple PpcReviewsProductParent documents */
  update?: InputMaybe<Array<PpcReviewsProductParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PpcReviewsProductParent documents */
  upsert?: InputMaybe<Array<PpcReviewsProductParentUpsertWithNestedWhereUniqueInput>>
}

export type PpcReviewsProductParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type PpcReviewsProductParentUpdateOneInlineInput = {
  /** Connect existing PpcReviewsProductParent document */
  connect?: InputMaybe<PpcReviewsProductParentWhereUniqueInput>
  /** Create and connect one PpcReviewsProductParent document */
  create?: InputMaybe<PpcReviewsProductParentCreateInput>
  /** Delete currently connected PpcReviewsProductParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PpcReviewsProductParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PpcReviewsProductParent document */
  update?: InputMaybe<PpcReviewsProductParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single PpcReviewsProductParent document */
  upsert?: InputMaybe<PpcReviewsProductParentUpsertWithNestedWhereUniqueInput>
}

export type PpcReviewsProductParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type PpcReviewsProductParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type PpcReviewsProductParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type PpcReviewsProductParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type PpcReviewsProductUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<PpcReviewsProductUpdateLocalizationsInput>
  /** ppcDescription input for default locale (de) */
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  reviewsProduct?: InputMaybe<ReviewsProductUpdateOneInlineInput>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type PpcReviewsProductUpdateLocalizationDataInput = {
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type PpcReviewsProductUpdateLocalizationInput = {
  data: PpcReviewsProductUpdateLocalizationDataInput
  locale: Locale
}

export type PpcReviewsProductUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PpcReviewsProductCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<PpcReviewsProductUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<PpcReviewsProductUpsertLocalizationInput>>
}

export type PpcReviewsProductUpdateManyInlineInput = {
  /** Create and connect multiple PpcReviewsProduct component instances */
  create?: InputMaybe<Array<PpcReviewsProductCreateWithPositionInput>>
  /** Delete multiple PpcReviewsProduct documents */
  delete?: InputMaybe<Array<PpcReviewsProductWhereUniqueInput>>
  /** Update multiple PpcReviewsProduct component instances */
  update?: InputMaybe<Array<PpcReviewsProductUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple PpcReviewsProduct component instances */
  upsert?: InputMaybe<Array<PpcReviewsProductUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type PpcReviewsProductUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<PpcReviewsProductUpdateManyLocalizationsInput>
  /** ppcDescription input for default locale (de) */
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars['String']['input']>
}

export type PpcReviewsProductUpdateManyLocalizationDataInput = {
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type PpcReviewsProductUpdateManyLocalizationInput = {
  data: PpcReviewsProductUpdateManyLocalizationDataInput
  locale: Locale
}

export type PpcReviewsProductUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PpcReviewsProductUpdateManyLocalizationInput>>
}

export type PpcReviewsProductUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PpcReviewsProductUpdateManyInput
  /** Document search */
  where: PpcReviewsProductWhereInput
}

export type PpcReviewsProductUpdateOneInlineInput = {
  /** Create and connect one PpcReviewsProduct document */
  create?: InputMaybe<PpcReviewsProductCreateInput>
  /** Delete currently connected PpcReviewsProduct document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PpcReviewsProduct document */
  update?: InputMaybe<PpcReviewsProductUpdateWithNestedWhereUniqueInput>
  /** Upsert single PpcReviewsProduct document */
  upsert?: InputMaybe<PpcReviewsProductUpsertWithNestedWhereUniqueInput>
}

export type PpcReviewsProductUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PpcReviewsProductUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: PpcReviewsProductWhereUniqueInput
}

export type PpcReviewsProductUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PpcReviewsProductUpdateInput
  /** Unique document search */
  where: PpcReviewsProductWhereUniqueInput
}

export type PpcReviewsProductUpsertInput = {
  /** Create document if it didn't exist */
  create: PpcReviewsProductCreateInput
  /** Update document if it exists */
  update: PpcReviewsProductUpdateInput
}

export type PpcReviewsProductUpsertLocalizationInput = {
  create: PpcReviewsProductCreateLocalizationDataInput
  locale: Locale
  update: PpcReviewsProductUpdateLocalizationDataInput
}

export type PpcReviewsProductUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PpcReviewsProductUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: PpcReviewsProductWhereUniqueInput
}

export type PpcReviewsProductUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PpcReviewsProductUpsertInput
  /** Unique document search */
  where: PpcReviewsProductWhereUniqueInput
}

/** Identifies documents */
export type PpcReviewsProductWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PpcReviewsProductWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  ppcDescription?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ppcDescription_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ppcDescription_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ppcDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ppcDescription_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ppcDescription_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ppcDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ppcDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ppcDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ppcDescription_starts_with?: InputMaybe<Scalars['String']['input']>
  reviewsProduct?: InputMaybe<ReviewsProductWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References PpcReviewsProduct record uniquely */
export type PpcReviewsProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing some quick actions. */
export type PreviewCardList = Entity & {
  __typename?: 'PreviewCardList'
  card: Array<Slide>
  /** Number of columns we want to show cards with */
  columns: Scalars['Int']['output']
  /** Description of the section */
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<PreviewCardList>
  /** System stage field */
  stage: Stage
  /** Title of the section */
  title?: Maybe<Scalars['String']['output']>
}

/** Section showing some quick actions. */
export type PreviewCardListCardArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SlideOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SlideWhereInput>
}

/** Section showing some quick actions. */
export type PreviewCardListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type PreviewCardListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: PreviewCardListWhereUniqueInput
}

/** A connection to a list of items. */
export type PreviewCardListConnection = {
  __typename?: 'PreviewCardListConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<PreviewCardListEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type PreviewCardListCreateInput = {
  card: SlideCreateManyInlineInput
  columns: Scalars['Int']['input']
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PreviewCardListCreateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListCreateLocalizationInput = {
  /** Localization input */
  data: PreviewCardListCreateLocalizationDataInput
  locale: Locale
}

export type PreviewCardListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PreviewCardListCreateLocalizationInput>>
}

export type PreviewCardListCreateManyInlineInput = {
  /** Create and connect multiple existing PreviewCardList documents */
  create?: InputMaybe<Array<PreviewCardListCreateInput>>
}

export type PreviewCardListCreateOneInlineInput = {
  /** Create and connect one PreviewCardList document */
  create?: InputMaybe<PreviewCardListCreateInput>
}

export type PreviewCardListCreateWithPositionInput = {
  /** Document to create */
  data: PreviewCardListCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type PreviewCardListEdge = {
  __typename?: 'PreviewCardListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: PreviewCardList
}

/** Identifies documents */
export type PreviewCardListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  card_every?: InputMaybe<SlideWhereInput>
  card_none?: InputMaybe<SlideWhereInput>
  card_some?: InputMaybe<SlideWhereInput>
  columns?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum PreviewCardListOrderByInput {
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PreviewCardListParent = EventPage | LandingPage

export type PreviewCardListParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type PreviewCardListParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type PreviewCardListParentCreateManyInlineInput = {
  /** Connect multiple existing PreviewCardListParent documents */
  connect?: InputMaybe<Array<PreviewCardListParentWhereUniqueInput>>
  /** Create and connect multiple existing PreviewCardListParent documents */
  create?: InputMaybe<Array<PreviewCardListParentCreateInput>>
}

export type PreviewCardListParentCreateOneInlineInput = {
  /** Connect one existing PreviewCardListParent document */
  connect?: InputMaybe<PreviewCardListParentWhereUniqueInput>
  /** Create and connect one PreviewCardListParent document */
  create?: InputMaybe<PreviewCardListParentCreateInput>
}

export type PreviewCardListParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type PreviewCardListParentUpdateManyInlineInput = {
  /** Connect multiple existing PreviewCardListParent documents */
  connect?: InputMaybe<Array<PreviewCardListParentConnectInput>>
  /** Create and connect multiple PreviewCardListParent documents */
  create?: InputMaybe<Array<PreviewCardListParentCreateInput>>
  /** Delete multiple PreviewCardListParent documents */
  delete?: InputMaybe<Array<PreviewCardListParentWhereUniqueInput>>
  /** Disconnect multiple PreviewCardListParent documents */
  disconnect?: InputMaybe<Array<PreviewCardListParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing PreviewCardListParent documents */
  set?: InputMaybe<Array<PreviewCardListParentWhereUniqueInput>>
  /** Update multiple PreviewCardListParent documents */
  update?: InputMaybe<Array<PreviewCardListParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple PreviewCardListParent documents */
  upsert?: InputMaybe<Array<PreviewCardListParentUpsertWithNestedWhereUniqueInput>>
}

export type PreviewCardListParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type PreviewCardListParentUpdateOneInlineInput = {
  /** Connect existing PreviewCardListParent document */
  connect?: InputMaybe<PreviewCardListParentWhereUniqueInput>
  /** Create and connect one PreviewCardListParent document */
  create?: InputMaybe<PreviewCardListParentCreateInput>
  /** Delete currently connected PreviewCardListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected PreviewCardListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PreviewCardListParent document */
  update?: InputMaybe<PreviewCardListParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single PreviewCardListParent document */
  upsert?: InputMaybe<PreviewCardListParentUpsertWithNestedWhereUniqueInput>
}

export type PreviewCardListParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type PreviewCardListParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type PreviewCardListParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type PreviewCardListParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type PreviewCardListUpdateInput = {
  card?: InputMaybe<SlideUpdateManyInlineInput>
  columns?: InputMaybe<Scalars['Int']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<PreviewCardListUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListUpdateLocalizationInput = {
  data: PreviewCardListUpdateLocalizationDataInput
  locale: Locale
}

export type PreviewCardListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PreviewCardListCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<PreviewCardListUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<PreviewCardListUpsertLocalizationInput>>
}

export type PreviewCardListUpdateManyInlineInput = {
  /** Create and connect multiple PreviewCardList component instances */
  create?: InputMaybe<Array<PreviewCardListCreateWithPositionInput>>
  /** Delete multiple PreviewCardList documents */
  delete?: InputMaybe<Array<PreviewCardListWhereUniqueInput>>
  /** Update multiple PreviewCardList component instances */
  update?: InputMaybe<Array<PreviewCardListUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple PreviewCardList component instances */
  upsert?: InputMaybe<Array<PreviewCardListUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type PreviewCardListUpdateManyInput = {
  columns?: InputMaybe<Scalars['Int']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<PreviewCardListUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type PreviewCardListUpdateManyLocalizationInput = {
  data: PreviewCardListUpdateManyLocalizationDataInput
  locale: Locale
}

export type PreviewCardListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PreviewCardListUpdateManyLocalizationInput>>
}

export type PreviewCardListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PreviewCardListUpdateManyInput
  /** Document search */
  where: PreviewCardListWhereInput
}

export type PreviewCardListUpdateOneInlineInput = {
  /** Create and connect one PreviewCardList document */
  create?: InputMaybe<PreviewCardListCreateInput>
  /** Delete currently connected PreviewCardList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single PreviewCardList document */
  update?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueInput>
  /** Upsert single PreviewCardList document */
  upsert?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueInput>
}

export type PreviewCardListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PreviewCardListUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: PreviewCardListWhereUniqueInput
}

export type PreviewCardListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PreviewCardListUpdateInput
  /** Unique document search */
  where: PreviewCardListWhereUniqueInput
}

export type PreviewCardListUpsertInput = {
  /** Create document if it didn't exist */
  create: PreviewCardListCreateInput
  /** Update document if it exists */
  update: PreviewCardListUpdateInput
}

export type PreviewCardListUpsertLocalizationInput = {
  create: PreviewCardListCreateLocalizationDataInput
  locale: Locale
  update: PreviewCardListUpdateLocalizationDataInput
}

export type PreviewCardListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PreviewCardListUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: PreviewCardListWhereUniqueInput
}

export type PreviewCardListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PreviewCardListUpsertInput
  /** Unique document search */
  where: PreviewCardListWhereUniqueInput
}

/** Identifies documents */
export type PreviewCardListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PreviewCardListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  card_every?: InputMaybe<SlideWhereInput>
  card_none?: InputMaybe<SlideWhereInput>
  card_some?: InputMaybe<SlideWhereInput>
  columns?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References PreviewCardList record uniquely */
export type PreviewCardListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type ProcessStep = Entity & {
  __typename?: 'ProcessStep'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** Option to display section decorations, false by default */
  displayDecorations?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<ProcessStep>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  /** Numbered according to the order */
  steps: Array<Scalars['String']['output']>
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

export type ProcessStepLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type ProcessStepConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ProcessStepWhereUniqueInput
}

/** A connection to a list of items. */
export type ProcessStepConnection = {
  __typename?: 'ProcessStepConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ProcessStepEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ProcessStepCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProcessStepCreateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** steps input for default locale (de) */
  steps: Array<Scalars['String']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  steps: Array<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepCreateLocalizationInput = {
  /** Localization input */
  data: ProcessStepCreateLocalizationDataInput
  locale: Locale
}

export type ProcessStepCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProcessStepCreateLocalizationInput>>
}

export type ProcessStepCreateManyInlineInput = {
  /** Create and connect multiple existing ProcessStep documents */
  create?: InputMaybe<Array<ProcessStepCreateInput>>
}

export type ProcessStepCreateOneInlineInput = {
  /** Create and connect one ProcessStep document */
  create?: InputMaybe<ProcessStepCreateInput>
}

export type ProcessStepCreateWithPositionInput = {
  /** Document to create */
  data: ProcessStepCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type ProcessStepEdge = {
  __typename?: 'ProcessStepEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ProcessStep
}

/** Identifies documents */
export type ProcessStepManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayDecorations_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum ProcessStepOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisplayDecorationsAsc = 'displayDecorations_ASC',
  DisplayDecorationsDesc = 'displayDecorations_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  StepsAsc = 'steps_ASC',
  StepsDesc = 'steps_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ProcessStepParent = EventPage

export type ProcessStepParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type ProcessStepParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type ProcessStepParentCreateManyInlineInput = {
  /** Connect multiple existing ProcessStepParent documents */
  connect?: InputMaybe<Array<ProcessStepParentWhereUniqueInput>>
  /** Create and connect multiple existing ProcessStepParent documents */
  create?: InputMaybe<Array<ProcessStepParentCreateInput>>
}

export type ProcessStepParentCreateOneInlineInput = {
  /** Connect one existing ProcessStepParent document */
  connect?: InputMaybe<ProcessStepParentWhereUniqueInput>
  /** Create and connect one ProcessStepParent document */
  create?: InputMaybe<ProcessStepParentCreateInput>
}

export type ProcessStepParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type ProcessStepParentUpdateManyInlineInput = {
  /** Connect multiple existing ProcessStepParent documents */
  connect?: InputMaybe<Array<ProcessStepParentConnectInput>>
  /** Create and connect multiple ProcessStepParent documents */
  create?: InputMaybe<Array<ProcessStepParentCreateInput>>
  /** Delete multiple ProcessStepParent documents */
  delete?: InputMaybe<Array<ProcessStepParentWhereUniqueInput>>
  /** Disconnect multiple ProcessStepParent documents */
  disconnect?: InputMaybe<Array<ProcessStepParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ProcessStepParent documents */
  set?: InputMaybe<Array<ProcessStepParentWhereUniqueInput>>
  /** Update multiple ProcessStepParent documents */
  update?: InputMaybe<Array<ProcessStepParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ProcessStepParent documents */
  upsert?: InputMaybe<Array<ProcessStepParentUpsertWithNestedWhereUniqueInput>>
}

export type ProcessStepParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type ProcessStepParentUpdateOneInlineInput = {
  /** Connect existing ProcessStepParent document */
  connect?: InputMaybe<ProcessStepParentWhereUniqueInput>
  /** Create and connect one ProcessStepParent document */
  create?: InputMaybe<ProcessStepParentCreateInput>
  /** Delete currently connected ProcessStepParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ProcessStepParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ProcessStepParent document */
  update?: InputMaybe<ProcessStepParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single ProcessStepParent document */
  upsert?: InputMaybe<ProcessStepParentUpsertWithNestedWhereUniqueInput>
}

export type ProcessStepParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type ProcessStepParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type ProcessStepParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type ProcessStepParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type ProcessStepUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ProcessStepUpdateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** steps input for default locale (de) */
  steps?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepUpdateLocalizationInput = {
  data: ProcessStepUpdateLocalizationDataInput
  locale: Locale
}

export type ProcessStepUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProcessStepCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ProcessStepUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ProcessStepUpsertLocalizationInput>>
}

export type ProcessStepUpdateManyInlineInput = {
  /** Create and connect multiple ProcessStep component instances */
  create?: InputMaybe<Array<ProcessStepCreateWithPositionInput>>
  /** Delete multiple ProcessStep documents */
  delete?: InputMaybe<Array<ProcessStepWhereUniqueInput>>
  /** Update multiple ProcessStep component instances */
  update?: InputMaybe<Array<ProcessStepUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple ProcessStep component instances */
  upsert?: InputMaybe<Array<ProcessStepUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type ProcessStepUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProcessStepUpdateManyLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** steps input for default locale (de) */
  steps?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  steps?: InputMaybe<Array<Scalars['String']['input']>>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ProcessStepUpdateManyLocalizationInput = {
  data: ProcessStepUpdateManyLocalizationDataInput
  locale: Locale
}

export type ProcessStepUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProcessStepUpdateManyLocalizationInput>>
}

export type ProcessStepUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProcessStepUpdateManyInput
  /** Document search */
  where: ProcessStepWhereInput
}

export type ProcessStepUpdateOneInlineInput = {
  /** Create and connect one ProcessStep document */
  create?: InputMaybe<ProcessStepCreateInput>
  /** Delete currently connected ProcessStep document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ProcessStep document */
  update?: InputMaybe<ProcessStepUpdateWithNestedWhereUniqueInput>
  /** Upsert single ProcessStep document */
  upsert?: InputMaybe<ProcessStepUpsertWithNestedWhereUniqueInput>
}

export type ProcessStepUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ProcessStepUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ProcessStepWhereUniqueInput
}

export type ProcessStepUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProcessStepUpdateInput
  /** Unique document search */
  where: ProcessStepWhereUniqueInput
}

export type ProcessStepUpsertInput = {
  /** Create document if it didn't exist */
  create: ProcessStepCreateInput
  /** Update document if it exists */
  update: ProcessStepUpdateInput
}

export type ProcessStepUpsertLocalizationInput = {
  create: ProcessStepCreateLocalizationDataInput
  locale: Locale
  update: ProcessStepUpdateLocalizationDataInput
}

export type ProcessStepUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ProcessStepUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: ProcessStepWhereUniqueInput
}

export type ProcessStepUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProcessStepUpsertInput
  /** Unique document search */
  where: ProcessStepWhereUniqueInput
}

/** Identifies documents */
export type ProcessStepWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProcessStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayDecorations_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  steps?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  steps_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  steps_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  steps_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  steps_not?: InputMaybe<Array<Scalars['String']['input']>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References ProcessStep record uniquely */
export type ProcessStepWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Available Product Widgets */
export enum ProductWidgetEnum {
  AlternativeToolsInCategory = 'alternativeToolsInCategory',
  FreeToolsInCategory = 'freeToolsInCategory',
  PaidToolsInCategory = 'paidToolsInCategory',
  TopRatedToolsInCategory = 'topRatedToolsInCategory',
}

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale
  /** Stages to publish selected locales to */
  stages: Array<Stage>
}

export type Query = {
  __typename?: 'Query'
  /** Retrieve a single animatedCounter */
  animatedCounter?: Maybe<AnimatedCounter>
  /** Retrieve document version */
  animatedCounterVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple animatedCounters */
  animatedCounters: Array<AnimatedCounter>
  /** Retrieve multiple animatedCounters using the Relay connection interface */
  animatedCountersConnection: AnimatedCounterConnection
  /** Retrieve a single article */
  article?: Maybe<Article>
  /** Retrieve multiple articleCategories */
  articleCategories: Array<ArticleCategory>
  /** Retrieve multiple articleCategories using the Relay connection interface */
  articleCategoriesConnection: ArticleCategoryConnection
  /** Retrieve a single articleCategory */
  articleCategory?: Maybe<ArticleCategory>
  /** Retrieve document version */
  articleCategoryVersion?: Maybe<DocumentVersion>
  /** Retrieve a single articlePageView */
  articlePageView?: Maybe<ArticlePageView>
  /** Retrieve document version */
  articlePageViewVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple articlePageViews */
  articlePageViews: Array<ArticlePageView>
  /** Retrieve multiple articlePageViews using the Relay connection interface */
  articlePageViewsConnection: ArticlePageViewConnection
  /** Retrieve document version */
  articleVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple articles */
  articles: Array<Article>
  /** Retrieve multiple articles using the Relay connection interface */
  articlesConnection: ArticleConnection
  /** Retrieve a single asset */
  asset?: Maybe<Asset>
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple assets */
  assets: Array<Asset>
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection
  /** Retrieve a single author */
  author?: Maybe<Author>
  /** Retrieve document version */
  authorVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple authors */
  authors: Array<Author>
  /** Retrieve multiple authors using the Relay connection interface */
  authorsConnection: AuthorConnection
  /** Retrieve a single booth */
  booth?: Maybe<Booth>
  /** Retrieve document version */
  boothVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple booths */
  booths: Array<Booth>
  /** Retrieve multiple booths using the Relay connection interface */
  boothsConnection: BoothConnection
  /** Retrieve a single contentReference */
  contentReference?: Maybe<ContentReference>
  /** Retrieve document version */
  contentReferenceVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple contentReferences */
  contentReferences: Array<ContentReference>
  /** Retrieve multiple contentReferences using the Relay connection interface */
  contentReferencesConnection: ContentReferenceConnection
  /** Retrieve a single educationReport */
  educationReport?: Maybe<EducationReport>
  /** Retrieve document version */
  educationReportVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple educationReports */
  educationReports: Array<EducationReport>
  /** Retrieve multiple educationReports using the Relay connection interface */
  educationReportsConnection: EducationReportConnection
  /** Retrieve a single educationSeminar */
  educationSeminar?: Maybe<EducationSeminar>
  /** Retrieve document version */
  educationSeminarVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple educationSeminars */
  educationSeminars: Array<EducationSeminar>
  /** Retrieve multiple educationSeminars using the Relay connection interface */
  educationSeminarsConnection: EducationSeminarConnection
  /** Fetches an object given its ID */
  entities?: Maybe<Array<Entity>>
  /** Retrieve a single event */
  event?: Maybe<Event>
  /** Retrieve a single eventAppPage */
  eventAppPage?: Maybe<EventAppPage>
  /** Retrieve document version */
  eventAppPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple eventAppPages */
  eventAppPages: Array<EventAppPage>
  /** Retrieve multiple eventAppPages using the Relay connection interface */
  eventAppPagesConnection: EventAppPageConnection
  /** Retrieve a single eventPage */
  eventPage?: Maybe<EventPage>
  /** Retrieve document version */
  eventPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple eventPages */
  eventPages: Array<EventPage>
  /** Retrieve multiple eventPages using the Relay connection interface */
  eventPagesConnection: EventPageConnection
  /** Retrieve a single eventStage */
  eventStage?: Maybe<EventStage>
  /** Retrieve document version */
  eventStageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple eventStages */
  eventStages: Array<EventStage>
  /** Retrieve multiple eventStages using the Relay connection interface */
  eventStagesConnection: EventStageConnection
  /** Retrieve document version */
  eventVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple events */
  events: Array<Event>
  /** Retrieve multiple events using the Relay connection interface */
  eventsConnection: EventConnection
  /** Retrieve a single exhibitorListOrder */
  exhibitorListOrder?: Maybe<ExhibitorListOrder>
  /** Retrieve document version */
  exhibitorListOrderVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple exhibitorListOrders */
  exhibitorListOrders: Array<ExhibitorListOrder>
  /** Retrieve multiple exhibitorListOrders using the Relay connection interface */
  exhibitorListOrdersConnection: ExhibitorListOrderConnection
  /** Retrieve a single faqQuestion */
  faqQuestion?: Maybe<FaqQuestion>
  /** Retrieve document version */
  faqQuestionVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple faqQuestions */
  faqQuestions: Array<FaqQuestion>
  /** Retrieve multiple faqQuestions using the Relay connection interface */
  faqQuestionsConnection: FaqQuestionConnection
  /** Retrieve a single footer */
  footer?: Maybe<Footer>
  /** Retrieve document version */
  footerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple footers */
  footers: Array<Footer>
  /** Retrieve multiple footers using the Relay connection interface */
  footersConnection: FooterConnection
  /** Retrieve multiple glossaries */
  glossaries: Array<Glossary>
  /** Retrieve multiple glossaries using the Relay connection interface */
  glossariesConnection: GlossaryConnection
  /** Retrieve a single glossary */
  glossary?: Maybe<Glossary>
  /** Retrieve document version */
  glossaryVersion?: Maybe<DocumentVersion>
  /** Retrieve a single header */
  header?: Maybe<Header>
  /** Retrieve document version */
  headerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple headers */
  headers: Array<Header>
  /** Retrieve multiple headers using the Relay connection interface */
  headersConnection: HeaderConnection
  /** Retrieve a single hotel */
  hotel?: Maybe<Hotel>
  /** Retrieve document version */
  hotelVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple hotels */
  hotels: Array<Hotel>
  /** Retrieve multiple hotels using the Relay connection interface */
  hotelsConnection: HotelConnection
  /** Retrieve a single htmlEmbed */
  htmlEmbed?: Maybe<HtmlEmbed>
  /** Retrieve document version */
  htmlEmbedVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple htmlEmbeds */
  htmlEmbeds: Array<HtmlEmbed>
  /** Retrieve multiple htmlEmbeds using the Relay connection interface */
  htmlEmbedsConnection: HtmlEmbedConnection
  /** Retrieve a single hubPage */
  hubPage?: Maybe<HubPage>
  /** Retrieve document version */
  hubPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple hubPages */
  hubPages: Array<HubPage>
  /** Retrieve multiple hubPages using the Relay connection interface */
  hubPagesConnection: HubPageConnection
  /** Search for content entries */
  hygraphSearchEntries: HygraphSearchResult
  /** Retrieve a single imageGalleryEmbed */
  imageGalleryEmbed?: Maybe<ImageGalleryEmbed>
  /** Retrieve document version */
  imageGalleryEmbedVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple imageGalleryEmbeds */
  imageGalleryEmbeds: Array<ImageGalleryEmbed>
  /** Retrieve multiple imageGalleryEmbeds using the Relay connection interface */
  imageGalleryEmbedsConnection: ImageGalleryEmbedConnection
  /** Retrieve a single interest */
  interest?: Maybe<Interest>
  /** Retrieve document version */
  interestVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple interests */
  interests: Array<Interest>
  /** Retrieve multiple interests using the Relay connection interface */
  interestsConnection: InterestConnection
  /** Retrieve a single jobListing */
  jobListing?: Maybe<JobListing>
  /** Retrieve document version */
  jobListingVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple jobListings */
  jobListings: Array<JobListing>
  /** Retrieve multiple jobListings using the Relay connection interface */
  jobListingsConnection: JobListingConnection
  /** Retrieve a single landingPage */
  landingPage?: Maybe<LandingPage>
  /** Retrieve document version */
  landingPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple landingPages */
  landingPages: Array<LandingPage>
  /** Retrieve multiple landingPages using the Relay connection interface */
  landingPagesConnection: LandingPageConnection
  /** Retrieve a single manageContentConfig */
  manageContentConfig?: Maybe<ManageContentConfig>
  /** Retrieve document version */
  manageContentConfigVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple manageContentConfigs */
  manageContentConfigs: Array<ManageContentConfig>
  /** Retrieve multiple manageContentConfigs using the Relay connection interface */
  manageContentConfigsConnection: ManageContentConfigConnection
  /** Retrieve a single mapMarker */
  mapMarker?: Maybe<MapMarker>
  /** Retrieve a single mapMarkerIcon */
  mapMarkerIcon?: Maybe<MapMarkerIcon>
  /** Retrieve document version */
  mapMarkerIconVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple mapMarkerIcons */
  mapMarkerIcons: Array<MapMarkerIcon>
  /** Retrieve multiple mapMarkerIcons using the Relay connection interface */
  mapMarkerIconsConnection: MapMarkerIconConnection
  /** Retrieve document version */
  mapMarkerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple mapMarkers */
  mapMarkers: Array<MapMarker>
  /** Retrieve multiple mapMarkers using the Relay connection interface */
  mapMarkersConnection: MapMarkerConnection
  /** Retrieve a single newsletterAudience */
  newsletterAudience?: Maybe<NewsletterAudience>
  /** Retrieve document version */
  newsletterAudienceVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple newsletterAudiences */
  newsletterAudiences: Array<NewsletterAudience>
  /** Retrieve multiple newsletterAudiences using the Relay connection interface */
  newsletterAudiencesConnection: NewsletterAudienceConnection
  /** Fetches an object given its ID */
  node?: Maybe<Node>
  /** Retrieve a single notification */
  notification?: Maybe<Notification>
  /** Retrieve document version */
  notificationVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple notifications */
  notifications: Array<Notification>
  /** Retrieve multiple notifications using the Relay connection interface */
  notificationsConnection: NotificationConnection
  /** Retrieve a single oMRPartner */
  oMRPartner?: Maybe<OmrPartner>
  /** Retrieve document version */
  oMRPartnerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple oMRPartners */
  oMRPartners: Array<OmrPartner>
  /** Retrieve multiple oMRPartners using the Relay connection interface */
  oMRPartnersConnection: OmrPartnerConnection
  /** Retrieve multiple partnerCompanies */
  partnerCompanies: Array<PartnerCompany>
  /** Retrieve multiple partnerCompanies using the Relay connection interface */
  partnerCompaniesConnection: PartnerCompanyConnection
  /** Retrieve a single partnerCompany */
  partnerCompany?: Maybe<PartnerCompany>
  /** Retrieve document version */
  partnerCompanyVersion?: Maybe<DocumentVersion>
  /** Retrieve a single physicalLocation */
  physicalLocation?: Maybe<PhysicalLocation>
  /** Retrieve document version */
  physicalLocationVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple physicalLocations */
  physicalLocations: Array<PhysicalLocation>
  /** Retrieve multiple physicalLocations using the Relay connection interface */
  physicalLocationsConnection: PhysicalLocationConnection
  /** Retrieve a single podcastEpisode */
  podcastEpisode?: Maybe<PodcastEpisode>
  /** Retrieve document version */
  podcastEpisodeVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple podcastEpisodes */
  podcastEpisodes: Array<PodcastEpisode>
  /** Retrieve multiple podcastEpisodes using the Relay connection interface */
  podcastEpisodesConnection: PodcastEpisodeConnection
  /** Retrieve a single podcastGroup */
  podcastGroup?: Maybe<PodcastGroup>
  /** Retrieve document version */
  podcastGroupVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple podcastGroups */
  podcastGroups: Array<PodcastGroup>
  /** Retrieve multiple podcastGroups using the Relay connection interface */
  podcastGroupsConnection: PodcastGroupConnection
  /** Retrieve a single recommendationFood */
  recommendationFood?: Maybe<RecommendationFood>
  /** Retrieve document version */
  recommendationFoodVersion?: Maybe<DocumentVersion>
  /** Retrieve a single recommendationNightOut */
  recommendationNightOut?: Maybe<RecommendationNightOut>
  /** Retrieve document version */
  recommendationNightOutVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple recommendationsFood */
  recommendationsFood: Array<RecommendationFood>
  /** Retrieve multiple recommendationsFood using the Relay connection interface */
  recommendationsFoodConnection: RecommendationFoodConnection
  /** Retrieve multiple recommendationsNightOut */
  recommendationsNightOut: Array<RecommendationNightOut>
  /** Retrieve multiple recommendationsNightOut using the Relay connection interface */
  recommendationsNightOutConnection: RecommendationNightOutConnection
  /** Retrieve multiple reviewsAgencies */
  reviewsAgencies: Array<ReviewsAgency>
  /** Retrieve multiple reviewsAgencies using the Relay connection interface */
  reviewsAgenciesConnection: ReviewsAgencyConnection
  /** Retrieve a single reviewsAgency */
  reviewsAgency?: Maybe<ReviewsAgency>
  /** Retrieve document version */
  reviewsAgencyVersion?: Maybe<DocumentVersion>
  /** Retrieve a single reviewsProduct */
  reviewsProduct?: Maybe<ReviewsProduct>
  /** Retrieve multiple reviewsProductCategories */
  reviewsProductCategories: Array<ReviewsProductCategory>
  /** Retrieve multiple reviewsProductCategories using the Relay connection interface */
  reviewsProductCategoriesConnection: ReviewsProductCategoryConnection
  /** Retrieve a single reviewsProductCategory */
  reviewsProductCategory?: Maybe<ReviewsProductCategory>
  /** Retrieve document version */
  reviewsProductCategoryVersion?: Maybe<DocumentVersion>
  /** Retrieve document version */
  reviewsProductVersion?: Maybe<DocumentVersion>
  /** Retrieve a single reviewsProductWidget */
  reviewsProductWidget?: Maybe<ReviewsProductWidget>
  /** Retrieve document version */
  reviewsProductWidgetVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple reviewsProductWidgets */
  reviewsProductWidgets: Array<ReviewsProductWidget>
  /** Retrieve multiple reviewsProductWidgets using the Relay connection interface */
  reviewsProductWidgetsConnection: ReviewsProductWidgetConnection
  /** Retrieve multiple reviewsProducts */
  reviewsProducts: Array<ReviewsProduct>
  /** Retrieve multiple reviewsProducts using the Relay connection interface */
  reviewsProductsConnection: ReviewsProductConnection
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection
  /** Retrieve a single socialEmbed */
  socialEmbed?: Maybe<SocialEmbed>
  /** Retrieve document version */
  socialEmbedVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple socialEmbeds */
  socialEmbeds: Array<SocialEmbed>
  /** Retrieve multiple socialEmbeds using the Relay connection interface */
  socialEmbedsConnection: SocialEmbedConnection
  /** Retrieve a single speaker */
  speaker?: Maybe<Speaker>
  /** Retrieve a single speakerListOrder */
  speakerListOrder?: Maybe<SpeakerListOrder>
  /** Retrieve document version */
  speakerListOrderVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple speakerListOrders */
  speakerListOrders: Array<SpeakerListOrder>
  /** Retrieve multiple speakerListOrders using the Relay connection interface */
  speakerListOrdersConnection: SpeakerListOrderConnection
  /** Retrieve document version */
  speakerVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple speakers */
  speakers: Array<Speaker>
  /** Retrieve multiple speakers using the Relay connection interface */
  speakersConnection: SpeakerConnection
  /** Retrieve a single tabContent */
  tabContent?: Maybe<TabContent>
  /** Retrieve document version */
  tabContentVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple tabContents */
  tabContents: Array<TabContent>
  /** Retrieve multiple tabContents using the Relay connection interface */
  tabContentsConnection: TabContentConnection
  /** Retrieve a single tag */
  tag?: Maybe<Tag>
  /** Retrieve document version */
  tagVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple tags */
  tags: Array<Tag>
  /** Retrieve multiple tags using the Relay connection interface */
  tagsConnection: TagConnection
  /** Retrieve a single topicPage */
  topicPage?: Maybe<TopicPage>
  /** Retrieve multiple topicPageCategories */
  topicPageCategories: Array<TopicPageCategory>
  /** Retrieve multiple topicPageCategories using the Relay connection interface */
  topicPageCategoriesConnection: TopicPageCategoryConnection
  /** Retrieve a single topicPageCategory */
  topicPageCategory?: Maybe<TopicPageCategory>
  /** Retrieve document version */
  topicPageCategoryVersion?: Maybe<DocumentVersion>
  /** Retrieve document version */
  topicPageVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple topicPages */
  topicPages: Array<TopicPage>
  /** Retrieve multiple topicPages using the Relay connection interface */
  topicPagesConnection: TopicPageConnection
  /** Retrieve a single user */
  user?: Maybe<User>
  /** Retrieve multiple users */
  users: Array<User>
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection
  /** Retrieve a single vGWort */
  vGWort?: Maybe<VgWort>
  /** Retrieve document version */
  vGWortVersion?: Maybe<DocumentVersion>
  /** Retrieve multiple vGWorts */
  vGWorts: Array<VgWort>
  /** Retrieve multiple vGWorts using the Relay connection interface */
  vGWortsConnection: VgWortConnection
}

export type QueryAnimatedCounterArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: AnimatedCounterWhereUniqueInput
}

export type QueryAnimatedCounterVersionArgs = {
  where: VersionWhereInput
}

export type QueryAnimatedCountersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AnimatedCounterOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AnimatedCounterWhereInput>
}

export type QueryAnimatedCountersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AnimatedCounterOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AnimatedCounterWhereInput>
}

export type QueryArticleArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ArticleWhereUniqueInput
}

export type QueryArticleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticleCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticleCategoryWhereInput>
}

export type QueryArticleCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticleCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticleCategoryWhereInput>
}

export type QueryArticleCategoryArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ArticleCategoryWhereUniqueInput
}

export type QueryArticleCategoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryArticlePageViewArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ArticlePageViewWhereUniqueInput
}

export type QueryArticlePageViewVersionArgs = {
  where: VersionWhereInput
}

export type QueryArticlePageViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticlePageViewOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticlePageViewWhereInput>
}

export type QueryArticlePageViewsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticlePageViewOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticlePageViewWhereInput>
}

export type QueryArticleVersionArgs = {
  where: VersionWhereInput
}

export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticleWhereInput>
}

export type QueryArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ArticleWhereInput>
}

export type QueryAssetArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: AssetWhereUniqueInput
}

export type QueryAssetVersionArgs = {
  where: VersionWhereInput
}

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AssetWhereInput>
}

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AssetWhereInput>
}

export type QueryAuthorArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: AuthorWhereUniqueInput
}

export type QueryAuthorVersionArgs = {
  where: VersionWhereInput
}

export type QueryAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AuthorWhereInput>
}

export type QueryAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<AuthorWhereInput>
}

export type QueryBoothArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: BoothWhereUniqueInput
}

export type QueryBoothVersionArgs = {
  where: VersionWhereInput
}

export type QueryBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<BoothOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<BoothWhereInput>
}

export type QueryBoothsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<BoothOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<BoothWhereInput>
}

export type QueryContentReferenceArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ContentReferenceWhereUniqueInput
}

export type QueryContentReferenceVersionArgs = {
  where: VersionWhereInput
}

export type QueryContentReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ContentReferenceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ContentReferenceWhereInput>
}

export type QueryContentReferencesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ContentReferenceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ContentReferenceWhereInput>
}

export type QueryEducationReportArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EducationReportWhereUniqueInput
}

export type QueryEducationReportVersionArgs = {
  where: VersionWhereInput
}

export type QueryEducationReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EducationReportOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EducationReportWhereInput>
}

export type QueryEducationReportsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EducationReportOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EducationReportWhereInput>
}

export type QueryEducationSeminarArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EducationSeminarWhereUniqueInput
}

export type QueryEducationSeminarVersionArgs = {
  where: VersionWhereInput
}

export type QueryEducationSeminarsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EducationSeminarOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EducationSeminarWhereInput>
}

export type QueryEducationSeminarsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EducationSeminarOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EducationSeminarWhereInput>
}

export type QueryEntitiesArgs = {
  locales?: InputMaybe<Array<Locale>>
  where: Array<EntityWhereInput>
}

export type QueryEventArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EventWhereUniqueInput
}

export type QueryEventAppPageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EventAppPageWhereUniqueInput
}

export type QueryEventAppPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryEventAppPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventAppPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventAppPageWhereInput>
}

export type QueryEventAppPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventAppPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventAppPageWhereInput>
}

export type QueryEventPageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EventPageWhereUniqueInput
}

export type QueryEventPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryEventPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventPageWhereInput>
}

export type QueryEventPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventPageWhereInput>
}

export type QueryEventStageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: EventStageWhereUniqueInput
}

export type QueryEventStageVersionArgs = {
  where: VersionWhereInput
}

export type QueryEventStagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventStageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventStageWhereInput>
}

export type QueryEventStagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventStageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventStageWhereInput>
}

export type QueryEventVersionArgs = {
  where: VersionWhereInput
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventWhereInput>
}

export type QueryEventsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<EventWhereInput>
}

export type QueryExhibitorListOrderArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ExhibitorListOrderWhereUniqueInput
}

export type QueryExhibitorListOrderVersionArgs = {
  where: VersionWhereInput
}

export type QueryExhibitorListOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ExhibitorListOrderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ExhibitorListOrderWhereInput>
}

export type QueryExhibitorListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ExhibitorListOrderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ExhibitorListOrderWhereInput>
}

export type QueryFaqQuestionArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: FaqQuestionWhereUniqueInput
}

export type QueryFaqQuestionVersionArgs = {
  where: VersionWhereInput
}

export type QueryFaqQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<FaqQuestionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<FaqQuestionWhereInput>
}

export type QueryFaqQuestionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<FaqQuestionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<FaqQuestionWhereInput>
}

export type QueryFooterArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: FooterWhereUniqueInput
}

export type QueryFooterVersionArgs = {
  where: VersionWhereInput
}

export type QueryFootersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<FooterOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<FooterWhereInput>
}

export type QueryFootersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<FooterOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<FooterWhereInput>
}

export type QueryGlossariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<GlossaryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<GlossaryWhereInput>
}

export type QueryGlossariesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<GlossaryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<GlossaryWhereInput>
}

export type QueryGlossaryArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: GlossaryWhereUniqueInput
}

export type QueryGlossaryVersionArgs = {
  where: VersionWhereInput
}

export type QueryHeaderArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: HeaderWhereUniqueInput
}

export type QueryHeaderVersionArgs = {
  where: VersionWhereInput
}

export type QueryHeadersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HeaderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HeaderWhereInput>
}

export type QueryHeadersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HeaderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HeaderWhereInput>
}

export type QueryHotelArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: HotelWhereUniqueInput
}

export type QueryHotelVersionArgs = {
  where: VersionWhereInput
}

export type QueryHotelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HotelOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HotelWhereInput>
}

export type QueryHotelsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HotelOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HotelWhereInput>
}

export type QueryHtmlEmbedArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: HtmlEmbedWhereUniqueInput
}

export type QueryHtmlEmbedVersionArgs = {
  where: VersionWhereInput
}

export type QueryHtmlEmbedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HtmlEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HtmlEmbedWhereInput>
}

export type QueryHtmlEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HtmlEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HtmlEmbedWhereInput>
}

export type QueryHubPageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: HubPageWhereUniqueInput
}

export type QueryHubPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryHubPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HubPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HubPageWhereInput>
}

export type QueryHubPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<HubPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<HubPageWhereInput>
}

export type QueryHygraphSearchEntriesArgs = {
  where?: InputMaybe<HygraphSearchWhereInput>
}

export type QueryImageGalleryEmbedArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ImageGalleryEmbedWhereUniqueInput
}

export type QueryImageGalleryEmbedVersionArgs = {
  where: VersionWhereInput
}

export type QueryImageGalleryEmbedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ImageGalleryEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ImageGalleryEmbedWhereInput>
}

export type QueryImageGalleryEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ImageGalleryEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ImageGalleryEmbedWhereInput>
}

export type QueryInterestArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: InterestWhereUniqueInput
}

export type QueryInterestVersionArgs = {
  where: VersionWhereInput
}

export type QueryInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<InterestOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<InterestWhereInput>
}

export type QueryInterestsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<InterestOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<InterestWhereInput>
}

export type QueryJobListingArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: JobListingWhereUniqueInput
}

export type QueryJobListingVersionArgs = {
  where: VersionWhereInput
}

export type QueryJobListingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<JobListingOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<JobListingWhereInput>
}

export type QueryJobListingsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<JobListingOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<JobListingWhereInput>
}

export type QueryLandingPageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: LandingPageWhereUniqueInput
}

export type QueryLandingPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryLandingPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<LandingPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<LandingPageWhereInput>
}

export type QueryLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<LandingPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<LandingPageWhereInput>
}

export type QueryManageContentConfigArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ManageContentConfigWhereUniqueInput
}

export type QueryManageContentConfigVersionArgs = {
  where: VersionWhereInput
}

export type QueryManageContentConfigsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ManageContentConfigOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ManageContentConfigWhereInput>
}

export type QueryManageContentConfigsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ManageContentConfigOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ManageContentConfigWhereInput>
}

export type QueryMapMarkerArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: MapMarkerWhereUniqueInput
}

export type QueryMapMarkerIconArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: MapMarkerIconWhereUniqueInput
}

export type QueryMapMarkerIconVersionArgs = {
  where: VersionWhereInput
}

export type QueryMapMarkerIconsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<MapMarkerIconOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<MapMarkerIconWhereInput>
}

export type QueryMapMarkerIconsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<MapMarkerIconOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<MapMarkerIconWhereInput>
}

export type QueryMapMarkerVersionArgs = {
  where: VersionWhereInput
}

export type QueryMapMarkersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<MapMarkerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<MapMarkerWhereInput>
}

export type QueryMapMarkersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<MapMarkerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<MapMarkerWhereInput>
}

export type QueryNewsletterAudienceArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: NewsletterAudienceWhereUniqueInput
}

export type QueryNewsletterAudienceVersionArgs = {
  where: VersionWhereInput
}

export type QueryNewsletterAudiencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<NewsletterAudienceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<NewsletterAudienceWhereInput>
}

export type QueryNewsletterAudiencesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<NewsletterAudienceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<NewsletterAudienceWhereInput>
}

export type QueryNodeArgs = {
  id: Scalars['ID']['input']
  locales?: Array<Locale>
  stage?: Stage
}

export type QueryNotificationArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: NotificationWhereUniqueInput
}

export type QueryNotificationVersionArgs = {
  where: VersionWhereInput
}

export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<NotificationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<NotificationWhereInput>
}

export type QueryNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<NotificationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<NotificationWhereInput>
}

export type QueryOMrPartnerArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: OmrPartnerWhereUniqueInput
}

export type QueryOMrPartnerVersionArgs = {
  where: VersionWhereInput
}

export type QueryOMrPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<OmrPartnerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<OmrPartnerWhereInput>
}

export type QueryOMrPartnersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<OmrPartnerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<OmrPartnerWhereInput>
}

export type QueryPartnerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PartnerCompanyWhereInput>
}

export type QueryPartnerCompaniesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PartnerCompanyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PartnerCompanyWhereInput>
}

export type QueryPartnerCompanyArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: PartnerCompanyWhereUniqueInput
}

export type QueryPartnerCompanyVersionArgs = {
  where: VersionWhereInput
}

export type QueryPhysicalLocationArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: PhysicalLocationWhereUniqueInput
}

export type QueryPhysicalLocationVersionArgs = {
  where: VersionWhereInput
}

export type QueryPhysicalLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PhysicalLocationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PhysicalLocationWhereInput>
}

export type QueryPhysicalLocationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PhysicalLocationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PhysicalLocationWhereInput>
}

export type QueryPodcastEpisodeArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: PodcastEpisodeWhereUniqueInput
}

export type QueryPodcastEpisodeVersionArgs = {
  where: VersionWhereInput
}

export type QueryPodcastEpisodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PodcastEpisodeOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PodcastEpisodeWhereInput>
}

export type QueryPodcastEpisodesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PodcastEpisodeOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PodcastEpisodeWhereInput>
}

export type QueryPodcastGroupArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: PodcastGroupWhereUniqueInput
}

export type QueryPodcastGroupVersionArgs = {
  where: VersionWhereInput
}

export type QueryPodcastGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PodcastGroupOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PodcastGroupWhereInput>
}

export type QueryPodcastGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<PodcastGroupOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<PodcastGroupWhereInput>
}

export type QueryRecommendationFoodArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: RecommendationFoodWhereUniqueInput
}

export type QueryRecommendationFoodVersionArgs = {
  where: VersionWhereInput
}

export type QueryRecommendationNightOutArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: RecommendationNightOutWhereUniqueInput
}

export type QueryRecommendationNightOutVersionArgs = {
  where: VersionWhereInput
}

export type QueryRecommendationsFoodArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<RecommendationFoodOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<RecommendationFoodWhereInput>
}

export type QueryRecommendationsFoodConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<RecommendationFoodOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<RecommendationFoodWhereInput>
}

export type QueryRecommendationsNightOutArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<RecommendationNightOutOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<RecommendationNightOutWhereInput>
}

export type QueryRecommendationsNightOutConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<RecommendationNightOutOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<RecommendationNightOutWhereInput>
}

export type QueryReviewsAgenciesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsAgencyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsAgencyWhereInput>
}

export type QueryReviewsAgenciesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsAgencyOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsAgencyWhereInput>
}

export type QueryReviewsAgencyArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ReviewsAgencyWhereUniqueInput
}

export type QueryReviewsAgencyVersionArgs = {
  where: VersionWhereInput
}

export type QueryReviewsProductArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ReviewsProductWhereUniqueInput
}

export type QueryReviewsProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductCategoryWhereInput>
}

export type QueryReviewsProductCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductCategoryWhereInput>
}

export type QueryReviewsProductCategoryArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ReviewsProductCategoryWhereUniqueInput
}

export type QueryReviewsProductCategoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryReviewsProductVersionArgs = {
  where: VersionWhereInput
}

export type QueryReviewsProductWidgetArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ReviewsProductWidgetWhereUniqueInput
}

export type QueryReviewsProductWidgetVersionArgs = {
  where: VersionWhereInput
}

export type QueryReviewsProductWidgetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductWidgetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductWidgetWhereInput>
}

export type QueryReviewsProductWidgetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductWidgetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductWidgetWhereInput>
}

export type QueryReviewsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductWhereInput>
}

export type QueryReviewsProductsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ReviewsProductWhereInput>
}

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ScheduledOperationWhereUniqueInput
}

export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: ScheduledReleaseWhereUniqueInput
}

export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ScheduledReleaseWhereInput>
}

export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<ScheduledReleaseWhereInput>
}

export type QuerySocialEmbedArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: SocialEmbedWhereUniqueInput
}

export type QuerySocialEmbedVersionArgs = {
  where: VersionWhereInput
}

export type QuerySocialEmbedsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SocialEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SocialEmbedWhereInput>
}

export type QuerySocialEmbedsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SocialEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SocialEmbedWhereInput>
}

export type QuerySpeakerArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: SpeakerWhereUniqueInput
}

export type QuerySpeakerListOrderArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: SpeakerListOrderWhereUniqueInput
}

export type QuerySpeakerListOrderVersionArgs = {
  where: VersionWhereInput
}

export type QuerySpeakerListOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SpeakerListOrderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SpeakerListOrderWhereInput>
}

export type QuerySpeakerListOrdersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SpeakerListOrderOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SpeakerListOrderWhereInput>
}

export type QuerySpeakerVersionArgs = {
  where: VersionWhereInput
}

export type QuerySpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SpeakerWhereInput>
}

export type QuerySpeakersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<SpeakerWhereInput>
}

export type QueryTabContentArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: TabContentWhereUniqueInput
}

export type QueryTabContentVersionArgs = {
  where: VersionWhereInput
}

export type QueryTabContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TabContentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TabContentWhereInput>
}

export type QueryTabContentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TabContentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TabContentWhereInput>
}

export type QueryTagArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: TagWhereUniqueInput
}

export type QueryTagVersionArgs = {
  where: VersionWhereInput
}

export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TagWhereInput>
}

export type QueryTagsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TagWhereInput>
}

export type QueryTopicPageArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: TopicPageWhereUniqueInput
}

export type QueryTopicPageCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TopicPageCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TopicPageCategoryWhereInput>
}

export type QueryTopicPageCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TopicPageCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TopicPageCategoryWhereInput>
}

export type QueryTopicPageCategoryArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: TopicPageCategoryWhereUniqueInput
}

export type QueryTopicPageCategoryVersionArgs = {
  where: VersionWhereInput
}

export type QueryTopicPageVersionArgs = {
  where: VersionWhereInput
}

export type QueryTopicPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TopicPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TopicPageWhereInput>
}

export type QueryTopicPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<TopicPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<TopicPageWhereInput>
}

export type QueryUserArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: UserWhereUniqueInput
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<UserWhereInput>
}

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<UserOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<UserWhereInput>
}

export type QueryVgWortArgs = {
  locales?: Array<Locale>
  stage?: Stage
  where: VgWortWhereUniqueInput
}

export type QueryVgWortVersionArgs = {
  where: VersionWhereInput
}

export type QueryVgWortsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<VgWortOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<VgWortWhereInput>
}

export type QueryVgWortsConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: Array<Locale>
  orderBy?: InputMaybe<VgWortOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  stage?: Stage
  where?: InputMaybe<VgWortWhereInput>
}

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA'
  a: Scalars['RGBATransparency']['output']
  b: Scalars['RGBAHue']['output']
  g: Scalars['RGBAHue']['output']
  r: Scalars['RGBAHue']['output']
}

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars['RGBATransparency']['input']
  b: Scalars['RGBAHue']['input']
  g: Scalars['RGBAHue']['input']
  r: Scalars['RGBAHue']['input']
}

/** a stand with food at the festival */
export type RecommendationFood = Entity &
  Node & {
    __typename?: 'RecommendationFood'
    /** for example A0 FT11.05 */
    boothNumber?: Maybe<Scalars['String']['output']>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** label for CTA link */
    ctaText?: Maybe<Scalars['String']['output']>
    /** link target for CTA */
    ctaUrl?: Maybe<Scalars['String']['output']>
    /** a short description of the food stand */
    description: Scalars['String']['output']
    /** Get the document in other stages */
    documentInStages: Array<RecommendationFood>
    /** List of RecommendationFood versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<RecommendationFood>
    /** the name of the stand */
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    typeOfFood: Array<FestivalFoodType>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** a stand with food at the festival */
export type RecommendationFoodCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a stand with food at the festival */
export type RecommendationFoodCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** a stand with food at the festival */
export type RecommendationFoodDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** a stand with food at the festival */
export type RecommendationFoodHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** a stand with food at the festival */
export type RecommendationFoodLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** a stand with food at the festival */
export type RecommendationFoodPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a stand with food at the festival */
export type RecommendationFoodPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** a stand with food at the festival */
export type RecommendationFoodScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** a stand with food at the festival */
export type RecommendationFoodUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a stand with food at the festival */
export type RecommendationFoodUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type RecommendationFoodConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: RecommendationFoodWhereUniqueInput
}

/** A connection to a list of items. */
export type RecommendationFoodConnection = {
  __typename?: 'RecommendationFoodConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<RecommendationFoodEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type RecommendationFoodCreateInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RecommendationFoodCreateLocalizationsInput>
  name: Scalars['String']['input']
  typeOfFood?: InputMaybe<Array<FestivalFoodType>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type RecommendationFoodCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type RecommendationFoodCreateLocalizationInput = {
  /** Localization input */
  data: RecommendationFoodCreateLocalizationDataInput
  locale: Locale
}

export type RecommendationFoodCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RecommendationFoodCreateLocalizationInput>>
}

export type RecommendationFoodCreateManyInlineInput = {
  /** Connect multiple existing RecommendationFood documents */
  connect?: InputMaybe<Array<RecommendationFoodWhereUniqueInput>>
  /** Create and connect multiple existing RecommendationFood documents */
  create?: InputMaybe<Array<RecommendationFoodCreateInput>>
}

export type RecommendationFoodCreateOneInlineInput = {
  /** Connect one existing RecommendationFood document */
  connect?: InputMaybe<RecommendationFoodWhereUniqueInput>
  /** Create and connect one RecommendationFood document */
  create?: InputMaybe<RecommendationFoodCreateInput>
}

/** An edge in a connection. */
export type RecommendationFoodEdge = {
  __typename?: 'RecommendationFoodEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: RecommendationFood
}

/** Identifies documents */
export type RecommendationFoodManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  boothNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  boothNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  boothNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  boothNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  boothNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  boothNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  boothNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  boothNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  boothNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<RecommendationFoodWhereStageInput>
  documentInStages_none?: InputMaybe<RecommendationFoodWhereStageInput>
  documentInStages_some?: InputMaybe<RecommendationFoodWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  typeOfFood?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array contains *all* items provided to the filter */
  typeOfFood_contains_all?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  typeOfFood_contains_none?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array contains at least one item provided to the filter */
  typeOfFood_contains_some?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  typeOfFood_not?: InputMaybe<Array<FestivalFoodType>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum RecommendationFoodOrderByInput {
  BoothNumberAsc = 'boothNumber_ASC',
  BoothNumberDesc = 'boothNumber_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeOfFoodAsc = 'typeOfFood_ASC',
  TypeOfFoodDesc = 'typeOfFood_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RecommendationFoodUpdateInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<RecommendationFoodUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  typeOfFood?: InputMaybe<Array<FestivalFoodType>>
}

export type RecommendationFoodUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
}

export type RecommendationFoodUpdateLocalizationInput = {
  data: RecommendationFoodUpdateLocalizationDataInput
  locale: Locale
}

export type RecommendationFoodUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RecommendationFoodCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<RecommendationFoodUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<RecommendationFoodUpsertLocalizationInput>>
}

export type RecommendationFoodUpdateManyInlineInput = {
  /** Connect multiple existing RecommendationFood documents */
  connect?: InputMaybe<Array<RecommendationFoodConnectInput>>
  /** Create and connect multiple RecommendationFood documents */
  create?: InputMaybe<Array<RecommendationFoodCreateInput>>
  /** Delete multiple RecommendationFood documents */
  delete?: InputMaybe<Array<RecommendationFoodWhereUniqueInput>>
  /** Disconnect multiple RecommendationFood documents */
  disconnect?: InputMaybe<Array<RecommendationFoodWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing RecommendationFood documents */
  set?: InputMaybe<Array<RecommendationFoodWhereUniqueInput>>
  /** Update multiple RecommendationFood documents */
  update?: InputMaybe<Array<RecommendationFoodUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple RecommendationFood documents */
  upsert?: InputMaybe<Array<RecommendationFoodUpsertWithNestedWhereUniqueInput>>
}

export type RecommendationFoodUpdateManyInput = {
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<RecommendationFoodUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  typeOfFood?: InputMaybe<Array<FestivalFoodType>>
}

export type RecommendationFoodUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
}

export type RecommendationFoodUpdateManyLocalizationInput = {
  data: RecommendationFoodUpdateManyLocalizationDataInput
  locale: Locale
}

export type RecommendationFoodUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RecommendationFoodUpdateManyLocalizationInput>>
}

export type RecommendationFoodUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RecommendationFoodUpdateManyInput
  /** Document search */
  where: RecommendationFoodWhereInput
}

export type RecommendationFoodUpdateOneInlineInput = {
  /** Connect existing RecommendationFood document */
  connect?: InputMaybe<RecommendationFoodWhereUniqueInput>
  /** Create and connect one RecommendationFood document */
  create?: InputMaybe<RecommendationFoodCreateInput>
  /** Delete currently connected RecommendationFood document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected RecommendationFood document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RecommendationFood document */
  update?: InputMaybe<RecommendationFoodUpdateWithNestedWhereUniqueInput>
  /** Upsert single RecommendationFood document */
  upsert?: InputMaybe<RecommendationFoodUpsertWithNestedWhereUniqueInput>
}

export type RecommendationFoodUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RecommendationFoodUpdateInput
  /** Unique document search */
  where: RecommendationFoodWhereUniqueInput
}

export type RecommendationFoodUpsertInput = {
  /** Create document if it didn't exist */
  create: RecommendationFoodCreateInput
  /** Update document if it exists */
  update: RecommendationFoodUpdateInput
}

export type RecommendationFoodUpsertLocalizationInput = {
  create: RecommendationFoodCreateLocalizationDataInput
  locale: Locale
  update: RecommendationFoodUpdateLocalizationDataInput
}

export type RecommendationFoodUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RecommendationFoodUpsertInput
  /** Unique document search */
  where: RecommendationFoodWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type RecommendationFoodWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type RecommendationFoodWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationFoodWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  boothNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  boothNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  boothNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  boothNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  boothNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  boothNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  boothNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  boothNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  boothNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<RecommendationFoodWhereStageInput>
  documentInStages_none?: InputMaybe<RecommendationFoodWhereStageInput>
  documentInStages_some?: InputMaybe<RecommendationFoodWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  typeOfFood?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array contains *all* items provided to the filter */
  typeOfFood_contains_all?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  typeOfFood_contains_none?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array contains at least one item provided to the filter */
  typeOfFood_contains_some?: InputMaybe<Array<FestivalFoodType>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  typeOfFood_not?: InputMaybe<Array<FestivalFoodType>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type RecommendationFoodWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationFoodWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationFoodWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationFoodWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<RecommendationFoodWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References RecommendationFood record uniquely */
export type RecommendationFoodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** a place to go at night during the festival */
export type RecommendationNightOut = Entity &
  Node & {
    __typename?: 'RecommendationNightOut'
    /** street and postcode with town */
    address: Scalars['String']['output']
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** label for CTA link */
    ctaText?: Maybe<Scalars['String']['output']>
    /** link target for CTA */
    ctaUrl?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<RecommendationNightOut>
    /** List of RecommendationNightOut versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<RecommendationNightOut>
    /** the name of the place */
    name: Scalars['String']['output']
    /** with leading +49 xx xxxxxxx */
    phoneNumber?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    typeOfNightOut: FestivalNightOutType
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** a place to go at night during the festival */
export type RecommendationNightOutCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a place to go at night during the festival */
export type RecommendationNightOutCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** a place to go at night during the festival */
export type RecommendationNightOutDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** a place to go at night during the festival */
export type RecommendationNightOutHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** a place to go at night during the festival */
export type RecommendationNightOutLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** a place to go at night during the festival */
export type RecommendationNightOutPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a place to go at night during the festival */
export type RecommendationNightOutPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** a place to go at night during the festival */
export type RecommendationNightOutScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** a place to go at night during the festival */
export type RecommendationNightOutUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** a place to go at night during the festival */
export type RecommendationNightOutUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type RecommendationNightOutConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: RecommendationNightOutWhereUniqueInput
}

/** A connection to a list of items. */
export type RecommendationNightOutConnection = {
  __typename?: 'RecommendationNightOutConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<RecommendationNightOutEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type RecommendationNightOutCreateInput = {
  address: Scalars['String']['input']
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RecommendationNightOutCreateLocalizationsInput>
  name: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  typeOfNightOut: FestivalNightOutType
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type RecommendationNightOutCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type RecommendationNightOutCreateLocalizationInput = {
  /** Localization input */
  data: RecommendationNightOutCreateLocalizationDataInput
  locale: Locale
}

export type RecommendationNightOutCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RecommendationNightOutCreateLocalizationInput>>
}

export type RecommendationNightOutCreateManyInlineInput = {
  /** Connect multiple existing RecommendationNightOut documents */
  connect?: InputMaybe<Array<RecommendationNightOutWhereUniqueInput>>
  /** Create and connect multiple existing RecommendationNightOut documents */
  create?: InputMaybe<Array<RecommendationNightOutCreateInput>>
}

export type RecommendationNightOutCreateOneInlineInput = {
  /** Connect one existing RecommendationNightOut document */
  connect?: InputMaybe<RecommendationNightOutWhereUniqueInput>
  /** Create and connect one RecommendationNightOut document */
  create?: InputMaybe<RecommendationNightOutCreateInput>
}

/** An edge in a connection. */
export type RecommendationNightOutEdge = {
  __typename?: 'RecommendationNightOutEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: RecommendationNightOut
}

/** Identifies documents */
export type RecommendationNightOutManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  address_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  address_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  address_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  address_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  address_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  address_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  address_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<RecommendationNightOutWhereStageInput>
  documentInStages_none?: InputMaybe<RecommendationNightOutWhereStageInput>
  documentInStages_some?: InputMaybe<RecommendationNightOutWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  typeOfNightOut?: InputMaybe<FestivalNightOutType>
  /** All values that are contained in given list. */
  typeOfNightOut_in?: InputMaybe<Array<InputMaybe<FestivalNightOutType>>>
  /** Any other value that exists and is not equal to the given value. */
  typeOfNightOut_not?: InputMaybe<FestivalNightOutType>
  /** All values that are not contained in given list. */
  typeOfNightOut_not_in?: InputMaybe<Array<InputMaybe<FestivalNightOutType>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum RecommendationNightOutOrderByInput {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PhoneNumberAsc = 'phoneNumber_ASC',
  PhoneNumberDesc = 'phoneNumber_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TypeOfNightOutAsc = 'typeOfNightOut_ASC',
  TypeOfNightOutDesc = 'typeOfNightOut_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RecommendationNightOutUpdateInput = {
  address?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<RecommendationNightOutUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  typeOfNightOut?: InputMaybe<FestivalNightOutType>
}

export type RecommendationNightOutUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
}

export type RecommendationNightOutUpdateLocalizationInput = {
  data: RecommendationNightOutUpdateLocalizationDataInput
  locale: Locale
}

export type RecommendationNightOutUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RecommendationNightOutCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<RecommendationNightOutUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<RecommendationNightOutUpsertLocalizationInput>>
}

export type RecommendationNightOutUpdateManyInlineInput = {
  /** Connect multiple existing RecommendationNightOut documents */
  connect?: InputMaybe<Array<RecommendationNightOutConnectInput>>
  /** Create and connect multiple RecommendationNightOut documents */
  create?: InputMaybe<Array<RecommendationNightOutCreateInput>>
  /** Delete multiple RecommendationNightOut documents */
  delete?: InputMaybe<Array<RecommendationNightOutWhereUniqueInput>>
  /** Disconnect multiple RecommendationNightOut documents */
  disconnect?: InputMaybe<Array<RecommendationNightOutWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing RecommendationNightOut documents */
  set?: InputMaybe<Array<RecommendationNightOutWhereUniqueInput>>
  /** Update multiple RecommendationNightOut documents */
  update?: InputMaybe<Array<RecommendationNightOutUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple RecommendationNightOut documents */
  upsert?: InputMaybe<Array<RecommendationNightOutUpsertWithNestedWhereUniqueInput>>
}

export type RecommendationNightOutUpdateManyInput = {
  address?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<RecommendationNightOutUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  typeOfNightOut?: InputMaybe<FestivalNightOutType>
}

export type RecommendationNightOutUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
}

export type RecommendationNightOutUpdateManyLocalizationInput = {
  data: RecommendationNightOutUpdateManyLocalizationDataInput
  locale: Locale
}

export type RecommendationNightOutUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RecommendationNightOutUpdateManyLocalizationInput>>
}

export type RecommendationNightOutUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RecommendationNightOutUpdateManyInput
  /** Document search */
  where: RecommendationNightOutWhereInput
}

export type RecommendationNightOutUpdateOneInlineInput = {
  /** Connect existing RecommendationNightOut document */
  connect?: InputMaybe<RecommendationNightOutWhereUniqueInput>
  /** Create and connect one RecommendationNightOut document */
  create?: InputMaybe<RecommendationNightOutCreateInput>
  /** Delete currently connected RecommendationNightOut document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected RecommendationNightOut document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RecommendationNightOut document */
  update?: InputMaybe<RecommendationNightOutUpdateWithNestedWhereUniqueInput>
  /** Upsert single RecommendationNightOut document */
  upsert?: InputMaybe<RecommendationNightOutUpsertWithNestedWhereUniqueInput>
}

export type RecommendationNightOutUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RecommendationNightOutUpdateInput
  /** Unique document search */
  where: RecommendationNightOutWhereUniqueInput
}

export type RecommendationNightOutUpsertInput = {
  /** Create document if it didn't exist */
  create: RecommendationNightOutCreateInput
  /** Update document if it exists */
  update: RecommendationNightOutUpdateInput
}

export type RecommendationNightOutUpsertLocalizationInput = {
  create: RecommendationNightOutCreateLocalizationDataInput
  locale: Locale
  update: RecommendationNightOutUpdateLocalizationDataInput
}

export type RecommendationNightOutUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RecommendationNightOutUpsertInput
  /** Unique document search */
  where: RecommendationNightOutWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type RecommendationNightOutWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type RecommendationNightOutWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationNightOutWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  address?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  address_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  address_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  address_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  address_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  address_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  address_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  address_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  address_starts_with?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<RecommendationNightOutWhereStageInput>
  documentInStages_none?: InputMaybe<RecommendationNightOutWhereStageInput>
  documentInStages_some?: InputMaybe<RecommendationNightOutWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  phoneNumber_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  phoneNumber_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  phoneNumber_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  phoneNumber_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  phoneNumber_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  phoneNumber_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  phoneNumber_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  phoneNumber_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  phoneNumber_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  typeOfNightOut?: InputMaybe<FestivalNightOutType>
  /** All values that are contained in given list. */
  typeOfNightOut_in?: InputMaybe<Array<InputMaybe<FestivalNightOutType>>>
  /** Any other value that exists and is not equal to the given value. */
  typeOfNightOut_not?: InputMaybe<FestivalNightOutType>
  /** All values that are not contained in given list. */
  typeOfNightOut_not_in?: InputMaybe<Array<InputMaybe<FestivalNightOutType>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type RecommendationNightOutWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RecommendationNightOutWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RecommendationNightOutWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RecommendationNightOutWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<RecommendationNightOutWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References RecommendationNightOut record uniquely */
export type RecommendationNightOutWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum ResultCreationOptions {
  Filter = 'filter',
  Sort = 'sort',
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgency = Entity &
  Node & {
    __typename?: 'ReviewsAgency'
    articles: Array<Article>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<ReviewsAgency>
    /** List of ReviewsAgency versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    logoUrl?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    published?: Maybe<Scalars['Boolean']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    uniqueId?: Maybe<Scalars['Int']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Reviews Agencies synchronised from reviews-api service */
export type ReviewsAgencyUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ReviewsAgencyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ReviewsAgencyWhereUniqueInput
}

/** A connection to a list of items. */
export type ReviewsAgencyConnection = {
  __typename?: 'ReviewsAgencyConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ReviewsAgencyEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ReviewsAgencyCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  published?: InputMaybe<Scalars['Boolean']['input']>
  slug: Scalars['String']['input']
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsAgencyCreateManyInlineInput = {
  /** Connect multiple existing ReviewsAgency documents */
  connect?: InputMaybe<Array<ReviewsAgencyWhereUniqueInput>>
  /** Create and connect multiple existing ReviewsAgency documents */
  create?: InputMaybe<Array<ReviewsAgencyCreateInput>>
}

export type ReviewsAgencyCreateOneInlineInput = {
  /** Connect one existing ReviewsAgency document */
  connect?: InputMaybe<ReviewsAgencyWhereUniqueInput>
  /** Create and connect one ReviewsAgency document */
  create?: InputMaybe<ReviewsAgencyCreateInput>
}

/** An edge in a connection. */
export type ReviewsAgencyEdge = {
  __typename?: 'ReviewsAgencyEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ReviewsAgency
}

/** Identifies documents */
export type ReviewsAgencyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsAgencyWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsAgencyWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsAgencyWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ReviewsAgencyOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ReviewsAgencyUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

export type ReviewsAgencyUpdateManyInlineInput = {
  /** Connect multiple existing ReviewsAgency documents */
  connect?: InputMaybe<Array<ReviewsAgencyConnectInput>>
  /** Create and connect multiple ReviewsAgency documents */
  create?: InputMaybe<Array<ReviewsAgencyCreateInput>>
  /** Delete multiple ReviewsAgency documents */
  delete?: InputMaybe<Array<ReviewsAgencyWhereUniqueInput>>
  /** Disconnect multiple ReviewsAgency documents */
  disconnect?: InputMaybe<Array<ReviewsAgencyWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ReviewsAgency documents */
  set?: InputMaybe<Array<ReviewsAgencyWhereUniqueInput>>
  /** Update multiple ReviewsAgency documents */
  update?: InputMaybe<Array<ReviewsAgencyUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ReviewsAgency documents */
  upsert?: InputMaybe<Array<ReviewsAgencyUpsertWithNestedWhereUniqueInput>>
}

export type ReviewsAgencyUpdateManyInput = {
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
}

export type ReviewsAgencyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ReviewsAgencyUpdateManyInput
  /** Document search */
  where: ReviewsAgencyWhereInput
}

export type ReviewsAgencyUpdateOneInlineInput = {
  /** Connect existing ReviewsAgency document */
  connect?: InputMaybe<ReviewsAgencyWhereUniqueInput>
  /** Create and connect one ReviewsAgency document */
  create?: InputMaybe<ReviewsAgencyCreateInput>
  /** Delete currently connected ReviewsAgency document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ReviewsAgency document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ReviewsAgency document */
  update?: InputMaybe<ReviewsAgencyUpdateWithNestedWhereUniqueInput>
  /** Upsert single ReviewsAgency document */
  upsert?: InputMaybe<ReviewsAgencyUpsertWithNestedWhereUniqueInput>
}

export type ReviewsAgencyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ReviewsAgencyUpdateInput
  /** Unique document search */
  where: ReviewsAgencyWhereUniqueInput
}

export type ReviewsAgencyUpsertInput = {
  /** Create document if it didn't exist */
  create: ReviewsAgencyCreateInput
  /** Update document if it exists */
  update: ReviewsAgencyUpdateInput
}

export type ReviewsAgencyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ReviewsAgencyUpsertInput
  /** Unique document search */
  where: ReviewsAgencyWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ReviewsAgencyWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ReviewsAgencyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsAgencyWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsAgencyWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsAgencyWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsAgencyWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ReviewsAgencyWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsAgencyWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsAgencyWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsAgencyWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ReviewsAgencyWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ReviewsAgency record uniquely */
export type ReviewsAgencyWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProduct = Entity &
  Node & {
    __typename?: 'ReviewsProduct'
    article: Array<Article>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ReviewsProduct>
    /** List of ReviewsProduct versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    isSuite?: Maybe<Scalars['Boolean']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<ReviewsProduct>
    logoUrl?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    partnerCompany?: Maybe<PartnerCompany>
    published?: Maybe<Scalars['Boolean']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    rating?: Maybe<Scalars['Float']['output']>
    ratingsCount?: Maybe<Scalars['Int']['output']>
    reviewsProductCategories: Array<ReviewsProductCategory>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Attributes. */
    seoAttributes?: Maybe<Seo>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    tags: Array<Tag>
    /** Backend ID */
    uniqueId?: Maybe<Scalars['Int']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductArticleArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductPartnerCompanyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductReviewsProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductCategoryWhereInput>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagWhereInput>
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Products synchronised from reviews-api service */
export type ReviewsProductUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategory = Entity &
  Node & {
    __typename?: 'ReviewsProductCategory'
    articles: Array<Article>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ReviewsProductCategory>
    /** List of ReviewsProductCategory versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<ReviewsProductCategory>
    name: Scalars['String']['output']
    productWidget: Array<ReviewsProductWidget>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    reviewsProducts: Array<ReviewsProduct>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Attributes. */
    seoAttributes?: Maybe<Seo>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    tags: Array<Tag>
    /** Backend ID */
    uniqueId?: Maybe<Scalars['Int']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryProductWidgetArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductWidgetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWidgetWhereInput>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryReviewsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWhereInput>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategorySeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagWhereInput>
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Reviews Product Categories synchronised from reviews-api service */
export type ReviewsProductCategoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ReviewsProductCategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ReviewsProductCategoryWhereUniqueInput
}

/** A connection to a list of items. */
export type ReviewsProductCategoryConnection = {
  __typename?: 'ReviewsProductCategoryConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ReviewsProductCategoryEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ReviewsProductCategoryCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ReviewsProductCategoryCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  productWidget?: InputMaybe<ReviewsProductWidgetCreateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  tags?: InputMaybe<TagCreateManyInlineInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsProductCategoryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsProductCategoryCreateLocalizationInput = {
  /** Localization input */
  data: ReviewsProductCategoryCreateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductCategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ReviewsProductCategoryCreateLocalizationInput>>
}

export type ReviewsProductCategoryCreateManyInlineInput = {
  /** Connect multiple existing ReviewsProductCategory documents */
  connect?: InputMaybe<Array<ReviewsProductCategoryWhereUniqueInput>>
  /** Create and connect multiple existing ReviewsProductCategory documents */
  create?: InputMaybe<Array<ReviewsProductCategoryCreateInput>>
}

export type ReviewsProductCategoryCreateOneInlineInput = {
  /** Connect one existing ReviewsProductCategory document */
  connect?: InputMaybe<ReviewsProductCategoryWhereUniqueInput>
  /** Create and connect one ReviewsProductCategory document */
  create?: InputMaybe<ReviewsProductCategoryCreateInput>
}

/** An edge in a connection. */
export type ReviewsProductCategoryEdge = {
  __typename?: 'ReviewsProductCategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ReviewsProductCategory
}

/** Identifies documents */
export type ReviewsProductCategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  productWidget_every?: InputMaybe<ReviewsProductWidgetWhereInput>
  productWidget_none?: InputMaybe<ReviewsProductWidgetWhereInput>
  productWidget_some?: InputMaybe<ReviewsProductWidgetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ReviewsProductCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ReviewsProductCategoryUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ReviewsProductCategoryUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  productWidget?: InputMaybe<ReviewsProductWidgetUpdateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagUpdateManyInlineInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

export type ReviewsProductCategoryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductCategoryUpdateLocalizationInput = {
  data: ReviewsProductCategoryUpdateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductCategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ReviewsProductCategoryCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductCategoryUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ReviewsProductCategoryUpsertLocalizationInput>>
}

export type ReviewsProductCategoryUpdateManyInlineInput = {
  /** Connect multiple existing ReviewsProductCategory documents */
  connect?: InputMaybe<Array<ReviewsProductCategoryConnectInput>>
  /** Create and connect multiple ReviewsProductCategory documents */
  create?: InputMaybe<Array<ReviewsProductCategoryCreateInput>>
  /** Delete multiple ReviewsProductCategory documents */
  delete?: InputMaybe<Array<ReviewsProductCategoryWhereUniqueInput>>
  /** Disconnect multiple ReviewsProductCategory documents */
  disconnect?: InputMaybe<Array<ReviewsProductCategoryWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ReviewsProductCategory documents */
  set?: InputMaybe<Array<ReviewsProductCategoryWhereUniqueInput>>
  /** Update multiple ReviewsProductCategory documents */
  update?: InputMaybe<Array<ReviewsProductCategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ReviewsProductCategory documents */
  upsert?: InputMaybe<Array<ReviewsProductCategoryUpsertWithNestedWhereUniqueInput>>
}

export type ReviewsProductCategoryUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ReviewsProductCategoryUpdateManyLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductCategoryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductCategoryUpdateManyLocalizationInput = {
  data: ReviewsProductCategoryUpdateManyLocalizationDataInput
  locale: Locale
}

export type ReviewsProductCategoryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductCategoryUpdateManyLocalizationInput>>
}

export type ReviewsProductCategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ReviewsProductCategoryUpdateManyInput
  /** Document search */
  where: ReviewsProductCategoryWhereInput
}

export type ReviewsProductCategoryUpdateOneInlineInput = {
  /** Connect existing ReviewsProductCategory document */
  connect?: InputMaybe<ReviewsProductCategoryWhereUniqueInput>
  /** Create and connect one ReviewsProductCategory document */
  create?: InputMaybe<ReviewsProductCategoryCreateInput>
  /** Delete currently connected ReviewsProductCategory document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ReviewsProductCategory document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ReviewsProductCategory document */
  update?: InputMaybe<ReviewsProductCategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single ReviewsProductCategory document */
  upsert?: InputMaybe<ReviewsProductCategoryUpsertWithNestedWhereUniqueInput>
}

export type ReviewsProductCategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ReviewsProductCategoryUpdateInput
  /** Unique document search */
  where: ReviewsProductCategoryWhereUniqueInput
}

export type ReviewsProductCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: ReviewsProductCategoryCreateInput
  /** Update document if it exists */
  update: ReviewsProductCategoryUpdateInput
}

export type ReviewsProductCategoryUpsertLocalizationInput = {
  create: ReviewsProductCategoryCreateLocalizationDataInput
  locale: Locale
  update: ReviewsProductCategoryUpdateLocalizationDataInput
}

export type ReviewsProductCategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ReviewsProductCategoryUpsertInput
  /** Unique document search */
  where: ReviewsProductCategoryWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ReviewsProductCategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ReviewsProductCategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  productWidget_every?: InputMaybe<ReviewsProductWidgetWhereInput>
  productWidget_none?: InputMaybe<ReviewsProductWidgetWhereInput>
  productWidget_some?: InputMaybe<ReviewsProductWidgetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ReviewsProductCategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductCategoryWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductCategoryWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductCategoryWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ReviewsProductCategoryWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ReviewsProductCategory record uniquely */
export type ReviewsProductCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

export type ReviewsProductConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ReviewsProductWhereUniqueInput
}

/** A connection to a list of items. */
export type ReviewsProductConnection = {
  __typename?: 'ReviewsProductConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ReviewsProductEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ReviewsProductCreateInput = {
  article?: InputMaybe<ArticleCreateManyInlineInput>
  cm03pfq6y2ct906tc3rzw3utq?: InputMaybe<PpcReviewsProductCreateManyInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  isSuite?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ReviewsProductCreateLocalizationsInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  partnerCompany?: InputMaybe<PartnerCompanyCreateOneInlineInput>
  published?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  ratingsCount?: InputMaybe<Scalars['Int']['input']>
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  slug: Scalars['String']['input']
  tags?: InputMaybe<TagCreateManyInlineInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsProductCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsProductCreateLocalizationInput = {
  /** Localization input */
  data: ReviewsProductCreateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ReviewsProductCreateLocalizationInput>>
}

export type ReviewsProductCreateManyInlineInput = {
  /** Connect multiple existing ReviewsProduct documents */
  connect?: InputMaybe<Array<ReviewsProductWhereUniqueInput>>
  /** Create and connect multiple existing ReviewsProduct documents */
  create?: InputMaybe<Array<ReviewsProductCreateInput>>
}

export type ReviewsProductCreateOneInlineInput = {
  /** Connect one existing ReviewsProduct document */
  connect?: InputMaybe<ReviewsProductWhereUniqueInput>
  /** Create and connect one ReviewsProduct document */
  create?: InputMaybe<ReviewsProductCreateInput>
}

/** An edge in a connection. */
export type ReviewsProductEdge = {
  __typename?: 'ReviewsProductEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ReviewsProduct
}

/** Identifies documents */
export type ReviewsProductManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article_every?: InputMaybe<ArticleWhereInput>
  article_none?: InputMaybe<ArticleWhereInput>
  article_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsProductWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isSuite?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isSuite_not?: InputMaybe<Scalars['Boolean']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  rating_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  rating_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  rating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  rating_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  rating_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  rating_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  ratingsCount?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  ratingsCount_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  ratingsCount_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  ratingsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  ratingsCount_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  ratingsCount_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ratingsCount_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  ratingsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ReviewsProductOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsSuiteAsc = 'isSuite_ASC',
  IsSuiteDesc = 'isSuite_DESC',
  LogoUrlAsc = 'logoUrl_ASC',
  LogoUrlDesc = 'logoUrl_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PublishedAsc = 'published_ASC',
  PublishedDesc = 'published_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  RatingsCountAsc = 'ratingsCount_ASC',
  RatingsCountDesc = 'ratingsCount_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UniqueIdAsc = 'uniqueId_ASC',
  UniqueIdDesc = 'uniqueId_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type ReviewsProductUpdateInput = {
  article?: InputMaybe<ArticleUpdateManyInlineInput>
  cm03pfq6y2ct906tc3rzw3utq?: InputMaybe<PpcReviewsProductUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  isSuite?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ReviewsProductUpdateLocalizationsInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyUpdateOneInlineInput>
  published?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  ratingsCount?: InputMaybe<Scalars['Int']['input']>
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagUpdateManyInlineInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

export type ReviewsProductUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductUpdateLocalizationInput = {
  data: ReviewsProductUpdateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ReviewsProductCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ReviewsProductUpsertLocalizationInput>>
}

export type ReviewsProductUpdateManyInlineInput = {
  /** Connect multiple existing ReviewsProduct documents */
  connect?: InputMaybe<Array<ReviewsProductConnectInput>>
  /** Create and connect multiple ReviewsProduct documents */
  create?: InputMaybe<Array<ReviewsProductCreateInput>>
  /** Delete multiple ReviewsProduct documents */
  delete?: InputMaybe<Array<ReviewsProductWhereUniqueInput>>
  /** Disconnect multiple ReviewsProduct documents */
  disconnect?: InputMaybe<Array<ReviewsProductWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ReviewsProduct documents */
  set?: InputMaybe<Array<ReviewsProductWhereUniqueInput>>
  /** Update multiple ReviewsProduct documents */
  update?: InputMaybe<Array<ReviewsProductUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ReviewsProduct documents */
  upsert?: InputMaybe<Array<ReviewsProductUpsertWithNestedWhereUniqueInput>>
}

export type ReviewsProductUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  isSuite?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ReviewsProductUpdateManyLocalizationsInput>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  published?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  ratingsCount?: InputMaybe<Scalars['Int']['input']>
}

export type ReviewsProductUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductUpdateManyLocalizationInput = {
  data: ReviewsProductUpdateManyLocalizationDataInput
  locale: Locale
}

export type ReviewsProductUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductUpdateManyLocalizationInput>>
}

export type ReviewsProductUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ReviewsProductUpdateManyInput
  /** Document search */
  where: ReviewsProductWhereInput
}

export type ReviewsProductUpdateOneInlineInput = {
  /** Connect existing ReviewsProduct document */
  connect?: InputMaybe<ReviewsProductWhereUniqueInput>
  /** Create and connect one ReviewsProduct document */
  create?: InputMaybe<ReviewsProductCreateInput>
  /** Delete currently connected ReviewsProduct document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ReviewsProduct document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ReviewsProduct document */
  update?: InputMaybe<ReviewsProductUpdateWithNestedWhereUniqueInput>
  /** Upsert single ReviewsProduct document */
  upsert?: InputMaybe<ReviewsProductUpsertWithNestedWhereUniqueInput>
}

export type ReviewsProductUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ReviewsProductUpdateInput
  /** Unique document search */
  where: ReviewsProductWhereUniqueInput
}

export type ReviewsProductUpsertInput = {
  /** Create document if it didn't exist */
  create: ReviewsProductCreateInput
  /** Update document if it exists */
  update: ReviewsProductUpdateInput
}

export type ReviewsProductUpsertLocalizationInput = {
  create: ReviewsProductCreateLocalizationDataInput
  locale: Locale
  update: ReviewsProductUpdateLocalizationDataInput
}

export type ReviewsProductUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ReviewsProductUpsertInput
  /** Unique document search */
  where: ReviewsProductWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ReviewsProductWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ReviewsProductWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article_every?: InputMaybe<ArticleWhereInput>
  article_none?: InputMaybe<ArticleWhereInput>
  article_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<ReviewsProductWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isSuite?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isSuite_not?: InputMaybe<Scalars['Boolean']['input']>
  logoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  logoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  logoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  logoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  logoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  logoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  logoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  logoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  logoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  logoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  published?: InputMaybe<Scalars['Boolean']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** Any other value that exists and is not equal to the given value. */
  published_not?: InputMaybe<Scalars['Boolean']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than the given value. */
  rating_gt?: InputMaybe<Scalars['Float']['input']>
  /** All values greater than or equal the given value. */
  rating_gte?: InputMaybe<Scalars['Float']['input']>
  /** All values that are contained in given list. */
  rating_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  /** All values less than the given value. */
  rating_lt?: InputMaybe<Scalars['Float']['input']>
  /** All values less than or equal the given value. */
  rating_lte?: InputMaybe<Scalars['Float']['input']>
  /** Any other value that exists and is not equal to the given value. */
  rating_not?: InputMaybe<Scalars['Float']['input']>
  /** All values that are not contained in given list. */
  rating_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  ratingsCount?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  ratingsCount_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  ratingsCount_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  ratingsCount_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  ratingsCount_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  ratingsCount_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ratingsCount_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  ratingsCount_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  uniqueId_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  uniqueId_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  uniqueId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  uniqueId_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  uniqueId_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  uniqueId_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  uniqueId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ReviewsProductWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ReviewsProductWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ReviewsProduct record uniquely */
export type ReviewsProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  uniqueId?: InputMaybe<Scalars['Int']['input']>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidget = Entity &
  Node & {
    __typename?: 'ReviewsProductWidget'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<ReviewsProductWidget>
    /** Widget Headline */
    headline?: Maybe<Scalars['String']['output']>
    /** List of ReviewsProductWidget versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<ReviewsProductWidget>
    /** Disable the minimum amount of products needed for showing the widget. */
    noMinimum?: Maybe<Scalars['Boolean']['output']>
    /** Choose Category for products shown in this widget */
    productCategory: ReviewsProductCategory
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** Widget Subtext */
    subtext?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Choose ProductWidget Type */
    widgetType: ProductWidgetEnum
  }

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetProductCategoryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Widgets to display pre-configurate productgroups in Contenthub */
export type ReviewsProductWidgetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ReviewsProductWidgetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ReviewsProductWidgetWhereUniqueInput
}

/** A connection to a list of items. */
export type ReviewsProductWidgetConnection = {
  __typename?: 'ReviewsProductWidgetConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ReviewsProductWidgetEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ReviewsProductWidgetCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ReviewsProductWidgetCreateLocalizationsInput>
  noMinimum?: InputMaybe<Scalars['Boolean']['input']>
  productCategory: ReviewsProductCategoryCreateOneInlineInput
  /** subtext input for default locale (de) */
  subtext?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  widgetType: ProductWidgetEnum
}

export type ReviewsProductWidgetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  headline?: InputMaybe<Scalars['String']['input']>
  subtext?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ReviewsProductWidgetCreateLocalizationInput = {
  /** Localization input */
  data: ReviewsProductWidgetCreateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductWidgetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ReviewsProductWidgetCreateLocalizationInput>>
}

export type ReviewsProductWidgetCreateManyInlineInput = {
  /** Connect multiple existing ReviewsProductWidget documents */
  connect?: InputMaybe<Array<ReviewsProductWidgetWhereUniqueInput>>
  /** Create and connect multiple existing ReviewsProductWidget documents */
  create?: InputMaybe<Array<ReviewsProductWidgetCreateInput>>
}

export type ReviewsProductWidgetCreateOneInlineInput = {
  /** Connect one existing ReviewsProductWidget document */
  connect?: InputMaybe<ReviewsProductWidgetWhereUniqueInput>
  /** Create and connect one ReviewsProductWidget document */
  create?: InputMaybe<ReviewsProductWidgetCreateInput>
}

/** An edge in a connection. */
export type ReviewsProductWidgetEdge = {
  __typename?: 'ReviewsProductWidgetEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ReviewsProductWidget
}

/** Identifies documents */
export type ReviewsProductWidgetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  noMinimum?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noMinimum_not?: InputMaybe<Scalars['Boolean']['input']>
  productCategory?: InputMaybe<ReviewsProductCategoryWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  widgetType?: InputMaybe<ProductWidgetEnum>
  /** All values that are contained in given list. */
  widgetType_in?: InputMaybe<Array<InputMaybe<ProductWidgetEnum>>>
  /** Any other value that exists and is not equal to the given value. */
  widgetType_not?: InputMaybe<ProductWidgetEnum>
  /** All values that are not contained in given list. */
  widgetType_not_in?: InputMaybe<Array<InputMaybe<ProductWidgetEnum>>>
}

export enum ReviewsProductWidgetOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NoMinimumAsc = 'noMinimum_ASC',
  NoMinimumDesc = 'noMinimum_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SubtextAsc = 'subtext_ASC',
  SubtextDesc = 'subtext_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidgetTypeAsc = 'widgetType_ASC',
  WidgetTypeDesc = 'widgetType_DESC',
}

export type ReviewsProductWidgetUpdateInput = {
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<ReviewsProductWidgetUpdateLocalizationsInput>
  noMinimum?: InputMaybe<Scalars['Boolean']['input']>
  productCategory?: InputMaybe<ReviewsProductCategoryUpdateOneInlineInput>
  /** subtext input for default locale (de) */
  subtext?: InputMaybe<Scalars['String']['input']>
  widgetType?: InputMaybe<ProductWidgetEnum>
}

export type ReviewsProductWidgetUpdateLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>
  subtext?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductWidgetUpdateLocalizationInput = {
  data: ReviewsProductWidgetUpdateLocalizationDataInput
  locale: Locale
}

export type ReviewsProductWidgetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ReviewsProductWidgetCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductWidgetUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<ReviewsProductWidgetUpsertLocalizationInput>>
}

export type ReviewsProductWidgetUpdateManyInlineInput = {
  /** Connect multiple existing ReviewsProductWidget documents */
  connect?: InputMaybe<Array<ReviewsProductWidgetConnectInput>>
  /** Create and connect multiple ReviewsProductWidget documents */
  create?: InputMaybe<Array<ReviewsProductWidgetCreateInput>>
  /** Delete multiple ReviewsProductWidget documents */
  delete?: InputMaybe<Array<ReviewsProductWidgetWhereUniqueInput>>
  /** Disconnect multiple ReviewsProductWidget documents */
  disconnect?: InputMaybe<Array<ReviewsProductWidgetWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ReviewsProductWidget documents */
  set?: InputMaybe<Array<ReviewsProductWidgetWhereUniqueInput>>
  /** Update multiple ReviewsProductWidget documents */
  update?: InputMaybe<Array<ReviewsProductWidgetUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ReviewsProductWidget documents */
  upsert?: InputMaybe<Array<ReviewsProductWidgetUpsertWithNestedWhereUniqueInput>>
}

export type ReviewsProductWidgetUpdateManyInput = {
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<ReviewsProductWidgetUpdateManyLocalizationsInput>
  noMinimum?: InputMaybe<Scalars['Boolean']['input']>
  /** subtext input for default locale (de) */
  subtext?: InputMaybe<Scalars['String']['input']>
  widgetType?: InputMaybe<ProductWidgetEnum>
}

export type ReviewsProductWidgetUpdateManyLocalizationDataInput = {
  headline?: InputMaybe<Scalars['String']['input']>
  subtext?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsProductWidgetUpdateManyLocalizationInput = {
  data: ReviewsProductWidgetUpdateManyLocalizationDataInput
  locale: Locale
}

export type ReviewsProductWidgetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ReviewsProductWidgetUpdateManyLocalizationInput>>
}

export type ReviewsProductWidgetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ReviewsProductWidgetUpdateManyInput
  /** Document search */
  where: ReviewsProductWidgetWhereInput
}

export type ReviewsProductWidgetUpdateOneInlineInput = {
  /** Connect existing ReviewsProductWidget document */
  connect?: InputMaybe<ReviewsProductWidgetWhereUniqueInput>
  /** Create and connect one ReviewsProductWidget document */
  create?: InputMaybe<ReviewsProductWidgetCreateInput>
  /** Delete currently connected ReviewsProductWidget document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ReviewsProductWidget document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ReviewsProductWidget document */
  update?: InputMaybe<ReviewsProductWidgetUpdateWithNestedWhereUniqueInput>
  /** Upsert single ReviewsProductWidget document */
  upsert?: InputMaybe<ReviewsProductWidgetUpsertWithNestedWhereUniqueInput>
}

export type ReviewsProductWidgetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ReviewsProductWidgetUpdateInput
  /** Unique document search */
  where: ReviewsProductWidgetWhereUniqueInput
}

export type ReviewsProductWidgetUpsertInput = {
  /** Create document if it didn't exist */
  create: ReviewsProductWidgetCreateInput
  /** Update document if it exists */
  update: ReviewsProductWidgetUpdateInput
}

export type ReviewsProductWidgetUpsertLocalizationInput = {
  create: ReviewsProductWidgetCreateLocalizationDataInput
  locale: Locale
  update: ReviewsProductWidgetUpdateLocalizationDataInput
}

export type ReviewsProductWidgetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ReviewsProductWidgetUpsertInput
  /** Unique document search */
  where: ReviewsProductWidgetWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type ReviewsProductWidgetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ReviewsProductWidgetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWidgetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  documentInStages_none?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  documentInStages_some?: InputMaybe<ReviewsProductWidgetWhereStageInput>
  headline?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  headline_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  headline_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  headline_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  headline_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  headline_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  headline_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  headline_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  noMinimum?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noMinimum_not?: InputMaybe<Scalars['Boolean']['input']>
  productCategory?: InputMaybe<ReviewsProductCategoryWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  subtext?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtext_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtext_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtext_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtext_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtext_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtext_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtext_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtext_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtext_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  widgetType?: InputMaybe<ProductWidgetEnum>
  /** All values that are contained in given list. */
  widgetType_in?: InputMaybe<Array<InputMaybe<ProductWidgetEnum>>>
  /** Any other value that exists and is not equal to the given value. */
  widgetType_not?: InputMaybe<ProductWidgetEnum>
  /** All values that are not contained in given list. */
  widgetType_not_in?: InputMaybe<Array<InputMaybe<ProductWidgetEnum>>>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ReviewsProductWidgetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ReviewsProductWidgetWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ReviewsProductWidgetWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ReviewsProductWidgetWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ReviewsProductWidgetWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References ReviewsProductWidget record uniquely */
export type ReviewsProductWidgetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type RichContent = Entity & {
  __typename?: 'RichContent'
  content: RichText
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<RichContent>
  /** System stage field */
  stage: Stage
}

export type RichContentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type RichContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: RichContentWhereUniqueInput
}

/** A connection to a list of items. */
export type RichContentConnection = {
  __typename?: 'RichContentConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<RichContentEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type RichContentCreateInput = {
  /** content input for default locale (de) */
  content: Scalars['RichTextAST']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RichContentCreateLocalizationsInput>
}

export type RichContentCreateLocalizationDataInput = {
  content: Scalars['RichTextAST']['input']
}

export type RichContentCreateLocalizationInput = {
  /** Localization input */
  data: RichContentCreateLocalizationDataInput
  locale: Locale
}

export type RichContentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RichContentCreateLocalizationInput>>
}

export type RichContentCreateManyInlineInput = {
  /** Create and connect multiple existing RichContent documents */
  create?: InputMaybe<Array<RichContentCreateInput>>
}

export type RichContentCreateOneInlineInput = {
  /** Create and connect one RichContent document */
  create?: InputMaybe<RichContentCreateInput>
}

export type RichContentCreateWithPositionInput = {
  /** Document to create */
  data: RichContentCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type RichContentEdge = {
  __typename?: 'RichContentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: RichContent
}

/** Identifies documents */
export type RichContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum RichContentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type RichContentParent = LandingPage | TopicPageSection

export type RichContentParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
  TopicPageSection?: InputMaybe<TopicPageSectionConnectInput>
}

export type RichContentParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
  TopicPageSection?: InputMaybe<TopicPageSectionCreateInput>
}

export type RichContentParentCreateManyInlineInput = {
  /** Connect multiple existing RichContentParent documents */
  connect?: InputMaybe<Array<RichContentParentWhereUniqueInput>>
  /** Create and connect multiple existing RichContentParent documents */
  create?: InputMaybe<Array<RichContentParentCreateInput>>
}

export type RichContentParentCreateOneInlineInput = {
  /** Connect one existing RichContentParent document */
  connect?: InputMaybe<RichContentParentWhereUniqueInput>
  /** Create and connect one RichContentParent document */
  create?: InputMaybe<RichContentParentCreateInput>
}

export type RichContentParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateInput>
}

export type RichContentParentUpdateManyInlineInput = {
  /** Connect multiple existing RichContentParent documents */
  connect?: InputMaybe<Array<RichContentParentConnectInput>>
  /** Create and connect multiple RichContentParent documents */
  create?: InputMaybe<Array<RichContentParentCreateInput>>
  /** Delete multiple RichContentParent documents */
  delete?: InputMaybe<Array<RichContentParentWhereUniqueInput>>
  /** Disconnect multiple RichContentParent documents */
  disconnect?: InputMaybe<Array<RichContentParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing RichContentParent documents */
  set?: InputMaybe<Array<RichContentParentWhereUniqueInput>>
  /** Update multiple RichContentParent documents */
  update?: InputMaybe<Array<RichContentParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple RichContentParent documents */
  upsert?: InputMaybe<Array<RichContentParentUpsertWithNestedWhereUniqueInput>>
}

export type RichContentParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateManyWithNestedWhereInput>
}

export type RichContentParentUpdateOneInlineInput = {
  /** Connect existing RichContentParent document */
  connect?: InputMaybe<RichContentParentWhereUniqueInput>
  /** Create and connect one RichContentParent document */
  create?: InputMaybe<RichContentParentCreateInput>
  /** Delete currently connected RichContentParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected RichContentParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RichContentParent document */
  update?: InputMaybe<RichContentParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single RichContentParent document */
  upsert?: InputMaybe<RichContentParentUpsertWithNestedWhereUniqueInput>
}

export type RichContentParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
}

export type RichContentParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type RichContentParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
  TopicPageSection?: InputMaybe<TopicPageSectionWhereInput>
}

export type RichContentParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
  TopicPageSection?: InputMaybe<TopicPageSectionWhereUniqueInput>
}

export type RichContentUpdateInput = {
  /** content input for default locale (de) */
  content?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<RichContentUpdateLocalizationsInput>
}

export type RichContentUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichContentUpdateLocalizationInput = {
  data: RichContentUpdateLocalizationDataInput
  locale: Locale
}

export type RichContentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RichContentCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<RichContentUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<RichContentUpsertLocalizationInput>>
}

export type RichContentUpdateManyInlineInput = {
  /** Create and connect multiple RichContent component instances */
  create?: InputMaybe<Array<RichContentCreateWithPositionInput>>
  /** Delete multiple RichContent documents */
  delete?: InputMaybe<Array<RichContentWhereUniqueInput>>
  /** Update multiple RichContent component instances */
  update?: InputMaybe<Array<RichContentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple RichContent component instances */
  upsert?: InputMaybe<Array<RichContentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type RichContentUpdateManyInput = {
  /** content input for default locale (de) */
  content?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<RichContentUpdateManyLocalizationsInput>
}

export type RichContentUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichContentUpdateManyLocalizationInput = {
  data: RichContentUpdateManyLocalizationDataInput
  locale: Locale
}

export type RichContentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RichContentUpdateManyLocalizationInput>>
}

export type RichContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RichContentUpdateManyInput
  /** Document search */
  where: RichContentWhereInput
}

export type RichContentUpdateOneInlineInput = {
  /** Create and connect one RichContent document */
  create?: InputMaybe<RichContentCreateInput>
  /** Delete currently connected RichContent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RichContent document */
  update?: InputMaybe<RichContentUpdateWithNestedWhereUniqueInput>
  /** Upsert single RichContent document */
  upsert?: InputMaybe<RichContentUpsertWithNestedWhereUniqueInput>
}

export type RichContentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<RichContentUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: RichContentWhereUniqueInput
}

export type RichContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RichContentUpdateInput
  /** Unique document search */
  where: RichContentWhereUniqueInput
}

export type RichContentUpsertInput = {
  /** Create document if it didn't exist */
  create: RichContentCreateInput
  /** Update document if it exists */
  update: RichContentUpdateInput
}

export type RichContentUpsertLocalizationInput = {
  create: RichContentCreateLocalizationDataInput
  locale: Locale
  update: RichContentUpdateLocalizationDataInput
}

export type RichContentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<RichContentUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: RichContentWhereUniqueInput
}

export type RichContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RichContentUpsertInput
  /** Unique document search */
  where: RichContentWhereUniqueInput
}

/** Identifies documents */
export type RichContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References RichContent record uniquely */
export type RichContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText'
  /** Returns HTMl representation */
  html: Scalars['String']['output']
  /** Returns Markdown representation */
  markdown: Scalars['String']['output']
  /** Returns AST representation */
  raw: Scalars['RichTextAST']['output']
  /** Returns plain-text contents of RichText */
  text: Scalars['String']['output']
}

/** Section component for richt text with media. */
export type RichTextMedia = Entity & {
  __typename?: 'RichTextMedia'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<RichTextMedia>
  media: Asset
  mediaPosition: RichTextMediaPosition
  /** System stage field */
  stage: Stage
  /** Text to be displayed */
  text: RichText
}

/** Section component for richt text with media. */
export type RichTextMediaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section component for richt text with media. */
export type RichTextMediaMediaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type RichTextMediaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: RichTextMediaWhereUniqueInput
}

/** A connection to a list of items. */
export type RichTextMediaConnection = {
  __typename?: 'RichTextMediaConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<RichTextMediaEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type RichTextMediaCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RichTextMediaCreateLocalizationsInput>
  media: AssetCreateOneInlineInput
  mediaPosition: RichTextMediaPosition
  /** text input for default locale (de) */
  text: Scalars['RichTextAST']['input']
}

export type RichTextMediaCreateLocalizationDataInput = {
  text: Scalars['RichTextAST']['input']
}

export type RichTextMediaCreateLocalizationInput = {
  /** Localization input */
  data: RichTextMediaCreateLocalizationDataInput
  locale: Locale
}

export type RichTextMediaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RichTextMediaCreateLocalizationInput>>
}

export type RichTextMediaCreateManyInlineInput = {
  /** Create and connect multiple existing RichTextMedia documents */
  create?: InputMaybe<Array<RichTextMediaCreateInput>>
}

export type RichTextMediaCreateOneInlineInput = {
  /** Create and connect one RichTextMedia document */
  create?: InputMaybe<RichTextMediaCreateInput>
}

export type RichTextMediaCreateWithPositionInput = {
  /** Document to create */
  data: RichTextMediaCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type RichTextMediaEdge = {
  __typename?: 'RichTextMediaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: RichTextMedia
}

/** Identifies documents */
export type RichTextMediaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  media?: InputMaybe<AssetWhereInput>
  mediaPosition?: InputMaybe<RichTextMediaPosition>
  /** All values that are contained in given list. */
  mediaPosition_in?: InputMaybe<Array<InputMaybe<RichTextMediaPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  mediaPosition_not?: InputMaybe<RichTextMediaPosition>
  /** All values that are not contained in given list. */
  mediaPosition_not_in?: InputMaybe<Array<InputMaybe<RichTextMediaPosition>>>
}

export enum RichTextMediaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MediaPositionAsc = 'mediaPosition_ASC',
  MediaPositionDesc = 'mediaPosition_DESC',
}

export type RichTextMediaParent = TopicPageSection

export type RichTextMediaParentConnectInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionConnectInput>
}

export type RichTextMediaParentCreateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateInput>
}

export type RichTextMediaParentCreateManyInlineInput = {
  /** Create and connect multiple existing RichTextMediaParent documents */
  create?: InputMaybe<Array<RichTextMediaParentCreateInput>>
}

export type RichTextMediaParentCreateOneInlineInput = {
  /** Create and connect one RichTextMediaParent document */
  create?: InputMaybe<RichTextMediaParentCreateInput>
}

export type RichTextMediaParentCreateWithPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateWithPositionInput>
}

export type RichTextMediaParentUpdateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateInput>
}

export type RichTextMediaParentUpdateManyInlineInput = {
  /** Create and connect multiple RichTextMediaParent component instances */
  create?: InputMaybe<Array<RichTextMediaParentCreateWithPositionInput>>
  /** Delete multiple RichTextMediaParent documents */
  delete?: InputMaybe<Array<RichTextMediaParentWhereUniqueInput>>
  /** Update multiple RichTextMediaParent component instances */
  update?: InputMaybe<Array<RichTextMediaParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple RichTextMediaParent component instances */
  upsert?: InputMaybe<Array<RichTextMediaParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type RichTextMediaParentUpdateManyWithNestedWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateManyWithNestedWhereInput>
}

export type RichTextMediaParentUpdateOneInlineInput = {
  /** Create and connect one RichTextMediaParent document */
  create?: InputMaybe<RichTextMediaParentCreateInput>
  /** Delete currently connected RichTextMediaParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RichTextMediaParent document */
  update?: InputMaybe<RichTextMediaParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single RichTextMediaParent document */
  upsert?: InputMaybe<RichTextMediaParentUpsertWithNestedWhereUniqueInput>
}

export type RichTextMediaParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type RichTextMediaParentUpdateWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
}

export type RichTextMediaParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type RichTextMediaParentUpsertWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type RichTextMediaParentWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereInput>
}

export type RichTextMediaParentWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereUniqueInput>
}

export enum RichTextMediaPosition {
  Left = 'left',
  Right = 'right',
}

export type RichTextMediaUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<RichTextMediaUpdateLocalizationsInput>
  media?: InputMaybe<AssetUpdateOneInlineInput>
  mediaPosition?: InputMaybe<RichTextMediaPosition>
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichTextMediaUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichTextMediaUpdateLocalizationInput = {
  data: RichTextMediaUpdateLocalizationDataInput
  locale: Locale
}

export type RichTextMediaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RichTextMediaCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<RichTextMediaUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<RichTextMediaUpsertLocalizationInput>>
}

export type RichTextMediaUpdateManyInlineInput = {
  /** Create and connect multiple RichTextMedia component instances */
  create?: InputMaybe<Array<RichTextMediaCreateWithPositionInput>>
  /** Delete multiple RichTextMedia documents */
  delete?: InputMaybe<Array<RichTextMediaWhereUniqueInput>>
  /** Update multiple RichTextMedia component instances */
  update?: InputMaybe<Array<RichTextMediaUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple RichTextMedia component instances */
  upsert?: InputMaybe<Array<RichTextMediaUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type RichTextMediaUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<RichTextMediaUpdateManyLocalizationsInput>
  mediaPosition?: InputMaybe<RichTextMediaPosition>
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichTextMediaUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type RichTextMediaUpdateManyLocalizationInput = {
  data: RichTextMediaUpdateManyLocalizationDataInput
  locale: Locale
}

export type RichTextMediaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RichTextMediaUpdateManyLocalizationInput>>
}

export type RichTextMediaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RichTextMediaUpdateManyInput
  /** Document search */
  where: RichTextMediaWhereInput
}

export type RichTextMediaUpdateOneInlineInput = {
  /** Create and connect one RichTextMedia document */
  create?: InputMaybe<RichTextMediaCreateInput>
  /** Delete currently connected RichTextMedia document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single RichTextMedia document */
  update?: InputMaybe<RichTextMediaUpdateWithNestedWhereUniqueInput>
  /** Upsert single RichTextMedia document */
  upsert?: InputMaybe<RichTextMediaUpsertWithNestedWhereUniqueInput>
}

export type RichTextMediaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<RichTextMediaUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: RichTextMediaWhereUniqueInput
}

export type RichTextMediaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RichTextMediaUpdateInput
  /** Unique document search */
  where: RichTextMediaWhereUniqueInput
}

export type RichTextMediaUpsertInput = {
  /** Create document if it didn't exist */
  create: RichTextMediaCreateInput
  /** Update document if it exists */
  update: RichTextMediaUpdateInput
}

export type RichTextMediaUpsertLocalizationInput = {
  create: RichTextMediaCreateLocalizationDataInput
  locale: Locale
  update: RichTextMediaUpdateLocalizationDataInput
}

export type RichTextMediaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<RichTextMediaUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: RichTextMediaWhereUniqueInput
}

export type RichTextMediaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RichTextMediaUpsertInput
  /** Unique document search */
  where: RichTextMediaWhereUniqueInput
}

/** Identifies documents */
export type RichTextMediaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextMediaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  media?: InputMaybe<AssetWhereInput>
  mediaPosition?: InputMaybe<RichTextMediaPosition>
  /** All values that are contained in given list. */
  mediaPosition_in?: InputMaybe<Array<InputMaybe<RichTextMediaPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  mediaPosition_not?: InputMaybe<RichTextMediaPosition>
  /** All values that are not contained in given list. */
  mediaPosition_not_in?: InputMaybe<Array<InputMaybe<RichTextMediaPosition>>>
}

/** References RichTextMedia record uniquely */
export type RichTextMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Scheduled Operation system model */
export type ScheduledOperation = Entity &
  Node & {
    __typename?: 'ScheduledOperation'
    affectedDocuments: Array<ScheduledOperationAffectedDocument>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Operation description */
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ScheduledOperation>
    /** Operation error message */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    /** Raw operation payload including all details, this field is subject to change */
    rawPayload: Scalars['Json']['output']
    /** The release this operation is scheduled for */
    release?: Maybe<ScheduledRelease>
    /** System stage field */
    stage: Stage
    /** operation Status */
    status: ScheduledOperationStatus
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ScheduledOperationAffectedDocument =
  | AnimatedCounter
  | Article
  | ArticleCategory
  | ArticlePageView
  | Asset
  | Author
  | Booth
  | ContentReference
  | EducationReport
  | EducationSeminar
  | Event
  | EventAppPage
  | EventPage
  | EventStage
  | ExhibitorListOrder
  | FaqQuestion
  | Footer
  | Glossary
  | Header
  | Hotel
  | HtmlEmbed
  | HubPage
  | ImageGalleryEmbed
  | Interest
  | JobListing
  | LandingPage
  | ManageContentConfig
  | MapMarker
  | MapMarkerIcon
  | NewsletterAudience
  | Notification
  | OmrPartner
  | PartnerCompany
  | PhysicalLocation
  | PodcastEpisode
  | PodcastGroup
  | RecommendationFood
  | RecommendationNightOut
  | ReviewsAgency
  | ReviewsProduct
  | ReviewsProductCategory
  | ReviewsProductWidget
  | SocialEmbed
  | Speaker
  | SpeakerListOrder
  | TabContent
  | Tag
  | TopicPage
  | TopicPageCategory
  | VgWort

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput
}

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>
}

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>
}

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ScheduledOperation
}

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>
  release?: InputMaybe<ScheduledReleaseWhereInput>
  status?: InputMaybe<ScheduledOperationStatus>
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>
}

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']['input']>
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']['input']>
  release?: InputMaybe<ScheduledReleaseWhereInput>
  status?: InputMaybe<ScheduledOperationStatus>
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Scheduled Release system model */
export type ScheduledRelease = Entity &
  Node & {
    __typename?: 'ScheduledRelease'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Release description */
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<ScheduledRelease>
    /** Release error message */
    errorMessage?: Maybe<Scalars['String']['output']>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Whether scheduled release should be run */
    isActive: Scalars['Boolean']['output']
    /** Whether scheduled release is implicit */
    isImplicit: Scalars['Boolean']['output']
    /** Operations to run with this release */
    operations: Array<ScheduledOperation>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    /** Release date and time */
    releaseAt?: Maybe<Scalars['DateTime']['output']>
    /** System stage field */
    stage: Stage
    /** Release Status */
    status: ScheduledReleaseStatus
    /** Release Title */
    title?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput
}

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>
}

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>
}

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: ScheduledRelease
}

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>
  isImplicit?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>
  operations_every?: InputMaybe<ScheduledOperationWhereInput>
  operations_none?: InputMaybe<ScheduledOperationWhereInput>
  operations_some?: InputMaybe<ScheduledOperationWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  status?: InputMaybe<ScheduledReleaseStatus>
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>
}

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput
  /** Document search */
  where: ScheduledReleaseWhereInput
}

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>
}

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput
}

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput
}

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput
}

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>
  isImplicit?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']['input']>
  operations_every?: InputMaybe<ScheduledOperationWhereInput>
  operations_none?: InputMaybe<ScheduledOperationWhereInput>
  operations_some?: InputMaybe<ScheduledOperationWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  releaseAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  status?: InputMaybe<ScheduledReleaseStatus>
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionAd = Entity & {
  __typename?: 'SectionAd'
  banner: Asset
  bannerMobile: Asset
  /** The unique identifier */
  id: Scalars['ID']['output']
  link?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
}

export type SectionAdBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionAdBannerMobileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionAdConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionAdWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionAdConnection = {
  __typename?: 'SectionAdConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionAdEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionAdCreateInput = {
  banner: AssetCreateOneInlineInput
  bannerMobile: AssetCreateOneInlineInput
  link?: InputMaybe<Scalars['String']['input']>
}

export type SectionAdCreateManyInlineInput = {
  /** Create and connect multiple existing SectionAd documents */
  create?: InputMaybe<Array<SectionAdCreateInput>>
}

export type SectionAdCreateOneInlineInput = {
  /** Create and connect one SectionAd document */
  create?: InputMaybe<SectionAdCreateInput>
}

export type SectionAdCreateWithPositionInput = {
  /** Document to create */
  data: SectionAdCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionAdEdge = {
  __typename?: 'SectionAdEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionAd
}

/** Identifies documents */
export type SectionAdManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAdWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAdWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAdWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  banner?: InputMaybe<AssetWhereInput>
  bannerMobile?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SectionAdOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
}

export type SectionAdParent = LandingPage

export type SectionAdParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionAdParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionAdParentCreateManyInlineInput = {
  /** Connect multiple existing SectionAdParent documents */
  connect?: InputMaybe<Array<SectionAdParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionAdParent documents */
  create?: InputMaybe<Array<SectionAdParentCreateInput>>
}

export type SectionAdParentCreateOneInlineInput = {
  /** Connect one existing SectionAdParent document */
  connect?: InputMaybe<SectionAdParentWhereUniqueInput>
  /** Create and connect one SectionAdParent document */
  create?: InputMaybe<SectionAdParentCreateInput>
}

export type SectionAdParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionAdParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionAdParent documents */
  connect?: InputMaybe<Array<SectionAdParentConnectInput>>
  /** Create and connect multiple SectionAdParent documents */
  create?: InputMaybe<Array<SectionAdParentCreateInput>>
  /** Delete multiple SectionAdParent documents */
  delete?: InputMaybe<Array<SectionAdParentWhereUniqueInput>>
  /** Disconnect multiple SectionAdParent documents */
  disconnect?: InputMaybe<Array<SectionAdParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionAdParent documents */
  set?: InputMaybe<Array<SectionAdParentWhereUniqueInput>>
  /** Update multiple SectionAdParent documents */
  update?: InputMaybe<Array<SectionAdParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionAdParent documents */
  upsert?: InputMaybe<Array<SectionAdParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionAdParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionAdParentUpdateOneInlineInput = {
  /** Connect existing SectionAdParent document */
  connect?: InputMaybe<SectionAdParentWhereUniqueInput>
  /** Create and connect one SectionAdParent document */
  create?: InputMaybe<SectionAdParentCreateInput>
  /** Delete currently connected SectionAdParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionAdParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAdParent document */
  update?: InputMaybe<SectionAdParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAdParent document */
  upsert?: InputMaybe<SectionAdParentUpsertWithNestedWhereUniqueInput>
}

export type SectionAdParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionAdParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionAdParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionAdParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionAdUpdateInput = {
  banner?: InputMaybe<AssetUpdateOneInlineInput>
  bannerMobile?: InputMaybe<AssetUpdateOneInlineInput>
  link?: InputMaybe<Scalars['String']['input']>
}

export type SectionAdUpdateManyInlineInput = {
  /** Create and connect multiple SectionAd component instances */
  create?: InputMaybe<Array<SectionAdCreateWithPositionInput>>
  /** Delete multiple SectionAd documents */
  delete?: InputMaybe<Array<SectionAdWhereUniqueInput>>
  /** Update multiple SectionAd component instances */
  update?: InputMaybe<Array<SectionAdUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionAd component instances */
  upsert?: InputMaybe<Array<SectionAdUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionAdUpdateManyInput = {
  link?: InputMaybe<Scalars['String']['input']>
}

export type SectionAdUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionAdUpdateManyInput
  /** Document search */
  where: SectionAdWhereInput
}

export type SectionAdUpdateOneInlineInput = {
  /** Create and connect one SectionAd document */
  create?: InputMaybe<SectionAdCreateInput>
  /** Delete currently connected SectionAd document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAd document */
  update?: InputMaybe<SectionAdUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAd document */
  upsert?: InputMaybe<SectionAdUpsertWithNestedWhereUniqueInput>
}

export type SectionAdUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionAdUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAdWhereUniqueInput
}

export type SectionAdUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionAdUpdateInput
  /** Unique document search */
  where: SectionAdWhereUniqueInput
}

export type SectionAdUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionAdCreateInput
  /** Update document if it exists */
  update: SectionAdUpdateInput
}

export type SectionAdUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionAdUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAdWhereUniqueInput
}

export type SectionAdUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionAdUpsertInput
  /** Unique document search */
  where: SectionAdWhereUniqueInput
}

/** Identifies documents */
export type SectionAdWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAdWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAdWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAdWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  banner?: InputMaybe<AssetWhereInput>
  bannerMobile?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionAd record uniquely */
export type SectionAdWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Area on the page that could be linked to with an anchor */
export type SectionAnchorTarget = Entity & {
  __typename?: 'SectionAnchorTarget'
  anchorId: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

export type SectionAnchorTargetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionAnchorTargetWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionAnchorTargetConnection = {
  __typename?: 'SectionAnchorTargetConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionAnchorTargetEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionAnchorTargetCreateInput = {
  anchorId: Scalars['String']['input']
}

export type SectionAnchorTargetCreateManyInlineInput = {
  /** Create and connect multiple existing SectionAnchorTarget documents */
  create?: InputMaybe<Array<SectionAnchorTargetCreateInput>>
}

export type SectionAnchorTargetCreateOneInlineInput = {
  /** Create and connect one SectionAnchorTarget document */
  create?: InputMaybe<SectionAnchorTargetCreateInput>
}

export type SectionAnchorTargetCreateWithPositionInput = {
  /** Document to create */
  data: SectionAnchorTargetCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionAnchorTargetEdge = {
  __typename?: 'SectionAnchorTargetEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionAnchorTarget
}

/** Identifies documents */
export type SectionAnchorTargetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  anchorId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  anchorId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  anchorId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  anchorId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  anchorId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  anchorId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  anchorId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  anchorId_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionAnchorTargetOrderByInput {
  AnchorIdAsc = 'anchorId_ASC',
  AnchorIdDesc = 'anchorId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionAnchorTargetParent = LandingPage

export type SectionAnchorTargetParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionAnchorTargetParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionAnchorTargetParentCreateManyInlineInput = {
  /** Connect multiple existing SectionAnchorTargetParent documents */
  connect?: InputMaybe<Array<SectionAnchorTargetParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionAnchorTargetParent documents */
  create?: InputMaybe<Array<SectionAnchorTargetParentCreateInput>>
}

export type SectionAnchorTargetParentCreateOneInlineInput = {
  /** Connect one existing SectionAnchorTargetParent document */
  connect?: InputMaybe<SectionAnchorTargetParentWhereUniqueInput>
  /** Create and connect one SectionAnchorTargetParent document */
  create?: InputMaybe<SectionAnchorTargetParentCreateInput>
}

export type SectionAnchorTargetParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionAnchorTargetParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionAnchorTargetParent documents */
  connect?: InputMaybe<Array<SectionAnchorTargetParentConnectInput>>
  /** Create and connect multiple SectionAnchorTargetParent documents */
  create?: InputMaybe<Array<SectionAnchorTargetParentCreateInput>>
  /** Delete multiple SectionAnchorTargetParent documents */
  delete?: InputMaybe<Array<SectionAnchorTargetParentWhereUniqueInput>>
  /** Disconnect multiple SectionAnchorTargetParent documents */
  disconnect?: InputMaybe<Array<SectionAnchorTargetParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionAnchorTargetParent documents */
  set?: InputMaybe<Array<SectionAnchorTargetParentWhereUniqueInput>>
  /** Update multiple SectionAnchorTargetParent documents */
  update?: InputMaybe<Array<SectionAnchorTargetParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionAnchorTargetParent documents */
  upsert?: InputMaybe<Array<SectionAnchorTargetParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionAnchorTargetParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionAnchorTargetParentUpdateOneInlineInput = {
  /** Connect existing SectionAnchorTargetParent document */
  connect?: InputMaybe<SectionAnchorTargetParentWhereUniqueInput>
  /** Create and connect one SectionAnchorTargetParent document */
  create?: InputMaybe<SectionAnchorTargetParentCreateInput>
  /** Delete currently connected SectionAnchorTargetParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionAnchorTargetParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAnchorTargetParent document */
  update?: InputMaybe<SectionAnchorTargetParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAnchorTargetParent document */
  upsert?: InputMaybe<SectionAnchorTargetParentUpsertWithNestedWhereUniqueInput>
}

export type SectionAnchorTargetParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionAnchorTargetParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionAnchorTargetParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionAnchorTargetParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionAnchorTargetUpdateInput = {
  anchorId?: InputMaybe<Scalars['String']['input']>
}

export type SectionAnchorTargetUpdateManyInlineInput = {
  /** Create and connect multiple SectionAnchorTarget component instances */
  create?: InputMaybe<Array<SectionAnchorTargetCreateWithPositionInput>>
  /** Delete multiple SectionAnchorTarget documents */
  delete?: InputMaybe<Array<SectionAnchorTargetWhereUniqueInput>>
  /** Update multiple SectionAnchorTarget component instances */
  update?: InputMaybe<Array<SectionAnchorTargetUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionAnchorTarget component instances */
  upsert?: InputMaybe<Array<SectionAnchorTargetUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionAnchorTargetUpdateManyInput = {
  anchorId?: InputMaybe<Scalars['String']['input']>
}

export type SectionAnchorTargetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionAnchorTargetUpdateManyInput
  /** Document search */
  where: SectionAnchorTargetWhereInput
}

export type SectionAnchorTargetUpdateOneInlineInput = {
  /** Create and connect one SectionAnchorTarget document */
  create?: InputMaybe<SectionAnchorTargetCreateInput>
  /** Delete currently connected SectionAnchorTarget document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAnchorTarget document */
  update?: InputMaybe<SectionAnchorTargetUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAnchorTarget document */
  upsert?: InputMaybe<SectionAnchorTargetUpsertWithNestedWhereUniqueInput>
}

export type SectionAnchorTargetUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionAnchorTargetUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAnchorTargetWhereUniqueInput
}

export type SectionAnchorTargetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionAnchorTargetUpdateInput
  /** Unique document search */
  where: SectionAnchorTargetWhereUniqueInput
}

export type SectionAnchorTargetUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionAnchorTargetCreateInput
  /** Update document if it exists */
  update: SectionAnchorTargetUpdateInput
}

export type SectionAnchorTargetUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionAnchorTargetUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAnchorTargetWhereUniqueInput
}

export type SectionAnchorTargetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionAnchorTargetUpsertInput
  /** Unique document search */
  where: SectionAnchorTargetWhereUniqueInput
}

/** Identifies documents */
export type SectionAnchorTargetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAnchorTargetWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  anchorId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  anchorId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  anchorId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  anchorId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  anchorId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  anchorId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  anchorId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  anchorId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  anchorId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  anchorId_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionAnchorTarget record uniquely */
export type SectionAnchorTargetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Call to action for App Download */
export type SectionAppCta = Entity & {
  __typename?: 'SectionAppCta'
  appStoreButton?: Maybe<Asset>
  appStoreUrl?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionAppCta>
  playStoreButton?: Maybe<Asset>
  playStoreUrl?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

/** Call to action for App Download */
export type SectionAppCtaAppStoreButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Call to action for App Download */
export type SectionAppCtaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Call to action for App Download */
export type SectionAppCtaPlayStoreButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionAppCtaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionAppCtaWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionAppCtaConnection = {
  __typename?: 'SectionAppCtaConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionAppCtaEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionAppCtaCreateInput = {
  appStoreButton?: InputMaybe<AssetCreateOneInlineInput>
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionAppCtaCreateLocalizationsInput>
  playStoreButton?: InputMaybe<AssetCreateOneInlineInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaCreateLocalizationInput = {
  /** Localization input */
  data: SectionAppCtaCreateLocalizationDataInput
  locale: Locale
}

export type SectionAppCtaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionAppCtaCreateLocalizationInput>>
}

export type SectionAppCtaCreateManyInlineInput = {
  /** Create and connect multiple existing SectionAppCta documents */
  create?: InputMaybe<Array<SectionAppCtaCreateInput>>
}

export type SectionAppCtaCreateOneInlineInput = {
  /** Create and connect one SectionAppCta document */
  create?: InputMaybe<SectionAppCtaCreateInput>
}

export type SectionAppCtaCreateWithPositionInput = {
  /** Document to create */
  data: SectionAppCtaCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionAppCtaEdge = {
  __typename?: 'SectionAppCtaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionAppCta
}

/** Identifies documents */
export type SectionAppCtaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  appStoreButton?: InputMaybe<AssetWhereInput>
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  appStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  appStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  appStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  appStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  appStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  appStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  appStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  appStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  appStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  playStoreButton?: InputMaybe<AssetWhereInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  playStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  playStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  playStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  playStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  playStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  playStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  playStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  playStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  playStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SectionAppCtaOrderByInput {
  AppStoreUrlAsc = 'appStoreUrl_ASC',
  AppStoreUrlDesc = 'appStoreUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlayStoreUrlAsc = 'playStoreUrl_ASC',
  PlayStoreUrlDesc = 'playStoreUrl_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionAppCtaParent = EventPage

export type SectionAppCtaParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionAppCtaParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionAppCtaParentCreateManyInlineInput = {
  /** Connect multiple existing SectionAppCtaParent documents */
  connect?: InputMaybe<Array<SectionAppCtaParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionAppCtaParent documents */
  create?: InputMaybe<Array<SectionAppCtaParentCreateInput>>
}

export type SectionAppCtaParentCreateOneInlineInput = {
  /** Connect one existing SectionAppCtaParent document */
  connect?: InputMaybe<SectionAppCtaParentWhereUniqueInput>
  /** Create and connect one SectionAppCtaParent document */
  create?: InputMaybe<SectionAppCtaParentCreateInput>
}

export type SectionAppCtaParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionAppCtaParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionAppCtaParent documents */
  connect?: InputMaybe<Array<SectionAppCtaParentConnectInput>>
  /** Create and connect multiple SectionAppCtaParent documents */
  create?: InputMaybe<Array<SectionAppCtaParentCreateInput>>
  /** Delete multiple SectionAppCtaParent documents */
  delete?: InputMaybe<Array<SectionAppCtaParentWhereUniqueInput>>
  /** Disconnect multiple SectionAppCtaParent documents */
  disconnect?: InputMaybe<Array<SectionAppCtaParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionAppCtaParent documents */
  set?: InputMaybe<Array<SectionAppCtaParentWhereUniqueInput>>
  /** Update multiple SectionAppCtaParent documents */
  update?: InputMaybe<Array<SectionAppCtaParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionAppCtaParent documents */
  upsert?: InputMaybe<Array<SectionAppCtaParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionAppCtaParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionAppCtaParentUpdateOneInlineInput = {
  /** Connect existing SectionAppCtaParent document */
  connect?: InputMaybe<SectionAppCtaParentWhereUniqueInput>
  /** Create and connect one SectionAppCtaParent document */
  create?: InputMaybe<SectionAppCtaParentCreateInput>
  /** Delete currently connected SectionAppCtaParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionAppCtaParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAppCtaParent document */
  update?: InputMaybe<SectionAppCtaParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAppCtaParent document */
  upsert?: InputMaybe<SectionAppCtaParentUpsertWithNestedWhereUniqueInput>
}

export type SectionAppCtaParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionAppCtaParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionAppCtaParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionAppCtaParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionAppCtaUpdateInput = {
  appStoreButton?: InputMaybe<AssetUpdateOneInlineInput>
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionAppCtaUpdateLocalizationsInput>
  playStoreButton?: InputMaybe<AssetUpdateOneInlineInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaUpdateLocalizationInput = {
  data: SectionAppCtaUpdateLocalizationDataInput
  locale: Locale
}

export type SectionAppCtaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionAppCtaCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionAppCtaUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionAppCtaUpsertLocalizationInput>>
}

export type SectionAppCtaUpdateManyInlineInput = {
  /** Create and connect multiple SectionAppCta component instances */
  create?: InputMaybe<Array<SectionAppCtaCreateWithPositionInput>>
  /** Delete multiple SectionAppCta documents */
  delete?: InputMaybe<Array<SectionAppCtaWhereUniqueInput>>
  /** Update multiple SectionAppCta component instances */
  update?: InputMaybe<Array<SectionAppCtaUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionAppCta component instances */
  upsert?: InputMaybe<Array<SectionAppCtaUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionAppCtaUpdateManyInput = {
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionAppCtaUpdateManyLocalizationsInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionAppCtaUpdateManyLocalizationInput = {
  data: SectionAppCtaUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionAppCtaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionAppCtaUpdateManyLocalizationInput>>
}

export type SectionAppCtaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionAppCtaUpdateManyInput
  /** Document search */
  where: SectionAppCtaWhereInput
}

export type SectionAppCtaUpdateOneInlineInput = {
  /** Create and connect one SectionAppCta document */
  create?: InputMaybe<SectionAppCtaCreateInput>
  /** Delete currently connected SectionAppCta document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionAppCta document */
  update?: InputMaybe<SectionAppCtaUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionAppCta document */
  upsert?: InputMaybe<SectionAppCtaUpsertWithNestedWhereUniqueInput>
}

export type SectionAppCtaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionAppCtaUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAppCtaWhereUniqueInput
}

export type SectionAppCtaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionAppCtaUpdateInput
  /** Unique document search */
  where: SectionAppCtaWhereUniqueInput
}

export type SectionAppCtaUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionAppCtaCreateInput
  /** Update document if it exists */
  update: SectionAppCtaUpdateInput
}

export type SectionAppCtaUpsertLocalizationInput = {
  create: SectionAppCtaCreateLocalizationDataInput
  locale: Locale
  update: SectionAppCtaUpdateLocalizationDataInput
}

export type SectionAppCtaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionAppCtaUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionAppCtaWhereUniqueInput
}

export type SectionAppCtaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionAppCtaUpsertInput
  /** Unique document search */
  where: SectionAppCtaWhereUniqueInput
}

/** Identifies documents */
export type SectionAppCtaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionAppCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  appStoreButton?: InputMaybe<AssetWhereInput>
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  appStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  appStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  appStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  appStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  appStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  appStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  appStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  appStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  appStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  playStoreButton?: InputMaybe<AssetWhereInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  playStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  playStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  playStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  playStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  playStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  playStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  playStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  playStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  playStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionAppCta record uniquely */
export type SectionAppCtaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionBanner = Entity & {
  __typename?: 'SectionBanner'
  banner: Asset
  /** If this is not set, none will be shown. */
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionBanner>
  /** Logo for display over the banner */
  logo?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SectionBannerBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionBannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionBannerLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionBannerWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionBannerConnection = {
  __typename?: 'SectionBannerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionBannerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionBannerCreateInput = {
  banner: AssetCreateOneInlineInput
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionBannerCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerCreateLocalizationInput = {
  /** Localization input */
  data: SectionBannerCreateLocalizationDataInput
  locale: Locale
}

export type SectionBannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionBannerCreateLocalizationInput>>
}

export type SectionBannerCreateManyInlineInput = {
  /** Create and connect multiple existing SectionBanner documents */
  create?: InputMaybe<Array<SectionBannerCreateInput>>
}

export type SectionBannerCreateOneInlineInput = {
  /** Create and connect one SectionBanner document */
  create?: InputMaybe<SectionBannerCreateInput>
}

export type SectionBannerCreateWithPositionInput = {
  /** Document to create */
  data: SectionBannerCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionBannerEdge = {
  __typename?: 'SectionBannerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionBanner
}

/** Identifies documents */
export type SectionBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  banner?: InputMaybe<AssetWhereInput>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
}

export enum SectionBannerOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionBannerParent = EventPage | LandingPage

export type SectionBannerParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionBannerParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionBannerParentCreateManyInlineInput = {
  /** Connect multiple existing SectionBannerParent documents */
  connect?: InputMaybe<Array<SectionBannerParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionBannerParent documents */
  create?: InputMaybe<Array<SectionBannerParentCreateInput>>
}

export type SectionBannerParentCreateOneInlineInput = {
  /** Connect one existing SectionBannerParent document */
  connect?: InputMaybe<SectionBannerParentWhereUniqueInput>
  /** Create and connect one SectionBannerParent document */
  create?: InputMaybe<SectionBannerParentCreateInput>
}

export type SectionBannerParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionBannerParent documents */
  connect?: InputMaybe<Array<SectionBannerParentConnectInput>>
  /** Create and connect multiple SectionBannerParent documents */
  create?: InputMaybe<Array<SectionBannerParentCreateInput>>
  /** Delete multiple SectionBannerParent documents */
  delete?: InputMaybe<Array<SectionBannerParentWhereUniqueInput>>
  /** Disconnect multiple SectionBannerParent documents */
  disconnect?: InputMaybe<Array<SectionBannerParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionBannerParent documents */
  set?: InputMaybe<Array<SectionBannerParentWhereUniqueInput>>
  /** Update multiple SectionBannerParent documents */
  update?: InputMaybe<Array<SectionBannerParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionBannerParent documents */
  upsert?: InputMaybe<Array<SectionBannerParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionBannerParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionBannerParentUpdateOneInlineInput = {
  /** Connect existing SectionBannerParent document */
  connect?: InputMaybe<SectionBannerParentWhereUniqueInput>
  /** Create and connect one SectionBannerParent document */
  create?: InputMaybe<SectionBannerParentCreateInput>
  /** Delete currently connected SectionBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionBannerParent document */
  update?: InputMaybe<SectionBannerParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionBannerParent document */
  upsert?: InputMaybe<SectionBannerParentUpsertWithNestedWhereUniqueInput>
}

export type SectionBannerParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionBannerParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionBannerParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionBannerParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionBannerUpdateInput = {
  banner?: InputMaybe<AssetUpdateOneInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionBannerUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerUpdateLocalizationInput = {
  data: SectionBannerUpdateLocalizationDataInput
  locale: Locale
}

export type SectionBannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionBannerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionBannerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionBannerUpsertLocalizationInput>>
}

export type SectionBannerUpdateManyInlineInput = {
  /** Create and connect multiple SectionBanner component instances */
  create?: InputMaybe<Array<SectionBannerCreateWithPositionInput>>
  /** Delete multiple SectionBanner documents */
  delete?: InputMaybe<Array<SectionBannerWhereUniqueInput>>
  /** Update multiple SectionBanner component instances */
  update?: InputMaybe<Array<SectionBannerUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionBanner component instances */
  upsert?: InputMaybe<Array<SectionBannerUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionBannerUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionBannerUpdateManyLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionBannerUpdateManyLocalizationInput = {
  data: SectionBannerUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionBannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionBannerUpdateManyLocalizationInput>>
}

export type SectionBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionBannerUpdateManyInput
  /** Document search */
  where: SectionBannerWhereInput
}

export type SectionBannerUpdateOneInlineInput = {
  /** Create and connect one SectionBanner document */
  create?: InputMaybe<SectionBannerCreateInput>
  /** Delete currently connected SectionBanner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionBanner document */
  update?: InputMaybe<SectionBannerUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionBanner document */
  upsert?: InputMaybe<SectionBannerUpsertWithNestedWhereUniqueInput>
}

export type SectionBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionBannerUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionBannerWhereUniqueInput
}

export type SectionBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionBannerUpdateInput
  /** Unique document search */
  where: SectionBannerWhereUniqueInput
}

export type SectionBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionBannerCreateInput
  /** Update document if it exists */
  update: SectionBannerUpdateInput
}

export type SectionBannerUpsertLocalizationInput = {
  create: SectionBannerCreateLocalizationDataInput
  locale: Locale
  update: SectionBannerUpdateLocalizationDataInput
}

export type SectionBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionBannerUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionBannerWhereUniqueInput
}

export type SectionBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionBannerUpsertInput
  /** Unique document search */
  where: SectionBannerWhereUniqueInput
}

/** Identifies documents */
export type SectionBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionBannerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  banner?: InputMaybe<AssetWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionBanner record uniquely */
export type SectionBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section displaying items with bold numbers with the description below and optional call to action */
export type SectionByTheNumbers = Entity & {
  __typename?: 'SectionByTheNumbers'
  /** Component is designed to handle 3 counters. Addig more or less will not break the component, but design can look weird. */
  counters: Array<AnimatedCounter>
  cta?: Maybe<CallToAction>
  /** Provide length in milliseconds. If not provided, defaults to 1500ms (1.5 seconds) */
  duration?: Maybe<Scalars['Int']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  items: Array<ExplanationStep>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionByTheNumbers>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  /** Sets component color scheme. */
  styleVariant?: Maybe<ComponentStyle>
  /** Section subtitle */
  subtitle?: Maybe<Scalars['String']['output']>
  /** Section title */
  title?: Maybe<Scalars['String']['output']>
}

/** Section displaying items with bold numbers with the description below and optional call to action */
export type SectionByTheNumbersCountersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AnimatedCounterOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AnimatedCounterWhereInput>
}

/** Section displaying items with bold numbers with the description below and optional call to action */
export type SectionByTheNumbersCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section displaying items with bold numbers with the description below and optional call to action */
export type SectionByTheNumbersItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ExplanationStepOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ExplanationStepWhereInput>
}

/** Section displaying items with bold numbers with the description below and optional call to action */
export type SectionByTheNumbersLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionByTheNumbersConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionByTheNumbersWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionByTheNumbersConnection = {
  __typename?: 'SectionByTheNumbersConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionByTheNumbersEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionByTheNumbersCreateInput = {
  counters: AnimatedCounterCreateManyInlineInput
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  items?: InputMaybe<ExplanationStepCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionByTheNumbersCreateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersCreateLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersCreateLocalizationInput = {
  /** Localization input */
  data: SectionByTheNumbersCreateLocalizationDataInput
  locale: Locale
}

export type SectionByTheNumbersCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionByTheNumbersCreateLocalizationInput>>
}

export type SectionByTheNumbersCreateManyInlineInput = {
  /** Create and connect multiple existing SectionByTheNumbers documents */
  create?: InputMaybe<Array<SectionByTheNumbersCreateInput>>
}

export type SectionByTheNumbersCreateOneInlineInput = {
  /** Create and connect one SectionByTheNumbers document */
  create?: InputMaybe<SectionByTheNumbersCreateInput>
}

export type SectionByTheNumbersCreateWithPositionInput = {
  /** Document to create */
  data: SectionByTheNumbersCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionByTheNumbersEdge = {
  __typename?: 'SectionByTheNumbersEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionByTheNumbers
}

/** Identifies documents */
export type SectionByTheNumbersManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  counters_every?: InputMaybe<AnimatedCounterWhereInput>
  counters_none?: InputMaybe<AnimatedCounterWhereInput>
  counters_some?: InputMaybe<AnimatedCounterWhereInput>
  cta?: InputMaybe<CallToActionWhereInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  duration_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  duration_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  duration_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  duration_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  items_every?: InputMaybe<ExplanationStepWhereInput>
  items_none?: InputMaybe<ExplanationStepWhereInput>
  items_some?: InputMaybe<ExplanationStepWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionByTheNumbersOrderByInput {
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionByTheNumbersParent = EventPage | LandingPage

export type SectionByTheNumbersParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionByTheNumbersParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionByTheNumbersParentCreateManyInlineInput = {
  /** Connect multiple existing SectionByTheNumbersParent documents */
  connect?: InputMaybe<Array<SectionByTheNumbersParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionByTheNumbersParent documents */
  create?: InputMaybe<Array<SectionByTheNumbersParentCreateInput>>
}

export type SectionByTheNumbersParentCreateOneInlineInput = {
  /** Connect one existing SectionByTheNumbersParent document */
  connect?: InputMaybe<SectionByTheNumbersParentWhereUniqueInput>
  /** Create and connect one SectionByTheNumbersParent document */
  create?: InputMaybe<SectionByTheNumbersParentCreateInput>
}

export type SectionByTheNumbersParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionByTheNumbersParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionByTheNumbersParent documents */
  connect?: InputMaybe<Array<SectionByTheNumbersParentConnectInput>>
  /** Create and connect multiple SectionByTheNumbersParent documents */
  create?: InputMaybe<Array<SectionByTheNumbersParentCreateInput>>
  /** Delete multiple SectionByTheNumbersParent documents */
  delete?: InputMaybe<Array<SectionByTheNumbersParentWhereUniqueInput>>
  /** Disconnect multiple SectionByTheNumbersParent documents */
  disconnect?: InputMaybe<Array<SectionByTheNumbersParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionByTheNumbersParent documents */
  set?: InputMaybe<Array<SectionByTheNumbersParentWhereUniqueInput>>
  /** Update multiple SectionByTheNumbersParent documents */
  update?: InputMaybe<Array<SectionByTheNumbersParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionByTheNumbersParent documents */
  upsert?: InputMaybe<Array<SectionByTheNumbersParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionByTheNumbersParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionByTheNumbersParentUpdateOneInlineInput = {
  /** Connect existing SectionByTheNumbersParent document */
  connect?: InputMaybe<SectionByTheNumbersParentWhereUniqueInput>
  /** Create and connect one SectionByTheNumbersParent document */
  create?: InputMaybe<SectionByTheNumbersParentCreateInput>
  /** Delete currently connected SectionByTheNumbersParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionByTheNumbersParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionByTheNumbersParent document */
  update?: InputMaybe<SectionByTheNumbersParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionByTheNumbersParent document */
  upsert?: InputMaybe<SectionByTheNumbersParentUpsertWithNestedWhereUniqueInput>
}

export type SectionByTheNumbersParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionByTheNumbersParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionByTheNumbersParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionByTheNumbersParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionByTheNumbersUpdateInput = {
  counters?: InputMaybe<AnimatedCounterUpdateManyInlineInput>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  items?: InputMaybe<ExplanationStepUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionByTheNumbersUpdateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersUpdateLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersUpdateLocalizationInput = {
  data: SectionByTheNumbersUpdateLocalizationDataInput
  locale: Locale
}

export type SectionByTheNumbersUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionByTheNumbersCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionByTheNumbersUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionByTheNumbersUpsertLocalizationInput>>
}

export type SectionByTheNumbersUpdateManyInlineInput = {
  /** Create and connect multiple SectionByTheNumbers component instances */
  create?: InputMaybe<Array<SectionByTheNumbersCreateWithPositionInput>>
  /** Delete multiple SectionByTheNumbers documents */
  delete?: InputMaybe<Array<SectionByTheNumbersWhereUniqueInput>>
  /** Update multiple SectionByTheNumbers component instances */
  update?: InputMaybe<Array<SectionByTheNumbersUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionByTheNumbers component instances */
  upsert?: InputMaybe<Array<SectionByTheNumbersUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionByTheNumbersUpdateManyInput = {
  duration?: InputMaybe<Scalars['Int']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionByTheNumbersUpdateManyLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersUpdateManyLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionByTheNumbersUpdateManyLocalizationInput = {
  data: SectionByTheNumbersUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionByTheNumbersUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionByTheNumbersUpdateManyLocalizationInput>>
}

export type SectionByTheNumbersUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionByTheNumbersUpdateManyInput
  /** Document search */
  where: SectionByTheNumbersWhereInput
}

export type SectionByTheNumbersUpdateOneInlineInput = {
  /** Create and connect one SectionByTheNumbers document */
  create?: InputMaybe<SectionByTheNumbersCreateInput>
  /** Delete currently connected SectionByTheNumbers document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionByTheNumbers document */
  update?: InputMaybe<SectionByTheNumbersUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionByTheNumbers document */
  upsert?: InputMaybe<SectionByTheNumbersUpsertWithNestedWhereUniqueInput>
}

export type SectionByTheNumbersUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionByTheNumbersUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionByTheNumbersWhereUniqueInput
}

export type SectionByTheNumbersUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionByTheNumbersUpdateInput
  /** Unique document search */
  where: SectionByTheNumbersWhereUniqueInput
}

export type SectionByTheNumbersUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionByTheNumbersCreateInput
  /** Update document if it exists */
  update: SectionByTheNumbersUpdateInput
}

export type SectionByTheNumbersUpsertLocalizationInput = {
  create: SectionByTheNumbersCreateLocalizationDataInput
  locale: Locale
  update: SectionByTheNumbersUpdateLocalizationDataInput
}

export type SectionByTheNumbersUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionByTheNumbersUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionByTheNumbersWhereUniqueInput
}

export type SectionByTheNumbersUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionByTheNumbersUpsertInput
  /** Unique document search */
  where: SectionByTheNumbersWhereUniqueInput
}

/** Identifies documents */
export type SectionByTheNumbersWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionByTheNumbersWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  counters_every?: InputMaybe<AnimatedCounterWhereInput>
  counters_none?: InputMaybe<AnimatedCounterWhereInput>
  counters_some?: InputMaybe<AnimatedCounterWhereInput>
  cta?: InputMaybe<CallToActionWhereInput>
  duration?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  duration_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  duration_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  duration_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  duration_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  duration_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  items_every?: InputMaybe<ExplanationStepWhereInput>
  items_none?: InputMaybe<ExplanationStepWhereInput>
  items_some?: InputMaybe<ExplanationStepWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionByTheNumbers record uniquely */
export type SectionByTheNumbersWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionCardGroup = Entity & {
  __typename?: 'SectionCardGroup'
  allCtaLink?: Maybe<Scalars['String']['output']>
  allCtaText?: Maybe<Scalars['String']['output']>
  cards?: Maybe<SectionCardGroupcardsUnion>
  columns: Scalars['Int']['output']
  count: Scalars['Int']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCardGroup>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type SectionCardGroupCardsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionCardGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionCardGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCardGroupWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCardGroupConnection = {
  __typename?: 'SectionCardGroupConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCardGroupEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCardGroupCreateInput = {
  /** allCtaLink input for default locale (de) */
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  /** allCtaText input for default locale (de) */
  allCtaText?: InputMaybe<Scalars['String']['input']>
  cards?: InputMaybe<SectionCardGroupcardsUnionCreateOneInlineInput>
  columns: Scalars['Int']['input']
  count: Scalars['Int']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCardGroupCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionCardGroupCreateLocalizationDataInput = {
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  allCtaText?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type SectionCardGroupCreateLocalizationInput = {
  /** Localization input */
  data: SectionCardGroupCreateLocalizationDataInput
  locale: Locale
}

export type SectionCardGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCardGroupCreateLocalizationInput>>
}

export type SectionCardGroupCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCardGroup documents */
  create?: InputMaybe<Array<SectionCardGroupCreateInput>>
}

export type SectionCardGroupCreateOneInlineInput = {
  /** Create and connect one SectionCardGroup document */
  create?: InputMaybe<SectionCardGroupCreateInput>
}

export type SectionCardGroupCreateWithPositionInput = {
  /** Document to create */
  data: SectionCardGroupCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCardGroupEdge = {
  __typename?: 'SectionCardGroupEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCardGroup
}

/** Identifies documents */
export type SectionCardGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  /** All values in which the modular component is connected to the given models */
  cards?: InputMaybe<SectionCardGroupcardsUnionWhereInput>
  /** All values in which the union is empty. */
  cards_empty?: InputMaybe<Scalars['Boolean']['input']>
  columns?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  count?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  count_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  count_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  count_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  count_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  count_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  count_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  count_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionCardGroupOrderByInput {
  AllCtaLinkAsc = 'allCtaLink_ASC',
  AllCtaLinkDesc = 'allCtaLink_DESC',
  AllCtaTextAsc = 'allCtaText_ASC',
  AllCtaTextDesc = 'allCtaText_DESC',
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  CountAsc = 'count_ASC',
  CountDesc = 'count_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCardGroupParent = LandingPage

export type SectionCardGroupParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionCardGroupParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionCardGroupParentCreateManyInlineInput = {
  /** Connect multiple existing SectionCardGroupParent documents */
  connect?: InputMaybe<Array<SectionCardGroupParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionCardGroupParent documents */
  create?: InputMaybe<Array<SectionCardGroupParentCreateInput>>
}

export type SectionCardGroupParentCreateOneInlineInput = {
  /** Connect one existing SectionCardGroupParent document */
  connect?: InputMaybe<SectionCardGroupParentWhereUniqueInput>
  /** Create and connect one SectionCardGroupParent document */
  create?: InputMaybe<SectionCardGroupParentCreateInput>
}

export type SectionCardGroupParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionCardGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionCardGroupParent documents */
  connect?: InputMaybe<Array<SectionCardGroupParentConnectInput>>
  /** Create and connect multiple SectionCardGroupParent documents */
  create?: InputMaybe<Array<SectionCardGroupParentCreateInput>>
  /** Delete multiple SectionCardGroupParent documents */
  delete?: InputMaybe<Array<SectionCardGroupParentWhereUniqueInput>>
  /** Disconnect multiple SectionCardGroupParent documents */
  disconnect?: InputMaybe<Array<SectionCardGroupParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionCardGroupParent documents */
  set?: InputMaybe<Array<SectionCardGroupParentWhereUniqueInput>>
  /** Update multiple SectionCardGroupParent documents */
  update?: InputMaybe<Array<SectionCardGroupParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionCardGroupParent documents */
  upsert?: InputMaybe<Array<SectionCardGroupParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionCardGroupParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionCardGroupParentUpdateOneInlineInput = {
  /** Connect existing SectionCardGroupParent document */
  connect?: InputMaybe<SectionCardGroupParentWhereUniqueInput>
  /** Create and connect one SectionCardGroupParent document */
  create?: InputMaybe<SectionCardGroupParentCreateInput>
  /** Delete currently connected SectionCardGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionCardGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCardGroupParent document */
  update?: InputMaybe<SectionCardGroupParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCardGroupParent document */
  upsert?: InputMaybe<SectionCardGroupParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCardGroupParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionCardGroupParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionCardGroupParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionCardGroupParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionCardGroupUpdateInput = {
  /** allCtaLink input for default locale (de) */
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  /** allCtaText input for default locale (de) */
  allCtaText?: InputMaybe<Scalars['String']['input']>
  cards?: InputMaybe<SectionCardGroupcardsUnionUpdateOneInlineInput>
  columns?: InputMaybe<Scalars['Int']['input']>
  count?: InputMaybe<Scalars['Int']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCardGroupUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCardGroupUpdateLocalizationDataInput = {
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  allCtaText?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCardGroupUpdateLocalizationInput = {
  data: SectionCardGroupUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCardGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCardGroupCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCardGroupUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCardGroupUpsertLocalizationInput>>
}

export type SectionCardGroupUpdateManyInlineInput = {
  /** Create and connect multiple SectionCardGroup component instances */
  create?: InputMaybe<Array<SectionCardGroupCreateWithPositionInput>>
  /** Delete multiple SectionCardGroup documents */
  delete?: InputMaybe<Array<SectionCardGroupWhereUniqueInput>>
  /** Update multiple SectionCardGroup component instances */
  update?: InputMaybe<Array<SectionCardGroupUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCardGroup component instances */
  upsert?: InputMaybe<Array<SectionCardGroupUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCardGroupUpdateManyInput = {
  /** allCtaLink input for default locale (de) */
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  /** allCtaText input for default locale (de) */
  allCtaText?: InputMaybe<Scalars['String']['input']>
  columns?: InputMaybe<Scalars['Int']['input']>
  count?: InputMaybe<Scalars['Int']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCardGroupUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCardGroupUpdateManyLocalizationDataInput = {
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  allCtaText?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCardGroupUpdateManyLocalizationInput = {
  data: SectionCardGroupUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCardGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCardGroupUpdateManyLocalizationInput>>
}

export type SectionCardGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCardGroupUpdateManyInput
  /** Document search */
  where: SectionCardGroupWhereInput
}

export type SectionCardGroupUpdateOneInlineInput = {
  /** Create and connect one SectionCardGroup document */
  create?: InputMaybe<SectionCardGroupCreateInput>
  /** Delete currently connected SectionCardGroup document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCardGroup document */
  update?: InputMaybe<SectionCardGroupUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCardGroup document */
  upsert?: InputMaybe<SectionCardGroupUpsertWithNestedWhereUniqueInput>
}

export type SectionCardGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCardGroupUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCardGroupWhereUniqueInput
}

export type SectionCardGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCardGroupUpdateInput
  /** Unique document search */
  where: SectionCardGroupWhereUniqueInput
}

export type SectionCardGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCardGroupCreateInput
  /** Update document if it exists */
  update: SectionCardGroupUpdateInput
}

export type SectionCardGroupUpsertLocalizationInput = {
  create: SectionCardGroupCreateLocalizationDataInput
  locale: Locale
  update: SectionCardGroupUpdateLocalizationDataInput
}

export type SectionCardGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCardGroupUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCardGroupWhereUniqueInput
}

export type SectionCardGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCardGroupUpsertInput
  /** Unique document search */
  where: SectionCardGroupWhereUniqueInput
}

/** Identifies documents */
export type SectionCardGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCardGroupWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  allCtaLink?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  allCtaLink_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  allCtaLink_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  allCtaLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  allCtaLink_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  allCtaLink_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  allCtaLink_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  allCtaLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  allCtaLink_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  allCtaLink_starts_with?: InputMaybe<Scalars['String']['input']>
  allCtaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  allCtaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  allCtaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  allCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  allCtaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  allCtaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  allCtaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  allCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  allCtaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  allCtaText_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values in which the modular component is connected to the given models */
  cards?: InputMaybe<SectionCardGroupcardsUnionWhereInput>
  /** All values in which the union is empty. */
  cards_empty?: InputMaybe<Scalars['Boolean']['input']>
  columns?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  count?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  count_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  count_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  count_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  count_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  count_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  count_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  count_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCardGroup record uniquely */
export type SectionCardGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionCardGroupcardsUnion = JobsCard

export type SectionCardGroupcardsUnionConnectInput = {
  JobsCard?: InputMaybe<JobsCardConnectInput>
}

export type SectionCardGroupcardsUnionCreateInput = {
  JobsCard?: InputMaybe<JobsCardCreateInput>
}

export type SectionCardGroupcardsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCardGroupcardsUnion documents */
  create?: InputMaybe<Array<SectionCardGroupcardsUnionCreateInput>>
}

export type SectionCardGroupcardsUnionCreateOneInlineInput = {
  /** Create and connect one SectionCardGroupcardsUnion document */
  create?: InputMaybe<SectionCardGroupcardsUnionCreateInput>
}

export type SectionCardGroupcardsUnionCreateWithPositionInput = {
  JobsCard?: InputMaybe<JobsCardCreateWithPositionInput>
}

export type SectionCardGroupcardsUnionUpdateInput = {
  JobsCard?: InputMaybe<JobsCardUpdateInput>
}

export type SectionCardGroupcardsUnionUpdateManyInlineInput = {
  /** Create and connect multiple SectionCardGroupcardsUnion component instances */
  create?: InputMaybe<Array<SectionCardGroupcardsUnionCreateWithPositionInput>>
  /** Delete multiple SectionCardGroupcardsUnion documents */
  delete?: InputMaybe<Array<SectionCardGroupcardsUnionWhereUniqueInput>>
  /** Update multiple SectionCardGroupcardsUnion component instances */
  update?: InputMaybe<Array<SectionCardGroupcardsUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCardGroupcardsUnion component instances */
  upsert?: InputMaybe<Array<SectionCardGroupcardsUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCardGroupcardsUnionUpdateManyWithNestedWhereInput = {
  JobsCard?: InputMaybe<JobsCardUpdateManyWithNestedWhereInput>
}

export type SectionCardGroupcardsUnionUpdateOneInlineInput = {
  /** Create and connect one SectionCardGroupcardsUnion document */
  create?: InputMaybe<SectionCardGroupcardsUnionCreateInput>
  /** Delete currently connected SectionCardGroupcardsUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCardGroupcardsUnion document */
  update?: InputMaybe<SectionCardGroupcardsUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCardGroupcardsUnion document */
  upsert?: InputMaybe<SectionCardGroupcardsUnionUpsertWithNestedWhereUniqueInput>
}

export type SectionCardGroupcardsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  JobsCard?: InputMaybe<JobsCardUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SectionCardGroupcardsUnionUpdateWithNestedWhereUniqueInput = {
  JobsCard?: InputMaybe<JobsCardUpdateWithNestedWhereUniqueInput>
}

export type SectionCardGroupcardsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  JobsCard?: InputMaybe<JobsCardUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SectionCardGroupcardsUnionUpsertWithNestedWhereUniqueInput = {
  JobsCard?: InputMaybe<JobsCardUpsertWithNestedWhereUniqueInput>
}

export type SectionCardGroupcardsUnionWhereInput = {
  JobsCard?: InputMaybe<JobsCardWhereInput>
}

export type SectionCardGroupcardsUnionWhereUniqueInput = {
  JobsCard?: InputMaybe<JobsCardWhereUniqueInput>
}

export type SectionCollab = Entity & {
  __typename?: 'SectionCollab'
  content?: Maybe<SectionCollabcontentUnion>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCollab>
  /** System stage field */
  stage: Stage
  teamOne: Scalars['String']['output']
  teamTwo: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type SectionCollabContentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionCollabLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionCollabConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCollabWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCollabConnection = {
  __typename?: 'SectionCollabConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCollabEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCollabCreateInput = {
  content?: InputMaybe<SectionCollabcontentUnionCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCollabCreateLocalizationsInput>
  /** teamOne input for default locale (de) */
  teamOne: Scalars['String']['input']
  /** teamTwo input for default locale (de) */
  teamTwo: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionCollabCreateLocalizationDataInput = {
  teamOne: Scalars['String']['input']
  teamTwo: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionCollabCreateLocalizationInput = {
  /** Localization input */
  data: SectionCollabCreateLocalizationDataInput
  locale: Locale
}

export type SectionCollabCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCollabCreateLocalizationInput>>
}

export type SectionCollabCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCollab documents */
  create?: InputMaybe<Array<SectionCollabCreateInput>>
}

export type SectionCollabCreateOneInlineInput = {
  /** Create and connect one SectionCollab document */
  create?: InputMaybe<SectionCollabCreateInput>
}

export type SectionCollabCreateWithPositionInput = {
  /** Document to create */
  data: SectionCollabCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCollabEdge = {
  __typename?: 'SectionCollabEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCollab
}

/** Identifies documents */
export type SectionCollabManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  /** All values in which the modular component is connected to the given models */
  content?: InputMaybe<SectionCollabcontentUnionWhereInput>
  /** All values in which the union is empty. */
  content_empty?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionCollabOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TeamOneAsc = 'teamOne_ASC',
  TeamOneDesc = 'teamOne_DESC',
  TeamTwoAsc = 'teamTwo_ASC',
  TeamTwoDesc = 'teamTwo_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCollabParent = LandingPage

export type SectionCollabParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionCollabParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionCollabParentCreateManyInlineInput = {
  /** Connect multiple existing SectionCollabParent documents */
  connect?: InputMaybe<Array<SectionCollabParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionCollabParent documents */
  create?: InputMaybe<Array<SectionCollabParentCreateInput>>
}

export type SectionCollabParentCreateOneInlineInput = {
  /** Connect one existing SectionCollabParent document */
  connect?: InputMaybe<SectionCollabParentWhereUniqueInput>
  /** Create and connect one SectionCollabParent document */
  create?: InputMaybe<SectionCollabParentCreateInput>
}

export type SectionCollabParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionCollabParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionCollabParent documents */
  connect?: InputMaybe<Array<SectionCollabParentConnectInput>>
  /** Create and connect multiple SectionCollabParent documents */
  create?: InputMaybe<Array<SectionCollabParentCreateInput>>
  /** Delete multiple SectionCollabParent documents */
  delete?: InputMaybe<Array<SectionCollabParentWhereUniqueInput>>
  /** Disconnect multiple SectionCollabParent documents */
  disconnect?: InputMaybe<Array<SectionCollabParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionCollabParent documents */
  set?: InputMaybe<Array<SectionCollabParentWhereUniqueInput>>
  /** Update multiple SectionCollabParent documents */
  update?: InputMaybe<Array<SectionCollabParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionCollabParent documents */
  upsert?: InputMaybe<Array<SectionCollabParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionCollabParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionCollabParentUpdateOneInlineInput = {
  /** Connect existing SectionCollabParent document */
  connect?: InputMaybe<SectionCollabParentWhereUniqueInput>
  /** Create and connect one SectionCollabParent document */
  create?: InputMaybe<SectionCollabParentCreateInput>
  /** Delete currently connected SectionCollabParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionCollabParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCollabParent document */
  update?: InputMaybe<SectionCollabParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCollabParent document */
  upsert?: InputMaybe<SectionCollabParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCollabParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionCollabParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionCollabParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionCollabParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionCollabUpdateInput = {
  content?: InputMaybe<SectionCollabcontentUnionUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCollabUpdateLocalizationsInput>
  /** teamOne input for default locale (de) */
  teamOne?: InputMaybe<Scalars['String']['input']>
  /** teamTwo input for default locale (de) */
  teamTwo?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollabUpdateLocalizationDataInput = {
  teamOne?: InputMaybe<Scalars['String']['input']>
  teamTwo?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollabUpdateLocalizationInput = {
  data: SectionCollabUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCollabUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCollabCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCollabUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCollabUpsertLocalizationInput>>
}

export type SectionCollabUpdateManyInlineInput = {
  /** Create and connect multiple SectionCollab component instances */
  create?: InputMaybe<Array<SectionCollabCreateWithPositionInput>>
  /** Delete multiple SectionCollab documents */
  delete?: InputMaybe<Array<SectionCollabWhereUniqueInput>>
  /** Update multiple SectionCollab component instances */
  update?: InputMaybe<Array<SectionCollabUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCollab component instances */
  upsert?: InputMaybe<Array<SectionCollabUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCollabUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCollabUpdateManyLocalizationsInput>
  /** teamOne input for default locale (de) */
  teamOne?: InputMaybe<Scalars['String']['input']>
  /** teamTwo input for default locale (de) */
  teamTwo?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollabUpdateManyLocalizationDataInput = {
  teamOne?: InputMaybe<Scalars['String']['input']>
  teamTwo?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollabUpdateManyLocalizationInput = {
  data: SectionCollabUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCollabUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCollabUpdateManyLocalizationInput>>
}

export type SectionCollabUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCollabUpdateManyInput
  /** Document search */
  where: SectionCollabWhereInput
}

export type SectionCollabUpdateOneInlineInput = {
  /** Create and connect one SectionCollab document */
  create?: InputMaybe<SectionCollabCreateInput>
  /** Delete currently connected SectionCollab document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCollab document */
  update?: InputMaybe<SectionCollabUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCollab document */
  upsert?: InputMaybe<SectionCollabUpsertWithNestedWhereUniqueInput>
}

export type SectionCollabUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCollabUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCollabWhereUniqueInput
}

export type SectionCollabUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCollabUpdateInput
  /** Unique document search */
  where: SectionCollabWhereUniqueInput
}

export type SectionCollabUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCollabCreateInput
  /** Update document if it exists */
  update: SectionCollabUpdateInput
}

export type SectionCollabUpsertLocalizationInput = {
  create: SectionCollabCreateLocalizationDataInput
  locale: Locale
  update: SectionCollabUpdateLocalizationDataInput
}

export type SectionCollabUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCollabUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCollabWhereUniqueInput
}

export type SectionCollabUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCollabUpsertInput
  /** Unique document search */
  where: SectionCollabWhereUniqueInput
}

/** Identifies documents */
export type SectionCollabWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCollabWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  /** All values in which the modular component is connected to the given models */
  content?: InputMaybe<SectionCollabcontentUnionWhereInput>
  /** All values in which the union is empty. */
  content_empty?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  teamOne?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  teamOne_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  teamOne_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  teamOne_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  teamOne_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  teamOne_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  teamOne_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  teamOne_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  teamOne_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  teamOne_starts_with?: InputMaybe<Scalars['String']['input']>
  teamTwo?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  teamTwo_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  teamTwo_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  teamTwo_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  teamTwo_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  teamTwo_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  teamTwo_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  teamTwo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  teamTwo_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  teamTwo_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCollab record uniquely */
export type SectionCollabWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionCollabcontentUnion = EducationCard

export type SectionCollabcontentUnionConnectInput = {
  EducationCard?: InputMaybe<EducationCardConnectInput>
}

export type SectionCollabcontentUnionCreateInput = {
  EducationCard?: InputMaybe<EducationCardCreateInput>
}

export type SectionCollabcontentUnionCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCollabcontentUnion documents */
  create?: InputMaybe<Array<SectionCollabcontentUnionCreateInput>>
}

export type SectionCollabcontentUnionCreateOneInlineInput = {
  /** Create and connect one SectionCollabcontentUnion document */
  create?: InputMaybe<SectionCollabcontentUnionCreateInput>
}

export type SectionCollabcontentUnionCreateWithPositionInput = {
  EducationCard?: InputMaybe<EducationCardCreateWithPositionInput>
}

export type SectionCollabcontentUnionUpdateInput = {
  EducationCard?: InputMaybe<EducationCardUpdateInput>
}

export type SectionCollabcontentUnionUpdateManyInlineInput = {
  /** Create and connect multiple SectionCollabcontentUnion component instances */
  create?: InputMaybe<Array<SectionCollabcontentUnionCreateWithPositionInput>>
  /** Delete multiple SectionCollabcontentUnion documents */
  delete?: InputMaybe<Array<SectionCollabcontentUnionWhereUniqueInput>>
  /** Update multiple SectionCollabcontentUnion component instances */
  update?: InputMaybe<Array<SectionCollabcontentUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCollabcontentUnion component instances */
  upsert?: InputMaybe<Array<SectionCollabcontentUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCollabcontentUnionUpdateManyWithNestedWhereInput = {
  EducationCard?: InputMaybe<EducationCardUpdateManyWithNestedWhereInput>
}

export type SectionCollabcontentUnionUpdateOneInlineInput = {
  /** Create and connect one SectionCollabcontentUnion document */
  create?: InputMaybe<SectionCollabcontentUnionCreateInput>
  /** Delete currently connected SectionCollabcontentUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCollabcontentUnion document */
  update?: InputMaybe<SectionCollabcontentUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCollabcontentUnion document */
  upsert?: InputMaybe<SectionCollabcontentUnionUpsertWithNestedWhereUniqueInput>
}

export type SectionCollabcontentUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  EducationCard?: InputMaybe<EducationCardUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SectionCollabcontentUnionUpdateWithNestedWhereUniqueInput = {
  EducationCard?: InputMaybe<EducationCardUpdateWithNestedWhereUniqueInput>
}

export type SectionCollabcontentUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  EducationCard?: InputMaybe<EducationCardUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SectionCollabcontentUnionUpsertWithNestedWhereUniqueInput = {
  EducationCard?: InputMaybe<EducationCardUpsertWithNestedWhereUniqueInput>
}

export type SectionCollabcontentUnionWhereInput = {
  EducationCard?: InputMaybe<EducationCardWhereInput>
}

export type SectionCollabcontentUnionWhereUniqueInput = {
  EducationCard?: InputMaybe<EducationCardWhereUniqueInput>
}

/** Text will be collapsed and only title will be shown */
export type SectionCollapsedRichContent = Entity & {
  __typename?: 'SectionCollapsedRichContent'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCollapsedRichContent>
  /** System stage field */
  stage: Stage
  /** Text to be displayed */
  text: RichText
  /** Title of the collapsed content */
  title: Scalars['String']['output']
}

/** Text will be collapsed and only title will be shown */
export type SectionCollapsedRichContentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionCollapsedRichContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCollapsedRichContentWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCollapsedRichContentConnection = {
  __typename?: 'SectionCollapsedRichContentConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCollapsedRichContentEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCollapsedRichContentCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCollapsedRichContentCreateLocalizationsInput>
  /** text input for default locale (de) */
  text: Scalars['RichTextAST']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionCollapsedRichContentCreateLocalizationDataInput = {
  text: Scalars['RichTextAST']['input']
  title: Scalars['String']['input']
}

export type SectionCollapsedRichContentCreateLocalizationInput = {
  /** Localization input */
  data: SectionCollapsedRichContentCreateLocalizationDataInput
  locale: Locale
}

export type SectionCollapsedRichContentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCollapsedRichContentCreateLocalizationInput>>
}

export type SectionCollapsedRichContentCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCollapsedRichContent documents */
  create?: InputMaybe<Array<SectionCollapsedRichContentCreateInput>>
}

export type SectionCollapsedRichContentCreateOneInlineInput = {
  /** Create and connect one SectionCollapsedRichContent document */
  create?: InputMaybe<SectionCollapsedRichContentCreateInput>
}

export type SectionCollapsedRichContentCreateWithPositionInput = {
  /** Document to create */
  data: SectionCollapsedRichContentCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCollapsedRichContentEdge = {
  __typename?: 'SectionCollapsedRichContentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCollapsedRichContent
}

/** Identifies documents */
export type SectionCollapsedRichContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionCollapsedRichContentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCollapsedRichContentParent = TopicPageSection

export type SectionCollapsedRichContentParentConnectInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionConnectInput>
}

export type SectionCollapsedRichContentParentCreateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateInput>
}

export type SectionCollapsedRichContentParentCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCollapsedRichContentParent documents */
  create?: InputMaybe<Array<SectionCollapsedRichContentParentCreateInput>>
}

export type SectionCollapsedRichContentParentCreateOneInlineInput = {
  /** Create and connect one SectionCollapsedRichContentParent document */
  create?: InputMaybe<SectionCollapsedRichContentParentCreateInput>
}

export type SectionCollapsedRichContentParentCreateWithPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateWithPositionInput>
}

export type SectionCollapsedRichContentParentUpdateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateInput>
}

export type SectionCollapsedRichContentParentUpdateManyInlineInput = {
  /** Create and connect multiple SectionCollapsedRichContentParent component instances */
  create?: InputMaybe<Array<SectionCollapsedRichContentParentCreateWithPositionInput>>
  /** Delete multiple SectionCollapsedRichContentParent documents */
  delete?: InputMaybe<Array<SectionCollapsedRichContentParentWhereUniqueInput>>
  /** Update multiple SectionCollapsedRichContentParent component instances */
  update?: InputMaybe<Array<SectionCollapsedRichContentParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCollapsedRichContentParent component instances */
  upsert?: InputMaybe<Array<SectionCollapsedRichContentParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCollapsedRichContentParentUpdateManyWithNestedWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateManyWithNestedWhereInput>
}

export type SectionCollapsedRichContentParentUpdateOneInlineInput = {
  /** Create and connect one SectionCollapsedRichContentParent document */
  create?: InputMaybe<SectionCollapsedRichContentParentCreateInput>
  /** Delete currently connected SectionCollapsedRichContentParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCollapsedRichContentParent document */
  update?: InputMaybe<SectionCollapsedRichContentParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCollapsedRichContentParent document */
  upsert?: InputMaybe<SectionCollapsedRichContentParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCollapsedRichContentParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SectionCollapsedRichContentParentUpdateWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
}

export type SectionCollapsedRichContentParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SectionCollapsedRichContentParentUpsertWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type SectionCollapsedRichContentParentWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereInput>
}

export type SectionCollapsedRichContentParentWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereUniqueInput>
}

export type SectionCollapsedRichContentUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCollapsedRichContentUpdateLocalizationsInput>
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['RichTextAST']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollapsedRichContentUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollapsedRichContentUpdateLocalizationInput = {
  data: SectionCollapsedRichContentUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCollapsedRichContentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCollapsedRichContentCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCollapsedRichContentUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCollapsedRichContentUpsertLocalizationInput>>
}

export type SectionCollapsedRichContentUpdateManyInlineInput = {
  /** Create and connect multiple SectionCollapsedRichContent component instances */
  create?: InputMaybe<Array<SectionCollapsedRichContentCreateWithPositionInput>>
  /** Delete multiple SectionCollapsedRichContent documents */
  delete?: InputMaybe<Array<SectionCollapsedRichContentWhereUniqueInput>>
  /** Update multiple SectionCollapsedRichContent component instances */
  update?: InputMaybe<Array<SectionCollapsedRichContentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCollapsedRichContent component instances */
  upsert?: InputMaybe<Array<SectionCollapsedRichContentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCollapsedRichContentUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCollapsedRichContentUpdateManyLocalizationsInput>
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars['RichTextAST']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollapsedRichContentUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCollapsedRichContentUpdateManyLocalizationInput = {
  data: SectionCollapsedRichContentUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCollapsedRichContentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCollapsedRichContentUpdateManyLocalizationInput>>
}

export type SectionCollapsedRichContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCollapsedRichContentUpdateManyInput
  /** Document search */
  where: SectionCollapsedRichContentWhereInput
}

export type SectionCollapsedRichContentUpdateOneInlineInput = {
  /** Create and connect one SectionCollapsedRichContent document */
  create?: InputMaybe<SectionCollapsedRichContentCreateInput>
  /** Delete currently connected SectionCollapsedRichContent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCollapsedRichContent document */
  update?: InputMaybe<SectionCollapsedRichContentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCollapsedRichContent document */
  upsert?: InputMaybe<SectionCollapsedRichContentUpsertWithNestedWhereUniqueInput>
}

export type SectionCollapsedRichContentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCollapsedRichContentUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCollapsedRichContentWhereUniqueInput
}

export type SectionCollapsedRichContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCollapsedRichContentUpdateInput
  /** Unique document search */
  where: SectionCollapsedRichContentWhereUniqueInput
}

export type SectionCollapsedRichContentUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCollapsedRichContentCreateInput
  /** Update document if it exists */
  update: SectionCollapsedRichContentUpdateInput
}

export type SectionCollapsedRichContentUpsertLocalizationInput = {
  create: SectionCollapsedRichContentCreateLocalizationDataInput
  locale: Locale
  update: SectionCollapsedRichContentUpdateLocalizationDataInput
}

export type SectionCollapsedRichContentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCollapsedRichContentUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCollapsedRichContentWhereUniqueInput
}

export type SectionCollapsedRichContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCollapsedRichContentUpsertInput
  /** Unique document search */
  where: SectionCollapsedRichContentWhereUniqueInput
}

/** Identifies documents */
export type SectionCollapsedRichContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCollapsedRichContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCollapsedRichContent record uniquely */
export type SectionCollapsedRichContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionContact = Entity & {
  __typename?: 'SectionContact'
  /** Text alignment centered */
  centered?: Maybe<Scalars['Boolean']['output']>
  contact: Array<Contact>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionContact>
  /** System stage field */
  stage: Stage
  /** Sets component color scheme. */
  styleVariant?: Maybe<ComponentStyle>
  title: Scalars['String']['output']
}

export type SectionContactContactArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ContactOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ContactWhereInput>
}

export type SectionContactLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionContactConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionContactWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionContactConnection = {
  __typename?: 'SectionContactConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionContactEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionContactCreateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  contact?: InputMaybe<ContactCreateManyInlineInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionContactCreateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionContactCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type SectionContactCreateLocalizationInput = {
  /** Localization input */
  data: SectionContactCreateLocalizationDataInput
  locale: Locale
}

export type SectionContactCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionContactCreateLocalizationInput>>
}

export type SectionContactCreateManyInlineInput = {
  /** Create and connect multiple existing SectionContact documents */
  create?: InputMaybe<Array<SectionContactCreateInput>>
}

export type SectionContactCreateOneInlineInput = {
  /** Create and connect one SectionContact document */
  create?: InputMaybe<SectionContactCreateInput>
}

export type SectionContactCreateWithPositionInput = {
  /** Document to create */
  data: SectionContactCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionContactEdge = {
  __typename?: 'SectionContactEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionContact
}

/** Identifies documents */
export type SectionContactManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionContactWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionContactWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionContactWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  contact_every?: InputMaybe<ContactWhereInput>
  contact_none?: InputMaybe<ContactWhereInput>
  contact_some?: InputMaybe<ContactWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionContactOrderByInput {
  CenteredAsc = 'centered_ASC',
  CenteredDesc = 'centered_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionContactParent = EventPage | LandingPage

export type SectionContactParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionContactParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionContactParentCreateManyInlineInput = {
  /** Connect multiple existing SectionContactParent documents */
  connect?: InputMaybe<Array<SectionContactParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionContactParent documents */
  create?: InputMaybe<Array<SectionContactParentCreateInput>>
}

export type SectionContactParentCreateOneInlineInput = {
  /** Connect one existing SectionContactParent document */
  connect?: InputMaybe<SectionContactParentWhereUniqueInput>
  /** Create and connect one SectionContactParent document */
  create?: InputMaybe<SectionContactParentCreateInput>
}

export type SectionContactParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionContactParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionContactParent documents */
  connect?: InputMaybe<Array<SectionContactParentConnectInput>>
  /** Create and connect multiple SectionContactParent documents */
  create?: InputMaybe<Array<SectionContactParentCreateInput>>
  /** Delete multiple SectionContactParent documents */
  delete?: InputMaybe<Array<SectionContactParentWhereUniqueInput>>
  /** Disconnect multiple SectionContactParent documents */
  disconnect?: InputMaybe<Array<SectionContactParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionContactParent documents */
  set?: InputMaybe<Array<SectionContactParentWhereUniqueInput>>
  /** Update multiple SectionContactParent documents */
  update?: InputMaybe<Array<SectionContactParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionContactParent documents */
  upsert?: InputMaybe<Array<SectionContactParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionContactParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionContactParentUpdateOneInlineInput = {
  /** Connect existing SectionContactParent document */
  connect?: InputMaybe<SectionContactParentWhereUniqueInput>
  /** Create and connect one SectionContactParent document */
  create?: InputMaybe<SectionContactParentCreateInput>
  /** Delete currently connected SectionContactParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionContactParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionContactParent document */
  update?: InputMaybe<SectionContactParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionContactParent document */
  upsert?: InputMaybe<SectionContactParentUpsertWithNestedWhereUniqueInput>
}

export type SectionContactParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionContactParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionContactParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionContactParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionContactUpdateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  contact?: InputMaybe<ContactUpdateManyInlineInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionContactUpdateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionContactUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionContactUpdateLocalizationInput = {
  data: SectionContactUpdateLocalizationDataInput
  locale: Locale
}

export type SectionContactUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionContactCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionContactUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionContactUpsertLocalizationInput>>
}

export type SectionContactUpdateManyInlineInput = {
  /** Create and connect multiple SectionContact component instances */
  create?: InputMaybe<Array<SectionContactCreateWithPositionInput>>
  /** Delete multiple SectionContact documents */
  delete?: InputMaybe<Array<SectionContactWhereUniqueInput>>
  /** Update multiple SectionContact component instances */
  update?: InputMaybe<Array<SectionContactUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionContact component instances */
  upsert?: InputMaybe<Array<SectionContactUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionContactUpdateManyInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionContactUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionContactUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionContactUpdateManyLocalizationInput = {
  data: SectionContactUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionContactUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionContactUpdateManyLocalizationInput>>
}

export type SectionContactUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionContactUpdateManyInput
  /** Document search */
  where: SectionContactWhereInput
}

export type SectionContactUpdateOneInlineInput = {
  /** Create and connect one SectionContact document */
  create?: InputMaybe<SectionContactCreateInput>
  /** Delete currently connected SectionContact document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionContact document */
  update?: InputMaybe<SectionContactUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionContact document */
  upsert?: InputMaybe<SectionContactUpsertWithNestedWhereUniqueInput>
}

export type SectionContactUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionContactUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionContactWhereUniqueInput
}

export type SectionContactUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionContactUpdateInput
  /** Unique document search */
  where: SectionContactWhereUniqueInput
}

export type SectionContactUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionContactCreateInput
  /** Update document if it exists */
  update: SectionContactUpdateInput
}

export type SectionContactUpsertLocalizationInput = {
  create: SectionContactCreateLocalizationDataInput
  locale: Locale
  update: SectionContactUpdateLocalizationDataInput
}

export type SectionContactUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionContactUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionContactWhereUniqueInput
}

export type SectionContactUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionContactUpsertInput
  /** Unique document search */
  where: SectionContactWhereUniqueInput
}

/** Identifies documents */
export type SectionContactWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionContactWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionContactWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionContactWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  contact_every?: InputMaybe<ContactWhereInput>
  contact_none?: InputMaybe<ContactWhereInput>
  contact_some?: InputMaybe<ContactWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionContact record uniquely */
export type SectionContactWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section to reference different content items. */
export type SectionContentReference = Entity & {
  __typename?: 'SectionContentReference'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Reference to multiple content items. */
  references: Array<Union_References_Cm0qshprp123q07te06v2bu9e>
  /** System stage field */
  stage: Stage
}

/** Section to reference different content items. */
export type SectionContentReferenceReferencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SectionContentReferenceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionContentReferenceWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionContentReferenceConnection = {
  __typename?: 'SectionContentReferenceConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionContentReferenceEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionContentReferenceCreateInput = {
  references?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eCreateManyInlineInput>
}

export type SectionContentReferenceCreateManyInlineInput = {
  /** Create and connect multiple existing SectionContentReference documents */
  create?: InputMaybe<Array<SectionContentReferenceCreateInput>>
}

export type SectionContentReferenceCreateOneInlineInput = {
  /** Create and connect one SectionContentReference document */
  create?: InputMaybe<SectionContentReferenceCreateInput>
}

export type SectionContentReferenceCreateWithPositionInput = {
  /** Document to create */
  data: SectionContentReferenceCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionContentReferenceEdge = {
  __typename?: 'SectionContentReferenceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionContentReference
}

/** Identifies documents */
export type SectionContentReferenceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty */
  references_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  references_some?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eWhereInput>
}

export enum SectionContentReferenceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionContentReferenceParent = TopicPageSection

export type SectionContentReferenceParentConnectInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionConnectInput>
}

export type SectionContentReferenceParentCreateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateInput>
}

export type SectionContentReferenceParentCreateManyInlineInput = {
  /** Create and connect multiple existing SectionContentReferenceParent documents */
  create?: InputMaybe<Array<SectionContentReferenceParentCreateInput>>
}

export type SectionContentReferenceParentCreateOneInlineInput = {
  /** Create and connect one SectionContentReferenceParent document */
  create?: InputMaybe<SectionContentReferenceParentCreateInput>
}

export type SectionContentReferenceParentCreateWithPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateWithPositionInput>
}

export type SectionContentReferenceParentUpdateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateInput>
}

export type SectionContentReferenceParentUpdateManyInlineInput = {
  /** Create and connect multiple SectionContentReferenceParent component instances */
  create?: InputMaybe<Array<SectionContentReferenceParentCreateWithPositionInput>>
  /** Delete multiple SectionContentReferenceParent documents */
  delete?: InputMaybe<Array<SectionContentReferenceParentWhereUniqueInput>>
  /** Update multiple SectionContentReferenceParent component instances */
  update?: InputMaybe<Array<SectionContentReferenceParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionContentReferenceParent component instances */
  upsert?: InputMaybe<Array<SectionContentReferenceParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionContentReferenceParentUpdateManyWithNestedWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateManyWithNestedWhereInput>
}

export type SectionContentReferenceParentUpdateOneInlineInput = {
  /** Create and connect one SectionContentReferenceParent document */
  create?: InputMaybe<SectionContentReferenceParentCreateInput>
  /** Delete currently connected SectionContentReferenceParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionContentReferenceParent document */
  update?: InputMaybe<SectionContentReferenceParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionContentReferenceParent document */
  upsert?: InputMaybe<SectionContentReferenceParentUpsertWithNestedWhereUniqueInput>
}

export type SectionContentReferenceParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SectionContentReferenceParentUpdateWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
}

export type SectionContentReferenceParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SectionContentReferenceParentUpsertWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type SectionContentReferenceParentWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereInput>
}

export type SectionContentReferenceParentWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereUniqueInput>
}

export type SectionContentReferenceUpdateInput = {
  references?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eUpdateManyInlineInput>
}

export type SectionContentReferenceUpdateManyInlineInput = {
  /** Create and connect multiple SectionContentReference component instances */
  create?: InputMaybe<Array<SectionContentReferenceCreateWithPositionInput>>
  /** Delete multiple SectionContentReference documents */
  delete?: InputMaybe<Array<SectionContentReferenceWhereUniqueInput>>
  /** Update multiple SectionContentReference component instances */
  update?: InputMaybe<Array<SectionContentReferenceUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionContentReference component instances */
  upsert?: InputMaybe<Array<SectionContentReferenceUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionContentReferenceUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionContentReferenceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionContentReferenceUpdateManyInput
  /** Document search */
  where: SectionContentReferenceWhereInput
}

export type SectionContentReferenceUpdateOneInlineInput = {
  /** Create and connect one SectionContentReference document */
  create?: InputMaybe<SectionContentReferenceCreateInput>
  /** Delete currently connected SectionContentReference document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionContentReference document */
  update?: InputMaybe<SectionContentReferenceUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionContentReference document */
  upsert?: InputMaybe<SectionContentReferenceUpsertWithNestedWhereUniqueInput>
}

export type SectionContentReferenceUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionContentReferenceUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionContentReferenceWhereUniqueInput
}

export type SectionContentReferenceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionContentReferenceUpdateInput
  /** Unique document search */
  where: SectionContentReferenceWhereUniqueInput
}

export type SectionContentReferenceUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionContentReferenceCreateInput
  /** Update document if it exists */
  update: SectionContentReferenceUpdateInput
}

export type SectionContentReferenceUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionContentReferenceUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionContentReferenceWhereUniqueInput
}

export type SectionContentReferenceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionContentReferenceUpsertInput
  /** Unique document search */
  where: SectionContentReferenceWhereUniqueInput
}

/** Identifies documents */
export type SectionContentReferenceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionContentReferenceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty */
  references_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  references_some?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eWhereInput>
}

/** References SectionContentReference record uniquely */
export type SectionContentReferenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionCta = Entity & {
  __typename?: 'SectionCta'
  appStoreUrl?: Maybe<Scalars['String']['output']>
  /** Defines text and CTA position. Allowed (left center right) Default (center) */
  contentPosition?: Maybe<ElementPosition>
  ctaExternal?: Maybe<Scalars['Boolean']['output']>
  /** Optional content image for left/right aligned content. */
  ctaImage?: Maybe<Asset>
  ctaTarget: Scalars['String']['output']
  ctaText: Scalars['String']['output']
  ctaUrl: Scalars['String']['output']
  darkTheme?: Maybe<Scalars['Boolean']['output']>
  /** Defines a decoration pattern to use with the component is is used for. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Reference an internal page */
  internalPageLink?: Maybe<EventPage>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCta>
  partnerLogo?: Maybe<Asset>
  playStoreUrl?: Maybe<Scalars['String']['output']>
  showDecoration?: Maybe<Scalars['Boolean']['output']>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

export type SectionCtaCtaImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionCtaInternalPageLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionCtaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionCtaPartnerLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionCtaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCtaWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCtaConnection = {
  __typename?: 'SectionCtaConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCtaEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCtaCreateInput = {
  /** appStoreUrl input for default locale (de) */
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  contentPosition?: InputMaybe<ElementPosition>
  ctaExternal?: InputMaybe<Scalars['Boolean']['input']>
  ctaImage?: InputMaybe<AssetCreateOneInlineInput>
  /** ctaTarget input for default locale (de) */
  ctaTarget: Scalars['String']['input']
  /** ctaText input for default locale (de) */
  ctaText: Scalars['String']['input']
  /** ctaUrl input for default locale (de) */
  ctaUrl: Scalars['String']['input']
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  internalPageLink?: InputMaybe<EventPageCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCtaCreateLocalizationsInput>
  partnerLogo?: InputMaybe<AssetCreateOneInlineInput>
  /** playStoreUrl input for default locale (de) */
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  showDecoration?: InputMaybe<Scalars['Boolean']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaCreateLocalizationDataInput = {
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  ctaTarget: Scalars['String']['input']
  ctaText: Scalars['String']['input']
  ctaUrl: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaCreateLocalizationInput = {
  /** Localization input */
  data: SectionCtaCreateLocalizationDataInput
  locale: Locale
}

export type SectionCtaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCtaCreateLocalizationInput>>
}

export type SectionCtaCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCta documents */
  create?: InputMaybe<Array<SectionCtaCreateInput>>
}

export type SectionCtaCreateOneInlineInput = {
  /** Create and connect one SectionCta document */
  create?: InputMaybe<SectionCtaCreateInput>
}

export type SectionCtaCreateWithPositionInput = {
  /** Document to create */
  data: SectionCtaCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCtaEdge = {
  __typename?: 'SectionCtaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCta
}

/** Identifies documents */
export type SectionCtaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  contentPosition?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  contentPosition_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  contentPosition_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  contentPosition_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  ctaExternal?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ctaExternal_not?: InputMaybe<Scalars['Boolean']['input']>
  ctaImage?: InputMaybe<AssetWhereInput>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  internalPageLink?: InputMaybe<EventPageWhereInput>
  partnerLogo?: InputMaybe<AssetWhereInput>
  showDecoration?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showDecoration_not?: InputMaybe<Scalars['Boolean']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionCtaOrderByInput {
  AppStoreUrlAsc = 'appStoreUrl_ASC',
  AppStoreUrlDesc = 'appStoreUrl_DESC',
  ContentPositionAsc = 'contentPosition_ASC',
  ContentPositionDesc = 'contentPosition_DESC',
  CtaExternalAsc = 'ctaExternal_ASC',
  CtaExternalDesc = 'ctaExternal_DESC',
  CtaTargetAsc = 'ctaTarget_ASC',
  CtaTargetDesc = 'ctaTarget_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DarkThemeAsc = 'darkTheme_ASC',
  DarkThemeDesc = 'darkTheme_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PlayStoreUrlAsc = 'playStoreUrl_ASC',
  PlayStoreUrlDesc = 'playStoreUrl_DESC',
  ShowDecorationAsc = 'showDecoration_ASC',
  ShowDecorationDesc = 'showDecoration_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCtaParent = EventPage | LandingPage

export type SectionCtaParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionCtaParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionCtaParentCreateManyInlineInput = {
  /** Connect multiple existing SectionCtaParent documents */
  connect?: InputMaybe<Array<SectionCtaParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionCtaParent documents */
  create?: InputMaybe<Array<SectionCtaParentCreateInput>>
}

export type SectionCtaParentCreateOneInlineInput = {
  /** Connect one existing SectionCtaParent document */
  connect?: InputMaybe<SectionCtaParentWhereUniqueInput>
  /** Create and connect one SectionCtaParent document */
  create?: InputMaybe<SectionCtaParentCreateInput>
}

export type SectionCtaParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionCtaParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionCtaParent documents */
  connect?: InputMaybe<Array<SectionCtaParentConnectInput>>
  /** Create and connect multiple SectionCtaParent documents */
  create?: InputMaybe<Array<SectionCtaParentCreateInput>>
  /** Delete multiple SectionCtaParent documents */
  delete?: InputMaybe<Array<SectionCtaParentWhereUniqueInput>>
  /** Disconnect multiple SectionCtaParent documents */
  disconnect?: InputMaybe<Array<SectionCtaParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionCtaParent documents */
  set?: InputMaybe<Array<SectionCtaParentWhereUniqueInput>>
  /** Update multiple SectionCtaParent documents */
  update?: InputMaybe<Array<SectionCtaParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionCtaParent documents */
  upsert?: InputMaybe<Array<SectionCtaParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionCtaParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionCtaParentUpdateOneInlineInput = {
  /** Connect existing SectionCtaParent document */
  connect?: InputMaybe<SectionCtaParentWhereUniqueInput>
  /** Create and connect one SectionCtaParent document */
  create?: InputMaybe<SectionCtaParentCreateInput>
  /** Delete currently connected SectionCtaParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionCtaParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCtaParent document */
  update?: InputMaybe<SectionCtaParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCtaParent document */
  upsert?: InputMaybe<SectionCtaParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCtaParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionCtaParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionCtaParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionCtaParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionCtaUpdateInput = {
  /** appStoreUrl input for default locale (de) */
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  contentPosition?: InputMaybe<ElementPosition>
  ctaExternal?: InputMaybe<Scalars['Boolean']['input']>
  ctaImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** ctaTarget input for default locale (de) */
  ctaTarget?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  internalPageLink?: InputMaybe<EventPageUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCtaUpdateLocalizationsInput>
  partnerLogo?: InputMaybe<AssetUpdateOneInlineInput>
  /** playStoreUrl input for default locale (de) */
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  showDecoration?: InputMaybe<Scalars['Boolean']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaUpdateLocalizationDataInput = {
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  ctaTarget?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaUpdateLocalizationInput = {
  data: SectionCtaUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCtaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCtaCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCtaUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCtaUpsertLocalizationInput>>
}

export type SectionCtaUpdateManyInlineInput = {
  /** Create and connect multiple SectionCta component instances */
  create?: InputMaybe<Array<SectionCtaCreateWithPositionInput>>
  /** Delete multiple SectionCta documents */
  delete?: InputMaybe<Array<SectionCtaWhereUniqueInput>>
  /** Update multiple SectionCta component instances */
  update?: InputMaybe<Array<SectionCtaUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCta component instances */
  upsert?: InputMaybe<Array<SectionCtaUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCtaUpdateManyInput = {
  /** appStoreUrl input for default locale (de) */
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  contentPosition?: InputMaybe<ElementPosition>
  ctaExternal?: InputMaybe<Scalars['Boolean']['input']>
  /** ctaTarget input for default locale (de) */
  ctaTarget?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCtaUpdateManyLocalizationsInput>
  /** playStoreUrl input for default locale (de) */
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  showDecoration?: InputMaybe<Scalars['Boolean']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaUpdateManyLocalizationDataInput = {
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  ctaTarget?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCtaUpdateManyLocalizationInput = {
  data: SectionCtaUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCtaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCtaUpdateManyLocalizationInput>>
}

export type SectionCtaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCtaUpdateManyInput
  /** Document search */
  where: SectionCtaWhereInput
}

export type SectionCtaUpdateOneInlineInput = {
  /** Create and connect one SectionCta document */
  create?: InputMaybe<SectionCtaCreateInput>
  /** Delete currently connected SectionCta document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCta document */
  update?: InputMaybe<SectionCtaUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCta document */
  upsert?: InputMaybe<SectionCtaUpsertWithNestedWhereUniqueInput>
}

export type SectionCtaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCtaUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCtaWhereUniqueInput
}

export type SectionCtaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCtaUpdateInput
  /** Unique document search */
  where: SectionCtaWhereUniqueInput
}

export type SectionCtaUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCtaCreateInput
  /** Update document if it exists */
  update: SectionCtaUpdateInput
}

export type SectionCtaUpsertLocalizationInput = {
  create: SectionCtaCreateLocalizationDataInput
  locale: Locale
  update: SectionCtaUpdateLocalizationDataInput
}

export type SectionCtaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCtaUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCtaWhereUniqueInput
}

export type SectionCtaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCtaUpsertInput
  /** Unique document search */
  where: SectionCtaWhereUniqueInput
}

/** Identifies documents */
export type SectionCtaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCtaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  appStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  appStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  appStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  appStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  appStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  appStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  appStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  appStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  appStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  appStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  contentPosition?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  contentPosition_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  contentPosition_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  contentPosition_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  ctaExternal?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ctaExternal_not?: InputMaybe<Scalars['Boolean']['input']>
  ctaImage?: InputMaybe<AssetWhereInput>
  ctaTarget?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaTarget_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaTarget_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaTarget_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaTarget_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaTarget_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaTarget_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaTarget_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  internalPageLink?: InputMaybe<EventPageWhereInput>
  partnerLogo?: InputMaybe<AssetWhereInput>
  playStoreUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  playStoreUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  playStoreUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  playStoreUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  playStoreUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  playStoreUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  playStoreUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  playStoreUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  playStoreUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  playStoreUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  showDecoration?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showDecoration_not?: InputMaybe<Scalars['Boolean']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCta record uniquely */
export type SectionCtaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Showing a curated list of speakers */
export type SectionCuratedSpeakerList = Entity & {
  __typename?: 'SectionCuratedSpeakerList'
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCuratedSpeakerList>
  /** The top speakers or artist list. */
  speakers: Array<Speaker>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Showing a curated list of speakers */
export type SectionCuratedSpeakerListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Showing a curated list of speakers */
export type SectionCuratedSpeakerListSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

export type SectionCuratedSpeakerListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCuratedSpeakerListWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCuratedSpeakerListConnection = {
  __typename?: 'SectionCuratedSpeakerListConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCuratedSpeakerListEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCuratedSpeakerListCreateInput = {
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCuratedSpeakerListCreateLocalizationsInput>
  speakers: SpeakerCreateManyInlineInput
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionCuratedSpeakerListCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionCuratedSpeakerListCreateLocalizationInput = {
  /** Localization input */
  data: SectionCuratedSpeakerListCreateLocalizationDataInput
  locale: Locale
}

export type SectionCuratedSpeakerListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCuratedSpeakerListCreateLocalizationInput>>
}

export type SectionCuratedSpeakerListCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCuratedSpeakerList documents */
  create?: InputMaybe<Array<SectionCuratedSpeakerListCreateInput>>
}

export type SectionCuratedSpeakerListCreateOneInlineInput = {
  /** Create and connect one SectionCuratedSpeakerList document */
  create?: InputMaybe<SectionCuratedSpeakerListCreateInput>
}

export type SectionCuratedSpeakerListCreateWithPositionInput = {
  /** Document to create */
  data: SectionCuratedSpeakerListCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCuratedSpeakerListEdge = {
  __typename?: 'SectionCuratedSpeakerListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCuratedSpeakerList
}

/** Identifies documents */
export type SectionCuratedSpeakerListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
}

export enum SectionCuratedSpeakerListOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCuratedSpeakerListParent = LandingPage

export type SectionCuratedSpeakerListParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionCuratedSpeakerListParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionCuratedSpeakerListParentCreateManyInlineInput = {
  /** Connect multiple existing SectionCuratedSpeakerListParent documents */
  connect?: InputMaybe<Array<SectionCuratedSpeakerListParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionCuratedSpeakerListParent documents */
  create?: InputMaybe<Array<SectionCuratedSpeakerListParentCreateInput>>
}

export type SectionCuratedSpeakerListParentCreateOneInlineInput = {
  /** Connect one existing SectionCuratedSpeakerListParent document */
  connect?: InputMaybe<SectionCuratedSpeakerListParentWhereUniqueInput>
  /** Create and connect one SectionCuratedSpeakerListParent document */
  create?: InputMaybe<SectionCuratedSpeakerListParentCreateInput>
}

export type SectionCuratedSpeakerListParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionCuratedSpeakerListParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionCuratedSpeakerListParent documents */
  connect?: InputMaybe<Array<SectionCuratedSpeakerListParentConnectInput>>
  /** Create and connect multiple SectionCuratedSpeakerListParent documents */
  create?: InputMaybe<Array<SectionCuratedSpeakerListParentCreateInput>>
  /** Delete multiple SectionCuratedSpeakerListParent documents */
  delete?: InputMaybe<Array<SectionCuratedSpeakerListParentWhereUniqueInput>>
  /** Disconnect multiple SectionCuratedSpeakerListParent documents */
  disconnect?: InputMaybe<Array<SectionCuratedSpeakerListParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionCuratedSpeakerListParent documents */
  set?: InputMaybe<Array<SectionCuratedSpeakerListParentWhereUniqueInput>>
  /** Update multiple SectionCuratedSpeakerListParent documents */
  update?: InputMaybe<Array<SectionCuratedSpeakerListParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionCuratedSpeakerListParent documents */
  upsert?: InputMaybe<Array<SectionCuratedSpeakerListParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionCuratedSpeakerListParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionCuratedSpeakerListParentUpdateOneInlineInput = {
  /** Connect existing SectionCuratedSpeakerListParent document */
  connect?: InputMaybe<SectionCuratedSpeakerListParentWhereUniqueInput>
  /** Create and connect one SectionCuratedSpeakerListParent document */
  create?: InputMaybe<SectionCuratedSpeakerListParentCreateInput>
  /** Delete currently connected SectionCuratedSpeakerListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionCuratedSpeakerListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCuratedSpeakerListParent document */
  update?: InputMaybe<SectionCuratedSpeakerListParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCuratedSpeakerListParent document */
  upsert?: InputMaybe<SectionCuratedSpeakerListParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCuratedSpeakerListParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionCuratedSpeakerListParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionCuratedSpeakerListParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionCuratedSpeakerListParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionCuratedSpeakerListUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCuratedSpeakerListUpdateLocalizationsInput>
  speakers?: InputMaybe<SpeakerUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCuratedSpeakerListUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCuratedSpeakerListUpdateLocalizationInput = {
  data: SectionCuratedSpeakerListUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCuratedSpeakerListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCuratedSpeakerListCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCuratedSpeakerListUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCuratedSpeakerListUpsertLocalizationInput>>
}

export type SectionCuratedSpeakerListUpdateManyInlineInput = {
  /** Create and connect multiple SectionCuratedSpeakerList component instances */
  create?: InputMaybe<Array<SectionCuratedSpeakerListCreateWithPositionInput>>
  /** Delete multiple SectionCuratedSpeakerList documents */
  delete?: InputMaybe<Array<SectionCuratedSpeakerListWhereUniqueInput>>
  /** Update multiple SectionCuratedSpeakerList component instances */
  update?: InputMaybe<Array<SectionCuratedSpeakerListUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCuratedSpeakerList component instances */
  upsert?: InputMaybe<Array<SectionCuratedSpeakerListUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCuratedSpeakerListUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCuratedSpeakerListUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCuratedSpeakerListUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCuratedSpeakerListUpdateManyLocalizationInput = {
  data: SectionCuratedSpeakerListUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCuratedSpeakerListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCuratedSpeakerListUpdateManyLocalizationInput>>
}

export type SectionCuratedSpeakerListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCuratedSpeakerListUpdateManyInput
  /** Document search */
  where: SectionCuratedSpeakerListWhereInput
}

export type SectionCuratedSpeakerListUpdateOneInlineInput = {
  /** Create and connect one SectionCuratedSpeakerList document */
  create?: InputMaybe<SectionCuratedSpeakerListCreateInput>
  /** Delete currently connected SectionCuratedSpeakerList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCuratedSpeakerList document */
  update?: InputMaybe<SectionCuratedSpeakerListUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCuratedSpeakerList document */
  upsert?: InputMaybe<SectionCuratedSpeakerListUpsertWithNestedWhereUniqueInput>
}

export type SectionCuratedSpeakerListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCuratedSpeakerListUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCuratedSpeakerListWhereUniqueInput
}

export type SectionCuratedSpeakerListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCuratedSpeakerListUpdateInput
  /** Unique document search */
  where: SectionCuratedSpeakerListWhereUniqueInput
}

export type SectionCuratedSpeakerListUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCuratedSpeakerListCreateInput
  /** Update document if it exists */
  update: SectionCuratedSpeakerListUpdateInput
}

export type SectionCuratedSpeakerListUpsertLocalizationInput = {
  create: SectionCuratedSpeakerListCreateLocalizationDataInput
  locale: Locale
  update: SectionCuratedSpeakerListUpdateLocalizationDataInput
}

export type SectionCuratedSpeakerListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCuratedSpeakerListUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCuratedSpeakerListWhereUniqueInput
}

export type SectionCuratedSpeakerListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCuratedSpeakerListUpsertInput
  /** Unique document search */
  where: SectionCuratedSpeakerListWhereUniqueInput
}

/** Identifies documents */
export type SectionCuratedSpeakerListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCuratedSpeakerListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCuratedSpeakerList record uniquely */
export type SectionCuratedSpeakerListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Map component using OpenStreetMap to showcase markers on it. */
export type SectionCustomMap = Entity & {
  __typename?: 'SectionCustomMap'
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  initialZoom?: Maybe<Scalars['Int']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionCustomMap>
  /** Default center used on the map. If not provided defaults to Hamburg Messe Halle. */
  mapCenter?: Maybe<Location>
  /** Markers displayed on the map. */
  markers: Array<MapMarker>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

/** Map component using OpenStreetMap to showcase markers on it. */
export type SectionCustomMapLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Map component using OpenStreetMap to showcase markers on it. */
export type SectionCustomMapMarkersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<MapMarkerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<MapMarkerWhereInput>
}

export type SectionCustomMapConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionCustomMapWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionCustomMapConnection = {
  __typename?: 'SectionCustomMapConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionCustomMapEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionCustomMapCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  initialZoom?: InputMaybe<Scalars['Int']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCustomMapCreateLocalizationsInput>
  mapCenter?: InputMaybe<LocationInput>
  markers: MapMarkerCreateManyInlineInput
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapCreateLocalizationInput = {
  /** Localization input */
  data: SectionCustomMapCreateLocalizationDataInput
  locale: Locale
}

export type SectionCustomMapCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCustomMapCreateLocalizationInput>>
}

export type SectionCustomMapCreateManyInlineInput = {
  /** Create and connect multiple existing SectionCustomMap documents */
  create?: InputMaybe<Array<SectionCustomMapCreateInput>>
}

export type SectionCustomMapCreateOneInlineInput = {
  /** Create and connect one SectionCustomMap document */
  create?: InputMaybe<SectionCustomMapCreateInput>
}

export type SectionCustomMapCreateWithPositionInput = {
  /** Document to create */
  data: SectionCustomMapCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionCustomMapEdge = {
  __typename?: 'SectionCustomMapEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionCustomMap
}

/** Identifies documents */
export type SectionCustomMapManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  initialZoom?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  initialZoom_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  initialZoom_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  initialZoom_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  initialZoom_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  initialZoom_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  initialZoom_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  initialZoom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  markers_every?: InputMaybe<MapMarkerWhereInput>
  markers_none?: InputMaybe<MapMarkerWhereInput>
  markers_some?: InputMaybe<MapMarkerWhereInput>
}

export enum SectionCustomMapOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InitialZoomAsc = 'initialZoom_ASC',
  InitialZoomDesc = 'initialZoom_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionCustomMapParent = EventPage

export type SectionCustomMapParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionCustomMapParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionCustomMapParentCreateManyInlineInput = {
  /** Connect multiple existing SectionCustomMapParent documents */
  connect?: InputMaybe<Array<SectionCustomMapParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionCustomMapParent documents */
  create?: InputMaybe<Array<SectionCustomMapParentCreateInput>>
}

export type SectionCustomMapParentCreateOneInlineInput = {
  /** Connect one existing SectionCustomMapParent document */
  connect?: InputMaybe<SectionCustomMapParentWhereUniqueInput>
  /** Create and connect one SectionCustomMapParent document */
  create?: InputMaybe<SectionCustomMapParentCreateInput>
}

export type SectionCustomMapParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionCustomMapParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionCustomMapParent documents */
  connect?: InputMaybe<Array<SectionCustomMapParentConnectInput>>
  /** Create and connect multiple SectionCustomMapParent documents */
  create?: InputMaybe<Array<SectionCustomMapParentCreateInput>>
  /** Delete multiple SectionCustomMapParent documents */
  delete?: InputMaybe<Array<SectionCustomMapParentWhereUniqueInput>>
  /** Disconnect multiple SectionCustomMapParent documents */
  disconnect?: InputMaybe<Array<SectionCustomMapParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionCustomMapParent documents */
  set?: InputMaybe<Array<SectionCustomMapParentWhereUniqueInput>>
  /** Update multiple SectionCustomMapParent documents */
  update?: InputMaybe<Array<SectionCustomMapParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionCustomMapParent documents */
  upsert?: InputMaybe<Array<SectionCustomMapParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionCustomMapParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionCustomMapParentUpdateOneInlineInput = {
  /** Connect existing SectionCustomMapParent document */
  connect?: InputMaybe<SectionCustomMapParentWhereUniqueInput>
  /** Create and connect one SectionCustomMapParent document */
  create?: InputMaybe<SectionCustomMapParentCreateInput>
  /** Delete currently connected SectionCustomMapParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionCustomMapParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCustomMapParent document */
  update?: InputMaybe<SectionCustomMapParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCustomMapParent document */
  upsert?: InputMaybe<SectionCustomMapParentUpsertWithNestedWhereUniqueInput>
}

export type SectionCustomMapParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionCustomMapParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionCustomMapParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionCustomMapParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionCustomMapUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  initialZoom?: InputMaybe<Scalars['Int']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionCustomMapUpdateLocalizationsInput>
  mapCenter?: InputMaybe<LocationInput>
  markers?: InputMaybe<MapMarkerUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapUpdateLocalizationInput = {
  data: SectionCustomMapUpdateLocalizationDataInput
  locale: Locale
}

export type SectionCustomMapUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCustomMapCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCustomMapUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionCustomMapUpsertLocalizationInput>>
}

export type SectionCustomMapUpdateManyInlineInput = {
  /** Create and connect multiple SectionCustomMap component instances */
  create?: InputMaybe<Array<SectionCustomMapCreateWithPositionInput>>
  /** Delete multiple SectionCustomMap documents */
  delete?: InputMaybe<Array<SectionCustomMapWhereUniqueInput>>
  /** Update multiple SectionCustomMap component instances */
  update?: InputMaybe<Array<SectionCustomMapUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionCustomMap component instances */
  upsert?: InputMaybe<Array<SectionCustomMapUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionCustomMapUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  initialZoom?: InputMaybe<Scalars['Int']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionCustomMapUpdateManyLocalizationsInput>
  mapCenter?: InputMaybe<LocationInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionCustomMapUpdateManyLocalizationInput = {
  data: SectionCustomMapUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionCustomMapUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionCustomMapUpdateManyLocalizationInput>>
}

export type SectionCustomMapUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionCustomMapUpdateManyInput
  /** Document search */
  where: SectionCustomMapWhereInput
}

export type SectionCustomMapUpdateOneInlineInput = {
  /** Create and connect one SectionCustomMap document */
  create?: InputMaybe<SectionCustomMapCreateInput>
  /** Delete currently connected SectionCustomMap document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionCustomMap document */
  update?: InputMaybe<SectionCustomMapUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionCustomMap document */
  upsert?: InputMaybe<SectionCustomMapUpsertWithNestedWhereUniqueInput>
}

export type SectionCustomMapUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionCustomMapUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCustomMapWhereUniqueInput
}

export type SectionCustomMapUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionCustomMapUpdateInput
  /** Unique document search */
  where: SectionCustomMapWhereUniqueInput
}

export type SectionCustomMapUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCustomMapCreateInput
  /** Update document if it exists */
  update: SectionCustomMapUpdateInput
}

export type SectionCustomMapUpsertLocalizationInput = {
  create: SectionCustomMapCreateLocalizationDataInput
  locale: Locale
  update: SectionCustomMapUpdateLocalizationDataInput
}

export type SectionCustomMapUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionCustomMapUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionCustomMapWhereUniqueInput
}

export type SectionCustomMapUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionCustomMapUpsertInput
  /** Unique document search */
  where: SectionCustomMapWhereUniqueInput
}

/** Identifies documents */
export type SectionCustomMapWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionCustomMapWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  initialZoom?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  initialZoom_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  initialZoom_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  initialZoom_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  initialZoom_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  initialZoom_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  initialZoom_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  initialZoom_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  markers_every?: InputMaybe<MapMarkerWhereInput>
  markers_none?: InputMaybe<MapMarkerWhereInput>
  markers_some?: InputMaybe<MapMarkerWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionCustomMap record uniquely */
export type SectionCustomMapWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** A proportions how whole section is divided into columns. */
export enum SectionDivision {
  FiftyFifty = 'fifty_fifty',
  SeventyThirty = 'seventy_thirty',
  ThirtySeventy = 'thirty_seventy',
}

export type SectionDownloads = Entity & {
  __typename?: 'SectionDownloads'
  description?: Maybe<Scalars['String']['output']>
  downloads: Array<Download>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionDownloads>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type SectionDownloadsDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<DownloadOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DownloadWhereInput>
}

export type SectionDownloadsLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionDownloadsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionDownloadsWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionDownloadsConnection = {
  __typename?: 'SectionDownloadsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionDownloadsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionDownloadsCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  downloads?: InputMaybe<DownloadCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionDownloadsCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionDownloadsCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type SectionDownloadsCreateLocalizationInput = {
  /** Localization input */
  data: SectionDownloadsCreateLocalizationDataInput
  locale: Locale
}

export type SectionDownloadsCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionDownloadsCreateLocalizationInput>>
}

export type SectionDownloadsCreateManyInlineInput = {
  /** Create and connect multiple existing SectionDownloads documents */
  create?: InputMaybe<Array<SectionDownloadsCreateInput>>
}

export type SectionDownloadsCreateOneInlineInput = {
  /** Create and connect one SectionDownloads document */
  create?: InputMaybe<SectionDownloadsCreateInput>
}

export type SectionDownloadsCreateWithPositionInput = {
  /** Document to create */
  data: SectionDownloadsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionDownloadsEdge = {
  __typename?: 'SectionDownloadsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionDownloads
}

/** Identifies documents */
export type SectionDownloadsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  downloads_every?: InputMaybe<DownloadWhereInput>
  downloads_none?: InputMaybe<DownloadWhereInput>
  downloads_some?: InputMaybe<DownloadWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionDownloadsOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionDownloadsParent = EventPage

export type SectionDownloadsParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionDownloadsParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionDownloadsParentCreateManyInlineInput = {
  /** Connect multiple existing SectionDownloadsParent documents */
  connect?: InputMaybe<Array<SectionDownloadsParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionDownloadsParent documents */
  create?: InputMaybe<Array<SectionDownloadsParentCreateInput>>
}

export type SectionDownloadsParentCreateOneInlineInput = {
  /** Connect one existing SectionDownloadsParent document */
  connect?: InputMaybe<SectionDownloadsParentWhereUniqueInput>
  /** Create and connect one SectionDownloadsParent document */
  create?: InputMaybe<SectionDownloadsParentCreateInput>
}

export type SectionDownloadsParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionDownloadsParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionDownloadsParent documents */
  connect?: InputMaybe<Array<SectionDownloadsParentConnectInput>>
  /** Create and connect multiple SectionDownloadsParent documents */
  create?: InputMaybe<Array<SectionDownloadsParentCreateInput>>
  /** Delete multiple SectionDownloadsParent documents */
  delete?: InputMaybe<Array<SectionDownloadsParentWhereUniqueInput>>
  /** Disconnect multiple SectionDownloadsParent documents */
  disconnect?: InputMaybe<Array<SectionDownloadsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionDownloadsParent documents */
  set?: InputMaybe<Array<SectionDownloadsParentWhereUniqueInput>>
  /** Update multiple SectionDownloadsParent documents */
  update?: InputMaybe<Array<SectionDownloadsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionDownloadsParent documents */
  upsert?: InputMaybe<Array<SectionDownloadsParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionDownloadsParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionDownloadsParentUpdateOneInlineInput = {
  /** Connect existing SectionDownloadsParent document */
  connect?: InputMaybe<SectionDownloadsParentWhereUniqueInput>
  /** Create and connect one SectionDownloadsParent document */
  create?: InputMaybe<SectionDownloadsParentCreateInput>
  /** Delete currently connected SectionDownloadsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionDownloadsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionDownloadsParent document */
  update?: InputMaybe<SectionDownloadsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionDownloadsParent document */
  upsert?: InputMaybe<SectionDownloadsParentUpsertWithNestedWhereUniqueInput>
}

export type SectionDownloadsParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionDownloadsParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionDownloadsParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionDownloadsParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionDownloadsUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  downloads?: InputMaybe<DownloadUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionDownloadsUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionDownloadsUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionDownloadsUpdateLocalizationInput = {
  data: SectionDownloadsUpdateLocalizationDataInput
  locale: Locale
}

export type SectionDownloadsUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionDownloadsCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionDownloadsUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionDownloadsUpsertLocalizationInput>>
}

export type SectionDownloadsUpdateManyInlineInput = {
  /** Create and connect multiple SectionDownloads component instances */
  create?: InputMaybe<Array<SectionDownloadsCreateWithPositionInput>>
  /** Delete multiple SectionDownloads documents */
  delete?: InputMaybe<Array<SectionDownloadsWhereUniqueInput>>
  /** Update multiple SectionDownloads component instances */
  update?: InputMaybe<Array<SectionDownloadsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionDownloads component instances */
  upsert?: InputMaybe<Array<SectionDownloadsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionDownloadsUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionDownloadsUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionDownloadsUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionDownloadsUpdateManyLocalizationInput = {
  data: SectionDownloadsUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionDownloadsUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionDownloadsUpdateManyLocalizationInput>>
}

export type SectionDownloadsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionDownloadsUpdateManyInput
  /** Document search */
  where: SectionDownloadsWhereInput
}

export type SectionDownloadsUpdateOneInlineInput = {
  /** Create and connect one SectionDownloads document */
  create?: InputMaybe<SectionDownloadsCreateInput>
  /** Delete currently connected SectionDownloads document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionDownloads document */
  update?: InputMaybe<SectionDownloadsUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionDownloads document */
  upsert?: InputMaybe<SectionDownloadsUpsertWithNestedWhereUniqueInput>
}

export type SectionDownloadsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionDownloadsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionDownloadsWhereUniqueInput
}

export type SectionDownloadsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionDownloadsUpdateInput
  /** Unique document search */
  where: SectionDownloadsWhereUniqueInput
}

export type SectionDownloadsUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionDownloadsCreateInput
  /** Update document if it exists */
  update: SectionDownloadsUpdateInput
}

export type SectionDownloadsUpsertLocalizationInput = {
  create: SectionDownloadsCreateLocalizationDataInput
  locale: Locale
  update: SectionDownloadsUpdateLocalizationDataInput
}

export type SectionDownloadsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionDownloadsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionDownloadsWhereUniqueInput
}

export type SectionDownloadsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionDownloadsUpsertInput
  /** Unique document search */
  where: SectionDownloadsWhereUniqueInput
}

/** Identifies documents */
export type SectionDownloadsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionDownloadsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  downloads_every?: InputMaybe<DownloadWhereInput>
  downloads_none?: InputMaybe<DownloadWhereInput>
  downloads_some?: InputMaybe<DownloadWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionDownloads record uniquely */
export type SectionDownloadsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Shows timetable for Event offers in list view modes. */
export type SectionEventOffersTimetable = Entity & {
  __typename?: 'SectionEventOffersTimetable'
  /** Provide day to preselect in yyyy-MM-dd format */
  dayPreselect?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

export type SectionEventOffersTimetableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionEventOffersTimetableWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionEventOffersTimetableConnection = {
  __typename?: 'SectionEventOffersTimetableConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionEventOffersTimetableEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionEventOffersTimetableCreateInput = {
  dayPreselect?: InputMaybe<Scalars['String']['input']>
}

export type SectionEventOffersTimetableCreateManyInlineInput = {
  /** Create and connect multiple existing SectionEventOffersTimetable documents */
  create?: InputMaybe<Array<SectionEventOffersTimetableCreateInput>>
}

export type SectionEventOffersTimetableCreateOneInlineInput = {
  /** Create and connect one SectionEventOffersTimetable document */
  create?: InputMaybe<SectionEventOffersTimetableCreateInput>
}

export type SectionEventOffersTimetableCreateWithPositionInput = {
  /** Document to create */
  data: SectionEventOffersTimetableCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionEventOffersTimetableEdge = {
  __typename?: 'SectionEventOffersTimetableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionEventOffersTimetable
}

/** Identifies documents */
export type SectionEventOffersTimetableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  dayPreselect_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  dayPreselect_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  dayPreselect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  dayPreselect_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  dayPreselect_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  dayPreselect_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  dayPreselect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  dayPreselect_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  dayPreselect_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionEventOffersTimetableOrderByInput {
  DayPreselectAsc = 'dayPreselect_ASC',
  DayPreselectDesc = 'dayPreselect_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionEventOffersTimetableParent = EventPage

export type SectionEventOffersTimetableParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionEventOffersTimetableParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionEventOffersTimetableParentCreateManyInlineInput = {
  /** Connect multiple existing SectionEventOffersTimetableParent documents */
  connect?: InputMaybe<Array<SectionEventOffersTimetableParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionEventOffersTimetableParent documents */
  create?: InputMaybe<Array<SectionEventOffersTimetableParentCreateInput>>
}

export type SectionEventOffersTimetableParentCreateOneInlineInput = {
  /** Connect one existing SectionEventOffersTimetableParent document */
  connect?: InputMaybe<SectionEventOffersTimetableParentWhereUniqueInput>
  /** Create and connect one SectionEventOffersTimetableParent document */
  create?: InputMaybe<SectionEventOffersTimetableParentCreateInput>
}

export type SectionEventOffersTimetableParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionEventOffersTimetableParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionEventOffersTimetableParent documents */
  connect?: InputMaybe<Array<SectionEventOffersTimetableParentConnectInput>>
  /** Create and connect multiple SectionEventOffersTimetableParent documents */
  create?: InputMaybe<Array<SectionEventOffersTimetableParentCreateInput>>
  /** Delete multiple SectionEventOffersTimetableParent documents */
  delete?: InputMaybe<Array<SectionEventOffersTimetableParentWhereUniqueInput>>
  /** Disconnect multiple SectionEventOffersTimetableParent documents */
  disconnect?: InputMaybe<Array<SectionEventOffersTimetableParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionEventOffersTimetableParent documents */
  set?: InputMaybe<Array<SectionEventOffersTimetableParentWhereUniqueInput>>
  /** Update multiple SectionEventOffersTimetableParent documents */
  update?: InputMaybe<Array<SectionEventOffersTimetableParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionEventOffersTimetableParent documents */
  upsert?: InputMaybe<Array<SectionEventOffersTimetableParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionEventOffersTimetableParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionEventOffersTimetableParentUpdateOneInlineInput = {
  /** Connect existing SectionEventOffersTimetableParent document */
  connect?: InputMaybe<SectionEventOffersTimetableParentWhereUniqueInput>
  /** Create and connect one SectionEventOffersTimetableParent document */
  create?: InputMaybe<SectionEventOffersTimetableParentCreateInput>
  /** Delete currently connected SectionEventOffersTimetableParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionEventOffersTimetableParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionEventOffersTimetableParent document */
  update?: InputMaybe<SectionEventOffersTimetableParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionEventOffersTimetableParent document */
  upsert?: InputMaybe<SectionEventOffersTimetableParentUpsertWithNestedWhereUniqueInput>
}

export type SectionEventOffersTimetableParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionEventOffersTimetableParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionEventOffersTimetableParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionEventOffersTimetableParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionEventOffersTimetableUpdateInput = {
  dayPreselect?: InputMaybe<Scalars['String']['input']>
}

export type SectionEventOffersTimetableUpdateManyInlineInput = {
  /** Create and connect multiple SectionEventOffersTimetable component instances */
  create?: InputMaybe<Array<SectionEventOffersTimetableCreateWithPositionInput>>
  /** Delete multiple SectionEventOffersTimetable documents */
  delete?: InputMaybe<Array<SectionEventOffersTimetableWhereUniqueInput>>
  /** Update multiple SectionEventOffersTimetable component instances */
  update?: InputMaybe<Array<SectionEventOffersTimetableUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionEventOffersTimetable component instances */
  upsert?: InputMaybe<Array<SectionEventOffersTimetableUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionEventOffersTimetableUpdateManyInput = {
  dayPreselect?: InputMaybe<Scalars['String']['input']>
}

export type SectionEventOffersTimetableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionEventOffersTimetableUpdateManyInput
  /** Document search */
  where: SectionEventOffersTimetableWhereInput
}

export type SectionEventOffersTimetableUpdateOneInlineInput = {
  /** Create and connect one SectionEventOffersTimetable document */
  create?: InputMaybe<SectionEventOffersTimetableCreateInput>
  /** Delete currently connected SectionEventOffersTimetable document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionEventOffersTimetable document */
  update?: InputMaybe<SectionEventOffersTimetableUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionEventOffersTimetable document */
  upsert?: InputMaybe<SectionEventOffersTimetableUpsertWithNestedWhereUniqueInput>
}

export type SectionEventOffersTimetableUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionEventOffersTimetableUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionEventOffersTimetableWhereUniqueInput
}

export type SectionEventOffersTimetableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionEventOffersTimetableUpdateInput
  /** Unique document search */
  where: SectionEventOffersTimetableWhereUniqueInput
}

export type SectionEventOffersTimetableUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionEventOffersTimetableCreateInput
  /** Update document if it exists */
  update: SectionEventOffersTimetableUpdateInput
}

export type SectionEventOffersTimetableUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionEventOffersTimetableUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionEventOffersTimetableWhereUniqueInput
}

export type SectionEventOffersTimetableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionEventOffersTimetableUpsertInput
  /** Unique document search */
  where: SectionEventOffersTimetableWhereUniqueInput
}

/** Identifies documents */
export type SectionEventOffersTimetableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionEventOffersTimetableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  dayPreselect_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  dayPreselect_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  dayPreselect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  dayPreselect_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  dayPreselect_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  dayPreselect_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  dayPreselect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  dayPreselect_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  dayPreselect_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionEventOffersTimetable record uniquely */
export type SectionEventOffersTimetableWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Contact form shown on 'become-an-exhibitor' page. */
export type SectionExhibitorContactForm = Entity & {
  __typename?: 'SectionExhibitorContactForm'
  captchaPuzzleEndpoint: Scalars['String']['output']
  captchaSiteKey: Scalars['String']['output']
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionExhibitorContactForm>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Contact form shown on 'become-an-exhibitor' page. */
export type SectionExhibitorContactFormLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionExhibitorContactFormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionExhibitorContactFormWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionExhibitorContactFormConnection = {
  __typename?: 'SectionExhibitorContactFormConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionExhibitorContactFormEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionExhibitorContactFormCreateInput = {
  captchaPuzzleEndpoint: Scalars['String']['input']
  captchaSiteKey: Scalars['String']['input']
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionExhibitorContactFormCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionExhibitorContactFormCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionExhibitorContactFormCreateLocalizationInput = {
  /** Localization input */
  data: SectionExhibitorContactFormCreateLocalizationDataInput
  locale: Locale
}

export type SectionExhibitorContactFormCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionExhibitorContactFormCreateLocalizationInput>>
}

export type SectionExhibitorContactFormCreateManyInlineInput = {
  /** Create and connect multiple existing SectionExhibitorContactForm documents */
  create?: InputMaybe<Array<SectionExhibitorContactFormCreateInput>>
}

export type SectionExhibitorContactFormCreateOneInlineInput = {
  /** Create and connect one SectionExhibitorContactForm document */
  create?: InputMaybe<SectionExhibitorContactFormCreateInput>
}

export type SectionExhibitorContactFormCreateWithPositionInput = {
  /** Document to create */
  data: SectionExhibitorContactFormCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionExhibitorContactFormEdge = {
  __typename?: 'SectionExhibitorContactFormEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionExhibitorContactForm
}

/** Identifies documents */
export type SectionExhibitorContactFormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  captchaPuzzleEndpoint?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  captchaPuzzleEndpoint_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  captchaPuzzleEndpoint_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  captchaPuzzleEndpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  captchaPuzzleEndpoint_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  captchaPuzzleEndpoint_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  captchaPuzzleEndpoint_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  captchaPuzzleEndpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  captchaPuzzleEndpoint_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  captchaPuzzleEndpoint_starts_with?: InputMaybe<Scalars['String']['input']>
  captchaSiteKey?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  captchaSiteKey_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  captchaSiteKey_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  captchaSiteKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  captchaSiteKey_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  captchaSiteKey_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  captchaSiteKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  captchaSiteKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  captchaSiteKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  captchaSiteKey_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionExhibitorContactFormOrderByInput {
  CaptchaPuzzleEndpointAsc = 'captchaPuzzleEndpoint_ASC',
  CaptchaPuzzleEndpointDesc = 'captchaPuzzleEndpoint_DESC',
  CaptchaSiteKeyAsc = 'captchaSiteKey_ASC',
  CaptchaSiteKeyDesc = 'captchaSiteKey_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionExhibitorContactFormParent = EventPage

export type SectionExhibitorContactFormParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionExhibitorContactFormParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionExhibitorContactFormParentCreateManyInlineInput = {
  /** Connect multiple existing SectionExhibitorContactFormParent documents */
  connect?: InputMaybe<Array<SectionExhibitorContactFormParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionExhibitorContactFormParent documents */
  create?: InputMaybe<Array<SectionExhibitorContactFormParentCreateInput>>
}

export type SectionExhibitorContactFormParentCreateOneInlineInput = {
  /** Connect one existing SectionExhibitorContactFormParent document */
  connect?: InputMaybe<SectionExhibitorContactFormParentWhereUniqueInput>
  /** Create and connect one SectionExhibitorContactFormParent document */
  create?: InputMaybe<SectionExhibitorContactFormParentCreateInput>
}

export type SectionExhibitorContactFormParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionExhibitorContactFormParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionExhibitorContactFormParent documents */
  connect?: InputMaybe<Array<SectionExhibitorContactFormParentConnectInput>>
  /** Create and connect multiple SectionExhibitorContactFormParent documents */
  create?: InputMaybe<Array<SectionExhibitorContactFormParentCreateInput>>
  /** Delete multiple SectionExhibitorContactFormParent documents */
  delete?: InputMaybe<Array<SectionExhibitorContactFormParentWhereUniqueInput>>
  /** Disconnect multiple SectionExhibitorContactFormParent documents */
  disconnect?: InputMaybe<Array<SectionExhibitorContactFormParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionExhibitorContactFormParent documents */
  set?: InputMaybe<Array<SectionExhibitorContactFormParentWhereUniqueInput>>
  /** Update multiple SectionExhibitorContactFormParent documents */
  update?: InputMaybe<Array<SectionExhibitorContactFormParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionExhibitorContactFormParent documents */
  upsert?: InputMaybe<Array<SectionExhibitorContactFormParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionExhibitorContactFormParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionExhibitorContactFormParentUpdateOneInlineInput = {
  /** Connect existing SectionExhibitorContactFormParent document */
  connect?: InputMaybe<SectionExhibitorContactFormParentWhereUniqueInput>
  /** Create and connect one SectionExhibitorContactFormParent document */
  create?: InputMaybe<SectionExhibitorContactFormParentCreateInput>
  /** Delete currently connected SectionExhibitorContactFormParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionExhibitorContactFormParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExhibitorContactFormParent document */
  update?: InputMaybe<SectionExhibitorContactFormParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExhibitorContactFormParent document */
  upsert?: InputMaybe<SectionExhibitorContactFormParentUpsertWithNestedWhereUniqueInput>
}

export type SectionExhibitorContactFormParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionExhibitorContactFormParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionExhibitorContactFormParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionExhibitorContactFormParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionExhibitorContactFormUpdateInput = {
  captchaPuzzleEndpoint?: InputMaybe<Scalars['String']['input']>
  captchaSiteKey?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionExhibitorContactFormUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExhibitorContactFormUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExhibitorContactFormUpdateLocalizationInput = {
  data: SectionExhibitorContactFormUpdateLocalizationDataInput
  locale: Locale
}

export type SectionExhibitorContactFormUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionExhibitorContactFormCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExhibitorContactFormUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionExhibitorContactFormUpsertLocalizationInput>>
}

export type SectionExhibitorContactFormUpdateManyInlineInput = {
  /** Create and connect multiple SectionExhibitorContactForm component instances */
  create?: InputMaybe<Array<SectionExhibitorContactFormCreateWithPositionInput>>
  /** Delete multiple SectionExhibitorContactForm documents */
  delete?: InputMaybe<Array<SectionExhibitorContactFormWhereUniqueInput>>
  /** Update multiple SectionExhibitorContactForm component instances */
  update?: InputMaybe<Array<SectionExhibitorContactFormUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionExhibitorContactForm component instances */
  upsert?: InputMaybe<Array<SectionExhibitorContactFormUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionExhibitorContactFormUpdateManyInput = {
  captchaPuzzleEndpoint?: InputMaybe<Scalars['String']['input']>
  captchaSiteKey?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionExhibitorContactFormUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExhibitorContactFormUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExhibitorContactFormUpdateManyLocalizationInput = {
  data: SectionExhibitorContactFormUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionExhibitorContactFormUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExhibitorContactFormUpdateManyLocalizationInput>>
}

export type SectionExhibitorContactFormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionExhibitorContactFormUpdateManyInput
  /** Document search */
  where: SectionExhibitorContactFormWhereInput
}

export type SectionExhibitorContactFormUpdateOneInlineInput = {
  /** Create and connect one SectionExhibitorContactForm document */
  create?: InputMaybe<SectionExhibitorContactFormCreateInput>
  /** Delete currently connected SectionExhibitorContactForm document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExhibitorContactForm document */
  update?: InputMaybe<SectionExhibitorContactFormUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExhibitorContactForm document */
  upsert?: InputMaybe<SectionExhibitorContactFormUpsertWithNestedWhereUniqueInput>
}

export type SectionExhibitorContactFormUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionExhibitorContactFormUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExhibitorContactFormWhereUniqueInput
}

export type SectionExhibitorContactFormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionExhibitorContactFormUpdateInput
  /** Unique document search */
  where: SectionExhibitorContactFormWhereUniqueInput
}

export type SectionExhibitorContactFormUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionExhibitorContactFormCreateInput
  /** Update document if it exists */
  update: SectionExhibitorContactFormUpdateInput
}

export type SectionExhibitorContactFormUpsertLocalizationInput = {
  create: SectionExhibitorContactFormCreateLocalizationDataInput
  locale: Locale
  update: SectionExhibitorContactFormUpdateLocalizationDataInput
}

export type SectionExhibitorContactFormUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionExhibitorContactFormUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExhibitorContactFormWhereUniqueInput
}

export type SectionExhibitorContactFormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionExhibitorContactFormUpsertInput
  /** Unique document search */
  where: SectionExhibitorContactFormWhereUniqueInput
}

/** Identifies documents */
export type SectionExhibitorContactFormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExhibitorContactFormWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  captchaPuzzleEndpoint?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  captchaPuzzleEndpoint_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  captchaPuzzleEndpoint_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  captchaPuzzleEndpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  captchaPuzzleEndpoint_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  captchaPuzzleEndpoint_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  captchaPuzzleEndpoint_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  captchaPuzzleEndpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  captchaPuzzleEndpoint_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  captchaPuzzleEndpoint_starts_with?: InputMaybe<Scalars['String']['input']>
  captchaSiteKey?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  captchaSiteKey_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  captchaSiteKey_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  captchaSiteKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  captchaSiteKey_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  captchaSiteKey_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  captchaSiteKey_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  captchaSiteKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  captchaSiteKey_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  captchaSiteKey_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionExhibitorContactForm record uniquely */
export type SectionExhibitorContactFormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionExhibitorsOptions {
  AllExhibitors = 'allExhibitors',
  PremiumExhibitors = 'premiumExhibitors',
  TopExhibitorsOnly = 'topExhibitorsOnly',
}

/** Section displaying items in columns with picture title description and optional call to action */
export type SectionExplanationBlocks = Entity & {
  __typename?: 'SectionExplanationBlocks'
  /** Text alignment centered */
  centered?: Maybe<Scalars['Boolean']['output']>
  /** Call to action element rendered below blocks */
  cta?: Maybe<CallToAction>
  /** Option to display section decorations, false by default */
  displayDecorations?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  items: Array<ExplanationStep>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionExplanationBlocks>
  /** Select custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  /** Section subtitle */
  subtitle?: Maybe<Scalars['String']['output']>
  /** Section title */
  title?: Maybe<Scalars['String']['output']>
}

/** Section displaying items in columns with picture title description and optional call to action */
export type SectionExplanationBlocksCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section displaying items in columns with picture title description and optional call to action */
export type SectionExplanationBlocksItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ExplanationStepOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ExplanationStepWhereInput>
}

/** Section displaying items in columns with picture title description and optional call to action */
export type SectionExplanationBlocksLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionExplanationBlocksConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionExplanationBlocksWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionExplanationBlocksConnection = {
  __typename?: 'SectionExplanationBlocksConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionExplanationBlocksEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionExplanationBlocksCreateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  items?: InputMaybe<ExplanationStepCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionExplanationBlocksCreateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksCreateLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksCreateLocalizationInput = {
  /** Localization input */
  data: SectionExplanationBlocksCreateLocalizationDataInput
  locale: Locale
}

export type SectionExplanationBlocksCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionExplanationBlocksCreateLocalizationInput>>
}

export type SectionExplanationBlocksCreateManyInlineInput = {
  /** Create and connect multiple existing SectionExplanationBlocks documents */
  create?: InputMaybe<Array<SectionExplanationBlocksCreateInput>>
}

export type SectionExplanationBlocksCreateOneInlineInput = {
  /** Create and connect one SectionExplanationBlocks document */
  create?: InputMaybe<SectionExplanationBlocksCreateInput>
}

export type SectionExplanationBlocksCreateWithPositionInput = {
  /** Document to create */
  data: SectionExplanationBlocksCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionExplanationBlocksEdge = {
  __typename?: 'SectionExplanationBlocksEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionExplanationBlocks
}

/** Identifies documents */
export type SectionExplanationBlocksManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayDecorations_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  items_every?: InputMaybe<ExplanationStepWhereInput>
  items_none?: InputMaybe<ExplanationStepWhereInput>
  items_some?: InputMaybe<ExplanationStepWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionExplanationBlocksOrderByInput {
  CenteredAsc = 'centered_ASC',
  CenteredDesc = 'centered_DESC',
  DisplayDecorationsAsc = 'displayDecorations_ASC',
  DisplayDecorationsDesc = 'displayDecorations_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionExplanationBlocksParent = EventPage | LandingPage

export type SectionExplanationBlocksParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionExplanationBlocksParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionExplanationBlocksParentCreateManyInlineInput = {
  /** Connect multiple existing SectionExplanationBlocksParent documents */
  connect?: InputMaybe<Array<SectionExplanationBlocksParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionExplanationBlocksParent documents */
  create?: InputMaybe<Array<SectionExplanationBlocksParentCreateInput>>
}

export type SectionExplanationBlocksParentCreateOneInlineInput = {
  /** Connect one existing SectionExplanationBlocksParent document */
  connect?: InputMaybe<SectionExplanationBlocksParentWhereUniqueInput>
  /** Create and connect one SectionExplanationBlocksParent document */
  create?: InputMaybe<SectionExplanationBlocksParentCreateInput>
}

export type SectionExplanationBlocksParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionExplanationBlocksParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionExplanationBlocksParent documents */
  connect?: InputMaybe<Array<SectionExplanationBlocksParentConnectInput>>
  /** Create and connect multiple SectionExplanationBlocksParent documents */
  create?: InputMaybe<Array<SectionExplanationBlocksParentCreateInput>>
  /** Delete multiple SectionExplanationBlocksParent documents */
  delete?: InputMaybe<Array<SectionExplanationBlocksParentWhereUniqueInput>>
  /** Disconnect multiple SectionExplanationBlocksParent documents */
  disconnect?: InputMaybe<Array<SectionExplanationBlocksParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionExplanationBlocksParent documents */
  set?: InputMaybe<Array<SectionExplanationBlocksParentWhereUniqueInput>>
  /** Update multiple SectionExplanationBlocksParent documents */
  update?: InputMaybe<Array<SectionExplanationBlocksParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionExplanationBlocksParent documents */
  upsert?: InputMaybe<Array<SectionExplanationBlocksParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionExplanationBlocksParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionExplanationBlocksParentUpdateOneInlineInput = {
  /** Connect existing SectionExplanationBlocksParent document */
  connect?: InputMaybe<SectionExplanationBlocksParentWhereUniqueInput>
  /** Create and connect one SectionExplanationBlocksParent document */
  create?: InputMaybe<SectionExplanationBlocksParentCreateInput>
  /** Delete currently connected SectionExplanationBlocksParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionExplanationBlocksParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExplanationBlocksParent document */
  update?: InputMaybe<SectionExplanationBlocksParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExplanationBlocksParent document */
  upsert?: InputMaybe<SectionExplanationBlocksParentUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationBlocksParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionExplanationBlocksParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationBlocksParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionExplanationBlocksParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionExplanationBlocksUpdateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  items?: InputMaybe<ExplanationStepUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionExplanationBlocksUpdateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksUpdateLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksUpdateLocalizationInput = {
  data: SectionExplanationBlocksUpdateLocalizationDataInput
  locale: Locale
}

export type SectionExplanationBlocksUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionExplanationBlocksCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExplanationBlocksUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionExplanationBlocksUpsertLocalizationInput>>
}

export type SectionExplanationBlocksUpdateManyInlineInput = {
  /** Create and connect multiple SectionExplanationBlocks component instances */
  create?: InputMaybe<Array<SectionExplanationBlocksCreateWithPositionInput>>
  /** Delete multiple SectionExplanationBlocks documents */
  delete?: InputMaybe<Array<SectionExplanationBlocksWhereUniqueInput>>
  /** Update multiple SectionExplanationBlocks component instances */
  update?: InputMaybe<Array<SectionExplanationBlocksUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionExplanationBlocks component instances */
  upsert?: InputMaybe<Array<SectionExplanationBlocksUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionExplanationBlocksUpdateManyInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionExplanationBlocksUpdateManyLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksUpdateManyLocalizationDataInput = {
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationBlocksUpdateManyLocalizationInput = {
  data: SectionExplanationBlocksUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionExplanationBlocksUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExplanationBlocksUpdateManyLocalizationInput>>
}

export type SectionExplanationBlocksUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionExplanationBlocksUpdateManyInput
  /** Document search */
  where: SectionExplanationBlocksWhereInput
}

export type SectionExplanationBlocksUpdateOneInlineInput = {
  /** Create and connect one SectionExplanationBlocks document */
  create?: InputMaybe<SectionExplanationBlocksCreateInput>
  /** Delete currently connected SectionExplanationBlocks document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExplanationBlocks document */
  update?: InputMaybe<SectionExplanationBlocksUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExplanationBlocks document */
  upsert?: InputMaybe<SectionExplanationBlocksUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationBlocksUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionExplanationBlocksUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExplanationBlocksWhereUniqueInput
}

export type SectionExplanationBlocksUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionExplanationBlocksUpdateInput
  /** Unique document search */
  where: SectionExplanationBlocksWhereUniqueInput
}

export type SectionExplanationBlocksUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionExplanationBlocksCreateInput
  /** Update document if it exists */
  update: SectionExplanationBlocksUpdateInput
}

export type SectionExplanationBlocksUpsertLocalizationInput = {
  create: SectionExplanationBlocksCreateLocalizationDataInput
  locale: Locale
  update: SectionExplanationBlocksUpdateLocalizationDataInput
}

export type SectionExplanationBlocksUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionExplanationBlocksUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExplanationBlocksWhereUniqueInput
}

export type SectionExplanationBlocksUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionExplanationBlocksUpsertInput
  /** Unique document search */
  where: SectionExplanationBlocksWhereUniqueInput
}

/** Identifies documents */
export type SectionExplanationBlocksWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExplanationBlocksWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  displayDecorations?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  displayDecorations_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  items_every?: InputMaybe<ExplanationStepWhereInput>
  items_none?: InputMaybe<ExplanationStepWhereInput>
  items_some?: InputMaybe<ExplanationStepWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionExplanationBlocks record uniquely */
export type SectionExplanationBlocksWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionExplanationStep = Entity & {
  __typename?: 'SectionExplanationStep'
  explanationSteps: Array<ExplanationStep>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionExplanationStep>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

export type SectionExplanationStepExplanationStepsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ExplanationStepOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ExplanationStepWhereInput>
}

export type SectionExplanationStepLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionExplanationStepConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionExplanationStepWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionExplanationStepConnection = {
  __typename?: 'SectionExplanationStepConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionExplanationStepEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionExplanationStepCreateInput = {
  explanationSteps?: InputMaybe<ExplanationStepCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionExplanationStepCreateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepCreateLocalizationInput = {
  /** Localization input */
  data: SectionExplanationStepCreateLocalizationDataInput
  locale: Locale
}

export type SectionExplanationStepCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionExplanationStepCreateLocalizationInput>>
}

export type SectionExplanationStepCreateManyInlineInput = {
  /** Create and connect multiple existing SectionExplanationStep documents */
  create?: InputMaybe<Array<SectionExplanationStepCreateInput>>
}

export type SectionExplanationStepCreateOneInlineInput = {
  /** Create and connect one SectionExplanationStep document */
  create?: InputMaybe<SectionExplanationStepCreateInput>
}

export type SectionExplanationStepCreateWithPositionInput = {
  /** Document to create */
  data: SectionExplanationStepCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionExplanationStepEdge = {
  __typename?: 'SectionExplanationStepEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionExplanationStep
}

/** Identifies documents */
export type SectionExplanationStepManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  explanationSteps_every?: InputMaybe<ExplanationStepWhereInput>
  explanationSteps_none?: InputMaybe<ExplanationStepWhereInput>
  explanationSteps_some?: InputMaybe<ExplanationStepWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionExplanationStepOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionExplanationStepParent = LandingPage

export type SectionExplanationStepParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionExplanationStepParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionExplanationStepParentCreateManyInlineInput = {
  /** Connect multiple existing SectionExplanationStepParent documents */
  connect?: InputMaybe<Array<SectionExplanationStepParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionExplanationStepParent documents */
  create?: InputMaybe<Array<SectionExplanationStepParentCreateInput>>
}

export type SectionExplanationStepParentCreateOneInlineInput = {
  /** Connect one existing SectionExplanationStepParent document */
  connect?: InputMaybe<SectionExplanationStepParentWhereUniqueInput>
  /** Create and connect one SectionExplanationStepParent document */
  create?: InputMaybe<SectionExplanationStepParentCreateInput>
}

export type SectionExplanationStepParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionExplanationStepParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionExplanationStepParent documents */
  connect?: InputMaybe<Array<SectionExplanationStepParentConnectInput>>
  /** Create and connect multiple SectionExplanationStepParent documents */
  create?: InputMaybe<Array<SectionExplanationStepParentCreateInput>>
  /** Delete multiple SectionExplanationStepParent documents */
  delete?: InputMaybe<Array<SectionExplanationStepParentWhereUniqueInput>>
  /** Disconnect multiple SectionExplanationStepParent documents */
  disconnect?: InputMaybe<Array<SectionExplanationStepParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionExplanationStepParent documents */
  set?: InputMaybe<Array<SectionExplanationStepParentWhereUniqueInput>>
  /** Update multiple SectionExplanationStepParent documents */
  update?: InputMaybe<Array<SectionExplanationStepParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionExplanationStepParent documents */
  upsert?: InputMaybe<Array<SectionExplanationStepParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionExplanationStepParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionExplanationStepParentUpdateOneInlineInput = {
  /** Connect existing SectionExplanationStepParent document */
  connect?: InputMaybe<SectionExplanationStepParentWhereUniqueInput>
  /** Create and connect one SectionExplanationStepParent document */
  create?: InputMaybe<SectionExplanationStepParentCreateInput>
  /** Delete currently connected SectionExplanationStepParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionExplanationStepParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExplanationStepParent document */
  update?: InputMaybe<SectionExplanationStepParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExplanationStepParent document */
  upsert?: InputMaybe<SectionExplanationStepParentUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationStepParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionExplanationStepParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationStepParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionExplanationStepParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionExplanationStepUpdateInput = {
  explanationSteps?: InputMaybe<ExplanationStepUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionExplanationStepUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepUpdateLocalizationInput = {
  data: SectionExplanationStepUpdateLocalizationDataInput
  locale: Locale
}

export type SectionExplanationStepUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionExplanationStepCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExplanationStepUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionExplanationStepUpsertLocalizationInput>>
}

export type SectionExplanationStepUpdateManyInlineInput = {
  /** Create and connect multiple SectionExplanationStep component instances */
  create?: InputMaybe<Array<SectionExplanationStepCreateWithPositionInput>>
  /** Delete multiple SectionExplanationStep documents */
  delete?: InputMaybe<Array<SectionExplanationStepWhereUniqueInput>>
  /** Update multiple SectionExplanationStep component instances */
  update?: InputMaybe<Array<SectionExplanationStepUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionExplanationStep component instances */
  upsert?: InputMaybe<Array<SectionExplanationStepUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionExplanationStepUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionExplanationStepUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionExplanationStepUpdateManyLocalizationInput = {
  data: SectionExplanationStepUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionExplanationStepUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionExplanationStepUpdateManyLocalizationInput>>
}

export type SectionExplanationStepUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionExplanationStepUpdateManyInput
  /** Document search */
  where: SectionExplanationStepWhereInput
}

export type SectionExplanationStepUpdateOneInlineInput = {
  /** Create and connect one SectionExplanationStep document */
  create?: InputMaybe<SectionExplanationStepCreateInput>
  /** Delete currently connected SectionExplanationStep document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionExplanationStep document */
  update?: InputMaybe<SectionExplanationStepUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionExplanationStep document */
  upsert?: InputMaybe<SectionExplanationStepUpsertWithNestedWhereUniqueInput>
}

export type SectionExplanationStepUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionExplanationStepUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExplanationStepWhereUniqueInput
}

export type SectionExplanationStepUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionExplanationStepUpdateInput
  /** Unique document search */
  where: SectionExplanationStepWhereUniqueInput
}

export type SectionExplanationStepUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionExplanationStepCreateInput
  /** Update document if it exists */
  update: SectionExplanationStepUpdateInput
}

export type SectionExplanationStepUpsertLocalizationInput = {
  create: SectionExplanationStepCreateLocalizationDataInput
  locale: Locale
  update: SectionExplanationStepUpdateLocalizationDataInput
}

export type SectionExplanationStepUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionExplanationStepUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionExplanationStepWhereUniqueInput
}

export type SectionExplanationStepUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionExplanationStepUpsertInput
  /** Unique document search */
  where: SectionExplanationStepWhereUniqueInput
}

/** Identifies documents */
export type SectionExplanationStepWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionExplanationStepWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  explanationSteps_every?: InputMaybe<ExplanationStepWhereInput>
  explanationSteps_none?: InputMaybe<ExplanationStepWhereInput>
  explanationSteps_some?: InputMaybe<ExplanationStepWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionExplanationStep record uniquely */
export type SectionExplanationStepWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionFestivalHero = Entity & {
  __typename?: 'SectionFestivalHero'
  background: Asset
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionFestivalHero>
  /** Logo for display over the Hero */
  logo?: Maybe<Asset>
  /** Optional Background for Mobile Screens */
  mobileBackground?: Maybe<Asset>
  secondTitle?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
  /** Decoration which appear attached to the title */
  titleDecoration?: Maybe<Asset>
}

export type SectionFestivalHeroBackgroundArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFestivalHeroLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionFestivalHeroLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFestivalHeroMobileBackgroundArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFestivalHeroTitleDecorationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFestivalHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionFestivalHeroWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionFestivalHeroConnection = {
  __typename?: 'SectionFestivalHeroConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionFestivalHeroEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionFestivalHeroCreateInput = {
  background: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionFestivalHeroCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  mobileBackground?: InputMaybe<AssetCreateOneInlineInput>
  /** secondTitle input for default locale (de) */
  secondTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetCreateOneInlineInput>
}

export type SectionFestivalHeroCreateLocalizationDataInput = {
  secondTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalHeroCreateLocalizationInput = {
  /** Localization input */
  data: SectionFestivalHeroCreateLocalizationDataInput
  locale: Locale
}

export type SectionFestivalHeroCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionFestivalHeroCreateLocalizationInput>>
}

export type SectionFestivalHeroCreateManyInlineInput = {
  /** Create and connect multiple existing SectionFestivalHero documents */
  create?: InputMaybe<Array<SectionFestivalHeroCreateInput>>
}

export type SectionFestivalHeroCreateOneInlineInput = {
  /** Create and connect one SectionFestivalHero document */
  create?: InputMaybe<SectionFestivalHeroCreateInput>
}

export type SectionFestivalHeroCreateWithPositionInput = {
  /** Document to create */
  data: SectionFestivalHeroCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionFestivalHeroEdge = {
  __typename?: 'SectionFestivalHeroEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionFestivalHero
}

/** Identifies documents */
export type SectionFestivalHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileBackground?: InputMaybe<AssetWhereInput>
  titleDecoration?: InputMaybe<AssetWhereInput>
}

export enum SectionFestivalHeroOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SecondTitleAsc = 'secondTitle_ASC',
  SecondTitleDesc = 'secondTitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionFestivalHeroParent = EventPage

export type SectionFestivalHeroParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionFestivalHeroParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionFestivalHeroParentCreateManyInlineInput = {
  /** Connect multiple existing SectionFestivalHeroParent documents */
  connect?: InputMaybe<Array<SectionFestivalHeroParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionFestivalHeroParent documents */
  create?: InputMaybe<Array<SectionFestivalHeroParentCreateInput>>
}

export type SectionFestivalHeroParentCreateOneInlineInput = {
  /** Connect one existing SectionFestivalHeroParent document */
  connect?: InputMaybe<SectionFestivalHeroParentWhereUniqueInput>
  /** Create and connect one SectionFestivalHeroParent document */
  create?: InputMaybe<SectionFestivalHeroParentCreateInput>
}

export type SectionFestivalHeroParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionFestivalHeroParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionFestivalHeroParent documents */
  connect?: InputMaybe<Array<SectionFestivalHeroParentConnectInput>>
  /** Create and connect multiple SectionFestivalHeroParent documents */
  create?: InputMaybe<Array<SectionFestivalHeroParentCreateInput>>
  /** Delete multiple SectionFestivalHeroParent documents */
  delete?: InputMaybe<Array<SectionFestivalHeroParentWhereUniqueInput>>
  /** Disconnect multiple SectionFestivalHeroParent documents */
  disconnect?: InputMaybe<Array<SectionFestivalHeroParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionFestivalHeroParent documents */
  set?: InputMaybe<Array<SectionFestivalHeroParentWhereUniqueInput>>
  /** Update multiple SectionFestivalHeroParent documents */
  update?: InputMaybe<Array<SectionFestivalHeroParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionFestivalHeroParent documents */
  upsert?: InputMaybe<Array<SectionFestivalHeroParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionFestivalHeroParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionFestivalHeroParentUpdateOneInlineInput = {
  /** Connect existing SectionFestivalHeroParent document */
  connect?: InputMaybe<SectionFestivalHeroParentWhereUniqueInput>
  /** Create and connect one SectionFestivalHeroParent document */
  create?: InputMaybe<SectionFestivalHeroParentCreateInput>
  /** Delete currently connected SectionFestivalHeroParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionFestivalHeroParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFestivalHeroParent document */
  update?: InputMaybe<SectionFestivalHeroParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFestivalHeroParent document */
  upsert?: InputMaybe<SectionFestivalHeroParentUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalHeroParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionFestivalHeroParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalHeroParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionFestivalHeroParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionFestivalHeroUpdateInput = {
  background?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionFestivalHeroUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  mobileBackground?: InputMaybe<AssetUpdateOneInlineInput>
  /** secondTitle input for default locale (de) */
  secondTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetUpdateOneInlineInput>
}

export type SectionFestivalHeroUpdateLocalizationDataInput = {
  secondTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalHeroUpdateLocalizationInput = {
  data: SectionFestivalHeroUpdateLocalizationDataInput
  locale: Locale
}

export type SectionFestivalHeroUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionFestivalHeroCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFestivalHeroUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionFestivalHeroUpsertLocalizationInput>>
}

export type SectionFestivalHeroUpdateManyInlineInput = {
  /** Create and connect multiple SectionFestivalHero component instances */
  create?: InputMaybe<Array<SectionFestivalHeroCreateWithPositionInput>>
  /** Delete multiple SectionFestivalHero documents */
  delete?: InputMaybe<Array<SectionFestivalHeroWhereUniqueInput>>
  /** Update multiple SectionFestivalHero component instances */
  update?: InputMaybe<Array<SectionFestivalHeroUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionFestivalHero component instances */
  upsert?: InputMaybe<Array<SectionFestivalHeroUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionFestivalHeroUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionFestivalHeroUpdateManyLocalizationsInput>
  /** secondTitle input for default locale (de) */
  secondTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalHeroUpdateManyLocalizationDataInput = {
  secondTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalHeroUpdateManyLocalizationInput = {
  data: SectionFestivalHeroUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionFestivalHeroUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFestivalHeroUpdateManyLocalizationInput>>
}

export type SectionFestivalHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionFestivalHeroUpdateManyInput
  /** Document search */
  where: SectionFestivalHeroWhereInput
}

export type SectionFestivalHeroUpdateOneInlineInput = {
  /** Create and connect one SectionFestivalHero document */
  create?: InputMaybe<SectionFestivalHeroCreateInput>
  /** Delete currently connected SectionFestivalHero document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFestivalHero document */
  update?: InputMaybe<SectionFestivalHeroUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFestivalHero document */
  upsert?: InputMaybe<SectionFestivalHeroUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalHeroUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionFestivalHeroUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFestivalHeroWhereUniqueInput
}

export type SectionFestivalHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionFestivalHeroUpdateInput
  /** Unique document search */
  where: SectionFestivalHeroWhereUniqueInput
}

export type SectionFestivalHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionFestivalHeroCreateInput
  /** Update document if it exists */
  update: SectionFestivalHeroUpdateInput
}

export type SectionFestivalHeroUpsertLocalizationInput = {
  create: SectionFestivalHeroCreateLocalizationDataInput
  locale: Locale
  update: SectionFestivalHeroUpdateLocalizationDataInput
}

export type SectionFestivalHeroUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionFestivalHeroUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFestivalHeroWhereUniqueInput
}

export type SectionFestivalHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionFestivalHeroUpsertInput
  /** Unique document search */
  where: SectionFestivalHeroWhereUniqueInput
}

/** Identifies documents */
export type SectionFestivalHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFestivalHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileBackground?: InputMaybe<AssetWhereInput>
  secondTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  secondTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  secondTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  secondTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  secondTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  secondTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  secondTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  secondTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  secondTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  secondTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetWhereInput>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionFestivalHero record uniquely */
export type SectionFestivalHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Shows timetable for Festival stages in 2D and list view modes. */
export type SectionFestivalTimetable = Entity & {
  __typename?: 'SectionFestivalTimetable'
  /** Call to action element */
  cta?: Maybe<CallToAction>
  /** Provide day to preselect in yyyy-MM-dd format */
  dayPreselect?: Maybe<Scalars['String']['output']>
  /** If value set, default view mode will be applied to festival timetable. If not set, when showing view swithcher default is Grid, otherwise list. */
  defaultViewMode?: Maybe<TimetableViewModes>
  eventStage?: Maybe<EventStage>
  /** If value set, only those exhibitors will be shown that has corresponding event type associated to them. Default is 'stage'. */
  eventType?: Maybe<SpeakerEventTypes>
  hideViewSwitcher?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionFestivalTimetable>
  optionalDescription?: Maybe<Scalars['String']['output']>
  optionalTitle?: Maybe<Scalars['String']['output']>
  pageSize?: Maybe<Scalars['Int']['output']>
  /** System stage field */
  stage: Stage
  /** If value set, tabs will be shown according to the selected setting. If no value, tab filter is not visible. */
  tabFilterType?: Maybe<TimetableTabFilterTypes>
}

/** Shows timetable for Festival stages in 2D and list view modes. */
export type SectionFestivalTimetableCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Shows timetable for Festival stages in 2D and list view modes. */
export type SectionFestivalTimetableEventStageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Shows timetable for Festival stages in 2D and list view modes. */
export type SectionFestivalTimetableLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionFestivalTimetableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionFestivalTimetableWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionFestivalTimetableConnection = {
  __typename?: 'SectionFestivalTimetableConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionFestivalTimetableEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionFestivalTimetableCreateInput = {
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  defaultViewMode?: InputMaybe<TimetableViewModes>
  eventStage?: InputMaybe<EventStageCreateOneInlineInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideViewSwitcher?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionFestivalTimetableCreateLocalizationsInput>
  /** optionalDescription input for default locale (de) */
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  /** optionalTitle input for default locale (de) */
  optionalTitle?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  tabFilterType?: InputMaybe<TimetableTabFilterTypes>
}

export type SectionFestivalTimetableCreateLocalizationDataInput = {
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  optionalTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalTimetableCreateLocalizationInput = {
  /** Localization input */
  data: SectionFestivalTimetableCreateLocalizationDataInput
  locale: Locale
}

export type SectionFestivalTimetableCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionFestivalTimetableCreateLocalizationInput>>
}

export type SectionFestivalTimetableCreateManyInlineInput = {
  /** Create and connect multiple existing SectionFestivalTimetable documents */
  create?: InputMaybe<Array<SectionFestivalTimetableCreateInput>>
}

export type SectionFestivalTimetableCreateOneInlineInput = {
  /** Create and connect one SectionFestivalTimetable document */
  create?: InputMaybe<SectionFestivalTimetableCreateInput>
}

export type SectionFestivalTimetableCreateWithPositionInput = {
  /** Document to create */
  data: SectionFestivalTimetableCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionFestivalTimetableEdge = {
  __typename?: 'SectionFestivalTimetableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionFestivalTimetable
}

/** Identifies documents */
export type SectionFestivalTimetableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  dayPreselect_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  dayPreselect_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  dayPreselect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  dayPreselect_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  dayPreselect_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  dayPreselect_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  dayPreselect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  dayPreselect_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  dayPreselect_starts_with?: InputMaybe<Scalars['String']['input']>
  defaultViewMode?: InputMaybe<TimetableViewModes>
  /** All values that are contained in given list. */
  defaultViewMode_in?: InputMaybe<Array<InputMaybe<TimetableViewModes>>>
  /** Any other value that exists and is not equal to the given value. */
  defaultViewMode_not?: InputMaybe<TimetableViewModes>
  /** All values that are not contained in given list. */
  defaultViewMode_not_in?: InputMaybe<Array<InputMaybe<TimetableViewModes>>>
  eventStage?: InputMaybe<EventStageWhereInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  hideViewSwitcher?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideViewSwitcher_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageSize_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageSize_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageSize_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageSize_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageSize_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  tabFilterType?: InputMaybe<TimetableTabFilterTypes>
  /** All values that are contained in given list. */
  tabFilterType_in?: InputMaybe<Array<InputMaybe<TimetableTabFilterTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  tabFilterType_not?: InputMaybe<TimetableTabFilterTypes>
  /** All values that are not contained in given list. */
  tabFilterType_not_in?: InputMaybe<Array<InputMaybe<TimetableTabFilterTypes>>>
}

export enum SectionFestivalTimetableOrderByInput {
  DayPreselectAsc = 'dayPreselect_ASC',
  DayPreselectDesc = 'dayPreselect_DESC',
  DefaultViewModeAsc = 'defaultViewMode_ASC',
  DefaultViewModeDesc = 'defaultViewMode_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  HideViewSwitcherAsc = 'hideViewSwitcher_ASC',
  HideViewSwitcherDesc = 'hideViewSwitcher_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OptionalDescriptionAsc = 'optionalDescription_ASC',
  OptionalDescriptionDesc = 'optionalDescription_DESC',
  OptionalTitleAsc = 'optionalTitle_ASC',
  OptionalTitleDesc = 'optionalTitle_DESC',
  PageSizeAsc = 'pageSize_ASC',
  PageSizeDesc = 'pageSize_DESC',
  TabFilterTypeAsc = 'tabFilterType_ASC',
  TabFilterTypeDesc = 'tabFilterType_DESC',
}

export type SectionFestivalTimetableParent = EventPage

export type SectionFestivalTimetableParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionFestivalTimetableParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionFestivalTimetableParentCreateManyInlineInput = {
  /** Connect multiple existing SectionFestivalTimetableParent documents */
  connect?: InputMaybe<Array<SectionFestivalTimetableParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionFestivalTimetableParent documents */
  create?: InputMaybe<Array<SectionFestivalTimetableParentCreateInput>>
}

export type SectionFestivalTimetableParentCreateOneInlineInput = {
  /** Connect one existing SectionFestivalTimetableParent document */
  connect?: InputMaybe<SectionFestivalTimetableParentWhereUniqueInput>
  /** Create and connect one SectionFestivalTimetableParent document */
  create?: InputMaybe<SectionFestivalTimetableParentCreateInput>
}

export type SectionFestivalTimetableParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionFestivalTimetableParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionFestivalTimetableParent documents */
  connect?: InputMaybe<Array<SectionFestivalTimetableParentConnectInput>>
  /** Create and connect multiple SectionFestivalTimetableParent documents */
  create?: InputMaybe<Array<SectionFestivalTimetableParentCreateInput>>
  /** Delete multiple SectionFestivalTimetableParent documents */
  delete?: InputMaybe<Array<SectionFestivalTimetableParentWhereUniqueInput>>
  /** Disconnect multiple SectionFestivalTimetableParent documents */
  disconnect?: InputMaybe<Array<SectionFestivalTimetableParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionFestivalTimetableParent documents */
  set?: InputMaybe<Array<SectionFestivalTimetableParentWhereUniqueInput>>
  /** Update multiple SectionFestivalTimetableParent documents */
  update?: InputMaybe<Array<SectionFestivalTimetableParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionFestivalTimetableParent documents */
  upsert?: InputMaybe<Array<SectionFestivalTimetableParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionFestivalTimetableParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionFestivalTimetableParentUpdateOneInlineInput = {
  /** Connect existing SectionFestivalTimetableParent document */
  connect?: InputMaybe<SectionFestivalTimetableParentWhereUniqueInput>
  /** Create and connect one SectionFestivalTimetableParent document */
  create?: InputMaybe<SectionFestivalTimetableParentCreateInput>
  /** Delete currently connected SectionFestivalTimetableParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionFestivalTimetableParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFestivalTimetableParent document */
  update?: InputMaybe<SectionFestivalTimetableParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFestivalTimetableParent document */
  upsert?: InputMaybe<SectionFestivalTimetableParentUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalTimetableParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionFestivalTimetableParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalTimetableParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionFestivalTimetableParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionFestivalTimetableUpdateInput = {
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  defaultViewMode?: InputMaybe<TimetableViewModes>
  eventStage?: InputMaybe<EventStageUpdateOneInlineInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideViewSwitcher?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionFestivalTimetableUpdateLocalizationsInput>
  /** optionalDescription input for default locale (de) */
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  /** optionalTitle input for default locale (de) */
  optionalTitle?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  tabFilterType?: InputMaybe<TimetableTabFilterTypes>
}

export type SectionFestivalTimetableUpdateLocalizationDataInput = {
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  optionalTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalTimetableUpdateLocalizationInput = {
  data: SectionFestivalTimetableUpdateLocalizationDataInput
  locale: Locale
}

export type SectionFestivalTimetableUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionFestivalTimetableCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFestivalTimetableUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionFestivalTimetableUpsertLocalizationInput>>
}

export type SectionFestivalTimetableUpdateManyInlineInput = {
  /** Create and connect multiple SectionFestivalTimetable component instances */
  create?: InputMaybe<Array<SectionFestivalTimetableCreateWithPositionInput>>
  /** Delete multiple SectionFestivalTimetable documents */
  delete?: InputMaybe<Array<SectionFestivalTimetableWhereUniqueInput>>
  /** Update multiple SectionFestivalTimetable component instances */
  update?: InputMaybe<Array<SectionFestivalTimetableUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionFestivalTimetable component instances */
  upsert?: InputMaybe<Array<SectionFestivalTimetableUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionFestivalTimetableUpdateManyInput = {
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  defaultViewMode?: InputMaybe<TimetableViewModes>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideViewSwitcher?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionFestivalTimetableUpdateManyLocalizationsInput>
  /** optionalDescription input for default locale (de) */
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  /** optionalTitle input for default locale (de) */
  optionalTitle?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  tabFilterType?: InputMaybe<TimetableTabFilterTypes>
}

export type SectionFestivalTimetableUpdateManyLocalizationDataInput = {
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  optionalTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionFestivalTimetableUpdateManyLocalizationInput = {
  data: SectionFestivalTimetableUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionFestivalTimetableUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFestivalTimetableUpdateManyLocalizationInput>>
}

export type SectionFestivalTimetableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionFestivalTimetableUpdateManyInput
  /** Document search */
  where: SectionFestivalTimetableWhereInput
}

export type SectionFestivalTimetableUpdateOneInlineInput = {
  /** Create and connect one SectionFestivalTimetable document */
  create?: InputMaybe<SectionFestivalTimetableCreateInput>
  /** Delete currently connected SectionFestivalTimetable document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFestivalTimetable document */
  update?: InputMaybe<SectionFestivalTimetableUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFestivalTimetable document */
  upsert?: InputMaybe<SectionFestivalTimetableUpsertWithNestedWhereUniqueInput>
}

export type SectionFestivalTimetableUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionFestivalTimetableUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFestivalTimetableWhereUniqueInput
}

export type SectionFestivalTimetableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionFestivalTimetableUpdateInput
  /** Unique document search */
  where: SectionFestivalTimetableWhereUniqueInput
}

export type SectionFestivalTimetableUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionFestivalTimetableCreateInput
  /** Update document if it exists */
  update: SectionFestivalTimetableUpdateInput
}

export type SectionFestivalTimetableUpsertLocalizationInput = {
  create: SectionFestivalTimetableCreateLocalizationDataInput
  locale: Locale
  update: SectionFestivalTimetableUpdateLocalizationDataInput
}

export type SectionFestivalTimetableUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionFestivalTimetableUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFestivalTimetableWhereUniqueInput
}

export type SectionFestivalTimetableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionFestivalTimetableUpsertInput
  /** Unique document search */
  where: SectionFestivalTimetableWhereUniqueInput
}

/** Identifies documents */
export type SectionFestivalTimetableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFestivalTimetableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  dayPreselect?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  dayPreselect_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  dayPreselect_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  dayPreselect_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  dayPreselect_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  dayPreselect_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  dayPreselect_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  dayPreselect_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  dayPreselect_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  dayPreselect_starts_with?: InputMaybe<Scalars['String']['input']>
  defaultViewMode?: InputMaybe<TimetableViewModes>
  /** All values that are contained in given list. */
  defaultViewMode_in?: InputMaybe<Array<InputMaybe<TimetableViewModes>>>
  /** Any other value that exists and is not equal to the given value. */
  defaultViewMode_not?: InputMaybe<TimetableViewModes>
  /** All values that are not contained in given list. */
  defaultViewMode_not_in?: InputMaybe<Array<InputMaybe<TimetableViewModes>>>
  eventStage?: InputMaybe<EventStageWhereInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  hideViewSwitcher?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideViewSwitcher_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  optionalDescription?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  optionalDescription_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  optionalDescription_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  optionalDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  optionalDescription_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  optionalDescription_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  optionalDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  optionalDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  optionalDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  optionalDescription_starts_with?: InputMaybe<Scalars['String']['input']>
  optionalTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  optionalTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  optionalTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  optionalTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  optionalTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  optionalTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  optionalTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  optionalTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  optionalTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  optionalTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  pageSize?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  pageSize_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  pageSize_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  pageSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  pageSize_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  pageSize_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  pageSize_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  pageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  tabFilterType?: InputMaybe<TimetableTabFilterTypes>
  /** All values that are contained in given list. */
  tabFilterType_in?: InputMaybe<Array<InputMaybe<TimetableTabFilterTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  tabFilterType_not?: InputMaybe<TimetableTabFilterTypes>
  /** All values that are not contained in given list. */
  tabFilterType_not_in?: InputMaybe<Array<InputMaybe<TimetableTabFilterTypes>>>
}

/** References SectionFestivalTimetable record uniquely */
export type SectionFestivalTimetableWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionFramesHero = Entity & {
  __typename?: 'SectionFramesHero'
  /** Decoration image indside the content area on the left side */
  contentBackgroundLeft?: Maybe<Asset>
  /** Decoration image indside the content area on the right side */
  contentBackgroundRight?: Maybe<Asset>
  /** The unique identifier */
  id: Scalars['ID']['output']
  intro?: Maybe<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionFramesHero>
  /** Logo to appear above title */
  logo?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

export type SectionFramesHeroContentBackgroundLeftArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFramesHeroContentBackgroundRightArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFramesHeroLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionFramesHeroLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionFramesHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionFramesHeroWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionFramesHeroConnection = {
  __typename?: 'SectionFramesHeroConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionFramesHeroEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionFramesHeroCreateInput = {
  contentBackgroundLeft?: InputMaybe<AssetCreateOneInlineInput>
  contentBackgroundRight?: InputMaybe<AssetCreateOneInlineInput>
  /** intro input for default locale (de) */
  intro?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionFramesHeroCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroCreateLocalizationDataInput = {
  intro?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroCreateLocalizationInput = {
  /** Localization input */
  data: SectionFramesHeroCreateLocalizationDataInput
  locale: Locale
}

export type SectionFramesHeroCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionFramesHeroCreateLocalizationInput>>
}

export type SectionFramesHeroCreateManyInlineInput = {
  /** Create and connect multiple existing SectionFramesHero documents */
  create?: InputMaybe<Array<SectionFramesHeroCreateInput>>
}

export type SectionFramesHeroCreateOneInlineInput = {
  /** Create and connect one SectionFramesHero document */
  create?: InputMaybe<SectionFramesHeroCreateInput>
}

export type SectionFramesHeroCreateWithPositionInput = {
  /** Document to create */
  data: SectionFramesHeroCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionFramesHeroEdge = {
  __typename?: 'SectionFramesHeroEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionFramesHero
}

/** Identifies documents */
export type SectionFramesHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  contentBackgroundLeft?: InputMaybe<AssetWhereInput>
  contentBackgroundRight?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionFramesHeroOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntroAsc = 'intro_ASC',
  IntroDesc = 'intro_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionFramesHeroParent = LandingPage

export type SectionFramesHeroParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionFramesHeroParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionFramesHeroParentCreateManyInlineInput = {
  /** Connect multiple existing SectionFramesHeroParent documents */
  connect?: InputMaybe<Array<SectionFramesHeroParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionFramesHeroParent documents */
  create?: InputMaybe<Array<SectionFramesHeroParentCreateInput>>
}

export type SectionFramesHeroParentCreateOneInlineInput = {
  /** Connect one existing SectionFramesHeroParent document */
  connect?: InputMaybe<SectionFramesHeroParentWhereUniqueInput>
  /** Create and connect one SectionFramesHeroParent document */
  create?: InputMaybe<SectionFramesHeroParentCreateInput>
}

export type SectionFramesHeroParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionFramesHeroParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionFramesHeroParent documents */
  connect?: InputMaybe<Array<SectionFramesHeroParentConnectInput>>
  /** Create and connect multiple SectionFramesHeroParent documents */
  create?: InputMaybe<Array<SectionFramesHeroParentCreateInput>>
  /** Delete multiple SectionFramesHeroParent documents */
  delete?: InputMaybe<Array<SectionFramesHeroParentWhereUniqueInput>>
  /** Disconnect multiple SectionFramesHeroParent documents */
  disconnect?: InputMaybe<Array<SectionFramesHeroParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionFramesHeroParent documents */
  set?: InputMaybe<Array<SectionFramesHeroParentWhereUniqueInput>>
  /** Update multiple SectionFramesHeroParent documents */
  update?: InputMaybe<Array<SectionFramesHeroParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionFramesHeroParent documents */
  upsert?: InputMaybe<Array<SectionFramesHeroParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionFramesHeroParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionFramesHeroParentUpdateOneInlineInput = {
  /** Connect existing SectionFramesHeroParent document */
  connect?: InputMaybe<SectionFramesHeroParentWhereUniqueInput>
  /** Create and connect one SectionFramesHeroParent document */
  create?: InputMaybe<SectionFramesHeroParentCreateInput>
  /** Delete currently connected SectionFramesHeroParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionFramesHeroParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFramesHeroParent document */
  update?: InputMaybe<SectionFramesHeroParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFramesHeroParent document */
  upsert?: InputMaybe<SectionFramesHeroParentUpsertWithNestedWhereUniqueInput>
}

export type SectionFramesHeroParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionFramesHeroParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionFramesHeroParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionFramesHeroParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionFramesHeroUpdateInput = {
  contentBackgroundLeft?: InputMaybe<AssetUpdateOneInlineInput>
  contentBackgroundRight?: InputMaybe<AssetUpdateOneInlineInput>
  /** intro input for default locale (de) */
  intro?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionFramesHeroUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroUpdateLocalizationDataInput = {
  intro?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroUpdateLocalizationInput = {
  data: SectionFramesHeroUpdateLocalizationDataInput
  locale: Locale
}

export type SectionFramesHeroUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionFramesHeroCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFramesHeroUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionFramesHeroUpsertLocalizationInput>>
}

export type SectionFramesHeroUpdateManyInlineInput = {
  /** Create and connect multiple SectionFramesHero component instances */
  create?: InputMaybe<Array<SectionFramesHeroCreateWithPositionInput>>
  /** Delete multiple SectionFramesHero documents */
  delete?: InputMaybe<Array<SectionFramesHeroWhereUniqueInput>>
  /** Update multiple SectionFramesHero component instances */
  update?: InputMaybe<Array<SectionFramesHeroUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionFramesHero component instances */
  upsert?: InputMaybe<Array<SectionFramesHeroUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionFramesHeroUpdateManyInput = {
  /** intro input for default locale (de) */
  intro?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionFramesHeroUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroUpdateManyLocalizationDataInput = {
  intro?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionFramesHeroUpdateManyLocalizationInput = {
  data: SectionFramesHeroUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionFramesHeroUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionFramesHeroUpdateManyLocalizationInput>>
}

export type SectionFramesHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionFramesHeroUpdateManyInput
  /** Document search */
  where: SectionFramesHeroWhereInput
}

export type SectionFramesHeroUpdateOneInlineInput = {
  /** Create and connect one SectionFramesHero document */
  create?: InputMaybe<SectionFramesHeroCreateInput>
  /** Delete currently connected SectionFramesHero document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionFramesHero document */
  update?: InputMaybe<SectionFramesHeroUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionFramesHero document */
  upsert?: InputMaybe<SectionFramesHeroUpsertWithNestedWhereUniqueInput>
}

export type SectionFramesHeroUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionFramesHeroUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFramesHeroWhereUniqueInput
}

export type SectionFramesHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionFramesHeroUpdateInput
  /** Unique document search */
  where: SectionFramesHeroWhereUniqueInput
}

export type SectionFramesHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionFramesHeroCreateInput
  /** Update document if it exists */
  update: SectionFramesHeroUpdateInput
}

export type SectionFramesHeroUpsertLocalizationInput = {
  create: SectionFramesHeroCreateLocalizationDataInput
  locale: Locale
  update: SectionFramesHeroUpdateLocalizationDataInput
}

export type SectionFramesHeroUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionFramesHeroUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionFramesHeroWhereUniqueInput
}

export type SectionFramesHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionFramesHeroUpsertInput
  /** Unique document search */
  where: SectionFramesHeroWhereUniqueInput
}

/** Identifies documents */
export type SectionFramesHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFramesHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  contentBackgroundLeft?: InputMaybe<AssetWhereInput>
  contentBackgroundRight?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  intro?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  intro_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  intro_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  intro_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  intro_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  intro_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  intro_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  intro_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionFramesHero record uniquely */
export type SectionFramesHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionHero = Entity & {
  __typename?: 'SectionHero'
  background: Asset
  /** Half screen height component with no subhead and description. */
  compact?: Maybe<Scalars['Boolean']['output']>
  /** Call to action element rendered below text content */
  cta?: Maybe<CallToAction>
  ctaOnMobileOnly?: Maybe<Scalars['Boolean']['output']>
  /** CTA will be rendered under the text component. */
  ctaSecondary?: Maybe<CallToAction>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionHero>
  /** Logo for display over the Hero */
  logo?: Maybe<Asset>
  /** Optional Background for Mobile Screens */
  mobileBackground?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  subtitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  titleDecoration?: Maybe<Asset>
  titleIsTransparent?: Maybe<Scalars['Boolean']['output']>
  video?: Maybe<Asset>
}

export type SectionHeroBackgroundArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroCtaSecondaryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionHeroLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroMobileBackgroundArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroTitleDecorationArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroVideoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionHeroWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionHeroConnection = {
  __typename?: 'SectionHeroConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionHeroEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionHeroCreateInput = {
  background: AssetCreateOneInlineInput
  compact?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  ctaOnMobileOnly?: InputMaybe<Scalars['Boolean']['input']>
  ctaSecondary?: InputMaybe<CallToActionCreateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionHeroCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  mobileBackground?: InputMaybe<AssetCreateOneInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetCreateOneInlineInput>
  titleIsTransparent?: InputMaybe<Scalars['Boolean']['input']>
  video?: InputMaybe<AssetCreateOneInlineInput>
}

export type SectionHeroCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHeroCreateLocalizationInput = {
  /** Localization input */
  data: SectionHeroCreateLocalizationDataInput
  locale: Locale
}

export type SectionHeroCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionHeroCreateLocalizationInput>>
}

export type SectionHeroCreateManyInlineInput = {
  /** Create and connect multiple existing SectionHero documents */
  create?: InputMaybe<Array<SectionHeroCreateInput>>
}

export type SectionHeroCreateOneInlineInput = {
  /** Create and connect one SectionHero document */
  create?: InputMaybe<SectionHeroCreateInput>
}

export type SectionHeroCreateWithPositionInput = {
  /** Document to create */
  data: SectionHeroCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionHeroEdge = {
  __typename?: 'SectionHeroEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionHero
}

/** Identifies documents */
export type SectionHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  compact?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  compact_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  ctaOnMobileOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ctaOnMobileOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  ctaSecondary?: InputMaybe<CallToActionWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileBackground?: InputMaybe<AssetWhereInput>
  titleDecoration?: InputMaybe<AssetWhereInput>
  titleIsTransparent?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  titleIsTransparent_not?: InputMaybe<Scalars['Boolean']['input']>
  video?: InputMaybe<AssetWhereInput>
}

export enum SectionHeroOrderByInput {
  CompactAsc = 'compact_ASC',
  CompactDesc = 'compact_DESC',
  CtaOnMobileOnlyAsc = 'ctaOnMobileOnly_ASC',
  CtaOnMobileOnlyDesc = 'ctaOnMobileOnly_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TitleIsTransparentAsc = 'titleIsTransparent_ASC',
  TitleIsTransparentDesc = 'titleIsTransparent_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionHeroParent = EventPage | LandingPage

export type SectionHeroParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionHeroParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionHeroParentCreateManyInlineInput = {
  /** Connect multiple existing SectionHeroParent documents */
  connect?: InputMaybe<Array<SectionHeroParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionHeroParent documents */
  create?: InputMaybe<Array<SectionHeroParentCreateInput>>
}

export type SectionHeroParentCreateOneInlineInput = {
  /** Connect one existing SectionHeroParent document */
  connect?: InputMaybe<SectionHeroParentWhereUniqueInput>
  /** Create and connect one SectionHeroParent document */
  create?: InputMaybe<SectionHeroParentCreateInput>
}

export type SectionHeroParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionHeroParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionHeroParent documents */
  connect?: InputMaybe<Array<SectionHeroParentConnectInput>>
  /** Create and connect multiple SectionHeroParent documents */
  create?: InputMaybe<Array<SectionHeroParentCreateInput>>
  /** Delete multiple SectionHeroParent documents */
  delete?: InputMaybe<Array<SectionHeroParentWhereUniqueInput>>
  /** Disconnect multiple SectionHeroParent documents */
  disconnect?: InputMaybe<Array<SectionHeroParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionHeroParent documents */
  set?: InputMaybe<Array<SectionHeroParentWhereUniqueInput>>
  /** Update multiple SectionHeroParent documents */
  update?: InputMaybe<Array<SectionHeroParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionHeroParent documents */
  upsert?: InputMaybe<Array<SectionHeroParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionHeroParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionHeroParentUpdateOneInlineInput = {
  /** Connect existing SectionHeroParent document */
  connect?: InputMaybe<SectionHeroParentWhereUniqueInput>
  /** Create and connect one SectionHeroParent document */
  create?: InputMaybe<SectionHeroParentCreateInput>
  /** Delete currently connected SectionHeroParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionHeroParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHeroParent document */
  update?: InputMaybe<SectionHeroParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHeroParent document */
  upsert?: InputMaybe<SectionHeroParentUpsertWithNestedWhereUniqueInput>
}

export type SectionHeroParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionHeroParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionHeroParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionHeroParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionHeroUpdateInput = {
  background?: InputMaybe<AssetUpdateOneInlineInput>
  compact?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  ctaOnMobileOnly?: InputMaybe<Scalars['Boolean']['input']>
  ctaSecondary?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionHeroUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  mobileBackground?: InputMaybe<AssetUpdateOneInlineInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetUpdateOneInlineInput>
  titleIsTransparent?: InputMaybe<Scalars['Boolean']['input']>
  video?: InputMaybe<AssetUpdateOneInlineInput>
}

export type SectionHeroUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHeroUpdateLocalizationInput = {
  data: SectionHeroUpdateLocalizationDataInput
  locale: Locale
}

export type SectionHeroUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionHeroCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHeroUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionHeroUpsertLocalizationInput>>
}

export type SectionHeroUpdateManyInlineInput = {
  /** Create and connect multiple SectionHero component instances */
  create?: InputMaybe<Array<SectionHeroCreateWithPositionInput>>
  /** Delete multiple SectionHero documents */
  delete?: InputMaybe<Array<SectionHeroWhereUniqueInput>>
  /** Update multiple SectionHero component instances */
  update?: InputMaybe<Array<SectionHeroUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionHero component instances */
  upsert?: InputMaybe<Array<SectionHeroUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionHeroUpdateManyInput = {
  compact?: InputMaybe<Scalars['Boolean']['input']>
  ctaOnMobileOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionHeroUpdateManyLocalizationsInput>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titleIsTransparent?: InputMaybe<Scalars['Boolean']['input']>
}

export type SectionHeroUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHeroUpdateManyLocalizationInput = {
  data: SectionHeroUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionHeroUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHeroUpdateManyLocalizationInput>>
}

export type SectionHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionHeroUpdateManyInput
  /** Document search */
  where: SectionHeroWhereInput
}

export type SectionHeroUpdateOneInlineInput = {
  /** Create and connect one SectionHero document */
  create?: InputMaybe<SectionHeroCreateInput>
  /** Delete currently connected SectionHero document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHero document */
  update?: InputMaybe<SectionHeroUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHero document */
  upsert?: InputMaybe<SectionHeroUpsertWithNestedWhereUniqueInput>
}

export type SectionHeroUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionHeroUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHeroWhereUniqueInput
}

export type SectionHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionHeroUpdateInput
  /** Unique document search */
  where: SectionHeroWhereUniqueInput
}

export type SectionHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionHeroCreateInput
  /** Update document if it exists */
  update: SectionHeroUpdateInput
}

export type SectionHeroUpsertLocalizationInput = {
  create: SectionHeroCreateLocalizationDataInput
  locale: Locale
  update: SectionHeroUpdateLocalizationDataInput
}

export type SectionHeroUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionHeroUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHeroWhereUniqueInput
}

export type SectionHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionHeroUpsertInput
  /** Unique document search */
  where: SectionHeroWhereUniqueInput
}

/** Identifies documents */
export type SectionHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHeroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  compact?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  compact_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  ctaOnMobileOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  ctaOnMobileOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  ctaSecondary?: InputMaybe<CallToActionWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  mobileBackground?: InputMaybe<AssetWhereInput>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  titleDecoration?: InputMaybe<AssetWhereInput>
  titleIsTransparent?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  titleIsTransparent_not?: InputMaybe<Scalars['Boolean']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<AssetWhereInput>
}

/** References SectionHero record uniquely */
export type SectionHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing the list of hotels. */
export type SectionHotel = Entity & {
  __typename?: 'SectionHotel'
  description: Scalars['String']['output']
  hotels: Array<Hotel>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionHotel>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Section showing the list of hotels. */
export type SectionHotelHotelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<HotelOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<HotelWhereInput>
}

/** Section showing the list of hotels. */
export type SectionHotelLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionHotelConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionHotelWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionHotelConnection = {
  __typename?: 'SectionHotelConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionHotelEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionHotelCreateInput = {
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  hotels: HotelCreateManyInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionHotelCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionHotelCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionHotelCreateLocalizationInput = {
  /** Localization input */
  data: SectionHotelCreateLocalizationDataInput
  locale: Locale
}

export type SectionHotelCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionHotelCreateLocalizationInput>>
}

export type SectionHotelCreateManyInlineInput = {
  /** Create and connect multiple existing SectionHotel documents */
  create?: InputMaybe<Array<SectionHotelCreateInput>>
}

export type SectionHotelCreateOneInlineInput = {
  /** Create and connect one SectionHotel document */
  create?: InputMaybe<SectionHotelCreateInput>
}

export type SectionHotelCreateWithPositionInput = {
  /** Document to create */
  data: SectionHotelCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionHotelEdge = {
  __typename?: 'SectionHotelEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionHotel
}

/** Identifies documents */
export type SectionHotelManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  hotels_every?: InputMaybe<HotelWhereInput>
  hotels_none?: InputMaybe<HotelWhereInput>
  hotels_some?: InputMaybe<HotelWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionHotelOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionHotelParent = EventPage

export type SectionHotelParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionHotelParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionHotelParentCreateManyInlineInput = {
  /** Connect multiple existing SectionHotelParent documents */
  connect?: InputMaybe<Array<SectionHotelParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionHotelParent documents */
  create?: InputMaybe<Array<SectionHotelParentCreateInput>>
}

export type SectionHotelParentCreateOneInlineInput = {
  /** Connect one existing SectionHotelParent document */
  connect?: InputMaybe<SectionHotelParentWhereUniqueInput>
  /** Create and connect one SectionHotelParent document */
  create?: InputMaybe<SectionHotelParentCreateInput>
}

export type SectionHotelParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionHotelParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionHotelParent documents */
  connect?: InputMaybe<Array<SectionHotelParentConnectInput>>
  /** Create and connect multiple SectionHotelParent documents */
  create?: InputMaybe<Array<SectionHotelParentCreateInput>>
  /** Delete multiple SectionHotelParent documents */
  delete?: InputMaybe<Array<SectionHotelParentWhereUniqueInput>>
  /** Disconnect multiple SectionHotelParent documents */
  disconnect?: InputMaybe<Array<SectionHotelParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionHotelParent documents */
  set?: InputMaybe<Array<SectionHotelParentWhereUniqueInput>>
  /** Update multiple SectionHotelParent documents */
  update?: InputMaybe<Array<SectionHotelParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionHotelParent documents */
  upsert?: InputMaybe<Array<SectionHotelParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionHotelParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionHotelParentUpdateOneInlineInput = {
  /** Connect existing SectionHotelParent document */
  connect?: InputMaybe<SectionHotelParentWhereUniqueInput>
  /** Create and connect one SectionHotelParent document */
  create?: InputMaybe<SectionHotelParentCreateInput>
  /** Delete currently connected SectionHotelParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionHotelParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHotelParent document */
  update?: InputMaybe<SectionHotelParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHotelParent document */
  upsert?: InputMaybe<SectionHotelParentUpsertWithNestedWhereUniqueInput>
}

export type SectionHotelParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionHotelParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionHotelParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionHotelParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionHotelUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hotels?: InputMaybe<HotelUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionHotelUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHotelUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHotelUpdateLocalizationInput = {
  data: SectionHotelUpdateLocalizationDataInput
  locale: Locale
}

export type SectionHotelUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionHotelCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHotelUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionHotelUpsertLocalizationInput>>
}

export type SectionHotelUpdateManyInlineInput = {
  /** Create and connect multiple SectionHotel component instances */
  create?: InputMaybe<Array<SectionHotelCreateWithPositionInput>>
  /** Delete multiple SectionHotel documents */
  delete?: InputMaybe<Array<SectionHotelWhereUniqueInput>>
  /** Update multiple SectionHotel component instances */
  update?: InputMaybe<Array<SectionHotelUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionHotel component instances */
  upsert?: InputMaybe<Array<SectionHotelUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionHotelUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionHotelUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHotelUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionHotelUpdateManyLocalizationInput = {
  data: SectionHotelUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionHotelUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHotelUpdateManyLocalizationInput>>
}

export type SectionHotelUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionHotelUpdateManyInput
  /** Document search */
  where: SectionHotelWhereInput
}

export type SectionHotelUpdateOneInlineInput = {
  /** Create and connect one SectionHotel document */
  create?: InputMaybe<SectionHotelCreateInput>
  /** Delete currently connected SectionHotel document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHotel document */
  update?: InputMaybe<SectionHotelUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHotel document */
  upsert?: InputMaybe<SectionHotelUpsertWithNestedWhereUniqueInput>
}

export type SectionHotelUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionHotelUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHotelWhereUniqueInput
}

export type SectionHotelUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionHotelUpdateInput
  /** Unique document search */
  where: SectionHotelWhereUniqueInput
}

export type SectionHotelUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionHotelCreateInput
  /** Update document if it exists */
  update: SectionHotelUpdateInput
}

export type SectionHotelUpsertLocalizationInput = {
  create: SectionHotelCreateLocalizationDataInput
  locale: Locale
  update: SectionHotelUpdateLocalizationDataInput
}

export type SectionHotelUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionHotelUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHotelWhereUniqueInput
}

export type SectionHotelUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionHotelUpsertInput
  /** Unique document search */
  where: SectionHotelWhereUniqueInput
}

/** Identifies documents */
export type SectionHotelWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHotelWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  hotels_every?: InputMaybe<HotelWhereInput>
  hotels_none?: InputMaybe<HotelWhereInput>
  hotels_some?: InputMaybe<HotelWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionHotel record uniquely */
export type SectionHotelWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Embed a Hubspot form section on the page */
export type SectionHubspotForm = Entity & {
  __typename?: 'SectionHubspotForm'
  formId: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionHubspotForm>
  /** System stage field */
  stage: Stage
}

/** Embed a Hubspot form section on the page */
export type SectionHubspotFormLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionHubspotFormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionHubspotFormWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionHubspotFormConnection = {
  __typename?: 'SectionHubspotFormConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionHubspotFormEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionHubspotFormCreateInput = {
  /** formId input for default locale (de) */
  formId: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionHubspotFormCreateLocalizationsInput>
}

export type SectionHubspotFormCreateLocalizationDataInput = {
  formId: Scalars['String']['input']
}

export type SectionHubspotFormCreateLocalizationInput = {
  /** Localization input */
  data: SectionHubspotFormCreateLocalizationDataInput
  locale: Locale
}

export type SectionHubspotFormCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionHubspotFormCreateLocalizationInput>>
}

export type SectionHubspotFormCreateManyInlineInput = {
  /** Create and connect multiple existing SectionHubspotForm documents */
  create?: InputMaybe<Array<SectionHubspotFormCreateInput>>
}

export type SectionHubspotFormCreateOneInlineInput = {
  /** Create and connect one SectionHubspotForm document */
  create?: InputMaybe<SectionHubspotFormCreateInput>
}

export type SectionHubspotFormCreateWithPositionInput = {
  /** Document to create */
  data: SectionHubspotFormCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionHubspotFormEdge = {
  __typename?: 'SectionHubspotFormEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionHubspotForm
}

/** Identifies documents */
export type SectionHubspotFormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionHubspotFormOrderByInput {
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionHubspotFormParent = LandingPage

export type SectionHubspotFormParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionHubspotFormParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionHubspotFormParentCreateManyInlineInput = {
  /** Connect multiple existing SectionHubspotFormParent documents */
  connect?: InputMaybe<Array<SectionHubspotFormParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionHubspotFormParent documents */
  create?: InputMaybe<Array<SectionHubspotFormParentCreateInput>>
}

export type SectionHubspotFormParentCreateOneInlineInput = {
  /** Connect one existing SectionHubspotFormParent document */
  connect?: InputMaybe<SectionHubspotFormParentWhereUniqueInput>
  /** Create and connect one SectionHubspotFormParent document */
  create?: InputMaybe<SectionHubspotFormParentCreateInput>
}

export type SectionHubspotFormParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionHubspotFormParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionHubspotFormParent documents */
  connect?: InputMaybe<Array<SectionHubspotFormParentConnectInput>>
  /** Create and connect multiple SectionHubspotFormParent documents */
  create?: InputMaybe<Array<SectionHubspotFormParentCreateInput>>
  /** Delete multiple SectionHubspotFormParent documents */
  delete?: InputMaybe<Array<SectionHubspotFormParentWhereUniqueInput>>
  /** Disconnect multiple SectionHubspotFormParent documents */
  disconnect?: InputMaybe<Array<SectionHubspotFormParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionHubspotFormParent documents */
  set?: InputMaybe<Array<SectionHubspotFormParentWhereUniqueInput>>
  /** Update multiple SectionHubspotFormParent documents */
  update?: InputMaybe<Array<SectionHubspotFormParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionHubspotFormParent documents */
  upsert?: InputMaybe<Array<SectionHubspotFormParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionHubspotFormParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionHubspotFormParentUpdateOneInlineInput = {
  /** Connect existing SectionHubspotFormParent document */
  connect?: InputMaybe<SectionHubspotFormParentWhereUniqueInput>
  /** Create and connect one SectionHubspotFormParent document */
  create?: InputMaybe<SectionHubspotFormParentCreateInput>
  /** Delete currently connected SectionHubspotFormParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionHubspotFormParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHubspotFormParent document */
  update?: InputMaybe<SectionHubspotFormParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHubspotFormParent document */
  upsert?: InputMaybe<SectionHubspotFormParentUpsertWithNestedWhereUniqueInput>
}

export type SectionHubspotFormParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionHubspotFormParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionHubspotFormParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionHubspotFormParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionHubspotFormUpdateInput = {
  /** formId input for default locale (de) */
  formId?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionHubspotFormUpdateLocalizationsInput>
}

export type SectionHubspotFormUpdateLocalizationDataInput = {
  formId?: InputMaybe<Scalars['String']['input']>
}

export type SectionHubspotFormUpdateLocalizationInput = {
  data: SectionHubspotFormUpdateLocalizationDataInput
  locale: Locale
}

export type SectionHubspotFormUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionHubspotFormCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHubspotFormUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionHubspotFormUpsertLocalizationInput>>
}

export type SectionHubspotFormUpdateManyInlineInput = {
  /** Create and connect multiple SectionHubspotForm component instances */
  create?: InputMaybe<Array<SectionHubspotFormCreateWithPositionInput>>
  /** Delete multiple SectionHubspotForm documents */
  delete?: InputMaybe<Array<SectionHubspotFormWhereUniqueInput>>
  /** Update multiple SectionHubspotForm component instances */
  update?: InputMaybe<Array<SectionHubspotFormUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionHubspotForm component instances */
  upsert?: InputMaybe<Array<SectionHubspotFormUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionHubspotFormUpdateManyInput = {
  /** formId input for default locale (de) */
  formId?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionHubspotFormUpdateManyLocalizationsInput>
}

export type SectionHubspotFormUpdateManyLocalizationDataInput = {
  formId?: InputMaybe<Scalars['String']['input']>
}

export type SectionHubspotFormUpdateManyLocalizationInput = {
  data: SectionHubspotFormUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionHubspotFormUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionHubspotFormUpdateManyLocalizationInput>>
}

export type SectionHubspotFormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionHubspotFormUpdateManyInput
  /** Document search */
  where: SectionHubspotFormWhereInput
}

export type SectionHubspotFormUpdateOneInlineInput = {
  /** Create and connect one SectionHubspotForm document */
  create?: InputMaybe<SectionHubspotFormCreateInput>
  /** Delete currently connected SectionHubspotForm document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionHubspotForm document */
  update?: InputMaybe<SectionHubspotFormUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionHubspotForm document */
  upsert?: InputMaybe<SectionHubspotFormUpsertWithNestedWhereUniqueInput>
}

export type SectionHubspotFormUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionHubspotFormUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHubspotFormWhereUniqueInput
}

export type SectionHubspotFormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionHubspotFormUpdateInput
  /** Unique document search */
  where: SectionHubspotFormWhereUniqueInput
}

export type SectionHubspotFormUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionHubspotFormCreateInput
  /** Update document if it exists */
  update: SectionHubspotFormUpdateInput
}

export type SectionHubspotFormUpsertLocalizationInput = {
  create: SectionHubspotFormCreateLocalizationDataInput
  locale: Locale
  update: SectionHubspotFormUpdateLocalizationDataInput
}

export type SectionHubspotFormUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionHubspotFormUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionHubspotFormWhereUniqueInput
}

export type SectionHubspotFormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionHubspotFormUpsertInput
  /** Unique document search */
  where: SectionHubspotFormWhereUniqueInput
}

/** Identifies documents */
export type SectionHubspotFormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHubspotFormWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  formId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  formId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  formId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  formId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  formId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  formId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  formId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  formId_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionHubspotForm record uniquely */
export type SectionHubspotFormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionIntro = Entity & {
  __typename?: 'SectionIntro'
  centered?: Maybe<Scalars['Boolean']['output']>
  /** Call to action element rendered below text content */
  cta?: Maybe<CallToAction>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionIntro>
  sectionHead?: Maybe<Scalars['Boolean']['output']>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type SectionIntroCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionIntroLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionIntroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionIntroWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionIntroConnection = {
  __typename?: 'SectionIntroConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionIntroEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionIntroCreateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionIntroCreateLocalizationsInput>
  sectionHead?: InputMaybe<Scalars['Boolean']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionIntroCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionIntroCreateLocalizationInput = {
  /** Localization input */
  data: SectionIntroCreateLocalizationDataInput
  locale: Locale
}

export type SectionIntroCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionIntroCreateLocalizationInput>>
}

export type SectionIntroCreateManyInlineInput = {
  /** Create and connect multiple existing SectionIntro documents */
  create?: InputMaybe<Array<SectionIntroCreateInput>>
}

export type SectionIntroCreateOneInlineInput = {
  /** Create and connect one SectionIntro document */
  create?: InputMaybe<SectionIntroCreateInput>
}

export type SectionIntroCreateWithPositionInput = {
  /** Document to create */
  data: SectionIntroCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionIntroEdge = {
  __typename?: 'SectionIntroEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionIntro
}

/** Identifies documents */
export type SectionIntroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  sectionHead_not?: InputMaybe<Scalars['Boolean']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
}

export enum SectionIntroOrderByInput {
  CenteredAsc = 'centered_ASC',
  CenteredDesc = 'centered_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionHeadAsc = 'sectionHead_ASC',
  SectionHeadDesc = 'sectionHead_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionIntroParent = EventPage | LandingPage

export type SectionIntroParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionIntroParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionIntroParentCreateManyInlineInput = {
  /** Connect multiple existing SectionIntroParent documents */
  connect?: InputMaybe<Array<SectionIntroParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionIntroParent documents */
  create?: InputMaybe<Array<SectionIntroParentCreateInput>>
}

export type SectionIntroParentCreateOneInlineInput = {
  /** Connect one existing SectionIntroParent document */
  connect?: InputMaybe<SectionIntroParentWhereUniqueInput>
  /** Create and connect one SectionIntroParent document */
  create?: InputMaybe<SectionIntroParentCreateInput>
}

export type SectionIntroParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionIntroParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionIntroParent documents */
  connect?: InputMaybe<Array<SectionIntroParentConnectInput>>
  /** Create and connect multiple SectionIntroParent documents */
  create?: InputMaybe<Array<SectionIntroParentCreateInput>>
  /** Delete multiple SectionIntroParent documents */
  delete?: InputMaybe<Array<SectionIntroParentWhereUniqueInput>>
  /** Disconnect multiple SectionIntroParent documents */
  disconnect?: InputMaybe<Array<SectionIntroParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionIntroParent documents */
  set?: InputMaybe<Array<SectionIntroParentWhereUniqueInput>>
  /** Update multiple SectionIntroParent documents */
  update?: InputMaybe<Array<SectionIntroParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionIntroParent documents */
  upsert?: InputMaybe<Array<SectionIntroParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionIntroParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionIntroParentUpdateOneInlineInput = {
  /** Connect existing SectionIntroParent document */
  connect?: InputMaybe<SectionIntroParentWhereUniqueInput>
  /** Create and connect one SectionIntroParent document */
  create?: InputMaybe<SectionIntroParentCreateInput>
  /** Delete currently connected SectionIntroParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionIntroParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionIntroParent document */
  update?: InputMaybe<SectionIntroParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionIntroParent document */
  upsert?: InputMaybe<SectionIntroParentUpsertWithNestedWhereUniqueInput>
}

export type SectionIntroParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionIntroParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionIntroParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionIntroParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionIntroUpdateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionIntroUpdateLocalizationsInput>
  sectionHead?: InputMaybe<Scalars['Boolean']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionIntroUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionIntroUpdateLocalizationInput = {
  data: SectionIntroUpdateLocalizationDataInput
  locale: Locale
}

export type SectionIntroUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionIntroCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionIntroUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionIntroUpsertLocalizationInput>>
}

export type SectionIntroUpdateManyInlineInput = {
  /** Create and connect multiple SectionIntro component instances */
  create?: InputMaybe<Array<SectionIntroCreateWithPositionInput>>
  /** Delete multiple SectionIntro documents */
  delete?: InputMaybe<Array<SectionIntroWhereUniqueInput>>
  /** Update multiple SectionIntro component instances */
  update?: InputMaybe<Array<SectionIntroUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionIntro component instances */
  upsert?: InputMaybe<Array<SectionIntroUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionIntroUpdateManyInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionIntroUpdateManyLocalizationsInput>
  sectionHead?: InputMaybe<Scalars['Boolean']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionIntroUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionIntroUpdateManyLocalizationInput = {
  data: SectionIntroUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionIntroUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionIntroUpdateManyLocalizationInput>>
}

export type SectionIntroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionIntroUpdateManyInput
  /** Document search */
  where: SectionIntroWhereInput
}

export type SectionIntroUpdateOneInlineInput = {
  /** Create and connect one SectionIntro document */
  create?: InputMaybe<SectionIntroCreateInput>
  /** Delete currently connected SectionIntro document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionIntro document */
  update?: InputMaybe<SectionIntroUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionIntro document */
  upsert?: InputMaybe<SectionIntroUpsertWithNestedWhereUniqueInput>
}

export type SectionIntroUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionIntroUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionIntroWhereUniqueInput
}

export type SectionIntroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionIntroUpdateInput
  /** Unique document search */
  where: SectionIntroWhereUniqueInput
}

export type SectionIntroUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionIntroCreateInput
  /** Update document if it exists */
  update: SectionIntroUpdateInput
}

export type SectionIntroUpsertLocalizationInput = {
  create: SectionIntroCreateLocalizationDataInput
  locale: Locale
  update: SectionIntroUpdateLocalizationDataInput
}

export type SectionIntroUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionIntroUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionIntroWhereUniqueInput
}

export type SectionIntroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionIntroUpsertInput
  /** Unique document search */
  where: SectionIntroWhereUniqueInput
}

/** Identifies documents */
export type SectionIntroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionIntroWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  sectionHead_not?: InputMaybe<Scalars['Boolean']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionIntro record uniquely */
export type SectionIntroWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionJobList = Entity & {
  __typename?: 'SectionJobList'
  /** The unique identifier */
  id: Scalars['ID']['output']
  jobs: Array<JobListing>
  /** System stage field */
  stage: Stage
}

export type SectionJobListJobsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<JobListingOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<JobListingWhereInput>
}

export type SectionJobListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionJobListWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionJobListConnection = {
  __typename?: 'SectionJobListConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionJobListEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionJobListCreateInput = {
  jobs?: InputMaybe<JobListingCreateManyInlineInput>
}

export type SectionJobListCreateManyInlineInput = {
  /** Create and connect multiple existing SectionJobList documents */
  create?: InputMaybe<Array<SectionJobListCreateInput>>
}

export type SectionJobListCreateOneInlineInput = {
  /** Create and connect one SectionJobList document */
  create?: InputMaybe<SectionJobListCreateInput>
}

export type SectionJobListCreateWithPositionInput = {
  /** Document to create */
  data: SectionJobListCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionJobListEdge = {
  __typename?: 'SectionJobListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionJobList
}

/** Identifies documents */
export type SectionJobListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobs_every?: InputMaybe<JobListingWhereInput>
  jobs_none?: InputMaybe<JobListingWhereInput>
  jobs_some?: InputMaybe<JobListingWhereInput>
}

export enum SectionJobListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionJobListParent = EventPage

export type SectionJobListParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionJobListParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionJobListParentCreateManyInlineInput = {
  /** Connect multiple existing SectionJobListParent documents */
  connect?: InputMaybe<Array<SectionJobListParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionJobListParent documents */
  create?: InputMaybe<Array<SectionJobListParentCreateInput>>
}

export type SectionJobListParentCreateOneInlineInput = {
  /** Connect one existing SectionJobListParent document */
  connect?: InputMaybe<SectionJobListParentWhereUniqueInput>
  /** Create and connect one SectionJobListParent document */
  create?: InputMaybe<SectionJobListParentCreateInput>
}

export type SectionJobListParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionJobListParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionJobListParent documents */
  connect?: InputMaybe<Array<SectionJobListParentConnectInput>>
  /** Create and connect multiple SectionJobListParent documents */
  create?: InputMaybe<Array<SectionJobListParentCreateInput>>
  /** Delete multiple SectionJobListParent documents */
  delete?: InputMaybe<Array<SectionJobListParentWhereUniqueInput>>
  /** Disconnect multiple SectionJobListParent documents */
  disconnect?: InputMaybe<Array<SectionJobListParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionJobListParent documents */
  set?: InputMaybe<Array<SectionJobListParentWhereUniqueInput>>
  /** Update multiple SectionJobListParent documents */
  update?: InputMaybe<Array<SectionJobListParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionJobListParent documents */
  upsert?: InputMaybe<Array<SectionJobListParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionJobListParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionJobListParentUpdateOneInlineInput = {
  /** Connect existing SectionJobListParent document */
  connect?: InputMaybe<SectionJobListParentWhereUniqueInput>
  /** Create and connect one SectionJobListParent document */
  create?: InputMaybe<SectionJobListParentCreateInput>
  /** Delete currently connected SectionJobListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionJobListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionJobListParent document */
  update?: InputMaybe<SectionJobListParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionJobListParent document */
  upsert?: InputMaybe<SectionJobListParentUpsertWithNestedWhereUniqueInput>
}

export type SectionJobListParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionJobListParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionJobListParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionJobListParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionJobListUpdateInput = {
  jobs?: InputMaybe<JobListingUpdateManyInlineInput>
}

export type SectionJobListUpdateManyInlineInput = {
  /** Create and connect multiple SectionJobList component instances */
  create?: InputMaybe<Array<SectionJobListCreateWithPositionInput>>
  /** Delete multiple SectionJobList documents */
  delete?: InputMaybe<Array<SectionJobListWhereUniqueInput>>
  /** Update multiple SectionJobList component instances */
  update?: InputMaybe<Array<SectionJobListUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionJobList component instances */
  upsert?: InputMaybe<Array<SectionJobListUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionJobListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionJobListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionJobListUpdateManyInput
  /** Document search */
  where: SectionJobListWhereInput
}

export type SectionJobListUpdateOneInlineInput = {
  /** Create and connect one SectionJobList document */
  create?: InputMaybe<SectionJobListCreateInput>
  /** Delete currently connected SectionJobList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionJobList document */
  update?: InputMaybe<SectionJobListUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionJobList document */
  upsert?: InputMaybe<SectionJobListUpsertWithNestedWhereUniqueInput>
}

export type SectionJobListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionJobListUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionJobListWhereUniqueInput
}

export type SectionJobListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionJobListUpdateInput
  /** Unique document search */
  where: SectionJobListWhereUniqueInput
}

export type SectionJobListUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionJobListCreateInput
  /** Update document if it exists */
  update: SectionJobListUpdateInput
}

export type SectionJobListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionJobListUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionJobListWhereUniqueInput
}

export type SectionJobListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionJobListUpsertInput
  /** Unique document search */
  where: SectionJobListWhereUniqueInput
}

/** Identifies documents */
export type SectionJobListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionJobListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  jobs_every?: InputMaybe<JobListingWhereInput>
  jobs_none?: InputMaybe<JobListingWhereInput>
  jobs_some?: InputMaybe<JobListingWhereInput>
}

/** References SectionJobList record uniquely */
export type SectionJobListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionLivestreams = Entity & {
  __typename?: 'SectionLivestreams'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionLivestreams>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
  ytEmbed: Array<YtEmbed>
}

export type SectionLivestreamsLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionLivestreamsYtEmbedArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<YtEmbedOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<YtEmbedWhereInput>
}

export type SectionLivestreamsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionLivestreamsWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionLivestreamsConnection = {
  __typename?: 'SectionLivestreamsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionLivestreamsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionLivestreamsCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionLivestreamsCreateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  ytEmbed?: InputMaybe<YtEmbedCreateManyInlineInput>
}

export type SectionLivestreamsCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLivestreamsCreateLocalizationInput = {
  /** Localization input */
  data: SectionLivestreamsCreateLocalizationDataInput
  locale: Locale
}

export type SectionLivestreamsCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionLivestreamsCreateLocalizationInput>>
}

export type SectionLivestreamsCreateManyInlineInput = {
  /** Create and connect multiple existing SectionLivestreams documents */
  create?: InputMaybe<Array<SectionLivestreamsCreateInput>>
}

export type SectionLivestreamsCreateOneInlineInput = {
  /** Create and connect one SectionLivestreams document */
  create?: InputMaybe<SectionLivestreamsCreateInput>
}

export type SectionLivestreamsCreateWithPositionInput = {
  /** Document to create */
  data: SectionLivestreamsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionLivestreamsEdge = {
  __typename?: 'SectionLivestreamsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionLivestreams
}

/** Identifies documents */
export type SectionLivestreamsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  ytEmbed_every?: InputMaybe<YtEmbedWhereInput>
  ytEmbed_none?: InputMaybe<YtEmbedWhereInput>
  ytEmbed_some?: InputMaybe<YtEmbedWhereInput>
}

export enum SectionLivestreamsOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionLivestreamsParent = EventPage

export type SectionLivestreamsParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionLivestreamsParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionLivestreamsParentCreateManyInlineInput = {
  /** Connect multiple existing SectionLivestreamsParent documents */
  connect?: InputMaybe<Array<SectionLivestreamsParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionLivestreamsParent documents */
  create?: InputMaybe<Array<SectionLivestreamsParentCreateInput>>
}

export type SectionLivestreamsParentCreateOneInlineInput = {
  /** Connect one existing SectionLivestreamsParent document */
  connect?: InputMaybe<SectionLivestreamsParentWhereUniqueInput>
  /** Create and connect one SectionLivestreamsParent document */
  create?: InputMaybe<SectionLivestreamsParentCreateInput>
}

export type SectionLivestreamsParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionLivestreamsParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionLivestreamsParent documents */
  connect?: InputMaybe<Array<SectionLivestreamsParentConnectInput>>
  /** Create and connect multiple SectionLivestreamsParent documents */
  create?: InputMaybe<Array<SectionLivestreamsParentCreateInput>>
  /** Delete multiple SectionLivestreamsParent documents */
  delete?: InputMaybe<Array<SectionLivestreamsParentWhereUniqueInput>>
  /** Disconnect multiple SectionLivestreamsParent documents */
  disconnect?: InputMaybe<Array<SectionLivestreamsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionLivestreamsParent documents */
  set?: InputMaybe<Array<SectionLivestreamsParentWhereUniqueInput>>
  /** Update multiple SectionLivestreamsParent documents */
  update?: InputMaybe<Array<SectionLivestreamsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionLivestreamsParent documents */
  upsert?: InputMaybe<Array<SectionLivestreamsParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionLivestreamsParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionLivestreamsParentUpdateOneInlineInput = {
  /** Connect existing SectionLivestreamsParent document */
  connect?: InputMaybe<SectionLivestreamsParentWhereUniqueInput>
  /** Create and connect one SectionLivestreamsParent document */
  create?: InputMaybe<SectionLivestreamsParentCreateInput>
  /** Delete currently connected SectionLivestreamsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionLivestreamsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionLivestreamsParent document */
  update?: InputMaybe<SectionLivestreamsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionLivestreamsParent document */
  upsert?: InputMaybe<SectionLivestreamsParentUpsertWithNestedWhereUniqueInput>
}

export type SectionLivestreamsParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionLivestreamsParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionLivestreamsParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionLivestreamsParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionLivestreamsUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionLivestreamsUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  ytEmbed?: InputMaybe<YtEmbedUpdateManyInlineInput>
}

export type SectionLivestreamsUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLivestreamsUpdateLocalizationInput = {
  data: SectionLivestreamsUpdateLocalizationDataInput
  locale: Locale
}

export type SectionLivestreamsUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionLivestreamsCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionLivestreamsUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionLivestreamsUpsertLocalizationInput>>
}

export type SectionLivestreamsUpdateManyInlineInput = {
  /** Create and connect multiple SectionLivestreams component instances */
  create?: InputMaybe<Array<SectionLivestreamsCreateWithPositionInput>>
  /** Delete multiple SectionLivestreams documents */
  delete?: InputMaybe<Array<SectionLivestreamsWhereUniqueInput>>
  /** Update multiple SectionLivestreams component instances */
  update?: InputMaybe<Array<SectionLivestreamsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionLivestreams component instances */
  upsert?: InputMaybe<Array<SectionLivestreamsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionLivestreamsUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionLivestreamsUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLivestreamsUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLivestreamsUpdateManyLocalizationInput = {
  data: SectionLivestreamsUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionLivestreamsUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionLivestreamsUpdateManyLocalizationInput>>
}

export type SectionLivestreamsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionLivestreamsUpdateManyInput
  /** Document search */
  where: SectionLivestreamsWhereInput
}

export type SectionLivestreamsUpdateOneInlineInput = {
  /** Create and connect one SectionLivestreams document */
  create?: InputMaybe<SectionLivestreamsCreateInput>
  /** Delete currently connected SectionLivestreams document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionLivestreams document */
  update?: InputMaybe<SectionLivestreamsUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionLivestreams document */
  upsert?: InputMaybe<SectionLivestreamsUpsertWithNestedWhereUniqueInput>
}

export type SectionLivestreamsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionLivestreamsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionLivestreamsWhereUniqueInput
}

export type SectionLivestreamsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionLivestreamsUpdateInput
  /** Unique document search */
  where: SectionLivestreamsWhereUniqueInput
}

export type SectionLivestreamsUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionLivestreamsCreateInput
  /** Update document if it exists */
  update: SectionLivestreamsUpdateInput
}

export type SectionLivestreamsUpsertLocalizationInput = {
  create: SectionLivestreamsCreateLocalizationDataInput
  locale: Locale
  update: SectionLivestreamsUpdateLocalizationDataInput
}

export type SectionLivestreamsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionLivestreamsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionLivestreamsWhereUniqueInput
}

export type SectionLivestreamsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionLivestreamsUpsertInput
  /** Unique document search */
  where: SectionLivestreamsWhereUniqueInput
}

/** Identifies documents */
export type SectionLivestreamsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionLivestreamsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  ytEmbed_every?: InputMaybe<YtEmbedWhereInput>
  ytEmbed_none?: InputMaybe<YtEmbedWhereInput>
  ytEmbed_some?: InputMaybe<YtEmbedWhereInput>
}

/** References SectionLivestreams record uniquely */
export type SectionLivestreamsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Multiple Logos with Header. Used for 'in partnership with' logo fields etc. */
export type SectionLogoField = Entity & {
  __typename?: 'SectionLogoField'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionLogoField>
  logo: Array<Asset>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Multiple Logos with Header. Used for 'in partnership with' logo fields etc. */
export type SectionLogoFieldLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Multiple Logos with Header. Used for 'in partnership with' logo fields etc. */
export type SectionLogoFieldLogoArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AssetOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AssetWhereInput>
}

export type SectionLogoFieldConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionLogoFieldWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionLogoFieldConnection = {
  __typename?: 'SectionLogoFieldConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionLogoFieldEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionLogoFieldCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionLogoFieldCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateManyInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionLogoFieldCreateLocalizationDataInput = {
  title: Scalars['String']['input']
}

export type SectionLogoFieldCreateLocalizationInput = {
  /** Localization input */
  data: SectionLogoFieldCreateLocalizationDataInput
  locale: Locale
}

export type SectionLogoFieldCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionLogoFieldCreateLocalizationInput>>
}

export type SectionLogoFieldCreateManyInlineInput = {
  /** Create and connect multiple existing SectionLogoField documents */
  create?: InputMaybe<Array<SectionLogoFieldCreateInput>>
}

export type SectionLogoFieldCreateOneInlineInput = {
  /** Create and connect one SectionLogoField document */
  create?: InputMaybe<SectionLogoFieldCreateInput>
}

export type SectionLogoFieldCreateWithPositionInput = {
  /** Document to create */
  data: SectionLogoFieldCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionLogoFieldEdge = {
  __typename?: 'SectionLogoFieldEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionLogoField
}

/** Identifies documents */
export type SectionLogoFieldManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo_every?: InputMaybe<AssetWhereInput>
  logo_none?: InputMaybe<AssetWhereInput>
  logo_some?: InputMaybe<AssetWhereInput>
}

export enum SectionLogoFieldOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionLogoFieldParent = EventPage

export type SectionLogoFieldParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionLogoFieldParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionLogoFieldParentCreateManyInlineInput = {
  /** Connect multiple existing SectionLogoFieldParent documents */
  connect?: InputMaybe<Array<SectionLogoFieldParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionLogoFieldParent documents */
  create?: InputMaybe<Array<SectionLogoFieldParentCreateInput>>
}

export type SectionLogoFieldParentCreateOneInlineInput = {
  /** Connect one existing SectionLogoFieldParent document */
  connect?: InputMaybe<SectionLogoFieldParentWhereUniqueInput>
  /** Create and connect one SectionLogoFieldParent document */
  create?: InputMaybe<SectionLogoFieldParentCreateInput>
}

export type SectionLogoFieldParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionLogoFieldParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionLogoFieldParent documents */
  connect?: InputMaybe<Array<SectionLogoFieldParentConnectInput>>
  /** Create and connect multiple SectionLogoFieldParent documents */
  create?: InputMaybe<Array<SectionLogoFieldParentCreateInput>>
  /** Delete multiple SectionLogoFieldParent documents */
  delete?: InputMaybe<Array<SectionLogoFieldParentWhereUniqueInput>>
  /** Disconnect multiple SectionLogoFieldParent documents */
  disconnect?: InputMaybe<Array<SectionLogoFieldParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionLogoFieldParent documents */
  set?: InputMaybe<Array<SectionLogoFieldParentWhereUniqueInput>>
  /** Update multiple SectionLogoFieldParent documents */
  update?: InputMaybe<Array<SectionLogoFieldParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionLogoFieldParent documents */
  upsert?: InputMaybe<Array<SectionLogoFieldParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionLogoFieldParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionLogoFieldParentUpdateOneInlineInput = {
  /** Connect existing SectionLogoFieldParent document */
  connect?: InputMaybe<SectionLogoFieldParentWhereUniqueInput>
  /** Create and connect one SectionLogoFieldParent document */
  create?: InputMaybe<SectionLogoFieldParentCreateInput>
  /** Delete currently connected SectionLogoFieldParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionLogoFieldParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionLogoFieldParent document */
  update?: InputMaybe<SectionLogoFieldParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionLogoFieldParent document */
  upsert?: InputMaybe<SectionLogoFieldParentUpsertWithNestedWhereUniqueInput>
}

export type SectionLogoFieldParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionLogoFieldParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionLogoFieldParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionLogoFieldParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionLogoFieldUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<SectionLogoFieldUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLogoFieldUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLogoFieldUpdateLocalizationInput = {
  data: SectionLogoFieldUpdateLocalizationDataInput
  locale: Locale
}

export type SectionLogoFieldUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionLogoFieldCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionLogoFieldUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionLogoFieldUpsertLocalizationInput>>
}

export type SectionLogoFieldUpdateManyInlineInput = {
  /** Create and connect multiple SectionLogoField component instances */
  create?: InputMaybe<Array<SectionLogoFieldCreateWithPositionInput>>
  /** Delete multiple SectionLogoField documents */
  delete?: InputMaybe<Array<SectionLogoFieldWhereUniqueInput>>
  /** Update multiple SectionLogoField component instances */
  update?: InputMaybe<Array<SectionLogoFieldUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionLogoField component instances */
  upsert?: InputMaybe<Array<SectionLogoFieldUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionLogoFieldUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionLogoFieldUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLogoFieldUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionLogoFieldUpdateManyLocalizationInput = {
  data: SectionLogoFieldUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionLogoFieldUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionLogoFieldUpdateManyLocalizationInput>>
}

export type SectionLogoFieldUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionLogoFieldUpdateManyInput
  /** Document search */
  where: SectionLogoFieldWhereInput
}

export type SectionLogoFieldUpdateOneInlineInput = {
  /** Create and connect one SectionLogoField document */
  create?: InputMaybe<SectionLogoFieldCreateInput>
  /** Delete currently connected SectionLogoField document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionLogoField document */
  update?: InputMaybe<SectionLogoFieldUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionLogoField document */
  upsert?: InputMaybe<SectionLogoFieldUpsertWithNestedWhereUniqueInput>
}

export type SectionLogoFieldUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionLogoFieldUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionLogoFieldWhereUniqueInput
}

export type SectionLogoFieldUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionLogoFieldUpdateInput
  /** Unique document search */
  where: SectionLogoFieldWhereUniqueInput
}

export type SectionLogoFieldUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionLogoFieldCreateInput
  /** Update document if it exists */
  update: SectionLogoFieldUpdateInput
}

export type SectionLogoFieldUpsertLocalizationInput = {
  create: SectionLogoFieldCreateLocalizationDataInput
  locale: Locale
  update: SectionLogoFieldUpdateLocalizationDataInput
}

export type SectionLogoFieldUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionLogoFieldUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionLogoFieldWhereUniqueInput
}

export type SectionLogoFieldUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionLogoFieldUpsertInput
  /** Unique document search */
  where: SectionLogoFieldWhereUniqueInput
}

/** Identifies documents */
export type SectionLogoFieldWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionLogoFieldWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo_every?: InputMaybe<AssetWhereInput>
  logo_none?: InputMaybe<AssetWhereInput>
  logo_some?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionLogoField record uniquely */
export type SectionLogoFieldWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Manage the logged in user's interests */
export type SectionManageInterests = Entity & {
  __typename?: 'SectionManageInterests'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

export type SectionManageInterestsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionManageInterestsWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionManageInterestsConnection = {
  __typename?: 'SectionManageInterestsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionManageInterestsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionManageInterestsCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionManageInterestsCreateManyInlineInput = {
  /** Create and connect multiple existing SectionManageInterests documents */
  create?: InputMaybe<Array<SectionManageInterestsCreateInput>>
}

export type SectionManageInterestsCreateOneInlineInput = {
  /** Create and connect one SectionManageInterests document */
  create?: InputMaybe<SectionManageInterestsCreateInput>
}

export type SectionManageInterestsCreateWithPositionInput = {
  /** Document to create */
  data: SectionManageInterestsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionManageInterestsEdge = {
  __typename?: 'SectionManageInterestsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionManageInterests
}

/** Identifies documents */
export type SectionManageInterestsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionManageInterestsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionManageInterestsParent = EventPage

export type SectionManageInterestsParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionManageInterestsParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionManageInterestsParentCreateManyInlineInput = {
  /** Connect multiple existing SectionManageInterestsParent documents */
  connect?: InputMaybe<Array<SectionManageInterestsParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionManageInterestsParent documents */
  create?: InputMaybe<Array<SectionManageInterestsParentCreateInput>>
}

export type SectionManageInterestsParentCreateOneInlineInput = {
  /** Connect one existing SectionManageInterestsParent document */
  connect?: InputMaybe<SectionManageInterestsParentWhereUniqueInput>
  /** Create and connect one SectionManageInterestsParent document */
  create?: InputMaybe<SectionManageInterestsParentCreateInput>
}

export type SectionManageInterestsParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionManageInterestsParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionManageInterestsParent documents */
  connect?: InputMaybe<Array<SectionManageInterestsParentConnectInput>>
  /** Create and connect multiple SectionManageInterestsParent documents */
  create?: InputMaybe<Array<SectionManageInterestsParentCreateInput>>
  /** Delete multiple SectionManageInterestsParent documents */
  delete?: InputMaybe<Array<SectionManageInterestsParentWhereUniqueInput>>
  /** Disconnect multiple SectionManageInterestsParent documents */
  disconnect?: InputMaybe<Array<SectionManageInterestsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionManageInterestsParent documents */
  set?: InputMaybe<Array<SectionManageInterestsParentWhereUniqueInput>>
  /** Update multiple SectionManageInterestsParent documents */
  update?: InputMaybe<Array<SectionManageInterestsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionManageInterestsParent documents */
  upsert?: InputMaybe<Array<SectionManageInterestsParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionManageInterestsParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionManageInterestsParentUpdateOneInlineInput = {
  /** Connect existing SectionManageInterestsParent document */
  connect?: InputMaybe<SectionManageInterestsParentWhereUniqueInput>
  /** Create and connect one SectionManageInterestsParent document */
  create?: InputMaybe<SectionManageInterestsParentCreateInput>
  /** Delete currently connected SectionManageInterestsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionManageInterestsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionManageInterestsParent document */
  update?: InputMaybe<SectionManageInterestsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionManageInterestsParent document */
  upsert?: InputMaybe<SectionManageInterestsParentUpsertWithNestedWhereUniqueInput>
}

export type SectionManageInterestsParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionManageInterestsParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionManageInterestsParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionManageInterestsParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionManageInterestsUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionManageInterestsUpdateManyInlineInput = {
  /** Create and connect multiple SectionManageInterests component instances */
  create?: InputMaybe<Array<SectionManageInterestsCreateWithPositionInput>>
  /** Delete multiple SectionManageInterests documents */
  delete?: InputMaybe<Array<SectionManageInterestsWhereUniqueInput>>
  /** Update multiple SectionManageInterests component instances */
  update?: InputMaybe<Array<SectionManageInterestsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionManageInterests component instances */
  upsert?: InputMaybe<Array<SectionManageInterestsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionManageInterestsUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionManageInterestsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionManageInterestsUpdateManyInput
  /** Document search */
  where: SectionManageInterestsWhereInput
}

export type SectionManageInterestsUpdateOneInlineInput = {
  /** Create and connect one SectionManageInterests document */
  create?: InputMaybe<SectionManageInterestsCreateInput>
  /** Delete currently connected SectionManageInterests document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionManageInterests document */
  update?: InputMaybe<SectionManageInterestsUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionManageInterests document */
  upsert?: InputMaybe<SectionManageInterestsUpsertWithNestedWhereUniqueInput>
}

export type SectionManageInterestsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionManageInterestsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionManageInterestsWhereUniqueInput
}

export type SectionManageInterestsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionManageInterestsUpdateInput
  /** Unique document search */
  where: SectionManageInterestsWhereUniqueInput
}

export type SectionManageInterestsUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionManageInterestsCreateInput
  /** Update document if it exists */
  update: SectionManageInterestsUpdateInput
}

export type SectionManageInterestsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionManageInterestsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionManageInterestsWhereUniqueInput
}

export type SectionManageInterestsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionManageInterestsUpsertInput
  /** Unique document search */
  where: SectionManageInterestsWhereUniqueInput
}

/** Identifies documents */
export type SectionManageInterestsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionManageInterestsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionManageInterests record uniquely */
export type SectionManageInterestsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing a filterable grid of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitors = Entity & {
  __typename?: 'SectionMyomrExhibitors'
  /** Call to action element */
  cta?: Maybe<CallToAction>
  description: Scalars['String']['output']
  enableBookmarking?: Maybe<Scalars['Boolean']['output']>
  /** If value set, only those exhibitors will be shown that has corresponding event type associated to them. */
  eventType?: Maybe<SpeakerEventTypes>
  /** Maximum exhibitors shown initially on desktop. */
  exhibitorsLimitDesktop: Scalars['Int']['output']
  /** Maximum exhibitors shown initially on mobile. */
  exhibitorsLimitMobile: Scalars['Int']['output']
  hideDetailPages?: Maybe<Scalars['Boolean']['output']>
  hideFilters?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** This option will set if provided ordered list will be used to filter or to sort the result set. */
  listOption: ResultCreationOptions
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionMyomrExhibitors>
  /** Selected custom sorting model for the exhibitors. */
  order?: Maybe<ExhibitorListOrder>
  premiumOnly?: Maybe<Scalars['Boolean']['output']>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Section showing a filterable grid of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitorsCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section showing a filterable grid of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitorsLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing a filterable grid of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitorsOrderArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionMyomrExhibitorsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionMyomrExhibitorsWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionMyomrExhibitorsConnection = {
  __typename?: 'SectionMyomrExhibitorsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionMyomrExhibitorsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionMyomrExhibitorsCreateInput = {
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  enableBookmarking?: InputMaybe<Scalars['Boolean']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  exhibitorsLimitDesktop: Scalars['Int']['input']
  exhibitorsLimitMobile: Scalars['Int']['input']
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption: ResultCreationOptions
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionMyomrExhibitorsCreateLocalizationsInput>
  order?: InputMaybe<ExhibitorListOrderCreateOneInlineInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionMyomrExhibitorsCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionMyomrExhibitorsCreateLocalizationInput = {
  /** Localization input */
  data: SectionMyomrExhibitorsCreateLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionMyomrExhibitorsCreateLocalizationInput>>
}

export type SectionMyomrExhibitorsCreateManyInlineInput = {
  /** Create and connect multiple existing SectionMyomrExhibitors documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsCreateInput>>
}

export type SectionMyomrExhibitorsCreateOneInlineInput = {
  /** Create and connect one SectionMyomrExhibitors document */
  create?: InputMaybe<SectionMyomrExhibitorsCreateInput>
}

export type SectionMyomrExhibitorsCreateWithPositionInput = {
  /** Document to create */
  data: SectionMyomrExhibitorsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionMyomrExhibitorsEdge = {
  __typename?: 'SectionMyomrExhibitorsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionMyomrExhibitors
}

/** Section showing a sorted list of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitorsList = Entity & {
  __typename?: 'SectionMyomrExhibitorsList'
  description: Scalars['String']['output']
  hideDetailPages?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionMyomrExhibitorsList>
  premiumOnly?: Maybe<Scalars['Boolean']['output']>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Section showing a sorted list of Exhibitors from MyOMR datasource. */
export type SectionMyomrExhibitorsListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionMyomrExhibitorsListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionMyomrExhibitorsListWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionMyomrExhibitorsListConnection = {
  __typename?: 'SectionMyomrExhibitorsListConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionMyomrExhibitorsListEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionMyomrExhibitorsListCreateInput = {
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionMyomrExhibitorsListCreateLocalizationsInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionMyomrExhibitorsListCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionMyomrExhibitorsListCreateLocalizationInput = {
  /** Localization input */
  data: SectionMyomrExhibitorsListCreateLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListCreateLocalizationInput>>
}

export type SectionMyomrExhibitorsListCreateManyInlineInput = {
  /** Create and connect multiple existing SectionMyomrExhibitorsList documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListCreateInput>>
}

export type SectionMyomrExhibitorsListCreateOneInlineInput = {
  /** Create and connect one SectionMyomrExhibitorsList document */
  create?: InputMaybe<SectionMyomrExhibitorsListCreateInput>
}

export type SectionMyomrExhibitorsListCreateWithPositionInput = {
  /** Document to create */
  data: SectionMyomrExhibitorsListCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionMyomrExhibitorsListEdge = {
  __typename?: 'SectionMyomrExhibitorsListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionMyomrExhibitorsList
}

/** Identifies documents */
export type SectionMyomrExhibitorsListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premiumOnly_not?: InputMaybe<Scalars['Boolean']['input']>
}

export enum SectionMyomrExhibitorsListOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HideDetailPagesAsc = 'hideDetailPages_ASC',
  HideDetailPagesDesc = 'hideDetailPages_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PremiumOnlyAsc = 'premiumOnly_ASC',
  PremiumOnlyDesc = 'premiumOnly_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionMyomrExhibitorsListParent = EventPage

export type SectionMyomrExhibitorsListParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionMyomrExhibitorsListParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionMyomrExhibitorsListParentCreateManyInlineInput = {
  /** Connect multiple existing SectionMyomrExhibitorsListParent documents */
  connect?: InputMaybe<Array<SectionMyomrExhibitorsListParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionMyomrExhibitorsListParent documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListParentCreateInput>>
}

export type SectionMyomrExhibitorsListParentCreateOneInlineInput = {
  /** Connect one existing SectionMyomrExhibitorsListParent document */
  connect?: InputMaybe<SectionMyomrExhibitorsListParentWhereUniqueInput>
  /** Create and connect one SectionMyomrExhibitorsListParent document */
  create?: InputMaybe<SectionMyomrExhibitorsListParentCreateInput>
}

export type SectionMyomrExhibitorsListParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionMyomrExhibitorsListParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionMyomrExhibitorsListParent documents */
  connect?: InputMaybe<Array<SectionMyomrExhibitorsListParentConnectInput>>
  /** Create and connect multiple SectionMyomrExhibitorsListParent documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListParentCreateInput>>
  /** Delete multiple SectionMyomrExhibitorsListParent documents */
  delete?: InputMaybe<Array<SectionMyomrExhibitorsListParentWhereUniqueInput>>
  /** Disconnect multiple SectionMyomrExhibitorsListParent documents */
  disconnect?: InputMaybe<Array<SectionMyomrExhibitorsListParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionMyomrExhibitorsListParent documents */
  set?: InputMaybe<Array<SectionMyomrExhibitorsListParentWhereUniqueInput>>
  /** Update multiple SectionMyomrExhibitorsListParent documents */
  update?: InputMaybe<Array<SectionMyomrExhibitorsListParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionMyomrExhibitorsListParent documents */
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsListParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionMyomrExhibitorsListParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionMyomrExhibitorsListParentUpdateOneInlineInput = {
  /** Connect existing SectionMyomrExhibitorsListParent document */
  connect?: InputMaybe<SectionMyomrExhibitorsListParentWhereUniqueInput>
  /** Create and connect one SectionMyomrExhibitorsListParent document */
  create?: InputMaybe<SectionMyomrExhibitorsListParentCreateInput>
  /** Delete currently connected SectionMyomrExhibitorsListParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionMyomrExhibitorsListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionMyomrExhibitorsListParent document */
  update?: InputMaybe<SectionMyomrExhibitorsListParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionMyomrExhibitorsListParent document */
  upsert?: InputMaybe<SectionMyomrExhibitorsListParentUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsListParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsListParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsListParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionMyomrExhibitorsListParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionMyomrExhibitorsListUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionMyomrExhibitorsListUpdateLocalizationsInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsListUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsListUpdateLocalizationInput = {
  data: SectionMyomrExhibitorsListUpdateLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionMyomrExhibitorsListUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsListUpsertLocalizationInput>>
}

export type SectionMyomrExhibitorsListUpdateManyInlineInput = {
  /** Create and connect multiple SectionMyomrExhibitorsList component instances */
  create?: InputMaybe<Array<SectionMyomrExhibitorsListCreateWithPositionInput>>
  /** Delete multiple SectionMyomrExhibitorsList documents */
  delete?: InputMaybe<Array<SectionMyomrExhibitorsListWhereUniqueInput>>
  /** Update multiple SectionMyomrExhibitorsList component instances */
  update?: InputMaybe<Array<SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionMyomrExhibitorsList component instances */
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionMyomrExhibitorsListUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionMyomrExhibitorsListUpdateManyLocalizationsInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsListUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsListUpdateManyLocalizationInput = {
  data: SectionMyomrExhibitorsListUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionMyomrExhibitorsListUpdateManyLocalizationInput>>
}

export type SectionMyomrExhibitorsListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionMyomrExhibitorsListUpdateManyInput
  /** Document search */
  where: SectionMyomrExhibitorsListWhereInput
}

export type SectionMyomrExhibitorsListUpdateOneInlineInput = {
  /** Create and connect one SectionMyomrExhibitorsList document */
  create?: InputMaybe<SectionMyomrExhibitorsListCreateInput>
  /** Delete currently connected SectionMyomrExhibitorsList document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionMyomrExhibitorsList document */
  update?: InputMaybe<SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionMyomrExhibitorsList document */
  upsert?: InputMaybe<SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionMyomrExhibitorsListUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionMyomrExhibitorsListWhereUniqueInput
}

export type SectionMyomrExhibitorsListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionMyomrExhibitorsListUpdateInput
  /** Unique document search */
  where: SectionMyomrExhibitorsListWhereUniqueInput
}

export type SectionMyomrExhibitorsListUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionMyomrExhibitorsListCreateInput
  /** Update document if it exists */
  update: SectionMyomrExhibitorsListUpdateInput
}

export type SectionMyomrExhibitorsListUpsertLocalizationInput = {
  create: SectionMyomrExhibitorsListCreateLocalizationDataInput
  locale: Locale
  update: SectionMyomrExhibitorsListUpdateLocalizationDataInput
}

export type SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionMyomrExhibitorsListUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionMyomrExhibitorsListWhereUniqueInput
}

export type SectionMyomrExhibitorsListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionMyomrExhibitorsListUpsertInput
  /** Unique document search */
  where: SectionMyomrExhibitorsListWhereUniqueInput
}

/** Identifies documents */
export type SectionMyomrExhibitorsListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionMyomrExhibitorsListWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premiumOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionMyomrExhibitorsList record uniquely */
export type SectionMyomrExhibitorsListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Identifies documents */
export type SectionMyomrExhibitorsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  enableBookmarking?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableBookmarking_not?: InputMaybe<Scalars['Boolean']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  exhibitorsLimitDesktop?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  exhibitorsLimitDesktop_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  exhibitorsLimitDesktop_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  exhibitorsLimitDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  exhibitorsLimitDesktop_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  exhibitorsLimitDesktop_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  exhibitorsLimitDesktop_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  exhibitorsLimitDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  exhibitorsLimitMobile?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  exhibitorsLimitMobile_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  exhibitorsLimitMobile_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  exhibitorsLimitMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  exhibitorsLimitMobile_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  exhibitorsLimitMobile_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  exhibitorsLimitMobile_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  exhibitorsLimitMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideFilters_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** All values that are contained in given list. */
  listOption_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  /** Any other value that exists and is not equal to the given value. */
  listOption_not?: InputMaybe<ResultCreationOptions>
  /** All values that are not contained in given list. */
  listOption_not_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  order?: InputMaybe<ExhibitorListOrderWhereInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premiumOnly_not?: InputMaybe<Scalars['Boolean']['input']>
}

export enum SectionMyomrExhibitorsOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EnableBookmarkingAsc = 'enableBookmarking_ASC',
  EnableBookmarkingDesc = 'enableBookmarking_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  ExhibitorsLimitDesktopAsc = 'exhibitorsLimitDesktop_ASC',
  ExhibitorsLimitDesktopDesc = 'exhibitorsLimitDesktop_DESC',
  ExhibitorsLimitMobileAsc = 'exhibitorsLimitMobile_ASC',
  ExhibitorsLimitMobileDesc = 'exhibitorsLimitMobile_DESC',
  HideDetailPagesAsc = 'hideDetailPages_ASC',
  HideDetailPagesDesc = 'hideDetailPages_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ListOptionAsc = 'listOption_ASC',
  ListOptionDesc = 'listOption_DESC',
  PremiumOnlyAsc = 'premiumOnly_ASC',
  PremiumOnlyDesc = 'premiumOnly_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionMyomrExhibitorsParent = EventPage

export type SectionMyomrExhibitorsParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionMyomrExhibitorsParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionMyomrExhibitorsParentCreateManyInlineInput = {
  /** Connect multiple existing SectionMyomrExhibitorsParent documents */
  connect?: InputMaybe<Array<SectionMyomrExhibitorsParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionMyomrExhibitorsParent documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsParentCreateInput>>
}

export type SectionMyomrExhibitorsParentCreateOneInlineInput = {
  /** Connect one existing SectionMyomrExhibitorsParent document */
  connect?: InputMaybe<SectionMyomrExhibitorsParentWhereUniqueInput>
  /** Create and connect one SectionMyomrExhibitorsParent document */
  create?: InputMaybe<SectionMyomrExhibitorsParentCreateInput>
}

export type SectionMyomrExhibitorsParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionMyomrExhibitorsParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionMyomrExhibitorsParent documents */
  connect?: InputMaybe<Array<SectionMyomrExhibitorsParentConnectInput>>
  /** Create and connect multiple SectionMyomrExhibitorsParent documents */
  create?: InputMaybe<Array<SectionMyomrExhibitorsParentCreateInput>>
  /** Delete multiple SectionMyomrExhibitorsParent documents */
  delete?: InputMaybe<Array<SectionMyomrExhibitorsParentWhereUniqueInput>>
  /** Disconnect multiple SectionMyomrExhibitorsParent documents */
  disconnect?: InputMaybe<Array<SectionMyomrExhibitorsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionMyomrExhibitorsParent documents */
  set?: InputMaybe<Array<SectionMyomrExhibitorsParentWhereUniqueInput>>
  /** Update multiple SectionMyomrExhibitorsParent documents */
  update?: InputMaybe<Array<SectionMyomrExhibitorsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionMyomrExhibitorsParent documents */
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionMyomrExhibitorsParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionMyomrExhibitorsParentUpdateOneInlineInput = {
  /** Connect existing SectionMyomrExhibitorsParent document */
  connect?: InputMaybe<SectionMyomrExhibitorsParentWhereUniqueInput>
  /** Create and connect one SectionMyomrExhibitorsParent document */
  create?: InputMaybe<SectionMyomrExhibitorsParentCreateInput>
  /** Delete currently connected SectionMyomrExhibitorsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionMyomrExhibitorsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionMyomrExhibitorsParent document */
  update?: InputMaybe<SectionMyomrExhibitorsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionMyomrExhibitorsParent document */
  upsert?: InputMaybe<SectionMyomrExhibitorsParentUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionMyomrExhibitorsParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionMyomrExhibitorsUpdateInput = {
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  enableBookmarking?: InputMaybe<Scalars['Boolean']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  exhibitorsLimitDesktop?: InputMaybe<Scalars['Int']['input']>
  exhibitorsLimitMobile?: InputMaybe<Scalars['Int']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionMyomrExhibitorsUpdateLocalizationsInput>
  order?: InputMaybe<ExhibitorListOrderUpdateOneInlineInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsUpdateLocalizationInput = {
  data: SectionMyomrExhibitorsUpdateLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionMyomrExhibitorsCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionMyomrExhibitorsUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsUpsertLocalizationInput>>
}

export type SectionMyomrExhibitorsUpdateManyInlineInput = {
  /** Create and connect multiple SectionMyomrExhibitors component instances */
  create?: InputMaybe<Array<SectionMyomrExhibitorsCreateWithPositionInput>>
  /** Delete multiple SectionMyomrExhibitors documents */
  delete?: InputMaybe<Array<SectionMyomrExhibitorsWhereUniqueInput>>
  /** Update multiple SectionMyomrExhibitors component instances */
  update?: InputMaybe<Array<SectionMyomrExhibitorsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionMyomrExhibitors component instances */
  upsert?: InputMaybe<Array<SectionMyomrExhibitorsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionMyomrExhibitorsUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  enableBookmarking?: InputMaybe<Scalars['Boolean']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  exhibitorsLimitDesktop?: InputMaybe<Scalars['Int']['input']>
  exhibitorsLimitMobile?: InputMaybe<Scalars['Int']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionMyomrExhibitorsUpdateManyLocalizationsInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionMyomrExhibitorsUpdateManyLocalizationInput = {
  data: SectionMyomrExhibitorsUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionMyomrExhibitorsUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionMyomrExhibitorsUpdateManyLocalizationInput>>
}

export type SectionMyomrExhibitorsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionMyomrExhibitorsUpdateManyInput
  /** Document search */
  where: SectionMyomrExhibitorsWhereInput
}

export type SectionMyomrExhibitorsUpdateOneInlineInput = {
  /** Create and connect one SectionMyomrExhibitors document */
  create?: InputMaybe<SectionMyomrExhibitorsCreateInput>
  /** Delete currently connected SectionMyomrExhibitors document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionMyomrExhibitors document */
  update?: InputMaybe<SectionMyomrExhibitorsUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionMyomrExhibitors document */
  upsert?: InputMaybe<SectionMyomrExhibitorsUpsertWithNestedWhereUniqueInput>
}

export type SectionMyomrExhibitorsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionMyomrExhibitorsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionMyomrExhibitorsWhereUniqueInput
}

export type SectionMyomrExhibitorsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionMyomrExhibitorsUpdateInput
  /** Unique document search */
  where: SectionMyomrExhibitorsWhereUniqueInput
}

export type SectionMyomrExhibitorsUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionMyomrExhibitorsCreateInput
  /** Update document if it exists */
  update: SectionMyomrExhibitorsUpdateInput
}

export type SectionMyomrExhibitorsUpsertLocalizationInput = {
  create: SectionMyomrExhibitorsCreateLocalizationDataInput
  locale: Locale
  update: SectionMyomrExhibitorsUpdateLocalizationDataInput
}

export type SectionMyomrExhibitorsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionMyomrExhibitorsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionMyomrExhibitorsWhereUniqueInput
}

export type SectionMyomrExhibitorsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionMyomrExhibitorsUpsertInput
  /** Unique document search */
  where: SectionMyomrExhibitorsWhereUniqueInput
}

/** Identifies documents */
export type SectionMyomrExhibitorsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionMyomrExhibitorsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  enableBookmarking?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableBookmarking_not?: InputMaybe<Scalars['Boolean']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  exhibitorsLimitDesktop?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  exhibitorsLimitDesktop_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  exhibitorsLimitDesktop_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  exhibitorsLimitDesktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  exhibitorsLimitDesktop_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  exhibitorsLimitDesktop_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  exhibitorsLimitDesktop_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  exhibitorsLimitDesktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  exhibitorsLimitMobile?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  exhibitorsLimitMobile_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  exhibitorsLimitMobile_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  exhibitorsLimitMobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  exhibitorsLimitMobile_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  exhibitorsLimitMobile_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  exhibitorsLimitMobile_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  exhibitorsLimitMobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideFilters_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** All values that are contained in given list. */
  listOption_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  /** Any other value that exists and is not equal to the given value. */
  listOption_not?: InputMaybe<ResultCreationOptions>
  /** All values that are not contained in given list. */
  listOption_not_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  order?: InputMaybe<ExhibitorListOrderWhereInput>
  premiumOnly?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  premiumOnly_not?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionMyomrExhibitors record uniquely */
export type SectionMyomrExhibitorsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing newsletter subscription form. */
export type SectionNewsletter = Entity & {
  __typename?: 'SectionNewsletter'
  audience?: Maybe<Scalars['String']['output']>
  cta?: Maybe<CallToAction>
  description: Scalars['String']['output']
  disclaimer?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  linkTarget?: Maybe<Scalars['String']['output']>
  linkText?: Maybe<Scalars['String']['output']>
  linkUrl?: Maybe<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionNewsletter>
  /** System stage field */
  stage: Stage
  successMessage?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
}

/** Section showing newsletter subscription form. */
export type SectionNewsletterCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section showing newsletter subscription form. */
export type SectionNewsletterLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing newsletter audiences where user can subscribe. */
export type SectionNewsletterAudience = Entity & {
  __typename?: 'SectionNewsletterAudience'
  audiences: Array<NewsletterAudience>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System stage field */
  stage: Stage
}

/** Section showing newsletter audiences where user can subscribe. */
export type SectionNewsletterAudienceAudiencesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<NewsletterAudienceOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<NewsletterAudienceWhereInput>
}

export type SectionNewsletterAudienceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionNewsletterAudienceWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionNewsletterAudienceConnection = {
  __typename?: 'SectionNewsletterAudienceConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionNewsletterAudienceEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionNewsletterAudienceCreateInput = {
  audiences?: InputMaybe<NewsletterAudienceCreateManyInlineInput>
}

export type SectionNewsletterAudienceCreateManyInlineInput = {
  /** Create and connect multiple existing SectionNewsletterAudience documents */
  create?: InputMaybe<Array<SectionNewsletterAudienceCreateInput>>
}

export type SectionNewsletterAudienceCreateOneInlineInput = {
  /** Create and connect one SectionNewsletterAudience document */
  create?: InputMaybe<SectionNewsletterAudienceCreateInput>
}

export type SectionNewsletterAudienceCreateWithPositionInput = {
  /** Document to create */
  data: SectionNewsletterAudienceCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionNewsletterAudienceEdge = {
  __typename?: 'SectionNewsletterAudienceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionNewsletterAudience
}

/** Identifies documents */
export type SectionNewsletterAudienceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  audiences_every?: InputMaybe<NewsletterAudienceWhereInput>
  audiences_none?: InputMaybe<NewsletterAudienceWhereInput>
  audiences_some?: InputMaybe<NewsletterAudienceWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionNewsletterAudienceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionNewsletterAudienceParent = LandingPage

export type SectionNewsletterAudienceParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionNewsletterAudienceParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionNewsletterAudienceParentCreateManyInlineInput = {
  /** Connect multiple existing SectionNewsletterAudienceParent documents */
  connect?: InputMaybe<Array<SectionNewsletterAudienceParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionNewsletterAudienceParent documents */
  create?: InputMaybe<Array<SectionNewsletterAudienceParentCreateInput>>
}

export type SectionNewsletterAudienceParentCreateOneInlineInput = {
  /** Connect one existing SectionNewsletterAudienceParent document */
  connect?: InputMaybe<SectionNewsletterAudienceParentWhereUniqueInput>
  /** Create and connect one SectionNewsletterAudienceParent document */
  create?: InputMaybe<SectionNewsletterAudienceParentCreateInput>
}

export type SectionNewsletterAudienceParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionNewsletterAudienceParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionNewsletterAudienceParent documents */
  connect?: InputMaybe<Array<SectionNewsletterAudienceParentConnectInput>>
  /** Create and connect multiple SectionNewsletterAudienceParent documents */
  create?: InputMaybe<Array<SectionNewsletterAudienceParentCreateInput>>
  /** Delete multiple SectionNewsletterAudienceParent documents */
  delete?: InputMaybe<Array<SectionNewsletterAudienceParentWhereUniqueInput>>
  /** Disconnect multiple SectionNewsletterAudienceParent documents */
  disconnect?: InputMaybe<Array<SectionNewsletterAudienceParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionNewsletterAudienceParent documents */
  set?: InputMaybe<Array<SectionNewsletterAudienceParentWhereUniqueInput>>
  /** Update multiple SectionNewsletterAudienceParent documents */
  update?: InputMaybe<Array<SectionNewsletterAudienceParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionNewsletterAudienceParent documents */
  upsert?: InputMaybe<Array<SectionNewsletterAudienceParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionNewsletterAudienceParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionNewsletterAudienceParentUpdateOneInlineInput = {
  /** Connect existing SectionNewsletterAudienceParent document */
  connect?: InputMaybe<SectionNewsletterAudienceParentWhereUniqueInput>
  /** Create and connect one SectionNewsletterAudienceParent document */
  create?: InputMaybe<SectionNewsletterAudienceParentCreateInput>
  /** Delete currently connected SectionNewsletterAudienceParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionNewsletterAudienceParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionNewsletterAudienceParent document */
  update?: InputMaybe<SectionNewsletterAudienceParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionNewsletterAudienceParent document */
  upsert?: InputMaybe<SectionNewsletterAudienceParentUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterAudienceParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionNewsletterAudienceParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterAudienceParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionNewsletterAudienceParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionNewsletterAudienceUpdateInput = {
  audiences?: InputMaybe<NewsletterAudienceUpdateManyInlineInput>
}

export type SectionNewsletterAudienceUpdateManyInlineInput = {
  /** Create and connect multiple SectionNewsletterAudience component instances */
  create?: InputMaybe<Array<SectionNewsletterAudienceCreateWithPositionInput>>
  /** Delete multiple SectionNewsletterAudience documents */
  delete?: InputMaybe<Array<SectionNewsletterAudienceWhereUniqueInput>>
  /** Update multiple SectionNewsletterAudience component instances */
  update?: InputMaybe<Array<SectionNewsletterAudienceUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionNewsletterAudience component instances */
  upsert?: InputMaybe<Array<SectionNewsletterAudienceUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionNewsletterAudienceUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionNewsletterAudienceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionNewsletterAudienceUpdateManyInput
  /** Document search */
  where: SectionNewsletterAudienceWhereInput
}

export type SectionNewsletterAudienceUpdateOneInlineInput = {
  /** Create and connect one SectionNewsletterAudience document */
  create?: InputMaybe<SectionNewsletterAudienceCreateInput>
  /** Delete currently connected SectionNewsletterAudience document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionNewsletterAudience document */
  update?: InputMaybe<SectionNewsletterAudienceUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionNewsletterAudience document */
  upsert?: InputMaybe<SectionNewsletterAudienceUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterAudienceUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionNewsletterAudienceUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionNewsletterAudienceWhereUniqueInput
}

export type SectionNewsletterAudienceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionNewsletterAudienceUpdateInput
  /** Unique document search */
  where: SectionNewsletterAudienceWhereUniqueInput
}

export type SectionNewsletterAudienceUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionNewsletterAudienceCreateInput
  /** Update document if it exists */
  update: SectionNewsletterAudienceUpdateInput
}

export type SectionNewsletterAudienceUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionNewsletterAudienceUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionNewsletterAudienceWhereUniqueInput
}

export type SectionNewsletterAudienceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionNewsletterAudienceUpsertInput
  /** Unique document search */
  where: SectionNewsletterAudienceWhereUniqueInput
}

/** Identifies documents */
export type SectionNewsletterAudienceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionNewsletterAudienceWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  audiences_every?: InputMaybe<NewsletterAudienceWhereInput>
  audiences_none?: InputMaybe<NewsletterAudienceWhereInput>
  audiences_some?: InputMaybe<NewsletterAudienceWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionNewsletterAudience record uniquely */
export type SectionNewsletterAudienceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionNewsletterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionNewsletterWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionNewsletterConnection = {
  __typename?: 'SectionNewsletterConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionNewsletterEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionNewsletterCreateInput = {
  audience?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  /** disclaimer input for default locale (de) */
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkTarget?: InputMaybe<Scalars['String']['input']>
  /** linkText input for default locale (de) */
  linkText?: InputMaybe<Scalars['String']['input']>
  linkUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionNewsletterCreateLocalizationsInput>
  /** successMessage input for default locale (de) */
  successMessage?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionNewsletterCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkText?: InputMaybe<Scalars['String']['input']>
  successMessage?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type SectionNewsletterCreateLocalizationInput = {
  /** Localization input */
  data: SectionNewsletterCreateLocalizationDataInput
  locale: Locale
}

export type SectionNewsletterCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionNewsletterCreateLocalizationInput>>
}

export type SectionNewsletterCreateManyInlineInput = {
  /** Create and connect multiple existing SectionNewsletter documents */
  create?: InputMaybe<Array<SectionNewsletterCreateInput>>
}

export type SectionNewsletterCreateOneInlineInput = {
  /** Create and connect one SectionNewsletter document */
  create?: InputMaybe<SectionNewsletterCreateInput>
}

export type SectionNewsletterCreateWithPositionInput = {
  /** Document to create */
  data: SectionNewsletterCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionNewsletterEdge = {
  __typename?: 'SectionNewsletterEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionNewsletter
}

/** Identifies documents */
export type SectionNewsletterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  audience?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  audience_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  audience_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  audience_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  audience_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  audience_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  audience_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  audience_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  audience_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  audience_starts_with?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  linkTarget?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkTarget_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkTarget_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkTarget_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkTarget_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkTarget_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkTarget_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkTarget_starts_with?: InputMaybe<Scalars['String']['input']>
  linkUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SectionNewsletterOrderByInput {
  AudienceAsc = 'audience_ASC',
  AudienceDesc = 'audience_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisclaimerAsc = 'disclaimer_ASC',
  DisclaimerDesc = 'disclaimer_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkTargetAsc = 'linkTarget_ASC',
  LinkTargetDesc = 'linkTarget_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  LinkUrlAsc = 'linkUrl_ASC',
  LinkUrlDesc = 'linkUrl_DESC',
  SuccessMessageAsc = 'successMessage_ASC',
  SuccessMessageDesc = 'successMessage_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionNewsletterParent = EventPage | LandingPage

export type SectionNewsletterParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionNewsletterParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionNewsletterParentCreateManyInlineInput = {
  /** Connect multiple existing SectionNewsletterParent documents */
  connect?: InputMaybe<Array<SectionNewsletterParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionNewsletterParent documents */
  create?: InputMaybe<Array<SectionNewsletterParentCreateInput>>
}

export type SectionNewsletterParentCreateOneInlineInput = {
  /** Connect one existing SectionNewsletterParent document */
  connect?: InputMaybe<SectionNewsletterParentWhereUniqueInput>
  /** Create and connect one SectionNewsletterParent document */
  create?: InputMaybe<SectionNewsletterParentCreateInput>
}

export type SectionNewsletterParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionNewsletterParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionNewsletterParent documents */
  connect?: InputMaybe<Array<SectionNewsletterParentConnectInput>>
  /** Create and connect multiple SectionNewsletterParent documents */
  create?: InputMaybe<Array<SectionNewsletterParentCreateInput>>
  /** Delete multiple SectionNewsletterParent documents */
  delete?: InputMaybe<Array<SectionNewsletterParentWhereUniqueInput>>
  /** Disconnect multiple SectionNewsletterParent documents */
  disconnect?: InputMaybe<Array<SectionNewsletterParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionNewsletterParent documents */
  set?: InputMaybe<Array<SectionNewsletterParentWhereUniqueInput>>
  /** Update multiple SectionNewsletterParent documents */
  update?: InputMaybe<Array<SectionNewsletterParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionNewsletterParent documents */
  upsert?: InputMaybe<Array<SectionNewsletterParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionNewsletterParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionNewsletterParentUpdateOneInlineInput = {
  /** Connect existing SectionNewsletterParent document */
  connect?: InputMaybe<SectionNewsletterParentWhereUniqueInput>
  /** Create and connect one SectionNewsletterParent document */
  create?: InputMaybe<SectionNewsletterParentCreateInput>
  /** Delete currently connected SectionNewsletterParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionNewsletterParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionNewsletterParent document */
  update?: InputMaybe<SectionNewsletterParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionNewsletterParent document */
  upsert?: InputMaybe<SectionNewsletterParentUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionNewsletterParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionNewsletterParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionNewsletterUpdateInput = {
  audience?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** disclaimer input for default locale (de) */
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkTarget?: InputMaybe<Scalars['String']['input']>
  /** linkText input for default locale (de) */
  linkText?: InputMaybe<Scalars['String']['input']>
  linkUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionNewsletterUpdateLocalizationsInput>
  /** successMessage input for default locale (de) */
  successMessage?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionNewsletterUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkText?: InputMaybe<Scalars['String']['input']>
  successMessage?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionNewsletterUpdateLocalizationInput = {
  data: SectionNewsletterUpdateLocalizationDataInput
  locale: Locale
}

export type SectionNewsletterUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionNewsletterCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionNewsletterUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionNewsletterUpsertLocalizationInput>>
}

export type SectionNewsletterUpdateManyInlineInput = {
  /** Create and connect multiple SectionNewsletter component instances */
  create?: InputMaybe<Array<SectionNewsletterCreateWithPositionInput>>
  /** Delete multiple SectionNewsletter documents */
  delete?: InputMaybe<Array<SectionNewsletterWhereUniqueInput>>
  /** Update multiple SectionNewsletter component instances */
  update?: InputMaybe<Array<SectionNewsletterUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionNewsletter component instances */
  upsert?: InputMaybe<Array<SectionNewsletterUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionNewsletterUpdateManyInput = {
  audience?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** disclaimer input for default locale (de) */
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkTarget?: InputMaybe<Scalars['String']['input']>
  /** linkText input for default locale (de) */
  linkText?: InputMaybe<Scalars['String']['input']>
  linkUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionNewsletterUpdateManyLocalizationsInput>
  /** successMessage input for default locale (de) */
  successMessage?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionNewsletterUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  disclaimer?: InputMaybe<Scalars['String']['input']>
  linkText?: InputMaybe<Scalars['String']['input']>
  successMessage?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionNewsletterUpdateManyLocalizationInput = {
  data: SectionNewsletterUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionNewsletterUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionNewsletterUpdateManyLocalizationInput>>
}

export type SectionNewsletterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionNewsletterUpdateManyInput
  /** Document search */
  where: SectionNewsletterWhereInput
}

export type SectionNewsletterUpdateOneInlineInput = {
  /** Create and connect one SectionNewsletter document */
  create?: InputMaybe<SectionNewsletterCreateInput>
  /** Delete currently connected SectionNewsletter document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionNewsletter document */
  update?: InputMaybe<SectionNewsletterUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionNewsletter document */
  upsert?: InputMaybe<SectionNewsletterUpsertWithNestedWhereUniqueInput>
}

export type SectionNewsletterUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionNewsletterUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionNewsletterWhereUniqueInput
}

export type SectionNewsletterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionNewsletterUpdateInput
  /** Unique document search */
  where: SectionNewsletterWhereUniqueInput
}

export type SectionNewsletterUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionNewsletterCreateInput
  /** Update document if it exists */
  update: SectionNewsletterUpdateInput
}

export type SectionNewsletterUpsertLocalizationInput = {
  create: SectionNewsletterCreateLocalizationDataInput
  locale: Locale
  update: SectionNewsletterUpdateLocalizationDataInput
}

export type SectionNewsletterUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionNewsletterUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionNewsletterWhereUniqueInput
}

export type SectionNewsletterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionNewsletterUpsertInput
  /** Unique document search */
  where: SectionNewsletterWhereUniqueInput
}

/** Identifies documents */
export type SectionNewsletterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionNewsletterWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  audience?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  audience_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  audience_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  audience_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  audience_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  audience_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  audience_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  audience_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  audience_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  audience_starts_with?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  disclaimer?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  disclaimer_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  disclaimer_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  disclaimer_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  disclaimer_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  disclaimer_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  disclaimer_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  disclaimer_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  linkTarget?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkTarget_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkTarget_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkTarget_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkTarget_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkTarget_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkTarget_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkTarget_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkTarget_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkTarget_starts_with?: InputMaybe<Scalars['String']['input']>
  linkText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkText_starts_with?: InputMaybe<Scalars['String']['input']>
  linkUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  successMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  successMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  successMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  successMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  successMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  successMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  successMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  successMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  successMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  successMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionNewsletter record uniquely */
export type SectionNewsletterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Logo field of OMR partners with external linking. Partners/Media Partners etc. */
export type SectionOmrPartner = Entity & {
  __typename?: 'SectionOMRPartner'
  /** Render partner logos as slider with horizontal auto scroll */
  asSlider?: Maybe<Scalars['Boolean']['output']>
  darkTheme?: Maybe<Scalars['Boolean']['output']>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionOmrPartner>
  omrPartners: Array<OmrPartner>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  title?: Maybe<Scalars['String']['output']>
}

/** Logo field of OMR partners with external linking. Partners/Media Partners etc. */
export type SectionOmrPartnerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Logo field of OMR partners with external linking. Partners/Media Partners etc. */
export type SectionOmrPartnerOmrPartnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<OmrPartnerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<OmrPartnerWhereInput>
}

export type SectionOmrPartnerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionOmrPartnerWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionOmrPartnerConnection = {
  __typename?: 'SectionOMRPartnerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionOmrPartnerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionOmrPartnerCreateInput = {
  asSlider?: InputMaybe<Scalars['Boolean']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionOmrPartnerCreateLocalizationsInput>
  omrPartners?: InputMaybe<OmrPartnerCreateManyInlineInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerCreateLocalizationInput = {
  /** Localization input */
  data: SectionOmrPartnerCreateLocalizationDataInput
  locale: Locale
}

export type SectionOmrPartnerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionOmrPartnerCreateLocalizationInput>>
}

export type SectionOmrPartnerCreateManyInlineInput = {
  /** Create and connect multiple existing SectionOMRPartner documents */
  create?: InputMaybe<Array<SectionOmrPartnerCreateInput>>
}

export type SectionOmrPartnerCreateOneInlineInput = {
  /** Create and connect one SectionOMRPartner document */
  create?: InputMaybe<SectionOmrPartnerCreateInput>
}

export type SectionOmrPartnerCreateWithPositionInput = {
  /** Document to create */
  data: SectionOmrPartnerCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionOmrPartnerEdge = {
  __typename?: 'SectionOMRPartnerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionOmrPartner
}

/** Identifies documents */
export type SectionOmrPartnerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  asSlider?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  asSlider_not?: InputMaybe<Scalars['Boolean']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrPartners_every?: InputMaybe<OmrPartnerWhereInput>
  omrPartners_none?: InputMaybe<OmrPartnerWhereInput>
  omrPartners_some?: InputMaybe<OmrPartnerWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
}

export enum SectionOmrPartnerOrderByInput {
  AsSliderAsc = 'asSlider_ASC',
  AsSliderDesc = 'asSlider_DESC',
  DarkThemeAsc = 'darkTheme_ASC',
  DarkThemeDesc = 'darkTheme_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionOmrPartnerParent = EventPage | LandingPage

export type SectionOmrPartnerParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionOmrPartnerParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionOmrPartnerParentCreateManyInlineInput = {
  /** Connect multiple existing SectionOMRPartnerParent documents */
  connect?: InputMaybe<Array<SectionOmrPartnerParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionOMRPartnerParent documents */
  create?: InputMaybe<Array<SectionOmrPartnerParentCreateInput>>
}

export type SectionOmrPartnerParentCreateOneInlineInput = {
  /** Connect one existing SectionOMRPartnerParent document */
  connect?: InputMaybe<SectionOmrPartnerParentWhereUniqueInput>
  /** Create and connect one SectionOMRPartnerParent document */
  create?: InputMaybe<SectionOmrPartnerParentCreateInput>
}

export type SectionOmrPartnerParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionOmrPartnerParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionOMRPartnerParent documents */
  connect?: InputMaybe<Array<SectionOmrPartnerParentConnectInput>>
  /** Create and connect multiple SectionOMRPartnerParent documents */
  create?: InputMaybe<Array<SectionOmrPartnerParentCreateInput>>
  /** Delete multiple SectionOMRPartnerParent documents */
  delete?: InputMaybe<Array<SectionOmrPartnerParentWhereUniqueInput>>
  /** Disconnect multiple SectionOMRPartnerParent documents */
  disconnect?: InputMaybe<Array<SectionOmrPartnerParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionOMRPartnerParent documents */
  set?: InputMaybe<Array<SectionOmrPartnerParentWhereUniqueInput>>
  /** Update multiple SectionOMRPartnerParent documents */
  update?: InputMaybe<Array<SectionOmrPartnerParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionOMRPartnerParent documents */
  upsert?: InputMaybe<Array<SectionOmrPartnerParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionOmrPartnerParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionOmrPartnerParentUpdateOneInlineInput = {
  /** Connect existing SectionOMRPartnerParent document */
  connect?: InputMaybe<SectionOmrPartnerParentWhereUniqueInput>
  /** Create and connect one SectionOMRPartnerParent document */
  create?: InputMaybe<SectionOmrPartnerParentCreateInput>
  /** Delete currently connected SectionOMRPartnerParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionOMRPartnerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionOMRPartnerParent document */
  update?: InputMaybe<SectionOmrPartnerParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionOMRPartnerParent document */
  upsert?: InputMaybe<SectionOmrPartnerParentUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrPartnerParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionOmrPartnerParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrPartnerParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionOmrPartnerParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionOmrPartnerUpdateInput = {
  asSlider?: InputMaybe<Scalars['Boolean']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionOmrPartnerUpdateLocalizationsInput>
  omrPartners?: InputMaybe<OmrPartnerUpdateManyInlineInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerUpdateLocalizationInput = {
  data: SectionOmrPartnerUpdateLocalizationDataInput
  locale: Locale
}

export type SectionOmrPartnerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionOmrPartnerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionOmrPartnerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionOmrPartnerUpsertLocalizationInput>>
}

export type SectionOmrPartnerUpdateManyInlineInput = {
  /** Create and connect multiple SectionOMRPartner component instances */
  create?: InputMaybe<Array<SectionOmrPartnerCreateWithPositionInput>>
  /** Delete multiple SectionOMRPartner documents */
  delete?: InputMaybe<Array<SectionOmrPartnerWhereUniqueInput>>
  /** Update multiple SectionOMRPartner component instances */
  update?: InputMaybe<Array<SectionOmrPartnerUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionOMRPartner component instances */
  upsert?: InputMaybe<Array<SectionOmrPartnerUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionOmrPartnerUpdateManyInput = {
  asSlider?: InputMaybe<Scalars['Boolean']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionOmrPartnerUpdateManyLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrPartnerUpdateManyLocalizationInput = {
  data: SectionOmrPartnerUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionOmrPartnerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionOmrPartnerUpdateManyLocalizationInput>>
}

export type SectionOmrPartnerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionOmrPartnerUpdateManyInput
  /** Document search */
  where: SectionOmrPartnerWhereInput
}

export type SectionOmrPartnerUpdateOneInlineInput = {
  /** Create and connect one SectionOMRPartner document */
  create?: InputMaybe<SectionOmrPartnerCreateInput>
  /** Delete currently connected SectionOMRPartner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionOMRPartner document */
  update?: InputMaybe<SectionOmrPartnerUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionOMRPartner document */
  upsert?: InputMaybe<SectionOmrPartnerUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrPartnerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionOmrPartnerUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionOmrPartnerWhereUniqueInput
}

export type SectionOmrPartnerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionOmrPartnerUpdateInput
  /** Unique document search */
  where: SectionOmrPartnerWhereUniqueInput
}

export type SectionOmrPartnerUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionOmrPartnerCreateInput
  /** Update document if it exists */
  update: SectionOmrPartnerUpdateInput
}

export type SectionOmrPartnerUpsertLocalizationInput = {
  create: SectionOmrPartnerCreateLocalizationDataInput
  locale: Locale
  update: SectionOmrPartnerUpdateLocalizationDataInput
}

export type SectionOmrPartnerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionOmrPartnerUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionOmrPartnerWhereUniqueInput
}

export type SectionOmrPartnerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionOmrPartnerUpsertInput
  /** Unique document search */
  where: SectionOmrPartnerWhereUniqueInput
}

/** Identifies documents */
export type SectionOmrPartnerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionOmrPartnerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  asSlider?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  asSlider_not?: InputMaybe<Scalars['Boolean']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrPartners_every?: InputMaybe<OmrPartnerWhereInput>
  omrPartners_none?: InputMaybe<OmrPartnerWhereInput>
  omrPartners_some?: InputMaybe<OmrPartnerWhereInput>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionOMRPartner record uniquely */
export type SectionOmrPartnerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionOmrProductCard = Entity & {
  __typename?: 'SectionOmrProductCard'
  /** The unique identifier */
  id: Scalars['ID']['output']
  omrProductCards: Array<OmrProductCard>
  /** System stage field */
  stage: Stage
}

export type SectionOmrProductCardOmrProductCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<OmrProductCardOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<OmrProductCardWhereInput>
}

export type SectionOmrProductCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionOmrProductCardWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionOmrProductCardConnection = {
  __typename?: 'SectionOmrProductCardConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionOmrProductCardEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionOmrProductCardCreateInput = {
  omrProductCards?: InputMaybe<OmrProductCardCreateManyInlineInput>
}

export type SectionOmrProductCardCreateManyInlineInput = {
  /** Create and connect multiple existing SectionOmrProductCard documents */
  create?: InputMaybe<Array<SectionOmrProductCardCreateInput>>
}

export type SectionOmrProductCardCreateOneInlineInput = {
  /** Create and connect one SectionOmrProductCard document */
  create?: InputMaybe<SectionOmrProductCardCreateInput>
}

export type SectionOmrProductCardCreateWithPositionInput = {
  /** Document to create */
  data: SectionOmrProductCardCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionOmrProductCardEdge = {
  __typename?: 'SectionOmrProductCardEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionOmrProductCard
}

/** Identifies documents */
export type SectionOmrProductCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrProductCards_every?: InputMaybe<OmrProductCardWhereInput>
  omrProductCards_none?: InputMaybe<OmrProductCardWhereInput>
  omrProductCards_some?: InputMaybe<OmrProductCardWhereInput>
}

export enum SectionOmrProductCardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionOmrProductCardParent = LandingPage

export type SectionOmrProductCardParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionOmrProductCardParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionOmrProductCardParentCreateManyInlineInput = {
  /** Connect multiple existing SectionOmrProductCardParent documents */
  connect?: InputMaybe<Array<SectionOmrProductCardParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionOmrProductCardParent documents */
  create?: InputMaybe<Array<SectionOmrProductCardParentCreateInput>>
}

export type SectionOmrProductCardParentCreateOneInlineInput = {
  /** Connect one existing SectionOmrProductCardParent document */
  connect?: InputMaybe<SectionOmrProductCardParentWhereUniqueInput>
  /** Create and connect one SectionOmrProductCardParent document */
  create?: InputMaybe<SectionOmrProductCardParentCreateInput>
}

export type SectionOmrProductCardParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionOmrProductCardParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionOmrProductCardParent documents */
  connect?: InputMaybe<Array<SectionOmrProductCardParentConnectInput>>
  /** Create and connect multiple SectionOmrProductCardParent documents */
  create?: InputMaybe<Array<SectionOmrProductCardParentCreateInput>>
  /** Delete multiple SectionOmrProductCardParent documents */
  delete?: InputMaybe<Array<SectionOmrProductCardParentWhereUniqueInput>>
  /** Disconnect multiple SectionOmrProductCardParent documents */
  disconnect?: InputMaybe<Array<SectionOmrProductCardParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionOmrProductCardParent documents */
  set?: InputMaybe<Array<SectionOmrProductCardParentWhereUniqueInput>>
  /** Update multiple SectionOmrProductCardParent documents */
  update?: InputMaybe<Array<SectionOmrProductCardParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionOmrProductCardParent documents */
  upsert?: InputMaybe<Array<SectionOmrProductCardParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionOmrProductCardParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionOmrProductCardParentUpdateOneInlineInput = {
  /** Connect existing SectionOmrProductCardParent document */
  connect?: InputMaybe<SectionOmrProductCardParentWhereUniqueInput>
  /** Create and connect one SectionOmrProductCardParent document */
  create?: InputMaybe<SectionOmrProductCardParentCreateInput>
  /** Delete currently connected SectionOmrProductCardParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionOmrProductCardParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionOmrProductCardParent document */
  update?: InputMaybe<SectionOmrProductCardParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionOmrProductCardParent document */
  upsert?: InputMaybe<SectionOmrProductCardParentUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrProductCardParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionOmrProductCardParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrProductCardParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionOmrProductCardParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionOmrProductCardUpdateInput = {
  omrProductCards?: InputMaybe<OmrProductCardUpdateManyInlineInput>
}

export type SectionOmrProductCardUpdateManyInlineInput = {
  /** Create and connect multiple SectionOmrProductCard component instances */
  create?: InputMaybe<Array<SectionOmrProductCardCreateWithPositionInput>>
  /** Delete multiple SectionOmrProductCard documents */
  delete?: InputMaybe<Array<SectionOmrProductCardWhereUniqueInput>>
  /** Update multiple SectionOmrProductCard component instances */
  update?: InputMaybe<Array<SectionOmrProductCardUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionOmrProductCard component instances */
  upsert?: InputMaybe<Array<SectionOmrProductCardUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionOmrProductCardUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionOmrProductCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionOmrProductCardUpdateManyInput
  /** Document search */
  where: SectionOmrProductCardWhereInput
}

export type SectionOmrProductCardUpdateOneInlineInput = {
  /** Create and connect one SectionOmrProductCard document */
  create?: InputMaybe<SectionOmrProductCardCreateInput>
  /** Delete currently connected SectionOmrProductCard document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionOmrProductCard document */
  update?: InputMaybe<SectionOmrProductCardUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionOmrProductCard document */
  upsert?: InputMaybe<SectionOmrProductCardUpsertWithNestedWhereUniqueInput>
}

export type SectionOmrProductCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionOmrProductCardUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionOmrProductCardWhereUniqueInput
}

export type SectionOmrProductCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionOmrProductCardUpdateInput
  /** Unique document search */
  where: SectionOmrProductCardWhereUniqueInput
}

export type SectionOmrProductCardUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionOmrProductCardCreateInput
  /** Update document if it exists */
  update: SectionOmrProductCardUpdateInput
}

export type SectionOmrProductCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionOmrProductCardUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionOmrProductCardWhereUniqueInput
}

export type SectionOmrProductCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionOmrProductCardUpsertInput
  /** Unique document search */
  where: SectionOmrProductCardWhereUniqueInput
}

/** Identifies documents */
export type SectionOmrProductCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionOmrProductCardWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  omrProductCards_every?: InputMaybe<OmrProductCardWhereInput>
  omrProductCards_none?: InputMaybe<OmrProductCardWhereInput>
  omrProductCards_some?: InputMaybe<OmrProductCardWhereInput>
}

/** References SectionOmrProductCard record uniquely */
export type SectionOmrProductCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionPadding {
  Extraskinny = 'extraskinny',
  NoBottom = 'noBottom',
  NoTop = 'noTop',
  None = 'none',
  ReducedBottom = 'reducedBottom',
  ReducedTop = 'reducedTop',
  Skinny = 'skinny',
}

/** Display a partner / partners who are the main sponsors of an event. */
export type SectionPoweredBy = Entity & {
  __typename?: 'SectionPoweredBy'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionPoweredBy>
  logo?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

/** Display a partner / partners who are the main sponsors of an event. */
export type SectionPoweredByLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Display a partner / partners who are the main sponsors of an event. */
export type SectionPoweredByLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionPoweredByConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionPoweredByWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionPoweredByConnection = {
  __typename?: 'SectionPoweredByConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionPoweredByEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionPoweredByCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionPoweredByCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByCreateLocalizationInput = {
  /** Localization input */
  data: SectionPoweredByCreateLocalizationDataInput
  locale: Locale
}

export type SectionPoweredByCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionPoweredByCreateLocalizationInput>>
}

export type SectionPoweredByCreateManyInlineInput = {
  /** Create and connect multiple existing SectionPoweredBy documents */
  create?: InputMaybe<Array<SectionPoweredByCreateInput>>
}

export type SectionPoweredByCreateOneInlineInput = {
  /** Create and connect one SectionPoweredBy document */
  create?: InputMaybe<SectionPoweredByCreateInput>
}

export type SectionPoweredByCreateWithPositionInput = {
  /** Document to create */
  data: SectionPoweredByCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionPoweredByEdge = {
  __typename?: 'SectionPoweredByEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionPoweredBy
}

/** Identifies documents */
export type SectionPoweredByManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SectionPoweredByOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionPoweredByParent = EventPage

export type SectionPoweredByParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionPoweredByParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionPoweredByParentCreateManyInlineInput = {
  /** Connect multiple existing SectionPoweredByParent documents */
  connect?: InputMaybe<Array<SectionPoweredByParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionPoweredByParent documents */
  create?: InputMaybe<Array<SectionPoweredByParentCreateInput>>
}

export type SectionPoweredByParentCreateOneInlineInput = {
  /** Connect one existing SectionPoweredByParent document */
  connect?: InputMaybe<SectionPoweredByParentWhereUniqueInput>
  /** Create and connect one SectionPoweredByParent document */
  create?: InputMaybe<SectionPoweredByParentCreateInput>
}

export type SectionPoweredByParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionPoweredByParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionPoweredByParent documents */
  connect?: InputMaybe<Array<SectionPoweredByParentConnectInput>>
  /** Create and connect multiple SectionPoweredByParent documents */
  create?: InputMaybe<Array<SectionPoweredByParentCreateInput>>
  /** Delete multiple SectionPoweredByParent documents */
  delete?: InputMaybe<Array<SectionPoweredByParentWhereUniqueInput>>
  /** Disconnect multiple SectionPoweredByParent documents */
  disconnect?: InputMaybe<Array<SectionPoweredByParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionPoweredByParent documents */
  set?: InputMaybe<Array<SectionPoweredByParentWhereUniqueInput>>
  /** Update multiple SectionPoweredByParent documents */
  update?: InputMaybe<Array<SectionPoweredByParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionPoweredByParent documents */
  upsert?: InputMaybe<Array<SectionPoweredByParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionPoweredByParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionPoweredByParentUpdateOneInlineInput = {
  /** Connect existing SectionPoweredByParent document */
  connect?: InputMaybe<SectionPoweredByParentWhereUniqueInput>
  /** Create and connect one SectionPoweredByParent document */
  create?: InputMaybe<SectionPoweredByParentCreateInput>
  /** Delete currently connected SectionPoweredByParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionPoweredByParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionPoweredByParent document */
  update?: InputMaybe<SectionPoweredByParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionPoweredByParent document */
  upsert?: InputMaybe<SectionPoweredByParentUpsertWithNestedWhereUniqueInput>
}

export type SectionPoweredByParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionPoweredByParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionPoweredByParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionPoweredByParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionPoweredByUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<SectionPoweredByUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByUpdateLocalizationInput = {
  data: SectionPoweredByUpdateLocalizationDataInput
  locale: Locale
}

export type SectionPoweredByUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionPoweredByCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionPoweredByUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionPoweredByUpsertLocalizationInput>>
}

export type SectionPoweredByUpdateManyInlineInput = {
  /** Create and connect multiple SectionPoweredBy component instances */
  create?: InputMaybe<Array<SectionPoweredByCreateWithPositionInput>>
  /** Delete multiple SectionPoweredBy documents */
  delete?: InputMaybe<Array<SectionPoweredByWhereUniqueInput>>
  /** Update multiple SectionPoweredBy component instances */
  update?: InputMaybe<Array<SectionPoweredByUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionPoweredBy component instances */
  upsert?: InputMaybe<Array<SectionPoweredByUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionPoweredByUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionPoweredByUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionPoweredByUpdateManyLocalizationInput = {
  data: SectionPoweredByUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionPoweredByUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionPoweredByUpdateManyLocalizationInput>>
}

export type SectionPoweredByUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionPoweredByUpdateManyInput
  /** Document search */
  where: SectionPoweredByWhereInput
}

export type SectionPoweredByUpdateOneInlineInput = {
  /** Create and connect one SectionPoweredBy document */
  create?: InputMaybe<SectionPoweredByCreateInput>
  /** Delete currently connected SectionPoweredBy document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionPoweredBy document */
  update?: InputMaybe<SectionPoweredByUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionPoweredBy document */
  upsert?: InputMaybe<SectionPoweredByUpsertWithNestedWhereUniqueInput>
}

export type SectionPoweredByUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionPoweredByUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionPoweredByWhereUniqueInput
}

export type SectionPoweredByUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionPoweredByUpdateInput
  /** Unique document search */
  where: SectionPoweredByWhereUniqueInput
}

export type SectionPoweredByUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionPoweredByCreateInput
  /** Update document if it exists */
  update: SectionPoweredByUpdateInput
}

export type SectionPoweredByUpsertLocalizationInput = {
  create: SectionPoweredByCreateLocalizationDataInput
  locale: Locale
  update: SectionPoweredByUpdateLocalizationDataInput
}

export type SectionPoweredByUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionPoweredByUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionPoweredByWhereUniqueInput
}

export type SectionPoweredByUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionPoweredByUpsertInput
  /** Unique document search */
  where: SectionPoweredByWhereUniqueInput
}

/** Identifies documents */
export type SectionPoweredByWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionPoweredByWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionPoweredBy record uniquely */
export type SectionPoweredByWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section representing quote */
export type SectionQuote = Entity & {
  __typename?: 'SectionQuote'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionQuote>
  /** Author name */
  name: Scalars['String']['output']
  /** Authors position in the company */
  position: Scalars['String']['output']
  /** Quote text content */
  quote: Scalars['String']['output']
  /** Image shown left to signature. Can be a company logo */
  signatureImage: Asset
  /** System stage field */
  stage: Stage
}

/** Section representing quote */
export type SectionQuoteLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section representing quote */
export type SectionQuoteSignatureImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionQuoteConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionQuoteWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionQuoteConnection = {
  __typename?: 'SectionQuoteConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionQuoteEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionQuoteCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionQuoteCreateLocalizationsInput>
  name: Scalars['String']['input']
  /** position input for default locale (de) */
  position: Scalars['String']['input']
  /** quote input for default locale (de) */
  quote: Scalars['String']['input']
  signatureImage: AssetCreateOneInlineInput
}

export type SectionQuoteCreateLocalizationDataInput = {
  position: Scalars['String']['input']
  quote: Scalars['String']['input']
}

export type SectionQuoteCreateLocalizationInput = {
  /** Localization input */
  data: SectionQuoteCreateLocalizationDataInput
  locale: Locale
}

export type SectionQuoteCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionQuoteCreateLocalizationInput>>
}

export type SectionQuoteCreateManyInlineInput = {
  /** Create and connect multiple existing SectionQuote documents */
  create?: InputMaybe<Array<SectionQuoteCreateInput>>
}

export type SectionQuoteCreateOneInlineInput = {
  /** Create and connect one SectionQuote document */
  create?: InputMaybe<SectionQuoteCreateInput>
}

export type SectionQuoteCreateWithPositionInput = {
  /** Document to create */
  data: SectionQuoteCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionQuoteEdge = {
  __typename?: 'SectionQuoteEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionQuote
}

/** Identifies documents */
export type SectionQuoteManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetWhereInput>
}

export enum SectionQuoteOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  QuoteAsc = 'quote_ASC',
  QuoteDesc = 'quote_DESC',
}

export type SectionQuoteParent = TopicPageSection

export type SectionQuoteParentConnectInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionConnectInput>
}

export type SectionQuoteParentCreateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateInput>
}

export type SectionQuoteParentCreateManyInlineInput = {
  /** Create and connect multiple existing SectionQuoteParent documents */
  create?: InputMaybe<Array<SectionQuoteParentCreateInput>>
}

export type SectionQuoteParentCreateOneInlineInput = {
  /** Create and connect one SectionQuoteParent document */
  create?: InputMaybe<SectionQuoteParentCreateInput>
}

export type SectionQuoteParentCreateWithPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionCreateWithPositionInput>
}

export type SectionQuoteParentUpdateInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateInput>
}

export type SectionQuoteParentUpdateManyInlineInput = {
  /** Create and connect multiple SectionQuoteParent component instances */
  create?: InputMaybe<Array<SectionQuoteParentCreateWithPositionInput>>
  /** Delete multiple SectionQuoteParent documents */
  delete?: InputMaybe<Array<SectionQuoteParentWhereUniqueInput>>
  /** Update multiple SectionQuoteParent component instances */
  update?: InputMaybe<Array<SectionQuoteParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionQuoteParent component instances */
  upsert?: InputMaybe<Array<SectionQuoteParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionQuoteParentUpdateManyWithNestedWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateManyWithNestedWhereInput>
}

export type SectionQuoteParentUpdateOneInlineInput = {
  /** Create and connect one SectionQuoteParent document */
  create?: InputMaybe<SectionQuoteParentCreateInput>
  /** Delete currently connected SectionQuoteParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionQuoteParent document */
  update?: InputMaybe<SectionQuoteParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionQuoteParent document */
  upsert?: InputMaybe<SectionQuoteParentUpsertWithNestedWhereUniqueInput>
}

export type SectionQuoteParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SectionQuoteParentUpdateWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
}

export type SectionQuoteParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SectionQuoteParentUpsertWithNestedWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type SectionQuoteParentWhereInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereInput>
}

export type SectionQuoteParentWhereUniqueInput = {
  TopicPageSection?: InputMaybe<TopicPageSectionWhereUniqueInput>
}

export type SectionQuoteUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<SectionQuoteUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** position input for default locale (de) */
  position?: InputMaybe<Scalars['String']['input']>
  /** quote input for default locale (de) */
  quote?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetUpdateOneInlineInput>
}

export type SectionQuoteUpdateLocalizationDataInput = {
  position?: InputMaybe<Scalars['String']['input']>
  quote?: InputMaybe<Scalars['String']['input']>
}

export type SectionQuoteUpdateLocalizationInput = {
  data: SectionQuoteUpdateLocalizationDataInput
  locale: Locale
}

export type SectionQuoteUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionQuoteCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionQuoteUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionQuoteUpsertLocalizationInput>>
}

export type SectionQuoteUpdateManyInlineInput = {
  /** Create and connect multiple SectionQuote component instances */
  create?: InputMaybe<Array<SectionQuoteCreateWithPositionInput>>
  /** Delete multiple SectionQuote documents */
  delete?: InputMaybe<Array<SectionQuoteWhereUniqueInput>>
  /** Update multiple SectionQuote component instances */
  update?: InputMaybe<Array<SectionQuoteUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionQuote component instances */
  upsert?: InputMaybe<Array<SectionQuoteUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionQuoteUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionQuoteUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** position input for default locale (de) */
  position?: InputMaybe<Scalars['String']['input']>
  /** quote input for default locale (de) */
  quote?: InputMaybe<Scalars['String']['input']>
}

export type SectionQuoteUpdateManyLocalizationDataInput = {
  position?: InputMaybe<Scalars['String']['input']>
  quote?: InputMaybe<Scalars['String']['input']>
}

export type SectionQuoteUpdateManyLocalizationInput = {
  data: SectionQuoteUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionQuoteUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionQuoteUpdateManyLocalizationInput>>
}

export type SectionQuoteUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionQuoteUpdateManyInput
  /** Document search */
  where: SectionQuoteWhereInput
}

export type SectionQuoteUpdateOneInlineInput = {
  /** Create and connect one SectionQuote document */
  create?: InputMaybe<SectionQuoteCreateInput>
  /** Delete currently connected SectionQuote document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionQuote document */
  update?: InputMaybe<SectionQuoteUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionQuote document */
  upsert?: InputMaybe<SectionQuoteUpsertWithNestedWhereUniqueInput>
}

export type SectionQuoteUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionQuoteUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionQuoteWhereUniqueInput
}

export type SectionQuoteUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionQuoteUpdateInput
  /** Unique document search */
  where: SectionQuoteWhereUniqueInput
}

export type SectionQuoteUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionQuoteCreateInput
  /** Update document if it exists */
  update: SectionQuoteUpdateInput
}

export type SectionQuoteUpsertLocalizationInput = {
  create: SectionQuoteCreateLocalizationDataInput
  locale: Locale
  update: SectionQuoteUpdateLocalizationDataInput
}

export type SectionQuoteUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionQuoteUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionQuoteWhereUniqueInput
}

export type SectionQuoteUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionQuoteUpsertInput
  /** Unique document search */
  where: SectionQuoteWhereUniqueInput
}

/** Identifies documents */
export type SectionQuoteWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionQuoteWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  position_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  position_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  position_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  position_starts_with?: InputMaybe<Scalars['String']['input']>
  quote?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  quote_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  quote_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  quote_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  quote_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  quote_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  quote_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  quote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  quote_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  quote_starts_with?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetWhereInput>
}

/** References SectionQuote record uniquely */
export type SectionQuoteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** show a grid of places to eat or go out at night during the festival */
export type SectionRecommendations = Entity & {
  __typename?: 'SectionRecommendations'
  /** The unique identifier */
  id: Scalars['ID']['output']
  recommendations: Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpy>
  /** System stage field */
  stage: Stage
}

/** show a grid of places to eat or go out at night during the festival */
export type SectionRecommendationsRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

export type SectionRecommendationsConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionRecommendationsWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionRecommendationsConnection = {
  __typename?: 'SectionRecommendationsConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionRecommendationsEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionRecommendationsCreateInput = {
  recommendations?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateManyInlineInput>
}

export type SectionRecommendationsCreateManyInlineInput = {
  /** Create and connect multiple existing SectionRecommendations documents */
  create?: InputMaybe<Array<SectionRecommendationsCreateInput>>
}

export type SectionRecommendationsCreateOneInlineInput = {
  /** Create and connect one SectionRecommendations document */
  create?: InputMaybe<SectionRecommendationsCreateInput>
}

export type SectionRecommendationsCreateWithPositionInput = {
  /** Document to create */
  data: SectionRecommendationsCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionRecommendationsEdge = {
  __typename?: 'SectionRecommendationsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionRecommendations
}

/** Identifies documents */
export type SectionRecommendationsManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty */
  recommendations_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  recommendations_some?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereInput>
}

export enum SectionRecommendationsOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionRecommendationsParent = EventPage

export type SectionRecommendationsParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionRecommendationsParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionRecommendationsParentCreateManyInlineInput = {
  /** Connect multiple existing SectionRecommendationsParent documents */
  connect?: InputMaybe<Array<SectionRecommendationsParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionRecommendationsParent documents */
  create?: InputMaybe<Array<SectionRecommendationsParentCreateInput>>
}

export type SectionRecommendationsParentCreateOneInlineInput = {
  /** Connect one existing SectionRecommendationsParent document */
  connect?: InputMaybe<SectionRecommendationsParentWhereUniqueInput>
  /** Create and connect one SectionRecommendationsParent document */
  create?: InputMaybe<SectionRecommendationsParentCreateInput>
}

export type SectionRecommendationsParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionRecommendationsParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionRecommendationsParent documents */
  connect?: InputMaybe<Array<SectionRecommendationsParentConnectInput>>
  /** Create and connect multiple SectionRecommendationsParent documents */
  create?: InputMaybe<Array<SectionRecommendationsParentCreateInput>>
  /** Delete multiple SectionRecommendationsParent documents */
  delete?: InputMaybe<Array<SectionRecommendationsParentWhereUniqueInput>>
  /** Disconnect multiple SectionRecommendationsParent documents */
  disconnect?: InputMaybe<Array<SectionRecommendationsParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionRecommendationsParent documents */
  set?: InputMaybe<Array<SectionRecommendationsParentWhereUniqueInput>>
  /** Update multiple SectionRecommendationsParent documents */
  update?: InputMaybe<Array<SectionRecommendationsParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionRecommendationsParent documents */
  upsert?: InputMaybe<Array<SectionRecommendationsParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionRecommendationsParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionRecommendationsParentUpdateOneInlineInput = {
  /** Connect existing SectionRecommendationsParent document */
  connect?: InputMaybe<SectionRecommendationsParentWhereUniqueInput>
  /** Create and connect one SectionRecommendationsParent document */
  create?: InputMaybe<SectionRecommendationsParentCreateInput>
  /** Delete currently connected SectionRecommendationsParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionRecommendationsParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionRecommendationsParent document */
  update?: InputMaybe<SectionRecommendationsParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionRecommendationsParent document */
  upsert?: InputMaybe<SectionRecommendationsParentUpsertWithNestedWhereUniqueInput>
}

export type SectionRecommendationsParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionRecommendationsParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionRecommendationsParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionRecommendationsParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionRecommendationsUpdateInput = {
  recommendations?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateManyInlineInput>
}

export type SectionRecommendationsUpdateManyInlineInput = {
  /** Create and connect multiple SectionRecommendations component instances */
  create?: InputMaybe<Array<SectionRecommendationsCreateWithPositionInput>>
  /** Delete multiple SectionRecommendations documents */
  delete?: InputMaybe<Array<SectionRecommendationsWhereUniqueInput>>
  /** Update multiple SectionRecommendations component instances */
  update?: InputMaybe<Array<SectionRecommendationsUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionRecommendations component instances */
  upsert?: InputMaybe<Array<SectionRecommendationsUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionRecommendationsUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type SectionRecommendationsUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionRecommendationsUpdateManyInput
  /** Document search */
  where: SectionRecommendationsWhereInput
}

export type SectionRecommendationsUpdateOneInlineInput = {
  /** Create and connect one SectionRecommendations document */
  create?: InputMaybe<SectionRecommendationsCreateInput>
  /** Delete currently connected SectionRecommendations document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionRecommendations document */
  update?: InputMaybe<SectionRecommendationsUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionRecommendations document */
  upsert?: InputMaybe<SectionRecommendationsUpsertWithNestedWhereUniqueInput>
}

export type SectionRecommendationsUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionRecommendationsUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionRecommendationsWhereUniqueInput
}

export type SectionRecommendationsUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionRecommendationsUpdateInput
  /** Unique document search */
  where: SectionRecommendationsWhereUniqueInput
}

export type SectionRecommendationsUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionRecommendationsCreateInput
  /** Update document if it exists */
  update: SectionRecommendationsUpdateInput
}

export type SectionRecommendationsUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionRecommendationsUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionRecommendationsWhereUniqueInput
}

export type SectionRecommendationsUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionRecommendationsUpsertInput
  /** Unique document search */
  where: SectionRecommendationsWhereUniqueInput
}

/** Identifies documents */
export type SectionRecommendationsWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionRecommendationsWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty */
  recommendations_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the union contains at least one connection to the provided item to the filter */
  recommendations_some?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereInput>
}

/** References SectionRecommendations record uniquely */
export type SectionRecommendationsWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
export type SectionSpeakers = Entity & {
  __typename?: 'SectionSpeakers'
  /** Call to action element */
  cta?: Maybe<CallToAction>
  description: Scalars['String']['output']
  eventStage?: Maybe<EventStage>
  /** If value set, only those speakers will be shown that has corresponding event type associated to them. */
  eventType?: Maybe<SpeakerEventTypes>
  hideDayFilter?: Maybe<Scalars['Boolean']['output']>
  hideDetailPages?: Maybe<Scalars['Boolean']['output']>
  hideFilters?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** This option will set if provided ordered list will be used to filter or to sort the result set. */
  listOption: ResultCreationOptions
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionSpeakers>
  /** Selected custom sorting model for the speakers. */
  order?: Maybe<SpeakerListOrder>
  showFfwd?: Maybe<Scalars['Boolean']['output']>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

/** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
export type SectionSpeakersCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
export type SectionSpeakersEventStageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
export type SectionSpeakersLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing a filterable grid of Speakers from MyOMR datasource. Old SpeakerSection component is depracated and should be used only for static list generation. */
export type SectionSpeakersOrderArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionSpeakersConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionSpeakersWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionSpeakersConnection = {
  __typename?: 'SectionSpeakersConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionSpeakersEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionSpeakersCreateInput = {
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  eventStage?: InputMaybe<EventStageCreateOneInlineInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideDayFilter?: InputMaybe<Scalars['Boolean']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption: ResultCreationOptions
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionSpeakersCreateLocalizationsInput>
  order?: InputMaybe<SpeakerListOrderCreateOneInlineInput>
  showFfwd?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionSpeakersCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionSpeakersCreateLocalizationInput = {
  /** Localization input */
  data: SectionSpeakersCreateLocalizationDataInput
  locale: Locale
}

export type SectionSpeakersCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionSpeakersCreateLocalizationInput>>
}

export type SectionSpeakersCreateManyInlineInput = {
  /** Create and connect multiple existing SectionSpeakers documents */
  create?: InputMaybe<Array<SectionSpeakersCreateInput>>
}

export type SectionSpeakersCreateOneInlineInput = {
  /** Create and connect one SectionSpeakers document */
  create?: InputMaybe<SectionSpeakersCreateInput>
}

export type SectionSpeakersCreateWithPositionInput = {
  /** Document to create */
  data: SectionSpeakersCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionSpeakersEdge = {
  __typename?: 'SectionSpeakersEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionSpeakers
}

/** Identifies documents */
export type SectionSpeakersManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  eventStage?: InputMaybe<EventStageWhereInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  hideDayFilter?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDayFilter_not?: InputMaybe<Scalars['Boolean']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideFilters_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** All values that are contained in given list. */
  listOption_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  /** Any other value that exists and is not equal to the given value. */
  listOption_not?: InputMaybe<ResultCreationOptions>
  /** All values that are not contained in given list. */
  listOption_not_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  order?: InputMaybe<SpeakerListOrderWhereInput>
  showFfwd?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showFfwd_not?: InputMaybe<Scalars['Boolean']['input']>
}

export enum SectionSpeakersOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  HideDayFilterAsc = 'hideDayFilter_ASC',
  HideDayFilterDesc = 'hideDayFilter_DESC',
  HideDetailPagesAsc = 'hideDetailPages_ASC',
  HideDetailPagesDesc = 'hideDetailPages_DESC',
  HideFiltersAsc = 'hideFilters_ASC',
  HideFiltersDesc = 'hideFilters_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ListOptionAsc = 'listOption_ASC',
  ListOptionDesc = 'listOption_DESC',
  ShowFfwdAsc = 'showFfwd_ASC',
  ShowFfwdDesc = 'showFfwd_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionSpeakersParent = EventPage

export type SectionSpeakersParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionSpeakersParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionSpeakersParentCreateManyInlineInput = {
  /** Connect multiple existing SectionSpeakersParent documents */
  connect?: InputMaybe<Array<SectionSpeakersParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionSpeakersParent documents */
  create?: InputMaybe<Array<SectionSpeakersParentCreateInput>>
}

export type SectionSpeakersParentCreateOneInlineInput = {
  /** Connect one existing SectionSpeakersParent document */
  connect?: InputMaybe<SectionSpeakersParentWhereUniqueInput>
  /** Create and connect one SectionSpeakersParent document */
  create?: InputMaybe<SectionSpeakersParentCreateInput>
}

export type SectionSpeakersParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionSpeakersParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionSpeakersParent documents */
  connect?: InputMaybe<Array<SectionSpeakersParentConnectInput>>
  /** Create and connect multiple SectionSpeakersParent documents */
  create?: InputMaybe<Array<SectionSpeakersParentCreateInput>>
  /** Delete multiple SectionSpeakersParent documents */
  delete?: InputMaybe<Array<SectionSpeakersParentWhereUniqueInput>>
  /** Disconnect multiple SectionSpeakersParent documents */
  disconnect?: InputMaybe<Array<SectionSpeakersParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionSpeakersParent documents */
  set?: InputMaybe<Array<SectionSpeakersParentWhereUniqueInput>>
  /** Update multiple SectionSpeakersParent documents */
  update?: InputMaybe<Array<SectionSpeakersParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionSpeakersParent documents */
  upsert?: InputMaybe<Array<SectionSpeakersParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionSpeakersParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionSpeakersParentUpdateOneInlineInput = {
  /** Connect existing SectionSpeakersParent document */
  connect?: InputMaybe<SectionSpeakersParentWhereUniqueInput>
  /** Create and connect one SectionSpeakersParent document */
  create?: InputMaybe<SectionSpeakersParentCreateInput>
  /** Delete currently connected SectionSpeakersParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionSpeakersParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionSpeakersParent document */
  update?: InputMaybe<SectionSpeakersParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionSpeakersParent document */
  upsert?: InputMaybe<SectionSpeakersParentUpsertWithNestedWhereUniqueInput>
}

export type SectionSpeakersParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionSpeakersParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionSpeakersParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionSpeakersParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionSpeakersUpdateInput = {
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageUpdateOneInlineInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideDayFilter?: InputMaybe<Scalars['Boolean']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionSpeakersUpdateLocalizationsInput>
  order?: InputMaybe<SpeakerListOrderUpdateOneInlineInput>
  showFfwd?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionSpeakersUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionSpeakersUpdateLocalizationInput = {
  data: SectionSpeakersUpdateLocalizationDataInput
  locale: Locale
}

export type SectionSpeakersUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionSpeakersCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionSpeakersUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionSpeakersUpsertLocalizationInput>>
}

export type SectionSpeakersUpdateManyInlineInput = {
  /** Create and connect multiple SectionSpeakers component instances */
  create?: InputMaybe<Array<SectionSpeakersCreateWithPositionInput>>
  /** Delete multiple SectionSpeakers documents */
  delete?: InputMaybe<Array<SectionSpeakersWhereUniqueInput>>
  /** Update multiple SectionSpeakers component instances */
  update?: InputMaybe<Array<SectionSpeakersUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionSpeakers component instances */
  upsert?: InputMaybe<Array<SectionSpeakersUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionSpeakersUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  eventType?: InputMaybe<SpeakerEventTypes>
  hideDayFilter?: InputMaybe<Scalars['Boolean']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionSpeakersUpdateManyLocalizationsInput>
  showFfwd?: InputMaybe<Scalars['Boolean']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionSpeakersUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionSpeakersUpdateManyLocalizationInput = {
  data: SectionSpeakersUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionSpeakersUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionSpeakersUpdateManyLocalizationInput>>
}

export type SectionSpeakersUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionSpeakersUpdateManyInput
  /** Document search */
  where: SectionSpeakersWhereInput
}

export type SectionSpeakersUpdateOneInlineInput = {
  /** Create and connect one SectionSpeakers document */
  create?: InputMaybe<SectionSpeakersCreateInput>
  /** Delete currently connected SectionSpeakers document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionSpeakers document */
  update?: InputMaybe<SectionSpeakersUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionSpeakers document */
  upsert?: InputMaybe<SectionSpeakersUpsertWithNestedWhereUniqueInput>
}

export type SectionSpeakersUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionSpeakersUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionSpeakersWhereUniqueInput
}

export type SectionSpeakersUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionSpeakersUpdateInput
  /** Unique document search */
  where: SectionSpeakersWhereUniqueInput
}

export type SectionSpeakersUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionSpeakersCreateInput
  /** Update document if it exists */
  update: SectionSpeakersUpdateInput
}

export type SectionSpeakersUpsertLocalizationInput = {
  create: SectionSpeakersCreateLocalizationDataInput
  locale: Locale
  update: SectionSpeakersUpdateLocalizationDataInput
}

export type SectionSpeakersUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionSpeakersUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionSpeakersWhereUniqueInput
}

export type SectionSpeakersUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionSpeakersUpsertInput
  /** Unique document search */
  where: SectionSpeakersWhereUniqueInput
}

/** Identifies documents */
export type SectionSpeakersWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionSpeakersWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  eventStage?: InputMaybe<EventStageWhereInput>
  eventType?: InputMaybe<SpeakerEventTypes>
  /** All values that are contained in given list. */
  eventType_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  /** Any other value that exists and is not equal to the given value. */
  eventType_not?: InputMaybe<SpeakerEventTypes>
  /** All values that are not contained in given list. */
  eventType_not_in?: InputMaybe<Array<InputMaybe<SpeakerEventTypes>>>
  hideDayFilter?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDayFilter_not?: InputMaybe<Scalars['Boolean']['input']>
  hideDetailPages?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideDetailPages_not?: InputMaybe<Scalars['Boolean']['input']>
  hideFilters?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideFilters_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  listOption?: InputMaybe<ResultCreationOptions>
  /** All values that are contained in given list. */
  listOption_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  /** Any other value that exists and is not equal to the given value. */
  listOption_not?: InputMaybe<ResultCreationOptions>
  /** All values that are not contained in given list. */
  listOption_not_in?: InputMaybe<Array<InputMaybe<ResultCreationOptions>>>
  order?: InputMaybe<SpeakerListOrderWhereInput>
  showFfwd?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  showFfwd_not?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionSpeakers record uniquely */
export type SectionSpeakersWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreview = Entity & {
  __typename?: 'SectionStagePreview'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  introTitle?: Maybe<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionStagePreview>
  /** Logo for the Stage */
  logo?: Maybe<Asset>
  /** The top speakers or artists for this stage. */
  speakers: Array<Speaker>
  speakersCtaText?: Maybe<Scalars['String']['output']>
  speakersCtaUrl?: Maybe<Scalars['String']['output']>
  speakersTitle?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  stageBG: Asset
  stageName?: Maybe<Scalars['String']['output']>
  tags: Array<Tag>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreviewLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreviewLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreviewSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreviewStageBgArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Shows a preview for a stage with Speakers */
export type SectionStagePreviewTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TagOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TagWhereInput>
}

export type SectionStagePreviewConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionStagePreviewWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionStagePreviewConnection = {
  __typename?: 'SectionStagePreviewConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionStagePreviewEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionStagePreviewCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionStagePreviewCreateLocalizationsInput>
  logo?: InputMaybe<AssetCreateOneInlineInput>
  speakers?: InputMaybe<SpeakerCreateManyInlineInput>
  /** speakersCtaText input for default locale (de) */
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  /** speakersCtaUrl input for default locale (de) */
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  /** speakersTitle input for default locale (de) */
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  stageBG: AssetCreateOneInlineInput
  /** stageName input for default locale (de) */
  stageName?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagCreateManyInlineInput>
}

export type SectionStagePreviewCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  introTitle?: InputMaybe<Scalars['String']['input']>
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  stageName?: InputMaybe<Scalars['String']['input']>
}

export type SectionStagePreviewCreateLocalizationInput = {
  /** Localization input */
  data: SectionStagePreviewCreateLocalizationDataInput
  locale: Locale
}

export type SectionStagePreviewCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionStagePreviewCreateLocalizationInput>>
}

export type SectionStagePreviewCreateManyInlineInput = {
  /** Create and connect multiple existing SectionStagePreview documents */
  create?: InputMaybe<Array<SectionStagePreviewCreateInput>>
}

export type SectionStagePreviewCreateOneInlineInput = {
  /** Create and connect one SectionStagePreview document */
  create?: InputMaybe<SectionStagePreviewCreateInput>
}

export type SectionStagePreviewCreateWithPositionInput = {
  /** Document to create */
  data: SectionStagePreviewCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionStagePreviewEdge = {
  __typename?: 'SectionStagePreviewEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionStagePreview
}

/** Identifies documents */
export type SectionStagePreviewManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  logo?: InputMaybe<AssetWhereInput>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  stageBG?: InputMaybe<AssetWhereInput>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
}

export enum SectionStagePreviewOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IntroTitleAsc = 'introTitle_ASC',
  IntroTitleDesc = 'introTitle_DESC',
  SpeakersCtaTextAsc = 'speakersCtaText_ASC',
  SpeakersCtaTextDesc = 'speakersCtaText_DESC',
  SpeakersCtaUrlAsc = 'speakersCtaUrl_ASC',
  SpeakersCtaUrlDesc = 'speakersCtaUrl_DESC',
  SpeakersTitleAsc = 'speakersTitle_ASC',
  SpeakersTitleDesc = 'speakersTitle_DESC',
  StageNameAsc = 'stageName_ASC',
  StageNameDesc = 'stageName_DESC',
}

export type SectionStagePreviewParent = EventPage

export type SectionStagePreviewParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionStagePreviewParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionStagePreviewParentCreateManyInlineInput = {
  /** Connect multiple existing SectionStagePreviewParent documents */
  connect?: InputMaybe<Array<SectionStagePreviewParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionStagePreviewParent documents */
  create?: InputMaybe<Array<SectionStagePreviewParentCreateInput>>
}

export type SectionStagePreviewParentCreateOneInlineInput = {
  /** Connect one existing SectionStagePreviewParent document */
  connect?: InputMaybe<SectionStagePreviewParentWhereUniqueInput>
  /** Create and connect one SectionStagePreviewParent document */
  create?: InputMaybe<SectionStagePreviewParentCreateInput>
}

export type SectionStagePreviewParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionStagePreviewParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionStagePreviewParent documents */
  connect?: InputMaybe<Array<SectionStagePreviewParentConnectInput>>
  /** Create and connect multiple SectionStagePreviewParent documents */
  create?: InputMaybe<Array<SectionStagePreviewParentCreateInput>>
  /** Delete multiple SectionStagePreviewParent documents */
  delete?: InputMaybe<Array<SectionStagePreviewParentWhereUniqueInput>>
  /** Disconnect multiple SectionStagePreviewParent documents */
  disconnect?: InputMaybe<Array<SectionStagePreviewParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionStagePreviewParent documents */
  set?: InputMaybe<Array<SectionStagePreviewParentWhereUniqueInput>>
  /** Update multiple SectionStagePreviewParent documents */
  update?: InputMaybe<Array<SectionStagePreviewParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionStagePreviewParent documents */
  upsert?: InputMaybe<Array<SectionStagePreviewParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionStagePreviewParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionStagePreviewParentUpdateOneInlineInput = {
  /** Connect existing SectionStagePreviewParent document */
  connect?: InputMaybe<SectionStagePreviewParentWhereUniqueInput>
  /** Create and connect one SectionStagePreviewParent document */
  create?: InputMaybe<SectionStagePreviewParentCreateInput>
  /** Delete currently connected SectionStagePreviewParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionStagePreviewParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStagePreviewParent document */
  update?: InputMaybe<SectionStagePreviewParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStagePreviewParent document */
  upsert?: InputMaybe<SectionStagePreviewParentUpsertWithNestedWhereUniqueInput>
}

export type SectionStagePreviewParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionStagePreviewParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionStagePreviewParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionStagePreviewParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionStagePreviewUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionStagePreviewUpdateLocalizationsInput>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  speakers?: InputMaybe<SpeakerUpdateManyInlineInput>
  /** speakersCtaText input for default locale (de) */
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  /** speakersCtaUrl input for default locale (de) */
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  /** speakersTitle input for default locale (de) */
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  stageBG?: InputMaybe<AssetUpdateOneInlineInput>
  /** stageName input for default locale (de) */
  stageName?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagUpdateManyInlineInput>
}

export type SectionStagePreviewUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  introTitle?: InputMaybe<Scalars['String']['input']>
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  stageName?: InputMaybe<Scalars['String']['input']>
}

export type SectionStagePreviewUpdateLocalizationInput = {
  data: SectionStagePreviewUpdateLocalizationDataInput
  locale: Locale
}

export type SectionStagePreviewUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionStagePreviewCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStagePreviewUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionStagePreviewUpsertLocalizationInput>>
}

export type SectionStagePreviewUpdateManyInlineInput = {
  /** Create and connect multiple SectionStagePreview component instances */
  create?: InputMaybe<Array<SectionStagePreviewCreateWithPositionInput>>
  /** Delete multiple SectionStagePreview documents */
  delete?: InputMaybe<Array<SectionStagePreviewWhereUniqueInput>>
  /** Update multiple SectionStagePreview component instances */
  update?: InputMaybe<Array<SectionStagePreviewUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionStagePreview component instances */
  upsert?: InputMaybe<Array<SectionStagePreviewUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionStagePreviewUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** introTitle input for default locale (de) */
  introTitle?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionStagePreviewUpdateManyLocalizationsInput>
  /** speakersCtaText input for default locale (de) */
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  /** speakersCtaUrl input for default locale (de) */
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  /** speakersTitle input for default locale (de) */
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  /** stageName input for default locale (de) */
  stageName?: InputMaybe<Scalars['String']['input']>
}

export type SectionStagePreviewUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  introTitle?: InputMaybe<Scalars['String']['input']>
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  stageName?: InputMaybe<Scalars['String']['input']>
}

export type SectionStagePreviewUpdateManyLocalizationInput = {
  data: SectionStagePreviewUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionStagePreviewUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStagePreviewUpdateManyLocalizationInput>>
}

export type SectionStagePreviewUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionStagePreviewUpdateManyInput
  /** Document search */
  where: SectionStagePreviewWhereInput
}

export type SectionStagePreviewUpdateOneInlineInput = {
  /** Create and connect one SectionStagePreview document */
  create?: InputMaybe<SectionStagePreviewCreateInput>
  /** Delete currently connected SectionStagePreview document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStagePreview document */
  update?: InputMaybe<SectionStagePreviewUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStagePreview document */
  upsert?: InputMaybe<SectionStagePreviewUpsertWithNestedWhereUniqueInput>
}

export type SectionStagePreviewUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionStagePreviewUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStagePreviewWhereUniqueInput
}

export type SectionStagePreviewUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionStagePreviewUpdateInput
  /** Unique document search */
  where: SectionStagePreviewWhereUniqueInput
}

export type SectionStagePreviewUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionStagePreviewCreateInput
  /** Update document if it exists */
  update: SectionStagePreviewUpdateInput
}

export type SectionStagePreviewUpsertLocalizationInput = {
  create: SectionStagePreviewCreateLocalizationDataInput
  locale: Locale
  update: SectionStagePreviewUpdateLocalizationDataInput
}

export type SectionStagePreviewUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionStagePreviewUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStagePreviewWhereUniqueInput
}

export type SectionStagePreviewUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionStagePreviewUpsertInput
  /** Unique document search */
  where: SectionStagePreviewWhereUniqueInput
}

/** Identifies documents */
export type SectionStagePreviewWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStagePreviewWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  introTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  introTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  introTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  introTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  introTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  introTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  introTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  introTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  introTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  introTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  speakersCtaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  speakersCtaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  speakersCtaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  speakersCtaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  speakersCtaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  speakersCtaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  speakersCtaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  speakersCtaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  speakersCtaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  speakersCtaText_starts_with?: InputMaybe<Scalars['String']['input']>
  speakersCtaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  speakersCtaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  speakersCtaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  speakersCtaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  speakersCtaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  speakersCtaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  speakersCtaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  speakersCtaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  speakersCtaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  speakersCtaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  speakersTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  speakersTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  speakersTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  speakersTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  speakersTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  speakersTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  speakersTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  speakersTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  speakersTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  speakersTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  stageBG?: InputMaybe<AssetWhereInput>
  stageName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  stageName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  stageName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  stageName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  stageName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  stageName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  stageName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  stageName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  stageName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  stageName_starts_with?: InputMaybe<Scalars['String']['input']>
  tags_every?: InputMaybe<TagWhereInput>
  tags_none?: InputMaybe<TagWhereInput>
  tags_some?: InputMaybe<TagWhereInput>
}

/** References SectionStagePreview record uniquely */
export type SectionStagePreviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Simple component for Header / Description / CTA. */
export type SectionStickyImage = Entity & {
  __typename?: 'SectionStickyImage'
  darkTheme?: Maybe<Scalars['Boolean']['output']>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** A title / description / optional cta block */
  infoBlock: Array<BasicHeaderText>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionStickyImage>
  /** System stage field */
  stage: Stage
  /** Sticky Image on Scroll */
  stickyImage?: Maybe<Asset>
  title: Scalars['String']['output']
}

/** Simple component for Header / Description / CTA. */
export type SectionStickyImageInfoBlockArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BasicHeaderTextOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BasicHeaderTextWhereInput>
}

/** Simple component for Header / Description / CTA. */
export type SectionStickyImageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Simple component for Header / Description / CTA. */
export type SectionStickyImageStickyImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionStickyImageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionStickyImageWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionStickyImageConnection = {
  __typename?: 'SectionStickyImageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionStickyImageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionStickyImageCreateInput = {
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  infoBlock?: InputMaybe<BasicHeaderTextCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionStickyImageCreateLocalizationsInput>
  stickyImage?: InputMaybe<AssetCreateOneInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionStickyImageCreateLocalizationDataInput = {
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionStickyImageCreateLocalizationInput = {
  /** Localization input */
  data: SectionStickyImageCreateLocalizationDataInput
  locale: Locale
}

export type SectionStickyImageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionStickyImageCreateLocalizationInput>>
}

export type SectionStickyImageCreateManyInlineInput = {
  /** Create and connect multiple existing SectionStickyImage documents */
  create?: InputMaybe<Array<SectionStickyImageCreateInput>>
}

export type SectionStickyImageCreateOneInlineInput = {
  /** Create and connect one SectionStickyImage document */
  create?: InputMaybe<SectionStickyImageCreateInput>
}

export type SectionStickyImageCreateWithPositionInput = {
  /** Document to create */
  data: SectionStickyImageCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionStickyImageEdge = {
  __typename?: 'SectionStickyImageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionStickyImage
}

/** Identifies documents */
export type SectionStickyImageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  infoBlock_every?: InputMaybe<BasicHeaderTextWhereInput>
  infoBlock_none?: InputMaybe<BasicHeaderTextWhereInput>
  infoBlock_some?: InputMaybe<BasicHeaderTextWhereInput>
  stickyImage?: InputMaybe<AssetWhereInput>
}

export enum SectionStickyImageOrderByInput {
  DarkThemeAsc = 'darkTheme_ASC',
  DarkThemeDesc = 'darkTheme_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionStickyImageParent = EventPage

export type SectionStickyImageParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionStickyImageParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionStickyImageParentCreateManyInlineInput = {
  /** Connect multiple existing SectionStickyImageParent documents */
  connect?: InputMaybe<Array<SectionStickyImageParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionStickyImageParent documents */
  create?: InputMaybe<Array<SectionStickyImageParentCreateInput>>
}

export type SectionStickyImageParentCreateOneInlineInput = {
  /** Connect one existing SectionStickyImageParent document */
  connect?: InputMaybe<SectionStickyImageParentWhereUniqueInput>
  /** Create and connect one SectionStickyImageParent document */
  create?: InputMaybe<SectionStickyImageParentCreateInput>
}

export type SectionStickyImageParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionStickyImageParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionStickyImageParent documents */
  connect?: InputMaybe<Array<SectionStickyImageParentConnectInput>>
  /** Create and connect multiple SectionStickyImageParent documents */
  create?: InputMaybe<Array<SectionStickyImageParentCreateInput>>
  /** Delete multiple SectionStickyImageParent documents */
  delete?: InputMaybe<Array<SectionStickyImageParentWhereUniqueInput>>
  /** Disconnect multiple SectionStickyImageParent documents */
  disconnect?: InputMaybe<Array<SectionStickyImageParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionStickyImageParent documents */
  set?: InputMaybe<Array<SectionStickyImageParentWhereUniqueInput>>
  /** Update multiple SectionStickyImageParent documents */
  update?: InputMaybe<Array<SectionStickyImageParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionStickyImageParent documents */
  upsert?: InputMaybe<Array<SectionStickyImageParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionStickyImageParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionStickyImageParentUpdateOneInlineInput = {
  /** Connect existing SectionStickyImageParent document */
  connect?: InputMaybe<SectionStickyImageParentWhereUniqueInput>
  /** Create and connect one SectionStickyImageParent document */
  create?: InputMaybe<SectionStickyImageParentCreateInput>
  /** Delete currently connected SectionStickyImageParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionStickyImageParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStickyImageParent document */
  update?: InputMaybe<SectionStickyImageParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStickyImageParent document */
  upsert?: InputMaybe<SectionStickyImageParentUpsertWithNestedWhereUniqueInput>
}

export type SectionStickyImageParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionStickyImageParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionStickyImageParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionStickyImageParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionStickyImageUpdateInput = {
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  infoBlock?: InputMaybe<BasicHeaderTextUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionStickyImageUpdateLocalizationsInput>
  stickyImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStickyImageUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStickyImageUpdateLocalizationInput = {
  data: SectionStickyImageUpdateLocalizationDataInput
  locale: Locale
}

export type SectionStickyImageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionStickyImageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStickyImageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionStickyImageUpsertLocalizationInput>>
}

export type SectionStickyImageUpdateManyInlineInput = {
  /** Create and connect multiple SectionStickyImage component instances */
  create?: InputMaybe<Array<SectionStickyImageCreateWithPositionInput>>
  /** Delete multiple SectionStickyImage documents */
  delete?: InputMaybe<Array<SectionStickyImageWhereUniqueInput>>
  /** Update multiple SectionStickyImage component instances */
  update?: InputMaybe<Array<SectionStickyImageUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionStickyImage component instances */
  upsert?: InputMaybe<Array<SectionStickyImageUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionStickyImageUpdateManyInput = {
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionStickyImageUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStickyImageUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStickyImageUpdateManyLocalizationInput = {
  data: SectionStickyImageUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionStickyImageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStickyImageUpdateManyLocalizationInput>>
}

export type SectionStickyImageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionStickyImageUpdateManyInput
  /** Document search */
  where: SectionStickyImageWhereInput
}

export type SectionStickyImageUpdateOneInlineInput = {
  /** Create and connect one SectionStickyImage document */
  create?: InputMaybe<SectionStickyImageCreateInput>
  /** Delete currently connected SectionStickyImage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStickyImage document */
  update?: InputMaybe<SectionStickyImageUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStickyImage document */
  upsert?: InputMaybe<SectionStickyImageUpsertWithNestedWhereUniqueInput>
}

export type SectionStickyImageUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionStickyImageUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStickyImageWhereUniqueInput
}

export type SectionStickyImageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionStickyImageUpdateInput
  /** Unique document search */
  where: SectionStickyImageWhereUniqueInput
}

export type SectionStickyImageUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionStickyImageCreateInput
  /** Update document if it exists */
  update: SectionStickyImageUpdateInput
}

export type SectionStickyImageUpsertLocalizationInput = {
  create: SectionStickyImageCreateLocalizationDataInput
  locale: Locale
  update: SectionStickyImageUpdateLocalizationDataInput
}

export type SectionStickyImageUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionStickyImageUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStickyImageWhereUniqueInput
}

export type SectionStickyImageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionStickyImageUpsertInput
  /** Unique document search */
  where: SectionStickyImageWhereUniqueInput
}

/** Identifies documents */
export type SectionStickyImageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStickyImageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  infoBlock_every?: InputMaybe<BasicHeaderTextWhereInput>
  infoBlock_none?: InputMaybe<BasicHeaderTextWhereInput>
  infoBlock_some?: InputMaybe<BasicHeaderTextWhereInput>
  stickyImage?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionStickyImage record uniquely */
export type SectionStickyImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionStorytelling = Entity & {
  __typename?: 'SectionStorytelling'
  benefitsCard: Array<BenefitsCard>
  ctaText: Scalars['String']['output']
  ctaUrl?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  /** Overrides any URL - important, Hubspot must be loaded on this page through TagManager */
  hubspot?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionStorytelling>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
}

export type SectionStorytellingBenefitsCardArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<BenefitsCardOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<BenefitsCardWhereInput>
}

export type SectionStorytellingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionStorytellingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionStorytellingWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionStorytellingConnection = {
  __typename?: 'SectionStorytellingConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionStorytellingEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionStorytellingCreateInput = {
  benefitsCard?: InputMaybe<BenefitsCardCreateManyInlineInput>
  /** ctaText input for default locale (de) */
  ctaText: Scalars['String']['input']
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  hubspot?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionStorytellingCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type SectionStorytellingCreateLocalizationDataInput = {
  ctaText: Scalars['String']['input']
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  title: Scalars['String']['input']
}

export type SectionStorytellingCreateLocalizationInput = {
  /** Localization input */
  data: SectionStorytellingCreateLocalizationDataInput
  locale: Locale
}

export type SectionStorytellingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionStorytellingCreateLocalizationInput>>
}

export type SectionStorytellingCreateManyInlineInput = {
  /** Create and connect multiple existing SectionStorytelling documents */
  create?: InputMaybe<Array<SectionStorytellingCreateInput>>
}

export type SectionStorytellingCreateOneInlineInput = {
  /** Create and connect one SectionStorytelling document */
  create?: InputMaybe<SectionStorytellingCreateInput>
}

export type SectionStorytellingCreateWithPositionInput = {
  /** Document to create */
  data: SectionStorytellingCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionStorytellingEdge = {
  __typename?: 'SectionStorytellingEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionStorytelling
}

/** Identifies documents */
export type SectionStorytellingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefitsCard_every?: InputMaybe<BenefitsCardWhereInput>
  benefitsCard_none?: InputMaybe<BenefitsCardWhereInput>
  benefitsCard_some?: InputMaybe<BenefitsCardWhereInput>
  hubspot?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hubspot_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionStorytellingOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HubspotAsc = 'hubspot_ASC',
  HubspotDesc = 'hubspot_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionStorytellingParent = EventPage

export type SectionStorytellingParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionStorytellingParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionStorytellingParentCreateManyInlineInput = {
  /** Connect multiple existing SectionStorytellingParent documents */
  connect?: InputMaybe<Array<SectionStorytellingParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionStorytellingParent documents */
  create?: InputMaybe<Array<SectionStorytellingParentCreateInput>>
}

export type SectionStorytellingParentCreateOneInlineInput = {
  /** Connect one existing SectionStorytellingParent document */
  connect?: InputMaybe<SectionStorytellingParentWhereUniqueInput>
  /** Create and connect one SectionStorytellingParent document */
  create?: InputMaybe<SectionStorytellingParentCreateInput>
}

export type SectionStorytellingParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionStorytellingParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionStorytellingParent documents */
  connect?: InputMaybe<Array<SectionStorytellingParentConnectInput>>
  /** Create and connect multiple SectionStorytellingParent documents */
  create?: InputMaybe<Array<SectionStorytellingParentCreateInput>>
  /** Delete multiple SectionStorytellingParent documents */
  delete?: InputMaybe<Array<SectionStorytellingParentWhereUniqueInput>>
  /** Disconnect multiple SectionStorytellingParent documents */
  disconnect?: InputMaybe<Array<SectionStorytellingParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionStorytellingParent documents */
  set?: InputMaybe<Array<SectionStorytellingParentWhereUniqueInput>>
  /** Update multiple SectionStorytellingParent documents */
  update?: InputMaybe<Array<SectionStorytellingParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionStorytellingParent documents */
  upsert?: InputMaybe<Array<SectionStorytellingParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionStorytellingParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionStorytellingParentUpdateOneInlineInput = {
  /** Connect existing SectionStorytellingParent document */
  connect?: InputMaybe<SectionStorytellingParentWhereUniqueInput>
  /** Create and connect one SectionStorytellingParent document */
  create?: InputMaybe<SectionStorytellingParentCreateInput>
  /** Delete currently connected SectionStorytellingParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionStorytellingParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStorytellingParent document */
  update?: InputMaybe<SectionStorytellingParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStorytellingParent document */
  upsert?: InputMaybe<SectionStorytellingParentUpsertWithNestedWhereUniqueInput>
}

export type SectionStorytellingParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionStorytellingParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionStorytellingParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionStorytellingParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionStorytellingUpdateInput = {
  benefitsCard?: InputMaybe<BenefitsCardUpdateManyInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hubspot?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionStorytellingUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStorytellingUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStorytellingUpdateLocalizationInput = {
  data: SectionStorytellingUpdateLocalizationDataInput
  locale: Locale
}

export type SectionStorytellingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionStorytellingCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStorytellingUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionStorytellingUpsertLocalizationInput>>
}

export type SectionStorytellingUpdateManyInlineInput = {
  /** Create and connect multiple SectionStorytelling component instances */
  create?: InputMaybe<Array<SectionStorytellingCreateWithPositionInput>>
  /** Delete multiple SectionStorytelling documents */
  delete?: InputMaybe<Array<SectionStorytellingWhereUniqueInput>>
  /** Update multiple SectionStorytelling component instances */
  update?: InputMaybe<Array<SectionStorytellingUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionStorytelling component instances */
  upsert?: InputMaybe<Array<SectionStorytellingUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionStorytellingUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hubspot?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionStorytellingUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStorytellingUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionStorytellingUpdateManyLocalizationInput = {
  data: SectionStorytellingUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionStorytellingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionStorytellingUpdateManyLocalizationInput>>
}

export type SectionStorytellingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionStorytellingUpdateManyInput
  /** Document search */
  where: SectionStorytellingWhereInput
}

export type SectionStorytellingUpdateOneInlineInput = {
  /** Create and connect one SectionStorytelling document */
  create?: InputMaybe<SectionStorytellingCreateInput>
  /** Delete currently connected SectionStorytelling document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionStorytelling document */
  update?: InputMaybe<SectionStorytellingUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionStorytelling document */
  upsert?: InputMaybe<SectionStorytellingUpsertWithNestedWhereUniqueInput>
}

export type SectionStorytellingUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionStorytellingUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStorytellingWhereUniqueInput
}

export type SectionStorytellingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionStorytellingUpdateInput
  /** Unique document search */
  where: SectionStorytellingWhereUniqueInput
}

export type SectionStorytellingUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionStorytellingCreateInput
  /** Update document if it exists */
  update: SectionStorytellingUpdateInput
}

export type SectionStorytellingUpsertLocalizationInput = {
  create: SectionStorytellingCreateLocalizationDataInput
  locale: Locale
  update: SectionStorytellingUpdateLocalizationDataInput
}

export type SectionStorytellingUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionStorytellingUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionStorytellingWhereUniqueInput
}

export type SectionStorytellingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionStorytellingUpsertInput
  /** Unique document search */
  where: SectionStorytellingWhereUniqueInput
}

/** Identifies documents */
export type SectionStorytellingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionStorytellingWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  benefitsCard_every?: InputMaybe<BenefitsCardWhereInput>
  benefitsCard_none?: InputMaybe<BenefitsCardWhereInput>
  benefitsCard_some?: InputMaybe<BenefitsCardWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  hubspot?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hubspot_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionStorytelling record uniquely */
export type SectionStorytellingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing a tab view on desktop and accordions on mobile. */
export type SectionTab = Entity & {
  __typename?: 'SectionTab'
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionTab>
  /** System stage field */
  stage: Stage
  tabContents: Array<TabContent>
  title?: Maybe<Scalars['String']['output']>
  /** Only left and center are supported */
  titlePosition?: Maybe<ElementPosition>
}

/** Section showing a tab view on desktop and accordions on mobile. */
export type SectionTabLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section showing a tab view on desktop and accordions on mobile. */
export type SectionTabTabContentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TabContentOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TabContentWhereInput>
}

export type SectionTabConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionTabWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionTabConnection = {
  __typename?: 'SectionTabConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionTabEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionTabCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionTabCreateLocalizationsInput>
  tabContents: TabContentCreateManyInlineInput
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titlePosition?: InputMaybe<ElementPosition>
}

export type SectionTabCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionTabCreateLocalizationInput = {
  /** Localization input */
  data: SectionTabCreateLocalizationDataInput
  locale: Locale
}

export type SectionTabCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionTabCreateLocalizationInput>>
}

export type SectionTabCreateManyInlineInput = {
  /** Create and connect multiple existing SectionTab documents */
  create?: InputMaybe<Array<SectionTabCreateInput>>
}

export type SectionTabCreateOneInlineInput = {
  /** Create and connect one SectionTab document */
  create?: InputMaybe<SectionTabCreateInput>
}

export type SectionTabCreateWithPositionInput = {
  /** Document to create */
  data: SectionTabCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionTabEdge = {
  __typename?: 'SectionTabEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionTab
}

/** Identifies documents */
export type SectionTabManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTabWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTabWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTabWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  tabContents_every?: InputMaybe<TabContentWhereInput>
  tabContents_none?: InputMaybe<TabContentWhereInput>
  tabContents_some?: InputMaybe<TabContentWhereInput>
  titlePosition?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  titlePosition_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  titlePosition_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  titlePosition_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
}

export enum SectionTabOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitlePositionAsc = 'titlePosition_ASC',
  TitlePositionDesc = 'titlePosition_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SectionTabParent = EventPage | LandingPage

export type SectionTabParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionTabParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionTabParentCreateManyInlineInput = {
  /** Connect multiple existing SectionTabParent documents */
  connect?: InputMaybe<Array<SectionTabParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionTabParent documents */
  create?: InputMaybe<Array<SectionTabParentCreateInput>>
}

export type SectionTabParentCreateOneInlineInput = {
  /** Connect one existing SectionTabParent document */
  connect?: InputMaybe<SectionTabParentWhereUniqueInput>
  /** Create and connect one SectionTabParent document */
  create?: InputMaybe<SectionTabParentCreateInput>
}

export type SectionTabParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionTabParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionTabParent documents */
  connect?: InputMaybe<Array<SectionTabParentConnectInput>>
  /** Create and connect multiple SectionTabParent documents */
  create?: InputMaybe<Array<SectionTabParentCreateInput>>
  /** Delete multiple SectionTabParent documents */
  delete?: InputMaybe<Array<SectionTabParentWhereUniqueInput>>
  /** Disconnect multiple SectionTabParent documents */
  disconnect?: InputMaybe<Array<SectionTabParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionTabParent documents */
  set?: InputMaybe<Array<SectionTabParentWhereUniqueInput>>
  /** Update multiple SectionTabParent documents */
  update?: InputMaybe<Array<SectionTabParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionTabParent documents */
  upsert?: InputMaybe<Array<SectionTabParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionTabParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionTabParentUpdateOneInlineInput = {
  /** Connect existing SectionTabParent document */
  connect?: InputMaybe<SectionTabParentWhereUniqueInput>
  /** Create and connect one SectionTabParent document */
  create?: InputMaybe<SectionTabParentCreateInput>
  /** Delete currently connected SectionTabParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionTabParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTabParent document */
  update?: InputMaybe<SectionTabParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTabParent document */
  upsert?: InputMaybe<SectionTabParentUpsertWithNestedWhereUniqueInput>
}

export type SectionTabParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionTabParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionTabParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionTabParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionTabUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionTabUpdateLocalizationsInput>
  tabContents?: InputMaybe<TabContentUpdateManyInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titlePosition?: InputMaybe<ElementPosition>
}

export type SectionTabUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionTabUpdateLocalizationInput = {
  data: SectionTabUpdateLocalizationDataInput
  locale: Locale
}

export type SectionTabUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionTabCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTabUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionTabUpsertLocalizationInput>>
}

export type SectionTabUpdateManyInlineInput = {
  /** Create and connect multiple SectionTab component instances */
  create?: InputMaybe<Array<SectionTabCreateWithPositionInput>>
  /** Delete multiple SectionTab documents */
  delete?: InputMaybe<Array<SectionTabWhereUniqueInput>>
  /** Update multiple SectionTab component instances */
  update?: InputMaybe<Array<SectionTabUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionTab component instances */
  upsert?: InputMaybe<Array<SectionTabUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionTabUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionTabUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  titlePosition?: InputMaybe<ElementPosition>
}

export type SectionTabUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SectionTabUpdateManyLocalizationInput = {
  data: SectionTabUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionTabUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTabUpdateManyLocalizationInput>>
}

export type SectionTabUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionTabUpdateManyInput
  /** Document search */
  where: SectionTabWhereInput
}

export type SectionTabUpdateOneInlineInput = {
  /** Create and connect one SectionTab document */
  create?: InputMaybe<SectionTabCreateInput>
  /** Delete currently connected SectionTab document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTab document */
  update?: InputMaybe<SectionTabUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTab document */
  upsert?: InputMaybe<SectionTabUpsertWithNestedWhereUniqueInput>
}

export type SectionTabUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionTabUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTabWhereUniqueInput
}

export type SectionTabUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionTabUpdateInput
  /** Unique document search */
  where: SectionTabWhereUniqueInput
}

export type SectionTabUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionTabCreateInput
  /** Update document if it exists */
  update: SectionTabUpdateInput
}

export type SectionTabUpsertLocalizationInput = {
  create: SectionTabCreateLocalizationDataInput
  locale: Locale
  update: SectionTabUpdateLocalizationDataInput
}

export type SectionTabUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionTabUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTabWhereUniqueInput
}

export type SectionTabUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionTabUpsertInput
  /** Unique document search */
  where: SectionTabWhereUniqueInput
}

/** Identifies documents */
export type SectionTabWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTabWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTabWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTabWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  tabContents_every?: InputMaybe<TabContentWhereInput>
  tabContents_none?: InputMaybe<TabContentWhereInput>
  tabContents_some?: InputMaybe<TabContentWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  titlePosition?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  titlePosition_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  titlePosition_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  titlePosition_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionTab record uniquely */
export type SectionTabWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionTeaser = Entity & {
  __typename?: 'SectionTeaser'
  background: Asset
  cta?: Maybe<CallToAction>
  /** DEPRECATED use CTA component */
  ctaLabel?: Maybe<Scalars['String']['output']>
  ctaSecondary?: Maybe<CallToAction>
  /** DEPRECATED use CTA component */
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  headline: Scalars['String']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionTeaser>
  /** System stage field */
  stage: Stage
}

export type SectionTeaserBackgroundArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionTeaserCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionTeaserCtaSecondaryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionTeaserLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionTeaserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionTeaserWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionTeaserConnection = {
  __typename?: 'SectionTeaserConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionTeaserEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionTeaserCreateInput = {
  background: AssetCreateOneInlineInput
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  ctaSecondary?: InputMaybe<CallToActionCreateOneInlineInput>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** headline input for default locale (de) */
  headline: Scalars['String']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionTeaserCreateLocalizationsInput>
}

export type SectionTeaserCreateLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  headline: Scalars['String']['input']
}

export type SectionTeaserCreateLocalizationInput = {
  /** Localization input */
  data: SectionTeaserCreateLocalizationDataInput
  locale: Locale
}

export type SectionTeaserCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionTeaserCreateLocalizationInput>>
}

export type SectionTeaserCreateManyInlineInput = {
  /** Create and connect multiple existing SectionTeaser documents */
  create?: InputMaybe<Array<SectionTeaserCreateInput>>
}

export type SectionTeaserCreateOneInlineInput = {
  /** Create and connect one SectionTeaser document */
  create?: InputMaybe<SectionTeaserCreateInput>
}

export type SectionTeaserCreateWithPositionInput = {
  /** Document to create */
  data: SectionTeaserCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionTeaserEdge = {
  __typename?: 'SectionTeaserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionTeaser
}

/** Identifies documents */
export type SectionTeaserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  cta?: InputMaybe<CallToActionWhereInput>
  ctaSecondary?: InputMaybe<CallToActionWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SectionTeaserOrderByInput {
  CtaLabelAsc = 'ctaLabel_ASC',
  CtaLabelDesc = 'ctaLabel_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionTeaserParent = EventPage | LandingPage

export type SectionTeaserParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SectionTeaserParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SectionTeaserParentCreateManyInlineInput = {
  /** Connect multiple existing SectionTeaserParent documents */
  connect?: InputMaybe<Array<SectionTeaserParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionTeaserParent documents */
  create?: InputMaybe<Array<SectionTeaserParentCreateInput>>
}

export type SectionTeaserParentCreateOneInlineInput = {
  /** Connect one existing SectionTeaserParent document */
  connect?: InputMaybe<SectionTeaserParentWhereUniqueInput>
  /** Create and connect one SectionTeaserParent document */
  create?: InputMaybe<SectionTeaserParentCreateInput>
}

export type SectionTeaserParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SectionTeaserParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionTeaserParent documents */
  connect?: InputMaybe<Array<SectionTeaserParentConnectInput>>
  /** Create and connect multiple SectionTeaserParent documents */
  create?: InputMaybe<Array<SectionTeaserParentCreateInput>>
  /** Delete multiple SectionTeaserParent documents */
  delete?: InputMaybe<Array<SectionTeaserParentWhereUniqueInput>>
  /** Disconnect multiple SectionTeaserParent documents */
  disconnect?: InputMaybe<Array<SectionTeaserParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionTeaserParent documents */
  set?: InputMaybe<Array<SectionTeaserParentWhereUniqueInput>>
  /** Update multiple SectionTeaserParent documents */
  update?: InputMaybe<Array<SectionTeaserParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionTeaserParent documents */
  upsert?: InputMaybe<Array<SectionTeaserParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionTeaserParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SectionTeaserParentUpdateOneInlineInput = {
  /** Connect existing SectionTeaserParent document */
  connect?: InputMaybe<SectionTeaserParentWhereUniqueInput>
  /** Create and connect one SectionTeaserParent document */
  create?: InputMaybe<SectionTeaserParentCreateInput>
  /** Delete currently connected SectionTeaserParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionTeaserParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTeaserParent document */
  update?: InputMaybe<SectionTeaserParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTeaserParent document */
  upsert?: InputMaybe<SectionTeaserParentUpsertWithNestedWhereUniqueInput>
}

export type SectionTeaserParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SectionTeaserParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SectionTeaserParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SectionTeaserParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SectionTeaserUpdateInput = {
  background?: InputMaybe<AssetUpdateOneInlineInput>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  ctaSecondary?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionTeaserUpdateLocalizationsInput>
}

export type SectionTeaserUpdateLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  headline?: InputMaybe<Scalars['String']['input']>
}

export type SectionTeaserUpdateLocalizationInput = {
  data: SectionTeaserUpdateLocalizationDataInput
  locale: Locale
}

export type SectionTeaserUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionTeaserCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTeaserUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionTeaserUpsertLocalizationInput>>
}

export type SectionTeaserUpdateManyInlineInput = {
  /** Create and connect multiple SectionTeaser component instances */
  create?: InputMaybe<Array<SectionTeaserCreateWithPositionInput>>
  /** Delete multiple SectionTeaser documents */
  delete?: InputMaybe<Array<SectionTeaserWhereUniqueInput>>
  /** Update multiple SectionTeaser component instances */
  update?: InputMaybe<Array<SectionTeaserUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionTeaser component instances */
  upsert?: InputMaybe<Array<SectionTeaserUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionTeaserUpdateManyInput = {
  /** ctaLabel input for default locale (de) */
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** headline input for default locale (de) */
  headline?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionTeaserUpdateManyLocalizationsInput>
}

export type SectionTeaserUpdateManyLocalizationDataInput = {
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  headline?: InputMaybe<Scalars['String']['input']>
}

export type SectionTeaserUpdateManyLocalizationInput = {
  data: SectionTeaserUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionTeaserUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTeaserUpdateManyLocalizationInput>>
}

export type SectionTeaserUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionTeaserUpdateManyInput
  /** Document search */
  where: SectionTeaserWhereInput
}

export type SectionTeaserUpdateOneInlineInput = {
  /** Create and connect one SectionTeaser document */
  create?: InputMaybe<SectionTeaserCreateInput>
  /** Delete currently connected SectionTeaser document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTeaser document */
  update?: InputMaybe<SectionTeaserUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTeaser document */
  upsert?: InputMaybe<SectionTeaserUpsertWithNestedWhereUniqueInput>
}

export type SectionTeaserUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionTeaserUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTeaserWhereUniqueInput
}

export type SectionTeaserUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionTeaserUpdateInput
  /** Unique document search */
  where: SectionTeaserWhereUniqueInput
}

export type SectionTeaserUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionTeaserCreateInput
  /** Update document if it exists */
  update: SectionTeaserUpdateInput
}

export type SectionTeaserUpsertLocalizationInput = {
  create: SectionTeaserCreateLocalizationDataInput
  locale: Locale
  update: SectionTeaserUpdateLocalizationDataInput
}

export type SectionTeaserUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionTeaserUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTeaserWhereUniqueInput
}

export type SectionTeaserUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionTeaserUpsertInput
  /** Unique document search */
  where: SectionTeaserWhereUniqueInput
}

/** Identifies documents */
export type SectionTeaserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTeaserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  background?: InputMaybe<AssetWhereInput>
  cta?: InputMaybe<CallToActionWhereInput>
  ctaLabel?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaLabel_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaLabel_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaLabel_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaLabel_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaLabel_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaSecondary?: InputMaybe<CallToActionWhereInput>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  headline?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  headline_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  headline_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  headline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  headline_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  headline_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  headline_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  headline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  headline_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  headline_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SectionTeaser record uniquely */
export type SectionTeaserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section representing single testimonial */
export type SectionTestimonial = Entity & {
  __typename?: 'SectionTestimonial'
  /** Defines style for the testimonial section. Default (square) */
  decorationStyle?: Maybe<DecorationStyle>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Testimonial author image or other visual representation */
  image: Asset
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionTestimonial>
  /** Role in the company */
  position?: Maybe<Scalars['String']['output']>
  /** Testimonial author signature */
  signature: Scalars['String']['output']
  /** Image shown below signature. Can be a company logo */
  signatureImage?: Maybe<Asset>
  /** System stage field */
  stage: Stage
  /** Testimonial text content */
  testimonial: Scalars['String']['output']
}

/** Section representing single testimonial */
export type SectionTestimonialImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Section representing single testimonial */
export type SectionTestimonialLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Section representing single testimonial */
export type SectionTestimonialSignatureImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SectionTestimonialConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionTestimonialWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionTestimonialConnection = {
  __typename?: 'SectionTestimonialConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionTestimonialEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionTestimonialCreateInput = {
  decorationStyle?: InputMaybe<DecorationStyle>
  image: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionTestimonialCreateLocalizationsInput>
  /** position input for default locale (de) */
  position?: InputMaybe<Scalars['String']['input']>
  signature: Scalars['String']['input']
  signatureImage?: InputMaybe<AssetCreateOneInlineInput>
  /** testimonial input for default locale (de) */
  testimonial: Scalars['String']['input']
}

export type SectionTestimonialCreateLocalizationDataInput = {
  position?: InputMaybe<Scalars['String']['input']>
  testimonial: Scalars['String']['input']
}

export type SectionTestimonialCreateLocalizationInput = {
  /** Localization input */
  data: SectionTestimonialCreateLocalizationDataInput
  locale: Locale
}

export type SectionTestimonialCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionTestimonialCreateLocalizationInput>>
}

export type SectionTestimonialCreateManyInlineInput = {
  /** Create and connect multiple existing SectionTestimonial documents */
  create?: InputMaybe<Array<SectionTestimonialCreateInput>>
}

export type SectionTestimonialCreateOneInlineInput = {
  /** Create and connect one SectionTestimonial document */
  create?: InputMaybe<SectionTestimonialCreateInput>
}

export type SectionTestimonialCreateWithPositionInput = {
  /** Document to create */
  data: SectionTestimonialCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionTestimonialEdge = {
  __typename?: 'SectionTestimonialEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionTestimonial
}

/** Identifies documents */
export type SectionTestimonialManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** All values that are contained in given list. */
  decorationStyle_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationStyle_not?: InputMaybe<DecorationStyle>
  /** All values that are not contained in given list. */
  decorationStyle_not_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  signature?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetWhereInput>
  /** All values containing the given string. */
  signature_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  signature_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  signature_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  signature_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  signature_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  signature_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  signature_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  signature_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  signature_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SectionTestimonialOrderByInput {
  DecorationStyleAsc = 'decorationStyle_ASC',
  DecorationStyleDesc = 'decorationStyle_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SignatureAsc = 'signature_ASC',
  SignatureDesc = 'signature_DESC',
  TestimonialAsc = 'testimonial_ASC',
  TestimonialDesc = 'testimonial_DESC',
}

export type SectionTestimonialParent = LandingPage | TestimonialSlider

export type SectionTestimonialParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderConnectInput>
}

export type SectionTestimonialParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderCreateInput>
}

export type SectionTestimonialParentCreateManyInlineInput = {
  /** Connect multiple existing SectionTestimonialParent documents */
  connect?: InputMaybe<Array<SectionTestimonialParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionTestimonialParent documents */
  create?: InputMaybe<Array<SectionTestimonialParentCreateInput>>
}

export type SectionTestimonialParentCreateOneInlineInput = {
  /** Connect one existing SectionTestimonialParent document */
  connect?: InputMaybe<SectionTestimonialParentWhereUniqueInput>
  /** Create and connect one SectionTestimonialParent document */
  create?: InputMaybe<SectionTestimonialParentCreateInput>
}

export type SectionTestimonialParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateInput>
}

export type SectionTestimonialParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionTestimonialParent documents */
  connect?: InputMaybe<Array<SectionTestimonialParentConnectInput>>
  /** Create and connect multiple SectionTestimonialParent documents */
  create?: InputMaybe<Array<SectionTestimonialParentCreateInput>>
  /** Delete multiple SectionTestimonialParent documents */
  delete?: InputMaybe<Array<SectionTestimonialParentWhereUniqueInput>>
  /** Disconnect multiple SectionTestimonialParent documents */
  disconnect?: InputMaybe<Array<SectionTestimonialParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionTestimonialParent documents */
  set?: InputMaybe<Array<SectionTestimonialParentWhereUniqueInput>>
  /** Update multiple SectionTestimonialParent documents */
  update?: InputMaybe<Array<SectionTestimonialParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionTestimonialParent documents */
  upsert?: InputMaybe<Array<SectionTestimonialParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionTestimonialParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateManyWithNestedWhereInput>
}

export type SectionTestimonialParentUpdateOneInlineInput = {
  /** Connect existing SectionTestimonialParent document */
  connect?: InputMaybe<SectionTestimonialParentWhereUniqueInput>
  /** Create and connect one SectionTestimonialParent document */
  create?: InputMaybe<SectionTestimonialParentCreateInput>
  /** Delete currently connected SectionTestimonialParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionTestimonialParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTestimonialParent document */
  update?: InputMaybe<SectionTestimonialParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTestimonialParent document */
  upsert?: InputMaybe<SectionTestimonialParentUpsertWithNestedWhereUniqueInput>
}

export type SectionTestimonialParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueInput>
}

export type SectionTestimonialParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueInput>
}

export type SectionTestimonialParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereInput>
}

export type SectionTestimonialParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
  TestimonialSlider?: InputMaybe<TestimonialSliderWhereUniqueInput>
}

export type SectionTestimonialUpdateInput = {
  decorationStyle?: InputMaybe<DecorationStyle>
  image?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionTestimonialUpdateLocalizationsInput>
  /** position input for default locale (de) */
  position?: InputMaybe<Scalars['String']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** testimonial input for default locale (de) */
  testimonial?: InputMaybe<Scalars['String']['input']>
}

export type SectionTestimonialUpdateLocalizationDataInput = {
  position?: InputMaybe<Scalars['String']['input']>
  testimonial?: InputMaybe<Scalars['String']['input']>
}

export type SectionTestimonialUpdateLocalizationInput = {
  data: SectionTestimonialUpdateLocalizationDataInput
  locale: Locale
}

export type SectionTestimonialUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionTestimonialCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTestimonialUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionTestimonialUpsertLocalizationInput>>
}

export type SectionTestimonialUpdateManyInlineInput = {
  /** Create and connect multiple SectionTestimonial component instances */
  create?: InputMaybe<Array<SectionTestimonialCreateWithPositionInput>>
  /** Delete multiple SectionTestimonial documents */
  delete?: InputMaybe<Array<SectionTestimonialWhereUniqueInput>>
  /** Update multiple SectionTestimonial component instances */
  update?: InputMaybe<Array<SectionTestimonialUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionTestimonial component instances */
  upsert?: InputMaybe<Array<SectionTestimonialUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionTestimonialUpdateManyInput = {
  decorationStyle?: InputMaybe<DecorationStyle>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionTestimonialUpdateManyLocalizationsInput>
  /** position input for default locale (de) */
  position?: InputMaybe<Scalars['String']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  /** testimonial input for default locale (de) */
  testimonial?: InputMaybe<Scalars['String']['input']>
}

export type SectionTestimonialUpdateManyLocalizationDataInput = {
  position?: InputMaybe<Scalars['String']['input']>
  testimonial?: InputMaybe<Scalars['String']['input']>
}

export type SectionTestimonialUpdateManyLocalizationInput = {
  data: SectionTestimonialUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionTestimonialUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTestimonialUpdateManyLocalizationInput>>
}

export type SectionTestimonialUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionTestimonialUpdateManyInput
  /** Document search */
  where: SectionTestimonialWhereInput
}

export type SectionTestimonialUpdateOneInlineInput = {
  /** Create and connect one SectionTestimonial document */
  create?: InputMaybe<SectionTestimonialCreateInput>
  /** Delete currently connected SectionTestimonial document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTestimonial document */
  update?: InputMaybe<SectionTestimonialUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTestimonial document */
  upsert?: InputMaybe<SectionTestimonialUpsertWithNestedWhereUniqueInput>
}

export type SectionTestimonialUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionTestimonialUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTestimonialWhereUniqueInput
}

export type SectionTestimonialUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionTestimonialUpdateInput
  /** Unique document search */
  where: SectionTestimonialWhereUniqueInput
}

export type SectionTestimonialUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionTestimonialCreateInput
  /** Update document if it exists */
  update: SectionTestimonialUpdateInput
}

export type SectionTestimonialUpsertLocalizationInput = {
  create: SectionTestimonialCreateLocalizationDataInput
  locale: Locale
  update: SectionTestimonialUpdateLocalizationDataInput
}

export type SectionTestimonialUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionTestimonialUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTestimonialWhereUniqueInput
}

export type SectionTestimonialUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionTestimonialUpsertInput
  /** Unique document search */
  where: SectionTestimonialWhereUniqueInput
}

/** Identifies documents */
export type SectionTestimonialWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTestimonialWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** All values that are contained in given list. */
  decorationStyle_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationStyle_not?: InputMaybe<DecorationStyle>
  /** All values that are not contained in given list. */
  decorationStyle_not_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  position?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  position_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  position_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  position_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  position_starts_with?: InputMaybe<Scalars['String']['input']>
  signature?: InputMaybe<Scalars['String']['input']>
  signatureImage?: InputMaybe<AssetWhereInput>
  /** All values containing the given string. */
  signature_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  signature_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  signature_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  signature_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  signature_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  signature_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  signature_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  signature_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  signature_starts_with?: InputMaybe<Scalars['String']['input']>
  testimonial?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  testimonial_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  testimonial_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  testimonial_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  testimonial_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  testimonial_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  testimonial_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  testimonial_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  testimonial_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  testimonial_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionTestimonial record uniquely */
export type SectionTestimonialWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SectionTicketsTable = Entity & {
  __typename?: 'SectionTicketsTable'
  discountLabel?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionTicketsTable>
  /** System stage field */
  stage: Stage
  ticketComparisonRows: Array<TicketsTableRow>
  ticketInfoMessage?: Maybe<Scalars['String']['output']>
  ticketPrice: Array<Scalars['String']['output']>
  ticketShopLabel: Scalars['String']['output']
  /** List of cta urls labels that lead to the ticket shop. The order should respect the order of the table */
  ticketShopUrlLabels: Array<Scalars['String']['output']>
  /**
   * list of cta urls that lead to the ticket shop.
   * The order should respect the order of the table
   */
  ticketShopUrls: Array<Scalars['String']['output']>
  ticketType: Array<Scalars['String']['output']>
}

export type SectionTicketsTableLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionTicketsTableTicketComparisonRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TicketsTableRowOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TicketsTableRowWhereInput>
}

export type SectionTicketsTableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionTicketsTableWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionTicketsTableConnection = {
  __typename?: 'SectionTicketsTableConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionTicketsTableEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionTicketsTableCreateInput = {
  /** discountLabel input for default locale (de) */
  discountLabel?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionTicketsTableCreateLocalizationsInput>
  ticketComparisonRows?: InputMaybe<TicketsTableRowCreateManyInlineInput>
  /** ticketInfoMessage input for default locale (de) */
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketPrice: Array<Scalars['String']['input']>
  /** ticketShopLabel input for default locale (de) */
  ticketShopLabel: Scalars['String']['input']
  /** ticketShopUrlLabels input for default locale (de) */
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketShopUrls input for default locale (de) */
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketType input for default locale (de) */
  ticketType: Array<Scalars['String']['input']>
}

export type SectionTicketsTableCreateLocalizationDataInput = {
  discountLabel?: InputMaybe<Scalars['String']['input']>
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketShopLabel: Scalars['String']['input']
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  ticketType: Array<Scalars['String']['input']>
}

export type SectionTicketsTableCreateLocalizationInput = {
  /** Localization input */
  data: SectionTicketsTableCreateLocalizationDataInput
  locale: Locale
}

export type SectionTicketsTableCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionTicketsTableCreateLocalizationInput>>
}

export type SectionTicketsTableCreateManyInlineInput = {
  /** Create and connect multiple existing SectionTicketsTable documents */
  create?: InputMaybe<Array<SectionTicketsTableCreateInput>>
}

export type SectionTicketsTableCreateOneInlineInput = {
  /** Create and connect one SectionTicketsTable document */
  create?: InputMaybe<SectionTicketsTableCreateInput>
}

export type SectionTicketsTableCreateWithPositionInput = {
  /** Document to create */
  data: SectionTicketsTableCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionTicketsTableEdge = {
  __typename?: 'SectionTicketsTableEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionTicketsTable
}

/** Identifies documents */
export type SectionTicketsTableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  ticketComparisonRows_every?: InputMaybe<TicketsTableRowWhereInput>
  ticketComparisonRows_none?: InputMaybe<TicketsTableRowWhereInput>
  ticketComparisonRows_some?: InputMaybe<TicketsTableRowWhereInput>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  ticketPrice?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  ticketPrice_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  ticketPrice_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  ticketPrice_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  ticketPrice_not?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum SectionTicketsTableOrderByInput {
  DiscountLabelAsc = 'discountLabel_ASC',
  DiscountLabelDesc = 'discountLabel_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TicketInfoMessageAsc = 'ticketInfoMessage_ASC',
  TicketInfoMessageDesc = 'ticketInfoMessage_DESC',
  TicketPriceAsc = 'ticketPrice_ASC',
  TicketPriceDesc = 'ticketPrice_DESC',
  TicketShopLabelAsc = 'ticketShopLabel_ASC',
  TicketShopLabelDesc = 'ticketShopLabel_DESC',
  TicketShopUrlLabelsAsc = 'ticketShopUrlLabels_ASC',
  TicketShopUrlLabelsDesc = 'ticketShopUrlLabels_DESC',
  TicketShopUrlsAsc = 'ticketShopUrls_ASC',
  TicketShopUrlsDesc = 'ticketShopUrls_DESC',
  TicketTypeAsc = 'ticketType_ASC',
  TicketTypeDesc = 'ticketType_DESC',
}

export type SectionTicketsTableParent = EventPage

export type SectionTicketsTableParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionTicketsTableParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionTicketsTableParentCreateManyInlineInput = {
  /** Connect multiple existing SectionTicketsTableParent documents */
  connect?: InputMaybe<Array<SectionTicketsTableParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionTicketsTableParent documents */
  create?: InputMaybe<Array<SectionTicketsTableParentCreateInput>>
}

export type SectionTicketsTableParentCreateOneInlineInput = {
  /** Connect one existing SectionTicketsTableParent document */
  connect?: InputMaybe<SectionTicketsTableParentWhereUniqueInput>
  /** Create and connect one SectionTicketsTableParent document */
  create?: InputMaybe<SectionTicketsTableParentCreateInput>
}

export type SectionTicketsTableParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionTicketsTableParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionTicketsTableParent documents */
  connect?: InputMaybe<Array<SectionTicketsTableParentConnectInput>>
  /** Create and connect multiple SectionTicketsTableParent documents */
  create?: InputMaybe<Array<SectionTicketsTableParentCreateInput>>
  /** Delete multiple SectionTicketsTableParent documents */
  delete?: InputMaybe<Array<SectionTicketsTableParentWhereUniqueInput>>
  /** Disconnect multiple SectionTicketsTableParent documents */
  disconnect?: InputMaybe<Array<SectionTicketsTableParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionTicketsTableParent documents */
  set?: InputMaybe<Array<SectionTicketsTableParentWhereUniqueInput>>
  /** Update multiple SectionTicketsTableParent documents */
  update?: InputMaybe<Array<SectionTicketsTableParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionTicketsTableParent documents */
  upsert?: InputMaybe<Array<SectionTicketsTableParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionTicketsTableParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionTicketsTableParentUpdateOneInlineInput = {
  /** Connect existing SectionTicketsTableParent document */
  connect?: InputMaybe<SectionTicketsTableParentWhereUniqueInput>
  /** Create and connect one SectionTicketsTableParent document */
  create?: InputMaybe<SectionTicketsTableParentCreateInput>
  /** Delete currently connected SectionTicketsTableParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionTicketsTableParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTicketsTableParent document */
  update?: InputMaybe<SectionTicketsTableParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTicketsTableParent document */
  upsert?: InputMaybe<SectionTicketsTableParentUpsertWithNestedWhereUniqueInput>
}

export type SectionTicketsTableParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionTicketsTableParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionTicketsTableParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionTicketsTableParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionTicketsTableUpdateInput = {
  /** discountLabel input for default locale (de) */
  discountLabel?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionTicketsTableUpdateLocalizationsInput>
  ticketComparisonRows?: InputMaybe<TicketsTableRowUpdateManyInlineInput>
  /** ticketInfoMessage input for default locale (de) */
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketPrice?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketShopLabel input for default locale (de) */
  ticketShopLabel?: InputMaybe<Scalars['String']['input']>
  /** ticketShopUrlLabels input for default locale (de) */
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketShopUrls input for default locale (de) */
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketType input for default locale (de) */
  ticketType?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SectionTicketsTableUpdateLocalizationDataInput = {
  discountLabel?: InputMaybe<Scalars['String']['input']>
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketShopLabel?: InputMaybe<Scalars['String']['input']>
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  ticketType?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SectionTicketsTableUpdateLocalizationInput = {
  data: SectionTicketsTableUpdateLocalizationDataInput
  locale: Locale
}

export type SectionTicketsTableUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionTicketsTableCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTicketsTableUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionTicketsTableUpsertLocalizationInput>>
}

export type SectionTicketsTableUpdateManyInlineInput = {
  /** Create and connect multiple SectionTicketsTable component instances */
  create?: InputMaybe<Array<SectionTicketsTableCreateWithPositionInput>>
  /** Delete multiple SectionTicketsTable documents */
  delete?: InputMaybe<Array<SectionTicketsTableWhereUniqueInput>>
  /** Update multiple SectionTicketsTable component instances */
  update?: InputMaybe<Array<SectionTicketsTableUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionTicketsTable component instances */
  upsert?: InputMaybe<Array<SectionTicketsTableUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionTicketsTableUpdateManyInput = {
  /** discountLabel input for default locale (de) */
  discountLabel?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionTicketsTableUpdateManyLocalizationsInput>
  /** ticketInfoMessage input for default locale (de) */
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketPrice?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketShopLabel input for default locale (de) */
  ticketShopLabel?: InputMaybe<Scalars['String']['input']>
  /** ticketShopUrlLabels input for default locale (de) */
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketShopUrls input for default locale (de) */
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  /** ticketType input for default locale (de) */
  ticketType?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SectionTicketsTableUpdateManyLocalizationDataInput = {
  discountLabel?: InputMaybe<Scalars['String']['input']>
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  ticketShopLabel?: InputMaybe<Scalars['String']['input']>
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  ticketType?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SectionTicketsTableUpdateManyLocalizationInput = {
  data: SectionTicketsTableUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionTicketsTableUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionTicketsTableUpdateManyLocalizationInput>>
}

export type SectionTicketsTableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionTicketsTableUpdateManyInput
  /** Document search */
  where: SectionTicketsTableWhereInput
}

export type SectionTicketsTableUpdateOneInlineInput = {
  /** Create and connect one SectionTicketsTable document */
  create?: InputMaybe<SectionTicketsTableCreateInput>
  /** Delete currently connected SectionTicketsTable document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionTicketsTable document */
  update?: InputMaybe<SectionTicketsTableUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionTicketsTable document */
  upsert?: InputMaybe<SectionTicketsTableUpsertWithNestedWhereUniqueInput>
}

export type SectionTicketsTableUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionTicketsTableUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTicketsTableWhereUniqueInput
}

export type SectionTicketsTableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionTicketsTableUpdateInput
  /** Unique document search */
  where: SectionTicketsTableWhereUniqueInput
}

export type SectionTicketsTableUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionTicketsTableCreateInput
  /** Update document if it exists */
  update: SectionTicketsTableUpdateInput
}

export type SectionTicketsTableUpsertLocalizationInput = {
  create: SectionTicketsTableCreateLocalizationDataInput
  locale: Locale
  update: SectionTicketsTableUpdateLocalizationDataInput
}

export type SectionTicketsTableUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionTicketsTableUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionTicketsTableWhereUniqueInput
}

export type SectionTicketsTableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionTicketsTableUpsertInput
  /** Unique document search */
  where: SectionTicketsTableWhereUniqueInput
}

/** Identifies documents */
export type SectionTicketsTableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionTicketsTableWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  discountLabel?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  discountLabel_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  discountLabel_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  discountLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  discountLabel_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  discountLabel_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  discountLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  discountLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  discountLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  discountLabel_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  ticketComparisonRows_every?: InputMaybe<TicketsTableRowWhereInput>
  ticketComparisonRows_none?: InputMaybe<TicketsTableRowWhereInput>
  ticketComparisonRows_some?: InputMaybe<TicketsTableRowWhereInput>
  ticketInfoMessage?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ticketInfoMessage_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ticketInfoMessage_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ticketInfoMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ticketInfoMessage_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ticketInfoMessage_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ticketInfoMessage_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ticketInfoMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ticketInfoMessage_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ticketInfoMessage_starts_with?: InputMaybe<Scalars['String']['input']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  ticketPrice?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  ticketPrice_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  ticketPrice_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  ticketPrice_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  ticketPrice_not?: InputMaybe<Array<Scalars['String']['input']>>
  ticketShopLabel?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ticketShopLabel_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ticketShopLabel_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ticketShopLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ticketShopLabel_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ticketShopLabel_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ticketShopLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ticketShopLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ticketShopLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ticketShopLabel_starts_with?: InputMaybe<Scalars['String']['input']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  ticketShopUrlLabels?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  ticketShopUrlLabels_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  ticketShopUrlLabels_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  ticketShopUrlLabels_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  ticketShopUrlLabels_not?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  ticketShopUrls?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  ticketShopUrls_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  ticketShopUrls_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  ticketShopUrls_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  ticketShopUrls_not?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  ticketType?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  ticketType_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  ticketType_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  ticketType_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  ticketType_not?: InputMaybe<Array<Scalars['String']['input']>>
}

/** References SectionTicketsTable record uniquely */
export type SectionTicketsTableWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Section showing a video with a title. */
export type SectionVideo = Entity & {
  __typename?: 'SectionVideo'
  dates: Array<Scalars['String']['output']>
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  /** Hide the Section Head for Livestream Layout */
  hideSectionHead?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SectionVideo>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  title: Scalars['String']['output']
  vidTitle?: Maybe<Scalars['String']['output']>
  ytVideoId: Scalars['String']['output']
  ytVideoTitle: Scalars['String']['output']
}

/** Section showing a video with a title. */
export type SectionVideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SectionVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SectionVideoWhereUniqueInput
}

/** A connection to a list of items. */
export type SectionVideoConnection = {
  __typename?: 'SectionVideoConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SectionVideoEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SectionVideoCreateInput = {
  /** dates input for default locale (de) */
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hideSectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionVideoCreateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  /** vidTitle input for default locale (de) */
  vidTitle?: InputMaybe<Scalars['String']['input']>
  ytVideoId: Scalars['String']['input']
  ytVideoTitle: Scalars['String']['input']
}

export type SectionVideoCreateLocalizationDataInput = {
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  vidTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionVideoCreateLocalizationInput = {
  /** Localization input */
  data: SectionVideoCreateLocalizationDataInput
  locale: Locale
}

export type SectionVideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionVideoCreateLocalizationInput>>
}

export type SectionVideoCreateManyInlineInput = {
  /** Create and connect multiple existing SectionVideo documents */
  create?: InputMaybe<Array<SectionVideoCreateInput>>
}

export type SectionVideoCreateOneInlineInput = {
  /** Create and connect one SectionVideo document */
  create?: InputMaybe<SectionVideoCreateInput>
}

export type SectionVideoCreateWithPositionInput = {
  /** Document to create */
  data: SectionVideoCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SectionVideoEdge = {
  __typename?: 'SectionVideoEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SectionVideo
}

/** Identifies documents */
export type SectionVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  hideSectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideSectionHead_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  ytVideoId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ytVideoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ytVideoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>
  ytVideoTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ytVideoTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ytVideoTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ytVideoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ytVideoTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ytVideoTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ytVideoTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ytVideoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ytVideoTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ytVideoTitle_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SectionVideoOrderByInput {
  DatesAsc = 'dates_ASC',
  DatesDesc = 'dates_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HideSectionHeadAsc = 'hideSectionHead_ASC',
  HideSectionHeadDesc = 'hideSectionHead_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VidTitleAsc = 'vidTitle_ASC',
  VidTitleDesc = 'vidTitle_DESC',
  YtVideoIdAsc = 'ytVideoId_ASC',
  YtVideoIdDesc = 'ytVideoId_DESC',
  YtVideoTitleAsc = 'ytVideoTitle_ASC',
  YtVideoTitleDesc = 'ytVideoTitle_DESC',
}

export type SectionVideoParent = EventPage

export type SectionVideoParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type SectionVideoParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type SectionVideoParentCreateManyInlineInput = {
  /** Connect multiple existing SectionVideoParent documents */
  connect?: InputMaybe<Array<SectionVideoParentWhereUniqueInput>>
  /** Create and connect multiple existing SectionVideoParent documents */
  create?: InputMaybe<Array<SectionVideoParentCreateInput>>
}

export type SectionVideoParentCreateOneInlineInput = {
  /** Connect one existing SectionVideoParent document */
  connect?: InputMaybe<SectionVideoParentWhereUniqueInput>
  /** Create and connect one SectionVideoParent document */
  create?: InputMaybe<SectionVideoParentCreateInput>
}

export type SectionVideoParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type SectionVideoParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionVideoParent documents */
  connect?: InputMaybe<Array<SectionVideoParentConnectInput>>
  /** Create and connect multiple SectionVideoParent documents */
  create?: InputMaybe<Array<SectionVideoParentCreateInput>>
  /** Delete multiple SectionVideoParent documents */
  delete?: InputMaybe<Array<SectionVideoParentWhereUniqueInput>>
  /** Disconnect multiple SectionVideoParent documents */
  disconnect?: InputMaybe<Array<SectionVideoParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SectionVideoParent documents */
  set?: InputMaybe<Array<SectionVideoParentWhereUniqueInput>>
  /** Update multiple SectionVideoParent documents */
  update?: InputMaybe<Array<SectionVideoParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SectionVideoParent documents */
  upsert?: InputMaybe<Array<SectionVideoParentUpsertWithNestedWhereUniqueInput>>
}

export type SectionVideoParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type SectionVideoParentUpdateOneInlineInput = {
  /** Connect existing SectionVideoParent document */
  connect?: InputMaybe<SectionVideoParentWhereUniqueInput>
  /** Create and connect one SectionVideoParent document */
  create?: InputMaybe<SectionVideoParentCreateInput>
  /** Delete currently connected SectionVideoParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SectionVideoParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionVideoParent document */
  update?: InputMaybe<SectionVideoParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionVideoParent document */
  upsert?: InputMaybe<SectionVideoParentUpsertWithNestedWhereUniqueInput>
}

export type SectionVideoParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type SectionVideoParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type SectionVideoParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type SectionVideoParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type SectionVideoUpdateInput = {
  /** dates input for default locale (de) */
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hideSectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SectionVideoUpdateLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** vidTitle input for default locale (de) */
  vidTitle?: InputMaybe<Scalars['String']['input']>
  ytVideoId?: InputMaybe<Scalars['String']['input']>
  ytVideoTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionVideoUpdateLocalizationDataInput = {
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  vidTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionVideoUpdateLocalizationInput = {
  data: SectionVideoUpdateLocalizationDataInput
  locale: Locale
}

export type SectionVideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionVideoCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SectionVideoUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SectionVideoUpsertLocalizationInput>>
}

export type SectionVideoUpdateManyInlineInput = {
  /** Create and connect multiple SectionVideo component instances */
  create?: InputMaybe<Array<SectionVideoCreateWithPositionInput>>
  /** Delete multiple SectionVideo documents */
  delete?: InputMaybe<Array<SectionVideoWhereUniqueInput>>
  /** Update multiple SectionVideo component instances */
  update?: InputMaybe<Array<SectionVideoUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SectionVideo component instances */
  upsert?: InputMaybe<Array<SectionVideoUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SectionVideoUpdateManyInput = {
  /** dates input for default locale (de) */
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  hideSectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionVideoUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** vidTitle input for default locale (de) */
  vidTitle?: InputMaybe<Scalars['String']['input']>
  ytVideoId?: InputMaybe<Scalars['String']['input']>
  ytVideoTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionVideoUpdateManyLocalizationDataInput = {
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  vidTitle?: InputMaybe<Scalars['String']['input']>
}

export type SectionVideoUpdateManyLocalizationInput = {
  data: SectionVideoUpdateManyLocalizationDataInput
  locale: Locale
}

export type SectionVideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionVideoUpdateManyLocalizationInput>>
}

export type SectionVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionVideoUpdateManyInput
  /** Document search */
  where: SectionVideoWhereInput
}

export type SectionVideoUpdateOneInlineInput = {
  /** Create and connect one SectionVideo document */
  create?: InputMaybe<SectionVideoCreateInput>
  /** Delete currently connected SectionVideo document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SectionVideo document */
  update?: InputMaybe<SectionVideoUpdateWithNestedWhereUniqueInput>
  /** Upsert single SectionVideo document */
  upsert?: InputMaybe<SectionVideoUpsertWithNestedWhereUniqueInput>
}

export type SectionVideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionVideoUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionVideoWhereUniqueInput
}

export type SectionVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionVideoUpdateInput
  /** Unique document search */
  where: SectionVideoWhereUniqueInput
}

export type SectionVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionVideoCreateInput
  /** Update document if it exists */
  update: SectionVideoUpdateInput
}

export type SectionVideoUpsertLocalizationInput = {
  create: SectionVideoCreateLocalizationDataInput
  locale: Locale
  update: SectionVideoUpdateLocalizationDataInput
}

export type SectionVideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionVideoUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SectionVideoWhereUniqueInput
}

export type SectionVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionVideoUpsertInput
  /** Unique document search */
  where: SectionVideoWhereUniqueInput
}

/** Identifies documents */
export type SectionVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionVideoWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  dates?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  dates_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  dates_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  dates_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  dates_not?: InputMaybe<Array<Scalars['String']['input']>>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  hideSectionHead?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  hideSectionHead_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  vidTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  vidTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  vidTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  vidTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  vidTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  vidTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  vidTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  vidTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  vidTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  vidTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  ytVideoId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ytVideoId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ytVideoId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ytVideoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ytVideoId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ytVideoId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ytVideoId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ytVideoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ytVideoId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ytVideoId_starts_with?: InputMaybe<Scalars['String']['input']>
  ytVideoTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ytVideoTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ytVideoTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ytVideoTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ytVideoTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ytVideoTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ytVideoTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ytVideoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ytVideoTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ytVideoTitle_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SectionVideo record uniquely */
export type SectionVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Seo = Entity & {
  __typename?: 'Seo'
  /** If not provided we will fallback to article snippet */
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Seo>
  noFollow?: Maybe<Scalars['Boolean']['output']>
  noIndex?: Maybe<Scalars['Boolean']['output']>
  /** If not provided we will fallback to SEO description */
  ogDescription?: Maybe<Scalars['String']['output']>
  ogImage?: Maybe<Asset>
  /** If not provided we will fallback to SEO title */
  ogTitle?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  /** If not provided we will fallback to page/article title */
  title?: Maybe<Scalars['String']['output']>
}

export type SeoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SeoOgImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SeoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SeoWhereUniqueInput
}

/** A connection to a list of items. */
export type SeoConnection = {
  __typename?: 'SeoConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SeoEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SeoContentAttribute = Entity & {
  __typename?: 'SeoContentAttribute'
  bottomSectionBody?: Maybe<RichText>
  bottomSectionHeadline?: Maybe<Scalars['String']['output']>
  h1?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SeoContentAttribute>
  /** System stage field */
  stage: Stage
  topSectionBody?: Maybe<RichText>
}

export type SeoContentAttributeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SeoContentAttributeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SeoContentAttributeWhereUniqueInput
}

/** A connection to a list of items. */
export type SeoContentAttributeConnection = {
  __typename?: 'SeoContentAttributeConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SeoContentAttributeEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SeoContentAttributeCreateInput = {
  /** bottomSectionBody input for default locale (de) */
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  /** bottomSectionHeadline input for default locale (de) */
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  /** h1 input for default locale (de) */
  h1?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SeoContentAttributeCreateLocalizationsInput>
  /** topSectionBody input for default locale (de) */
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeCreateLocalizationDataInput = {
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  h1?: InputMaybe<Scalars['String']['input']>
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeCreateLocalizationInput = {
  /** Localization input */
  data: SeoContentAttributeCreateLocalizationDataInput
  locale: Locale
}

export type SeoContentAttributeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SeoContentAttributeCreateLocalizationInput>>
}

export type SeoContentAttributeCreateManyInlineInput = {
  /** Create and connect multiple existing SeoContentAttribute documents */
  create?: InputMaybe<Array<SeoContentAttributeCreateInput>>
}

export type SeoContentAttributeCreateOneInlineInput = {
  /** Create and connect one SeoContentAttribute document */
  create?: InputMaybe<SeoContentAttributeCreateInput>
}

export type SeoContentAttributeCreateWithPositionInput = {
  /** Document to create */
  data: SeoContentAttributeCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SeoContentAttributeEdge = {
  __typename?: 'SeoContentAttributeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SeoContentAttribute
}

/** Identifies documents */
export type SeoContentAttributeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum SeoContentAttributeOrderByInput {
  BottomSectionHeadlineAsc = 'bottomSectionHeadline_ASC',
  BottomSectionHeadlineDesc = 'bottomSectionHeadline_DESC',
  H1Asc = 'h1_ASC',
  H1Desc = 'h1_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SeoContentAttributeParent = LandingPage | Tag

export type SeoContentAttributeParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
  Tag?: InputMaybe<TagConnectInput>
}

export type SeoContentAttributeParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
  Tag?: InputMaybe<TagCreateInput>
}

export type SeoContentAttributeParentCreateManyInlineInput = {
  /** Connect multiple existing SeoContentAttributeParent documents */
  connect?: InputMaybe<Array<SeoContentAttributeParentWhereUniqueInput>>
  /** Create and connect multiple existing SeoContentAttributeParent documents */
  create?: InputMaybe<Array<SeoContentAttributeParentCreateInput>>
}

export type SeoContentAttributeParentCreateOneInlineInput = {
  /** Connect one existing SeoContentAttributeParent document */
  connect?: InputMaybe<SeoContentAttributeParentWhereUniqueInput>
  /** Create and connect one SeoContentAttributeParent document */
  create?: InputMaybe<SeoContentAttributeParentCreateInput>
}

export type SeoContentAttributeParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
  Tag?: InputMaybe<TagUpdateInput>
}

export type SeoContentAttributeParentUpdateManyInlineInput = {
  /** Connect multiple existing SeoContentAttributeParent documents */
  connect?: InputMaybe<Array<SeoContentAttributeParentConnectInput>>
  /** Create and connect multiple SeoContentAttributeParent documents */
  create?: InputMaybe<Array<SeoContentAttributeParentCreateInput>>
  /** Delete multiple SeoContentAttributeParent documents */
  delete?: InputMaybe<Array<SeoContentAttributeParentWhereUniqueInput>>
  /** Disconnect multiple SeoContentAttributeParent documents */
  disconnect?: InputMaybe<Array<SeoContentAttributeParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SeoContentAttributeParent documents */
  set?: InputMaybe<Array<SeoContentAttributeParentWhereUniqueInput>>
  /** Update multiple SeoContentAttributeParent documents */
  update?: InputMaybe<Array<SeoContentAttributeParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SeoContentAttributeParent documents */
  upsert?: InputMaybe<Array<SeoContentAttributeParentUpsertWithNestedWhereUniqueInput>>
}

export type SeoContentAttributeParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
  Tag?: InputMaybe<TagUpdateManyWithNestedWhereInput>
}

export type SeoContentAttributeParentUpdateOneInlineInput = {
  /** Connect existing SeoContentAttributeParent document */
  connect?: InputMaybe<SeoContentAttributeParentWhereUniqueInput>
  /** Create and connect one SeoContentAttributeParent document */
  create?: InputMaybe<SeoContentAttributeParentCreateInput>
  /** Delete currently connected SeoContentAttributeParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SeoContentAttributeParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SeoContentAttributeParent document */
  update?: InputMaybe<SeoContentAttributeParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SeoContentAttributeParent document */
  upsert?: InputMaybe<SeoContentAttributeParentUpsertWithNestedWhereUniqueInput>
}

export type SeoContentAttributeParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
  Tag?: InputMaybe<TagUpdateWithNestedWhereUniqueInput>
}

export type SeoContentAttributeParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
  Tag?: InputMaybe<TagUpsertWithNestedWhereUniqueInput>
}

export type SeoContentAttributeParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
  Tag?: InputMaybe<TagWhereInput>
}

export type SeoContentAttributeParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
  Tag?: InputMaybe<TagWhereUniqueInput>
}

export type SeoContentAttributeUpdateInput = {
  /** bottomSectionBody input for default locale (de) */
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  /** bottomSectionHeadline input for default locale (de) */
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  /** h1 input for default locale (de) */
  h1?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SeoContentAttributeUpdateLocalizationsInput>
  /** topSectionBody input for default locale (de) */
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeUpdateLocalizationDataInput = {
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  h1?: InputMaybe<Scalars['String']['input']>
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeUpdateLocalizationInput = {
  data: SeoContentAttributeUpdateLocalizationDataInput
  locale: Locale
}

export type SeoContentAttributeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SeoContentAttributeCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SeoContentAttributeUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SeoContentAttributeUpsertLocalizationInput>>
}

export type SeoContentAttributeUpdateManyInlineInput = {
  /** Create and connect multiple SeoContentAttribute component instances */
  create?: InputMaybe<Array<SeoContentAttributeCreateWithPositionInput>>
  /** Delete multiple SeoContentAttribute documents */
  delete?: InputMaybe<Array<SeoContentAttributeWhereUniqueInput>>
  /** Update multiple SeoContentAttribute component instances */
  update?: InputMaybe<Array<SeoContentAttributeUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SeoContentAttribute component instances */
  upsert?: InputMaybe<Array<SeoContentAttributeUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SeoContentAttributeUpdateManyInput = {
  /** bottomSectionBody input for default locale (de) */
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  /** bottomSectionHeadline input for default locale (de) */
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  /** h1 input for default locale (de) */
  h1?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SeoContentAttributeUpdateManyLocalizationsInput>
  /** topSectionBody input for default locale (de) */
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeUpdateManyLocalizationDataInput = {
  bottomSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  h1?: InputMaybe<Scalars['String']['input']>
  topSectionBody?: InputMaybe<Scalars['RichTextAST']['input']>
}

export type SeoContentAttributeUpdateManyLocalizationInput = {
  data: SeoContentAttributeUpdateManyLocalizationDataInput
  locale: Locale
}

export type SeoContentAttributeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SeoContentAttributeUpdateManyLocalizationInput>>
}

export type SeoContentAttributeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SeoContentAttributeUpdateManyInput
  /** Document search */
  where: SeoContentAttributeWhereInput
}

export type SeoContentAttributeUpdateOneInlineInput = {
  /** Create and connect one SeoContentAttribute document */
  create?: InputMaybe<SeoContentAttributeCreateInput>
  /** Delete currently connected SeoContentAttribute document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SeoContentAttribute document */
  update?: InputMaybe<SeoContentAttributeUpdateWithNestedWhereUniqueInput>
  /** Upsert single SeoContentAttribute document */
  upsert?: InputMaybe<SeoContentAttributeUpsertWithNestedWhereUniqueInput>
}

export type SeoContentAttributeUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SeoContentAttributeUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SeoContentAttributeWhereUniqueInput
}

export type SeoContentAttributeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SeoContentAttributeUpdateInput
  /** Unique document search */
  where: SeoContentAttributeWhereUniqueInput
}

export type SeoContentAttributeUpsertInput = {
  /** Create document if it didn't exist */
  create: SeoContentAttributeCreateInput
  /** Update document if it exists */
  update: SeoContentAttributeUpdateInput
}

export type SeoContentAttributeUpsertLocalizationInput = {
  create: SeoContentAttributeCreateLocalizationDataInput
  locale: Locale
  update: SeoContentAttributeUpdateLocalizationDataInput
}

export type SeoContentAttributeUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SeoContentAttributeUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SeoContentAttributeWhereUniqueInput
}

export type SeoContentAttributeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SeoContentAttributeUpsertInput
  /** Unique document search */
  where: SeoContentAttributeWhereUniqueInput
}

/** Identifies documents */
export type SeoContentAttributeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoContentAttributeWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  bottomSectionHeadline?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  bottomSectionHeadline_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  bottomSectionHeadline_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  bottomSectionHeadline_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  bottomSectionHeadline_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  bottomSectionHeadline_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  bottomSectionHeadline_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  bottomSectionHeadline_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  bottomSectionHeadline_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  bottomSectionHeadline_starts_with?: InputMaybe<Scalars['String']['input']>
  h1?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  h1_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  h1_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  h1_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  h1_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  h1_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  h1_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  h1_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  h1_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  h1_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

/** References SeoContentAttribute record uniquely */
export type SeoContentAttributeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type SeoCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** keywords input for default locale (de) */
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SeoCreateLocalizationsInput>
  noFollow?: InputMaybe<Scalars['Boolean']['input']>
  noIndex?: InputMaybe<Scalars['Boolean']['input']>
  /** ogDescription input for default locale (de) */
  ogDescription?: InputMaybe<Scalars['String']['input']>
  ogImage?: InputMaybe<AssetCreateOneInlineInput>
  /** ogTitle input for default locale (de) */
  ogTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  ogDescription?: InputMaybe<Scalars['String']['input']>
  ogTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoCreateLocalizationInput = {
  /** Localization input */
  data: SeoCreateLocalizationDataInput
  locale: Locale
}

export type SeoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>
}

export type SeoCreateManyInlineInput = {
  /** Create and connect multiple existing Seo documents */
  create?: InputMaybe<Array<SeoCreateInput>>
}

export type SeoCreateOneInlineInput = {
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>
}

export type SeoCreateWithPositionInput = {
  /** Document to create */
  data: SeoCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SeoEdge = {
  __typename?: 'SeoEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Seo
}

/** Identifies documents */
export type SeoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  noFollow?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>
  noIndex?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>
  ogImage?: InputMaybe<AssetWhereInput>
}

export enum SeoOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeywordsAsc = 'keywords_ASC',
  KeywordsDesc = 'keywords_DESC',
  NoFollowAsc = 'noFollow_ASC',
  NoFollowDesc = 'noFollow_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  OgDescriptionAsc = 'ogDescription_ASC',
  OgDescriptionDesc = 'ogDescription_DESC',
  OgTitleAsc = 'ogTitle_ASC',
  OgTitleDesc = 'ogTitle_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SeoParent =
  | Article
  | ArticleCategory
  | Author
  | EventPage
  | Glossary
  | LandingPage
  | ReviewsProduct
  | ReviewsProductCategory
  | Tag
  | TopicPage

export type SeoParentConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>
  ArticleCategory?: InputMaybe<ArticleCategoryConnectInput>
  Author?: InputMaybe<AuthorConnectInput>
  EventPage?: InputMaybe<EventPageConnectInput>
  Glossary?: InputMaybe<GlossaryConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
  ReviewsProduct?: InputMaybe<ReviewsProductConnectInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryConnectInput>
  Tag?: InputMaybe<TagConnectInput>
  TopicPage?: InputMaybe<TopicPageConnectInput>
}

export type SeoParentCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>
  ArticleCategory?: InputMaybe<ArticleCategoryCreateInput>
  Author?: InputMaybe<AuthorCreateInput>
  EventPage?: InputMaybe<EventPageCreateInput>
  Glossary?: InputMaybe<GlossaryCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
  ReviewsProduct?: InputMaybe<ReviewsProductCreateInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryCreateInput>
  Tag?: InputMaybe<TagCreateInput>
  TopicPage?: InputMaybe<TopicPageCreateInput>
}

export type SeoParentCreateManyInlineInput = {
  /** Connect multiple existing SeoParent documents */
  connect?: InputMaybe<Array<SeoParentWhereUniqueInput>>
  /** Create and connect multiple existing SeoParent documents */
  create?: InputMaybe<Array<SeoParentCreateInput>>
}

export type SeoParentCreateOneInlineInput = {
  /** Connect one existing SeoParent document */
  connect?: InputMaybe<SeoParentWhereUniqueInput>
  /** Create and connect one SeoParent document */
  create?: InputMaybe<SeoParentCreateInput>
}

export type SeoParentUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>
  ArticleCategory?: InputMaybe<ArticleCategoryUpdateInput>
  Author?: InputMaybe<AuthorUpdateInput>
  EventPage?: InputMaybe<EventPageUpdateInput>
  Glossary?: InputMaybe<GlossaryUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryUpdateInput>
  Tag?: InputMaybe<TagUpdateInput>
  TopicPage?: InputMaybe<TopicPageUpdateInput>
}

export type SeoParentUpdateManyInlineInput = {
  /** Connect multiple existing SeoParent documents */
  connect?: InputMaybe<Array<SeoParentConnectInput>>
  /** Create and connect multiple SeoParent documents */
  create?: InputMaybe<Array<SeoParentCreateInput>>
  /** Delete multiple SeoParent documents */
  delete?: InputMaybe<Array<SeoParentWhereUniqueInput>>
  /** Disconnect multiple SeoParent documents */
  disconnect?: InputMaybe<Array<SeoParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SeoParent documents */
  set?: InputMaybe<Array<SeoParentWhereUniqueInput>>
  /** Update multiple SeoParent documents */
  update?: InputMaybe<Array<SeoParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SeoParent documents */
  upsert?: InputMaybe<Array<SeoParentUpsertWithNestedWhereUniqueInput>>
}

export type SeoParentUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>
  ArticleCategory?: InputMaybe<ArticleCategoryUpdateManyWithNestedWhereInput>
  Author?: InputMaybe<AuthorUpdateManyWithNestedWhereInput>
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  Glossary?: InputMaybe<GlossaryUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateManyWithNestedWhereInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryUpdateManyWithNestedWhereInput>
  Tag?: InputMaybe<TagUpdateManyWithNestedWhereInput>
  TopicPage?: InputMaybe<TopicPageUpdateManyWithNestedWhereInput>
}

export type SeoParentUpdateOneInlineInput = {
  /** Connect existing SeoParent document */
  connect?: InputMaybe<SeoParentWhereUniqueInput>
  /** Create and connect one SeoParent document */
  create?: InputMaybe<SeoParentCreateInput>
  /** Delete currently connected SeoParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SeoParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SeoParent document */
  update?: InputMaybe<SeoParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SeoParent document */
  upsert?: InputMaybe<SeoParentUpsertWithNestedWhereUniqueInput>
}

export type SeoParentUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>
  ArticleCategory?: InputMaybe<ArticleCategoryUpdateWithNestedWhereUniqueInput>
  Author?: InputMaybe<AuthorUpdateWithNestedWhereUniqueInput>
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  Glossary?: InputMaybe<GlossaryUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateWithNestedWhereUniqueInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryUpdateWithNestedWhereUniqueInput>
  Tag?: InputMaybe<TagUpdateWithNestedWhereUniqueInput>
  TopicPage?: InputMaybe<TopicPageUpdateWithNestedWhereUniqueInput>
}

export type SeoParentUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>
  ArticleCategory?: InputMaybe<ArticleCategoryUpsertWithNestedWhereUniqueInput>
  Author?: InputMaybe<AuthorUpsertWithNestedWhereUniqueInput>
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  Glossary?: InputMaybe<GlossaryUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpsertWithNestedWhereUniqueInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryUpsertWithNestedWhereUniqueInput>
  Tag?: InputMaybe<TagUpsertWithNestedWhereUniqueInput>
  TopicPage?: InputMaybe<TopicPageUpsertWithNestedWhereUniqueInput>
}

export type SeoParentWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>
  ArticleCategory?: InputMaybe<ArticleCategoryWhereInput>
  Author?: InputMaybe<AuthorWhereInput>
  EventPage?: InputMaybe<EventPageWhereInput>
  Glossary?: InputMaybe<GlossaryWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
  ReviewsProduct?: InputMaybe<ReviewsProductWhereInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryWhereInput>
  Tag?: InputMaybe<TagWhereInput>
  TopicPage?: InputMaybe<TopicPageWhereInput>
}

export type SeoParentWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>
  ArticleCategory?: InputMaybe<ArticleCategoryWhereUniqueInput>
  Author?: InputMaybe<AuthorWhereUniqueInput>
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  Glossary?: InputMaybe<GlossaryWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductWhereUniqueInput>
  ReviewsProductCategory?: InputMaybe<ReviewsProductCategoryWhereUniqueInput>
  Tag?: InputMaybe<TagWhereUniqueInput>
  TopicPage?: InputMaybe<TopicPageWhereUniqueInput>
}

export type SeoUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** keywords input for default locale (de) */
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  /** Manage document localizations */
  localizations?: InputMaybe<SeoUpdateLocalizationsInput>
  noFollow?: InputMaybe<Scalars['Boolean']['input']>
  noIndex?: InputMaybe<Scalars['Boolean']['input']>
  /** ogDescription input for default locale (de) */
  ogDescription?: InputMaybe<Scalars['String']['input']>
  ogImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** ogTitle input for default locale (de) */
  ogTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  ogDescription?: InputMaybe<Scalars['String']['input']>
  ogTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoUpdateLocalizationInput = {
  data: SeoUpdateLocalizationDataInput
  locale: Locale
}

export type SeoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SeoUpsertLocalizationInput>>
}

export type SeoUpdateManyInlineInput = {
  /** Create and connect multiple Seo component instances */
  create?: InputMaybe<Array<SeoCreateWithPositionInput>>
  /** Delete multiple Seo documents */
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>
  /** Update multiple Seo component instances */
  update?: InputMaybe<Array<SeoUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Seo component instances */
  upsert?: InputMaybe<Array<SeoUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SeoUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** keywords input for default locale (de) */
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SeoUpdateManyLocalizationsInput>
  noFollow?: InputMaybe<Scalars['Boolean']['input']>
  noIndex?: InputMaybe<Scalars['Boolean']['input']>
  /** ogDescription input for default locale (de) */
  ogDescription?: InputMaybe<Scalars['String']['input']>
  /** ogTitle input for default locale (de) */
  ogTitle?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  ogDescription?: InputMaybe<Scalars['String']['input']>
  ogTitle?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SeoUpdateManyLocalizationInput = {
  data: SeoUpdateManyLocalizationDataInput
  locale: Locale
}

export type SeoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateManyLocalizationInput>>
}

export type SeoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SeoUpdateManyInput
  /** Document search */
  where: SeoWhereInput
}

export type SeoUpdateOneInlineInput = {
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>
  /** Delete currently connected Seo document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Seo document */
  update?: InputMaybe<SeoUpdateWithNestedWhereUniqueInput>
  /** Upsert single Seo document */
  upsert?: InputMaybe<SeoUpsertWithNestedWhereUniqueInput>
}

export type SeoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SeoUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SeoWhereUniqueInput
}

export type SeoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SeoUpdateInput
  /** Unique document search */
  where: SeoWhereUniqueInput
}

export type SeoUpsertInput = {
  /** Create document if it didn't exist */
  create: SeoCreateInput
  /** Update document if it exists */
  update: SeoUpdateInput
}

export type SeoUpsertLocalizationInput = {
  create: SeoCreateLocalizationDataInput
  locale: Locale
  update: SeoUpdateLocalizationDataInput
}

export type SeoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SeoUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SeoWhereUniqueInput
}

export type SeoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SeoUpsertInput
  /** Unique document search */
  where: SeoWhereUniqueInput
}

/** Identifies documents */
export type SeoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  keywords?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains *all* items provided to the filter */
  keywords_contains_all?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contain any of the items provided to the filter */
  keywords_contains_none?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array contains at least one item provided to the filter */
  keywords_contains_some?: InputMaybe<Array<Scalars['String']['input']>>
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  keywords_not?: InputMaybe<Array<Scalars['String']['input']>>
  noFollow?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noFollow_not?: InputMaybe<Scalars['Boolean']['input']>
  noIndex?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  noIndex_not?: InputMaybe<Scalars['Boolean']['input']>
  ogDescription?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ogDescription_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ogDescription_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ogDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ogDescription_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ogDescription_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ogDescription_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ogDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ogDescription_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ogDescription_starts_with?: InputMaybe<Scalars['String']['input']>
  ogImage?: InputMaybe<AssetWhereInput>
  ogTitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ogTitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ogTitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ogTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ogTitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ogTitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ogTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ogTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ogTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ogTitle_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References Seo record uniquely */
export type SeoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Slide = Entity & {
  __typename?: 'Slide'
  ctaText?: Maybe<Scalars['String']['output']>
  ctaUrl?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  heading?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  image: Asset
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<Slide>
  /** System stage field */
  stage: Stage
}

export type SlideImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SlideLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SlideConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SlideWhereUniqueInput
}

/** A connection to a list of items. */
export type SlideConnection = {
  __typename?: 'SlideConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SlideEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SlideCreateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** heading input for default locale (de) */
  heading?: InputMaybe<Scalars['String']['input']>
  image: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SlideCreateLocalizationsInput>
}

export type SlideCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  heading?: InputMaybe<Scalars['String']['input']>
}

export type SlideCreateLocalizationInput = {
  /** Localization input */
  data: SlideCreateLocalizationDataInput
  locale: Locale
}

export type SlideCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SlideCreateLocalizationInput>>
}

export type SlideCreateManyInlineInput = {
  /** Create and connect multiple existing Slide documents */
  create?: InputMaybe<Array<SlideCreateInput>>
}

export type SlideCreateOneInlineInput = {
  /** Create and connect one Slide document */
  create?: InputMaybe<SlideCreateInput>
}

export type SlideCreateWithPositionInput = {
  /** Document to create */
  data: SlideCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SlideEdge = {
  __typename?: 'SlideEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Slide
}

/** Identifies documents */
export type SlideManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SlideWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SlideWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SlideWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
}

export enum SlideOrderByInput {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SlideParent = ImageSlider | PreviewCardList

export type SlideParentConnectInput = {
  ImageSlider?: InputMaybe<ImageSliderConnectInput>
  PreviewCardList?: InputMaybe<PreviewCardListConnectInput>
}

export type SlideParentCreateInput = {
  ImageSlider?: InputMaybe<ImageSliderCreateInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateInput>
}

export type SlideParentCreateManyInlineInput = {
  /** Create and connect multiple existing SlideParent documents */
  create?: InputMaybe<Array<SlideParentCreateInput>>
}

export type SlideParentCreateOneInlineInput = {
  /** Create and connect one SlideParent document */
  create?: InputMaybe<SlideParentCreateInput>
}

export type SlideParentCreateWithPositionInput = {
  ImageSlider?: InputMaybe<ImageSliderCreateWithPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListCreateWithPositionInput>
}

export type SlideParentUpdateInput = {
  ImageSlider?: InputMaybe<ImageSliderUpdateInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateInput>
}

export type SlideParentUpdateManyInlineInput = {
  /** Create and connect multiple SlideParent component instances */
  create?: InputMaybe<Array<SlideParentCreateWithPositionInput>>
  /** Delete multiple SlideParent documents */
  delete?: InputMaybe<Array<SlideParentWhereUniqueInput>>
  /** Update multiple SlideParent component instances */
  update?: InputMaybe<Array<SlideParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SlideParent component instances */
  upsert?: InputMaybe<Array<SlideParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SlideParentUpdateManyWithNestedWhereInput = {
  ImageSlider?: InputMaybe<ImageSliderUpdateManyWithNestedWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateManyWithNestedWhereInput>
}

export type SlideParentUpdateOneInlineInput = {
  /** Create and connect one SlideParent document */
  create?: InputMaybe<SlideParentCreateInput>
  /** Delete currently connected SlideParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SlideParent document */
  update?: InputMaybe<SlideParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SlideParent document */
  upsert?: InputMaybe<SlideParentUpsertWithNestedWhereUniqueInput>
}

export type SlideParentUpdateWithNestedWhereUniqueAndPositionInput = {
  ImageSlider?: InputMaybe<ImageSliderUpdateWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueAndPositionInput>
}

export type SlideParentUpdateWithNestedWhereUniqueInput = {
  ImageSlider?: InputMaybe<ImageSliderUpdateWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpdateWithNestedWhereUniqueInput>
}

export type SlideParentUpsertWithNestedWhereUniqueAndPositionInput = {
  ImageSlider?: InputMaybe<ImageSliderUpsertWithNestedWhereUniqueAndPositionInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueAndPositionInput>
}

export type SlideParentUpsertWithNestedWhereUniqueInput = {
  ImageSlider?: InputMaybe<ImageSliderUpsertWithNestedWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListUpsertWithNestedWhereUniqueInput>
}

export type SlideParentWhereInput = {
  ImageSlider?: InputMaybe<ImageSliderWhereInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereInput>
}

export type SlideParentWhereUniqueInput = {
  ImageSlider?: InputMaybe<ImageSliderWhereUniqueInput>
  PreviewCardList?: InputMaybe<PreviewCardListWhereUniqueInput>
}

export type SlideUpdateInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** heading input for default locale (de) */
  heading?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<SlideUpdateLocalizationsInput>
}

export type SlideUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  heading?: InputMaybe<Scalars['String']['input']>
}

export type SlideUpdateLocalizationInput = {
  data: SlideUpdateLocalizationDataInput
  locale: Locale
}

export type SlideUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SlideCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SlideUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SlideUpsertLocalizationInput>>
}

export type SlideUpdateManyInlineInput = {
  /** Create and connect multiple Slide component instances */
  create?: InputMaybe<Array<SlideCreateWithPositionInput>>
  /** Delete multiple Slide documents */
  delete?: InputMaybe<Array<SlideWhereUniqueInput>>
  /** Update multiple Slide component instances */
  update?: InputMaybe<Array<SlideUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Slide component instances */
  upsert?: InputMaybe<Array<SlideUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SlideUpdateManyInput = {
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** heading input for default locale (de) */
  heading?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SlideUpdateManyLocalizationsInput>
}

export type SlideUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  heading?: InputMaybe<Scalars['String']['input']>
}

export type SlideUpdateManyLocalizationInput = {
  data: SlideUpdateManyLocalizationDataInput
  locale: Locale
}

export type SlideUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SlideUpdateManyLocalizationInput>>
}

export type SlideUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SlideUpdateManyInput
  /** Document search */
  where: SlideWhereInput
}

export type SlideUpdateOneInlineInput = {
  /** Create and connect one Slide document */
  create?: InputMaybe<SlideCreateInput>
  /** Delete currently connected Slide document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Slide document */
  update?: InputMaybe<SlideUpdateWithNestedWhereUniqueInput>
  /** Upsert single Slide document */
  upsert?: InputMaybe<SlideUpsertWithNestedWhereUniqueInput>
}

export type SlideUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SlideUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SlideWhereUniqueInput
}

export type SlideUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SlideUpdateInput
  /** Unique document search */
  where: SlideWhereUniqueInput
}

export type SlideUpsertInput = {
  /** Create document if it didn't exist */
  create: SlideCreateInput
  /** Update document if it exists */
  update: SlideUpdateInput
}

export type SlideUpsertLocalizationInput = {
  create: SlideCreateLocalizationDataInput
  locale: Locale
  update: SlideUpdateLocalizationDataInput
}

export type SlideUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SlideUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SlideWhereUniqueInput
}

export type SlideUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SlideUpsertInput
  /** Unique document search */
  where: SlideWhereUniqueInput
}

/** Identifies documents */
export type SlideWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SlideWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SlideWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SlideWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  heading?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
}

/** References Slide record uniquely */
export type SlideWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum SliderLayout {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

/** Social media related links */
export type Social = Entity & {
  __typename?: 'Social'
  facebookUrl?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  instagramUrl?: Maybe<Scalars['String']['output']>
  linkedInUrl?: Maybe<Scalars['String']['output']>
  newsletterUrl?: Maybe<Scalars['String']['output']>
  /** System stage field */
  stage: Stage
  tikTokUrl?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  youTubeUrl?: Maybe<Scalars['String']['output']>
}

export type SocialConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SocialWhereUniqueInput
}

/** A connection to a list of items. */
export type SocialConnection = {
  __typename?: 'SocialConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SocialEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SocialCreateInput = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  linkedInUrl?: InputMaybe<Scalars['String']['input']>
  newsletterUrl?: InputMaybe<Scalars['String']['input']>
  tikTokUrl?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  youTubeUrl?: InputMaybe<Scalars['String']['input']>
}

export type SocialCreateManyInlineInput = {
  /** Create and connect multiple existing Social documents */
  create?: InputMaybe<Array<SocialCreateInput>>
}

export type SocialCreateOneInlineInput = {
  /** Create and connect one Social document */
  create?: InputMaybe<SocialCreateInput>
}

export type SocialCreateWithPositionInput = {
  /** Document to create */
  data: SocialCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SocialEdge = {
  __typename?: 'SocialEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Social
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbed = Entity &
  Node & {
    __typename?: 'SocialEmbed'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<SocialEmbed>
    /** List of SocialEmbed versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    provider: SocialEmbedProvider
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    url: Scalars['String']['output']
    /** Optional wordpress reference used by importer */
    wpId?: Maybe<Scalars['String']['output']>
  }

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Social embeds for Blog Content. Instagram, Podigee, Tiktok etc */
export type SocialEmbedUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SocialEmbedConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SocialEmbedWhereUniqueInput
}

/** A connection to a list of items. */
export type SocialEmbedConnection = {
  __typename?: 'SocialEmbedConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SocialEmbedEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SocialEmbedCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  provider: SocialEmbedProvider
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  url: Scalars['String']['input']
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type SocialEmbedCreateManyInlineInput = {
  /** Connect multiple existing SocialEmbed documents */
  connect?: InputMaybe<Array<SocialEmbedWhereUniqueInput>>
  /** Create and connect multiple existing SocialEmbed documents */
  create?: InputMaybe<Array<SocialEmbedCreateInput>>
}

export type SocialEmbedCreateOneInlineInput = {
  /** Connect one existing SocialEmbed document */
  connect?: InputMaybe<SocialEmbedWhereUniqueInput>
  /** Create and connect one SocialEmbed document */
  create?: InputMaybe<SocialEmbedCreateInput>
}

/** An edge in a connection. */
export type SocialEmbedEdge = {
  __typename?: 'SocialEmbedEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SocialEmbed
}

/** Identifies documents */
export type SocialEmbedManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<SocialEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<SocialEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<SocialEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  provider?: InputMaybe<SocialEmbedProvider>
  /** All values that are contained in given list. */
  provider_in?: InputMaybe<Array<InputMaybe<SocialEmbedProvider>>>
  /** Any other value that exists and is not equal to the given value. */
  provider_not?: InputMaybe<SocialEmbedProvider>
  /** All values that are not contained in given list. */
  provider_not_in?: InputMaybe<Array<InputMaybe<SocialEmbedProvider>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SocialEmbedOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ProviderAsc = 'provider_ASC',
  ProviderDesc = 'provider_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export enum SocialEmbedProvider {
  Facebook = 'Facebook',
  Iframe = 'Iframe',
  Instagram = 'Instagram',
  Podigee = 'Podigee',
  Reddit = 'Reddit',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Vimeo = 'Vimeo',
  YouTube = 'YouTube',
}

export type SocialEmbedUpdateInput = {
  provider?: InputMaybe<SocialEmbedProvider>
  title?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type SocialEmbedUpdateManyInlineInput = {
  /** Connect multiple existing SocialEmbed documents */
  connect?: InputMaybe<Array<SocialEmbedConnectInput>>
  /** Create and connect multiple SocialEmbed documents */
  create?: InputMaybe<Array<SocialEmbedCreateInput>>
  /** Delete multiple SocialEmbed documents */
  delete?: InputMaybe<Array<SocialEmbedWhereUniqueInput>>
  /** Disconnect multiple SocialEmbed documents */
  disconnect?: InputMaybe<Array<SocialEmbedWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SocialEmbed documents */
  set?: InputMaybe<Array<SocialEmbedWhereUniqueInput>>
  /** Update multiple SocialEmbed documents */
  update?: InputMaybe<Array<SocialEmbedUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SocialEmbed documents */
  upsert?: InputMaybe<Array<SocialEmbedUpsertWithNestedWhereUniqueInput>>
}

export type SocialEmbedUpdateManyInput = {
  provider?: InputMaybe<SocialEmbedProvider>
  title?: InputMaybe<Scalars['String']['input']>
  url?: InputMaybe<Scalars['String']['input']>
}

export type SocialEmbedUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SocialEmbedUpdateManyInput
  /** Document search */
  where: SocialEmbedWhereInput
}

export type SocialEmbedUpdateOneInlineInput = {
  /** Connect existing SocialEmbed document */
  connect?: InputMaybe<SocialEmbedWhereUniqueInput>
  /** Create and connect one SocialEmbed document */
  create?: InputMaybe<SocialEmbedCreateInput>
  /** Delete currently connected SocialEmbed document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SocialEmbed document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SocialEmbed document */
  update?: InputMaybe<SocialEmbedUpdateWithNestedWhereUniqueInput>
  /** Upsert single SocialEmbed document */
  upsert?: InputMaybe<SocialEmbedUpsertWithNestedWhereUniqueInput>
}

export type SocialEmbedUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SocialEmbedUpdateInput
  /** Unique document search */
  where: SocialEmbedWhereUniqueInput
}

export type SocialEmbedUpsertInput = {
  /** Create document if it didn't exist */
  create: SocialEmbedCreateInput
  /** Update document if it exists */
  update: SocialEmbedUpdateInput
}

export type SocialEmbedUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SocialEmbedUpsertInput
  /** Unique document search */
  where: SocialEmbedWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type SocialEmbedWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type SocialEmbedWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<SocialEmbedWhereStageInput>
  documentInStages_none?: InputMaybe<SocialEmbedWhereStageInput>
  documentInStages_some?: InputMaybe<SocialEmbedWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  provider?: InputMaybe<SocialEmbedProvider>
  /** All values that are contained in given list. */
  provider_in?: InputMaybe<Array<InputMaybe<SocialEmbedProvider>>>
  /** Any other value that exists and is not equal to the given value. */
  provider_not?: InputMaybe<SocialEmbedProvider>
  /** All values that are not contained in given list. */
  provider_not_in?: InputMaybe<Array<InputMaybe<SocialEmbedProvider>>>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  url?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SocialEmbedWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialEmbedWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialEmbedWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialEmbedWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SocialEmbedWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References SocialEmbed record uniquely */
export type SocialEmbedWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type SocialLink = Entity & {
  __typename?: 'SocialLink'
  /** The unique identifier */
  id: Scalars['ID']['output']
  link: Scalars['String']['output']
  logo?: Maybe<Asset>
  name: Scalars['String']['output']
  /** System stage field */
  stage: Stage
}

export type SocialLinkLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SocialLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SocialLinkWhereUniqueInput
}

/** A connection to a list of items. */
export type SocialLinkConnection = {
  __typename?: 'SocialLinkConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SocialLinkEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SocialLinkCreateInput = {
  link: Scalars['String']['input']
  logo?: InputMaybe<AssetCreateOneInlineInput>
  name: Scalars['String']['input']
}

export type SocialLinkCreateManyInlineInput = {
  /** Create and connect multiple existing SocialLink documents */
  create?: InputMaybe<Array<SocialLinkCreateInput>>
}

export type SocialLinkCreateOneInlineInput = {
  /** Create and connect one SocialLink document */
  create?: InputMaybe<SocialLinkCreateInput>
}

export type SocialLinkCreateWithPositionInput = {
  /** Document to create */
  data: SocialLinkCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SocialLinkEdge = {
  __typename?: 'SocialLinkEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SocialLink
}

/** Identifies documents */
export type SocialLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SocialLinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type SocialLinkParent = Footer | Header

export type SocialLinkParentConnectInput = {
  Footer?: InputMaybe<FooterConnectInput>
  Header?: InputMaybe<HeaderConnectInput>
}

export type SocialLinkParentCreateInput = {
  Footer?: InputMaybe<FooterCreateInput>
  Header?: InputMaybe<HeaderCreateInput>
}

export type SocialLinkParentCreateManyInlineInput = {
  /** Connect multiple existing SocialLinkParent documents */
  connect?: InputMaybe<Array<SocialLinkParentWhereUniqueInput>>
  /** Create and connect multiple existing SocialLinkParent documents */
  create?: InputMaybe<Array<SocialLinkParentCreateInput>>
}

export type SocialLinkParentCreateOneInlineInput = {
  /** Connect one existing SocialLinkParent document */
  connect?: InputMaybe<SocialLinkParentWhereUniqueInput>
  /** Create and connect one SocialLinkParent document */
  create?: InputMaybe<SocialLinkParentCreateInput>
}

export type SocialLinkParentUpdateInput = {
  Footer?: InputMaybe<FooterUpdateInput>
  Header?: InputMaybe<HeaderUpdateInput>
}

export type SocialLinkParentUpdateManyInlineInput = {
  /** Connect multiple existing SocialLinkParent documents */
  connect?: InputMaybe<Array<SocialLinkParentConnectInput>>
  /** Create and connect multiple SocialLinkParent documents */
  create?: InputMaybe<Array<SocialLinkParentCreateInput>>
  /** Delete multiple SocialLinkParent documents */
  delete?: InputMaybe<Array<SocialLinkParentWhereUniqueInput>>
  /** Disconnect multiple SocialLinkParent documents */
  disconnect?: InputMaybe<Array<SocialLinkParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SocialLinkParent documents */
  set?: InputMaybe<Array<SocialLinkParentWhereUniqueInput>>
  /** Update multiple SocialLinkParent documents */
  update?: InputMaybe<Array<SocialLinkParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SocialLinkParent documents */
  upsert?: InputMaybe<Array<SocialLinkParentUpsertWithNestedWhereUniqueInput>>
}

export type SocialLinkParentUpdateManyWithNestedWhereInput = {
  Footer?: InputMaybe<FooterUpdateManyWithNestedWhereInput>
  Header?: InputMaybe<HeaderUpdateManyWithNestedWhereInput>
}

export type SocialLinkParentUpdateOneInlineInput = {
  /** Connect existing SocialLinkParent document */
  connect?: InputMaybe<SocialLinkParentWhereUniqueInput>
  /** Create and connect one SocialLinkParent document */
  create?: InputMaybe<SocialLinkParentCreateInput>
  /** Delete currently connected SocialLinkParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SocialLinkParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SocialLinkParent document */
  update?: InputMaybe<SocialLinkParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SocialLinkParent document */
  upsert?: InputMaybe<SocialLinkParentUpsertWithNestedWhereUniqueInput>
}

export type SocialLinkParentUpdateWithNestedWhereUniqueInput = {
  Footer?: InputMaybe<FooterUpdateWithNestedWhereUniqueInput>
  Header?: InputMaybe<HeaderUpdateWithNestedWhereUniqueInput>
}

export type SocialLinkParentUpsertWithNestedWhereUniqueInput = {
  Footer?: InputMaybe<FooterUpsertWithNestedWhereUniqueInput>
  Header?: InputMaybe<HeaderUpsertWithNestedWhereUniqueInput>
}

export type SocialLinkParentWhereInput = {
  Footer?: InputMaybe<FooterWhereInput>
  Header?: InputMaybe<HeaderWhereInput>
}

export type SocialLinkParentWhereUniqueInput = {
  Footer?: InputMaybe<FooterWhereUniqueInput>
  Header?: InputMaybe<HeaderWhereUniqueInput>
}

export type SocialLinkUpdateInput = {
  link?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetUpdateOneInlineInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type SocialLinkUpdateManyInlineInput = {
  /** Create and connect multiple SocialLink component instances */
  create?: InputMaybe<Array<SocialLinkCreateWithPositionInput>>
  /** Delete multiple SocialLink documents */
  delete?: InputMaybe<Array<SocialLinkWhereUniqueInput>>
  /** Update multiple SocialLink component instances */
  update?: InputMaybe<Array<SocialLinkUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SocialLink component instances */
  upsert?: InputMaybe<Array<SocialLinkUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SocialLinkUpdateManyInput = {
  link?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type SocialLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SocialLinkUpdateManyInput
  /** Document search */
  where: SocialLinkWhereInput
}

export type SocialLinkUpdateOneInlineInput = {
  /** Create and connect one SocialLink document */
  create?: InputMaybe<SocialLinkCreateInput>
  /** Delete currently connected SocialLink document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SocialLink document */
  update?: InputMaybe<SocialLinkUpdateWithNestedWhereUniqueInput>
  /** Upsert single SocialLink document */
  upsert?: InputMaybe<SocialLinkUpsertWithNestedWhereUniqueInput>
}

export type SocialLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SocialLinkUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SocialLinkWhereUniqueInput
}

export type SocialLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SocialLinkUpdateInput
  /** Unique document search */
  where: SocialLinkWhereUniqueInput
}

export type SocialLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: SocialLinkCreateInput
  /** Update document if it exists */
  update: SocialLinkUpdateInput
}

export type SocialLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SocialLinkUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SocialLinkWhereUniqueInput
}

export type SocialLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SocialLinkUpsertInput
  /** Unique document search */
  where: SocialLinkWhereUniqueInput
}

/** Identifies documents */
export type SocialLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialLinkWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  link?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<AssetWhereInput>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SocialLink record uniquely */
export type SocialLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Identifies documents */
export type SocialManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebookUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebookUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebookUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebookUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebookUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebookUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebookUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebookUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebookUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagramUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagramUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagramUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagramUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagramUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagramUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagramUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagramUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagramUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedInUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedInUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedInUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedInUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedInUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedInUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedInUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedInUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedInUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedInUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  newsletterUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  newsletterUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  newsletterUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  newsletterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  newsletterUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  newsletterUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  newsletterUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  newsletterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  newsletterUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  newsletterUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  tikTokUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tikTokUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tikTokUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tikTokUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tikTokUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tikTokUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tikTokUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tikTokUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tikTokUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tikTokUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitterUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitterUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitterUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitterUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitterUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitterUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitterUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  youTubeUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youTubeUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youTubeUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youTubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youTubeUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youTubeUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youTubeUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youTubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youTubeUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youTubeUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SocialOrderByInput {
  FacebookUrlAsc = 'facebookUrl_ASC',
  FacebookUrlDesc = 'facebookUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InstagramUrlAsc = 'instagramUrl_ASC',
  InstagramUrlDesc = 'instagramUrl_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  NewsletterUrlAsc = 'newsletterUrl_ASC',
  NewsletterUrlDesc = 'newsletterUrl_DESC',
  TikTokUrlAsc = 'tikTokUrl_ASC',
  TikTokUrlDesc = 'tikTokUrl_DESC',
  TwitterUrlAsc = 'twitterUrl_ASC',
  TwitterUrlDesc = 'twitterUrl_DESC',
  YouTubeUrlAsc = 'youTubeUrl_ASC',
  YouTubeUrlDesc = 'youTubeUrl_DESC',
}

export type SocialParent = PodcastGroup

export type SocialParentConnectInput = {
  PodcastGroup?: InputMaybe<PodcastGroupConnectInput>
}

export type SocialParentCreateInput = {
  PodcastGroup?: InputMaybe<PodcastGroupCreateInput>
}

export type SocialParentCreateManyInlineInput = {
  /** Connect multiple existing SocialParent documents */
  connect?: InputMaybe<Array<SocialParentWhereUniqueInput>>
  /** Create and connect multiple existing SocialParent documents */
  create?: InputMaybe<Array<SocialParentCreateInput>>
}

export type SocialParentCreateOneInlineInput = {
  /** Connect one existing SocialParent document */
  connect?: InputMaybe<SocialParentWhereUniqueInput>
  /** Create and connect one SocialParent document */
  create?: InputMaybe<SocialParentCreateInput>
}

export type SocialParentUpdateInput = {
  PodcastGroup?: InputMaybe<PodcastGroupUpdateInput>
}

export type SocialParentUpdateManyInlineInput = {
  /** Connect multiple existing SocialParent documents */
  connect?: InputMaybe<Array<SocialParentConnectInput>>
  /** Create and connect multiple SocialParent documents */
  create?: InputMaybe<Array<SocialParentCreateInput>>
  /** Delete multiple SocialParent documents */
  delete?: InputMaybe<Array<SocialParentWhereUniqueInput>>
  /** Disconnect multiple SocialParent documents */
  disconnect?: InputMaybe<Array<SocialParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SocialParent documents */
  set?: InputMaybe<Array<SocialParentWhereUniqueInput>>
  /** Update multiple SocialParent documents */
  update?: InputMaybe<Array<SocialParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SocialParent documents */
  upsert?: InputMaybe<Array<SocialParentUpsertWithNestedWhereUniqueInput>>
}

export type SocialParentUpdateManyWithNestedWhereInput = {
  PodcastGroup?: InputMaybe<PodcastGroupUpdateManyWithNestedWhereInput>
}

export type SocialParentUpdateOneInlineInput = {
  /** Connect existing SocialParent document */
  connect?: InputMaybe<SocialParentWhereUniqueInput>
  /** Create and connect one SocialParent document */
  create?: InputMaybe<SocialParentCreateInput>
  /** Delete currently connected SocialParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SocialParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SocialParent document */
  update?: InputMaybe<SocialParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SocialParent document */
  upsert?: InputMaybe<SocialParentUpsertWithNestedWhereUniqueInput>
}

export type SocialParentUpdateWithNestedWhereUniqueInput = {
  PodcastGroup?: InputMaybe<PodcastGroupUpdateWithNestedWhereUniqueInput>
}

export type SocialParentUpsertWithNestedWhereUniqueInput = {
  PodcastGroup?: InputMaybe<PodcastGroupUpsertWithNestedWhereUniqueInput>
}

export type SocialParentWhereInput = {
  PodcastGroup?: InputMaybe<PodcastGroupWhereInput>
}

export type SocialParentWhereUniqueInput = {
  PodcastGroup?: InputMaybe<PodcastGroupWhereUniqueInput>
}

export type SocialUpdateInput = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  linkedInUrl?: InputMaybe<Scalars['String']['input']>
  newsletterUrl?: InputMaybe<Scalars['String']['input']>
  tikTokUrl?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  youTubeUrl?: InputMaybe<Scalars['String']['input']>
}

export type SocialUpdateManyInlineInput = {
  /** Create and connect multiple Social component instances */
  create?: InputMaybe<Array<SocialCreateWithPositionInput>>
  /** Delete multiple Social documents */
  delete?: InputMaybe<Array<SocialWhereUniqueInput>>
  /** Update multiple Social component instances */
  update?: InputMaybe<Array<SocialUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple Social component instances */
  upsert?: InputMaybe<Array<SocialUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SocialUpdateManyInput = {
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  linkedInUrl?: InputMaybe<Scalars['String']['input']>
  newsletterUrl?: InputMaybe<Scalars['String']['input']>
  tikTokUrl?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  youTubeUrl?: InputMaybe<Scalars['String']['input']>
}

export type SocialUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SocialUpdateManyInput
  /** Document search */
  where: SocialWhereInput
}

export type SocialUpdateOneInlineInput = {
  /** Create and connect one Social document */
  create?: InputMaybe<SocialCreateInput>
  /** Delete currently connected Social document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Social document */
  update?: InputMaybe<SocialUpdateWithNestedWhereUniqueInput>
  /** Upsert single Social document */
  upsert?: InputMaybe<SocialUpsertWithNestedWhereUniqueInput>
}

export type SocialUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SocialUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SocialWhereUniqueInput
}

export type SocialUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SocialUpdateInput
  /** Unique document search */
  where: SocialWhereUniqueInput
}

export type SocialUpsertInput = {
  /** Create document if it didn't exist */
  create: SocialCreateInput
  /** Update document if it exists */
  update: SocialUpdateInput
}

export type SocialUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SocialUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SocialWhereUniqueInput
}

export type SocialUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SocialUpsertInput
  /** Unique document search */
  where: SocialWhereUniqueInput
}

/** Identifies documents */
export type SocialWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebookUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebookUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebookUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebookUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebookUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebookUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebookUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebookUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebookUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagramUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagramUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagramUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagramUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagramUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagramUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagramUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagramUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagramUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedInUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedInUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedInUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedInUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedInUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedInUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedInUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedInUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedInUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedInUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  newsletterUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  newsletterUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  newsletterUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  newsletterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  newsletterUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  newsletterUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  newsletterUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  newsletterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  newsletterUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  newsletterUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  tikTokUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tikTokUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tikTokUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tikTokUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tikTokUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tikTokUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tikTokUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tikTokUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tikTokUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tikTokUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitterUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitterUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitterUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitterUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitterUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitterUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitterUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitterUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitterUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  youTubeUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youTubeUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youTubeUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youTubeUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youTubeUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youTubeUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youTubeUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youTubeUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youTubeUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youTubeUrl_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References Social record uniquely */
export type SocialWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Speaker = Entity &
  Node & {
    __typename?: 'Speaker'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<Speaker>
    eventsAsArtist: Array<Event>
    eventsAsSpeaker: Array<Event>
    facebook?: Maybe<Scalars['String']['output']>
    firstname?: Maybe<Scalars['String']['output']>
    /** List of Speaker versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    instagram?: Maybe<Scalars['String']['output']>
    interests: Array<Interest>
    isTop?: Maybe<Scalars['Boolean']['output']>
    lastname?: Maybe<Scalars['String']['output']>
    linkedin?: Maybe<Scalars['String']['output']>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Speaker>
    name?: Maybe<Scalars['String']['output']>
    partnerCompany?: Maybe<PartnerCompany>
    partnerCompanyName?: Maybe<Scalars['String']['output']>
    photoUrl?: Maybe<Scalars['String']['output']>
    position?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    tiktok?: Maybe<Scalars['String']['output']>
    title?: Maybe<Scalars['String']['output']>
    twitter?: Maybe<Scalars['String']['output']>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    website?: Maybe<Scalars['String']['output']>
    youtube?: Maybe<Scalars['String']['output']>
  }

export type SpeakerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type SpeakerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SpeakerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type SpeakerEventsAsArtistArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

export type SpeakerEventsAsSpeakerArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<EventOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<EventWhereInput>
}

export type SpeakerHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type SpeakerInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<InterestOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<InterestWhereInput>
}

export type SpeakerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SpeakerPartnerCompanyArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SpeakerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type SpeakerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SpeakerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type SpeakerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type SpeakerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SpeakerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SpeakerWhereUniqueInput
}

/** A connection to a list of items. */
export type SpeakerConnection = {
  __typename?: 'SpeakerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SpeakerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SpeakerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  curatedSpeakers?: InputMaybe<SectionCuratedSpeakerListCreateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  eventsAsArtist?: InputMaybe<EventCreateManyInlineInput>
  eventsAsSpeaker?: InputMaybe<EventCreateManyInlineInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<InterestCreateManyInlineInput>
  isTop?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SpeakerCreateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyCreateOneInlineInput>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  photoUrl?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  slug: Scalars['String']['input']
  speakerOrder?: InputMaybe<SpeakerListOrderCreateManyInlineInput>
  speakerSection?: InputMaybe<SectionStagePreviewCreateOneInlineInput>
  tiktok?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SpeakerCreateLocalizationInput = {
  /** Localization input */
  data: SpeakerCreateLocalizationDataInput
  locale: Locale
}

export type SpeakerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SpeakerCreateLocalizationInput>>
}

export type SpeakerCreateManyInlineInput = {
  /** Connect multiple existing Speaker documents */
  connect?: InputMaybe<Array<SpeakerWhereUniqueInput>>
  /** Create and connect multiple existing Speaker documents */
  create?: InputMaybe<Array<SpeakerCreateInput>>
}

export type SpeakerCreateOneInlineInput = {
  /** Connect one existing Speaker document */
  connect?: InputMaybe<SpeakerWhereUniqueInput>
  /** Create and connect one Speaker document */
  create?: InputMaybe<SpeakerCreateInput>
}

/** An edge in a connection. */
export type SpeakerEdge = {
  __typename?: 'SpeakerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Speaker
}

export enum SpeakerEventTypes {
  GuidedTour = 'guidedTour',
  Masterclass = 'masterclass',
  SideEvent = 'sideEvent',
  Stage = 'stage',
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrder = Entity &
  Node & {
    __typename?: 'SpeakerListOrder'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<SpeakerListOrder>
    event: Event
    /** List of SpeakerListOrder versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** The top speakers or artist list. */
    speakers: Array<Speaker>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderEventArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderSpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SpeakerOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SpeakerWhereInput>
}

/** Model to hold ordering information used by myomr sorting. This order will be reflected eg. on speakers pages or in the mobile app. */
export type SpeakerListOrderUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type SpeakerListOrderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SpeakerListOrderWhereUniqueInput
}

/** A connection to a list of items. */
export type SpeakerListOrderConnection = {
  __typename?: 'SpeakerListOrderConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SpeakerListOrderEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SpeakerListOrderCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event: EventCreateOneInlineInput
  sectionsMyomrSpeakers?: InputMaybe<SectionSpeakersCreateManyInlineInput>
  slug: Scalars['String']['input']
  speakers: SpeakerCreateManyInlineInput
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SpeakerListOrderCreateManyInlineInput = {
  /** Connect multiple existing SpeakerListOrder documents */
  connect?: InputMaybe<Array<SpeakerListOrderWhereUniqueInput>>
  /** Create and connect multiple existing SpeakerListOrder documents */
  create?: InputMaybe<Array<SpeakerListOrderCreateInput>>
}

export type SpeakerListOrderCreateOneInlineInput = {
  /** Connect one existing SpeakerListOrder document */
  connect?: InputMaybe<SpeakerListOrderWhereUniqueInput>
  /** Create and connect one SpeakerListOrder document */
  create?: InputMaybe<SpeakerListOrderCreateInput>
}

/** An edge in a connection. */
export type SpeakerListOrderEdge = {
  __typename?: 'SpeakerListOrderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SpeakerListOrder
}

/** Identifies documents */
export type SpeakerListOrderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<SpeakerListOrderWhereStageInput>
  documentInStages_none?: InputMaybe<SpeakerListOrderWhereStageInput>
  documentInStages_some?: InputMaybe<SpeakerListOrderWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum SpeakerListOrderOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type SpeakerListOrderUpdateInput = {
  event?: InputMaybe<EventUpdateOneInlineInput>
  sectionsMyomrSpeakers?: InputMaybe<SectionSpeakersUpdateManyInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  speakers?: InputMaybe<SpeakerUpdateManyInlineInput>
}

export type SpeakerListOrderUpdateManyInlineInput = {
  /** Connect multiple existing SpeakerListOrder documents */
  connect?: InputMaybe<Array<SpeakerListOrderConnectInput>>
  /** Create and connect multiple SpeakerListOrder documents */
  create?: InputMaybe<Array<SpeakerListOrderCreateInput>>
  /** Delete multiple SpeakerListOrder documents */
  delete?: InputMaybe<Array<SpeakerListOrderWhereUniqueInput>>
  /** Disconnect multiple SpeakerListOrder documents */
  disconnect?: InputMaybe<Array<SpeakerListOrderWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SpeakerListOrder documents */
  set?: InputMaybe<Array<SpeakerListOrderWhereUniqueInput>>
  /** Update multiple SpeakerListOrder documents */
  update?: InputMaybe<Array<SpeakerListOrderUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SpeakerListOrder documents */
  upsert?: InputMaybe<Array<SpeakerListOrderUpsertWithNestedWhereUniqueInput>>
}

export type SpeakerListOrderUpdateManyInput = {
  slug?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerListOrderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SpeakerListOrderUpdateManyInput
  /** Document search */
  where: SpeakerListOrderWhereInput
}

export type SpeakerListOrderUpdateOneInlineInput = {
  /** Connect existing SpeakerListOrder document */
  connect?: InputMaybe<SpeakerListOrderWhereUniqueInput>
  /** Create and connect one SpeakerListOrder document */
  create?: InputMaybe<SpeakerListOrderCreateInput>
  /** Delete currently connected SpeakerListOrder document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SpeakerListOrder document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SpeakerListOrder document */
  update?: InputMaybe<SpeakerListOrderUpdateWithNestedWhereUniqueInput>
  /** Upsert single SpeakerListOrder document */
  upsert?: InputMaybe<SpeakerListOrderUpsertWithNestedWhereUniqueInput>
}

export type SpeakerListOrderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SpeakerListOrderUpdateInput
  /** Unique document search */
  where: SpeakerListOrderWhereUniqueInput
}

export type SpeakerListOrderUpsertInput = {
  /** Create document if it didn't exist */
  create: SpeakerListOrderCreateInput
  /** Update document if it exists */
  update: SpeakerListOrderUpdateInput
}

export type SpeakerListOrderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SpeakerListOrderUpsertInput
  /** Unique document search */
  where: SpeakerListOrderWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type SpeakerListOrderWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type SpeakerListOrderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerListOrderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<SpeakerListOrderWhereStageInput>
  documentInStages_none?: InputMaybe<SpeakerListOrderWhereStageInput>
  documentInStages_some?: InputMaybe<SpeakerListOrderWhereStageInput>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  speakers_every?: InputMaybe<SpeakerWhereInput>
  speakers_none?: InputMaybe<SpeakerWhereInput>
  speakers_some?: InputMaybe<SpeakerWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SpeakerListOrderWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerListOrderWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerListOrderWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerListOrderWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SpeakerListOrderWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References SpeakerListOrder record uniquely */
export type SpeakerListOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Identifies documents */
export type SpeakerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<SpeakerWhereStageInput>
  documentInStages_none?: InputMaybe<SpeakerWhereStageInput>
  documentInStages_some?: InputMaybe<SpeakerWhereStageInput>
  eventsAsArtist_every?: InputMaybe<EventWhereInput>
  eventsAsArtist_none?: InputMaybe<EventWhereInput>
  eventsAsArtist_some?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_every?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_none?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_some?: InputMaybe<EventWhereInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebook_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebook_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebook_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebook_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebook_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebook_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebook_starts_with?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  firstname_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  firstname_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  firstname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  firstname_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  firstname_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  firstname_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  firstname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  firstname_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  firstname_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagram_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagram_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagram_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagram_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagram_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagram_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagram_starts_with?: InputMaybe<Scalars['String']['input']>
  interests_every?: InputMaybe<InterestWhereInput>
  interests_none?: InputMaybe<InterestWhereInput>
  interests_some?: InputMaybe<InterestWhereInput>
  isTop?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isTop_not?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  lastname_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  lastname_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  lastname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  lastname_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  lastname_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  lastname_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  lastname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  lastname_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  lastname_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedin_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedin_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedin_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedin_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedin_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedin_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedin_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  partnerCompanyName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  partnerCompanyName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  partnerCompanyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  partnerCompanyName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  partnerCompanyName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  partnerCompanyName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  partnerCompanyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  partnerCompanyName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  partnerCompanyName_starts_with?: InputMaybe<Scalars['String']['input']>
  photoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  photoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  photoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  photoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  photoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  photoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  photoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  photoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  photoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  photoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  position_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  position_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  position_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  position_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tiktok?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tiktok_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tiktok_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tiktok_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tiktok_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tiktok_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tiktok_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tiktok_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tiktok_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tiktok_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitter_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitter_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitter_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitter_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitter_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitter_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitter_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youtube_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youtube_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youtube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youtube_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youtube_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youtube_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youtube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youtube_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youtube_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum SpeakerOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FacebookAsc = 'facebook_ASC',
  FacebookDesc = 'facebook_DESC',
  FirstnameAsc = 'firstname_ASC',
  FirstnameDesc = 'firstname_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InstagramAsc = 'instagram_ASC',
  InstagramDesc = 'instagram_DESC',
  IsTopAsc = 'isTop_ASC',
  IsTopDesc = 'isTop_DESC',
  LastnameAsc = 'lastname_ASC',
  LastnameDesc = 'lastname_DESC',
  LinkedinAsc = 'linkedin_ASC',
  LinkedinDesc = 'linkedin_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PartnerCompanyNameAsc = 'partnerCompanyName_ASC',
  PartnerCompanyNameDesc = 'partnerCompanyName_DESC',
  PhotoUrlAsc = 'photoUrl_ASC',
  PhotoUrlDesc = 'photoUrl_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TiktokAsc = 'tiktok_ASC',
  TiktokDesc = 'tiktok_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TwitterAsc = 'twitter_ASC',
  TwitterDesc = 'twitter_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WebsiteAsc = 'website_ASC',
  WebsiteDesc = 'website_DESC',
  YoutubeAsc = 'youtube_ASC',
  YoutubeDesc = 'youtube_DESC',
}

/** Speaker/Artist displays this type with any manually added sorted to the top. Custom displays a sorted list and ONLY this list. */
export enum SpeakerRole {
  Artist = 'Artist',
  Speaker = 'Speaker',
  Custom = 'custom',
}

export type SpeakerUpdateInput = {
  curatedSpeakers?: InputMaybe<SectionCuratedSpeakerListUpdateManyInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  eventsAsArtist?: InputMaybe<EventUpdateManyInlineInput>
  eventsAsSpeaker?: InputMaybe<EventUpdateManyInlineInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<InterestUpdateManyInlineInput>
  isTop?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SpeakerUpdateLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyUpdateOneInlineInput>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  photoUrl?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  speakerOrder?: InputMaybe<SpeakerListOrderUpdateManyInlineInput>
  speakerSection?: InputMaybe<SectionStagePreviewUpdateOneInlineInput>
  tiktok?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerUpdateLocalizationInput = {
  data: SpeakerUpdateLocalizationDataInput
  locale: Locale
}

export type SpeakerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SpeakerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SpeakerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SpeakerUpsertLocalizationInput>>
}

export type SpeakerUpdateManyInlineInput = {
  /** Connect multiple existing Speaker documents */
  connect?: InputMaybe<Array<SpeakerConnectInput>>
  /** Create and connect multiple Speaker documents */
  create?: InputMaybe<Array<SpeakerCreateInput>>
  /** Delete multiple Speaker documents */
  delete?: InputMaybe<Array<SpeakerWhereUniqueInput>>
  /** Disconnect multiple Speaker documents */
  disconnect?: InputMaybe<Array<SpeakerWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Speaker documents */
  set?: InputMaybe<Array<SpeakerWhereUniqueInput>>
  /** Update multiple Speaker documents */
  update?: InputMaybe<Array<SpeakerUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Speaker documents */
  upsert?: InputMaybe<Array<SpeakerUpsertWithNestedWhereUniqueInput>>
}

export type SpeakerUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  isTop?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SpeakerUpdateManyLocalizationsInput>
  name?: InputMaybe<Scalars['String']['input']>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  photoUrl?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  tiktok?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
}

export type SpeakerUpdateManyLocalizationInput = {
  data: SpeakerUpdateManyLocalizationDataInput
  locale: Locale
}

export type SpeakerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SpeakerUpdateManyLocalizationInput>>
}

export type SpeakerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SpeakerUpdateManyInput
  /** Document search */
  where: SpeakerWhereInput
}

export type SpeakerUpdateOneInlineInput = {
  /** Connect existing Speaker document */
  connect?: InputMaybe<SpeakerWhereUniqueInput>
  /** Create and connect one Speaker document */
  create?: InputMaybe<SpeakerCreateInput>
  /** Delete currently connected Speaker document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Speaker document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Speaker document */
  update?: InputMaybe<SpeakerUpdateWithNestedWhereUniqueInput>
  /** Upsert single Speaker document */
  upsert?: InputMaybe<SpeakerUpsertWithNestedWhereUniqueInput>
}

export type SpeakerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SpeakerUpdateInput
  /** Unique document search */
  where: SpeakerWhereUniqueInput
}

export type SpeakerUpsertInput = {
  /** Create document if it didn't exist */
  create: SpeakerCreateInput
  /** Update document if it exists */
  update: SpeakerUpdateInput
}

export type SpeakerUpsertLocalizationInput = {
  create: SpeakerCreateLocalizationDataInput
  locale: Locale
  update: SpeakerUpdateLocalizationDataInput
}

export type SpeakerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SpeakerUpsertInput
  /** Unique document search */
  where: SpeakerWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type SpeakerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type SpeakerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<SpeakerWhereStageInput>
  documentInStages_none?: InputMaybe<SpeakerWhereStageInput>
  documentInStages_some?: InputMaybe<SpeakerWhereStageInput>
  eventsAsArtist_every?: InputMaybe<EventWhereInput>
  eventsAsArtist_none?: InputMaybe<EventWhereInput>
  eventsAsArtist_some?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_every?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_none?: InputMaybe<EventWhereInput>
  eventsAsSpeaker_some?: InputMaybe<EventWhereInput>
  facebook?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  facebook_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  facebook_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  facebook_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  facebook_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  facebook_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  facebook_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  facebook_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  facebook_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  facebook_starts_with?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  firstname_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  firstname_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  firstname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  firstname_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  firstname_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  firstname_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  firstname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  firstname_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  firstname_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  instagram_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  instagram_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  instagram_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  instagram_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  instagram_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  instagram_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  instagram_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  instagram_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  instagram_starts_with?: InputMaybe<Scalars['String']['input']>
  interests_every?: InputMaybe<InterestWhereInput>
  interests_none?: InputMaybe<InterestWhereInput>
  interests_some?: InputMaybe<InterestWhereInput>
  isTop?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isTop_not?: InputMaybe<Scalars['Boolean']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  lastname_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  lastname_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  lastname_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  lastname_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  lastname_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  lastname_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  lastname_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  lastname_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  lastname_starts_with?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  linkedin_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  linkedin_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  linkedin_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  linkedin_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  linkedin_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  linkedin_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  linkedin_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  linkedin_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  linkedin_starts_with?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  partnerCompany?: InputMaybe<PartnerCompanyWhereInput>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  partnerCompanyName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  partnerCompanyName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  partnerCompanyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  partnerCompanyName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  partnerCompanyName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  partnerCompanyName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  partnerCompanyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  partnerCompanyName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  partnerCompanyName_starts_with?: InputMaybe<Scalars['String']['input']>
  photoUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  photoUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  photoUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  photoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  photoUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  photoUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  photoUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  photoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  photoUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  photoUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  position_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  position_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  position_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  position_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  position_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  position_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  tiktok?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tiktok_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tiktok_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tiktok_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tiktok_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tiktok_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tiktok_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tiktok_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tiktok_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tiktok_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  twitter_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  twitter_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  twitter_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  twitter_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  twitter_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  twitter_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  twitter_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  twitter_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  twitter_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  website?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  website_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  website_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  website_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  website_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  website_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  website_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  website_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  website_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  website_starts_with?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  youtube_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  youtube_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  youtube_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  youtube_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  youtube_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  youtube_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  youtube_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  youtube_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  youtube_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SpeakerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpeakerWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpeakerWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpeakerWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SpeakerWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Speaker record uniquely */
export type SpeakerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
}

export type SubscriptionTeaser = Entity & {
  __typename?: 'SubscriptionTeaser'
  bannerText: Scalars['String']['output']
  ctaText: Scalars['String']['output']
  ctaUrl: Scalars['String']['output']
  /** Select a decoration pattern for this component. */
  decorationPattern?: Maybe<DecorationPattern>
  description?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<SubscriptionTeaser>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  styleVariant?: Maybe<ComponentStyle>
  subBannerText?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SubscriptionTeaserLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type SubscriptionTeaserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: SubscriptionTeaserWhereUniqueInput
}

/** A connection to a list of items. */
export type SubscriptionTeaserConnection = {
  __typename?: 'SubscriptionTeaserConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<SubscriptionTeaserEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type SubscriptionTeaserCreateInput = {
  /** bannerText input for default locale (de) */
  bannerText: Scalars['String']['input']
  /** ctaText input for default locale (de) */
  ctaText: Scalars['String']['input']
  /** ctaUrl input for default locale (de) */
  ctaUrl: Scalars['String']['input']
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SubscriptionTeaserCreateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subBannerText input for default locale (de) */
  subBannerText?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserCreateLocalizationDataInput = {
  bannerText: Scalars['String']['input']
  ctaText: Scalars['String']['input']
  ctaUrl: Scalars['String']['input']
  description?: InputMaybe<Scalars['String']['input']>
  subBannerText?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserCreateLocalizationInput = {
  /** Localization input */
  data: SubscriptionTeaserCreateLocalizationDataInput
  locale: Locale
}

export type SubscriptionTeaserCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SubscriptionTeaserCreateLocalizationInput>>
}

export type SubscriptionTeaserCreateManyInlineInput = {
  /** Create and connect multiple existing SubscriptionTeaser documents */
  create?: InputMaybe<Array<SubscriptionTeaserCreateInput>>
}

export type SubscriptionTeaserCreateOneInlineInput = {
  /** Create and connect one SubscriptionTeaser document */
  create?: InputMaybe<SubscriptionTeaserCreateInput>
}

export type SubscriptionTeaserCreateWithPositionInput = {
  /** Document to create */
  data: SubscriptionTeaserCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type SubscriptionTeaserEdge = {
  __typename?: 'SubscriptionTeaserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: SubscriptionTeaser
}

/** Identifies documents */
export type SubscriptionTeaserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum SubscriptionTeaserOrderByInput {
  BannerTextAsc = 'bannerText_ASC',
  BannerTextDesc = 'bannerText_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  SubBannerTextAsc = 'subBannerText_ASC',
  SubBannerTextDesc = 'subBannerText_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SubscriptionTeaserParent = EventPage | LandingPage

export type SubscriptionTeaserParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type SubscriptionTeaserParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type SubscriptionTeaserParentCreateManyInlineInput = {
  /** Connect multiple existing SubscriptionTeaserParent documents */
  connect?: InputMaybe<Array<SubscriptionTeaserParentWhereUniqueInput>>
  /** Create and connect multiple existing SubscriptionTeaserParent documents */
  create?: InputMaybe<Array<SubscriptionTeaserParentCreateInput>>
}

export type SubscriptionTeaserParentCreateOneInlineInput = {
  /** Connect one existing SubscriptionTeaserParent document */
  connect?: InputMaybe<SubscriptionTeaserParentWhereUniqueInput>
  /** Create and connect one SubscriptionTeaserParent document */
  create?: InputMaybe<SubscriptionTeaserParentCreateInput>
}

export type SubscriptionTeaserParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type SubscriptionTeaserParentUpdateManyInlineInput = {
  /** Connect multiple existing SubscriptionTeaserParent documents */
  connect?: InputMaybe<Array<SubscriptionTeaserParentConnectInput>>
  /** Create and connect multiple SubscriptionTeaserParent documents */
  create?: InputMaybe<Array<SubscriptionTeaserParentCreateInput>>
  /** Delete multiple SubscriptionTeaserParent documents */
  delete?: InputMaybe<Array<SubscriptionTeaserParentWhereUniqueInput>>
  /** Disconnect multiple SubscriptionTeaserParent documents */
  disconnect?: InputMaybe<Array<SubscriptionTeaserParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing SubscriptionTeaserParent documents */
  set?: InputMaybe<Array<SubscriptionTeaserParentWhereUniqueInput>>
  /** Update multiple SubscriptionTeaserParent documents */
  update?: InputMaybe<Array<SubscriptionTeaserParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple SubscriptionTeaserParent documents */
  upsert?: InputMaybe<Array<SubscriptionTeaserParentUpsertWithNestedWhereUniqueInput>>
}

export type SubscriptionTeaserParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type SubscriptionTeaserParentUpdateOneInlineInput = {
  /** Connect existing SubscriptionTeaserParent document */
  connect?: InputMaybe<SubscriptionTeaserParentWhereUniqueInput>
  /** Create and connect one SubscriptionTeaserParent document */
  create?: InputMaybe<SubscriptionTeaserParentCreateInput>
  /** Delete currently connected SubscriptionTeaserParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected SubscriptionTeaserParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SubscriptionTeaserParent document */
  update?: InputMaybe<SubscriptionTeaserParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single SubscriptionTeaserParent document */
  upsert?: InputMaybe<SubscriptionTeaserParentUpsertWithNestedWhereUniqueInput>
}

export type SubscriptionTeaserParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type SubscriptionTeaserParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type SubscriptionTeaserParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type SubscriptionTeaserParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type SubscriptionTeaserUpdateInput = {
  /** bannerText input for default locale (de) */
  bannerText?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<SubscriptionTeaserUpdateLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subBannerText input for default locale (de) */
  subBannerText?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserUpdateLocalizationDataInput = {
  bannerText?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subBannerText?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserUpdateLocalizationInput = {
  data: SubscriptionTeaserUpdateLocalizationDataInput
  locale: Locale
}

export type SubscriptionTeaserUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SubscriptionTeaserCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<SubscriptionTeaserUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<SubscriptionTeaserUpsertLocalizationInput>>
}

export type SubscriptionTeaserUpdateManyInlineInput = {
  /** Create and connect multiple SubscriptionTeaser component instances */
  create?: InputMaybe<Array<SubscriptionTeaserCreateWithPositionInput>>
  /** Delete multiple SubscriptionTeaser documents */
  delete?: InputMaybe<Array<SubscriptionTeaserWhereUniqueInput>>
  /** Update multiple SubscriptionTeaser component instances */
  update?: InputMaybe<Array<SubscriptionTeaserUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple SubscriptionTeaser component instances */
  upsert?: InputMaybe<Array<SubscriptionTeaserUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type SubscriptionTeaserUpdateManyInput = {
  /** bannerText input for default locale (de) */
  bannerText?: InputMaybe<Scalars['String']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<SubscriptionTeaserUpdateManyLocalizationsInput>
  sectionPadding?: InputMaybe<SectionPadding>
  styleVariant?: InputMaybe<ComponentStyle>
  /** subBannerText input for default locale (de) */
  subBannerText?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserUpdateManyLocalizationDataInput = {
  bannerText?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subBannerText?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionTeaserUpdateManyLocalizationInput = {
  data: SubscriptionTeaserUpdateManyLocalizationDataInput
  locale: Locale
}

export type SubscriptionTeaserUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SubscriptionTeaserUpdateManyLocalizationInput>>
}

export type SubscriptionTeaserUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SubscriptionTeaserUpdateManyInput
  /** Document search */
  where: SubscriptionTeaserWhereInput
}

export type SubscriptionTeaserUpdateOneInlineInput = {
  /** Create and connect one SubscriptionTeaser document */
  create?: InputMaybe<SubscriptionTeaserCreateInput>
  /** Delete currently connected SubscriptionTeaser document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single SubscriptionTeaser document */
  update?: InputMaybe<SubscriptionTeaserUpdateWithNestedWhereUniqueInput>
  /** Upsert single SubscriptionTeaser document */
  upsert?: InputMaybe<SubscriptionTeaserUpsertWithNestedWhereUniqueInput>
}

export type SubscriptionTeaserUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SubscriptionTeaserUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SubscriptionTeaserWhereUniqueInput
}

export type SubscriptionTeaserUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SubscriptionTeaserUpdateInput
  /** Unique document search */
  where: SubscriptionTeaserWhereUniqueInput
}

export type SubscriptionTeaserUpsertInput = {
  /** Create document if it didn't exist */
  create: SubscriptionTeaserCreateInput
  /** Update document if it exists */
  update: SubscriptionTeaserUpdateInput
}

export type SubscriptionTeaserUpsertLocalizationInput = {
  create: SubscriptionTeaserCreateLocalizationDataInput
  locale: Locale
  update: SubscriptionTeaserUpdateLocalizationDataInput
}

export type SubscriptionTeaserUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SubscriptionTeaserUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: SubscriptionTeaserWhereUniqueInput
}

export type SubscriptionTeaserUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SubscriptionTeaserUpsertInput
  /** Unique document search */
  where: SubscriptionTeaserWhereUniqueInput
}

/** Identifies documents */
export type SubscriptionTeaserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SubscriptionTeaserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  bannerText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  bannerText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  bannerText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  bannerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  bannerText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  bannerText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  bannerText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  bannerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  bannerText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  bannerText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  subBannerText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subBannerText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subBannerText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subBannerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subBannerText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subBannerText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subBannerText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subBannerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subBannerText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subBannerText_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References SubscriptionTeaser record uniquely */
export type SubscriptionTeaserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION',
}

export type TabContent = Entity &
  Node & {
    __typename?: 'TabContent'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    description?: Maybe<Scalars['String']['output']>
    /** Get the document in other stages */
    documentInStages: Array<TabContent>
    downloads: Array<Download>
    /** List of TabContent versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    image?: Maybe<Asset>
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<TabContent>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    tabName: Scalars['String']['output']
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

export type TabContentCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type TabContentCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TabContentDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type TabContentDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<DownloadOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<DownloadWhereInput>
}

export type TabContentHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

export type TabContentImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TabContentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type TabContentPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type TabContentPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TabContentScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

export type TabContentUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

export type TabContentUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TabContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TabContentWhereUniqueInput
}

/** A connection to a list of items. */
export type TabContentConnection = {
  __typename?: 'TabContentConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TabContentEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TabContentCreateInput = {
  contentInSection?: InputMaybe<SectionTabCreateOneInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  downloads?: InputMaybe<DownloadCreateManyInlineInput>
  image?: InputMaybe<AssetCreateOneInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TabContentCreateLocalizationsInput>
  /** tabName input for default locale (de) */
  tabName: Scalars['String']['input']
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TabContentCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  tabName: Scalars['String']['input']
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TabContentCreateLocalizationInput = {
  /** Localization input */
  data: TabContentCreateLocalizationDataInput
  locale: Locale
}

export type TabContentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TabContentCreateLocalizationInput>>
}

export type TabContentCreateManyInlineInput = {
  /** Connect multiple existing TabContent documents */
  connect?: InputMaybe<Array<TabContentWhereUniqueInput>>
  /** Create and connect multiple existing TabContent documents */
  create?: InputMaybe<Array<TabContentCreateInput>>
}

export type TabContentCreateOneInlineInput = {
  /** Connect one existing TabContent document */
  connect?: InputMaybe<TabContentWhereUniqueInput>
  /** Create and connect one TabContent document */
  create?: InputMaybe<TabContentCreateInput>
}

/** An edge in a connection. */
export type TabContentEdge = {
  __typename?: 'TabContentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TabContent
}

/** Identifies documents */
export type TabContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TabContentWhereStageInput>
  documentInStages_none?: InputMaybe<TabContentWhereStageInput>
  documentInStages_some?: InputMaybe<TabContentWhereStageInput>
  downloads_every?: InputMaybe<DownloadWhereInput>
  downloads_none?: InputMaybe<DownloadWhereInput>
  downloads_some?: InputMaybe<DownloadWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum TabContentOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TabNameAsc = 'tabName_ASC',
  TabNameDesc = 'tabName_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TabContentUpdateInput = {
  contentInSection?: InputMaybe<SectionTabUpdateOneInlineInput>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  downloads?: InputMaybe<DownloadUpdateManyInlineInput>
  image?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<TabContentUpdateLocalizationsInput>
  /** tabName input for default locale (de) */
  tabName?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TabContentUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  tabName?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TabContentUpdateLocalizationInput = {
  data: TabContentUpdateLocalizationDataInput
  locale: Locale
}

export type TabContentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TabContentCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TabContentUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TabContentUpsertLocalizationInput>>
}

export type TabContentUpdateManyInlineInput = {
  /** Connect multiple existing TabContent documents */
  connect?: InputMaybe<Array<TabContentConnectInput>>
  /** Create and connect multiple TabContent documents */
  create?: InputMaybe<Array<TabContentCreateInput>>
  /** Delete multiple TabContent documents */
  delete?: InputMaybe<Array<TabContentWhereUniqueInput>>
  /** Disconnect multiple TabContent documents */
  disconnect?: InputMaybe<Array<TabContentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TabContent documents */
  set?: InputMaybe<Array<TabContentWhereUniqueInput>>
  /** Update multiple TabContent documents */
  update?: InputMaybe<Array<TabContentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TabContent documents */
  upsert?: InputMaybe<Array<TabContentUpsertWithNestedWhereUniqueInput>>
}

export type TabContentUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<TabContentUpdateManyLocalizationsInput>
  /** tabName input for default locale (de) */
  tabName?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TabContentUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  tabName?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TabContentUpdateManyLocalizationInput = {
  data: TabContentUpdateManyLocalizationDataInput
  locale: Locale
}

export type TabContentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TabContentUpdateManyLocalizationInput>>
}

export type TabContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TabContentUpdateManyInput
  /** Document search */
  where: TabContentWhereInput
}

export type TabContentUpdateOneInlineInput = {
  /** Connect existing TabContent document */
  connect?: InputMaybe<TabContentWhereUniqueInput>
  /** Create and connect one TabContent document */
  create?: InputMaybe<TabContentCreateInput>
  /** Delete currently connected TabContent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TabContent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TabContent document */
  update?: InputMaybe<TabContentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TabContent document */
  upsert?: InputMaybe<TabContentUpsertWithNestedWhereUniqueInput>
}

export type TabContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TabContentUpdateInput
  /** Unique document search */
  where: TabContentWhereUniqueInput
}

export type TabContentUpsertInput = {
  /** Create document if it didn't exist */
  create: TabContentCreateInput
  /** Update document if it exists */
  update: TabContentUpdateInput
}

export type TabContentUpsertLocalizationInput = {
  create: TabContentCreateLocalizationDataInput
  locale: Locale
  update: TabContentUpdateLocalizationDataInput
}

export type TabContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TabContentUpsertInput
  /** Unique document search */
  where: TabContentWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type TabContentWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type TabContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabContentWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabContentWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabContentWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  documentInStages_every?: InputMaybe<TabContentWhereStageInput>
  documentInStages_none?: InputMaybe<TabContentWhereStageInput>
  documentInStages_some?: InputMaybe<TabContentWhereStageInput>
  downloads_every?: InputMaybe<DownloadWhereInput>
  downloads_none?: InputMaybe<DownloadWhereInput>
  downloads_some?: InputMaybe<DownloadWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  tabName?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tabName_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tabName_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tabName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tabName_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tabName_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tabName_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tabName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tabName_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tabName_starts_with?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TabContentWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabContentWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabContentWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabContentWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TabContentWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References TabContent record uniquely */
export type TabContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type Tag = Entity &
  Node & {
    __typename?: 'Tag'
    articles: Array<Article>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<Tag>
    /** List of Tag versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<Tag>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    reviewsProductCategories: Array<ReviewsProductCategory>
    reviewsProducts: Array<ReviewsProduct>
    scheduledIn: Array<ScheduledOperation>
    /** Optional configuration if you want to have specific SEO Meta Attributes. */
    seoAttributes?: Maybe<Seo>
    /** Optional configuration if you want to have specific SEO Content Attributes. */
    seoContentAttributes?: Maybe<SeoContentAttribute>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
    /** Wordpress tag ID (required for migration) */
    wpId?: Maybe<Scalars['String']['output']>
  }

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ArticleOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ArticleWhereInput>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagReviewsProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductCategoryWhereInput>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagReviewsProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<ReviewsProductOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ReviewsProductWhereInput>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagSeoContentAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Content Tags which can be used to cross reference across multiple content types. For SEO Purposes keep Tags general and not too specific.  */
export type TagUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TagConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TagWhereUniqueInput
}

/** A connection to a list of items. */
export type TagConnection = {
  __typename?: 'TagConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TagEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TagCreateInput = {
  articleByTag?: InputMaybe<ArticlesByTagCreateOneInlineInput>
  articles?: InputMaybe<ArticleCreateManyInlineInput>
  catInterestTags?: InputMaybe<SectionStagePreviewCreateOneInlineInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TagCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryCreateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeCreateOneInlineInput>
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type TagCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TagCreateLocalizationInput = {
  /** Localization input */
  data: TagCreateLocalizationDataInput
  locale: Locale
}

export type TagCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TagCreateLocalizationInput>>
}

export type TagCreateManyInlineInput = {
  /** Connect multiple existing Tag documents */
  connect?: InputMaybe<Array<TagWhereUniqueInput>>
  /** Create and connect multiple existing Tag documents */
  create?: InputMaybe<Array<TagCreateInput>>
}

export type TagCreateOneInlineInput = {
  /** Connect one existing Tag document */
  connect?: InputMaybe<TagWhereUniqueInput>
  /** Create and connect one Tag document */
  create?: InputMaybe<TagCreateInput>
}

/** An edge in a connection. */
export type TagEdge = {
  __typename?: 'TagEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: Tag
}

/** Identifies documents */
export type TagManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TagWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TagWhereStageInput>
  documentInStages_none?: InputMaybe<TagWhereStageInput>
  documentInStages_some?: InputMaybe<TagWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

export enum TagOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WpIdAsc = 'wpId_ASC',
  WpIdDesc = 'wpId_DESC',
}

export type TagUpdateInput = {
  articleByTag?: InputMaybe<ArticlesByTagUpdateOneInlineInput>
  articles?: InputMaybe<ArticleUpdateManyInlineInput>
  catInterestTags?: InputMaybe<SectionStagePreviewUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<TagUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  reviewsProductCategories?: InputMaybe<ReviewsProductCategoryUpdateManyInlineInput>
  reviewsProducts?: InputMaybe<ReviewsProductUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeUpdateOneInlineInput>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

export type TagUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type TagUpdateLocalizationInput = {
  data: TagUpdateLocalizationDataInput
  locale: Locale
}

export type TagUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TagCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TagUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TagUpsertLocalizationInput>>
}

export type TagUpdateManyInlineInput = {
  /** Connect multiple existing Tag documents */
  connect?: InputMaybe<Array<TagConnectInput>>
  /** Create and connect multiple Tag documents */
  create?: InputMaybe<Array<TagCreateInput>>
  /** Delete multiple Tag documents */
  delete?: InputMaybe<Array<TagWhereUniqueInput>>
  /** Disconnect multiple Tag documents */
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Tag documents */
  set?: InputMaybe<Array<TagWhereUniqueInput>>
  /** Update multiple Tag documents */
  update?: InputMaybe<Array<TagUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Tag documents */
  upsert?: InputMaybe<Array<TagUpsertWithNestedWhereUniqueInput>>
}

export type TagUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type TagUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TagUpdateManyInput
  /** Document search */
  where: TagWhereInput
}

export type TagUpdateOneInlineInput = {
  /** Connect existing Tag document */
  connect?: InputMaybe<TagWhereUniqueInput>
  /** Create and connect one Tag document */
  create?: InputMaybe<TagCreateInput>
  /** Delete currently connected Tag document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Tag document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Tag document */
  update?: InputMaybe<TagUpdateWithNestedWhereUniqueInput>
  /** Upsert single Tag document */
  upsert?: InputMaybe<TagUpsertWithNestedWhereUniqueInput>
}

export type TagUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TagUpdateInput
  /** Unique document search */
  where: TagWhereUniqueInput
}

export type TagUpsertInput = {
  /** Create document if it didn't exist */
  create: TagCreateInput
  /** Update document if it exists */
  update: TagUpdateInput
}

export type TagUpsertLocalizationInput = {
  create: TagCreateLocalizationDataInput
  locale: Locale
  update: TagUpdateLocalizationDataInput
}

export type TagUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TagUpsertInput
  /** Unique document search */
  where: TagWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type TagWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type TagWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TagWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TagWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TagWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  articles_every?: InputMaybe<ArticleWhereInput>
  articles_none?: InputMaybe<ArticleWhereInput>
  articles_some?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TagWhereStageInput>
  documentInStages_none?: InputMaybe<TagWhereStageInput>
  documentInStages_some?: InputMaybe<TagWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  reviewsProductCategories_every?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_none?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProductCategories_some?: InputMaybe<ReviewsProductCategoryWhereInput>
  reviewsProducts_every?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_none?: InputMaybe<ReviewsProductWhereInput>
  reviewsProducts_some?: InputMaybe<ReviewsProductWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  seoContentAttributes?: InputMaybe<SeoContentAttributeWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
  wpId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  wpId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  wpId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  wpId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  wpId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  wpId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  wpId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  wpId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  wpId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  wpId_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TagWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TagWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TagWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TagWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TagWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References Tag record uniquely */
export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  wpId?: InputMaybe<Scalars['String']['input']>
}

/** A section with multiple testimonials rendered as a slider. */
export type TestimonialSlider = Entity & {
  __typename?: 'TestimonialSlider'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<TestimonialSlider>
  slide: Array<SectionTestimonial>
  /** System stage field */
  stage: Stage
  /** Sets component color scheme. */
  styleVariant?: Maybe<ComponentStyle>
  title?: Maybe<Scalars['String']['output']>
}

/** A section with multiple testimonials rendered as a slider. */
export type TestimonialSliderLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** A section with multiple testimonials rendered as a slider. */
export type TestimonialSliderSlideArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<SectionTestimonialOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<SectionTestimonialWhereInput>
}

export type TestimonialSliderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TestimonialSliderWhereUniqueInput
}

/** A connection to a list of items. */
export type TestimonialSliderConnection = {
  __typename?: 'TestimonialSliderConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TestimonialSliderEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TestimonialSliderCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TestimonialSliderCreateLocalizationsInput>
  slide?: InputMaybe<SectionTestimonialCreateManyInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderCreateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderCreateLocalizationInput = {
  /** Localization input */
  data: TestimonialSliderCreateLocalizationDataInput
  locale: Locale
}

export type TestimonialSliderCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TestimonialSliderCreateLocalizationInput>>
}

export type TestimonialSliderCreateManyInlineInput = {
  /** Create and connect multiple existing TestimonialSlider documents */
  create?: InputMaybe<Array<TestimonialSliderCreateInput>>
}

export type TestimonialSliderCreateOneInlineInput = {
  /** Create and connect one TestimonialSlider document */
  create?: InputMaybe<TestimonialSliderCreateInput>
}

export type TestimonialSliderCreateWithPositionInput = {
  /** Document to create */
  data: TestimonialSliderCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type TestimonialSliderEdge = {
  __typename?: 'TestimonialSliderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TestimonialSlider
}

/** Identifies documents */
export type TestimonialSliderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  slide_every?: InputMaybe<SectionTestimonialWhereInput>
  slide_none?: InputMaybe<SectionTestimonialWhereInput>
  slide_some?: InputMaybe<SectionTestimonialWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
}

export enum TestimonialSliderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleVariantAsc = 'styleVariant_ASC',
  StyleVariantDesc = 'styleVariant_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TestimonialSliderParent = EventPage | LandingPage

export type TestimonialSliderParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type TestimonialSliderParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type TestimonialSliderParentCreateManyInlineInput = {
  /** Connect multiple existing TestimonialSliderParent documents */
  connect?: InputMaybe<Array<TestimonialSliderParentWhereUniqueInput>>
  /** Create and connect multiple existing TestimonialSliderParent documents */
  create?: InputMaybe<Array<TestimonialSliderParentCreateInput>>
}

export type TestimonialSliderParentCreateOneInlineInput = {
  /** Connect one existing TestimonialSliderParent document */
  connect?: InputMaybe<TestimonialSliderParentWhereUniqueInput>
  /** Create and connect one TestimonialSliderParent document */
  create?: InputMaybe<TestimonialSliderParentCreateInput>
}

export type TestimonialSliderParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type TestimonialSliderParentUpdateManyInlineInput = {
  /** Connect multiple existing TestimonialSliderParent documents */
  connect?: InputMaybe<Array<TestimonialSliderParentConnectInput>>
  /** Create and connect multiple TestimonialSliderParent documents */
  create?: InputMaybe<Array<TestimonialSliderParentCreateInput>>
  /** Delete multiple TestimonialSliderParent documents */
  delete?: InputMaybe<Array<TestimonialSliderParentWhereUniqueInput>>
  /** Disconnect multiple TestimonialSliderParent documents */
  disconnect?: InputMaybe<Array<TestimonialSliderParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TestimonialSliderParent documents */
  set?: InputMaybe<Array<TestimonialSliderParentWhereUniqueInput>>
  /** Update multiple TestimonialSliderParent documents */
  update?: InputMaybe<Array<TestimonialSliderParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TestimonialSliderParent documents */
  upsert?: InputMaybe<Array<TestimonialSliderParentUpsertWithNestedWhereUniqueInput>>
}

export type TestimonialSliderParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type TestimonialSliderParentUpdateOneInlineInput = {
  /** Connect existing TestimonialSliderParent document */
  connect?: InputMaybe<TestimonialSliderParentWhereUniqueInput>
  /** Create and connect one TestimonialSliderParent document */
  create?: InputMaybe<TestimonialSliderParentCreateInput>
  /** Delete currently connected TestimonialSliderParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TestimonialSliderParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TestimonialSliderParent document */
  update?: InputMaybe<TestimonialSliderParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TestimonialSliderParent document */
  upsert?: InputMaybe<TestimonialSliderParentUpsertWithNestedWhereUniqueInput>
}

export type TestimonialSliderParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type TestimonialSliderParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type TestimonialSliderParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type TestimonialSliderParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type TestimonialSliderUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<TestimonialSliderUpdateLocalizationsInput>
  slide?: InputMaybe<SectionTestimonialUpdateManyInlineInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderUpdateLocalizationInput = {
  data: TestimonialSliderUpdateLocalizationDataInput
  locale: Locale
}

export type TestimonialSliderUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TestimonialSliderCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialSliderUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TestimonialSliderUpsertLocalizationInput>>
}

export type TestimonialSliderUpdateManyInlineInput = {
  /** Create and connect multiple TestimonialSlider component instances */
  create?: InputMaybe<Array<TestimonialSliderCreateWithPositionInput>>
  /** Delete multiple TestimonialSlider documents */
  delete?: InputMaybe<Array<TestimonialSliderWhereUniqueInput>>
  /** Update multiple TestimonialSlider component instances */
  update?: InputMaybe<Array<TestimonialSliderUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TestimonialSlider component instances */
  upsert?: InputMaybe<Array<TestimonialSliderUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TestimonialSliderUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<TestimonialSliderUpdateManyLocalizationsInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type TestimonialSliderUpdateManyLocalizationInput = {
  data: TestimonialSliderUpdateManyLocalizationDataInput
  locale: Locale
}

export type TestimonialSliderUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialSliderUpdateManyLocalizationInput>>
}

export type TestimonialSliderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TestimonialSliderUpdateManyInput
  /** Document search */
  where: TestimonialSliderWhereInput
}

export type TestimonialSliderUpdateOneInlineInput = {
  /** Create and connect one TestimonialSlider document */
  create?: InputMaybe<TestimonialSliderCreateInput>
  /** Delete currently connected TestimonialSlider document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TestimonialSlider document */
  update?: InputMaybe<TestimonialSliderUpdateWithNestedWhereUniqueInput>
  /** Upsert single TestimonialSlider document */
  upsert?: InputMaybe<TestimonialSliderUpsertWithNestedWhereUniqueInput>
}

export type TestimonialSliderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TestimonialSliderUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TestimonialSliderWhereUniqueInput
}

export type TestimonialSliderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TestimonialSliderUpdateInput
  /** Unique document search */
  where: TestimonialSliderWhereUniqueInput
}

export type TestimonialSliderUpsertInput = {
  /** Create document if it didn't exist */
  create: TestimonialSliderCreateInput
  /** Update document if it exists */
  update: TestimonialSliderUpdateInput
}

export type TestimonialSliderUpsertLocalizationInput = {
  create: TestimonialSliderCreateLocalizationDataInput
  locale: Locale
  update: TestimonialSliderUpdateLocalizationDataInput
}

export type TestimonialSliderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TestimonialSliderUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TestimonialSliderWhereUniqueInput
}

export type TestimonialSliderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TestimonialSliderUpsertInput
  /** Unique document search */
  where: TestimonialSliderWhereUniqueInput
}

/** Identifies documents */
export type TestimonialSliderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialSliderWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  slide_every?: InputMaybe<SectionTestimonialWhereInput>
  slide_none?: InputMaybe<SectionTestimonialWhereInput>
  slide_some?: InputMaybe<SectionTestimonialWhereInput>
  styleVariant?: InputMaybe<ComponentStyle>
  /** All values that are contained in given list. */
  styleVariant_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  styleVariant_not?: InputMaybe<ComponentStyle>
  /** All values that are not contained in given list. */
  styleVariant_not_in?: InputMaybe<Array<InputMaybe<ComponentStyle>>>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References TestimonialSlider record uniquely */
export type TestimonialSliderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** A section representing media asset next to text content with optional CTA */
export type TextMedia = Entity & {
  __typename?: 'TextMedia'
  /** Text alignment centered */
  centered?: Maybe<Scalars['Boolean']['output']>
  /** Call to action element rendered below text content */
  cta?: Maybe<CallToAction>
  /** DEPRECATED use CTA component */
  ctaText?: Maybe<Scalars['String']['output']>
  /** DEPRECATED use CTA component */
  ctaUrl?: Maybe<Scalars['String']['output']>
  darkTheme?: Maybe<Scalars['Boolean']['output']>
  decorationPattern?: Maybe<DecorationPattern>
  decorationStyle?: Maybe<DecorationStyle>
  description: Scalars['String']['output']
  enableDescriptionStyling?: Maybe<Scalars['Boolean']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Video or image to render */
  image: Asset
  /** Hide media asset on mobile devices */
  imageHiddenOnMobile?: Maybe<Scalars['Boolean']['output']>
  /** CTA url for image media asset */
  imageLinkUrl?: Maybe<Scalars['String']['output']>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<TextMedia>
  /** Position of the media asset (defaults to left) */
  position?: Maybe<ElementPosition>
  /** A proportions how whole section is divided into columns. */
  sectionDivision?: Maybe<SectionDivision>
  /** Select a custom padding for this section, otherwise default is used. */
  sectionPadding?: Maybe<SectionPadding>
  /** System stage field */
  stage: Stage
  subtitle?: Maybe<Scalars['String']['output']>
  tag?: Maybe<Scalars['String']['output']>
  textBanner?: Maybe<Asset>
  title: Scalars['String']['output']
  /** Render image belo text content */
  vertical?: Maybe<Scalars['Boolean']['output']>
}

/** A section representing media asset next to text content with optional CTA */
export type TextMediaCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** A section representing media asset next to text content with optional CTA */
export type TextMediaImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** A section representing media asset next to text content with optional CTA */
export type TextMediaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** A section representing media asset next to text content with optional CTA */
export type TextMediaTextBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TextMediaBanner = Entity & {
  __typename?: 'TextMediaBanner'
  description?: Maybe<Scalars['String']['output']>
  desktopBanner: Asset
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<TextMediaBanner>
  mobileBanner: Asset
  /** System stage field */
  stage: Stage
  textMediaBannerCta?: Maybe<CallToAction>
  title?: Maybe<Scalars['String']['output']>
}

export type TextMediaBannerDesktopBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TextMediaBannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type TextMediaBannerMobileBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TextMediaBannerTextMediaBannerCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TextMediaBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TextMediaBannerWhereUniqueInput
}

/** A connection to a list of items. */
export type TextMediaBannerConnection = {
  __typename?: 'TextMediaBannerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TextMediaBannerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TextMediaBannerCreateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  desktopBanner: AssetCreateOneInlineInput
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TextMediaBannerCreateLocalizationsInput>
  mobileBanner: AssetCreateOneInlineInput
  textMediaBannerCta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerCreateLocalizationInput = {
  /** Localization input */
  data: TextMediaBannerCreateLocalizationDataInput
  locale: Locale
}

export type TextMediaBannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TextMediaBannerCreateLocalizationInput>>
}

export type TextMediaBannerCreateManyInlineInput = {
  /** Create and connect multiple existing TextMediaBanner documents */
  create?: InputMaybe<Array<TextMediaBannerCreateInput>>
}

export type TextMediaBannerCreateOneInlineInput = {
  /** Create and connect one TextMediaBanner document */
  create?: InputMaybe<TextMediaBannerCreateInput>
}

export type TextMediaBannerCreateWithPositionInput = {
  /** Document to create */
  data: TextMediaBannerCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type TextMediaBannerEdge = {
  __typename?: 'TextMediaBannerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TextMediaBanner
}

/** Identifies documents */
export type TextMediaBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  desktopBanner?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileBanner?: InputMaybe<AssetWhereInput>
  textMediaBannerCta?: InputMaybe<CallToActionWhereInput>
}

export enum TextMediaBannerOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TextMediaBannerParent = LandingPage

export type TextMediaBannerParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type TextMediaBannerParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type TextMediaBannerParentCreateManyInlineInput = {
  /** Connect multiple existing TextMediaBannerParent documents */
  connect?: InputMaybe<Array<TextMediaBannerParentWhereUniqueInput>>
  /** Create and connect multiple existing TextMediaBannerParent documents */
  create?: InputMaybe<Array<TextMediaBannerParentCreateInput>>
}

export type TextMediaBannerParentCreateOneInlineInput = {
  /** Connect one existing TextMediaBannerParent document */
  connect?: InputMaybe<TextMediaBannerParentWhereUniqueInput>
  /** Create and connect one TextMediaBannerParent document */
  create?: InputMaybe<TextMediaBannerParentCreateInput>
}

export type TextMediaBannerParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type TextMediaBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing TextMediaBannerParent documents */
  connect?: InputMaybe<Array<TextMediaBannerParentConnectInput>>
  /** Create and connect multiple TextMediaBannerParent documents */
  create?: InputMaybe<Array<TextMediaBannerParentCreateInput>>
  /** Delete multiple TextMediaBannerParent documents */
  delete?: InputMaybe<Array<TextMediaBannerParentWhereUniqueInput>>
  /** Disconnect multiple TextMediaBannerParent documents */
  disconnect?: InputMaybe<Array<TextMediaBannerParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TextMediaBannerParent documents */
  set?: InputMaybe<Array<TextMediaBannerParentWhereUniqueInput>>
  /** Update multiple TextMediaBannerParent documents */
  update?: InputMaybe<Array<TextMediaBannerParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TextMediaBannerParent documents */
  upsert?: InputMaybe<Array<TextMediaBannerParentUpsertWithNestedWhereUniqueInput>>
}

export type TextMediaBannerParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type TextMediaBannerParentUpdateOneInlineInput = {
  /** Connect existing TextMediaBannerParent document */
  connect?: InputMaybe<TextMediaBannerParentWhereUniqueInput>
  /** Create and connect one TextMediaBannerParent document */
  create?: InputMaybe<TextMediaBannerParentCreateInput>
  /** Delete currently connected TextMediaBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TextMediaBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TextMediaBannerParent document */
  update?: InputMaybe<TextMediaBannerParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TextMediaBannerParent document */
  upsert?: InputMaybe<TextMediaBannerParentUpsertWithNestedWhereUniqueInput>
}

export type TextMediaBannerParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type TextMediaBannerParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type TextMediaBannerParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type TextMediaBannerParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type TextMediaBannerUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  desktopBanner?: InputMaybe<AssetUpdateOneInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<TextMediaBannerUpdateLocalizationsInput>
  mobileBanner?: InputMaybe<AssetUpdateOneInlineInput>
  textMediaBannerCta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerUpdateLocalizationInput = {
  data: TextMediaBannerUpdateLocalizationDataInput
  locale: Locale
}

export type TextMediaBannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TextMediaBannerCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TextMediaBannerUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TextMediaBannerUpsertLocalizationInput>>
}

export type TextMediaBannerUpdateManyInlineInput = {
  /** Create and connect multiple TextMediaBanner component instances */
  create?: InputMaybe<Array<TextMediaBannerCreateWithPositionInput>>
  /** Delete multiple TextMediaBanner documents */
  delete?: InputMaybe<Array<TextMediaBannerWhereUniqueInput>>
  /** Update multiple TextMediaBanner component instances */
  update?: InputMaybe<Array<TextMediaBannerUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TextMediaBanner component instances */
  upsert?: InputMaybe<Array<TextMediaBannerUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TextMediaBannerUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<TextMediaBannerUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaBannerUpdateManyLocalizationInput = {
  data: TextMediaBannerUpdateManyLocalizationDataInput
  locale: Locale
}

export type TextMediaBannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TextMediaBannerUpdateManyLocalizationInput>>
}

export type TextMediaBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextMediaBannerUpdateManyInput
  /** Document search */
  where: TextMediaBannerWhereInput
}

export type TextMediaBannerUpdateOneInlineInput = {
  /** Create and connect one TextMediaBanner document */
  create?: InputMaybe<TextMediaBannerCreateInput>
  /** Delete currently connected TextMediaBanner document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TextMediaBanner document */
  update?: InputMaybe<TextMediaBannerUpdateWithNestedWhereUniqueInput>
  /** Upsert single TextMediaBanner document */
  upsert?: InputMaybe<TextMediaBannerUpsertWithNestedWhereUniqueInput>
}

export type TextMediaBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TextMediaBannerUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TextMediaBannerWhereUniqueInput
}

export type TextMediaBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextMediaBannerUpdateInput
  /** Unique document search */
  where: TextMediaBannerWhereUniqueInput
}

export type TextMediaBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: TextMediaBannerCreateInput
  /** Update document if it exists */
  update: TextMediaBannerUpdateInput
}

export type TextMediaBannerUpsertLocalizationInput = {
  create: TextMediaBannerCreateLocalizationDataInput
  locale: Locale
  update: TextMediaBannerUpdateLocalizationDataInput
}

export type TextMediaBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TextMediaBannerUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TextMediaBannerWhereUniqueInput
}

export type TextMediaBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextMediaBannerUpsertInput
  /** Unique document search */
  where: TextMediaBannerWhereUniqueInput
}

/** Identifies documents */
export type TextMediaBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextMediaBannerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  desktopBanner?: InputMaybe<AssetWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileBanner?: InputMaybe<AssetWhereInput>
  textMediaBannerCta?: InputMaybe<CallToActionWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References TextMediaBanner record uniquely */
export type TextMediaBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type TextMediaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TextMediaWhereUniqueInput
}

/** A connection to a list of items. */
export type TextMediaConnection = {
  __typename?: 'TextMediaConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TextMediaEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TextMediaCreateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionCreateOneInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** description input for default locale (de) */
  description: Scalars['String']['input']
  enableDescriptionStyling?: InputMaybe<Scalars['Boolean']['input']>
  image: AssetCreateOneInlineInput
  imageHiddenOnMobile?: InputMaybe<Scalars['Boolean']['input']>
  imageLinkUrl?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TextMediaCreateLocalizationsInput>
  position?: InputMaybe<ElementPosition>
  sectionDivision?: InputMaybe<SectionDivision>
  sectionPadding?: InputMaybe<SectionPadding>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** tag input for default locale (de) */
  tag?: InputMaybe<Scalars['String']['input']>
  textBanner?: InputMaybe<AssetCreateOneInlineInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  vertical?: InputMaybe<Scalars['Boolean']['input']>
}

export type TextMediaCreateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description: Scalars['String']['input']
  subtitle?: InputMaybe<Scalars['String']['input']>
  tag?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

export type TextMediaCreateLocalizationInput = {
  /** Localization input */
  data: TextMediaCreateLocalizationDataInput
  locale: Locale
}

export type TextMediaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TextMediaCreateLocalizationInput>>
}

export type TextMediaCreateManyInlineInput = {
  /** Create and connect multiple existing TextMedia documents */
  create?: InputMaybe<Array<TextMediaCreateInput>>
}

export type TextMediaCreateOneInlineInput = {
  /** Create and connect one TextMedia document */
  create?: InputMaybe<TextMediaCreateInput>
}

export type TextMediaCreateWithPositionInput = {
  /** Document to create */
  data: TextMediaCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type TextMediaEdge = {
  __typename?: 'TextMediaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TextMedia
}

/** Identifies documents */
export type TextMediaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextMediaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextMediaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextMediaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** All values that are contained in given list. */
  decorationStyle_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationStyle_not?: InputMaybe<DecorationStyle>
  /** All values that are not contained in given list. */
  decorationStyle_not_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  enableDescriptionStyling?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableDescriptionStyling_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  imageHiddenOnMobile?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  imageHiddenOnMobile_not?: InputMaybe<Scalars['Boolean']['input']>
  imageLinkUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageLinkUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageLinkUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageLinkUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageLinkUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageLinkUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageLinkUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageLinkUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  sectionDivision?: InputMaybe<SectionDivision>
  /** All values that are contained in given list. */
  sectionDivision_in?: InputMaybe<Array<InputMaybe<SectionDivision>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionDivision_not?: InputMaybe<SectionDivision>
  /** All values that are not contained in given list. */
  sectionDivision_not_in?: InputMaybe<Array<InputMaybe<SectionDivision>>>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  textBanner?: InputMaybe<AssetWhereInput>
  vertical?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  vertical_not?: InputMaybe<Scalars['Boolean']['input']>
}

export enum TextMediaOrderByInput {
  CenteredAsc = 'centered_ASC',
  CenteredDesc = 'centered_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  CtaUrlAsc = 'ctaUrl_ASC',
  CtaUrlDesc = 'ctaUrl_DESC',
  DarkThemeAsc = 'darkTheme_ASC',
  DarkThemeDesc = 'darkTheme_DESC',
  DecorationPatternAsc = 'decorationPattern_ASC',
  DecorationPatternDesc = 'decorationPattern_DESC',
  DecorationStyleAsc = 'decorationStyle_ASC',
  DecorationStyleDesc = 'decorationStyle_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EnableDescriptionStylingAsc = 'enableDescriptionStyling_ASC',
  EnableDescriptionStylingDesc = 'enableDescriptionStyling_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageHiddenOnMobileAsc = 'imageHiddenOnMobile_ASC',
  ImageHiddenOnMobileDesc = 'imageHiddenOnMobile_DESC',
  ImageLinkUrlAsc = 'imageLinkUrl_ASC',
  ImageLinkUrlDesc = 'imageLinkUrl_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SectionDivisionAsc = 'sectionDivision_ASC',
  SectionDivisionDesc = 'sectionDivision_DESC',
  SectionPaddingAsc = 'sectionPadding_ASC',
  SectionPaddingDesc = 'sectionPadding_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TagAsc = 'tag_ASC',
  TagDesc = 'tag_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VerticalAsc = 'vertical_ASC',
  VerticalDesc = 'vertical_DESC',
}

export type TextMediaParent = EventPage | LandingPage

export type TextMediaParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
  LandingPage?: InputMaybe<LandingPageConnectInput>
}

export type TextMediaParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
  LandingPage?: InputMaybe<LandingPageCreateInput>
}

export type TextMediaParentCreateManyInlineInput = {
  /** Connect multiple existing TextMediaParent documents */
  connect?: InputMaybe<Array<TextMediaParentWhereUniqueInput>>
  /** Create and connect multiple existing TextMediaParent documents */
  create?: InputMaybe<Array<TextMediaParentCreateInput>>
}

export type TextMediaParentCreateOneInlineInput = {
  /** Connect one existing TextMediaParent document */
  connect?: InputMaybe<TextMediaParentWhereUniqueInput>
  /** Create and connect one TextMediaParent document */
  create?: InputMaybe<TextMediaParentCreateInput>
}

export type TextMediaParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
  LandingPage?: InputMaybe<LandingPageUpdateInput>
}

export type TextMediaParentUpdateManyInlineInput = {
  /** Connect multiple existing TextMediaParent documents */
  connect?: InputMaybe<Array<TextMediaParentConnectInput>>
  /** Create and connect multiple TextMediaParent documents */
  create?: InputMaybe<Array<TextMediaParentCreateInput>>
  /** Delete multiple TextMediaParent documents */
  delete?: InputMaybe<Array<TextMediaParentWhereUniqueInput>>
  /** Disconnect multiple TextMediaParent documents */
  disconnect?: InputMaybe<Array<TextMediaParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TextMediaParent documents */
  set?: InputMaybe<Array<TextMediaParentWhereUniqueInput>>
  /** Update multiple TextMediaParent documents */
  update?: InputMaybe<Array<TextMediaParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TextMediaParent documents */
  upsert?: InputMaybe<Array<TextMediaParentUpsertWithNestedWhereUniqueInput>>
}

export type TextMediaParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>
}

export type TextMediaParentUpdateOneInlineInput = {
  /** Connect existing TextMediaParent document */
  connect?: InputMaybe<TextMediaParentWhereUniqueInput>
  /** Create and connect one TextMediaParent document */
  create?: InputMaybe<TextMediaParentCreateInput>
  /** Delete currently connected TextMediaParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TextMediaParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TextMediaParent document */
  update?: InputMaybe<TextMediaParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TextMediaParent document */
  upsert?: InputMaybe<TextMediaParentUpsertWithNestedWhereUniqueInput>
}

export type TextMediaParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>
}

export type TextMediaParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>
}

export type TextMediaParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
  LandingPage?: InputMaybe<LandingPageWhereInput>
}

export type TextMediaParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>
}

export type TextMediaUpdateInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionUpdateOneInlineInput>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  enableDescriptionStyling?: InputMaybe<Scalars['Boolean']['input']>
  image?: InputMaybe<AssetUpdateOneInlineInput>
  imageHiddenOnMobile?: InputMaybe<Scalars['Boolean']['input']>
  imageLinkUrl?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<TextMediaUpdateLocalizationsInput>
  position?: InputMaybe<ElementPosition>
  sectionDivision?: InputMaybe<SectionDivision>
  sectionPadding?: InputMaybe<SectionPadding>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** tag input for default locale (de) */
  tag?: InputMaybe<Scalars['String']['input']>
  textBanner?: InputMaybe<AssetUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<Scalars['Boolean']['input']>
}

export type TextMediaUpdateLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  tag?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaUpdateLocalizationInput = {
  data: TextMediaUpdateLocalizationDataInput
  locale: Locale
}

export type TextMediaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TextMediaCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TextMediaUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TextMediaUpsertLocalizationInput>>
}

export type TextMediaUpdateManyInlineInput = {
  /** Create and connect multiple TextMedia component instances */
  create?: InputMaybe<Array<TextMediaCreateWithPositionInput>>
  /** Delete multiple TextMedia documents */
  delete?: InputMaybe<Array<TextMediaWhereUniqueInput>>
  /** Update multiple TextMedia component instances */
  update?: InputMaybe<Array<TextMediaUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TextMedia component instances */
  upsert?: InputMaybe<Array<TextMediaUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TextMediaUpdateManyInput = {
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** ctaText input for default locale (de) */
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** ctaUrl input for default locale (de) */
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars['String']['input']>
  enableDescriptionStyling?: InputMaybe<Scalars['Boolean']['input']>
  imageHiddenOnMobile?: InputMaybe<Scalars['Boolean']['input']>
  imageLinkUrl?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<TextMediaUpdateManyLocalizationsInput>
  position?: InputMaybe<ElementPosition>
  sectionDivision?: InputMaybe<SectionDivision>
  sectionPadding?: InputMaybe<SectionPadding>
  /** subtitle input for default locale (de) */
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** tag input for default locale (de) */
  tag?: InputMaybe<Scalars['String']['input']>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<Scalars['Boolean']['input']>
}

export type TextMediaUpdateManyLocalizationDataInput = {
  ctaText?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  subtitle?: InputMaybe<Scalars['String']['input']>
  tag?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TextMediaUpdateManyLocalizationInput = {
  data: TextMediaUpdateManyLocalizationDataInput
  locale: Locale
}

export type TextMediaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TextMediaUpdateManyLocalizationInput>>
}

export type TextMediaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextMediaUpdateManyInput
  /** Document search */
  where: TextMediaWhereInput
}

export type TextMediaUpdateOneInlineInput = {
  /** Create and connect one TextMedia document */
  create?: InputMaybe<TextMediaCreateInput>
  /** Delete currently connected TextMedia document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TextMedia document */
  update?: InputMaybe<TextMediaUpdateWithNestedWhereUniqueInput>
  /** Upsert single TextMedia document */
  upsert?: InputMaybe<TextMediaUpsertWithNestedWhereUniqueInput>
}

export type TextMediaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TextMediaUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TextMediaWhereUniqueInput
}

export type TextMediaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextMediaUpdateInput
  /** Unique document search */
  where: TextMediaWhereUniqueInput
}

export type TextMediaUpsertInput = {
  /** Create document if it didn't exist */
  create: TextMediaCreateInput
  /** Update document if it exists */
  update: TextMediaUpdateInput
}

export type TextMediaUpsertLocalizationInput = {
  create: TextMediaCreateLocalizationDataInput
  locale: Locale
  update: TextMediaUpdateLocalizationDataInput
}

export type TextMediaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TextMediaUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TextMediaWhereUniqueInput
}

export type TextMediaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextMediaUpsertInput
  /** Unique document search */
  where: TextMediaWhereUniqueInput
}

/** Identifies documents */
export type TextMediaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextMediaWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextMediaWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextMediaWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  centered?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  centered_not?: InputMaybe<Scalars['Boolean']['input']>
  cta?: InputMaybe<CallToActionWhereInput>
  ctaText?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']['input']>
  ctaUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  ctaUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  ctaUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  ctaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  ctaUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  ctaUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  ctaUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  ctaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  ctaUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  ctaUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  darkTheme?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  darkTheme_not?: InputMaybe<Scalars['Boolean']['input']>
  decorationPattern?: InputMaybe<DecorationPattern>
  /** All values that are contained in given list. */
  decorationPattern_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationPattern_not?: InputMaybe<DecorationPattern>
  /** All values that are not contained in given list. */
  decorationPattern_not_in?: InputMaybe<Array<InputMaybe<DecorationPattern>>>
  decorationStyle?: InputMaybe<DecorationStyle>
  /** All values that are contained in given list. */
  decorationStyle_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  /** Any other value that exists and is not equal to the given value. */
  decorationStyle_not?: InputMaybe<DecorationStyle>
  /** All values that are not contained in given list. */
  decorationStyle_not_in?: InputMaybe<Array<InputMaybe<DecorationStyle>>>
  description?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']['input']>
  enableDescriptionStyling?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  enableDescriptionStyling_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  image?: InputMaybe<AssetWhereInput>
  imageHiddenOnMobile?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  imageHiddenOnMobile_not?: InputMaybe<Scalars['Boolean']['input']>
  imageLinkUrl?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  imageLinkUrl_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  imageLinkUrl_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  imageLinkUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  imageLinkUrl_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  imageLinkUrl_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  imageLinkUrl_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  imageLinkUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  imageLinkUrl_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  imageLinkUrl_starts_with?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<ElementPosition>
  /** All values that are contained in given list. */
  position_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  /** Any other value that exists and is not equal to the given value. */
  position_not?: InputMaybe<ElementPosition>
  /** All values that are not contained in given list. */
  position_not_in?: InputMaybe<Array<InputMaybe<ElementPosition>>>
  sectionDivision?: InputMaybe<SectionDivision>
  /** All values that are contained in given list. */
  sectionDivision_in?: InputMaybe<Array<InputMaybe<SectionDivision>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionDivision_not?: InputMaybe<SectionDivision>
  /** All values that are not contained in given list. */
  sectionDivision_not_in?: InputMaybe<Array<InputMaybe<SectionDivision>>>
  sectionPadding?: InputMaybe<SectionPadding>
  /** All values that are contained in given list. */
  sectionPadding_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  /** Any other value that exists and is not equal to the given value. */
  sectionPadding_not?: InputMaybe<SectionPadding>
  /** All values that are not contained in given list. */
  sectionPadding_not_in?: InputMaybe<Array<InputMaybe<SectionPadding>>>
  subtitle?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  subtitle_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  subtitle_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  subtitle_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  subtitle_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  subtitle_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  subtitle_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  subtitle_starts_with?: InputMaybe<Scalars['String']['input']>
  tag?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  tag_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  tag_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  tag_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  tag_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  tag_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  tag_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  tag_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  tag_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  tag_starts_with?: InputMaybe<Scalars['String']['input']>
  textBanner?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  vertical?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  vertical_not?: InputMaybe<Scalars['Boolean']['input']>
}

/** References TextMedia record uniquely */
export type TextMediaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type TicketsTableRow = Entity & {
  __typename?: 'TicketsTableRow'
  availableOne: Scalars['Boolean']['output']
  availableThree: Scalars['Boolean']['output']
  availableTwo: Scalars['Boolean']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<TicketsTableRow>
  rowLabel: Scalars['String']['output']
  /** System stage field */
  stage: Stage
}

export type TicketsTableRowLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type TicketsTableRowConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TicketsTableRowWhereUniqueInput
}

/** A connection to a list of items. */
export type TicketsTableRowConnection = {
  __typename?: 'TicketsTableRowConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TicketsTableRowEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TicketsTableRowCreateInput = {
  availableOne: Scalars['Boolean']['input']
  availableThree: Scalars['Boolean']['input']
  availableTwo: Scalars['Boolean']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TicketsTableRowCreateLocalizationsInput>
  /** rowLabel input for default locale (de) */
  rowLabel: Scalars['String']['input']
}

export type TicketsTableRowCreateLocalizationDataInput = {
  rowLabel: Scalars['String']['input']
}

export type TicketsTableRowCreateLocalizationInput = {
  /** Localization input */
  data: TicketsTableRowCreateLocalizationDataInput
  locale: Locale
}

export type TicketsTableRowCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TicketsTableRowCreateLocalizationInput>>
}

export type TicketsTableRowCreateManyInlineInput = {
  /** Create and connect multiple existing TicketsTableRow documents */
  create?: InputMaybe<Array<TicketsTableRowCreateInput>>
}

export type TicketsTableRowCreateOneInlineInput = {
  /** Create and connect one TicketsTableRow document */
  create?: InputMaybe<TicketsTableRowCreateInput>
}

export type TicketsTableRowCreateWithPositionInput = {
  /** Document to create */
  data: TicketsTableRowCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type TicketsTableRowEdge = {
  __typename?: 'TicketsTableRowEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TicketsTableRow
}

/** Identifies documents */
export type TicketsTableRowManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  availableOne?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableOne_not?: InputMaybe<Scalars['Boolean']['input']>
  availableThree?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableThree_not?: InputMaybe<Scalars['Boolean']['input']>
  availableTwo?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableTwo_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum TicketsTableRowOrderByInput {
  AvailableOneAsc = 'availableOne_ASC',
  AvailableOneDesc = 'availableOne_DESC',
  AvailableThreeAsc = 'availableThree_ASC',
  AvailableThreeDesc = 'availableThree_DESC',
  AvailableTwoAsc = 'availableTwo_ASC',
  AvailableTwoDesc = 'availableTwo_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  RowLabelAsc = 'rowLabel_ASC',
  RowLabelDesc = 'rowLabel_DESC',
}

export type TicketsTableRowParent = SectionTicketsTable

export type TicketsTableRowParentConnectInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableConnectInput>
}

export type TicketsTableRowParentCreateInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableCreateInput>
}

export type TicketsTableRowParentCreateManyInlineInput = {
  /** Create and connect multiple existing TicketsTableRowParent documents */
  create?: InputMaybe<Array<TicketsTableRowParentCreateInput>>
}

export type TicketsTableRowParentCreateOneInlineInput = {
  /** Create and connect one TicketsTableRowParent document */
  create?: InputMaybe<TicketsTableRowParentCreateInput>
}

export type TicketsTableRowParentCreateWithPositionInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableCreateWithPositionInput>
}

export type TicketsTableRowParentUpdateInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateInput>
}

export type TicketsTableRowParentUpdateManyInlineInput = {
  /** Create and connect multiple TicketsTableRowParent component instances */
  create?: InputMaybe<Array<TicketsTableRowParentCreateWithPositionInput>>
  /** Delete multiple TicketsTableRowParent documents */
  delete?: InputMaybe<Array<TicketsTableRowParentWhereUniqueInput>>
  /** Update multiple TicketsTableRowParent component instances */
  update?: InputMaybe<Array<TicketsTableRowParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TicketsTableRowParent component instances */
  upsert?: InputMaybe<Array<TicketsTableRowParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TicketsTableRowParentUpdateManyWithNestedWhereInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateManyWithNestedWhereInput>
}

export type TicketsTableRowParentUpdateOneInlineInput = {
  /** Create and connect one TicketsTableRowParent document */
  create?: InputMaybe<TicketsTableRowParentCreateInput>
  /** Delete currently connected TicketsTableRowParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TicketsTableRowParent document */
  update?: InputMaybe<TicketsTableRowParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TicketsTableRowParent document */
  upsert?: InputMaybe<TicketsTableRowParentUpsertWithNestedWhereUniqueInput>
}

export type TicketsTableRowParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateWithNestedWhereUniqueAndPositionInput>
}

export type TicketsTableRowParentUpdateWithNestedWhereUniqueInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpdateWithNestedWhereUniqueInput>
}

export type TicketsTableRowParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpsertWithNestedWhereUniqueAndPositionInput>
}

export type TicketsTableRowParentUpsertWithNestedWhereUniqueInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableUpsertWithNestedWhereUniqueInput>
}

export type TicketsTableRowParentWhereInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableWhereInput>
}

export type TicketsTableRowParentWhereUniqueInput = {
  SectionTicketsTable?: InputMaybe<SectionTicketsTableWhereUniqueInput>
}

export type TicketsTableRowUpdateInput = {
  availableOne?: InputMaybe<Scalars['Boolean']['input']>
  availableThree?: InputMaybe<Scalars['Boolean']['input']>
  availableTwo?: InputMaybe<Scalars['Boolean']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<TicketsTableRowUpdateLocalizationsInput>
  /** rowLabel input for default locale (de) */
  rowLabel?: InputMaybe<Scalars['String']['input']>
}

export type TicketsTableRowUpdateLocalizationDataInput = {
  rowLabel?: InputMaybe<Scalars['String']['input']>
}

export type TicketsTableRowUpdateLocalizationInput = {
  data: TicketsTableRowUpdateLocalizationDataInput
  locale: Locale
}

export type TicketsTableRowUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TicketsTableRowCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TicketsTableRowUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TicketsTableRowUpsertLocalizationInput>>
}

export type TicketsTableRowUpdateManyInlineInput = {
  /** Create and connect multiple TicketsTableRow component instances */
  create?: InputMaybe<Array<TicketsTableRowCreateWithPositionInput>>
  /** Delete multiple TicketsTableRow documents */
  delete?: InputMaybe<Array<TicketsTableRowWhereUniqueInput>>
  /** Update multiple TicketsTableRow component instances */
  update?: InputMaybe<Array<TicketsTableRowUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TicketsTableRow component instances */
  upsert?: InputMaybe<Array<TicketsTableRowUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TicketsTableRowUpdateManyInput = {
  availableOne?: InputMaybe<Scalars['Boolean']['input']>
  availableThree?: InputMaybe<Scalars['Boolean']['input']>
  availableTwo?: InputMaybe<Scalars['Boolean']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<TicketsTableRowUpdateManyLocalizationsInput>
  /** rowLabel input for default locale (de) */
  rowLabel?: InputMaybe<Scalars['String']['input']>
}

export type TicketsTableRowUpdateManyLocalizationDataInput = {
  rowLabel?: InputMaybe<Scalars['String']['input']>
}

export type TicketsTableRowUpdateManyLocalizationInput = {
  data: TicketsTableRowUpdateManyLocalizationDataInput
  locale: Locale
}

export type TicketsTableRowUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TicketsTableRowUpdateManyLocalizationInput>>
}

export type TicketsTableRowUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TicketsTableRowUpdateManyInput
  /** Document search */
  where: TicketsTableRowWhereInput
}

export type TicketsTableRowUpdateOneInlineInput = {
  /** Create and connect one TicketsTableRow document */
  create?: InputMaybe<TicketsTableRowCreateInput>
  /** Delete currently connected TicketsTableRow document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TicketsTableRow document */
  update?: InputMaybe<TicketsTableRowUpdateWithNestedWhereUniqueInput>
  /** Upsert single TicketsTableRow document */
  upsert?: InputMaybe<TicketsTableRowUpsertWithNestedWhereUniqueInput>
}

export type TicketsTableRowUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TicketsTableRowUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TicketsTableRowWhereUniqueInput
}

export type TicketsTableRowUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TicketsTableRowUpdateInput
  /** Unique document search */
  where: TicketsTableRowWhereUniqueInput
}

export type TicketsTableRowUpsertInput = {
  /** Create document if it didn't exist */
  create: TicketsTableRowCreateInput
  /** Update document if it exists */
  update: TicketsTableRowUpdateInput
}

export type TicketsTableRowUpsertLocalizationInput = {
  create: TicketsTableRowCreateLocalizationDataInput
  locale: Locale
  update: TicketsTableRowUpdateLocalizationDataInput
}

export type TicketsTableRowUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TicketsTableRowUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TicketsTableRowWhereUniqueInput
}

export type TicketsTableRowUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TicketsTableRowUpsertInput
  /** Unique document search */
  where: TicketsTableRowWhereUniqueInput
}

/** Identifies documents */
export type TicketsTableRowWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TicketsTableRowWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  availableOne?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableOne_not?: InputMaybe<Scalars['Boolean']['input']>
  availableThree?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableThree_not?: InputMaybe<Scalars['Boolean']['input']>
  availableTwo?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  availableTwo_not?: InputMaybe<Scalars['Boolean']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  rowLabel?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  rowLabel_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  rowLabel_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  rowLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  rowLabel_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  rowLabel_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  rowLabel_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  rowLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  rowLabel_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  rowLabel_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References TicketsTableRow record uniquely */
export type TicketsTableRowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum TimetableEventType {
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  SideEvent = 'SideEvent',
}

export enum TimetableTabFilterTypes {
  Track = 'track',
  Type = 'type',
}

export enum TimetableViewModes {
  Grid = 'grid',
  List = 'list',
}

/** Base model for all topic pages. */
export type TopicPage = Entity &
  Node & {
    __typename?: 'TopicPage'
    authors: Array<Author>
    categories: Array<TopicPageCategory>
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<TopicPage>
    glossaryRedirect: Array<Glossary>
    /** List of TopicPage versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Intro text displayed at the top of the page. */
    intro: RichText
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<TopicPage>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** Section list Content of the page. */
    sections: Array<TopicPageSection>
    /** Optional configuration if you want to have specific SEO Attributes. */
    seoAttributes?: Maybe<Seo>
    /** Unique identifier for the page used in the URL. */
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    /** Thumbnail image to be displayed when linking eg. on hompage */
    thumbnailImage: Asset
    /** Headline/Title of the page. */
    title: Scalars['String']['output']
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Base model for all topic pages. */
export type TopicPageAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<AuthorOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<AuthorWhereInput>
}

/** Base model for all topic pages. */
export type TopicPageCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TopicPageCategoryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageCategoryWhereInput>
}

/** Base model for all topic pages. */
export type TopicPageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Base model for all topic pages. */
export type TopicPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Base model for all topic pages. */
export type TopicPageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Base model for all topic pages. */
export type TopicPageGlossaryRedirectArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<GlossaryOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<GlossaryWhereInput>
}

/** Base model for all topic pages. */
export type TopicPageHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Base model for all topic pages. */
export type TopicPageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Base model for all topic pages. */
export type TopicPagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Base model for all topic pages. */
export type TopicPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Base model for all topic pages. */
export type TopicPageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Base model for all topic pages. */
export type TopicPageSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TopicPageSectionOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageSectionWhereInput>
}

/** Base model for all topic pages. */
export type TopicPageSeoAttributesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Base model for all topic pages. */
export type TopicPageThumbnailImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Base model for all topic pages. */
export type TopicPageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Base model for all topic pages. */
export type TopicPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Topc page category */
export type TopicPageCategory = Entity &
  Node & {
    __typename?: 'TopicPageCategory'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<TopicPageCategory>
    /** List of TopicPageCategory versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** System Locale field */
    locale: Locale
    /** Get the other localizations for this document */
    localizations: Array<TopicPageCategory>
    name: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    slug: Scalars['String']['output']
    /** System stage field */
    stage: Stage
    topicPages: Array<TopicPage>
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** Topc page category */
export type TopicPageCategoryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Topc page category */
export type TopicPageCategoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Topc page category */
export type TopicPageCategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** Topc page category */
export type TopicPageCategoryHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** Topc page category */
export type TopicPageCategoryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

/** Topc page category */
export type TopicPageCategoryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Topc page category */
export type TopicPageCategoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** Topc page category */
export type TopicPageCategoryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** Topc page category */
export type TopicPageCategoryTopicPagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  orderBy?: InputMaybe<TopicPageOrderByInput>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<TopicPageWhereInput>
}

/** Topc page category */
export type TopicPageCategoryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation
}

/** Topc page category */
export type TopicPageCategoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type TopicPageCategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TopicPageCategoryWhereUniqueInput
}

/** A connection to a list of items. */
export type TopicPageCategoryConnection = {
  __typename?: 'TopicPageCategoryConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TopicPageCategoryEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TopicPageCategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TopicPageCategoryCreateLocalizationsInput>
  /** name input for default locale (de) */
  name: Scalars['String']['input']
  /** slug input for default locale (de) */
  slug: Scalars['String']['input']
  topicPages?: InputMaybe<TopicPageCreateManyInlineInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TopicPageCategoryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  name: Scalars['String']['input']
  slug: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TopicPageCategoryCreateLocalizationInput = {
  /** Localization input */
  data: TopicPageCategoryCreateLocalizationDataInput
  locale: Locale
}

export type TopicPageCategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TopicPageCategoryCreateLocalizationInput>>
}

export type TopicPageCategoryCreateManyInlineInput = {
  /** Connect multiple existing TopicPageCategory documents */
  connect?: InputMaybe<Array<TopicPageCategoryWhereUniqueInput>>
  /** Create and connect multiple existing TopicPageCategory documents */
  create?: InputMaybe<Array<TopicPageCategoryCreateInput>>
}

export type TopicPageCategoryCreateOneInlineInput = {
  /** Connect one existing TopicPageCategory document */
  connect?: InputMaybe<TopicPageCategoryWhereUniqueInput>
  /** Create and connect one TopicPageCategory document */
  create?: InputMaybe<TopicPageCategoryCreateInput>
}

/** An edge in a connection. */
export type TopicPageCategoryEdge = {
  __typename?: 'TopicPageCategoryEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TopicPageCategory
}

/** Identifies documents */
export type TopicPageCategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TopicPageCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<TopicPageCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<TopicPageCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  topicPages_every?: InputMaybe<TopicPageWhereInput>
  topicPages_none?: InputMaybe<TopicPageWhereInput>
  topicPages_some?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum TopicPageCategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TopicPageCategoryUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<TopicPageCategoryUpdateLocalizationsInput>
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars['String']['input']>
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>
  topicPages?: InputMaybe<TopicPageUpdateManyInlineInput>
}

export type TopicPageCategoryUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageCategoryUpdateLocalizationInput = {
  data: TopicPageCategoryUpdateLocalizationDataInput
  locale: Locale
}

export type TopicPageCategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TopicPageCategoryCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TopicPageCategoryUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TopicPageCategoryUpsertLocalizationInput>>
}

export type TopicPageCategoryUpdateManyInlineInput = {
  /** Connect multiple existing TopicPageCategory documents */
  connect?: InputMaybe<Array<TopicPageCategoryConnectInput>>
  /** Create and connect multiple TopicPageCategory documents */
  create?: InputMaybe<Array<TopicPageCategoryCreateInput>>
  /** Delete multiple TopicPageCategory documents */
  delete?: InputMaybe<Array<TopicPageCategoryWhereUniqueInput>>
  /** Disconnect multiple TopicPageCategory documents */
  disconnect?: InputMaybe<Array<TopicPageCategoryWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TopicPageCategory documents */
  set?: InputMaybe<Array<TopicPageCategoryWhereUniqueInput>>
  /** Update multiple TopicPageCategory documents */
  update?: InputMaybe<Array<TopicPageCategoryUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TopicPageCategory documents */
  upsert?: InputMaybe<Array<TopicPageCategoryUpsertWithNestedWhereUniqueInput>>
}

export type TopicPageCategoryUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageCategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TopicPageCategoryUpdateManyInput
  /** Document search */
  where: TopicPageCategoryWhereInput
}

export type TopicPageCategoryUpdateOneInlineInput = {
  /** Connect existing TopicPageCategory document */
  connect?: InputMaybe<TopicPageCategoryWhereUniqueInput>
  /** Create and connect one TopicPageCategory document */
  create?: InputMaybe<TopicPageCategoryCreateInput>
  /** Delete currently connected TopicPageCategory document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TopicPageCategory document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TopicPageCategory document */
  update?: InputMaybe<TopicPageCategoryUpdateWithNestedWhereUniqueInput>
  /** Upsert single TopicPageCategory document */
  upsert?: InputMaybe<TopicPageCategoryUpsertWithNestedWhereUniqueInput>
}

export type TopicPageCategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TopicPageCategoryUpdateInput
  /** Unique document search */
  where: TopicPageCategoryWhereUniqueInput
}

export type TopicPageCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: TopicPageCategoryCreateInput
  /** Update document if it exists */
  update: TopicPageCategoryUpdateInput
}

export type TopicPageCategoryUpsertLocalizationInput = {
  create: TopicPageCategoryCreateLocalizationDataInput
  locale: Locale
  update: TopicPageCategoryUpdateLocalizationDataInput
}

export type TopicPageCategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TopicPageCategoryUpsertInput
  /** Unique document search */
  where: TopicPageCategoryWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type TopicPageCategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type TopicPageCategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageCategoryWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TopicPageCategoryWhereStageInput>
  documentInStages_none?: InputMaybe<TopicPageCategoryWhereStageInput>
  documentInStages_some?: InputMaybe<TopicPageCategoryWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  topicPages_every?: InputMaybe<TopicPageWhereInput>
  topicPages_none?: InputMaybe<TopicPageWhereInput>
  topicPages_some?: InputMaybe<TopicPageWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TopicPageCategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageCategoryWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageCategoryWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageCategoryWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TopicPageCategoryWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References TopicPageCategory record uniquely */
export type TopicPageCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type TopicPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TopicPageWhereUniqueInput
}

/** A connection to a list of items. */
export type TopicPageConnection = {
  __typename?: 'TopicPageConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TopicPageEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TopicPageCreateInput = {
  authors: AuthorCreateManyInlineInput
  categories: TopicPageCategoryCreateManyInlineInput
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  glossaryRedirect?: InputMaybe<GlossaryCreateManyInlineInput>
  /** intro input for default locale (de) */
  intro: Scalars['RichTextAST']['input']
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TopicPageCreateLocalizationsInput>
  sections?: InputMaybe<TopicPageSectionCreateManyInlineInput>
  seoAttributes?: InputMaybe<SeoCreateOneInlineInput>
  /** slug input for default locale (de) */
  slug: Scalars['String']['input']
  thumbnailImage: AssetCreateOneInlineInput
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TopicPageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  intro: Scalars['RichTextAST']['input']
  slug: Scalars['String']['input']
  title: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type TopicPageCreateLocalizationInput = {
  /** Localization input */
  data: TopicPageCreateLocalizationDataInput
  locale: Locale
}

export type TopicPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TopicPageCreateLocalizationInput>>
}

export type TopicPageCreateManyInlineInput = {
  /** Connect multiple existing TopicPage documents */
  connect?: InputMaybe<Array<TopicPageWhereUniqueInput>>
  /** Create and connect multiple existing TopicPage documents */
  create?: InputMaybe<Array<TopicPageCreateInput>>
}

export type TopicPageCreateOneInlineInput = {
  /** Connect one existing TopicPage document */
  connect?: InputMaybe<TopicPageWhereUniqueInput>
  /** Create and connect one TopicPage document */
  create?: InputMaybe<TopicPageCreateInput>
}

/** An edge in a connection. */
export type TopicPageEdge = {
  __typename?: 'TopicPageEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TopicPage
}

/** Identifies documents */
export type TopicPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  authors_every?: InputMaybe<AuthorWhereInput>
  authors_none?: InputMaybe<AuthorWhereInput>
  authors_some?: InputMaybe<AuthorWhereInput>
  categories_every?: InputMaybe<TopicPageCategoryWhereInput>
  categories_none?: InputMaybe<TopicPageCategoryWhereInput>
  categories_some?: InputMaybe<TopicPageCategoryWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TopicPageWhereStageInput>
  documentInStages_none?: InputMaybe<TopicPageWhereStageInput>
  documentInStages_some?: InputMaybe<TopicPageWhereStageInput>
  glossaryRedirect_every?: InputMaybe<GlossaryWhereInput>
  glossaryRedirect_none?: InputMaybe<GlossaryWhereInput>
  glossaryRedirect_some?: InputMaybe<GlossaryWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  sections_every?: InputMaybe<TopicPageSectionWhereInput>
  sections_none?: InputMaybe<TopicPageSectionWhereInput>
  sections_some?: InputMaybe<TopicPageSectionWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  thumbnailImage?: InputMaybe<AssetWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum TopicPageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Section component for topic pages. */
export type TopicPageSection = Entity & {
  __typename?: 'TopicPageSection'
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Content structure build upon multiple content components such as richt text, references, etc. */
  layout: Array<TopicPageSectionlayoutUnion>
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<TopicPageSection>
  /** System stage field */
  stage: Stage
  /** Title/headline leading each section. Also used for generating table of content entries. */
  title: Scalars['String']['output']
}

/** Section component for topic pages. */
export type TopicPageSectionLayoutArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
}

/** Section component for topic pages. */
export type TopicPageSectionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type TopicPageSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: TopicPageSectionWhereUniqueInput
}

/** A connection to a list of items. */
export type TopicPageSectionConnection = {
  __typename?: 'TopicPageSectionConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<TopicPageSectionEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type TopicPageSectionCreateInput = {
  layout?: InputMaybe<TopicPageSectionlayoutUnionCreateManyInlineInput>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TopicPageSectionCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
}

export type TopicPageSectionCreateLocalizationDataInput = {
  title: Scalars['String']['input']
}

export type TopicPageSectionCreateLocalizationInput = {
  /** Localization input */
  data: TopicPageSectionCreateLocalizationDataInput
  locale: Locale
}

export type TopicPageSectionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TopicPageSectionCreateLocalizationInput>>
}

export type TopicPageSectionCreateManyInlineInput = {
  /** Create and connect multiple existing TopicPageSection documents */
  create?: InputMaybe<Array<TopicPageSectionCreateInput>>
}

export type TopicPageSectionCreateOneInlineInput = {
  /** Create and connect one TopicPageSection document */
  create?: InputMaybe<TopicPageSectionCreateInput>
}

export type TopicPageSectionCreateWithPositionInput = {
  /** Document to create */
  data: TopicPageSectionCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type TopicPageSectionEdge = {
  __typename?: 'TopicPageSectionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: TopicPageSection
}

/** Identifies documents */
export type TopicPageSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  layout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  layout_some?: InputMaybe<TopicPageSectionlayoutUnionWhereInput>
}

export enum TopicPageSectionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TopicPageSectionParent = TopicPage

export type TopicPageSectionParentConnectInput = {
  TopicPage?: InputMaybe<TopicPageConnectInput>
}

export type TopicPageSectionParentCreateInput = {
  TopicPage?: InputMaybe<TopicPageCreateInput>
}

export type TopicPageSectionParentCreateManyInlineInput = {
  /** Connect multiple existing TopicPageSectionParent documents */
  connect?: InputMaybe<Array<TopicPageSectionParentWhereUniqueInput>>
  /** Create and connect multiple existing TopicPageSectionParent documents */
  create?: InputMaybe<Array<TopicPageSectionParentCreateInput>>
}

export type TopicPageSectionParentCreateOneInlineInput = {
  /** Connect one existing TopicPageSectionParent document */
  connect?: InputMaybe<TopicPageSectionParentWhereUniqueInput>
  /** Create and connect one TopicPageSectionParent document */
  create?: InputMaybe<TopicPageSectionParentCreateInput>
}

export type TopicPageSectionParentUpdateInput = {
  TopicPage?: InputMaybe<TopicPageUpdateInput>
}

export type TopicPageSectionParentUpdateManyInlineInput = {
  /** Connect multiple existing TopicPageSectionParent documents */
  connect?: InputMaybe<Array<TopicPageSectionParentConnectInput>>
  /** Create and connect multiple TopicPageSectionParent documents */
  create?: InputMaybe<Array<TopicPageSectionParentCreateInput>>
  /** Delete multiple TopicPageSectionParent documents */
  delete?: InputMaybe<Array<TopicPageSectionParentWhereUniqueInput>>
  /** Disconnect multiple TopicPageSectionParent documents */
  disconnect?: InputMaybe<Array<TopicPageSectionParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TopicPageSectionParent documents */
  set?: InputMaybe<Array<TopicPageSectionParentWhereUniqueInput>>
  /** Update multiple TopicPageSectionParent documents */
  update?: InputMaybe<Array<TopicPageSectionParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TopicPageSectionParent documents */
  upsert?: InputMaybe<Array<TopicPageSectionParentUpsertWithNestedWhereUniqueInput>>
}

export type TopicPageSectionParentUpdateManyWithNestedWhereInput = {
  TopicPage?: InputMaybe<TopicPageUpdateManyWithNestedWhereInput>
}

export type TopicPageSectionParentUpdateOneInlineInput = {
  /** Connect existing TopicPageSectionParent document */
  connect?: InputMaybe<TopicPageSectionParentWhereUniqueInput>
  /** Create and connect one TopicPageSectionParent document */
  create?: InputMaybe<TopicPageSectionParentCreateInput>
  /** Delete currently connected TopicPageSectionParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TopicPageSectionParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TopicPageSectionParent document */
  update?: InputMaybe<TopicPageSectionParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single TopicPageSectionParent document */
  upsert?: InputMaybe<TopicPageSectionParentUpsertWithNestedWhereUniqueInput>
}

export type TopicPageSectionParentUpdateWithNestedWhereUniqueInput = {
  TopicPage?: InputMaybe<TopicPageUpdateWithNestedWhereUniqueInput>
}

export type TopicPageSectionParentUpsertWithNestedWhereUniqueInput = {
  TopicPage?: InputMaybe<TopicPageUpsertWithNestedWhereUniqueInput>
}

export type TopicPageSectionParentWhereInput = {
  TopicPage?: InputMaybe<TopicPageWhereInput>
}

export type TopicPageSectionParentWhereUniqueInput = {
  TopicPage?: InputMaybe<TopicPageWhereUniqueInput>
}

export type TopicPageSectionUpdateInput = {
  layout?: InputMaybe<TopicPageSectionlayoutUnionUpdateManyInlineInput>
  /** Manage document localizations */
  localizations?: InputMaybe<TopicPageSectionUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageSectionUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageSectionUpdateLocalizationInput = {
  data: TopicPageSectionUpdateLocalizationDataInput
  locale: Locale
}

export type TopicPageSectionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TopicPageSectionCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TopicPageSectionUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TopicPageSectionUpsertLocalizationInput>>
}

export type TopicPageSectionUpdateManyInlineInput = {
  /** Create and connect multiple TopicPageSection component instances */
  create?: InputMaybe<Array<TopicPageSectionCreateWithPositionInput>>
  /** Delete multiple TopicPageSection documents */
  delete?: InputMaybe<Array<TopicPageSectionWhereUniqueInput>>
  /** Update multiple TopicPageSection component instances */
  update?: InputMaybe<Array<TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TopicPageSection component instances */
  upsert?: InputMaybe<Array<TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TopicPageSectionUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<TopicPageSectionUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageSectionUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageSectionUpdateManyLocalizationInput = {
  data: TopicPageSectionUpdateManyLocalizationDataInput
  locale: Locale
}

export type TopicPageSectionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TopicPageSectionUpdateManyLocalizationInput>>
}

export type TopicPageSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TopicPageSectionUpdateManyInput
  /** Document search */
  where: TopicPageSectionWhereInput
}

export type TopicPageSectionUpdateOneInlineInput = {
  /** Create and connect one TopicPageSection document */
  create?: InputMaybe<TopicPageSectionCreateInput>
  /** Delete currently connected TopicPageSection document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TopicPageSection document */
  update?: InputMaybe<TopicPageSectionUpdateWithNestedWhereUniqueInput>
  /** Upsert single TopicPageSection document */
  upsert?: InputMaybe<TopicPageSectionUpsertWithNestedWhereUniqueInput>
}

export type TopicPageSectionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TopicPageSectionUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TopicPageSectionWhereUniqueInput
}

export type TopicPageSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TopicPageSectionUpdateInput
  /** Unique document search */
  where: TopicPageSectionWhereUniqueInput
}

export type TopicPageSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: TopicPageSectionCreateInput
  /** Update document if it exists */
  update: TopicPageSectionUpdateInput
}

export type TopicPageSectionUpsertLocalizationInput = {
  create: TopicPageSectionCreateLocalizationDataInput
  locale: Locale
  update: TopicPageSectionUpdateLocalizationDataInput
}

export type TopicPageSectionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TopicPageSectionUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: TopicPageSectionWhereUniqueInput
}

export type TopicPageSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TopicPageSectionUpsertInput
  /** Unique document search */
  where: TopicPageSectionWhereUniqueInput
}

/** Identifies documents */
export type TopicPageSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageSectionWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values in which the union is empty. */
  layout_empty?: InputMaybe<Scalars['Boolean']['input']>
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  layout_some?: InputMaybe<TopicPageSectionlayoutUnionWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References TopicPageSection record uniquely */
export type TopicPageSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type TopicPageSectionlayoutUnion = RichContent | RichTextMedia | SectionCollapsedRichContent | SectionContentReference | SectionQuote

export type TopicPageSectionlayoutUnionConnectInput = {
  RichContent?: InputMaybe<RichContentConnectInput>
  RichTextMedia?: InputMaybe<RichTextMediaConnectInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentConnectInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceConnectInput>
  SectionQuote?: InputMaybe<SectionQuoteConnectInput>
}

export type TopicPageSectionlayoutUnionCreateInput = {
  RichContent?: InputMaybe<RichContentCreateInput>
  RichTextMedia?: InputMaybe<RichTextMediaCreateInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentCreateInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceCreateInput>
  SectionQuote?: InputMaybe<SectionQuoteCreateInput>
}

export type TopicPageSectionlayoutUnionCreateManyInlineInput = {
  /** Create and connect multiple existing TopicPageSectionlayoutUnion documents */
  create?: InputMaybe<Array<TopicPageSectionlayoutUnionCreateInput>>
}

export type TopicPageSectionlayoutUnionCreateOneInlineInput = {
  /** Create and connect one TopicPageSectionlayoutUnion document */
  create?: InputMaybe<TopicPageSectionlayoutUnionCreateInput>
}

export type TopicPageSectionlayoutUnionCreateWithPositionInput = {
  RichContent?: InputMaybe<RichContentCreateWithPositionInput>
  RichTextMedia?: InputMaybe<RichTextMediaCreateWithPositionInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentCreateWithPositionInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceCreateWithPositionInput>
  SectionQuote?: InputMaybe<SectionQuoteCreateWithPositionInput>
}

export type TopicPageSectionlayoutUnionUpdateInput = {
  RichContent?: InputMaybe<RichContentUpdateInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpdateInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpdateInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpdateInput>
  SectionQuote?: InputMaybe<SectionQuoteUpdateInput>
}

export type TopicPageSectionlayoutUnionUpdateManyInlineInput = {
  /** Create and connect multiple TopicPageSectionlayoutUnion component instances */
  create?: InputMaybe<Array<TopicPageSectionlayoutUnionCreateWithPositionInput>>
  /** Delete multiple TopicPageSectionlayoutUnion documents */
  delete?: InputMaybe<Array<TopicPageSectionlayoutUnionWhereUniqueInput>>
  /** Update multiple TopicPageSectionlayoutUnion component instances */
  update?: InputMaybe<Array<TopicPageSectionlayoutUnionUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple TopicPageSectionlayoutUnion component instances */
  upsert?: InputMaybe<Array<TopicPageSectionlayoutUnionUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type TopicPageSectionlayoutUnionUpdateManyWithNestedWhereInput = {
  RichContent?: InputMaybe<RichContentUpdateManyWithNestedWhereInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpdateManyWithNestedWhereInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpdateManyWithNestedWhereInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpdateManyWithNestedWhereInput>
  SectionQuote?: InputMaybe<SectionQuoteUpdateManyWithNestedWhereInput>
}

export type TopicPageSectionlayoutUnionUpdateOneInlineInput = {
  /** Create and connect one TopicPageSectionlayoutUnion document */
  create?: InputMaybe<TopicPageSectionlayoutUnionCreateInput>
  /** Delete currently connected TopicPageSectionlayoutUnion document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TopicPageSectionlayoutUnion document */
  update?: InputMaybe<TopicPageSectionlayoutUnionUpdateWithNestedWhereUniqueInput>
  /** Upsert single TopicPageSectionlayoutUnion document */
  upsert?: InputMaybe<TopicPageSectionlayoutUnionUpsertWithNestedWhereUniqueInput>
}

export type TopicPageSectionlayoutUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  RichContent?: InputMaybe<RichContentUpdateWithNestedWhereUniqueAndPositionInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpdateWithNestedWhereUniqueAndPositionInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpdateWithNestedWhereUniqueAndPositionInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpdateWithNestedWhereUniqueAndPositionInput>
  SectionQuote?: InputMaybe<SectionQuoteUpdateWithNestedWhereUniqueAndPositionInput>
}

export type TopicPageSectionlayoutUnionUpdateWithNestedWhereUniqueInput = {
  RichContent?: InputMaybe<RichContentUpdateWithNestedWhereUniqueInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpdateWithNestedWhereUniqueInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpdateWithNestedWhereUniqueInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpdateWithNestedWhereUniqueInput>
  SectionQuote?: InputMaybe<SectionQuoteUpdateWithNestedWhereUniqueInput>
}

export type TopicPageSectionlayoutUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  RichContent?: InputMaybe<RichContentUpsertWithNestedWhereUniqueAndPositionInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpsertWithNestedWhereUniqueAndPositionInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpsertWithNestedWhereUniqueAndPositionInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpsertWithNestedWhereUniqueAndPositionInput>
  SectionQuote?: InputMaybe<SectionQuoteUpsertWithNestedWhereUniqueAndPositionInput>
}

export type TopicPageSectionlayoutUnionUpsertWithNestedWhereUniqueInput = {
  RichContent?: InputMaybe<RichContentUpsertWithNestedWhereUniqueInput>
  RichTextMedia?: InputMaybe<RichTextMediaUpsertWithNestedWhereUniqueInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentUpsertWithNestedWhereUniqueInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceUpsertWithNestedWhereUniqueInput>
  SectionQuote?: InputMaybe<SectionQuoteUpsertWithNestedWhereUniqueInput>
}

export type TopicPageSectionlayoutUnionWhereInput = {
  RichContent?: InputMaybe<RichContentWhereInput>
  RichTextMedia?: InputMaybe<RichTextMediaWhereInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentWhereInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceWhereInput>
  SectionQuote?: InputMaybe<SectionQuoteWhereInput>
}

export type TopicPageSectionlayoutUnionWhereUniqueInput = {
  RichContent?: InputMaybe<RichContentWhereUniqueInput>
  RichTextMedia?: InputMaybe<RichTextMediaWhereUniqueInput>
  SectionCollapsedRichContent?: InputMaybe<SectionCollapsedRichContentWhereUniqueInput>
  SectionContentReference?: InputMaybe<SectionContentReferenceWhereUniqueInput>
  SectionQuote?: InputMaybe<SectionQuoteWhereUniqueInput>
}

export type TopicPageUpdateInput = {
  authors?: InputMaybe<AuthorUpdateManyInlineInput>
  categories?: InputMaybe<TopicPageCategoryUpdateManyInlineInput>
  glossaryRedirect?: InputMaybe<GlossaryUpdateManyInlineInput>
  /** intro input for default locale (de) */
  intro?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<TopicPageUpdateLocalizationsInput>
  sections?: InputMaybe<TopicPageSectionUpdateManyInlineInput>
  seoAttributes?: InputMaybe<SeoUpdateOneInlineInput>
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars['String']['input']>
  thumbnailImage?: InputMaybe<AssetUpdateOneInlineInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageUpdateLocalizationDataInput = {
  intro?: InputMaybe<Scalars['RichTextAST']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageUpdateLocalizationInput = {
  data: TopicPageUpdateLocalizationDataInput
  locale: Locale
}

export type TopicPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TopicPageCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<TopicPageUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<TopicPageUpsertLocalizationInput>>
}

export type TopicPageUpdateManyInlineInput = {
  /** Connect multiple existing TopicPage documents */
  connect?: InputMaybe<Array<TopicPageConnectInput>>
  /** Create and connect multiple TopicPage documents */
  create?: InputMaybe<Array<TopicPageCreateInput>>
  /** Delete multiple TopicPage documents */
  delete?: InputMaybe<Array<TopicPageWhereUniqueInput>>
  /** Disconnect multiple TopicPage documents */
  disconnect?: InputMaybe<Array<TopicPageWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing TopicPage documents */
  set?: InputMaybe<Array<TopicPageWhereUniqueInput>>
  /** Update multiple TopicPage documents */
  update?: InputMaybe<Array<TopicPageUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple TopicPage documents */
  upsert?: InputMaybe<Array<TopicPageUpsertWithNestedWhereUniqueInput>>
}

export type TopicPageUpdateManyInput = {
  /** intro input for default locale (de) */
  intro?: InputMaybe<Scalars['RichTextAST']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<TopicPageUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageUpdateManyLocalizationDataInput = {
  intro?: InputMaybe<Scalars['RichTextAST']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type TopicPageUpdateManyLocalizationInput = {
  data: TopicPageUpdateManyLocalizationDataInput
  locale: Locale
}

export type TopicPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TopicPageUpdateManyLocalizationInput>>
}

export type TopicPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TopicPageUpdateManyInput
  /** Document search */
  where: TopicPageWhereInput
}

export type TopicPageUpdateOneInlineInput = {
  /** Connect existing TopicPage document */
  connect?: InputMaybe<TopicPageWhereUniqueInput>
  /** Create and connect one TopicPage document */
  create?: InputMaybe<TopicPageCreateInput>
  /** Delete currently connected TopicPage document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected TopicPage document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single TopicPage document */
  update?: InputMaybe<TopicPageUpdateWithNestedWhereUniqueInput>
  /** Upsert single TopicPage document */
  upsert?: InputMaybe<TopicPageUpsertWithNestedWhereUniqueInput>
}

export type TopicPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TopicPageUpdateInput
  /** Unique document search */
  where: TopicPageWhereUniqueInput
}

export type TopicPageUpsertInput = {
  /** Create document if it didn't exist */
  create: TopicPageCreateInput
  /** Update document if it exists */
  update: TopicPageUpdateInput
}

export type TopicPageUpsertLocalizationInput = {
  create: TopicPageCreateLocalizationDataInput
  locale: Locale
  update: TopicPageUpdateLocalizationDataInput
}

export type TopicPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TopicPageUpsertInput
  /** Unique document search */
  where: TopicPageWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type TopicPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type TopicPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  authors_every?: InputMaybe<AuthorWhereInput>
  authors_none?: InputMaybe<AuthorWhereInput>
  authors_some?: InputMaybe<AuthorWhereInput>
  categories_every?: InputMaybe<TopicPageCategoryWhereInput>
  categories_none?: InputMaybe<TopicPageCategoryWhereInput>
  categories_some?: InputMaybe<TopicPageCategoryWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<TopicPageWhereStageInput>
  documentInStages_none?: InputMaybe<TopicPageWhereStageInput>
  documentInStages_some?: InputMaybe<TopicPageWhereStageInput>
  glossaryRedirect_every?: InputMaybe<GlossaryWhereInput>
  glossaryRedirect_none?: InputMaybe<GlossaryWhereInput>
  glossaryRedirect_some?: InputMaybe<GlossaryWhereInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  sections_every?: InputMaybe<TopicPageSectionWhereInput>
  sections_none?: InputMaybe<TopicPageSectionWhereInput>
  sections_some?: InputMaybe<TopicPageSectionWhereInput>
  seoAttributes?: InputMaybe<SeoWhereInput>
  slug?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']['input']>
  thumbnailImage?: InputMaybe<AssetWhereInput>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TopicPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicPageWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicPageWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicPageWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TopicPageWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References TopicPage record uniquely */
export type TopicPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpy = RecommendationFood | RecommendationNightOut

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyConnectInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodConnectInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutConnectInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodCreateInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutCreateInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateManyInlineInput = {
  /** Connect multiple existing Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  connect?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>>
  /** Create and connect multiple existing Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  create?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateInput>>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateOneInlineInput = {
  /** Connect one existing Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  connect?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>
  /** Create and connect one Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  create?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodUpdateInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutUpdateInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateManyInlineInput = {
  /** Connect multiple existing Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  connect?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyConnectInput>>
  /** Create and connect multiple Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  create?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateInput>>
  /** Delete multiple Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  delete?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>>
  /** Disconnect multiple Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  disconnect?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  set?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>>
  /** Update multiple Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  update?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Union_recommendations_clgkwf1vg03eq01ug96koewpy documents */
  upsert?: InputMaybe<Array<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpsertWithNestedWhereUniqueInput>>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateManyWithNestedWhereInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodUpdateManyWithNestedWhereInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutUpdateManyWithNestedWhereInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateOneInlineInput = {
  /** Connect existing Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  connect?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput>
  /** Create and connect one Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  create?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyCreateInput>
  /** Delete currently connected Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  update?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateWithNestedWhereUniqueInput>
  /** Upsert single Union_recommendations_clgkwf1vg03eq01ug96koewpy document */
  upsert?: InputMaybe<Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpsertWithNestedWhereUniqueInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpdateWithNestedWhereUniqueInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodUpdateWithNestedWhereUniqueInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutUpdateWithNestedWhereUniqueInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyUpsertWithNestedWhereUniqueInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodUpsertWithNestedWhereUniqueInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutUpsertWithNestedWhereUniqueInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodWhereInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutWhereInput>
}

export type Union_Recommendations_Clgkwf1vg03eq01ug96koewpyWhereUniqueInput = {
  RecommendationFood?: InputMaybe<RecommendationFoodWhereUniqueInput>
  RecommendationNightOut?: InputMaybe<RecommendationNightOutWhereUniqueInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9e =
  | Article
  | ContentReference
  | EducationReport
  | EducationSeminar
  | PodcastEpisode
  | ReviewsProduct

export type Union_References_Cm0qshprp123q07te06v2bu9eConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>
  ContentReference?: InputMaybe<ContentReferenceConnectInput>
  EducationReport?: InputMaybe<EducationReportConnectInput>
  EducationSeminar?: InputMaybe<EducationSeminarConnectInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeConnectInput>
  ReviewsProduct?: InputMaybe<ReviewsProductConnectInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>
  ContentReference?: InputMaybe<ContentReferenceCreateInput>
  EducationReport?: InputMaybe<EducationReportCreateInput>
  EducationSeminar?: InputMaybe<EducationSeminarCreateInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeCreateInput>
  ReviewsProduct?: InputMaybe<ReviewsProductCreateInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eCreateManyInlineInput = {
  /** Connect multiple existing Union_references_cm0qshprp123q07te06v2bu9e documents */
  connect?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>>
  /** Create and connect multiple existing Union_references_cm0qshprp123q07te06v2bu9e documents */
  create?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eCreateInput>>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eCreateOneInlineInput = {
  /** Connect one existing Union_references_cm0qshprp123q07te06v2bu9e document */
  connect?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>
  /** Create and connect one Union_references_cm0qshprp123q07te06v2bu9e document */
  create?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eCreateInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>
  ContentReference?: InputMaybe<ContentReferenceUpdateInput>
  EducationReport?: InputMaybe<EducationReportUpdateInput>
  EducationSeminar?: InputMaybe<EducationSeminarUpdateInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeUpdateInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpdateManyInlineInput = {
  /** Connect multiple existing Union_references_cm0qshprp123q07te06v2bu9e documents */
  connect?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eConnectInput>>
  /** Create and connect multiple Union_references_cm0qshprp123q07te06v2bu9e documents */
  create?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eCreateInput>>
  /** Delete multiple Union_references_cm0qshprp123q07te06v2bu9e documents */
  delete?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>>
  /** Disconnect multiple Union_references_cm0qshprp123q07te06v2bu9e documents */
  disconnect?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing Union_references_cm0qshprp123q07te06v2bu9e documents */
  set?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>>
  /** Update multiple Union_references_cm0qshprp123q07te06v2bu9e documents */
  update?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple Union_references_cm0qshprp123q07te06v2bu9e documents */
  upsert?: InputMaybe<Array<Union_References_Cm0qshprp123q07te06v2bu9eUpsertWithNestedWhereUniqueInput>>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>
  ContentReference?: InputMaybe<ContentReferenceUpdateManyWithNestedWhereInput>
  EducationReport?: InputMaybe<EducationReportUpdateManyWithNestedWhereInput>
  EducationSeminar?: InputMaybe<EducationSeminarUpdateManyWithNestedWhereInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeUpdateManyWithNestedWhereInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateManyWithNestedWhereInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpdateOneInlineInput = {
  /** Connect existing Union_references_cm0qshprp123q07te06v2bu9e document */
  connect?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput>
  /** Create and connect one Union_references_cm0qshprp123q07te06v2bu9e document */
  create?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eCreateInput>
  /** Delete currently connected Union_references_cm0qshprp123q07te06v2bu9e document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected Union_references_cm0qshprp123q07te06v2bu9e document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single Union_references_cm0qshprp123q07te06v2bu9e document */
  update?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eUpdateWithNestedWhereUniqueInput>
  /** Upsert single Union_references_cm0qshprp123q07te06v2bu9e document */
  upsert?: InputMaybe<Union_References_Cm0qshprp123q07te06v2bu9eUpsertWithNestedWhereUniqueInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>
  ContentReference?: InputMaybe<ContentReferenceUpdateWithNestedWhereUniqueInput>
  EducationReport?: InputMaybe<EducationReportUpdateWithNestedWhereUniqueInput>
  EducationSeminar?: InputMaybe<EducationSeminarUpdateWithNestedWhereUniqueInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeUpdateWithNestedWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpdateWithNestedWhereUniqueInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>
  ContentReference?: InputMaybe<ContentReferenceUpsertWithNestedWhereUniqueInput>
  EducationReport?: InputMaybe<EducationReportUpsertWithNestedWhereUniqueInput>
  EducationSeminar?: InputMaybe<EducationSeminarUpsertWithNestedWhereUniqueInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeUpsertWithNestedWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductUpsertWithNestedWhereUniqueInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>
  ContentReference?: InputMaybe<ContentReferenceWhereInput>
  EducationReport?: InputMaybe<EducationReportWhereInput>
  EducationSeminar?: InputMaybe<EducationSeminarWhereInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeWhereInput>
  ReviewsProduct?: InputMaybe<ReviewsProductWhereInput>
}

export type Union_References_Cm0qshprp123q07te06v2bu9eWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>
  ContentReference?: InputMaybe<ContentReferenceWhereUniqueInput>
  EducationReport?: InputMaybe<EducationReportWhereUniqueInput>
  EducationSeminar?: InputMaybe<EducationSeminarWhereUniqueInput>
  PodcastEpisode?: InputMaybe<PodcastEpisodeWhereUniqueInput>
  ReviewsProduct?: InputMaybe<ReviewsProductWhereUniqueInput>
}

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>
}

/** User system model */
export type User = Entity &
  Node & {
    __typename?: 'User'
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** Get the document in other stages */
    documentInStages: Array<User>
    /** The unique identifier */
    id: Scalars['ID']['output']
    /** Flag to determine if user is active or not */
    isActive: Scalars['Boolean']['output']
    /** User Kind. Can be either MEMBER, PAT or PUBLIC */
    kind: UserKind
    /** The username */
    name: Scalars['String']['output']
    /** Profile Picture url */
    picture?: Maybe<Scalars['String']['output']>
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
  }

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: UserWhereUniqueInput
}

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<UserEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>
}

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: User
}

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK',
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  documentInStages_every?: InputMaybe<UserWhereStageInput>
  documentInStages_none?: InputMaybe<UserWhereStageInput>
  documentInStages_some?: InputMaybe<UserWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>
  kind?: InputMaybe<UserKind>
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
}

export enum UserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>
}

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  documentInStages_every?: InputMaybe<UserWhereStageInput>
  documentInStages_none?: InputMaybe<UserWhereStageInput>
  documentInStages_some?: InputMaybe<UserWhereStageInput>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']['input']>
  kind?: InputMaybe<UserKind>
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>
  name?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']['input']>
  picture?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

/** VG Wort integration data */
export type VgWort = Entity &
  Node & {
    __typename?: 'VGWort'
    article: Article
    /** The time the document was created */
    createdAt: Scalars['DateTime']['output']
    /** User that created this document */
    createdBy?: Maybe<User>
    /** Get the document in other stages */
    documentInStages: Array<VgWort>
    domain: Scalars['String']['output']
    /** List of VGWort versions */
    history: Array<Version>
    /** The unique identifier */
    id: Scalars['ID']['output']
    orderDateTime: Scalars['DateTime']['output']
    privateIdentificationId: Scalars['String']['output']
    publicIdentificationId: Scalars['String']['output']
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']['output']>
    /** User that last published this document */
    publishedBy?: Maybe<User>
    scheduledIn: Array<ScheduledOperation>
    /** System stage field */
    stage: Stage
    /** The time the document was updated */
    updatedAt: Scalars['DateTime']['output']
    /** User that last updated this document */
    updatedBy?: Maybe<User>
  }

/** VG Wort integration data */
export type VgWortArticleArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** VG Wort integration data */
export type VgWortCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** VG Wort integration data */
export type VgWortDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  inheritLocale?: Scalars['Boolean']['input']
  stages?: Array<Stage>
}

/** VG Wort integration data */
export type VgWortHistoryArgs = {
  limit?: Scalars['Int']['input']
  skip?: Scalars['Int']['input']
  stageOverride?: InputMaybe<Stage>
}

/** VG Wort integration data */
export type VgWortPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

/** VG Wort integration data */
export type VgWortScheduledInArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  locales?: InputMaybe<Array<Locale>>
  skip?: InputMaybe<Scalars['Int']['input']>
  where?: InputMaybe<ScheduledOperationWhereInput>
}

/** VG Wort integration data */
export type VgWortUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']['input']>
  locales?: InputMaybe<Array<Locale>>
}

export type VgWortConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: VgWortWhereUniqueInput
}

/** A connection to a list of items. */
export type VgWortConnection = {
  __typename?: 'VGWortConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<VgWortEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type VgWortCreateInput = {
  article: ArticleCreateOneInlineInput
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  domain: Scalars['String']['input']
  orderDateTime: Scalars['DateTime']['input']
  privateIdentificationId: Scalars['String']['input']
  publicIdentificationId: Scalars['String']['input']
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type VgWortCreateManyInlineInput = {
  /** Connect multiple existing VGWort documents */
  connect?: InputMaybe<Array<VgWortWhereUniqueInput>>
  /** Create and connect multiple existing VGWort documents */
  create?: InputMaybe<Array<VgWortCreateInput>>
}

export type VgWortCreateOneInlineInput = {
  /** Connect one existing VGWort document */
  connect?: InputMaybe<VgWortWhereUniqueInput>
  /** Create and connect one VGWort document */
  create?: InputMaybe<VgWortCreateInput>
}

/** An edge in a connection. */
export type VgWortEdge = {
  __typename?: 'VGWortEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: VgWort
}

/** Identifies documents */
export type VgWortManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VgWortWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VgWortWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VgWortWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<VgWortWhereStageInput>
  documentInStages_none?: InputMaybe<VgWortWhereStageInput>
  documentInStages_some?: InputMaybe<VgWortWhereStageInput>
  domain?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  domain_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  domain_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  domain_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  domain_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  domain_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  domain_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  domain_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  domain_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  domain_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  orderDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  orderDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  orderDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  orderDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  orderDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  orderDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  orderDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  orderDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  privateIdentificationId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  privateIdentificationId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  privateIdentificationId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  privateIdentificationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  privateIdentificationId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  privateIdentificationId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  privateIdentificationId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  privateIdentificationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  privateIdentificationId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  privateIdentificationId_starts_with?: InputMaybe<Scalars['String']['input']>
  publicIdentificationId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  publicIdentificationId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  publicIdentificationId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  publicIdentificationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  publicIdentificationId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  publicIdentificationId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  publicIdentificationId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  publicIdentificationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  publicIdentificationId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  publicIdentificationId_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

export enum VgWortOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DomainAsc = 'domain_ASC',
  DomainDesc = 'domain_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OrderDateTimeAsc = 'orderDateTime_ASC',
  OrderDateTimeDesc = 'orderDateTime_DESC',
  PrivateIdentificationIdAsc = 'privateIdentificationId_ASC',
  PrivateIdentificationIdDesc = 'privateIdentificationId_DESC',
  PublicIdentificationIdAsc = 'publicIdentificationId_ASC',
  PublicIdentificationIdDesc = 'publicIdentificationId_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type VgWortUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneInlineInput>
  domain?: InputMaybe<Scalars['String']['input']>
  orderDateTime?: InputMaybe<Scalars['DateTime']['input']>
  privateIdentificationId?: InputMaybe<Scalars['String']['input']>
  publicIdentificationId?: InputMaybe<Scalars['String']['input']>
}

export type VgWortUpdateManyInlineInput = {
  /** Connect multiple existing VGWort documents */
  connect?: InputMaybe<Array<VgWortConnectInput>>
  /** Create and connect multiple VGWort documents */
  create?: InputMaybe<Array<VgWortCreateInput>>
  /** Delete multiple VGWort documents */
  delete?: InputMaybe<Array<VgWortWhereUniqueInput>>
  /** Disconnect multiple VGWort documents */
  disconnect?: InputMaybe<Array<VgWortWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing VGWort documents */
  set?: InputMaybe<Array<VgWortWhereUniqueInput>>
  /** Update multiple VGWort documents */
  update?: InputMaybe<Array<VgWortUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple VGWort documents */
  upsert?: InputMaybe<Array<VgWortUpsertWithNestedWhereUniqueInput>>
}

export type VgWortUpdateManyInput = {
  domain?: InputMaybe<Scalars['String']['input']>
  orderDateTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type VgWortUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VgWortUpdateManyInput
  /** Document search */
  where: VgWortWhereInput
}

export type VgWortUpdateOneInlineInput = {
  /** Connect existing VGWort document */
  connect?: InputMaybe<VgWortWhereUniqueInput>
  /** Create and connect one VGWort document */
  create?: InputMaybe<VgWortCreateInput>
  /** Delete currently connected VGWort document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected VGWort document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single VGWort document */
  update?: InputMaybe<VgWortUpdateWithNestedWhereUniqueInput>
  /** Upsert single VGWort document */
  upsert?: InputMaybe<VgWortUpsertWithNestedWhereUniqueInput>
}

export type VgWortUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VgWortUpdateInput
  /** Unique document search */
  where: VgWortWhereUniqueInput
}

export type VgWortUpsertInput = {
  /** Create document if it didn't exist */
  create: VgWortCreateInput
  /** Update document if it exists */
  update: VgWortUpdateInput
}

export type VgWortUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VgWortUpsertInput
  /** Unique document search */
  where: VgWortWhereUniqueInput
}

/** This contains a set of filters that can be used to compare values internally */
export type VgWortWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']['input']>
}

/** Identifies documents */
export type VgWortWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VgWortWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VgWortWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VgWortWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  article?: InputMaybe<ArticleWhereInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  createdBy?: InputMaybe<UserWhereInput>
  documentInStages_every?: InputMaybe<VgWortWhereStageInput>
  documentInStages_none?: InputMaybe<VgWortWhereStageInput>
  documentInStages_some?: InputMaybe<VgWortWhereStageInput>
  domain?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  domain_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  domain_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  domain_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  domain_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  domain_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  domain_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  domain_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  domain_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  domain_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  orderDateTime?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  orderDateTime_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  orderDateTime_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  orderDateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  orderDateTime_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  orderDateTime_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  orderDateTime_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  orderDateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  privateIdentificationId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  privateIdentificationId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  privateIdentificationId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  privateIdentificationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  privateIdentificationId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  privateIdentificationId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  privateIdentificationId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  privateIdentificationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  privateIdentificationId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  privateIdentificationId_starts_with?: InputMaybe<Scalars['String']['input']>
  publicIdentificationId?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  publicIdentificationId_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  publicIdentificationId_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  publicIdentificationId_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  publicIdentificationId_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  publicIdentificationId_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  publicIdentificationId_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  publicIdentificationId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  publicIdentificationId_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  publicIdentificationId_starts_with?: InputMaybe<Scalars['String']['input']>
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  publishedBy?: InputMaybe<UserWhereInput>
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>
  updatedBy?: InputMaybe<UserWhereInput>
}

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type VgWortWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VgWortWhereStageInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VgWortWhereStageInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VgWortWhereStageInput>>
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<VgWortWhereComparatorInput>
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>
}

/** References VGWort record uniquely */
export type VgWortWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  privateIdentificationId?: InputMaybe<Scalars['String']['input']>
  publicIdentificationId?: InputMaybe<Scalars['String']['input']>
}

export type Version = {
  __typename?: 'Version'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  revision: Scalars['Int']['output']
  stage: Stage
}

export type VersionWhereInput = {
  id: Scalars['ID']['input']
  revision: Scalars['Int']['input']
  stage: Stage
}

export type VerticalDivider = Entity & {
  __typename?: 'VerticalDivider'
  /** Height in pixel (desktop/tablet sized screens) */
  desktopHeight: Scalars['Int']['output']
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** Height in pixel (mobile sized screens) */
  mobileHeight: Scalars['Int']['output']
  /** System stage field */
  stage: Stage
}

export type VerticalDividerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: VerticalDividerWhereUniqueInput
}

/** A connection to a list of items. */
export type VerticalDividerConnection = {
  __typename?: 'VerticalDividerConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<VerticalDividerEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type VerticalDividerCreateInput = {
  desktopHeight: Scalars['Int']['input']
  mobileHeight: Scalars['Int']['input']
}

export type VerticalDividerCreateManyInlineInput = {
  /** Create and connect multiple existing VerticalDivider documents */
  create?: InputMaybe<Array<VerticalDividerCreateInput>>
}

export type VerticalDividerCreateOneInlineInput = {
  /** Create and connect one VerticalDivider document */
  create?: InputMaybe<VerticalDividerCreateInput>
}

export type VerticalDividerCreateWithPositionInput = {
  /** Document to create */
  data: VerticalDividerCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type VerticalDividerEdge = {
  __typename?: 'VerticalDividerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: VerticalDivider
}

/** Identifies documents */
export type VerticalDividerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  desktopHeight?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  desktopHeight_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  desktopHeight_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  desktopHeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  desktopHeight_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  desktopHeight_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  desktopHeight_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  desktopHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileHeight?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  mobileHeight_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  mobileHeight_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  mobileHeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  mobileHeight_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  mobileHeight_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  mobileHeight_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  mobileHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

export enum VerticalDividerOrderByInput {
  DesktopHeightAsc = 'desktopHeight_ASC',
  DesktopHeightDesc = 'desktopHeight_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MobileHeightAsc = 'mobileHeight_ASC',
  MobileHeightDesc = 'mobileHeight_DESC',
}

export type VerticalDividerParent = EventPage

export type VerticalDividerParentConnectInput = {
  EventPage?: InputMaybe<EventPageConnectInput>
}

export type VerticalDividerParentCreateInput = {
  EventPage?: InputMaybe<EventPageCreateInput>
}

export type VerticalDividerParentCreateManyInlineInput = {
  /** Connect multiple existing VerticalDividerParent documents */
  connect?: InputMaybe<Array<VerticalDividerParentWhereUniqueInput>>
  /** Create and connect multiple existing VerticalDividerParent documents */
  create?: InputMaybe<Array<VerticalDividerParentCreateInput>>
}

export type VerticalDividerParentCreateOneInlineInput = {
  /** Connect one existing VerticalDividerParent document */
  connect?: InputMaybe<VerticalDividerParentWhereUniqueInput>
  /** Create and connect one VerticalDividerParent document */
  create?: InputMaybe<VerticalDividerParentCreateInput>
}

export type VerticalDividerParentUpdateInput = {
  EventPage?: InputMaybe<EventPageUpdateInput>
}

export type VerticalDividerParentUpdateManyInlineInput = {
  /** Connect multiple existing VerticalDividerParent documents */
  connect?: InputMaybe<Array<VerticalDividerParentConnectInput>>
  /** Create and connect multiple VerticalDividerParent documents */
  create?: InputMaybe<Array<VerticalDividerParentCreateInput>>
  /** Delete multiple VerticalDividerParent documents */
  delete?: InputMaybe<Array<VerticalDividerParentWhereUniqueInput>>
  /** Disconnect multiple VerticalDividerParent documents */
  disconnect?: InputMaybe<Array<VerticalDividerParentWhereUniqueInput>>
  /** Override currently-connected documents with multiple existing VerticalDividerParent documents */
  set?: InputMaybe<Array<VerticalDividerParentWhereUniqueInput>>
  /** Update multiple VerticalDividerParent documents */
  update?: InputMaybe<Array<VerticalDividerParentUpdateWithNestedWhereUniqueInput>>
  /** Upsert multiple VerticalDividerParent documents */
  upsert?: InputMaybe<Array<VerticalDividerParentUpsertWithNestedWhereUniqueInput>>
}

export type VerticalDividerParentUpdateManyWithNestedWhereInput = {
  EventPage?: InputMaybe<EventPageUpdateManyWithNestedWhereInput>
}

export type VerticalDividerParentUpdateOneInlineInput = {
  /** Connect existing VerticalDividerParent document */
  connect?: InputMaybe<VerticalDividerParentWhereUniqueInput>
  /** Create and connect one VerticalDividerParent document */
  create?: InputMaybe<VerticalDividerParentCreateInput>
  /** Delete currently connected VerticalDividerParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Disconnect currently connected VerticalDividerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single VerticalDividerParent document */
  update?: InputMaybe<VerticalDividerParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single VerticalDividerParent document */
  upsert?: InputMaybe<VerticalDividerParentUpsertWithNestedWhereUniqueInput>
}

export type VerticalDividerParentUpdateWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpdateWithNestedWhereUniqueInput>
}

export type VerticalDividerParentUpsertWithNestedWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageUpsertWithNestedWhereUniqueInput>
}

export type VerticalDividerParentWhereInput = {
  EventPage?: InputMaybe<EventPageWhereInput>
}

export type VerticalDividerParentWhereUniqueInput = {
  EventPage?: InputMaybe<EventPageWhereUniqueInput>
}

export type VerticalDividerUpdateInput = {
  desktopHeight?: InputMaybe<Scalars['Int']['input']>
  mobileHeight?: InputMaybe<Scalars['Int']['input']>
}

export type VerticalDividerUpdateManyInlineInput = {
  /** Create and connect multiple VerticalDivider component instances */
  create?: InputMaybe<Array<VerticalDividerCreateWithPositionInput>>
  /** Delete multiple VerticalDivider documents */
  delete?: InputMaybe<Array<VerticalDividerWhereUniqueInput>>
  /** Update multiple VerticalDivider component instances */
  update?: InputMaybe<Array<VerticalDividerUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple VerticalDivider component instances */
  upsert?: InputMaybe<Array<VerticalDividerUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type VerticalDividerUpdateManyInput = {
  desktopHeight?: InputMaybe<Scalars['Int']['input']>
  mobileHeight?: InputMaybe<Scalars['Int']['input']>
}

export type VerticalDividerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VerticalDividerUpdateManyInput
  /** Document search */
  where: VerticalDividerWhereInput
}

export type VerticalDividerUpdateOneInlineInput = {
  /** Create and connect one VerticalDivider document */
  create?: InputMaybe<VerticalDividerCreateInput>
  /** Delete currently connected VerticalDivider document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single VerticalDivider document */
  update?: InputMaybe<VerticalDividerUpdateWithNestedWhereUniqueInput>
  /** Upsert single VerticalDivider document */
  upsert?: InputMaybe<VerticalDividerUpsertWithNestedWhereUniqueInput>
}

export type VerticalDividerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VerticalDividerUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: VerticalDividerWhereUniqueInput
}

export type VerticalDividerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VerticalDividerUpdateInput
  /** Unique document search */
  where: VerticalDividerWhereUniqueInput
}

export type VerticalDividerUpsertInput = {
  /** Create document if it didn't exist */
  create: VerticalDividerCreateInput
  /** Update document if it exists */
  update: VerticalDividerUpdateInput
}

export type VerticalDividerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VerticalDividerUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: VerticalDividerWhereUniqueInput
}

export type VerticalDividerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VerticalDividerUpsertInput
  /** Unique document search */
  where: VerticalDividerWhereUniqueInput
}

/** Identifies documents */
export type VerticalDividerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VerticalDividerWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  desktopHeight?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  desktopHeight_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  desktopHeight_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  desktopHeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  desktopHeight_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  desktopHeight_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  desktopHeight_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  desktopHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  mobileHeight?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than the given value. */
  mobileHeight_gt?: InputMaybe<Scalars['Int']['input']>
  /** All values greater than or equal the given value. */
  mobileHeight_gte?: InputMaybe<Scalars['Int']['input']>
  /** All values that are contained in given list. */
  mobileHeight_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
  /** All values less than the given value. */
  mobileHeight_lt?: InputMaybe<Scalars['Int']['input']>
  /** All values less than or equal the given value. */
  mobileHeight_lte?: InputMaybe<Scalars['Int']['input']>
  /** Any other value that exists and is not equal to the given value. */
  mobileHeight_not?: InputMaybe<Scalars['Int']['input']>
  /** All values that are not contained in given list. */
  mobileHeight_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>
}

/** References VerticalDivider record uniquely */
export type VerticalDividerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type YtEmbed = Entity & {
  __typename?: 'YTEmbed'
  date?: Maybe<Scalars['String']['output']>
  /** The unique identifier */
  id: Scalars['ID']['output']
  /** System Locale field */
  locale: Locale
  /** Get the other localizations for this document */
  localizations: Array<YtEmbed>
  /** System stage field */
  stage: Stage
  title: Scalars['String']['output']
  videoID?: Maybe<Scalars['String']['output']>
}

export type YtEmbedLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean']['input']
  locales?: Array<Locale>
}

export type YtEmbedConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Document to connect */
  where: YtEmbedWhereUniqueInput
}

/** A connection to a list of items. */
export type YtEmbedConnection = {
  __typename?: 'YTEmbedConnection'
  aggregate: Aggregate
  /** A list of edges. */
  edges: Array<YtEmbedEdge>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

export type YtEmbedCreateInput = {
  /** date input for default locale (de) */
  date?: InputMaybe<Scalars['String']['input']>
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<YtEmbedCreateLocalizationsInput>
  /** title input for default locale (de) */
  title: Scalars['String']['input']
  /** videoID input for default locale (de) */
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedCreateLocalizationDataInput = {
  date?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedCreateLocalizationInput = {
  /** Localization input */
  data: YtEmbedCreateLocalizationDataInput
  locale: Locale
}

export type YtEmbedCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<YtEmbedCreateLocalizationInput>>
}

export type YtEmbedCreateManyInlineInput = {
  /** Create and connect multiple existing YTEmbed documents */
  create?: InputMaybe<Array<YtEmbedCreateInput>>
}

export type YtEmbedCreateOneInlineInput = {
  /** Create and connect one YTEmbed document */
  create?: InputMaybe<YtEmbedCreateInput>
}

export type YtEmbedCreateWithPositionInput = {
  /** Document to create */
  data: YtEmbedCreateInput
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
}

/** An edge in a connection. */
export type YtEmbedEdge = {
  __typename?: 'YTEmbedEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node: YtEmbed
}

/** Identifies documents */
export type YtEmbedManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
}

export enum YtEmbedOrderByInput {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VideoIdAsc = 'videoID_ASC',
  VideoIdDesc = 'videoID_DESC',
}

export type YtEmbedParent = SectionLivestreams

export type YtEmbedParentConnectInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsConnectInput>
}

export type YtEmbedParentCreateInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsCreateInput>
}

export type YtEmbedParentCreateManyInlineInput = {
  /** Create and connect multiple existing YTEmbedParent documents */
  create?: InputMaybe<Array<YtEmbedParentCreateInput>>
}

export type YtEmbedParentCreateOneInlineInput = {
  /** Create and connect one YTEmbedParent document */
  create?: InputMaybe<YtEmbedParentCreateInput>
}

export type YtEmbedParentCreateWithPositionInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsCreateWithPositionInput>
}

export type YtEmbedParentUpdateInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateInput>
}

export type YtEmbedParentUpdateManyInlineInput = {
  /** Create and connect multiple YTEmbedParent component instances */
  create?: InputMaybe<Array<YtEmbedParentCreateWithPositionInput>>
  /** Delete multiple YTEmbedParent documents */
  delete?: InputMaybe<Array<YtEmbedParentWhereUniqueInput>>
  /** Update multiple YTEmbedParent component instances */
  update?: InputMaybe<Array<YtEmbedParentUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple YTEmbedParent component instances */
  upsert?: InputMaybe<Array<YtEmbedParentUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type YtEmbedParentUpdateManyWithNestedWhereInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateManyWithNestedWhereInput>
}

export type YtEmbedParentUpdateOneInlineInput = {
  /** Create and connect one YTEmbedParent document */
  create?: InputMaybe<YtEmbedParentCreateInput>
  /** Delete currently connected YTEmbedParent document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single YTEmbedParent document */
  update?: InputMaybe<YtEmbedParentUpdateWithNestedWhereUniqueInput>
  /** Upsert single YTEmbedParent document */
  upsert?: InputMaybe<YtEmbedParentUpsertWithNestedWhereUniqueInput>
}

export type YtEmbedParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateWithNestedWhereUniqueAndPositionInput>
}

export type YtEmbedParentUpdateWithNestedWhereUniqueInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpdateWithNestedWhereUniqueInput>
}

export type YtEmbedParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpsertWithNestedWhereUniqueAndPositionInput>
}

export type YtEmbedParentUpsertWithNestedWhereUniqueInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsUpsertWithNestedWhereUniqueInput>
}

export type YtEmbedParentWhereInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsWhereInput>
}

export type YtEmbedParentWhereUniqueInput = {
  SectionLivestreams?: InputMaybe<SectionLivestreamsWhereUniqueInput>
}

export type YtEmbedUpdateInput = {
  /** date input for default locale (de) */
  date?: InputMaybe<Scalars['String']['input']>
  /** Manage document localizations */
  localizations?: InputMaybe<YtEmbedUpdateLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** videoID input for default locale (de) */
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedUpdateLocalizationDataInput = {
  date?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedUpdateLocalizationInput = {
  data: YtEmbedUpdateLocalizationDataInput
  locale: Locale
}

export type YtEmbedUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<YtEmbedCreateLocalizationInput>>
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>
  /** Localizations to update */
  update?: InputMaybe<Array<YtEmbedUpdateLocalizationInput>>
  upsert?: InputMaybe<Array<YtEmbedUpsertLocalizationInput>>
}

export type YtEmbedUpdateManyInlineInput = {
  /** Create and connect multiple YTEmbed component instances */
  create?: InputMaybe<Array<YtEmbedCreateWithPositionInput>>
  /** Delete multiple YTEmbed documents */
  delete?: InputMaybe<Array<YtEmbedWhereUniqueInput>>
  /** Update multiple YTEmbed component instances */
  update?: InputMaybe<Array<YtEmbedUpdateWithNestedWhereUniqueAndPositionInput>>
  /** Upsert multiple YTEmbed component instances */
  upsert?: InputMaybe<Array<YtEmbedUpsertWithNestedWhereUniqueAndPositionInput>>
}

export type YtEmbedUpdateManyInput = {
  /** date input for default locale (de) */
  date?: InputMaybe<Scalars['String']['input']>
  /** Optional updates to localizations */
  localizations?: InputMaybe<YtEmbedUpdateManyLocalizationsInput>
  /** title input for default locale (de) */
  title?: InputMaybe<Scalars['String']['input']>
  /** videoID input for default locale (de) */
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedUpdateManyLocalizationDataInput = {
  date?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  videoID?: InputMaybe<Scalars['String']['input']>
}

export type YtEmbedUpdateManyLocalizationInput = {
  data: YtEmbedUpdateManyLocalizationDataInput
  locale: Locale
}

export type YtEmbedUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<YtEmbedUpdateManyLocalizationInput>>
}

export type YtEmbedUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: YtEmbedUpdateManyInput
  /** Document search */
  where: YtEmbedWhereInput
}

export type YtEmbedUpdateOneInlineInput = {
  /** Create and connect one YTEmbed document */
  create?: InputMaybe<YtEmbedCreateInput>
  /** Delete currently connected YTEmbed document */
  delete?: InputMaybe<Scalars['Boolean']['input']>
  /** Update single YTEmbed document */
  update?: InputMaybe<YtEmbedUpdateWithNestedWhereUniqueInput>
  /** Upsert single YTEmbed document */
  upsert?: InputMaybe<YtEmbedUpsertWithNestedWhereUniqueInput>
}

export type YtEmbedUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<YtEmbedUpdateInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: YtEmbedWhereUniqueInput
}

export type YtEmbedUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: YtEmbedUpdateInput
  /** Unique document search */
  where: YtEmbedWhereUniqueInput
}

export type YtEmbedUpsertInput = {
  /** Create document if it didn't exist */
  create: YtEmbedCreateInput
  /** Update document if it exists */
  update: YtEmbedUpdateInput
}

export type YtEmbedUpsertLocalizationInput = {
  create: YtEmbedCreateLocalizationDataInput
  locale: Locale
  update: YtEmbedUpdateLocalizationDataInput
}

export type YtEmbedUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<YtEmbedUpsertInput>
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>
  /** Unique component instance search */
  where: YtEmbedWhereUniqueInput
}

export type YtEmbedUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: YtEmbedUpsertInput
  /** Unique document search */
  where: YtEmbedWhereUniqueInput
}

/** Identifies documents */
export type YtEmbedWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YtEmbedWhereInput>>
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  date_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  date_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  date_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  date_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  date_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  date_starts_with?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']['input']>
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']['input']>
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']['input']>
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']['input']>
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']['input']>
  videoID?: InputMaybe<Scalars['String']['input']>
  /** All values containing the given string. */
  videoID_contains?: InputMaybe<Scalars['String']['input']>
  /** All values ending with the given string. */
  videoID_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are contained in given list. */
  videoID_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Any other value that exists and is not equal to the given value. */
  videoID_not?: InputMaybe<Scalars['String']['input']>
  /** All values not containing the given string. */
  videoID_not_contains?: InputMaybe<Scalars['String']['input']>
  /** All values not ending with the given string */
  videoID_not_ends_with?: InputMaybe<Scalars['String']['input']>
  /** All values that are not contained in given list. */
  videoID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** All values not starting with the given string. */
  videoID_not_starts_with?: InputMaybe<Scalars['String']['input']>
  /** All values starting with the given string. */
  videoID_starts_with?: InputMaybe<Scalars['String']['input']>
}

/** References YTEmbed record uniquely */
export type YtEmbedWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export enum _FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  JsonPathExists = 'json_path_exists',
  JsonValueRecursive = 'json_value_recursive',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with',
  UnionEmpty = 'union_empty',
  UnionEvery = 'union_every',
  UnionNone = 'union_none',
  UnionSingle = 'union_single',
  UnionSome = 'union_some',
}

export enum _MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual',
}

export enum _MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert',
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum _RelationInputCardinality {
  Many = 'many',
  One = 'one',
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update',
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union',
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization',
}

export type LandingPageLayoutFieldsFragment = {
  __typename?: 'LandingPage'
  pageLayout: Array<
    | ({ __typename?: 'Faq' } & SectionFaqFieldsFragment)
    | ({ __typename?: 'PpcReviewsProduct' } & PpcReviewsProductFieldsFragment)
    | { __typename?: 'PreviewCardList' }
    | ({ __typename?: 'RichContent' } & SectionRichContentFieldsFragment)
    | { __typename?: 'SectionAd' }
    | ({ __typename?: 'SectionAnchorTarget' } & SectionAnchorTargetFieldsFragment)
    | { __typename?: 'SectionBanner' }
    | ({ __typename?: 'SectionByTheNumbers' } & SectionByTheNumbersFieldsFragment)
    | { __typename?: 'SectionCardGroup' }
    | { __typename?: 'SectionCollab' }
    | ({ __typename?: 'SectionContact' } & SectionContactFieldsFragment)
    | ({ __typename?: 'SectionCta' } & SectionCtaFieldsFragment)
    | { __typename?: 'SectionCuratedSpeakerList' }
    | ({ __typename?: 'SectionExplanationBlocks' } & SectionExplanationBlocksFieldsFragment)
    | { __typename?: 'SectionExplanationStep' }
    | ({ __typename?: 'SectionFramesHero' } & SectionFramesHeroFieldsFragment)
    | ({ __typename?: 'SectionHero' } & SectionHeroFieldsFragment)
    | ({ __typename?: 'SectionHubspotForm' } & SectionHubspotFormFieldsFragment)
    | ({ __typename?: 'SectionIntro' } & SectionIntroFieldsFragment)
    | { __typename?: 'SectionNewsletter' }
    | ({ __typename?: 'SectionNewsletterAudience' } & SectionNewsletterAudiencesFieldsFragment)
    | ({ __typename?: 'SectionOMRPartner' } & SectionOmrPartnerFieldsFragment)
    | { __typename?: 'SectionOmrProductCard' }
    | ({ __typename?: 'SectionTab' } & SectionTabsFieldsFragment)
    | ({ __typename?: 'SectionTeaser' } & SectionTeaserFieldsFragment)
    | { __typename?: 'SectionTestimonial' }
    | ({ __typename?: 'SubscriptionTeaser' } & SubscriptionTeaserFieldsFragment)
    | ({ __typename?: 'TestimonialSlider' } & SectionTestimonialSliderFieldsFragment)
    | ({ __typename?: 'TextMedia' } & SectionTextMediaFieldsFragment)
    | { __typename?: 'TextMediaBanner' }
  >
}

export type LandingPageLayoutBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  locale: Array<Locale> | Locale
  stage?: InputMaybe<Stage>
}>

export type LandingPageLayoutBySlugQuery = {
  __typename?: 'Query'
  landingPage?:
    | ({ __typename?: 'LandingPage'; id: string; slug: string; stage: Stage; title: string } & LandingPageLayoutFieldsFragment)
    | null
}

export type FooterFieldsFragment = {
  __typename?: 'Footer'
  id: string
  omrFooterLinks: Array<{ __typename?: 'FooterLink'; id: string; name?: string | null; link?: string | null }>
  omrFooterSocials: Array<{
    __typename?: 'SocialLink'
    id: string
    name: string
    link: string
    logo?: { __typename?: 'Asset'; url: string } | null
  }>
}

export type HeaderFieldsFragment = {
  __typename?: 'Header'
  id: string
  logo?: { __typename?: 'Asset'; url: string } | null
  mobileLogo?: { __typename?: 'Asset'; url: string } | null
  omrHeaderMenuItems: Array<{
    __typename?: 'HeaderMenuItem'
    id: string
    slug: string
    name: string
    link: string
    omrHeaderSubmenuItems: Array<{
      __typename?: 'HeaderSubMenuItem'
      id: string
      name: string
      link: string
      omrHeaderSubSubmenuItems: Array<{ __typename?: 'HeaderSubSubMenuItem'; id: string; name: string; link: string }>
    }>
    omrHeaderSubmenuCta?: { __typename?: 'CallToAction'; label: string; url: string } | null
  }>
  omrHeaderPartners: Array<{
    __typename?: 'OMRPartner'
    id: string
    name: string
    partnerUrl?: string | null
    logo: { __typename?: 'Asset'; url: string }
  }>
  omrHeaderSocials: Array<{
    __typename?: 'SocialLink'
    id: string
    name: string
    link: string
    logo?: { __typename?: 'Asset'; url: string } | null
  }>
}

export type PpcReviewsProductFieldsFragment = {
  __typename: 'PpcReviewsProduct'
  ppcDescription?: string | null
  id: string
  url: string
  reviewsProduct?: {
    __typename?: 'ReviewsProduct'
    id: string
    name: string
    rating?: number | null
    ratingsCount?: number | null
    logoUrl?: string | null
  } | null
}

export type ProcessStepFieldsFragment = {
  __typename: 'ProcessStep'
  id: string
  steps: Array<string>
  sectionPadding?: SectionPadding | null
  styleVariant?: ComponentStyle | null
  displayDecorations?: boolean | null
  optionalTitle?: string | null
  optionalDescription?: string | null
  optionalCtaText?: string | null
  optionalCtaUrl?: string | null
}

export type SectionAnchorTargetFieldsFragment = { __typename: 'SectionAnchorTarget'; id: string; anchorId: string }

export type SectionByTheNumbersFieldsFragment = {
  __typename: 'SectionByTheNumbers'
  id: string
  sectionPadding?: SectionPadding | null
  styleVariant?: ComponentStyle | null
  duration?: number | null
  optionalTitle?: string | null
  optionalSubtitle?: string | null
  counters: Array<{
    __typename?: 'AnimatedCounter'
    target: number
    start?: number | null
    prefix?: string | null
    suffix?: string | null
    subhead?: string | null
  }>
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
}

export type SectionContactFieldsFragment = {
  __typename: 'SectionContact'
  id: string
  title: string
  decorationPattern?: DecorationPattern | null
  styleVariant?: ComponentStyle | null
  centered?: boolean | null
  optionalDescription?: string | null
  contact: Array<{
    __typename?: 'Contact'
    email?: string | null
    id: string
    phone?: string | null
    title: string
    description: string
    department?: string | null
    departmentUrl?: string | null
    contactIcons?: boolean | null
    contactPhoto: { __typename?: 'Asset' } & AssetFieldsFragment
  }>
}

export type SectionCtaFieldsFragment = {
  __typename: 'SectionCta'
  id: string
  ctaText: string
  ctaUrl: string
  ctaTarget: string
  ctaExternal?: boolean | null
  contentPosition?: ElementPosition | null
  showDecoration?: boolean | null
  decorationPattern?: DecorationPattern | null
  appStoreUrl?: string | null
  playStoreUrl?: string | null
  darkTheme?: boolean | null
  styleVariant?: ComponentStyle | null
  optionalTitle?: string | null
  optionalDescription?: string | null
  ctaImage?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  internalPageLink?: { __typename?: 'EventPage'; slug: string; event?: { __typename?: 'Event'; slug: string } | null } | null
  partnerLogo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type SectionExplanationBlocksFieldsFragment = {
  __typename: 'SectionExplanationBlocks'
  id: string
  centered?: boolean | null
  styleVariant?: ComponentStyle | null
  sectionPadding?: SectionPadding | null
  displayDecorations?: boolean | null
  optionalTitle?: string | null
  optionalSubtitle?: string | null
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  items: Array<{ __typename?: 'ExplanationStep' } & ExplanationStepFieldsFragment>
}

export type SectionFaqFieldsFragment = {
  __typename: 'Faq'
  id: string
  title: string
  description: string
  styleVariant?: ComponentStyle | null
  decorationPattern?: DecorationPattern | null
  optionalCtaText?: string | null
  optionalCtaUrl?: string | null
  questions: Array<{ __typename?: 'FaqQuestion'; id: string; question?: string | null; answer?: string | null }>
}

export type SectionFestivalHeroFieldsFragment = {
  __typename: 'SectionFestivalHero'
  id: string
  optionalTitle?: string | null
  optionalSecondTitle?: string | null
  background: { __typename?: 'Asset' } & AssetFieldsFragment
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  titleDecoration?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type SectionFramesHeroFieldsFragment = {
  __typename: 'SectionFramesHero'
  id: string
  styleVariant?: ComponentStyle | null
  optionalTitle?: string | null
  optionalIntro?: string | null
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  contentBackgroundLeft?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  contentBackgroundRight?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type SectionHeroFieldsFragment = {
  __typename: 'SectionHero'
  id: string
  compact?: boolean | null
  ctaOnMobileOnly?: boolean | null
  titleIsTransparent?: boolean | null
  optionalTitle?: string | null
  optionalSubtitle?: string | null
  optionalDescription?: string | null
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  ctaSecondary?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  background: { __typename?: 'Asset' } & AssetFieldsFragment
  mobileBackground?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  video?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type SectionHotelsFieldsFragment = {
  __typename: 'SectionHotel'
  id: string
  title: string
  description: string
  hotels: Array<{
    __typename?: 'Hotel'
    id: string
    name: string
    description: string
    rating: string
    distance: string
    url: string
    logo: { __typename?: 'Asset' } & AssetFieldsFragment
  }>
}

export type SectionHubspotFormFieldsFragment = { __typename: 'SectionHubspotForm'; id: string; formId: string }

export type SectionIntroFieldsFragment = {
  __typename: 'SectionIntro'
  id: string
  title: string
  description: string
  sectionHead?: boolean | null
  sectionPadding?: SectionPadding | null
  cta?: { __typename?: 'CallToAction'; label: string; url: string; target?: string | null } | null
}

export type SectionNewsletterAudiencesFieldsFragment = {
  __typename: 'SectionNewsletterAudience'
  id: string
  audiences: Array<{
    __typename?: 'NewsletterAudience'
    slug: string
    name: string
    description?: string | null
    private?: boolean | null
    previewCta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  }>
}

export type SectionOmrPartnerFieldsFragment = {
  __typename: 'SectionOMRPartner'
  id: string
  asSlider?: boolean | null
  sectionPadding?: SectionPadding | null
  darkTheme?: boolean | null
  decorationPattern?: DecorationPattern | null
  optionalTitle?: string | null
  optionalDescription?: string | null
  omrPartners: Array<{ __typename?: 'OMRPartner' } & OmrPartnerFieldsFragment>
}

export type SectionPoweredByFieldsFragment = {
  __typename: 'SectionPoweredBy'
  id: string
  styleVariant?: ComponentStyle | null
  optionalTitle?: string | null
  logo?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
}

export type SectionRichContentFieldsFragment = { __typename: 'RichContent'; id: string; content: { __typename?: 'RichText'; raw: any } }

export type SectionTabsFieldsFragment = {
  __typename: 'SectionTab'
  id: string
  titlePosition?: ElementPosition | null
  optionalTitle?: string | null
  optionalDescription?: string | null
  tabContents: Array<{
    __typename?: 'TabContent'
    id: string
    tabName: string
    title: string
    description?: string | null
    image?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
    downloads: Array<{
      __typename?: 'Download'
      date: any
      description: string
      title: string
      id: string
      previewimage?: { __typename?: 'Asset'; url: string; title?: string | null } | null
      downloadDe?: { __typename?: 'Asset'; url: string; fileName: string } | null
      downloadEn?: { __typename?: 'Asset'; url: string; fileName: string } | null
    }>
  }>
}

export type SectionTeaserFieldsFragment = {
  __typename: 'SectionTeaser'
  id: string
  headline: string
  optionalDescription?: string | null
  optionalCtaUrl?: string | null
  optionalCtaLabel?: string | null
  background: { __typename?: 'Asset' } & AssetFieldsFragment
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  ctaSecondary?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
}

export type SectionTestimonialSliderFieldsFragment = {
  __typename: 'TestimonialSlider'
  id: string
  styleVariant?: ComponentStyle | null
  optionalTitle?: string | null
  slide: Array<{ __typename?: 'SectionTestimonial' } & SectionTestimonialFieldsFragment>
}

export type SectionTextMediaFieldsFragment = {
  __typename: 'TextMedia'
  id: string
  title: string
  subtitle?: string | null
  tag?: string | null
  sectionPadding?: SectionPadding | null
  sectionDivision?: SectionDivision | null
  darkTheme?: boolean | null
  centered?: boolean | null
  description: string
  imageLinkUrl?: string | null
  imageHiddenOnMobile?: boolean | null
  vertical?: boolean | null
  enableDescriptionStyling?: boolean | null
  optionalCtaUrl?: string | null
  optionalCtaText?: string | null
  elementPosition?: ElementPosition | null
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
  image: { __typename?: 'Asset' } & AssetFieldsFragment
}

export type SubscriptionTeaserFieldsFragment = {
  __typename: 'SubscriptionTeaser'
  id: string
  bannerText: string
  ctaText: string
  ctaUrl: string
  sectionPadding?: SectionPadding | null
  styleVariant?: ComponentStyle | null
  decorationPattern?: DecorationPattern | null
  optionalTitle?: string | null
  optionalDescription?: string | null
  optionalSubBannerText?: string | null
}

export type AssetFieldsFragment = {
  __typename?: 'Asset'
  url: string
  title?: string | null
  description?: string | null
  mimeType?: string | null
}

export type CallToActionFieldsFragment = {
  __typename?: 'CallToAction'
  label: string
  url: string
  target?: string | null
  external?: boolean | null
  type?: CallToActionType | null
  color?: CallToActionColor | null
  size?: CallToActionSize | null
}

export type ExplanationStepFieldsFragment = {
  __typename?: 'ExplanationStep'
  title?: string | null
  description: string
  icon?: IconEnum | null
  picture?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  cta?: ({ __typename?: 'CallToAction' } & CallToActionFieldsFragment) | null
}

export type OmrPartnerFieldsFragment = {
  __typename?: 'OMRPartner'
  id: string
  name: string
  partnerUrl?: string | null
  logo: { __typename?: 'Asset' } & AssetFieldsFragment
}

export type SectionQuoteFieldsFragment = {
  __typename?: 'SectionQuote'
  quote: string
  name: string
  position: string
  signatureImage: { __typename?: 'Asset' } & AssetFieldsFragment
}

export type SectionTestimonialFieldsFragment = {
  __typename?: 'SectionTestimonial'
  testimonial: string
  signature: string
  position?: string | null
  signatureImage?: ({ __typename?: 'Asset' } & AssetFieldsFragment) | null
  image: { __typename?: 'Asset' } & AssetFieldsFragment
}

export type LandingPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
  locales: Array<Locale> | Locale
}>

export type LandingPageBySlugQuery = {
  __typename?: 'Query'
  landingPage?:
    | ({
        __typename?: 'LandingPage'
        id: string
        slug: string
        stage: Stage
        title: string
        seoContentAttributes?: {
          __typename?: 'SeoContentAttribute'
          bottomSectionHeadline?: string | null
          h1?: string | null
          id: string
          bottomSectionBody?: { __typename?: 'RichText'; markdown: string } | null
          topSectionBody?: { __typename?: 'RichText'; markdown: string } | null
        } | null
      } & LandingPageLayoutFieldsFragment)
    | null
}

export type ManageContentConfigsQueryVariables = Exact<{
  locales: Array<Locale> | Locale
}>

export type ManageContentConfigsQuery = {
  __typename?: 'Query'
  manageContentConfigs: Array<{
    __typename?: 'ManageContentConfig'
    id: string
    contentSlug: string
    loggedInSlug?: string | null
    anonymousSlug?: string | null
    menuTitle?: string | null
    customerTypes: Array<CustomerType>
  }>
}

export const CallToActionFieldsFragmentDoc = gql`
  fragment CallToActionFields on CallToAction {
    label
    url
    target
    external
    type
    color
    size
  }
`
export const SectionByTheNumbersFieldsFragmentDoc = gql`
  fragment SectionByTheNumbersFields on SectionByTheNumbers {
    __typename
    id
    optionalTitle: title
    optionalSubtitle: subtitle
    sectionPadding
    styleVariant
    duration
    counters {
      target
      start
      prefix
      suffix
      subhead
    }
    cta {
      ...CallToActionFields
    }
  }
  ${CallToActionFieldsFragmentDoc}
`
export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on Asset {
    url
    title
    description
    mimeType
  }
`
export const SectionContactFieldsFragmentDoc = gql`
  fragment SectionContactFields on SectionContact {
    __typename
    id
    optionalDescription: description
    title
    contact {
      email
      id
      phone
      title
      description
      department
      departmentUrl
      contactIcons
      contactPhoto(locales: de) {
        ...AssetFields
      }
    }
    decorationPattern
    styleVariant
    centered
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionCtaFieldsFragmentDoc = gql`
  fragment SectionCtaFields on SectionCta {
    __typename
    id
    optionalTitle: title
    optionalDescription: description
    ctaText
    ctaUrl
    ctaTarget
    ctaImage(locales: de) {
      ...AssetFields
    }
    ctaExternal
    contentPosition
    showDecoration
    decorationPattern
    appStoreUrl
    playStoreUrl
    darkTheme
    styleVariant
    internalPageLink {
      event {
        slug
      }
      slug
    }
    partnerLogo(locales: de) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const ExplanationStepFieldsFragmentDoc = gql`
  fragment ExplanationStepFields on ExplanationStep {
    title
    description
    picture(locales: de) {
      ...AssetFields
    }
    icon
    cta {
      ...CallToActionFields
    }
  }
  ${AssetFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const SectionExplanationBlocksFieldsFragmentDoc = gql`
  fragment SectionExplanationBlocksFields on SectionExplanationBlocks {
    __typename
    id
    cta {
      ...CallToActionFields
    }
    optionalTitle: title
    optionalSubtitle: subtitle
    centered
    items {
      ...ExplanationStepFields
    }
    styleVariant
    sectionPadding
    displayDecorations
  }
  ${CallToActionFieldsFragmentDoc}
  ${ExplanationStepFieldsFragmentDoc}
`
export const SectionFaqFieldsFragmentDoc = gql`
  fragment SectionFaqFields on Faq {
    __typename
    id
    title
    description
    optionalCtaText: ctaText
    optionalCtaUrl: ctaUrl
    questions: questions {
      ... on FaqQuestion {
        id
        question
        answer
      }
    }
    styleVariant
    decorationPattern
  }
`
export const SectionFramesHeroFieldsFragmentDoc = gql`
  fragment SectionFramesHeroFields on SectionFramesHero {
    __typename
    id
    optionalTitle: title
    optionalIntro: intro
    logo(locales: de) {
      ...AssetFields
    }
    contentBackgroundLeft(locales: de) {
      ...AssetFields
    }
    contentBackgroundRight(locales: de) {
      ...AssetFields
    }
    styleVariant
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionHeroFieldsFragmentDoc = gql`
  fragment SectionHeroFields on SectionHero {
    __typename
    id
    optionalTitle: title
    optionalSubtitle: subtitle
    optionalDescription: description
    compact
    cta {
      ...CallToActionFields
    }
    ctaSecondary {
      ...CallToActionFields
    }
    ctaOnMobileOnly
    background(locales: de) {
      ...AssetFields
    }
    mobileBackground(locales: de) {
      ...AssetFields
    }
    logo(locales: de) {
      ...AssetFields
    }
    video(locales: de) {
      ...AssetFields
    }
    titleIsTransparent
  }
  ${CallToActionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`
export const SectionIntroFieldsFragmentDoc = gql`
  fragment SectionIntroFields on SectionIntro {
    __typename
    id
    title
    description
    sectionHead
    sectionPadding
    cta {
      label
      url
      target
    }
  }
`
export const OmrPartnerFieldsFragmentDoc = gql`
  fragment OMRPartnerFields on OMRPartner {
    id
    name
    partnerUrl
    logo(locales: de) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionOmrPartnerFieldsFragmentDoc = gql`
  fragment SectionOMRPartnerFields on SectionOMRPartner {
    __typename
    id
    optionalTitle: title
    optionalDescription: description
    asSlider
    sectionPadding
    darkTheme
    omrPartners {
      ...OMRPartnerFields
    }
    decorationPattern
  }
  ${OmrPartnerFieldsFragmentDoc}
`
export const SectionRichContentFieldsFragmentDoc = gql`
  fragment SectionRichContentFields on RichContent {
    __typename
    id
    content {
      raw
    }
  }
`
export const SectionTeaserFieldsFragmentDoc = gql`
  fragment SectionTeaserFields on SectionTeaser {
    __typename
    id
    headline
    background(locales: de) {
      ...AssetFields
    }
    cta {
      ...CallToActionFields
    }
    ctaSecondary {
      ...CallToActionFields
    }
    optionalDescription: description
    optionalCtaUrl: ctaUrl
    optionalCtaLabel: ctaLabel
  }
  ${AssetFieldsFragmentDoc}
  ${CallToActionFieldsFragmentDoc}
`
export const SectionTestimonialFieldsFragmentDoc = gql`
  fragment SectionTestimonialFields on SectionTestimonial {
    testimonial
    signature
    signatureImage(locales: de) {
      ...AssetFields
    }
    image(locales: de) {
      ...AssetFields
    }
    position
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionTestimonialSliderFieldsFragmentDoc = gql`
  fragment SectionTestimonialSliderFields on TestimonialSlider {
    __typename
    id
    optionalTitle: title
    styleVariant
    slide {
      ...SectionTestimonialFields
    }
  }
  ${SectionTestimonialFieldsFragmentDoc}
`
export const SectionTextMediaFieldsFragmentDoc = gql`
  fragment SectionTextMediaFields on TextMedia {
    __typename
    id
    title
    subtitle
    tag
    optionalCtaUrl: ctaUrl
    optionalCtaText: ctaText
    cta {
      ...CallToActionFields
    }
    sectionPadding
    sectionDivision
    darkTheme
    centered
    description
    image(locales: de) {
      ...AssetFields
    }
    elementPosition: position
    imageLinkUrl
    imageHiddenOnMobile
    vertical
    enableDescriptionStyling
  }
  ${CallToActionFieldsFragmentDoc}
  ${AssetFieldsFragmentDoc}
`
export const SectionNewsletterAudiencesFieldsFragmentDoc = gql`
  fragment SectionNewsletterAudiencesFields on SectionNewsletterAudience {
    __typename
    id
    audiences {
      slug
      name
      description
      private
      previewCta {
        ...CallToActionFields
      }
    }
  }
  ${CallToActionFieldsFragmentDoc}
`
export const SectionTabsFieldsFragmentDoc = gql`
  fragment SectionTabsFields on SectionTab {
    __typename
    id
    optionalTitle: title
    optionalDescription: description
    titlePosition
    tabContents: tabContents {
      ... on TabContent {
        id
        tabName
        title
        description
        image {
          ...AssetFields
        }
        downloads {
          date
          description
          title
          previewimage {
            url
            title
          }
          id
          downloadDe {
            url
            fileName
          }
          downloadEn {
            url
            fileName
          }
        }
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const SubscriptionTeaserFieldsFragmentDoc = gql`
  fragment SubscriptionTeaserFields on SubscriptionTeaser {
    __typename
    id
    optionalTitle: title
    optionalDescription: description
    bannerText
    optionalSubBannerText: subBannerText
    ctaText
    ctaUrl
    sectionPadding
    styleVariant
    decorationPattern
  }
`
export const PpcReviewsProductFieldsFragmentDoc = gql`
  fragment PpcReviewsProductFields on PpcReviewsProduct {
    __typename
    ppcDescription
    id
    url
    reviewsProduct {
      id
      name
      rating
      ratingsCount
      logoUrl
    }
  }
`
export const SectionHubspotFormFieldsFragmentDoc = gql`
  fragment SectionHubspotFormFields on SectionHubspotForm {
    __typename
    id
    formId
  }
`
export const SectionAnchorTargetFieldsFragmentDoc = gql`
  fragment SectionAnchorTargetFields on SectionAnchorTarget {
    __typename
    id
    anchorId
  }
`
export const LandingPageLayoutFieldsFragmentDoc = gql`
  fragment LandingPageLayoutFields on LandingPage {
    pageLayout {
      ...SectionByTheNumbersFields
      ...SectionContactFields
      ...SectionCtaFields
      ...SectionExplanationBlocksFields
      ...SectionFaqFields
      ...SectionFramesHeroFields
      ...SectionHeroFields
      ...SectionIntroFields
      ...SectionOMRPartnerFields
      ...SectionRichContentFields
      ...SectionTeaserFields
      ...SectionTestimonialSliderFields
      ...SectionTextMediaFields
      ...SectionNewsletterAudiencesFields
      ...SectionTabsFields
      ...SubscriptionTeaserFields
      ...PpcReviewsProductFields
      ...SectionHubspotFormFields
      ...SectionAnchorTargetFields
    }
  }
  ${SectionByTheNumbersFieldsFragmentDoc}
  ${SectionContactFieldsFragmentDoc}
  ${SectionCtaFieldsFragmentDoc}
  ${SectionExplanationBlocksFieldsFragmentDoc}
  ${SectionFaqFieldsFragmentDoc}
  ${SectionFramesHeroFieldsFragmentDoc}
  ${SectionHeroFieldsFragmentDoc}
  ${SectionIntroFieldsFragmentDoc}
  ${SectionOmrPartnerFieldsFragmentDoc}
  ${SectionRichContentFieldsFragmentDoc}
  ${SectionTeaserFieldsFragmentDoc}
  ${SectionTestimonialSliderFieldsFragmentDoc}
  ${SectionTextMediaFieldsFragmentDoc}
  ${SectionNewsletterAudiencesFieldsFragmentDoc}
  ${SectionTabsFieldsFragmentDoc}
  ${SubscriptionTeaserFieldsFragmentDoc}
  ${PpcReviewsProductFieldsFragmentDoc}
  ${SectionHubspotFormFieldsFragmentDoc}
  ${SectionAnchorTargetFieldsFragmentDoc}
`
export const FooterFieldsFragmentDoc = gql`
  fragment FooterFields on Footer {
    id
    omrFooterLinks {
      id
      name
      link
    }
    omrFooterSocials {
      id
      name
      link
      logo(locales: de) {
        url
      }
    }
  }
`
export const HeaderFieldsFragmentDoc = gql`
  fragment HeaderFields on Header {
    id
    logo(locales: de) {
      url
    }
    mobileLogo(locales: de) {
      url
    }
    omrHeaderMenuItems {
      id
      slug
      name
      link
      omrHeaderSubmenuItems {
        id
        name
        link
        omrHeaderSubSubmenuItems {
          id
          name
          link
        }
      }
      omrHeaderSubmenuCta {
        label
        url
      }
    }
    omrHeaderPartners {
      id
      name
      logo(locales: de) {
        url
      }
      partnerUrl
    }
    omrHeaderSocials {
      id
      name
      link
      logo(locales: de) {
        url
      }
    }
  }
`
export const ProcessStepFieldsFragmentDoc = gql`
  fragment ProcessStepFields on ProcessStep {
    __typename
    id
    optionalTitle: title
    optionalDescription: description
    optionalCtaText: ctaText
    optionalCtaUrl: ctaUrl
    steps
    sectionPadding
    styleVariant
    displayDecorations
  }
`
export const SectionFestivalHeroFieldsFragmentDoc = gql`
  fragment SectionFestivalHeroFields on SectionFestivalHero {
    __typename
    id
    optionalTitle: title
    optionalSecondTitle: secondTitle
    background(locales: de) {
      ...AssetFields
    }
    logo(locales: de) {
      ...AssetFields
    }
    titleDecoration {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionHotelsFieldsFragmentDoc = gql`
  fragment SectionHotelsFields on SectionHotel {
    __typename
    id
    title
    description
    hotels: hotels {
      ... on Hotel {
        id
        name
        description
        rating
        distance
        url
        logo(locales: de) {
          ...AssetFields
        }
      }
    }
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionPoweredByFieldsFragmentDoc = gql`
  fragment SectionPoweredByFields on SectionPoweredBy {
    __typename
    id
    logo(locales: de) {
      ...AssetFields
    }
    optionalTitle: title
    styleVariant
  }
  ${AssetFieldsFragmentDoc}
`
export const SectionQuoteFieldsFragmentDoc = gql`
  fragment SectionQuoteFields on SectionQuote {
    quote
    name
    signatureImage(locales: de) {
      ...AssetFields
    }
    position
  }
  ${AssetFieldsFragmentDoc}
`
export const LandingPageLayoutBySlugDocument = gql`
  query landingPageLayoutBySlug($slug: String!, $locale: [Locale!]!, $stage: Stage = PUBLISHED) {
    landingPage(where: { slug: $slug }, stage: $stage, locales: $locale) {
      id
      slug
      stage
      title
      ...LandingPageLayoutFields
    }
  }
  ${LandingPageLayoutFieldsFragmentDoc}
`

/**
 * __useLandingPageLayoutBySlugQuery__
 *
 * To run a query within a Vue component, call `useLandingPageLayoutBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPageLayoutBySlugQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLandingPageLayoutBySlugQuery({
 *   slug: // value for 'slug'
 *   locale: // value for 'locale'
 *   stage: // value for 'stage'
 * });
 */
export function useLandingPageLayoutBySlugQuery(
  variables:
    | LandingPageLayoutBySlugQueryVariables
    | VueCompositionApi.Ref<LandingPageLayoutBySlugQueryVariables>
    | ReactiveFunction<LandingPageLayoutBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>(
    LandingPageLayoutBySlugDocument,
    variables,
    options,
  )
}
export function useLandingPageLayoutBySlugLazyQuery(
  variables?:
    | LandingPageLayoutBySlugQueryVariables
    | VueCompositionApi.Ref<LandingPageLayoutBySlugQueryVariables>
    | ReactiveFunction<LandingPageLayoutBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<LandingPageLayoutBySlugQuery, LandingPageLayoutBySlugQueryVariables>(
    LandingPageLayoutBySlugDocument,
    variables,
    options,
  )
}
export type LandingPageLayoutBySlugQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  LandingPageLayoutBySlugQuery,
  LandingPageLayoutBySlugQueryVariables
>
export const LandingPageBySlugDocument = gql`
  query landingPageBySlug($slug: String!, $locales: [Locale!]!) {
    landingPage(where: { slug: $slug }, locales: $locales) {
      id
      ...LandingPageLayoutFields
      seoContentAttributes {
        bottomSectionBody {
          markdown
        }
        bottomSectionHeadline
        h1
        id
        topSectionBody {
          markdown
        }
      }
      slug
      stage
      title
    }
  }
  ${LandingPageLayoutFieldsFragmentDoc}
`

/**
 * __useLandingPageBySlugQuery__
 *
 * To run a query within a Vue component, call `useLandingPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandingPageBySlugQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLandingPageBySlugQuery({
 *   slug: // value for 'slug'
 *   locales: // value for 'locales'
 * });
 */
export function useLandingPageBySlugQuery(
  variables:
    | LandingPageBySlugQueryVariables
    | VueCompositionApi.Ref<LandingPageBySlugQueryVariables>
    | ReactiveFunction<LandingPageBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>(
    LandingPageBySlugDocument,
    variables,
    options,
  )
}
export function useLandingPageBySlugLazyQuery(
  variables?:
    | LandingPageBySlugQueryVariables
    | VueCompositionApi.Ref<LandingPageBySlugQueryVariables>
    | ReactiveFunction<LandingPageBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>(
    LandingPageBySlugDocument,
    variables,
    options,
  )
}
export type LandingPageBySlugQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  LandingPageBySlugQuery,
  LandingPageBySlugQueryVariables
>
export const ManageContentConfigsDocument = gql`
  query manageContentConfigs($locales: [Locale!]!) {
    manageContentConfigs(locales: $locales) {
      id
      contentSlug
      loggedInSlug
      anonymousSlug
      menuTitle
      customerTypes
    }
  }
`

/**
 * __useManageContentConfigsQuery__
 *
 * To run a query within a Vue component, call `useManageContentConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageContentConfigsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useManageContentConfigsQuery({
 *   locales: // value for 'locales'
 * });
 */
export function useManageContentConfigsQuery(
  variables:
    | ManageContentConfigsQueryVariables
    | VueCompositionApi.Ref<ManageContentConfigsQueryVariables>
    | ReactiveFunction<ManageContentConfigsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>(
    ManageContentConfigsDocument,
    variables,
    options,
  )
}
export function useManageContentConfigsLazyQuery(
  variables?:
    | ManageContentConfigsQueryVariables
    | VueCompositionApi.Ref<ManageContentConfigsQueryVariables>
    | ReactiveFunction<ManageContentConfigsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ManageContentConfigsQuery, ManageContentConfigsQueryVariables>(
    ManageContentConfigsDocument,
    variables,
    options,
  )
}
export type ManageContentConfigsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ManageContentConfigsQuery,
  ManageContentConfigsQueryVariables
>
