import { useUiStore } from '@/stores'
import type { UiNotification, UiNotificationType } from '@/stores/ui'

type Notification = Omit<UiNotification, 'id'> & { timeout?: number }

const DEFAULT_TIMEOUT = 5000

function showNotification(message: string, type: UiNotificationType, timeout?: number): UiNotification
function showNotification(notification: Notification): UiNotification
function showNotification(...args: any[]): UiNotification {
  const uiStore = useUiStore()

  const [messageOrNotification, maybeType, maybeTimeout] = args

  let payload: UiNotification
  let timeout: number
  if (typeof messageOrNotification === 'string') {
    payload = {
      type: maybeType,
      message: messageOrNotification,
    }
    timeout = maybeTimeout
  } else {
    payload = {
      type: messageOrNotification.type,
      message: messageOrNotification.message,
      headline: messageOrNotification.headline,
    }
    timeout = messageOrNotification.timeout
  }
  const notification = uiStore.addNotification(payload)
  window.setTimeout(() => uiStore.removeNotification(notification), timeout || DEFAULT_TIMEOUT)

  return notification
}
export { showNotification }
